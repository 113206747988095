import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';

import type {TNode, TTreeMap} from 'src/components/TreeSelect';
import {EAudienceGroupState, TAudienceGroupState} from 'src/constants';

import {
  ALL_RECEIVERS_TAG_ID,
  TAudience,
  TAudienceOperator,
  TAudienceParam,
  TBackendItem,
  TCategoryMap,
  TItem,
  TOperand,
  TState,
  TTagCategory,
  TTagQuery,
  TTagsOperand,
} from './definitions';

export const ROOT_NODE_ID = 0;
export const ROOT_NODE_PARENT_ID = -1;

export const getTreeMapByNodeList = (nodeList: TNode[]) => {
  const treeMap: Record<TNode['id'], TNode> = {
    // tree root for multiple roots for BE, tree must have only one root

    [ALL_RECEIVERS_TAG_ID]: {
      id: ALL_RECEIVERS_TAG_ID,
      name: '',
      code: null,
      subscriberId: null,
      // categoryID добавляется в getTagsGroups
      categoryId: null,
    } as any as TNode,
    [ROOT_NODE_ID]: {
      id: ROOT_NODE_ID,
      name: 'root',
      parentId: ROOT_NODE_PARENT_ID,
      childIds: [],
    },
  };

  nodeList.forEach((node) => {
    const parentId =
      node.parentId === null || node.parentId === undefined ? ROOT_NODE_ID : node.parentId;

    treeMap[node.id] = {
      ...node,
      childIds: [],
      parentId,
    };
  });

  Object.values(treeMap).map((node) => {
    const parentId = node.parentId;

    if (treeMap[parentId]) {
      treeMap[parentId].childIds.push(node.id);
    }
  });

  return treeMap;
};

// Breadth First Search (BFS)
export const getAllChildLeafIds = (id: TNode['id'], tree: TTreeMap) => {
  const stack: TNode['id'][] = [id];
  const result: TNode['id'][] = [];

  while (stack.length > 0) {
    const pop = stack.pop();

    if (pop !== undefined) {
      if (tree[pop]?.childIds.length === 0) {
        result.push(pop);
      }

      stack.push(...(tree[pop]?.childIds ?? []));
    }
  }

  return result;
};

export const getAllChildNodesIds = (id: TNode['id'], tree: TTreeMap) => {
  const stack: TNode['id'][] = [id];
  const result: TNode['id'][] = [];

  while (stack.length > 0) {
    const pop = stack.pop();

    if (pop !== undefined) {
      result.push(pop);

      stack.push(...tree[pop].childIds);
    }
  }

  return result;
};

export const getOwnChildIds = (id: TNode['id'], tree: TTreeMap) => {
  const stack: TNode['id'][] = [id];
  const result: TNode['id'][] = [];

  while (stack.length > 0) {
    const pop = stack.pop();

    if (pop === id) {
      result.push(...tree[id].childIds);
      break;
    }

    stack.push(...tree[id].childIds);
  }

  return result;
};

export const getPathByNodeId = (id: TNode['id'], tree: TTreeMap, includeRoot = false) => {
  const stack: TNode['parentId'][] = [id];
  const result: TNode['parentId'][] = [id];
  const checkParentId = (parentId: number) => {
    if (!Number.isInteger(parentId)) {
      return false;
    }

    return includeRoot ? true : parentId !== ROOT_NODE_ID;
  };

  while (stack.length > 0) {
    const pop = stack.pop();

    if (pop && checkParentId(tree[pop]?.parentId)) {
      result.push(tree[pop].parentId);
      stack.push(tree[pop].parentId);
    }
  }

  return result;
};

export const getTreeMapForSelectedTags = (tags: TItem[], tree: TTreeMap) => {
  const tagsCategories = uniq(compact(tags.map((item) => item.categoryId)));
  const tagsCategoriesWithParents = flatten(
    tagsCategories.map((item) => getPathByNodeId(item, tree, true)),
  );

  const result = pickBy(
    tree,
    (item) => tagsCategories.includes(item.id) || tagsCategoriesWithParents.includes(item.id),
  );

  return mapValues(result, (value) => ({
    ...value,
    childIds: value.childIds.filter((item) => result[item] !== undefined),
  }));
};

// Maps backend tag structure to frontend (tagId -> id)
export const mapBackendItemToItem = ({
  tagId,
  name,
  code,
  subscriberId,
  categoryId,
}: TBackendItem) => ({
  id: tagId,
  name,
  code,
  subscriberId,
  categoryId,
});

// Maps frontend tag structure to backend (id -> tagId)
export const mapItemToBackendItem = ({id, ...item}: TItem) => ({
  tagId: id,
  ...item,
});

export const transformContentToItemsMap = (content: TBackendItem[]): TState['itemsMap'] =>
  keyBy(content.map(mapBackendItemToItem), 'id');

export const transformOperandsToAudience = (operand: TOperand | TTagsOperand): TAudienceParam[] => {
  if (operand.operator === 'ALL') {
    return [{id: uniqueId(), type: 'OR', delay: null, items: [ALL_RECEIVERS_TAG_ID]}];
  }

  // Получение id тега из операнда с tagID
  const processSingleTag = (tagOperand: TTagsOperand) => {
    const {tagId} = tagOperand;

    return tagId;
  };

  // Если операнд ничего не содержит
  if (isEmpty(operand)) {
    return [];
  }

  // Если операнд содержит единственный тег
  if (operand.operator === 'EQ') {
    return [
      {
        id: uniqueId(),
        type: 'OR',
        delay: operand.delay,
        items: [processSingleTag(operand)],
      },
    ];
  }

  // Если операнд содержит единственную группу с несколькими тегами
  const operandHasOnlyTags = (operand?.operands ?? [])
    .map((item) => item.operator)
    .every((item) => item === 'EQ');

  if (operandHasOnlyTags) {
    return [
      {
        id: uniqueId(),
        type: operand.operator as TAudienceOperator,
        items: (operand.operands as TTagsOperand[]).map(processSingleTag),
        delay: (operand.operands as TTagsOperand[])[0]?.delay,
      },
    ];
  }

  // Если операнд содержит несколько групп с любым числом тегов
  return (operand?.operands ?? []).reduce(
    (result: TAudienceParam[], tagOperand) => [
      ...result,
      ...transformOperandsToAudience(tagOperand),
    ],
    [],
  );
};

const processSingleTag = (tagId: TTagQuery['id'], delay: string | null = null): TTagsOperand => ({
  operator: 'EQ',
  delay,
  tagId,
});

const getOperands = (
  type: TAudienceGroupState,
  values: TAudienceParam[],
): TOperand | TTagsOperand => {
  if (values[0].items.includes(ALL_RECEIVERS_TAG_ID)) {
    return {operator: 'ALL'};
  }

  const processSingleValue = (value: TAudienceParam): TOperand | TTagsOperand => {
    if (value.items.length === 1) {
      return processSingleTag(value.items[0], value.delay);
    }

    return {
      operator: value.type,
      operands: value.items.map((id) => processSingleTag(id, value.delay)),
    };
  };

  if (values.length === 1) {
    return processSingleValue(values[0]);
  }

  return {
    operator: 'OR',
    operands: values.map(processSingleValue),
  };
};

export const prepareTagForSave = (audience: TAudience): TTagQuery['query'] => {
  const newExcluded = [...audience.excluded];

  const hasExcluded = newExcluded.length && newExcluded[0].items.length;

  if (hasExcluded) {
    return {
      operator: 'MINUS',
      operands: [
        getOperands(EAudienceGroupState.included, audience.included),
        getOperands(EAudienceGroupState.excluded, newExcluded),
      ],
    };
  }

  return getOperands(EAudienceGroupState.included, audience.included);
};

export const getAllSelectedTagsIds = ({included, excluded}: TAudience) => {
  const result: TTagQuery['id'][] = [];

  [...excluded, ...included].forEach((group) => {
    result.push(...group.items);
  });

  return result;
};

export const getCategoriesMap = (nodeList: TTagCategory[]) => {
  const treeMap: TCategoryMap = {
    // tree root for multiple roots for BE, tree must have only one root
    [ROOT_NODE_ID]: {
      id: ROOT_NODE_ID,
      name: 'root',
      parentId: ROOT_NODE_PARENT_ID,
      childIds: [],
    },
  };

  nodeList.forEach((node) => {
    const parentId =
      node.parentId === null || node.parentId === undefined ? ROOT_NODE_ID : node.parentId;

    treeMap[node.id] = {
      ...node,
      childIds: [],
      parentId,
    };
  });

  Object.values(treeMap).map((node) => {
    const parentId = node.parentId;

    if (treeMap[parentId]) {
      treeMap[parentId].childIds.push(node.id);
    }
  });

  return treeMap;
};
