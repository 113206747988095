export default {
  title: 'edna Pulse — единое окно для решения всех задач в области цифровых коммуникаций',
  navigation: {
    home: 'Главная',
    settings: 'Настройки',
    triggers: 'Сценарии',
    broadcasts: 'Рассылки',
    audience: 'Аудитория',
    cascades: 'Каскады',
    channels: 'Каналы',
    templates: 'Шаблоны',
    analytics: 'Аналитика',
    analyticsMessages: 'Сообщения',
    analyticsReport: 'Итоговый отчет',
    profile: 'Профиль',
    myProfile: 'Мой профиль',
    leadClients: 'Лиды',
    supportClients: 'Клиенты компании',
    userProfile: 'Профиль компании',
    help: 'Помощь',
    notifications: 'Уведомления',
    signout: 'Выход',
    contentManager: 'Менеджер контента',
    integration: 'Интеграция',
    corrections: 'Корректировки',
  },
};
