import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

import {TBadgeColor} from './constants';

export type TBadgeProps = {
  color: TBadgeColor;
  isSolid?: boolean;
};

const getSolidBadgeStyle = ({color, theme}: TTheme & TBadgeProps) => {
  switch (color) {
    case 'GREEN':
      return css`
        background: ${theme.palette.green400};
        border-color: ${theme.palette.green400};
        color: ${theme.palette.grey10};
      `;
    case 'YELLOW':
      return css`
        background: ${theme.branding.colors.warning};
        border-color: ${theme.branding.colors.warning};
        color: ${theme.palette.grey800};
      `;
    case 'ORANGE':
      return css`
        background: ${theme.palette.orange500};
        border-color: ${theme.palette.orange500};
        color: ${theme.palette.grey10};
      `;
    case 'RED':
      return css`
        background: ${theme.palette.red400};
        border-color: ${theme.palette.red400};
        color: ${theme.palette.grey10};
      `;
    case 'PURPLE':
      return css`
        background: ${theme.color.darkPurple};
        border-color: ${theme.color.darkPurple};
        color: ${theme.palette.grey10};
      `;
    case 'GRAY':
      return css`
        background: ${theme.branding.content.tertiary};
        border-color: ${theme.branding.content.tertiary};
        color: ${theme.palette.grey10};
      `;
    case 'PINK':
      return css`
        background: ${theme.branding.colors.accentB};
        border-color: ${theme.branding.colors.accentB};
        color: ${theme.palette.grey10};
      `;
    case 'BLUE':
    default:
      return css`
        background: ${theme.branding.colors.info};
        border-color: ${theme.branding.colors.info};
        color: ${theme.palette.grey10};
      `;
  }
};

const getBadgeStyle = ({color, theme}: TTheme & TBadgeProps) => {
  switch (color) {
    case 'GREEN':
      return css`
        color: ${theme.branding.colors.success};
        border-color: ${theme.branding.colors.success};
      `;
    case 'YELLOW':
      return css`
        color: ${theme.branding.colors.warning};
        border-color: ${theme.branding.colors.warning};
      `;
    case 'ORANGE':
      return css`
        color: ${theme.palette.orange500};
        border-color: ${theme.palette.orange500};
      `;
    case 'RED':
      return css`
        color: ${theme.palette.red400};
        border-color: ${theme.palette.red400};
      `;
    case 'PURPLE':
      return css`
        color: ${theme.color.darkPurple};
        border-color: ${theme.color.darkPurple};
      `;
    case 'GRAY':
      return css`
        color: ${theme.branding.content.tertiary};
        border-color: ${theme.branding.content.tertiary};
      `;
    case 'PINK':
      return css`
        color: ${theme.branding.colors.accentB};
        border-color: ${theme.branding.colors.accentB};
      `;
    case 'BLUE':
    default:
      return css`
        color: ${theme.branding.colors.info};
        border-color: ${theme.branding.colors.info};
      `;
  }
};

export const Badge = styled.div<TBadgeProps>`
  ${mixins.f2}
  display: inline-block;
  border-style: solid;
  font-weight: 600;
  line-height: 12px;
  word-break: normal;
  ${({isSolid, color, theme}) =>
    isSolid
      ? css`
          ${getSolidBadgeStyle({color, theme})}
        `
      : css`
          ${getBadgeStyle({color, theme})}
        `}

  ${({theme}) => css`
    padding: ${theme.spacing.s1} ${theme.spacing.s3};
    border-width: ${theme.dimension.borderWidth}px;
    border-radius: ${theme.borderRadius.br6};
  `};
`;
