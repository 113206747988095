import {ELanguage} from 'src/constants';

const pluralRules = new Intl.PluralRules('en-US', {type: 'ordinal'});
const pluralSuffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

const getOrdinalDay = (date: Date, locale: string) => {
  const day = date.getDate();

  if (locale === ELanguage.ru) {
    return day.toString();
  }

  const rule = pluralRules.select(day);
  const suffix = pluralSuffixes.get(rule);

  return `${day}${suffix}`;
};

export {getOrdinalDay};
