import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom';

import {useExactSelector, useOnMount} from '@edna/hooks';

import userModel from 'src/containers/Auth/userModel';
import Layout from 'src/containers/Layout';
import RouteErrorBoundary from 'src/containers/ServiceUnavailable/RouteErrorBoundary';
import {routeChanged} from 'src/models/actions';
import PATHS, {AUTH_PATH_PREFIX} from 'src/paths';
import {sendPageMetrics} from 'src/utils';
import {setTitle} from 'src/utils/pageTitle';

import {
  Components,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  TRoutesOptions,
  getPrivateRoutesOptions,
} from './routes';
import {selectIsServiceAvailable} from './slice';
import {useMetrics} from './useMetrics';

export {PRIVATE_ROUTES};

type TContentProps = {
  isServiceAvailable?: boolean;
};

const Content = React.memo<TContentProps>(({isServiceAvailable}) => {
  const {pathname} = useLocation();

  if (!isServiceAvailable) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return (
    <Layout>
      <RouteErrorBoundary isFullWidth={pathname.startsWith(AUTH_PATH_PREFIX)} pathname={pathname}>
        <Outlet />
      </RouteErrorBoundary>
    </Layout>
  );
});

const App = React.memo(() => {
  const {i18n} = useTranslation();
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const logged = useExactSelector(userModel.selectors.logged);
  const isUserLoading = useExactSelector(userModel.selectors.loading);
  const isUserLoaded = useExactSelector(userModel.selectors.loaded);
  const permissions = useExactSelector(userModel.selectors.userPermissions);
  const roles = useExactSelector(userModel.selectors.userRoles);
  const isServiceAvailable = useExactSelector(selectIsServiceAvailable);

  useMetrics();

  const allowedPrivateRoutes: TRoutesOptions = React.useMemo(
    () => (logged ? getPrivateRoutesOptions(permissions, roles) : {}),
    [permissions, roles, logged],
  );

  React.useLayoutEffect(() => {
    dispatch(routeChanged(pathname));
    sendPageMetrics(pathname);
  }, [pathname]);

  useOnMount(() => {
    dispatch(userModel.actions.setDefaultTenantId({useLast: true}));
    dispatch(userModel.actions.requestUser());
  });

  React.useEffect(() => {
    setTitle(i18n, pathname, isUserLoading);
  }, [pathname, isUserLoading, i18n]);

  if (isUserLoading || !isUserLoaded) {
    return null;
  }

  if (!isServiceAvailable) {
    return (
      <Routes>
        <Route element={<Content isServiceAvailable={isServiceAvailable} />}>
          <Route path={PATHS.UNAVAILABLE} element={<Components.ServiceUnavailable />} />
          <Route path="*" element={<Navigate to={PATHS.UNAVAILABLE} />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<Content />}>
        {logged && (
          <>
            {Object.entries(allowedPrivateRoutes).map(([path, Component]) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path={`${AUTH_PATH_PREFIX}/*`} element={<Navigate to={PATHS.HOME} />} />
            <Route path="/" element={<Components.Home />} />
            <Route path="*" element={<Components.NotFound />} />
          </>
        )}
        {!logged && (
          <>
            {Object.entries(PUBLIC_ROUTES).map(([path, Component]) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<Navigate to={PATHS.SIGN_IN} />} />
          </>
        )}
      </Route>
    </Routes>
  );
});

App.displayName = 'App';

export default App;
