import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import formatDate from '@edna/utils/formatDate';

import {News, NewsDate, useNewsDate} from 'src/containers/News';
import {MaintenanceImage} from 'src/images';

import {ESystemNewsType, TReleaseNews, TSystemNews} from '../model';

type TProps = {
  item: TSystemNews;
};

const UpdatePlanned = React.memo<{item: TReleaseNews}>(({item}) => {
  const {t} = useTranslation();

  const date = useNewsDate(item.meta.startAt);
  const startTime = formatDate(new Date(item.meta.startAt), {format: 'HH:mm'});
  const endTime = formatDate(new Date(item.meta.endAt), {format: 'HH:mm'});

  return (
    <News
      title={t(`Notifications:news.${item.type}.title`, {date})}
      image={MaintenanceImage}
      id={item.id}
    >
      <Trans i18nKey={`Notifications:news.${item.type}.content`} values={{startTime, endTime}}>
        <NewsDate date={item.meta.startAt} isOrdinal />
      </Trans>
    </News>
  );
});

const SystemNews = React.memo<TProps>(({item}) => {
  if (!item?.type) {
    return null;
  }

  switch (item.type) {
    case ESystemNewsType.UpdatePlanned:
      return <UpdatePlanned item={item} />;

    default:
      return null;
  }
});

SystemNews.displayName = 'SystemNews';

export {SystemNews};
