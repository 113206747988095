import styled from 'styled-components';

import {mixins} from '@edna/components/styles';
import BaseMoreHorizIcon from '@edna/icons/moreHoriz.svg';

export const MoreHorizIcon = styled(BaseMoreHorizIcon)`
  transform: rotate(90deg);
`;

export const OptionIcon = styled.svg`
  ${mixins.wh('16px')}
  margin-right: ${({theme}: TTheme) => theme.spacing.s4};
`;
