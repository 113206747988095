import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

type TTabProps = TTheme & {
  active: boolean;
};

type TContentProps = {
  color?: TThemeColor;
};

export const Content = styled.div<TContentProps>`
  ${mixins.flexAlign('flex-start', 'center')}
  position: relative;
  ${({color, theme}) => css`
    border-bottom: 5px solid ${color ?? theme.palette.grey100};
  `}
`;

const getTabStyle = ({theme}: TTabProps) => css`
  margin-bottom: -${theme.spacing.s1};
  padding: ${theme.spacing.s4};
  &::after {
    ${mixins.position({position: 'absolute', left: 0, bottom: '-1px'})}
    display: inline-block;
    width: 100%;
    content: '';
    border-bottom: 5px solid transparent;
  }
`;

const getActiveTabStyle = ({active, theme}: TTabProps) => {
  if (active) {
    return css`
      &::after {
        border-color: ${theme.branding.content.primary};
      }
    `;
  }

  return css`
    cursor: pointer;
  `;
};

export const Tab = styled.div<TTabProps>`
  ${mixins.flexAlign('center')}
  position: relative;
  ${getTabStyle}
  ${getActiveTabStyle}
`;
