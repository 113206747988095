// TODO https://jira.edna.ru/browse/EP-4129 check ftp usage
const isValidUrl = (value: string) => /^((http|https|ftp):\/\/)/.test(value);
const isValidHttpsUrl = (value: string) => /^(https:\/\/)/.test(value);

const isValidASCII = (value: string) => /^[\x00-\x7F]*$/.test(value);

const isValidBIC = (value: string) => /^\d{9}$/.test(value);

const isValidKPP = (value: string) => /^[0-9A-Z]{9}$/.test(value);

const isValidTIN = (value: string) => /^(\d{10}|\d{12})$/.test(value);

const isValidPhone = (value: any) => {
  if (typeof value !== 'string' || typeof value !== 'number') {
    return false;
  }

  return String(value).replace(/[^0-9]/g, '').length === 11;
};

export {
  isValidUrl,
  isValidHttpsUrl,
  isValidASCII,
  isValidBIC,
  isValidKPP,
  isValidTIN,
  isValidPhone,
};
