export default {
  validator: {
    uppercase: 'Has an uppercase letter',
    lowercase: 'Has a lowercase letter',
    latin: 'Uses Latin characters only',
    length: 'Consists of 8 to 32 characters',
    number: 'Has a digit',
    specialCharacter: 'Has a special character',
  },
};
