import styled, {css} from 'styled-components';

import {TLoadingButtonProps} from '@edna/components/Button';
import {
  Button as BaseButton,
  LoadingButton as BaseLoadingButton,
} from '@edna/components/Button/style';
import {mixins} from '@edna/components/styles';

export * from '@edna/components/Button/style';

type TButtonProps = TLoadingButtonProps & TTheme;

const getButtonStyle = ({
  theme: {palette, branding},
  disabled,
  loading,
  appearance,
}: TButtonProps) => {
  if (disabled && !loading) {
    switch (appearance) {
      case 'primary':
        return css`
          color: ${branding.colors.disabled};
          background-color: ${branding.backgrounds.secondary};
          border-color: ${palette.grey50};
        `;
      case 'secondary':
        return css`
          color: ${palette.grey300};
          background-color: ${branding.backgrounds.primary};
          border-color: ${palette.grey300};
        `;
      default:
        return css`
          color: ${branding.colors.disabled};
          background-color: ${branding.backgrounds.primary};
          border-color: ${palette.white};
        `;
    }
  }

  switch (appearance) {
    case 'primary': {
      return css`
        color: ${palette.white};
        background-color: ${branding.content.primary};
        border-color: ${branding.content.primary};
        &:hover {
          color: ${branding.content.tertiary};
          background-color: ${branding.content.primary};
          border-color: ${branding.content.primary};
        }
        &:active {
          color: ${palette.white};
          background-color: ${palette.grey700};
          border-color: ${palette.grey700};
        }
      `;
    }

    case 'secondary': {
      return css`
        color: ${branding.content.primary};
        background-color: ${branding.backgrounds.primary};
        border-color: ${branding.content.primary};
        &:hover {
          color: ${palette.grey500};
          background-color: ${branding.backgrounds.primary};
          border-color: ${palette.grey500};
        }
        &:active {
          color: ${branding.content.secondary};
          background-color: ${branding.backgrounds.primary};
          border-color: ${branding.content.secondary};
        }
      `;
    }
    default:
      return css`
        color: ${branding.content.primary};
        background-color: ${branding.backgrounds.primary};
        border-color: ${palette.white};
        box-shadow: 0 1px 4px -1px ${branding.content.tertiary};
        &:active {
          background-color: ${branding.backgrounds.secondary};
          border-color: ${palette.grey50};
        }
      `;
  }
};

const getButtonSizeStyle = ({size}: TButtonProps) => {
  switch (size) {
    case 'S':
      return css`
        ${mixins.f2}
        font-weight: 500;
      `;
    case 'L':
      return css`
        ${mixins.f4}
        font-weight: 600;
      `;
    case 'M':
    default:
      return css`
        ${mixins.f3}
        font-weight: 600;
      `;
  }
};

const button = css`
  text-transform: none;
  ${getButtonSizeStyle}
  ${getButtonStyle}
`;

export const Button = styled(BaseButton)`
  ${button}
`;

export const LoadingButton = styled(BaseLoadingButton)`
  ${button}
`;
