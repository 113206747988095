export default {
  ttl: `in {{hours}}{{minutes}}{{seconds}}
      to those who did not receive the message`,
  whatsAppWarning:
    'The <0></0><b>{{name}}</b> channel has received many complaints. Daily limits can be reduced.',
  whatsAppRestricted: `The broadcast may not be sent! The <0></0><b>{{name}}</b> channel has reached its daily limit of outgoing messages.
    <br/>Some messages may not be delivered.`,
  messagingLimit: 'Daily limit: <b>{{limit}}</b>{{messages}}',
  whatsAppInfo: `The daily limit of the <0></0><b>{{name}}</b> channel is <b>{{limit}}</b>{{messages}}.
    <br/>Take this into account when planning your broadcast.`,
  whatsAppInfoMessages: ' messages',
  time: {
    seconds_one: 'second',
    seconds_other: 'seconds',
    minutes_one: 'minute',
    minutes_other: 'minutes',
    hours_one: 'hour',
    hours_other: 'hours',
  },
};
