export default {
  label: 'Continue with Facebook',
  permissionsError: {
    title: 'Error connecting the account',
    message:
      'To connect your account to the {{channel}} channel in edna, you need to select “Yes” everywhere in the Facebook form at the “What is allowed for the application” stage. <b>Settings need to be changed</b>',
    button: 'Try again',
  },
  connectionError: {
    title: 'Error connecting to Facebook',
    button: 'Try again',
  },
};
