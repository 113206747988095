import styled, {css} from 'styled-components';

import {ERROR_NODE_HEIGHT} from '@edna/components/FormField/constants';
import {
  FormField as BaseFormField,
  Label as BaseLabel,
  TFormFieldProps as TBaseFormFieldProps,
  TLabelProps,
} from '@edna/components/FormField/primitives';
import {mixins} from '@edna/components/styles';

export * from '@edna/components/FormField/primitives';

export type TFormFieldProps = Partial<TBaseFormFieldProps>;

export const Label = styled(BaseLabel)<TLabelProps>`
  color: ${({theme, disabled}: TLabelProps & TTheme) =>
    disabled ? theme.branding.colors.disabled : theme.branding.content.secondary};
`;

const getMarginBottom = ({noMargin, isGroupViewType, theme, type}: TTheme & TFormFieldProps) => {
  if (noMargin) {
    return css`
      margin-bottom: 0;
    `;
  }

  if (isGroupViewType) {
    return css`
      margin-bottom: ${theme.spacing.s2};
    `;
  }

  const marginBottom = ERROR_NODE_HEIGHT;

  if (type === 'radio') {
    return css`
      margin-bottom: ${theme.spacing.s2};
    `;
  }

  return css`
    margin-bottom: ${marginBottom}px;
  `;
};

// TODO убрать стиль для last-children в edna пакете
export const FormField = styled(BaseFormField)<TFormFieldProps>`
  ${mixins.column}
  width: 100%;
  ${getMarginBottom}
`;
