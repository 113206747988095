import React from 'react';
import {useTranslation} from 'react-i18next';

import {EMessagingLimit, limitPriorityMap} from 'src/containers/Channels/definitions';
import {TEmbeddedWhatsAppChannel} from 'src/containers/EmbeddedWhatsAppChannel/definitions';
import {TWhatsAppChannel} from 'src/containers/WhatsAppChannel/definitions';
import {parseISO} from 'src/utils';

import * as S from './style';

type TProps = {
  item: TWhatsAppChannel | TEmbeddedWhatsAppChannel;
};

const ChannelMessagingLimit = React.memo<TProps>(({item}) => {
  const {t} = useTranslation();

  return (
    <div>
      <S.InfoRow>
        <S.InfoLabel>{t('Channels:messagesLimit')}</S.InfoLabel>
        <S.Limit
          isGrayColor={
            item.messagingLimit === null || item.messagingLimit === EMessagingLimit.TIER_NOT_SET
          }
        >
          {item.previousMessagingLimit && item.messagingLimit && (
            <S.ArrowIcon
              isDown={
                limitPriorityMap[item.previousMessagingLimit] >
                limitPriorityMap[item.messagingLimit]
              }
            />
          )}
          {t(`Channels:limit.${item.messagingLimit}`)}
        </S.Limit>
      </S.InfoRow>
      <S.InfoRow>
        <S.InfoLabel>{t('Channels:qualityUpdatedAt')}</S.InfoLabel>
        <S.LimitDate>{parseISO(item.qualityUpdatedAt, 'dd.MM.yyyy HH:mm:ss')}</S.LimitDate>
      </S.InfoRow>
    </div>
  );
});

ChannelMessagingLimit.displayName = 'ChannelMessagingLimit';

export {ChannelMessagingLimit};
