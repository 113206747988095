import {EChannelStatus} from 'src/containers/PushChannel/definitions';

import {EAppStatus, EPushAppType} from './definitions';

export default {
  appData: 'Данные приложения',
  appStatus: {
    [EAppStatus.DEVICES_CONNECTED]: {
      title: 'Подключено',
      details: 'Приложение настроено, ошибок нет',
    },
    [EAppStatus.DEVICES_NOT_CONNECTED]: {
      title: 'Не подключено',
      details: 'Настройка приложения не завершена',
    },
    [EAppStatus.ERROR]: {
      title: 'Ошибка',
      details: 'Ошибка в настройках приложения',
    },
    [EAppStatus.BLOCKED]: {
      title: 'Заблокировано',
      details: 'Приложение заблокировано',
    },
  },
  copy: 'Скопировать',
  change: 'Изменить',
  update: 'Обновить',
  application: 'Приложение',
  appRegistrationDate: 'Дата регистрации приложения',
  connect: 'Подключить',
  ednaKey: 'Ключ доступа в edna Pulse',
  appPackage: 'Имя пакета приложения (app package)',
  serviceJson: 'Файл JSON сервисного аккаунта Google',
  serviceJsonWarning:
    'Для продолжения работы канала загрузите файл JSON сервисного аккаунта Google. <0>Как получить файл</0>',
  attachJson: 'Загрузить JSON',
  changeJson: 'Заменить JSON',
  registrationType: 'Способ регистрации устройств в edna Pulse',
  automaticRegistrationText:
    'Автоматическая регистрация: все устройства самостоятельно регистрируются в edna Pulse и могут получать рекламные и маркетинговые сообщения',
  automaticRegistrationInfo:
    'Для передачи конфиденциальных сообщений рекомендуем реализовать ручной способ регистрации устройств в edna Pulse',
  manualRegistrationText:
    'Ручная регистрация: ваш бэкенд регистрирует устройства через <0>метод registration публичного API</0> – подходит для отправки всех типов сообщений',
  setUpManualRegistration: 'Настроить ручную регистрацию',
  bundleID: 'BundleID',
  productionCertificatePassword: 'Пароль сертификата .p12',
  productionCertificateFile: 'Файл сертификата .p12',
  certificateExpiredAt: 'Срок жизни сертификата истекает {{date}}',
  huaweiApp: {
    title: 'Приложение Huawei',
    huaweiAppId: 'Huawei Client ID',
    huaweiAppSecret: 'Huawei Client Secret',
    [EAppStatus.DEVICES_CONNECTED]: {
      title: 'Подключено',
      details: 'Приложение подключено',
    },
    [EAppStatus.DEVICES_NOT_CONNECTED]: {
      title: 'Не подключено',
      details: 'Приложение не подключено',
    },
    hints: {
      huaweiAppId: 'Параметр Huawei Client Id из консоли Huawei',
      huaweiAppSecret: 'Ключ Huawei Client Secret из консоли Huawei',
    },
  },
  hints: {
    appRegistrationDate: 'Дата регистрации приложения в сервисе edna',
    ednaKey: 'Ключ доступа к сервису edna',
    appPackage: 'Имя пакета приложения (app package), например io.edna.app',
    registrationType:
      'При автоматической регистрации мобильная пуш-библиотека edna регистрирует устройство в edna Pulse после вызова метода login со стороны мобильного приложения. Этот способ проще, не требует от вас получать адрес устройства и управлять регистрацией с бэка, но не рекомендуется для отправки конфиденциальных данных. \n' +
      'В ручной регистрации ваш бэкенд должен получить с мобильного приложения адрес устройства и зарегистрировать устройство в сервисе edna через <0>метод registration публичного API</0>',
    bundleID: 'BundleID',
    productionCertificateFile:
      'Сертификат для отправки пуш-уведомлений из developer.apple.com, экспортированный в формате .p12',
    productionCertificatePassword:
      'Пароль от сертификата .p12, который ввели при экспорте из Связки ключей',
  },
  dialogs: {
    changeFirebaseAPIKey: 'Изменить Firebase Server Key',
    newFirebaseAPIKey: 'Новый Firebase Server Key',
    changeIOSCertificateData: 'Изменить данные сертификата iOS',
    changeHuaweiAppSecret: 'Изменить Huawei Client Secret',
    newHuaweiAppSecret: 'Новый Huawei Client Secret',
    connectHuaweiApp: {
      title: 'Подключить пуш-облако HUAWEI',
      subTitle:
        'Настройте отправку уведомлений через пуш-облако Huawei. Для этого укажите параметры Client Id и Client Secret из консоли Huawei, а также подключите плагин и добавьте библиотеку edna huawei в список зависимостей вашего приложения по <0>инструкции</0>',
    },
    hints: {
      changeFirebaseAPIKey: 'Измените ключ Server Key для доступа к Firebase',
      newFirebaseAPIKey:
        'Введите новый ключ Server Key c вкладки Cloud Messaging настроек проекта в консоли Firebase',
      newHuaweiAppSecret: 'Введите новый App Secret из консоли Huawei',
    },
  },
  listApps: {
    message: `Один пуш-канал объединяет приложения для платформ iOS и Андроид. Объедините приложения в канал и отправляйте пуши пользователям этих приложений в одной рассылке. Интегрируйте каждое приложение канала с платформой edna.`,
    th: {
      name: 'Название',
      status: 'Статус',
      registrationCount: 'Всего зарегистрированных',
      perWeekCount: 'Новых за 7 дней',
    },
    actions: {
      move: 'Перенести в другой канал',
      block: 'Заблокировать',
      unblock: 'Разблокировать',
      moveButton: 'Перенести',
      addApp: 'Добавить приложение',
      setupApp: 'Настроить приложение',
      android: 'Приложение Андроид',
      ios: 'Приложение iOS',
    },
    empty: {
      [EPushAppType.ANDROID]: {
        title: 'Для подключения приложения Андроид потребуется',
        requirements: [
          {
            text: 'Проект приложения Андроид с minSdk не ранее 21 (Андроид 5) и targetSdk не позже 34 (Андроид 14)',
          },
          {
            text: 'Получить файл JSON сервисного аккаунта Google. <0>Как получить файл</0>',
          },
          {text: 'Получить файл google-services.json из Firebase. <0>Как получить файл</0>'},
          {text: 'Устройство Андроид 5.0 и новее или эмулятор c сервисами Google Play'},
          {text: 'Актуальная версия Android Studio'},
        ],
      },
      [EPushAppType.IOS]: {
        title: 'Для подключения приложения iOS потребуется',
        requirements: [
          {text: 'Приложение iOS'},
          {
            text: 'Устройство iOS (iPhone, iPad, iPod Touch) для тестирования. Симулятор Xcode не поддерживает пуш-уведомления, поэтому тестируйте на физическом устройстве',
          },
          {text: 'Компьютер Мас с последней версией Xcode'},
          {text: 'Пуш-сертификат Production Apple Push Certificate'},
          {text: 'Утилита CocoaPods или Swift Package Manager'},
        ],
      },
    },
    status: {
      [EChannelStatus.NO_CONNECTED_APPS]: {
        title: 'Не подключен',
        details: 'Нет настроенных приложений',
      },
      [EChannelStatus.ACTIVE]: {
        title: 'Работает исправно',
        details: 'Приложения настроены, ошибок или предупреждений нет',
      },
      [EChannelStatus.NEED_ATTENTION]: {
        title: 'Требует внимания',
        details: 'Есть ошибка или предупреждение в одном из приложений',
      },
      [EChannelStatus.DISABLED]: {
        title: 'Отключен',
        details: 'Приложения не могут получать пуши',
      },
    },
    moveModal: {
      title: 'Перенести в другой канал',
      placeholder: 'Выберите канал',
      label: 'Выберите канал для переноса',
    },
    blockModal: {
      title: 'Заблокировать приложение?',
      message: 'Пользователи приложения не смогут получать пуш-уведомления.',
    },
    unblockModal: {
      title: 'Разблокировать приложение?',
      message: 'Пользователи приложения смогут снова получать пуш-уведомления.',
    },
    notification: {
      successBlock: 'Приложение заблокировано!',
      successUnblock: 'Приложение разблокировано!',
      successMove: 'Приложение перенесено!',
    },
  },
};
