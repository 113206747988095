import {EJoiError} from './definitions';

const arrayMin = 'Нужно выбрать хотя бы {{count}}';
const arrayMax = 'Нужно выбрать не более {{count}}';
const stringMin = 'Должно быть не менее {{count}}';
const stringMax = 'Должно быть не более {{count}}';
const stringLength = 'Длина поля должна быть {{count}}';

export default {
  any: {
    only: 'Поле необходимо заполнить',
    required: 'Поле необходимо заполнить',
  },
  boolean: {
    base: 'Поле необходимо заполнить',
  },
  object: {
    and: 'Заполните оба поля',
    missing: 'Заполните одно из полей',
  },
  array: {
    min_one: `${arrayMin} элемент`,
    min_few: `${arrayMin} элемента`,
    min_many: `${arrayMin} элементов`,
    max_one: `${arrayMax} элемент`,
    max_few: `${arrayMax} элемента`,
    max_many: `${arrayMax} элементов`,
    unique: 'Такое название уже есть',
  },
  number: {
    base: 'Значение должно быть числом',
    min: 'Значение не должно быть меньше {{limit}}',
    max: 'Значение не должно быть больше {{limit}}',
  },
  string: {
    email: 'Некорректный электронный адрес',
    min_one: `${stringMin} символа`,
    min_few: `${stringMin} символов`,
    min_many: `${stringMin} символов`,
    max_one: `${stringMax} символ`,
    max_few: `${stringMax} символов`,
    max_many: `${stringMax} символов`,
    hex: 'Поле должно иметь шестнадцатеричный формат',
    length_one: `${stringLength} символ`,
    length_few: `${stringLength} символа`,
    length_many: `${stringLength} символов`,
  },
  date: {
    base: 'Необходимо указать дату',
    min: 'Укажите дату не ранее чем {{limit}}',
    max: 'Укажите дату не позднее чем {{limit}}',
  },
  uniqueKey: 'Ключ с таким названием уже есть',
  invalidPhone: 'Некорректный номер телефона',
  invalidPassword: 'Пароль не соответствует требованиям',
  passwordsIsEqual: 'Новый пароль совпадает со старым',
  passwordsDoNotMatch: 'Пароли не совпадают',
  invalidUrl: 'Неправильный формат URL',
  invalidNoEmoji: 'Поле содержит недопустимые символы Emoji',
  invalidCurrency: 'Неверный формат суммы',
  minCurrency: 'Платеж должен быть не меньше {{minAmount}} {{currency}}',
  invalidMessageMatcher: 'В шаблоне отсутствует знак %. Шаблон составлен некорректно.',
  invalidGradient: 'Неверное значение градиента',
  invalidBIC: 'БИК указан некорректно',
  invalidTIN: 'ИНН указан некорректно',
  invalidKPP: 'КПП указан некорректно',
  invalidIBAN: 'IBAN указан некорректно',
  invalidTimeFormat: 'Неверный формат времени',
  chooseTime: 'Выберите время',
  invalidOperBankAccount: 'Расчетный счет указан некорректно',
  invalidCorrBankAccount: 'Корреспондентский счет указан некорректно',
  expiredTime: 'Время просрочено',
  emptyTime: 'Время не должно быть нулевым',
  ttlMinSeconds: 'Для канала {{channelType}} значение времени должно быть от {{limit}} секунд',
  invalidFile: 'Неверный тип значения, требуется файл',
  invalidSpaceInBegin: 'Пробел или перенос в начале фразы недопустим',
  invalidSpaceInEnd: 'Пробел или перенос в конце фразы недопустим',
  invalidTextFormat:
    'Текст не соответствует формату. Использование трех и более переносов подряд, табуляции, 4-х и более пробелов недопустимо.',
  invalidTextWithHashFormat:
    'Текст сообщения, с учетом строки символов, не может содержать символ #.',
  invalidVariableTextFormat: 'Текст не соответствует формату. Использование переносов недопустимо.',
  emptyVariable: 'Необходимо выбрать переменные',
  onlyVariable:
    'Текст не соответствует формату. Текст шаблона не может содержать только переменные.',
  maxVariables:
    'Текст не соответствует формату. Текст шаблона не может содержать более {{limit}} переменных.',
  numberNotContainPlus: 'Ошибка! Телефон должен содержать символ плюс',
  passwordHasNotASCII: 'Пароль содержит символы не из ASCII и не может быть применен.',
  invalidVibrate: 'Значение интервала не должно быть больше {{limit}}',
  invalidTemplateName:
    'Допускается только латиница маленькими буквами, цифры и знак нижнего подчеркивания. Без пробелов',
  invalidTemporaryBlockDays: 'Перевести в неактивный статус можно на период до {{maxDays}} дней',
  invalidMinDimensions:
    'Неверный размер изображения. Минимальный размер {{minWidth}}x{{minHeight}}px',
  invalidExactDimensions:
    'Неверный размер изображения. Размер изображения должен быть {{minWidth}}x{{minHeight}}px',
  invalidTemplateTextLength_one:
    'Текст сообщения, с учетом строки символов, не может превышать {{count}} символа.',
  invalidTemplateTextLength_few:
    'Текст сообщения, с учетом строки символов, не может превышать {{count}} символов.',
  invalidTemplateTextLength_many:
    'Текст сообщения, с учетом строки символов, не может превышать {{count}} символов.',
  atLeastOnePhone: 'Выберите колонку с номером телефона',
  atLeastOneDay: 'Укажите хотя бы один день',
  minThreshold: 'Минимальный баланс – {{limit}} {{currency}}',
  minAmount: 'Минимальная сумма пополнения – {{limit}} {{currency}}',
  [EJoiError.NOT_SPACED_REGEXPS]: 'Добавьте пробел между переменными',
};
