import {showNewNotification} from '@edna/components';
import {TThunkCreator} from '@edna/models/apiModel';

import {INSTAGRAM_EXISTS_ERROR} from 'src/containers/InstagramChannel/constants';
import errorNotification, {getErrorCode} from 'src/models/errorNotification';
import listApiModel, {TMeta} from 'src/models/listApiModel';

import {TFormData, TItem} from './types';

const id = 'channel-profile/instagram';

type TState = {
  initialValues: TFormData;
};

type TActions = {
  // Thunks
  saveAccount: TThunkCreator<TFormData, TMeta, Promise<{result?: TItem; error?: TAnyObject}>>;
};

const defaultInitialValues: TFormData = {
  name: '',
};

const getInitialValues = (item: TItem | null = null): TFormData => {
  if (item === null) {
    return defaultInitialValues;
  }

  return {
    id: item.id,
    name: item.name,
    facebookAccountId: item.facebookAccountId,
    instagramAccountId: item.instagramAccountId,
    userName: item.userName,
    profileName: item.profileName,
    profileUrl: item.profileUrl,
    profilePictureUrl: item.profilePictureUrl,
    userAccessToken: item.userAccessToken,
    tariffTemplateId: item.tariffTemplateId,
  };
};

export default listApiModel<typeof id, TItem, TEmptyObject, TState, TActions>({
  id,
  thunks: ({actions, api}) => ({
    saveAccount: async ({dispatch}, {payload, meta = {}}) => {
      const isCreate = payload.id === undefined;

      dispatch(actions.updateItemState({item: payload, state: {saving: true}}));

      const {result, error} = await api.saveItem(payload);

      if (result) {
        showNewNotification({
          type: 'success',
          messageKey: 'List:notification.saveSuccess',
        });

        if (isCreate) {
          if (meta.insertItem) {
            dispatch(actions.insertItem(result));
          }
        } else {
          dispatch(actions.refreshItem(result));
        }

        if (meta.setItemForEdit) {
          dispatch(actions.setItemForEdit(result));
        }
      }

      if (getErrorCode(error) !== INSTAGRAM_EXISTS_ERROR) {
        errorNotification(error, 'List:errors.failedToSaveItem');
      }

      dispatch(actions.updateItemState({item: payload, state: {saving: false}}));

      return {result, error};
    },
  }),
  utils: {
    getInitialValues,
  },
});
