import styled from 'styled-components';

import {InfoDropdownOverlay, NewNumberInputField} from '@edna/components';
import {Icon} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';

import {CoinsIcon} from 'src/icons';

export const Wrapper = styled.div`
  ${mixins.stack({size: '4'})};
  margin-bottom: ${({theme}) => theme.spacing.s4};
`;

export const NumberInputField = styled(NewNumberInputField)`
  width: 158px;
  margin: 0;
`;

export const NestedBlock = styled.div`
  ${mixins.stack({size: '4'})}
  margin-left: ${({theme}) => theme.spacing.s7};
`;

export const SecondaryCoinsIcon = styled(Icon).attrs({as: CoinsIcon, size: '20px'})`
  color: ${({theme}) => theme.branding.content.tertiary};
  margin: ${({theme}) => `0 ${theme.spacing.s1} ${theme.spacing.s1} 0`};
`;

export const Overlay = styled(InfoDropdownOverlay)`
  ${mixins.position({position: 'absolute', top: '-50%', left: 'calc(100% + 5px)'})}
  width: 250px;

  &::before {
    ${mixins.position({
      position: 'absolute',
      bottom: '100%',
      right: '100%',
      top: '19px',
      left: '-4px',
      transform: 'rotate(-135deg)',
    })}
  }
`;
