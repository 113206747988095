import {ELicenseStatus} from './definitions';

export default {
  licenses: 'Licenses',
  termsValidUntil: 'The user agreement is valid until:',
  termsAccepted: 'The user agreement has been accepted on',
  availableChannels: 'Available channels',
  activeSignatures: 'Active signatures',
  noActiveSignatures: 'You have no active signatures',
  licenseStatus: {
    [ELicenseStatus.NEW]: 'Attention required',
    [ELicenseStatus.CANCELLED]: 'Inactive',
    [ELicenseStatus.ACTIVE]: 'Active',
    [ELicenseStatus.ACTIVE_DEBT]: 'Attention required',
    [ELicenseStatus.EXPIRED]: 'Expired',
    [ELicenseStatus.TEMPORARY_BLOCKED]: 'Inactive',
  },
  createdAt: 'Created ',
  cancelledAt: 'Cancelled ',
  validUntil: 'Valid until ',
  expiredAt: 'Expired on ',
  temporaryblockedAt: 'Temporarily blocked from ',
  validUntilHint: 'Activated on {{activatedAt}}; paid until {{paidUntil}}',
  showAll: 'Show all',
  hide: 'hide',
  button: {
    block: 'Discontinue',
    refuse: 'Discontinue',
    cancel: 'Cancel',
  },
  licensesInformation: {
    licenses: 'Licenses:',
    status: {
      [ELicenseStatus.ACTIVE]: '{{count}} active',
      [ELicenseStatus.EXPIRED]: '{{count}} expired',
      [ELicenseStatus.CANCELLED]: '{{count}} inactive',
      [ELicenseStatus.TEMPORARY_BLOCKED]: '{{count}} inactive',
    },
    needAttention: '{{count}} attention required',
  },
  cancelModal: {
    clientTitle: 'Do you want to discontinue your license?',
    clientText:
      'The license will be permanently blocked, which means that you will not be able to send and receive messages using this signature. A refund for the paid license is not possible according to the contract.',
    accountTitle: 'Discontinue the license?',
    accountText:
      'If you discontinue your license, the signature will be permanently blocked. You will not be able to send and receive messages using this signature.',
    successBlock: 'The license has been successfully blocked',
  },
};
