import React from 'react';

import {TBadgeColor} from './constants';
import * as S from './style';

type TProps = {
  children: React.ReactNode;
  color?: TBadgeColor;
  className?: string;
  isSolid?: boolean;
};

const Badge = React.memo<TProps>(({children, color = 'BLUE', className, isSolid}) => (
  <S.Badge className={className} color={color} isSolid={isSolid}>
    {children}
  </S.Badge>
));

export default Badge;

export type {TBadgeColor};
