import styled, {css} from 'styled-components';

import {Label as BaseLabel} from '@edna/components/FormField';

import {OkIcon} from 'src/icons';

export const Label = styled(BaseLabel)`
  margin-bottom: ${({theme}) => theme.spacing.s2};
`;

export const OkGreenIcon = styled(OkIcon)`
  ${({theme: {spacing, branding}}) => css`
    color: ${branding.colors.success};
    flex-shrink: 0;
    margin-right: ${spacing.s2};
  `}
`;
