import {EMessagingLimit, EQualityScore, EQualityStatus, EStatus} from './definitions';

export default {
  channel: 'channel',
  searchPlaceholder: 'Quick search by channel name or ID',
  filters: {
    channelLabel: 'Channel',
    channelStatuses: 'Channel status',
    channels: 'channels',
  },
  status: {
    [EStatus.DRAFT]: 'Draft',
    [EStatus.ACTIVE]: 'Correct',
    [EStatus.PROBLEM]: 'Problem',
    [EStatus.NEEDS_ATTENTION]: 'Requires attention',
    [EStatus.DISABLED]: 'Disabled',
    [EStatus.REGISTRATION]: 'Under consideration',
    [EStatus.CANCELED]: 'Canceled',
  },
  qualityStatus: {
    [EQualityStatus.FLAGGED]: 'Warning',
    [EQualityStatus.RESTRICTED]: 'Limited',
    [EQualityStatus.CONNECTED]: 'Correct',
  },
  th: {
    channelId: 'Subject ID',
    type: 'Type',
    name: 'Name',
    subject: 'Subject',
    status: 'Channel status',
  },
  whatsAppTechPartner: 'Customer WhatsApp channel',
  changeUrl: 'Update link',
  copyUrl: 'Copy link',
  deleteConfirm: 'Are you sure you want to remove the channel "{{name}}"? This cannot be undone.',
  successRegister: {
    head: 'The channel form has been registered!',
    text: 'The registration form has been completed and sent.<br /><br />Now you can see the channel registration statuses in the channel list and on the channel page.',
  },
  empty: {
    title: 'This is empty for now',
    subtitle: 'Here you create channels the system uses to send messages to your clients.',
  },
  editForm: {
    tariffLabel: 'Pricing for the channel',
    tariffPlaceholder: 'Select pricing',
  },
  messages: {
    important: 'Important!',
    needAcceptTariffs:
      'To register a channel, you need to accept the pricing specified on the Pricing tab in <0>Company Profile</0>.',
    noTariffs:
      'You have no available pricing options for this channel, so you cannot register it yet. If you want to register the channel, contact your manager.',
  },
  qualityScoreLabel: 'Channel quality',
  limitLabel: 'Daily limit',
  messagesLimit: 'Daily limit of outgoing messages',
  qualityUpdatedAt: 'Date of limit update',
  activeChannel: 'Correct channel operation',
  restrictedChannel: 'Channel operation is temporarily restricted',
  restrictedChannelDescription:
    'This channel has reached its daily limit of outgoing messages. The channel is only available for incoming requests.',
  flaggedChannel: 'Violations recorded in the channel',
  flaggedChannelDescription:
    'This channel has received many complaints. Daily limits can be reduced.',
  aboutLimits: 'More about limits',
  qualityWarning: 'Daily limits can be reduced',
  flaggedWarning: 'Violations recorded',
  qualityScore: {
    [EQualityScore.GREEN]: 'High',
    [EQualityScore.YELLOW]: 'Medium',
    [EQualityScore.RED]: 'Low',
    [EQualityScore.NA]: 'Unknown',
  },
  limit: {
    [EMessagingLimit.TIER_50]: '50',
    [EMessagingLimit.TIER_250]: '250',
    [EMessagingLimit.TIER_1K]: '1000',
    [EMessagingLimit.TIER_10K]: '10 000',
    [EMessagingLimit.TIER_100K]: '100 000',
    [EMessagingLimit.TIER_UNLIMITED]: 'Unlimited',
    [EMessagingLimit.TIER_NOT_SET]: 'Unknown',
  },
};
