import React from 'react';
import {useTranslation} from 'react-i18next';

import {Modal, ModalHeader, ModalSubmitButton, showModal} from '@edna/components';

import {
  ModalLayout,
  ModalLeftActions,
  ModalLeftContent,
  NoShrinkImage,
} from 'src/components/primitives';
import {TrainerImage} from 'src/images';

const SuccessCreatingTariffModal = React.memo<{closeModal: TAnyFunction}>(({closeModal}) => {
  const {t} = useTranslation();

  const handleContinue = React.useCallback(closeModal, [closeModal]);

  return (
    <Modal width="700px">
      <ModalHeader>{t('Tariffs:successCreatingTariff.title')}</ModalHeader>
      <ModalLayout>
        <ModalLeftContent>
          {t('Tariffs:successCreatingTariff.text')}
          <ModalLeftActions>
            <ModalSubmitButton onClick={handleContinue}>
              {t('Common:button.continue')}
            </ModalSubmitButton>
          </ModalLeftActions>
        </ModalLeftContent>
        <NoShrinkImage as={TrainerImage} />
      </ModalLayout>
    </Modal>
  );
});

export const showSuccessCreatingTariffModal = () =>
  showModal((closeModal) => <SuccessCreatingTariffModal closeModal={closeModal} />);
