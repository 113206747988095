import {EUrgencyType} from './definitions';

export default {
  title: 'Sound, vibration pattern, and LED color',
  urgencyType: {
    [EUrgencyType.PASSIVE]: 'Passive',
    [EUrgencyType.ACTIVE]: 'Active',
    [EUrgencyType.TIME_SENSITIVE]: 'Time-sensitive',
    [EUrgencyType.CRITICAL]: 'Critical',
  },
  urgency: 'Notification interruption level (iOS only)',
  urgencyHint:
    'Notification interruption level defines the notification type for iOS 15 and higher. For more detail, refer here <0>at edna docs</0>',
  addStyle: 'Add a new notification channel',
  styleName: 'Notification channel name for Android',
  styleNameHint:
    'Select a notification channel for Android. Clients will see its name in their device settings. Only clients can change the channel parameters. For more details, refer here <0>{{docs}}</0>',
  addStyleModal: {
    title: 'Add a new Android notification channel',
    name: 'Enter the Android notification channel name',
    sound: 'Device sound for receiving push notifications',
    soundHint:
      'Sound file name (without extension). A file with this name must exist in the res/raw catalog of an Android app and the Xcode root catalog of an iOS app',
    lights: 'LED color for receiving push notifications (Android only)',
    lightsHint:
      'Some Android devices have signal LEDs. You can select a color this LED blinks with when the device receives your push notification',
    vibrate: 'Vibration pattern when receiving a push',
    vibrateHint: `The sequence of intervals of the device motor's inactivity and vibration when it receives your push notification (in milliseconds).
       The first value is inactivity.

       For example, in the [300,500,300,500] pattern, the vibration is off for 300 ms, on for 500 ms, off again for 300 ms, on again for 500 ms.

       Android only.`,
    vibratePlaceholder: '[300,500,300,500]',
  },
};
