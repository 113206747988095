import {EAutoCreditType, EAutoDebitType, ETariffType} from './definitions';

const INIT_RENEWAL_LICENSE_PREFIX =
  'Лицензия на использование Системы edna Pulse с подключением канала';
const WHATSAPP_AMAU_PAYMENT_PREFIX =
  'Переменная часть вознаграждения за Лицензию Системы edna Pulse c функциональностью WA';

export default {
  total: 'Итого за период: ',
  allTransactions: 'Списано <0></0>, зачислено <1></1>',
  debit: 'Списано {{value}}',
  credit: 'Зачислено {{value}}',
  th: {
    date: 'Дата',
    dailyStatement: 'Выписка за день',
    createdAt: 'Дата операции',
    amount: 'Сумма',
    description: 'Описание операции',
  },
  filters: {
    title: 'Фильтры',
    reset: 'Очистить',
    credit: 'Зачисления',
    debit: 'Списания',
    allOperations: 'Все операции',
  },
  button: {
    downloadList: 'Выгрузить список',
  },
  listFileName: 'Выписка.xlsx',
  [ETariffType.SERVICE]: 'Сервисные сообщения',
  [ETariffType.HSM]: 'Рекламные сообщения',
  [ETariffType.MULTI]: 'Мультинейминг',
  item: {
    statement: 'Выписка по операциям (логин {{login}})',
    changed: 'Изменено',
    cancelled: 'Отменена:',
    credit: 'Зачислено – {{value}}',
    debit: 'Списано – {{value}}',
    debitTh: {
      type: 'Тип',
      count: 'Количество',
      amount: 'Сумма',
    },
    creditTh: {
      paymentPurpose: 'Назначение платежа',
      amount: 'Сумма',
      invoice: 'Оплата лицензий и услуг',
    },
    number: ' (номер {{channelAttribute}})',
    [EAutoDebitType.WHATSAPP_ABOVE_MAU_MAP]: `${WHATSAPP_AMAU_PAYMENT_PREFIX}, учетная запись {{subject}}{{number}}.`,
    [EAutoDebitType.WHATSAPP_CONVERSATION]: `Услуги по инициации диалогов посредством Системы edna Pulse, учетная запись {{subject}}{{number}}. Диалоги`,
    [EAutoDebitType.WHATSAPP_LICENSE_INIT]: `${INIT_RENEWAL_LICENSE_PREFIX} Whatsapp (MAU {{mauCount}}), учетная запись {{subject}}{{number}}, {{month}} {{year}}г.`,
    [EAutoDebitType.WHATSAPP_LICENSE_RENEWAL]: `${INIT_RENEWAL_LICENSE_PREFIX} Whatsapp (MAU {{mauCount}}), учетная запись {{subject}}{{number}}, {{month}} {{year}}г.`,
    [EAutoDebitType.WHATSAPP_CONNECTION_FEE_OPERATIONS]: `Подключение номера телефона {{number}} MAU {{mauCount}} за {{month}} {{year}} г.`,
    [EAutoDebitType.SMS_LICENSE_INIT]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EAutoDebitType.SMS_LICENSE_RENEWAL]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EAutoDebitType.VIBER_LICENSE_INIT]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EAutoDebitType.VIBER_LICENSE_RENEWAL]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EAutoDebitType.VIBER_SUBJECTS]: `Услуги по маршрутизации сообщений посредством Системы edna Pulse, учетная запись {{subject}}. {{tariff}}`,
    [EAutoDebitType.VIBER_RECURRENT_PAYMENT]: `Минимальный платеж за услуги передачи сообщений Viber за {{month}} {{year}}г., учетная запись {{subject}}`,
    [EAutoDebitType.SMS_SUBJECTS]: `Услуги по маршрутизации сообщений посредством Системы edna Pulse, альфанумерическая подпись {{subject}}. {{tariff}}`,
    [EAutoDebitType.SMS_RECURRENT_PAYMENT]: `Абонентская плата за использование альфанумерического имени (подписи SMS-сообщения {{subject}}, оператор «{{mobileOperator}}») за {{month}} {{year}}г.`,
    [EAutoDebitType.VIBER_CORRECTION_TRANSACTION_REMOVE]: `Корректировка баланса (учет курсовых разниц)`,
    [EAutoDebitType.WHATSAPP_CORRECTION_TRANSACTION_REMOVE]: `Корректировка баланса (учет курсовых разниц)`,
    [EAutoCreditType.VIBER_CORRECTION_TRANSACTION_ADD]: `Корректировка баланса (учет курсовых разниц)`,
    [EAutoCreditType.WHATSAPP_CORRECTION_TRANSACTION_ADD]: `Корректировка баланса (учет курсовых разниц)`,
  },
};
