import React from 'react';
import {useTranslation} from 'react-i18next';

import {Stack} from '@edna/components/primitives';

import {
  ETemplateButtonColor,
  ETemplateButtonTypes,
  TItemButton,
  TVkNotifyItemContent,
} from 'src/containers/MessageMatchers/types';

import * as SS from '../style';
import {FOOTER_TIME} from '../utils';
import * as S from './style';

type TProps = {
  content: TVkNotifyItemContent;
  text: React.ReactNode;
};

const getButtonColor = (button: TItemButton) => {
  if (
    button.buttonType &&
    [ETemplateButtonTypes.LOCATION, ETemplateButtonTypes.VK_MINI_APPS].includes(button.buttonType)
  ) {
    return ETemplateButtonColor.PRIMARY;
  }

  return button.color ?? ETemplateButtonColor.SECONDARY;
};

const VkNotifyContent = React.memo<TProps>(({content, text}) => {
  const {t} = useTranslation();

  const images = content?.vkAttachments ?? [];
  const buttons = content?.buttons ?? [];

  return (
    <S.Message>
      <SS.MessageContent display="inline-block">
        <Stack size="2">
          <S.TextContainer>
            {text}
            {!images.length && (
              <SS.Time>
                <span>{FOOTER_TIME}</span>
              </SS.Time>
            )}
          </S.TextContainer>
          {!!images.length && (
            <S.Gallery>
              {images.map((attachment, index) => (
                <S.Image
                  bigFirst={images.length === 1 || images.length === 5}
                  key={index}
                  src={attachment?.fileUrl}
                />
              ))}
            </S.Gallery>
          )}
          {buttons.map((button, index) => (
            <S.Button key={index} color={getButtonColor(button)}>
              {button.buttonType === ETemplateButtonTypes.URL && <S.ExternalLinkIcon />}
              {button.buttonType === ETemplateButtonTypes.LOCATION ? (
                <>
                  <S.LocationIcon />
                  <span>{t('MessageMatchers:vkNotify.buttonLocation')}</span>
                </>
              ) : (
                button.text
              )}
            </S.Button>
          ))}
        </Stack>
      </SS.MessageContent>
    </S.Message>
  );
});

VkNotifyContent.displayName = 'VkNotifyContent';

export default VkNotifyContent;
