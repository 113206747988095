import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@edna/components';
import {H2, Stack} from '@edna/components/primitives';

import {TEmbeddedWhatsAppChannel} from 'src/containers/EmbeddedWhatsAppChannel/definitions';
import {TWhatsAppChannel} from 'src/containers/WhatsAppChannel/definitions';
import {useEdnaLinks} from 'src/hooks';

import {ChannelMessagingLimit} from './MessagingLimit';
import {ChannelQualityScore} from './QualityScore';
import * as S from './style';

type TProps = {
  item: TWhatsAppChannel | TEmbeddedWhatsAppChannel;
};

const ChannelRestrictedMessage = React.memo<TProps>(({item}) => {
  const {t} = useTranslation();
  const links = useEdnaLinks();

  return (
    <S.Message>
      <S.StatusIcon name="warning" />
      <S.Content>
        <Stack size="5">
          <Stack size="4">
            <Stack size="2">
              <H2>{t('Channels:restrictedChannel')}</H2>
              <S.Text>{t('Channels:restrictedChannelDescription')}</S.Text>
            </Stack>
            <Stack size="2">
              <ChannelQualityScore item={item} />
              <ChannelMessagingLimit item={item} />
            </Stack>
          </Stack>
          <Button
            as="a"
            href={links.CHANNEL_QUALITY_WA}
            target="_blank"
            rel="noopener noreferrer"
            appearance="primary"
          >
            {t('Channels:aboutLimits')}
          </Button>
        </Stack>
      </S.Content>
    </S.Message>
  );
});

ChannelRestrictedMessage.displayName = 'ChannelRestrictedMessage';

export {ChannelRestrictedMessage};
