import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const Card = styled.div`
  ${mixins.row}
  min-width: 100%;
  ${({theme, theme: {spacing}}) => css`
    margin-top: ${spacing.s6};
    padding: ${spacing.s5};
    border-radius: ${theme.borderRadius.modal};
    box-shadow: ${theme.boxShadow.widget};
  `}
`;

export const CardInfo = styled.div`
  ${mixins.column}
  ${mixins.flexAlign('start')}
`;

export const CardImage = styled.div`
  min-width: 220px;
  ${mixins.flexAlign('center')}
`;

export const Content = styled.div`
  display: flex;
  gap: ${({theme}) => theme.spacing.s5};
  align-items: center;
`;
