import {TCurrency} from '@edna/utils/formatMoney';

import {TPaymentType} from 'src/containers/Licenses/PaymentTab/definitions';

type TPaymentData = {
  operBankAccount: string | null;
  corrBankAccount: string | null;
  bankName: string | null;
  taxNumber: string | null;
  kpp: string | null;
  nonresident: boolean;
  postAddress: string | null;
  legalAddress: string | null;
  siteAddress: string | null;
  country: string | null;
  iban: string | null;
  registrationEmail: string | null;
  authorizedPersonName: string | null;
  vatNumber: string | null;
  bic: string | null;
  bankAddress: string | null;
};

export type TTenantData = {
  paymentData: TPaymentData;
  contacts: {
    email: string | null;
    phone: string | null;
    country: string | null;
  };
  personalManagerId: number | null;
  personalManagerName: string | null;
  personalManagerEmail: string | null;
  personalManagerPhone: string | null;
  supportEmail: string | null;
  companyName: string | null;
  createdAt: string | null;
  needAgreementConfirmation: boolean;
  offerContentUrl: string | null;
  paymentType: TPaymentType | undefined;
  licensePaymentTerm: number | undefined;
  temporaryBlocked: boolean | undefined;
  unblockAt: string | undefined;
  locked: boolean | undefined;
  resellerId: number | undefined;
  defaultCurrency: TCurrency;
};

export type TState = {
  tenantDataLoading: boolean;
  tenantData: TTenantData;
  generatePaymentLinkLoading: boolean;
  paymentLink?: string;
  lowBalanceLimits: Record<string, number>;
  isRelevantNews?: boolean;
};

export enum EUserCompanyProfileTabs {
  PAYMENT = 'payment',
  LICENSE = 'license',
  REQUISITES = 'requisites',
  PRICING = 'pricing',
}

export const COMPANY_PROFILE_TABS = Object.values(EUserCompanyProfileTabs);

export const defaultTenantData: TState['tenantData'] = {
  paymentData: {
    operBankAccount: null,
    corrBankAccount: null,
    bankName: null,
    taxNumber: null,
    kpp: null,
    nonresident: false,
    postAddress: null,
    legalAddress: null,
    siteAddress: null,
    country: null,
    iban: null,
    registrationEmail: null,
    authorizedPersonName: null,
    vatNumber: null,
    bic: null,
    bankAddress: null,
  },
  contacts: {
    email: null,
    phone: null,
    country: null,
  },
  personalManagerId: null,
  personalManagerName: null,
  personalManagerEmail: null,
  personalManagerPhone: null,
  supportEmail: null,
  companyName: null,
  createdAt: null,
  needAgreementConfirmation: false,
  offerContentUrl: null,
  paymentType: undefined,
  licensePaymentTerm: undefined,
  temporaryBlocked: undefined,
  unblockAt: undefined,
  locked: undefined,
  resellerId: undefined,
  defaultCurrency: 'RUB',
};

export enum EClientStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum ETemporaryBlockedTypes {
  BROADCASTS = 'BROADCASTS',
  TEMPLATES = 'TEMPLATES',
  CHANNELS = 'CHANNELS',
  CASCADES = 'CASCADES',
  OTHER = 'OTHER',
}
