import React from 'react';

import difference from 'lodash/difference';

import {TNewNotification, hideNewNotification, showNewNotification} from '@edna/components';

import {SystemNotification} from './SystemNotification';
import {WebNotification} from './WebNotification';
import {
  ENotificationStatus,
  ESystemNotificationEntityType,
  TNotifications,
  TSystemNotification,
  TWebNotification,
} from './model';

const showWebNotification = (
  notification: TWebNotification,
  changeStatus: (status: TWebNotification['status']) => void,
) =>
  showNewNotification({
    id: notification.id,
    lifeDuration: 'infinity',
    onClose: () => changeStatus(ENotificationStatus.READ),
    message: <WebNotification item={notification} />,
    type: notification.level.toLowerCase() as TNewNotification['type'],
  });

const showSystemNotification = (notification: TSystemNotification) =>
  showNewNotification({
    id: notification.id,
    isPermanent: true,
    message: <SystemNotification item={notification} />,
    type: notification.level.toLowerCase() as TNewNotification['type'],
  });

const displayedSystemNotificationIds = new Set<number>();

const manageSystemNotifications = (payload: TNotifications) => {
  const systemNotifications =
    payload.systemNotifications?.filter(
      (item): item is TSystemNotification =>
        item.entityType === ESystemNotificationEntityType.NOTIFICATION,
    ) ?? [];

  if (systemNotifications.length) {
    systemNotifications.forEach((notification) => {
      displayedSystemNotificationIds.add(notification.id);
      showSystemNotification(notification);
    });
  }

  const notificationsToHide = difference(
    [...displayedSystemNotificationIds],
    systemNotifications.map((notification) => notification.id),
  );

  notificationsToHide.forEach(hideNewNotification);
};

export {showWebNotification, showSystemNotification, manageSystemNotifications};
