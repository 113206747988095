export default {
  httpStatus: {
    401: 'Вы не авторизованы',
    403: 'Доступ запрещен',
    404: 'Запрашиваемый ресурс не найден',
  },
  tag: {
    'already-exists': 'Тег с таким названием уже существует',
    query: {
      validation: {failed: 'Некорректные параметры групп рассылки'},
    },
  },
  'tag-category': {
    'cannot-add-tags': 'Невозможно добавить тег к категории у которой есть подкатегории',
  },
  'subscribers-upload': {
    'unsupported-format': 'Поддерживается только формат Excel',
    'not-found': 'Задача загрузки подписчиков не найдена по идентификатору',
    'parameter-not-found': 'Не найден один или несколько параметров',
    'no-phone-column-selected': 'В качестве номера телефона не выбран ни один столбец',
    'multiple-phone-column-selected': 'В качестве номера телефона выбрано несколько столбцов',
    'column-duplicate': 'Существует несколько столбцов с одинаковым типом параметра',
  },
  'viber-profile': {
    save: {
      'wrong-files': 'Загрузите файлы правильного разрешения',
    },
    'file-upload': {
      'logo-is-wrong-size': 'Логотип не подходит ни под один из указанных размеров',
    },
    'not-found': 'Канал Viber не найден',
    'logo-upload': {
      dimensions: 'Неверный размер логотипа',
    },
  },
  'error-unauthorized': 'Вы не авторизованы',
  'error-already-exist': 'Аккаунт с таким email уже существует',
  'sms-profile': {
    'already-exists': 'Канал SMS с таким названием уже существует',
    update: {
      'illegal-status': 'Обновлять канал можно только в статусе "Черновик"',
    },
    'validate-error': {
      'name-not-valid': 'Имя подписи не соответствует требованиям',
    },
  },
  unknownErrorCode: 'Произошла ошибка {{code}}',
  networkError: 'Произошла сетевая ошибка, проверьте Ваше подключение',
  unexpectedError: 'Произошла непредвиденная ошибка',
  segmentCountError: 'Извините, не удалось рассчитать количество сегментов.',
  'error-captcha-challenge': `Указан неверный код с картинки.
   Повторите, пожалуйста, попытку`,
  'error-bad-credentials': 'Указан неверный e-mail или пароль. Повторите, пожалуйста, попытку',
  'user-not-found': 'Указан неверный e-mail или пароль. Повторите, пожалуйста, попытку',
  'confirmation-send-retry': 'Все попытки ввода кода исчерпаны. Запросите код подтверждения заново',
  'confirmation-send-failed-internal':
    'Неудачная отправка кода подтверждения на указанный номер, просьба обратиться в техническую поддержку',
  'registration-title': 'Извините, произошла непредвиденная ошибка',
  'registration-message': 'Повторите, пожалуйста, попытку зарегистрироваться позже',
  registration:
    'Извините, произошла непредвиденная ошибка. Повторите, пожалуйста, попытку зарегистрироваться позже',
  'account-unknown': 'Аккаунт с таким email не зарегистрирован',
  'confirmation-send-blocked':
    'Все попытки восстановления пароля исчерпаны. Пожалуйста, свяжитесь с поддержкой: +7 495 287 0112',
  'confirmation-check-failed': `Неверный код подтверждения.
  Пожалуйста повторите попытку`,
  'confirmation-send-wait':
    'Все попытки ввода кода исчерпаны. Запросить код повторно можно будет позже',
  'check-failed-phone': 'Некорректный номер телефона',
  'confirmation-not-found': 'Не было запрошено восстановление пароля',
  'error-system': 'Произошла неизвестная ошибка сервера',
  'error-temporary-locked': 'К сожалению, вы совершили слишком много ошибок при входе.',
  'error-temporary-locked_0':
    'К сожалению, вы совершили слишком много ошибок при входе. Попробуйте повторить попытку через {{count}} минуту',
  'error-temporary-locked_1':
    'К сожалению, вы совершили слишком много ошибок при входе. Попробуйте повторить попытку через {{count}} минуты',
  'error-temporary-locked_2':
    'К сожалению, вы совершили слишком много ошибок при входе. Попробуйте повторить попытку через {{count}} минут',
  'error-insufficient-data': 'Вы запросили несуществующие данные',
  'error-bad-request': 'Данные, которые вы отправили, некорректны',
  'account-exist': 'An account with this email already exists',
  otp: {
    incorrect: 'Неверный код подтверждения. Пожалуйста повторите попытку',
    'not-found': 'Идентификатор одноразового пароля не найден',
    'too-much-attempts': 'Превышено максимальное количество запросов кода',
  },
  'error-taxNumber-non-updatable': 'Редактирование ИНН не допускается',
  'error-calendar-group-code-not-valid':
    'Некорректный формат кода. Длина от 3 до 100 символов и только латинские буквы, тире и цифры',
  sort: {
    property: {
      incorrect: 'Неверно указано поле для сортировки',
    },
  },
  json: {
    format: {
      error: 'Неверный формат json',
    },
  },
  'saving-file-error': 'Ошибка сохранения файла',
  file: {
    read: {
      error: 'Не удалось прочесть файл',
    },
    'unsupported-format': 'Такой формат файла не поддерживается',
    upload: {
      'document-exceeds-size': 'Размер загруженного документа превышает максимально допустимый',
    },
    'not-found': 'Запрашиваемый ресурс не найден',
  },
  'unknown-broadcast-type-internal': 'Неизвестный тип рассылки',
  'unknown-subject-type-internal': 'Неизвестный тип отправителя',
  'tariffication-configuration-error': 'Извините, не удалось найти пункт назначения',
  'invoice-number-generation-failed-internal': 'Не удалось получить номер счета',
  'notification-eval-failed-internal': 'Не удалось сформировать текст',
  'registration-failed-internal': {
    'reseller-not-found': 'Не найден реселлер по логину',
    'connector-not-found': 'Не найден коннектор по коду',
  },
  'subject-tariff': {
    'subject-not-found': 'Извините, не удалось найти отправителя',
    'not-found-by-subject-id': 'Извините, не удалось найти тариф отправителя',
  },
  'dynamic-tariff': {
    'common-tariff-not-found': 'Извините, не удалось найти общий тариф для пункта назначения',
    'not-found-for-account-id':
      'Извините, не удалось найти динамический тариф для выбранного аккаунта',
  },
  'service-package': {
    'not-found-by-account-id': 'Извините, не удалось найти пакет услуг для выбранного аккаунта',
    'not-found': 'Извините, не удалось найти выбранный пакет услуг',
  },
  broadcast: {
    'not-exists': 'Извините, не удалось найти выбранную рассылку',
    deleted: 'Извините, выбранная рассылка удалена',
    'no-valid-sms-messages': 'Отсутствуют корректные номера абонентов',
    'unrecognized-error':
      'Неизвестная ошибка. За пояснением причины возникновения ошибки обратитесь в службу технической поддержки',
    'incorrect-phone-number': 'Некорректный номер',
    'subject-not-found': 'Имя отправителя не найдено',
    'insufficient-funds': 'Недостаточно средств для отправки сообщения',
    'text-max-length-exceeded': 'Невалидная длина текста рассылки',
    'start-time-expired': 'Время старта рассылки просрочено',
    'confirmation-period-expired':
      'Срок подтверждения истек. Необходимо отправить рассылку повторно',
    'cannot-rate-phone-number': 'Невозможно тарифицировать номер телефона',
    'internal-server-error':
      'Произошла внутренняя ошибка сервера, просьба обратиться в техническую поддержку',
  },
  user: {
    'not-found': 'Извините, не удалось найти выбранного пользователя',
  },
  'calendars-theme-image': {
    'not-found': 'Извините, не удается найти изображение темы календарей по имени',
  },
  'reseller-info': {
    'not-found': 'Извините, не удалось найти выбранного реселлера',
  },
  'account-api-key': {
    'not-found': 'Аккаунт не имеет ни одного активного API-ключа',
  },
  subscriber: {
    'not-found': 'Извините, не удалось найти выбранного подписчика',
    'constraint-violation': {
      address: 'Номер уже был добавлен ранее',
    },
    address: {
      already: {
        exist: 'Извините, клиент с данным номером существует',
      },
    },
  },
  'error-password-validation-failed': 'Пароль не прошел валидацию',
  'error-incorrect-new-password-confirmation': 'Неверное подтверждение нового пароля',
  'error-old-new-password-same': 'Новый пароль совпадает со старым',
  message: {
    'unknown-direction': 'Неверный тип трафика',
    direction: {
      'unknown-direction': 'Неизвестное направление трафика',
    },
    status: {
      details: {
        default: {
          'unrecognized-code':
            'Неизвестная ошибка. За пояснением причины возникновения ошибки обратитесь в службу технической поддержки',
        },
        'insufficient-funds': 'На счете было недостаточно средств для отправки сообщения',
        'absent-subscriber': 'Абонент недоступен',
        'call-barred':
          'Абонент заблокирован либо абонент находится в роуминге, а услуга роуминга у абонента не включена, или у оператора, который доставляет сообщение, нет роумингового соглашения с роуминг-оператором',
        failure:
          'Ошибка на оборудовании сети, в сети или на телефоне абонента - системная ошибка при доставке',
        'memory-capacity-exceeded': 'Память телефона абонента переполнена',
        'teleservice-not-provisioned':
          'Абонент заблокирован либо абонент находится в роуминге, а услуга роуминга у абонента не включена, или абоненту не предоставляется услуга доставки SMS сообщений',
        timeout:
          'За установленное время сообщение не было доставлено абоненту. Возможно, абонент находится в роуминге, и оборудование роуминг-оператора не отвечает оператору, через которого осуществляется доставка, либо ошибки в сети',
        'unknown-subscriber':
          'Неизвестный абонент. Данный номер не зарегистрирован в сети оператора',
        duplicate:
          'Сработала проверка на дубликаты. За короткое время на один и тот же номер направлены одинаковые сообщения',
        filtered: 'Ошибка фильтрации',
        unrouted: 'Ошибка подключения к оператору',
        unknown:
          'Оператор не предоставил отчет о доставке в течение установленного времени ожидания отчета (за 72 часа)',
        rejected: 'Отказано в обработке оператором',
        'cancelled-by-user': 'Рассылка отменена пользователем',
        'declined-by-moderator': 'Отклонена модератором',
        'broadcast-deadline-expired': 'Прием информации по рассылке завершен',
        'invalid-subscriber':
          'Номер некорректен. Например, не мобильный или не относящийся к какой-либо известной нумерации',
        'equipment-protocol-error':
          'Аппарат абонента не принимает SMS из-за сбоя в SIM-карте или программном обеспечении аппарата. Либо произошла аппаратная ошибка в самом телефоне',
        'subject-not-allowed':
          'Оператор отверг сообщение из-за того, что подпись сообщения не разрешена на его стороне',
        'template-not-allowed':
          'Оператор отверг сообщение из-за того, что текст сообщения содержит запрещенные слова либо их сочетание',
        'subscriber-barred-during-mnp':
          'Абоненту после смены SIM-карты на 24 часа оператором были заблокированы SMS-сообщения',
        'timeout-stek':
          'За установленное время сообщение не было доставлено абоненту. Возможно, абонент находится в роуминге, и оборудование роуминг-оператора не отвечает оператору, через которого осуществляется доставка, либо ошибки в сети',
      },
    },
    subject: {
      'max-pending-exceeded':
        'Превышено максимальное количество наименований отправителя, которые мы можем принять на рассмотрение. Пожалуйста, дождитесь рассмотрения ранее отправленных.',
      blacklisted: 'Наименование отправителя недоступно для регистрации',
    },
  },
  validation: {
    password: {
      length: 'Невалидная длина пароля',
      'uppercase-chars-required': 'Пароль должен содержать прописные буквы',
      'lowercase-chars-required': 'Пароль должен содержать строчные буквы',
      'digits-required': 'Пароль должен содержать цифры',
      'special-chars-optional': 'Пароль может содержать спецсимволы',
      'repeated-chars-forbidden':
        'Пароль не должен содержать последовательности повторяющихся символов, например: "aaa"',
      'numeric-sequences-forbidden':
        'Пароль не должен содержать числовые последовательности, например: "123"',
      'whitespace-forbidden': 'Пароль не должен содержать пробельных символов',
      passay: {
        TOO_SHORT: 'Невалидная длинна пароля',
        TOO_LONG: 'Невалидная длинна пароля',
        INSUFFICIENT_UPPERCASE: 'Пароль должен содержать прописные буквы',
        INSUFFICIENT_LOWERCASE: 'Пароль должен содержать строчные буквы',
        INSUFFICIENT_DIGIT: 'Пароль должен содержать цифры',
        ILLEGAL_MATCH:
          'Пароль не должен содержать последовательности повторяющихся символов, например: "aaa"',
        ILLEGAL_NUMBER_RANGE:
          'Пароль не должен содержать числовые последовательности, например: "123"',
        ILLEGAL_WHITESPACE: 'Пароль не должен содержать пробельных символов',
      },
    },
    subject: {
      length: 'Не верная длинна подписи',
      'forbidden-characters': 'Имя отправителя содержит недопустимые символы',
      'phone-number-restrict': 'Номера телефонов не могут использоваться для массовых рассылок',
      duplicate: 'Данный отправитель уже есть в Вашем списке отправителей',
    },
  },
  errors: {
    Size: {
      password: 'Невалидная длина пароля',
    },
  },
  account: {
    'not-found': 'Извините, аккаунт не найден',
  },
  subject: {
    add: {
      'operator-not-found': 'Извините, такого оператора не существует',
    },
    'not-found': 'Извините, отправитель не найден',
    'not-found-by-id': 'Извините, отправитель не найден',
    register: {
      'illegal-status': 'Отправить на регистрацию можно только отправителя в статусе "Черновик"',
    },
    update: {
      'illegal-status': 'Редактировать можно только отправителя в статусе "Черновик"',
    },
  },
  invoice: {
    limitError: 'Извините, указана сумма, менее установленного лимита в {{minSum}}',
    'not-found': 'Извините, счет с Id {{invoiceId}} не найден',
  },
  'subscriber-group': {
    'name-not-unique': 'Извините, группа с таким названием уже существует',
    'brute-force':
      'Большая часть списка содержит номера по-порядку, что говорит о попытке отправки рассылок "перебором" номеров. Рассылка по такому списку будет отклонена',
    'not-found': 'Извините, группа не найдена',
  },
  SubscriberParameter: {
    'already-exists': 'Переменная уже существует',
  },
  template: {
    'constraint-violation': {
      text: 'Такой шаблон текста рассылки уже был добавлен ранее',
    },
    'text-is-empty': 'Текст шаблона не может быть пустым',
    'entity-not-found': 'Извините, шаблон не найден',
    'subject-do-not-match': 'Отправитель не соответствует шаблону',
  },
  calendarGroup: {
    'entity-not-found': 'Извините, группа не найдена',
    'constraint-violation': {
      code: 'Такой код группы уже был добавлен ранее',
    },
    'code-is-empty': 'Код группы не может быть пустым',
    already: {
      block: 'Группа уже заблокирована',
      unblock: 'Группа уже разблокирована',
    },
  },
  calendar: {
    'constraint-violation': {
      code: 'Такой код календаря уже был добавлен ранее',
    },
    'calendarGroup-not-found': 'Извините, код группы не найден',
    'entity-not-found': 'Извините, календарь не найден',
    'link-not-found': 'Извините, общая ссылка не найдена для выбранного календаря',
    already: {
      block: 'Календарь уже заблокирован',
      unblock: 'Календарь уже разблокирован',
    },
  },
  eventCategory: {
    'entity-not-found': 'Извините, категория не найдена',
    'constraint-violation': {
      code: 'Такой код категории уже был добавлен ранее',
    },
    'code-is-empty': 'Код категории не может быть пустым',
    already: {
      block: 'Категория уже заблокирована',
      unblock: 'Категория уже разблокирована',
    },
    has: {
      events: 'Извините, у категории есть предстоящие события',
    },
  },
  eventCategoryGroup: {
    'entity-not-found': 'Извините, группа категорий не найдена',
    error: {
      'constraint-violation': {
        code: 'Такой код группы категорий уже был добавлен ранее',
      },
      'code-is-empty': 'Код группы категорий не может быть пустым',
      'name-is-empty': 'Имя группы категорий не может быть пустым',
      'already-blocked': 'Группа категорий уже заблокирована',
    },
  },
  event: {
    'already-exists': 'Событие уже существует',
    'calendarId-is-empty': 'Идентификатор календаря не может быть пустым',
    'name-is-empty': 'Имя события не может быть пустым',
    'startDate-is-empty': 'Дата начала не может быть пустой',
    'endDate-is-empty': 'Дата окончания не может быть пустой',
    'endDate-before-startDate': 'Дата окончания события не может быть раньше даты начала',
    'eventCategory-not-found': 'Извините, код категории не найден',
    'calendar-not-found': 'Извините, идентификатор календаря не найден',
    'entity-not-found': 'Извините, событие не найдено',
  },
  calendarClient: {
    'entity-not-found': 'Извините, уникальная ссылка не найдена',
  },
  calendarStatistic: {
    'calendarIds-is-empty': 'Фильтр по id календарей не может быть пустым',
  },
  client: {
    'entity-not-found': 'Извините, подписчик не найден',
  },
  serviceMessageTemplate: {
    'constraint-violation': {
      text: 'Такой шаблон сервисных сообщений рассылки уже был добавлен ранее',
    },
    'text-is-empty': 'Текст шаблона сервисных сообщений не может быть пустым',
    'entity-not-found': 'Извините, шаблон сервисных сообщений не найден',
    'subject-list-is-empty': 'Список подписей не может быть пустым',
    'operator-list-is-empty': 'Список операторов не может быть пустым',
    'subject-not-found': 'Извините, имя отправителя не найдено',
    'editing-not-allowed': 'Редактирование доступно только в статусе "Черновик"',
    registration: {
      'illegal-status':
        'Операция отправки на регистрацию возможна только для шаблонов в статусе "Черновик"',
    },
    delete: {
      'illegal-status': 'Удаление возможно только для шаблонов в статусе "Черновик"',
    },
  },
  standaloneEvent: {
    'entity-not-found': 'Извините, событие не найдено',
    clientLink: {
      'entity-not-found': 'Извините, короткая ссылка для выбранного события не найдена',
    },
  },
  eventClient: {
    'entity-not-found': 'Уникальная ссылка на событие не найдена',
    'eventId-is-empty': 'Идентификатор разового события не может быть пустым',
  },
  'facebook-manager-id-service': {
    'creation-failed':
      'Создание канала WhatsApp не удалось из-за некорректного Facebook Manager Id',
  },
  'whatsapp-profile': {
    'delete-file': {
      error: 'Ошибка удаления файла',
    },
    delete: {
      'illegal-status': 'Удалить канал можно только в статусе "Черновик"',
    },
    'update-failed-due-status': 'Обновить канал можно только в статусе "Черновик"',
    'upload-logo': {
      error: 'Ошибка загрузки логотипа',
    },
    'sender-name': {
      empty: 'Имя в WhatsApp не может быть пустым',
    },
    description: {
      'max-size': 'Описание превышает максимального длину',
    },
    'phone-code': {
      'max-size': 'Код страны превышает максимальную длину',
      empty: 'Код страны не может быть пустым',
    },
    phone: {
      'max-size': 'Номер телефона превышает максимальную длину',
      empty: 'Номер телефона не может быть пустым',
    },
    address: {
      'max-size': 'Адрес компании превышает максимальную длину',
      empty: 'Адрес компании не может быть пустым',
    },
    urls: {
      empty: 'Информация о вас в сети не может быть пустой',
    },
    email: {
      'max-size': 'E-mail превышает максимальную длину',
      empty: 'E-mail компании не может быть пустым',
    },
    'logo-url': {
      'max-size': 'Ссылка на логотип превышает максимальную длину',
      empty: 'Ссылка на логотип не может быть пустой',
    },
    'business-kind': {
      'max-size': 'Вид бизнеса превышает максимальную длину',
      empty: 'Вид бизнеса не может быть пустым',
    },
    'facebook-page': {
      url: {
        'max-size': 'URL на страницу Facebook превышает максимальную длину',
      },
    },
    'contact-status': {
      'max-size': 'Статус контакта в WhatsApp превышает максимальную длину',
    },
    'not-found': 'Анкета в WhatsApp не найдена',
    'already-exists': 'Анкета в WhatsApp уже существует',
    'update-failed-due-amount-of-profiles':
      'Вы не можете обновить профиль с более чем 5 анкетами в процессе',
    'exceeded-max-in-progress':
      'Превышено максимальное количество анкет {{maxProfilesWithInProgressStatusCount}}, которые мы можем принять на рассмотрение. Пожалуйста, дождитесь рассмотрения ранее отправленных',
    'registrar-error': 'Неверный Business Manager ID',
    'phone-verification': {
      'too-many-requests':
        'Количество запросов превышено. Пожалуйста, повторите попытку через некоторое время.',
      'unknown-error': 'Не удалось выполнить проверку телефона',
      'cert-not-found':
        'Этот номер телефона не имеет сертификата и не может быть зарегистрирован. Дождитесь получения сертификата.',
      expired: 'Срок действия кода истек',
      'verification-code-expired': 'Срок действия кода истек',
      used: 'Номер телефона уже используется в приложении WhatsApp. Удалите номер из приложения.',
      'unapproved-account': 'Запрашиваемый аккаунт не подтвержден',
      'wrong-country-code': 'Код страны не прошел валидацию. Введите код в формате +код_страны',
      'wrong-verify-code': 'Неверный код',
      'validation-error': 'Не все поля заполнены',
    },
  },
  'whatsapp-business': {
    'manager-id': {
      invalid: 'Неверный Business Manager ID',
    },
  },
  'whatsapp-registrar-rest-service': {
    EXTERNAL_ERROR: 'Что-то пошло не так',
    WRONG_VERIFY_CODE: 'Код не прошел проверку WhatsApp.',
    REQUESTS_MAX_NUMBER_EXCEEDED: 'Превышено максимальное количество запросов к внешней системе',
    EXTERNAL_SERVICE_UNAVAILABLE: 'Внешняя система недоступна',
    ENTITY_NOT_FOUND: 'Сущность не найдена (например, заявка с таким id не существует)',
    WRONG_APPLICATION_STATUS: 'Неправильный статус заявки',
    VALIDATION_ERROR: 'Неправильный формат Facebook Business Manager ID',
    AUTH_ERROR: 'Ошибка авторизации',
    JSON_ERROR: 'Ошибка, связанная с неправильной структурой json',
    INTERNAL_ERROR: 'Внутренняя ошибка приложения',
    'check-facebook-manager-id-failed': 'Неверный Business Manager ID',
    'request-phone-verification-failed': 'Не удалось подтвердить запрос телефона',
    'confirm-phone-verification-failed': 'Не удалось подтвердить проверку телефона',
  },
  'instagram-profile': {
    'not-found': 'Профиль Instagram не найден',
    'already-exists': 'Профиль Instagram с указанным instagramAccountId уже существует',
    'registrar-error': 'Ошибка регистратора Instagram',
    'send-profile.registrar-error': 'Не удалось отправить профиль регистратору Instagram',
  },
  'account-test-address': {
    'not-found': 'Извините, этот телефон не найден',
    'max-count-reached': 'Вы достигли максимального количества тестовых номеров.',
    address: {
      'not-unique': 'Такой тестовый номер уже существует',
      empty: 'Извините, Вы не указали номер телефона',
    },
  },
  hsmTemplate: {
    'constraint-violation': {
      template: 'Извините, вы пытаетесь сохранить неуникальный шаблон',
    },
    'entity-not-found': 'Извините, шаблон не найден',
    'editing-not-allowed':
      'Извините, редактирование разрешено только для шаблонов в статусе "Черновик"',
    'subject-not-found': 'Извините, не найден отправитель',
    registration: {
      'illegal-status': 'Извините, зарегистрировать можно только шаблоны в статусе "Черновик"',
    },
    delete: {
      'illegal-status': 'Извините, удалить можно только шаблоны в статусе "Черновик"',
    },
  },
  'change-password': {
    'incorrect-old-password': 'Старый пароль неверный',
    'old-new-password-same': 'Старый и новые пароли совпадают',
    'new-password-validation-failed': 'Новый пароль не удовлетворяет указанным условиям',
  },
  dlvStatus: {
    'session-not-started': 'Сообщение не соответствует зарегистрированному шаблону',
    'message-undeliverable': 'Не удалось доставить сообщение получателю',
    'spam-rate-limit-hit':
      'Не удалось доставить сообщение, так как многие предыдущие сообщения были заблокированы или отмечены как спам',
    ok: 'Исходящее сообщение успешно принято к отправке',
    'error-system': 'Системная ошибка',
    'error-instant-message-client-id-not-unique':
      'Параметр clientId не уникален в рамках взаимодействия между клиентом и платформой',
    'error-subject-format': 'Некорректный формат подписи сообщения',
    'error-subject-unknown':
      'Указанная подпись не разрешена клиенту в конфигурации платформы провайдера',
    'error-subject-not-specified': 'Подпись сообщения не указана',
    'error-address-format': 'Некорректный формат адреса пользователя',
    'error-address-unknown':
      'В конфигурации платформы клиенту запрещена отправка сообщений на номерную емкость, к которой относится номер абонента',
    'error-address-not-specified': 'Адрес пользователя не указан',
    'error-priority-format': 'Неправильный формат значения приоритета',
    'error-comment-format': 'Неправильный формат значения комментария',
    'error-instant-message-type-format': 'Неправильный формат типа сообщения',
    'error-instant-message-type-not-specified': 'Неправильный формат типа содержимого сообщения',
    'error-caption-too-long': 'Превышена длина названия кнопки',
    'error-content-type-not-specified': 'Не указано поле contentType',
    'error-content-type-format': 'Неправильный формат поля contentType',
    'error-content-format': 'Неправильный формат контента сообщения',
    'error-content-not-specified': 'Не указан параметр контента сообщения',
    'error-content-image-id-or-image-url-not-specified': 'Не указан параметр URL изображения',
    'error-validity-period-seconds-format': 'Неправильный формат значения периода жизни сообщения',
    'error-validity-period-seconds-too-large':
      'Задано недопустимое значение поля validityPeriodSeconds',
    'error-sms-subject-format': 'Неправильный формат подписи резервной СМС',
    'error-sms-subject-unknown': 'Указана неизвестная подпись резервной СМС',
    'error-sms-priority-format': 'Неправильный формат значения приоритета резервной СМС',
    'error-sms-content-not-specified': 'Не указан параметр с текстом резервного СМС',
    'error-sms-comment-format': 'Неправильный формат комментария для резервной СМС',
    'error-sms-not-permitted': 'Отправка резервной СМС не разрешена. Обратитесь в поддержку.',
    'error-content-document-url-not-specified': 'Не указан URL на передаваемый документ/файл',
    'error-content-image-name-not-specified': 'Не указан URL на передаваемое изображение',
    'error-content-document-name-not-specified':
      'Не указано название передаваемого документа/файла',
    'error-content-document-size-not-specified': 'Не указан размер передаваемого документа/файла',
    'error-content-text-format': 'Неправильный формат передаваемого текста сообщения',
    'error-content-latitude-not-specified': 'Не указан параметр широты',
    'error-content-longitude-not-specified': 'Не указан параметр долготы',
    'error-content-location-address-not-specified': 'Не указан адрес передаваемой локации',
    'message-limit-exceeded': 'Исчерпано допустимое количество доставленных сообщений',
    'not-template-match': 'Сообщение не соответствует зарегистрированному шаблону',
    'not-whatsapp-user': 'Пользователь не использует приложение Whatsapp',
    'error-destination-not-allowed':
      'Направление не подключено. Обратитесь в службу тех. поддержки',
    'error-phone-not-for-testing':
      'Отправлено сообщение от тестовой подписи на номер телефона, которого нет в списке тестовых номеров телефонов',
    'not-viber-user': 'У пользователя не установлено приложение Viber',
    'absent-subscriber': 'Абонент недоступен.',
    'call-bared':
      'Абонент заблокирован, либо абонент в роуминге, а услуга роуминга не включена. Либо абонент в роуминге, но у оператора, который доставляет сообщение, нет роумингового соглашения с роуминг-оператором.',
    'call-barred':
      'Абонент заблокирован, либо абонент в роуминге, а услуга роуминга не включена. Либо абонент в роуминге, но у оператора, который доставляет сообщение, нет роумингового соглашения с роуминг-оператором.',
    'error-out-message-client-id-not-unique':
      'у каждого исходящего смс в hpx коннекторе есть параметр clientId и он должен быть уникальным в рамках периода хранения кэша. Период хранения кэша определяется настройками коннектора. Если же clientId оказался неуникальным, то выдаётся эта ошибка',
    failure:
      'Ошибка на оборудовании сети или в сети или на телефоне - системная ошибка при доставке. В данный общий код “failure” входит комплекс ошибок, связанных со сбоями ТА, sim-карты абонента, оборудования сети операторов, и тд.Таким образом, если не фиксируется массовых проблем с доставкой сообщений, причиной недоставки конкретному абоненту носит локальный характер, вызванный сбоем ТА или оборудования на стороне оператора.',
    'memory-capacity-exceeded': 'Память телефона переполнена.',
    'subject-not-allowed':
      'Оператор отверг сообщение из-за того, что подпись сообщения не разрешена на его стороне.',
    'subscriber-barred-during-mnp':
      'Абоненту был заблокирован смс траффик на 24 часа оператором после смены сим-карты',
    'teleservice-not-provisioned':
      'Абонент заблокирован, либо абонент в роуминге, а услуга роуминга не включена. Либо абоненту не предоставляется услуга доставки SMS сообщений.',
    'unknown-subscriber': 'Неизвестный абонент. Данный номер не зарегистрирован в сети оператора',
    unrouted:
      'На нашей платформе не удалось найти канал, через который можно было бы доставить сообщение.',
  },
  'push-style': {
    saving: {
      'already-exists': 'Канал с таким именем уже существует. Введите другое имя канала',
    },
    'not-exists': 'Извините, не удалось найти канал',
  },
  'push-app': {
    ios: {
      'certificate-subject-validation-error':
        'Bundle ID сертификата не совпадает с указанным в шаге 1.3.',
      'certificate-processing-error': 'Указан некорректный сертификат',
      'certificate-expired': 'Истек срок действия сертификата.',
      'has-not-certificate': 'В загруженном файле p12 нет сертификата.',
      'password-incorrect': 'Введен неправильный пароль от файла p12.',
      'password-contain-not-ascii': 'Пароль содержит символы не из ASCII и не может быть применен.',
      'app-already-exist':
        'Вы пытаетесь добавить приложение, которое уже существует в одном из ваших пуш-каналов.',
    },
    android: {
      'invalid-service-json':
        'Файл JSON содержит не все необходимые атрибуты. Загрузите другой файл',
    },
  },
  balance: {
    'not-found': 'Извините, баланс не найден',
  },
  tenant: {
    'test-phone': {
      create: {
        failed: {
          error: 'Номер телефона не может быть использован для тестирования',
          'limit-reached':
            'Номеров телефонов для тестирования не может быть больше, чем {{maxCount}} штук.',
        },
      },
    },
  },
  'error-phone-not-for-testing':
    'Не доставлено по причине отсутствия номера телефона в списке тестовых номеров',
  connection: {
    error:
      'Непредвиденная ошибка при попытке подключения URL. Неверный URL-адрес или, если вы уверены, что это не так, попробуйте позже.',
  },
  'message-matcher-name-already-exists': 'Шаблон с таким названием уже существует.',
  'message-matcher': {
    saving: {
      'bad-request': 'Шаблон с таким именем уже существует.',
      'already-exists': '{{type}} шаблон с таким же контентом уже существует.',
    },
    'name-exists': 'Шаблон с таким названием уже существует. Невозможно сохранить.',
  },
  callback: {
    url: {
      'not-available-error': 'Callback URL недоступен',
    },
    jivo: 'Нет активных подписей WhatsApp',
  },
  'request-id-not-unique': 'requestId неуникальный',
  'upload-document': {
    'already-exists': 'Документ с таким именем и чек-суммой уже существует',
  },
  'tenant-lead.in-accepted-status': 'Ссылка уже была активирована',
  'tenant-offer': {
    'not-found': 'Оферта не найдена',
  },
  violations: {
    tariffTemplateId: 'В учетной записи нет тарифа. Обратитесь в службу поддержки',
  },
};
