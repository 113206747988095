import {EAudienceGroupState} from 'src/constants';

import {EFormStep, EStatus, ESummary} from './definitions';

export default {
  createBroadcast: 'рассылку',
  actions: {
    cancelBroadcast: 'Отменить рассылку',
    returnToDrafts: 'Вернуть в черновики',
  },
  details: {
    actions: 'Действия с рассылкой',
    statusAt: {
      [EStatus.SCHEDULED]: 'Последнее обновление в {{date}}',
      [EStatus.RUNNING]: 'Стартовала в {{date}}',
      [EStatus.COMPLETED]: 'Выполнена в {{date}}',
      [EStatus.CANCELLED]: 'Отменена в {{date}}',
      [EStatus.TRIGGERED]: 'Стартовала в {{date}}',
      [EStatus.CANCEL_REQUESTED]: 'Отменена в {{date}}',
      [EStatus.CANCELING]: 'Отменена в {{date}}',
      [EStatus.EXPIRED]: 'Просрочена {{date}}',
      [EStatus.INCOMPLETE]: 'Отменена в {{date}}',
      [EStatus.SUSPEND_REQUESTED]: 'Отложена в {{date}}',
      [EStatus.SUSPENDING]: 'Отложена в {{date}}',
      [EStatus.SUSPENDED]: 'Отложена в {{date}}',
    },
    nameLabel: 'Название рассылки',
    summaryTitle: 'Статистика рассылки',
    stagesSummaryTitle: 'Статистика по шагам каскада',
    requestedAt: 'Данные на {{date}}',
    statistics: {
      [ESummary.totalCount]: 'Отправлено сообщений',
      [ESummary.deliveredCount]: 'Доставлено сообщений',
      [ESummary.readCount]: 'Прочитано сообщений',
      [ESummary.undeliveredCount]: 'Не доставлено сообщений',
    },
    th: {
      name: 'Шаг каскада/канал',
      sentCount: 'Отправлено в канале',
      deliveredCount: 'Получили в канале',
      readCount: 'Прочитали в канале',
      undeliveredCount: 'Не получили (просрочено)',
    },
    report: 'Отчет по сообщениям',
  },
  allEmpty: {
    title: 'Terra incognita! Неизведанная земля!',
    subtitle:
      'Сюда еще никто не заходил чтобы создать рассылку. Всего пару подготовительных шагов, и вы будете первым!',
    listTitle: 'Что нужно для начала?',
    listItem1: 'Зарегистрировать на платформе хотя бы один <0>канал коммуникации</0>',
    listItem2:
      'Если канал работает только с шаблонами, зарегистрировать хотя бы один <0>шаблон для канала</0>',
    messageMatchersButton: 'В шаблоны',
    channelsButton: 'В каналы',
  },
  empty: {
    title: 'Вы первопроходец!',
    subtitle: 'Здесь пока еще нет рассылок, но вы легко это исправите!',
    listTitle: 'Проверим, что у вас уже есть все необходимое для начала:',
    listItem1: 'Хотя бы один зарегистрированный <0>канал коммуникации</0>',
    listItem2: 'Если канал работает только с шаблонами, для него есть хотя бы один <0>шаблон</0>',
    createButton: 'Создать рассылку',
  },
  th: {
    startPlannedAt: 'Дата запуска',
    name: 'Название рассылки',
    status: 'Статус рассылки',
    audience: 'Аудитория рассылки',
    receivedMessagesNumber: 'Получили сообщения',
    openedMessagesNumber: 'Открыли сообщения',
    notReceivedMessagesNumber: 'Не получили',
  },
  td: {
    cascade: 'Каскад: {{name}}',
  },
  status: {
    [EStatus.DRAFT]: 'Черновик',
    [EStatus.SCHEDULED]: 'Запланирована',
    [EStatus.TRIGGERED]: 'Запланирована',
    [EStatus.RUNNING]: 'Выполняется',
    [EStatus.CANCELING]: 'Отменяется',
    [EStatus.CANCEL_REQUESTED]: 'Отменяется',
    [EStatus.COMPLETED]: 'Завершена',
    [EStatus.CANCELLED]: 'Отменена',
    [EStatus.INCOMPLETE]: 'Остановлена',
    [EStatus.SUSPENDED]: 'Остановлена',
    [EStatus.SUSPEND_REQUESTED]: 'Остановка запрошена',
    [EStatus.SUSPENDING]: 'Останавливается',
    [EStatus.EXPIRED]: 'Просрочена',
  },
  confirm: {
    cancelTitle: 'Хотите отменить рассылку?',
    cancelBody:
      'Если вы сейчас нажмете "да", то выбранная вами рассылка будет остановлена, и запустить ее заново вы не сможете, придется создавать заново',
    delete: 'Вы уверены, что хотите удалить рассылку "{{name}}" без возможности восстановления?',
  },
  steps: {
    [EFormStep.SETTINGS]: 'Настройки рассылки',
    [EFormStep.AUDIENCE]: 'Аудитория рассылки',
    [EFormStep.CONTENT]: 'Содержание рассылки',
    [EFormStep.CHECK]: 'Проверка рассылки',
  },
  messageSpeedOptions: {
    perSecond_one: '{{count}} сообщение в секунду',
    perSecond_few: '{{count}} сообщения в секунду',
    perSecond_many: '{{count}} сообщений в секунду',
    defaultPrefix: 'По умолчанию - ',
  },
  settingsForm: {
    name: 'Название рассылки',
    nameHint: 'Название будет использоваться в списке рассылок и в отчете по сообщениям',
    startPlannedAt: 'Дата и время отправки рассылки',
    startNow: 'Отправить сразу',
    startPlannedAtCustom: 'Задать дату и время',
    timeZone: 'Часовой пояс при отправке сообщений',
    timeZoneHint:
      'Ваш текущий часовой пояс {{timeZone}}. Если вы планируете рассылку в другом часовом поясе,\nвыберите его из списка ниже. Результаты рассылки будут отображаться в вашем часовом поясе',
    timePeriod: 'Временной диапазон, в который пользователь получит рассылку',
    timePeriodHint: 'Часы в течение дня, в которые разрешено отправлять рассылку',
    timePeriodOff: 'Не устанавливать',
    timePeriodOn: 'Установить свой диапазон, не учитывать местное время получателей',
    timePeriodOnHint:
      'Мы отправим рассылку в указанном диапазоне согласно настройке часового пояса в профиле {{timeZone}}',
    timePeriodOnWithTimezoneHint:
      'Мы отправим рассылку в указанном диапазоне согласно настройке часового пояса рассылки {{timeZone}}',
    timePeriodOnByLocal: 'Установить свой диапазон и учитывать местное время получателей',
    timePeriodOnByLocalHint:
      'Если мы не сможем определить местное время получателя, то отправим рассылку в указанном диапазоне согласно настройке\nчасового пояса в профиле {{timeZone}}',
    timePeriodOnByLocalWithTimezoneHint:
      'Если мы не сможем определить местное время получателя, то отправим рассылку в указанном диапазоне согласно настройке\nчасового пояса рассылки {{timeZone}}',
    messageSpeed: 'Скорость отправки сообщений в рассылке',
    flowIntegration: 'Интеграция с триггерными цепочками',
    flowIntegrationHint:
      'Включите, если какая-либо из ваших триггерных цепочек реагирует на или инициируется исходящим сообщением',
    comment: 'Дополнительная информация о рассылке',
    commentHint:
      'Комментарий, мета-информация о рассылке (необязательно). Созданный комментарий будет\nпередан в callback вместе со статусом доставки сообщения. Это могут быть любые символы и\nпеременные',
  },
  contentForm: {
    title: 'Содержание рассылки и последовательность отправки сообщений',
    cascade: 'Выберите каскад, по правилам которого  будет создана рассылка',
    cascadeRules: 'Правила выбранного каскада',
    contentTitle: 'Содержание сообщений по каналам',
  },
  smsForm: {
    findMatch: 'Проверить на соответствие шаблону',
    templateMatch: {
      found: 'Шаблон зарегистрирован.',
      foundHint:
        'Введенный текст соответствует зарегистрированному шаблону. Тарификация дешевле у следующих операторов:',
      foundPartial: 'Шаблон частично зарегистрирован.',
      foundPartialHint:
        'Сообщения, не соответствующие шаблону, тарифицируются следующими операторами как рекламные:',
      notFound: 'Шаблон не найден в системе.',
      notFoundHint:
        'Сообщения, не соответствующие шаблону, тарифицируются операторами как рекламные.',
      otherHint:
        'У остальных операторов сообщения не  соответствуют шаблону и тарифицируются как рекламные',
    },
    phoneNumberHint:
      'Если вы попробуете отправить рассылку на номера разных операторов, вы сможете посмотреть стоимость сообщений в итоговом отчете. Рассылка платная.',
    subject: 'Имя отправителя',
    messageMatcherId: 'Выберите шаблон (необязательно)',
    defaultMessageMatcherId: 'Без шаблона',
    text: 'Текст сообщения',
    textHint:
      'Вы можете обратиться к получателю сообщения по имени, указав в тексте сообщения тег {имя} или {name}. Например, «Добрый день, {имя}! Приятного дня». Если в списке получателей будет заполнено имя, то SMS будет вида «Добрый день, Юлия! Приятного дня». Если имя не будет заполнено, то тег будет пропущен.',
    operatorsApproved:
      'Сообщения для активного операторского шаблона стоят дешевле. Шаблон активен у следующих операторов:',
    operatorsPending:
      'После регистрации операторского шаблона тарификация дешевле у следующих операторов (до регистрации сообщения тарифицируются как рекламные):',
    operatorsRejected:
      'После отклонения операторского шаблона сообщения тарифицируются как рекламные. Шаблон отклонен у следующих операторов:',
  },
  testForm: {
    recipient: 'Протестировать рассылку',
    recipientPlaceholder: 'Добавить номер для тестирования',
    sendTest: 'Отправить тест',
    sendMessage: 'Отправить сообщение',
    byPhone: 'на номер: {{phone}}',
    pushRecipient: 'Протестировать пуш',
    pushRecipientHint: 'Выберите клиентов для тестирования и отправьте пуш-уведомления',
    sendPushTest: 'Отправить тестовый пуш',
  },
  viberForm: {
    accountName: 'Аккаунт отправителя рассылки',
    messageMatcherId: 'Выберите шаблон (необязательно)',
    defaultMessageMatcherId: 'Без шаблона',
    text: 'Текст сообщения',
    textHint:
      'Вы можете обратиться к получателю сообщения по имени, указав в тексте сообщения тег {имя} или {name}. Например, «Добрый день, {имя}! Приятного дня». Если в списке получателей будет заполнено имя, то SMS будет вида «Добрый день, Юлия! Приятного дня». Если имя не будет заполнено, то тег будет пропущен.',
    type: 'Выберите тип сообщения',
    imageLabel: 'Добавьте картинку',
    buttonLabel: 'Название кнопки',
    buttonLinkLabel: 'Ссылка кнопки',
    optional: ' (необязательно)',
    uploadDocument: 'Загрузить с компьютера',
    addDocumentLink: 'Вставить ссылку на файл',
    documentLabel: 'Загрузить файл',
    documentUrlLabel: 'Адрес ссылки на файл',
    documentUrlHint:
      'Поддерживаемые форматы вложений: текстовые файлы (.doc, .docx, .txt, .rtf, .dot, .dotx, .odt, odf, .fodt, .info), PDF, Excel (.xls и .xslx) и графические файлы (.xps, .pdax, .eps). Максимальный размер файла 25Мб',
  },
  notifyForm: {
    accountName: 'Аккаунт отправителя рассылки',
    messageMatcherId: 'Название шаблона',
    defaultMessageMatcherId: 'Без шаблона',
  },
  whatsAppForm: {
    text: 'Текст сообщения',
    accountName: 'Аккаунт отправителя рассылки',
    messageMatcherId: 'Шаблон',
    headerAttachment: 'Вложение для заголовка',
    invalidHeaderAttachmentFormat: 'Неверный формат вложения',
    uploadFile: 'Загрузить с компьютера',
    addFileLink: 'Вставить ссылку',
    fileLabel: 'Загрузить файл',
    fileUrlLabel: 'Адрес ссылки на файл',
    hasHeader: 'Добавить заголовок',
    headerType: 'Тип',
    header: 'Содержание',
    addFooter: 'Добавить подпись сообщения',
    addButtons: 'Добавить кнопки',
    chatButtons: 'Кнопки чата',
    noChatButtons: 'Кнопки ссылка/звонок',
    imageUrlHint:
      'Поддерживаемые форматы изображений: JPEG, PNG. Максимальный размер изображения -1600x1200px, до 2Мб',
    documentUrlHint:
      'Поддерживаемые форматы вложений: текстовые файлы, PDF, Excel (.xls и .xslx). Максимальный размер файла - 100 МБ',
    videoUrlHint: 'Поддерживаемые форматы видеофайлов (MP4). Максимальный размер файла - 16 МБ',
    buttonFields: {
      textLabel: 'Текст',
      urlLabel: 'Ссылка',
      urlHint: 'Разрешено использовать только https ссылки',
      payloadLabel: 'Код',
      payloadHint: 'Используйте этот код, чтобы отслеживать нажатие кнопок через API',
      phoneLabel: 'Номер телефона',
      staticUrlLabel: 'Ссылка кнопки (статическая часть ссылки)',
      dynamicUrlLabel: 'Динамическая часть ссылки',
      dynamicUrlHint:
        'Для динамической части ссылки укажите ее значение без символа "/". Пример: <b>action234</b>. Чтобы сделать ссылку уникальной используйте переменные. Переменные добавляются <b>строго</b> в конец ссылки.',
    },
    buttonTypes: {
      PHONE: 'Кнопка-звонок',
      URL: 'Кнопка-ссылка',
    },
    message:
      'В этом шаблоне нужно вручную ввести динамическую часть ссылки вашей кнопки (дописать недостающую часть ссылки и/или вставить переменную)',
  },
  pushForm: {
    messageMatcherId: 'Выберите шаблон',
    defaultMessageMatcherId: 'Без шаблона',
    header: 'Заголовок сообщения',
    headerHint:
      'Заголовок отображается на устройстве в одну строку. Максимальная длина примерно равна 50 символам (ограничения Андроид)',
    text: 'Введите текст рассылки и переменные',
    textHint:
      'Вы можете обратиться к получателю сообщения по имени, указав в тексте сообщения тег {имя} или {name}. Например, «Добрый день, {имя}! Приятного дня». Если в списке получателей будет заполнено имя, то SMS будет вида «Добрый день, Юлия! Приятного дня». Если имя не будет заполнено, то тег будет пропущен.',
  },
  checkForm: {
    title: 'Проверьте данные рассылки перед отправкой',
    name: 'Название рассылки',
    startDateAndTime: 'Дата и время отправки',
    sendSettings: 'Настройки отправки',
    unknown: 'Неизвестно',
    notSet: 'Не установлено',
    date: 'Дата',
    time: 'Время',
    startNow: 'Отправить сразу',
    broadcastDuration: 'Продолжительность рассылки',
    timePeriod: 'Время получения сообщений получателем',
    countingRecipients: 'Рассчитывается...',
    numberOfRecipients: 'Количество получателей',
    uploadSubscribers: 'Аудитория еще загружается. <0>Посмотреть прогресс</0>',
    includedRecipients: 'Включенные  получатели',
    excludedRecipients: 'Исключенные получатели',
    messageSpeed: 'Интервал отправки сообщений',
    previewTitle: 'Вид сообщения',
    cascade: 'Каскад рассылки',
    additionalInfo: 'Дополнительная информация',
    comment: 'Комментарий, мета-информация',
  },
  audienceForm: {
    [EAudienceGroupState.included]: 'Включить получателей с тегами',
    [EAudienceGroupState.excluded]: 'Исключить получателей с тегами',
    subscribers: 'получателей',
    tags: 'Доступные теги',
    tagsSearch: 'Поиск по тегам',
    emptyTags: {
      [EAudienceGroupState.included]: 'Добавьте теги включенной аудитории',
      [EAudienceGroupState.excluded]: 'Добавьте теги исключенной аудитории',
    },
    group: 'Группа',
    addGroup: 'Добавить еще группу',
    groupOperator: 'Оператор внутри группы',
    groupsOperator: 'Оператор между группами',
    uploadSubtitle:
      'Загружаем и обновляем вашу аудиторию, это займет какое-то время.\n' +
      'Вы можете покинуть страницу — процесс будет выполняться. \n' +
      'Актуальный статус загрузки можно узнать на <0>этой странице</0>',
  },
  sendConfirmation: {
    title: 'Отправляем рассылку?',
    message: 'Все проверено и все готово, можно отправлять!',
  },
  planingConfirmation: {
    title: 'Планируем рассылку?',
    message: 'Все проверено и все готово, дальше рассылка отправится сама в нужное время!',
  },
  countdown: {
    title: 'Отправляем рассылку',
    message1: 'Рассылка отправится через 5 секунд.',
    message2:
      'У вас есть 5 секунд, чтобы передумать и поправить, прежде чем рассылка уйдет получателям',
  },
  sentAlert: {
    title: 'Ваша рассылка отправлена!',
    message1: 'Ваши пользователи получат рассылку в течение {{value}}',
    message2: 'Статус рассылки и данные по ней смотрите в карточке рассылки',
  },
  returnToDraftsConfirm: {
    title: 'Вернуть рассылку в черновики?',
    message:
      'Если вы нажмете "да", то эта запланированная рассылка перейдет в статус "Черновик", и, соответственно, не будет отправлена',
  },
  selectTemplate: {
    title: 'Использовать шаблон',
  },
  plannedAlert: {
    title: 'Ваша рассылка запланирована!',
    message1: 'Ваши пользователи начнут получать рассылку в указанные вами день и время',
    message2: 'Статус рассылки и данные по ней смотрите в карточке рассылки',
  },
  plannedError: {
    title: 'Ошибка при планировании рассылки',
    message: `При проверке данных рассылки мы обнаружили ошибку, которая не дает запланировать рассылку.<br/>Скопируйте код возникшей ошибки и передайте в службу поддержки:`,
    copyErrorCode: 'Скопировать код ошибки',
  },
  loadingAudienceModal: {
    title: 'Аудитория рассылки еще не загружена',
    subtitle: 'Рассылку можно будет отправить, когда весь список аудитории будет загружен',
  },
  notification: {
    testSentSuccess: 'Тестовое сообщение успешно отправлено',
    returnToDraftsSuccess: 'Рассылка возращена в черновики',
  },
  error: {
    failedToTest: 'Извините, не удалось протестировать рассылку.',
    failedToCancel: 'Извините, не удалось отменить рассылку.',
  },
  and: 'И',
  or: 'Или',
  invalidDateAndTime: 'Проверьте дату и время отправки рассылки',
  invalidMessageSpeed: 'Проверьте интервал отправки сообщений',
  hasInvalidRecipients: 'В аудитории есть ошибка. Рассылку никто не получит',
  invalidContent: 'В содержании рассылки для выделенного канала есть ошибка',
  invalidEmptyCascade: 'Каскад не выбран',
  startsNow: 'Сразу после подтверждения отправки',
  template: 'Шаблон',
  balanceProblem: {
    title: 'Вы не можете отправить рассылку',
    message1:
      'Сейчас средств на вашем счету недостаточно для отправки рассылки. Чтобы снова отправлять рассылки вам нужно пополнить баланс. Не волнуйтесь, мы в любом случае сохраним эту рассылку как черновик',
    message2:
      'Сейчас размера вашего овердрафта недостаточно для отправки рассылки. Чтобы решить вопрос с вашим балансом и снова отправлять рассылки, свяжитесь с вашим менеджером. Мы в любом случае сохраним эту рассылку как черновик',
    topUp: 'Пополнить баланс',
    writeManager: 'Написать менеджеру',
  },
  filters: {
    searchPlaceholder: 'Быстрый поиск по названию рассылки',
    broadcastStartedDate: 'Дата запуска рассылки',
    broadcastStatus: 'Статус рассылки',
    channelType: 'Канал',
    broadcastName: 'Название рассылки',
    cascadeName: 'Название каскада',
    channelName: 'Название канала',
    subscribersTags: 'Теги получателей рассылки',
    selectTags: 'Выбрать теги',
    comment: 'Комментарий (мета-информация) о рассылке',
    trafficType: 'Тип трафика',
    commentFilled: 'Заполнен',
    trafficHint: 'Комментарий указывается в настройках рассылки',
    commentHint:
      'Созданный комментарий будет передан в callback вместе со статусом доставки сообщения. Это могут быть любые символы и переменные',
    broadcasts: 'Рассылок',
  },
};
