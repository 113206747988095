import {TData, TPayload} from 'src/components/List';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models/api';

import {TItem} from './definitions';

export const parametersApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createParameter: builder.mutation<TItem, TItem>({
      query: (data) => ({
        method: 'POST',
        url: '/subscriber/parameter',
        data,
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToSaveItem',
          isShowSuccess: true,
          successMessageKey: 'List:notification.saveSuccess',
        },
      }),
      invalidatesTags: [ECacheTag.PARAMETERS],
    }),
    getAllParameters: builder.query<TItem[], void>({
      query: () => ({
        method: 'GET',
        url: '/subscriber/parameter',
        meta: {isShowError: true},
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: (result) => {
        if (!result) {
          return [ECacheTag.PARAMETERS];
        }

        return [ECacheTag.PARAMETERS, ...result.map(({id}) => ({id, type: ECacheTag.PARAMETERS}))];
      },
    }),
    getParameters: builder.query<TData<TItem>, TPayload>({
      query: (data) => ({
        method: 'POST',
        url: '/subscriber/parameter/list',
        data,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {useCreateParameterMutation, useGetAllParametersQuery, useGetParametersQuery} =
  parametersApi;
