import {EAudienceGroupState} from 'src/constants';

import {ETab} from './EditForm/TagsParametersAddresses/definitions';

export default {
  createClient: 'client',
  uploadList: 'list',
  editForm: {
    title: 'User data',
    fields: {
      lastName: 'Last name',
      firstName: 'First name',
      surName: 'Middle name',
      gender: 'Gender',
      birthDate: 'Date of birth',
      phone: 'Phone',
      blacklisted: 'Do not send any messages to this client',
    },
    placeholders: {
      lastName: 'Not specified',
      firstName: 'Not specified',
      surName: 'Not specified',
      gender: 'Not specified',
      birthDate: 'Enter a date',
      phone: 'Enter a number',
    },
    tag: 'Tag',
    [ETab.TAGS]: {
      head: 'User tags',
      content: {
        title: 'User tags',
        action: 'Assign tag',
        notification: 'Tags have been successfully added',
      },
      empty: {
        title: "This client doesn't have any tags yet",
        text: 'User tags can be added immediately or later, when you have more information about the client',
        action: 'Assign tag',
      },
    },
    [ETab.PARAMETERS]: {
      head: 'Variables',
      content: {
        title: 'User variables',
        action: 'Assign variable',
        nameLabel: 'Variable',
        valueLabel: 'Value of the variable',
      },
      empty: {
        title: "This user doesn't have any variables yet",
        text: 'Sorry, there are no distinctive client characteristics yet. Would you like to add one?',
        action: 'Add variable',
      },
    },
    [ETab.ADDRESSES]: {
      head: 'Identifiers',
      content: {
        title: 'Identifiers in channels',
        type: 'Type',
        name: 'Name',
        id: 'ID',
      },
      channelIdentifier: 'Identifier in channel',
      systemIdentifier: 'Identifier in system',
      empty: {
        title: "This client doesn't have any identifiers yet",
        text: 'You can add them immediately or later when you have more information about it',
        action: 'Add identifier',
      },
    },
  },
  editGroupForm: {
    title: 'Upload document',
    subtitle: `You can add a new list and tag it to the already selected client audience. Once the upload is finished, you will be able to select whether the audience with this tag must be included in the broadcast or excluded from it.<br/><br/>Uploading a list of new clients might take some time.`,
    pendingHint: `<0>Please do not leave the page.</>
    If you leave the page, your progress will not be saved.`,
    fileButton: '.xlsx',
    anotherFileButton: 'Upload another document',
    chooseTag: 'Choose tags',
    tag: 'tag',
    chooseFromTagTree: 'Select tags from the tree',
    tagIds: 'Assign tags to your list',
    tagIdsHint: 'Select the tags that will be applied to the list',
    variable: 'variable',
    variableIds: 'Assign variables to the clients in the list',
    variableIdsHint: 'Select a variable name for each column',
    chooseVariable: 'Choose variable',
    editTitle: 'Edit list',
    uploadTitle: 'Client list is uploading',
    uploadSubtitle: `Uploading and updating your audience is going to take some time.
    <0>You don't have to stay on this page.</0> The process will continue if you open another page..
    Come back to <1>this page</1> to check the current upload status.`,
    errorLoadingFile:
      'Client list upload was interrupted. The list of the clients {{fileName}} has not been uploaded.',
  },
  editGroupFormModal: {
    title: 'Upload document',
    subtitle: `You can add a new list and tag it to the already selected client audience. Once the upload is finished, you will be able to select whether the audience with this tag must be included in the broadcast or excluded from it.<br/><br/>Uploading a list of new clients might take some time.`,
    fileButton: 'Attach file',
    anotherFileButton: 'Attach another file',
  },
  pickAudienceForm: {
    title: 'What to do with this audience?',
    subtitle: {
      tagsChosen: 'The audience with these tags must be:',
      tagsNotChosen:
        'You uploaded a list of clients and did not assign any tags to it. We have assigned a tag with the file name to this list. You can find the list in the Broadcast Lists category.',
    },
    whichGroup: 'To which group should the audience belong?',
    includeToBroadcast: 'Added to the broadcast',
    excludeFromBroadcast: 'Excluded from the broadcast',
    button: {
      continue: 'Continue',
      back: 'Back',
    },
    group: 'Group',
    tagWarning: {
      [EAudienceGroupState.excluded]:
        'The audience with this tag is already included in the broadcast. You cannot exclude it from the broadcast at the same time.',
      [EAudienceGroupState.included]:
        'The audience with this tag has already been excluded from the broadcast. You cannot simultaneously include it while it is excluded.',
    },
  },
  modal: {
    uploadingTitle: 'Client list loading progress',
    resultTitle: 'Client list loading result',
    successInfoWithFile:
      'Successfully <b>uploaded {{successCount}}</b> out of {{totalCount}} records, or <b>{{percent}}%</b> of the list {{fileName}}',
    successInfo:
      'Successfully <b>uploaded {{successCount}}</b> out of {{totalCount}} records, or <b>{{percent}}%</b> of the list',
    errorInfo_one: '. {{errorCount}} record was not uploaded. <0><b>Download the list</b></0>',
    errorInfo_other: '. {{errorCount}} records was not uploaded. <0><b>Download the list</b></0>',
  },
  error: {
    atLeastOnePhone: 'Select the phone number column',
    atLeastOnePhoneMessage:
      'One of the columns in the list must have the header "Phone" and contain phone numbers',
    failedToLoadParameters: "There was an error while getting the client's variables",
    failedToLoadTags: "There was an error while getting the client's tags",
    failedToLoadAddresses: "There was an error while getting the client's identifiers",
  },
  list: {
    searchPlaceholder: 'Quick search by phone number',
    name: 'Full name',
    emptyName: 'Name not specified',
    phone: 'Identifier',
    addButton: 'to database',
    addClient: 'Add client',
    addList: 'Add list',
  },
  notification: {
    emptyFile: 'File is empty, please upload other file',
  },
  dialog: {
    remove: {
      title: 'Do you really want to delete the client?',
      body: 'We will irrevocably lose the record and all information about the client',
    },
    blacklist: {
      title: 'Add to the black list?',
      body: `This will add the client to the “black list” so they will no longer receive your broadcasts. Also, the tag "Black list" will be automatically added to the client's tag list.`,
    },
    unblacklist: {
      title: 'Remove from the “black list”?',
      body: `This will remove the user from the “black list” category so they will be able to receive your broadcasts. The tag “Black list” will be automatically removed from the client's tag list. Continue?`,
    },
  },
  empty: {
    title: 'Nothing here yet',
    subtitle:
      "Unfortunately, you don't have anyone in your client base yet. You can create clients manually or upload a whole list at once",
  },
  noDataWithFilters: {
    title: ':(',
    text: 'We could not find a client with this phone number or identifier',
    hint: 'Please check if the data you entered is correct',
    button: 'Back to list',
  },
  filters: {
    selectTags: 'Select tags',
    recipientTags: 'Recipient tags',
    parameters: 'Variables',
    clients: 'Clients',
  },
};
