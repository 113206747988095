import {EWeekDay} from './types';

export default {
  title: 'Settings',
  allSettings: 'General',
  name: 'Script name',
  subject: 'Select WhatsApp sender name for messages in the thread',
  subjectHint: 'This account must be used only for sending messages via scripts',
  onlyOnes: 'Do not run for same recipients',
  onlyOnesHint: 'If you restart the script, it will run for new recipients only',
  saveContinue: 'Save and continue',
  chainRepeat: {
    title: 'Recurrence',
    singleLaunch: 'Start once',
    singleLaunchHint: 'Run the script once after saving, or specify date and time',
    regular: 'Run on schedule',
    regularHint: 'Run the script on schedule until end date and time',
  },
  regularTrigger: {
    title: 'One time or on schedule',
  },
  startDate: {
    title: 'When do we start?',
    now: 'Run after saving',
    setDate: 'Set date and time',
    date: 'Start date',
    time: 'Start time',
  },
  endDate: {
    date: 'End date',
    time: 'End time',
    title: 'Specify end date and time',
    label: 'When do we stop',
    labelHint: "The script will be stopped after 48 hours without recipient's actions",
    titleHint:
      "When the script stops, your conversations with recipients will be terminated. Subscribers participating in the scenario won't be able to complete it",
  },
  chooseDayAndTime: 'Select days and time when the script runs',
  addDate: 'Add date',
  weekDays: {
    [EWeekDay.MONDAY]: 'Monday',
    [EWeekDay.TUESDAY]: 'Tuesday',
    [EWeekDay.WEDNESDAY]: 'Wednesday',
    [EWeekDay.THURSDAY]: 'Thursday',
    [EWeekDay.FRIDAY]: 'Friday',
    [EWeekDay.SATURDAY]: 'Saturday',
    [EWeekDay.SUNDAY]: 'Sunday',
  },
};
