import {TFacebookSDKConfig} from 'src/components';
import {EResellerIds} from 'src/constants';
import {EMessagingLimit, EQualityScore, EQualityStatus} from 'src/containers/Channels/definitions';

const DEV_CONFIG_ID = '1071488274287061';
const PROD_CONFIG_ID = '791330699428621';

const facebookSDKConfig: TFacebookSDKConfig = {
  DEV_APP_ID: '180603563823183',
  PROD_APP_ID: '2026253854199025',
};

const LOGO_SIZE = 400;
const LOGO_FORMATS = '.jpg, .jpeg';

type TEmbeddedWhatsAppChannel = {
  id: number;
  name: string;
  status: EStatus;
  tariffTemplateId: number;
  phone?: string;
  address: string;
  email: string;
  logoFile?: TFile | null;
  contactStatus?: string;
  qualityScore: EQualityScore | null;
  messagingLimit?: EMessagingLimit;
  previousQualityScore?: EQualityScore;
  previousMessagingLimit?: EMessagingLimit;
  qualityStatus?: EQualityStatus | null;
  qualityUpdatedAt: string;
};

type TTechPartnerChannel = {
  id: number;
  name: string;
  code: string;
  status: EStatus;
  tariffTemplateId: number;
};

enum EPublicStatus {
  NEW = 'NEW',
  SUCCESS = 'SUCCESS',
  ERROR_OLD_CODE = 'ERROR_OLD_CODE',
  ERROR_INCORRECT_TARIFF = 'ERROR_INCORRECT_TARIFF',
  ERROR_USED_CODE = 'ERROR_USED_CODE',
}

type TPublicChannel = {
  id: number;
  partnerName: string;
  status: EPublicStatus;
  resellerId: EResellerIds;
};

type TFormData = {
  id?: TEmbeddedWhatsAppChannel['id'];
  name?: TEmbeddedWhatsAppChannel['name'];
  address?: TEmbeddedWhatsAppChannel['address'];
  email?: TEmbeddedWhatsAppChannel['email'];
  phone?: TEmbeddedWhatsAppChannel['phone'];
  code?: string;
  tariffTemplateId?: TEmbeddedWhatsAppChannel['tariffTemplateId'] | null;
  logoFile?: TEmbeddedWhatsAppChannel['logoFile'];
  contactStatus?: TEmbeddedWhatsAppChannel['contactStatus'];
};

type TTechPartnerChannelFormData = {
  id?: TTechPartnerChannel['id'];
  name?: TTechPartnerChannel['name'];
  tariffTemplateId?: TTechPartnerChannel['tariffTemplateId'] | null;
  code?: TTechPartnerChannel['code'];
};

type TPublicChannelFormData = {
  address?: TEmbeddedWhatsAppChannel['address'];
  logoFile?: TEmbeddedWhatsAppChannel['logoFile'] | null;
  email?: TEmbeddedWhatsAppChannel['email'];
  contactStatus?: TEmbeddedWhatsAppChannel['contactStatus'];
  code?: TTechPartnerChannel['code'];
};

enum EStatus {
  DRAFT = 'DRAFT',
  SENT_TO_PLATFORM = 'SENT_TO_PLATFORM',
  BUSINESS_VERIFICATION_EXPIRED = 'BUSINESS_VERIFICATION_EXPIRED',
  DONE_FINISH_REGISTER = 'DONE_FINISH_REGISTER',
  DONE_BUSINESS_VERIFICATION = 'DONE_BUSINESS_VERIFICATION',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

const DEFAULT_VALUES: TFormData = {
  name: '',
  tariffTemplateId: null,
  address: '',
  contactStatus: '',
  email: '',
  logoFile: null,
};

const TECH_PARTNER_CHANNEL_DEFAULT_VALUES: TTechPartnerChannelFormData = {
  name: '',
  tariffTemplateId: null,
};

const PUBLIC_CHANNEL_DEFAULT_VALUES: TPublicChannelFormData = {
  address: '',
  email: '',
  contactStatus: '',
  logoFile: null,
};

enum EFormSteps {
  SETTINGS = 'SETTINGS',
  VIEW = 'VIEW',
}

export type {
  TEmbeddedWhatsAppChannel,
  TFormData,
  TTechPartnerChannel,
  TTechPartnerChannelFormData,
  TPublicChannel,
  TPublicChannelFormData,
};

export {
  EFormSteps,
  facebookSDKConfig,
  DEV_CONFIG_ID,
  PROD_CONFIG_ID,
  EStatus,
  EPublicStatus,
  LOGO_SIZE,
  LOGO_FORMATS,
  DEFAULT_VALUES,
  TECH_PARTNER_CHANNEL_DEFAULT_VALUES,
  PUBLIC_CHANNEL_DEFAULT_VALUES,
};
