import {TSchedule} from 'src/containers/Triggers/types';

export enum EWeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type TFlowSettings = {
  name: string;
  hasEndAt?: boolean;
  onlyOnes: boolean;
  subjectId?: number;
  isStartNow: boolean;
  schedule: TSchedule;
  startAtDate?: Date;
  startAtTime?: string;
  endAtDate?: Date;
  endAtTime?: string;
};
