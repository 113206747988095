import {EStatus} from '../definitions';

export default {
  confirmPhoneModal: {
    title: 'How to confirm your phone number',
    contentHead: `To activate your WhatsApp Business API account, confirm the phone number used to register your account. After confirmation, your WhatsApp Business API account will appear in WhatsApp.`,
    contentListHeader1: 'Phone number confirmation',
    contentList1: `
    <0>Select the confirmation method:
        <0>
        <1>SMS message: you will receive a code via SMS. We recommend using SMS messages to confirm mobile phone numbers.</1>
          <1>Voice message: you will receive an incoming call from the country code «+1» (USA). The robot will tell you a 6 digit code. Write it down. We recommend using voice messages to confirm landline numbers.</1>
        </0>
      </0>
      <0>Click <b>Send</b>.</0>
      <0>Enter the received code in the field of the confirmation form.</0>
      <0>Click <b>Send</b>.</0>
    `,
    contentListHeader2: 'Features and restrictions when confirming a phone number',
    contentList2: `
      <0>Specify the country code when entering your phone number.</0>
      <0>In case of voice confirmation, check with your provider whether the calls from the United States are allowed for your phone number. The country code for the United States is «+1».</0>
    <0>In case of voice confirmation, if you use Interactive Voice Response (IVR), configure the routing of incoming calls from the country code «+1» directly to the operator. Or find the call record in the IVR logs and listen to it.</0>
      <0>The number of attempts to confirm the code is 3. If failed, you can try one more time in 4 hours. Then one more time a day later. If you can't get the confirmation code after the fifth attempt, please contact our Customer Support <0>{{supportEmail}}</0></0>
      <0>If you need to know which numbers will be used for incoming calls, please contact our Customer Support <0>{{supportEmail}}</0></0>
    `,
    contentFooter:
      'If you have problems confirming a phone number, <b>please contact</b> <0>{{personalManagerEmail}}</0>.',
  },
  [EStatus.CANCELED]: {
    content: 'WhatsApp account registration was canceled',
  },
  [EStatus.SENT]: {
    title: 'Step 1 of 7: The WhatsApp account has been sent for registration',
    content: `We will send a notification with the next steps to your email.`,
  },
  [EStatus.NEED_CONFIRM_ACCOUNT]: {
    title: 'Step 2 of 7: Request confirmation required',
    content: `You need to confirm the request to manage your account from edna Pulse.
 To do this <0>follow the link</0> and click button <b>Confirm</b>. We will send a notification with the next steps.`,
  },
  [EStatus.NEED_VERIFY_FACEBOOK_BUSINESS]: {
    title: 'Step 3 of 7: Facebook Business verification required',
    content: `You need to verify your business in Facebook Business Manager.
  Follow the <0>instruction</0> to do so. Upon completion, we will send a notification with the next steps.`,
  },
  [EStatus.CREATE_WHATSAPP_ACCOUNT]: {
    title: 'Step 4 of 7: Your application has been accepted and is currently being reviewed by us',
    content: `The procedure takes some time, usually from 10 to 15 days.
A manager will contact you if any issues arise.
When the registration is over you will receive an email to {{email}}.`,
  },
  [EStatus.NEED_CONFIRM_CODE]: {
    title: 'Step 5 of 7: Phone number verification required',
    content: `You need to verify the phone number connected to WhatsApp account.
To do so, click <b>Verify phone number</b> и select a verification method.
Then click <b>Send</b>. Enter the received code in the corresponding field and click <b>Send</b>.
Before you start, please familiarize yourself with the <0>features</0>.`,
    button: 'Verify phone number',
  },
  [EStatus.CONFIRM_CODE_REQUEST]: {
    title: 'Step 6 of 7: Verify phone number',
    content: `Verification of the phone number used to register
the WhatsApp Business API is required to connect an account.
After confirmation, your account appears on the WhatsApp network.
Click the "Request code" button and select an option: SMS or Voice message.
If you requested the code earlier and it has expired, click "Send code"
Before you start, please familiarize yourself with the <0>features</0>.`,
    requestCodeButton: 'Request code',
    sendCodeButton: 'Send code',
  },
  [EStatus.SEND_CONFIRM_CODE]: {
    title: 'Step 6 of 7: Almost done',
    content: `We are finishing the registration of WhatsApp account`,
  },
  [EStatus.DONE_NOT_VERIFIED]: {
    title: 'Step 7 of 7: Service configuration is over!',
    content: `Your account is active! (<0>status details</0>)`,
    modalTitle: 'Congratulations, you have registered your WhatsApp Business API account!',
    modalContent: `You can now start sending WhatsApp messages to clients.<br/>
If you need to register a template for outbound messaging, <b>please contact</b> <0>{{personalManagerEmail}}</0><br/>
You can learn more about rules for outbound messaging on <1>the Facebook for Developers website</1>.<br/><br/>

<b>Getting official status</b><br/>
We have already sent a request for an official status on WhatsApp,<br/>
but very few companies are given the official status.<br/>
The name of the official business account is visible for all users and has a green checkmark badge.
You can learn more about official status policies on <1>the Facebook for Developers website</1>.
`,
  },
  [EStatus.DONE_VERIFIED]: {
    title: 'Step 7 of 7: Service configuration is over!',
    content: `Your account is registered with the official status.
You can send messages now.`,
  },
};
