import {EChannelType} from 'src/constants';

import {EStatus} from './api';

export default {
  cascade: 'cascade',
  searchPlaceholder: 'Quick search by cascade name or ID',
  filters: {
    channelLabel: 'Channel',
    cascades: 'Cascades',
    status: 'Cascade`s status',
    subjectName: 'Used channels in cascade',
  },
  title: {
    new: 'New cascade',
  },
  status: {
    [EStatus.ACTIVE]: 'Active',
    [EStatus.DISABLED]: 'Disabled',
  },
  th: {
    cascadeId: 'Cascade ID',
    name: 'Cascade name',
    status: 'Status',
    channels: 'Channels',
  },
  add: 'Connect cascade',
  editForm: {
    name: 'Name',
    cascadeId: 'Cascade ID',
    cascadeSteps: 'Cascade steps',
    step: 'step',
    send: 'Send a message via channel',
    sendCondition: "if clients don't receive the message in step {{step}} within",
    lastStep: 'This is the last step of the cascade, the delivery time for it is {{time}}',
    sendDuration: {
      [EChannelType.WHATSAPP]: 'WhatsApp will deliver your messages up to 30 days',
      [EChannelType.SMS]: 'SMS operators will deliver your messages up to 3 days',
    },
    ttlTitle: 'Learn more about messages delivery time via channels',
    ttlInfo:
      'If a client does not receive a message via the channel within the specified time, edna Pulse will send them a message in the next step of the cascade.\n' +
      'edna Pulse stops the attempt to deliver messages via the channel when moving to the next step of the cascade.\n' +
      'For the last step in the cascade, the maximum message delivery time in the selected channel is used.',
    ttlDuration: 'Messages delivery time via channel:',
    channelsTtl: {
      [EChannelType.WHATSAPP]: 'to 30 days',
      [EChannelType.VIBER]: 'from 30 seconds to 14 days',
      [EChannelType.VK_NOTIFY]: 'from 15 seconds to 24 hours',
      [EChannelType.OK_NOTIFY]: 'from 15 seconds to 24 hours',
      [EChannelType.PUSH]: 'to 24 hours',
      [EChannelType.SMS]: 'to 3 days',
    },
    maxChannelsTtl: {
      [EChannelType.WHATSAPP]: '30 days',
      [EChannelType.VIBER]: '14 days',
      [EChannelType.VK_NOTIFY]: '24 hours',
      [EChannelType.OK_NOTIFY]: '24 hours',
      [EChannelType.PUSH]: '24 hours',
      [EChannelType.SMS]: '3 days',
    },
  },
  deleteConfirm: 'Are you sure you want to remove the cascade "{{name}}"? This cannot be undone.',
  empty: {
    toChannels: 'To channels',
    title: 'Nothing here yet',
    subtitle:
      'A cascade is a fallback messaging scenario that you can use in a broadcast. Using cascades, you make sure your clients do get your messages by specifying the order in which the system sends them (for example, first via SMS, and then, if not received, via WhatsApp). To create a cascade, you must have at least one active channel.',
  },
  modal: {
    saveWarning: {
      title: 'Be careful!',
      message: `
 Once you save a cascade, you cannot edit it. This way, when you review and/or edit broadcasts, you will not lose any important content.
   `,
    },
  },
};
