type TFacebookSDKConfig = {
  DEV_APP_ID: string;
  PROD_APP_ID: string;
};

type TFacebookPayload = {
  config: TFacebookSDKConfig;
  onError?: TEmptyFunction;
};

type TLoginResponse = {
  error?: facebook.StatusResponse;
  result?: facebook.AuthResponse;
};

type TLoginOptions = facebook.LoginOptions;

enum EFacebookStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type TFacebook = {
  status?: EFacebookStatus;
  checkConnection: TEmptyFunction;
  login: (options: TLoginOptions) => Promise<TLoginResponse>;
};

const FACEBOOK_SDK_ID = 'facebook-sdk';

export {FACEBOOK_SDK_ID, EFacebookStatus};
export type {TFacebookSDKConfig, TFacebookPayload, TFacebook, TLoginOptions, TLoginResponse};
