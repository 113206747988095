export default {
  ednaNews: 'edna Pulse news',
  date: {
    0: 'January {{day}}',
    1: 'February {{day}}',
    2: 'March {{day}}',
    3: 'April {{day}}',
    4: 'May {{day}}',
    5: 'June {{day}}',
    6: 'July {{day}}',
    7: 'August {{day}}',
    8: 'September {{day}}',
    9: 'October {{day}}',
    10: 'November {{day}}',
    11: 'December {{day}}',
  },
};
