import {EPaymentTab} from './definitions';

export default {
  yourBalance: 'Your account balance is',
  generateInvoice: 'Generate invoice',
  topUpBalance: 'Top up the account',
  stripe: 'Pay by card',
  tab: {
    [EPaymentTab.REPLENISHMENT]: 'Balance replenishment',
    [EPaymentTab.ACCOUNT_STATEMENT]: 'Account statement',
    [EPaymentTab.DOCUMENTATION]: 'Documents',
  },
};
