import {useParams} from 'react-router-dom';

import {skipToken} from '@reduxjs/toolkit/query';

import {useExactSelector} from '@edna/hooks';

import {EResellerIds} from 'src/constants';
import {useFeatureFlag} from 'src/containers/Auth/featureFlags';
import userModel, {EFeatureFlag} from 'src/containers/Auth/userModel';
import {EPaymentType} from 'src/containers/Licenses/PaymentTab/definitions';
import {useGetSupportClientQuery} from 'src/containers/SupportClients/api';
import userCompanyProfileModel from 'src/containers/UserCompanyProfile/model';

export const useAutopaymentEnabled = () => {
  const {id: supportTenantId} = useParams<'id'>();
  const hasFeatureFlag = useFeatureFlag(EFeatureFlag.STRIPE_AUTOPAYMENT);

  const {data: supportClient} = useGetSupportClientQuery(supportTenantId ?? skipToken);
  const isUserPrePaymentType = useExactSelector(userCompanyProfileModel.selectors.isPrePaymentType);
  const userResellerId = useExactSelector(userModel.selectors.resellerId);

  const isPrePaymentType = supportTenantId
    ? supportClient?.paymentType === EPaymentType.PREPAYMENT
    : isUserPrePaymentType;
  const resellerId = supportTenantId ? supportClient?.resellerId : userResellerId;

  return hasFeatureFlag || (isPrePaymentType && resellerId === EResellerIds.MFM_ID);
};
