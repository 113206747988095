import {EDebtPaymentType} from 'src/containers/Licenses/PaymentTab/definitions';

const INIT_RENEWAL_LICENSE_PREFIX =
  'Лицензия на использование Системы edna Pulse с подключением канала';
const WHATSAPP_AMAU_PAYMENT_PREFIX =
  'Переменная часть вознаграждения за Лицензию Системы edna Pulse c функциональностью WA';

export default {
  paymentDebtsTitle: 'У вас образовалась задолженность по платежам в размере',
  nextPaymentsTitle: 'Ближайшие списания за <strong>{{month}} {{year}}г.</strong> Предварительно',
  th: {
    createdAt: 'Дата перехода в статус Просрочено',
    blockedAt: 'Дата блокировки',
    paymentType: 'Тип платежа',
    total: 'Сумма',
    paymentDate: 'Дата списания',
    expireAt: 'Оплатить до',
  },
  item: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} WHATSAPP (MAU {{mauCount}}), учетная запись {{subject}}{{number}}, {{month}} {{year}}г.`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} WHATSAPP (MAU {{mauCount}}), учетная запись {{subject}}{{number}}, {{month}} {{year}}г.`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Минимальный платеж за услуги передачи сообщений Viber за {{month}} {{year}}г., учетная запись {{subject}}`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `${WHATSAPP_AMAU_PAYMENT_PREFIX}, учетная запись {{subject}}{{number}}. AMAU`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Абонентская плата за использование альфанумерического имени (подписи SMS-сообщения {{subject}}, оператор «{{mobileOperator}}») за {{month}} {{year}}г.`,
  },
  nextPaymentItem: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `Активация лицензии {{number}}, {{month}} {{year}}г.`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} WHATSAPP (MAU {{mauCount}}), учетная запись {{subject}}{{number}}, {{month}} {{year}}г.`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} SMS, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `${INIT_RENEWAL_LICENSE_PREFIX} VIBER, {{month}} {{year}}г.`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Минимальный платеж за услуги передачи сообщений Viber за {{month}} {{year}}г., учетная запись {{subject}}`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `${WHATSAPP_AMAU_PAYMENT_PREFIX}, учетная запись {{subject}}{{number}}. AMAU`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Абонентская плата за использование альфанумерического имени (подписи SMS-сообщения {{subject}}, оператор «{{mobileOperator}}») за {{month}} {{year}}г.`,
  },
  collapseTd: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `Активация лицензии`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `Активация лицензии`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `Активация лицензии`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `Пролонгация лицензии`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `Пролонгация лицензии`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `Пролонгация лицензии`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `Услуги по маршрутизации сообщений`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Абонентская плата за использование подписей в SMS`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Абонентская плата за использование подписей в VIBER`,
  },
  hint: {
    blockedAtHint:
      'После этого ваш аккаунт и интерфейс будет заблокирован без возможности восстановления',
    createdAtHint:
      'До этой даты средств на балансе было недостаточно для оплаты задолженности. Отправка сообщений в данном канале невозможна',
    expireAtHint:
      'Если до этой даты доступных средств на балансе будет недостаточно для списания, то лицензия будет заблокирована и отправка сообщений в канале станет невозможной',
    nextPaymentTotalHint:
      'Приблизительная сумма, зависит от переменной части лицензии и других нефиксированных платежей. Точная сумма будет известна {{date}}',
    initPaymentTotalHint: 'Сумма появится в момент активации лицензии',
    amauPaymentTotalHint:
      'Сумма зависит от количества сообщений, отправленных сверх лицензии (AMAU)',
  },
};
