import {EPushAppType} from '../definitions';

export default {
  setup: 'Start setup',
  saveContinue: 'Save and continue',
  testPush: 'Test push notifications',
  [EPushAppType.IOS]: {
    new: {
      title: 'Before you continue with the setup, verify that you have the following:',
      list: [
        {
          text: 'An iOS app',
        },
        {
          text: `An iOS device (iPhone, iPad, iPod Touch) for testing. The Xcode simulator does not support
          push notifications, so you need to use a physical device for testing.`,
        },
        {
          text: 'A Mac workstation with the latest version of Xcode',
        },
        {
          text: 'Apple Production Push Certificate',
          hint: 'A certificate for sending push notifications from developer.apple.com exported in the .p12 format via Keychain',
        },
        {
          text: 'CocoaPods or Swift Package Manager',
        },
      ],
      document: {
        text: 'How to set up an iOS app',
      },
    },
    step1: {
      attach: 'Attach',
      title: 'Create an AppGroup and add an Apple Push Services Production certificate.',
      number1: {
        label: 'Log into your account at ',
        text: `Go to <strong>Certificates, Identifiers & Profiles</strong>.

        In the menu on the left, click <strong>Identifiers</strong> and make sure an App Group is registered for your app.

        The group identifier must match the app name. For example, if the name of the app is com.edna.push.demoapp, the group name must be group.com.edna.push.demoapp.

        If you do not have an app group, click <strong>«+»</strong> next to the Identifiers header and register a new App Group. Make note of the group identifier, you will need it later.
        `,
        imageLabel: 'Creating an AppGroup in an Apple Developer account',
        url: 'https://developer.apple.com/account/resources/',
        urlText: 'developer.apple.com.',
      },
      number2: 'Obtain a Production Push Certificate from Apple. <0>How to get the certificate</0>',
      number3: {
        label: 'Enter the app Bundle ID',
        inputLabel: 'App Bundle ID',
        inputHint: 'Bundle ID is a unique app identifier, for example, io.edna.app',
      },
      number4: {
        label: 'Attach the Production certificate file in the .p12 format and enter its password',
        password: '.p12 certificate file password',
        passwordHint:
          'Password to the .p12 certificate entered when exporting from Keychain. This password is required when setting up an iOS app in edna Pulse.',
      },
    },
    step2: {
      title: 'Create Notification Service Extension',
      number1: {
        label:
          'In the app project in Xcode, add a new target <strong>(File > New > Target)</strong>:',
        imageLabel: 'Adding a new target to the app project',
      },
      number2: {
        label:
          'In the <strong>Choose a template for your new target</strong> window, select <strong>Notification Service Extension</strong>.',
        imageLabel: 'Selecting an extension template for the new target of the project',
      },
      number3: {
        label:
          'For the main app, on the <strong>Signing & Capabilities</strong> tab, add the AppGroup created in the previous step: add the <strong>Push Notifications</strong> and <strong>Background Modes – Remote notifications</strong> capabilities',
        imageLabel: 'Configuring settings and adding capabilities for the main app',
      },
      number4: {
        label:
          'For Notification Service Extension, on the <strong>Signing & Capabilities</strong> tab, add the AppGroup and add the <strong>Push Notifications</strong> capability',
        imageLabel:
          'Configuring settings and enabling capabilities for Notification Service Extension',
      },
      number5: {
        label:
          'Enter the AppGroup name for the app and the extension in the Info.plist files in the <strong>edna_app_group</strong> parameter.',
        imageLabel: 'Entering the AppGroup information into Info.plist',
      },
    },
    step3: {
      title: 'Connect edna Libraries to the Project',
      number1:
        'In this step, you will connect edna libraries to your project via CocoaPods or Swift Package Manager.',
      mainApp: '<strong>Connect libraries for the main app:</strong>',
      swift: 'Via Swift Package Manager:',
      cocoapods: 'Via CocoaPods:',
      notificationService: '<strong>Connect libraries for Notification Service Extension:</strong>',
    },
    step4: {
      title: 'Integrate Library in the App Code',
      number1: 'In the project AppDelegate file, add the following string to connect the library:',
      number2:
        'Set a unique application key and initialize the library in the AppDelegate <strong>applicationDidFinishLaunchingWithOptions</strong> method:',
      number3:
        'Pass to the library system calls in the AppDelegate <strong>didRegisterForRemoteNotificationsWithDeviceToken</strong> and <strong>didReceiveRemoteNotification</strong> methods:',
      number4:
        'Replace the code of the main class of Notification Service Extension to the following:',
      number5:
        'If a client taps the push notification area or its buttons, the app is launched. To receive information from the library that the app was launched by tapping the notifications, pass the following closure to the <strong>EDNAPushX.setOnPushAction</strong> method:',
      message:
        'You need to call the EDNAPushX.setOnPushAction method before initializing the library – before you call the EDNAPushX.initWithLaunchOptions method.',
      codeComments: {
        uniqueAppKey: 'Unique application key',
        autoRegister: 'Automatically show a request for push notifications',
      },
    },
    step5: {
      title: 'Support Registration of Client Devices in edna',
      automaticRegistrationDescription:
        "A client's device appears in edna after it is registered. Two methods – automatic or manual – are possible. With automatic registration, edna library registers the device in edna after calling the login method on the mobile app side. This method is simpler, does not require you to get the device address and manage registration from the backend, but is not recommended for sending sensitive data.",
      manualRegistrationDescription:
        'In manual registration, your backend must obtain the device address from the mobile app and register the device with the edna service via <0>the registration method of the public API</0>',
      autoDescription: {
        text: 'edna library automatically registers the client and their device in edna.',
        number2: {
          label:
            'To register a client in edna, call the login library method specifying subscriberId and SubscriberIdType of the client.',
          text: 'Call the method only after the client is successfully authorized in your app.',
        },
        number3:
          'When the user is deauthorizing, call the logout method of the library to log out the device from edna:',
      },
      manualDescription: {
        number2:
          "To register a client with the edna service, get the deviceAddress from the mobile library and call <0>the registration method of the public API</0>, specifying the client's subscriberId, his SubscriberIdType, and the deviceAddress. You should call the method only after the client has been successfully authorized in your application",
        number3:
          "At the time the client is deauthorized in the application, call <0>the logout method of the public API</0> to disconnect the client's device in the edna service.",
      },
      message:
        'The following types of SubscriberIdType are allowed: phone number (PhoneNumber), email address (Email), IDs in Facebook, Telegram, Google, Apple, Yandex, or your internal client IDs (FaceBookId, TelegramId, GoogleId, AppleId, YandexId, ExtUserId, CookieId), as well as UTM tag (UTM).',
      number1: {
        label: 'Select a method of device registration in the platform:',
        autoRegistration:
          'Automatic registration: All devices are registered in the platform automatically. This method is suitable for sending advertising and marketing messages',
        manualRegistration:
          'Manual registration: your backend registers devices via <0>the registration method of the public API</0> – suitable for sending all types of messages',
      },
    },
    step6: {
      title: 'Connect Notification Content Extension',
      text: `If you tap a push notification hard or long enough, its expanded view opens. By default, for a notification extended view, the data of the main app is used: header, text, and logo. This view can be configured using Notification Content Extension.

      To work with Notification Content Extension, edna provides an additional library that allows displaying an extended view of notifications. A header, extended text, and an additional image are transferred to extended notifications. The library also allows to add action buttons to extended notifications.`,
      number1: {
        label:
          'In the app project in Xcode, add a new target (<strong>File > New > Target</strong>) Then, in the <strong>Choose a template for your new target</strong> window, select <strong>Notification Content Extension</strong>.',
        imageLabel:
          'Configuring required settings and permissions for Notification Content Extension',
      },
      number2: {
        label:
          'Connect a library for Notification Content Extension using Swift Package Manager or CocoaPods.',
        swift: 'Via Swift Package Manager: ',
        cocoapods: 'Via CocoaPods:',
      },
      number3:
        'Replace the code of the main class of Notification Content Extension to the following:',
      number4:
        'Enter the name of the ednaPushCategory extension category in the Info.plist file of the extension in the <strong>NSExtension/NSExtensionAttributes/UNNotificationExtensionCategory</strong> parameter. Notifications that require extended layout will be sent with the <strong>ednaPushCategory</strong> extension category.',
      number5:
        'Make sure YES (1) is set for the <strong>NSExtension/NSExtensionAttributes/UNNotificationExtensionDefaultContentHidden</strong> parameter. This parameter allows to hide the information from the original notification when extended view is displayed.',
    },
    step7: {
      title: 'Compile and launch the app',
      message: 'Consider the following when compiling:',
      messageList: [
        'Connect the device that will be used to test the service to the Internet',
        'Test receiving push notifications on a physical device (the XCode emulator does not support push notifications)',
        'Approve the display of push notifications in the dialog box after launching the application',
      ],
    },
    step8: {
      title: 'Check the device connection and test receiving a push notification',
    },
  },
  [EPushAppType.ANDROID]: {
    new: {
      title: 'Verify you have the following for the setup:',
      list: [
        {
          text: 'An Android app with minSdk no earlier than 21 (Android 5) and targetSdk no later than 34 (Android 14).',
        },
        {
          text: 'Google service account JSON file. <0>How to get the file</0>',
        },
        {text: 'The google-services.json file from Firebase. <0>How to get the file</0>'},
        {
          text: 'A device with Android 5.0 or higher or an Android emulator with the Google Play services',
          hint: 'To test the Huawei cloud, you will need a device with Huawei services',
        },
        {text: 'A current version of Android Studio'},
      ],
      document: {
        text: 'How to set up an Android app',
      },
    },
    step1: {
      title: 'Enter data from Firebase',
      huaweiClientId: 'Huawei Client Id',
      huaweiClientSecret: 'Huawei Client Secret',
      appSetup: {
        number1: 'Register the app in Firebase. <0>How to register the app</0>',
        number2: 'Enter the following parameters',
      },
      addHuawei: 'Connect the Huawei push cloud',
      huaweiSetup: {
        number1: 'Register the app in Huawei AppGallery. <0>How to register the app</0>',
        number2:
          'Obtain the Huawei Client Id and Client Secret parameters. <0>How to obtain the parameters</0>',
        number3: 'Enter the following parameters',
      },
      checkbox: 'Connect the Huawei push cloud',
      nameApp: {
        label: 'App name',
        hint: 'App package name, for example io.edna.app',
      },
      huaweiAppIDHint: 'Huawei Client Id parameter from Huawei Console',
      huaweiAppSecretHint: 'Huawei Client Secret key from Huawei Console',
    },
    step2: {
      title: 'Add dependencies to the build.gradle file',
      number1:
        'Update the root file of the build.gradle project – specify the edna repository address and add Google services to the list of dependencies:',
      number2:
        'To the build.gradle file of the app, apply the plugin, and add edna library to the list of dependencies:',
      number3: 'Specify the app unique access key for the edna push services in app manifest:',
      number4:
        'Add the google-services.json file to the root of the app directory of your app in Android Studio.',
      huaweiNumber4:
        'Add the google-services.json file from the Firebase console and the agconnect-services.json file from the Huawei AppGallery console to the root folder of your application.',
      number5: `The edna library is initialized automatically. However, if <0>Configuration.Provider</0> is implemented in the app, you should <1>use manual initialization</1>.`,
    },
    step3: {
      title: 'Add the code for processing notification taps',
      text: `If a client taps a notification or an action button, the app launches. edna library processes the tap-through data and sends Intent to the app's start Activity.
      Process the data received from edna library in the app's onCreate and onNewIntent methods. The edna library sends an action string you specified in the broadcast to Intent.
      Example of the code for processing a client's notification tap-through:`,
      codeComments: {
        action: 'Client navigation based on the passed string with PUSH_ACTION_KEY = "action"',
      },
    },
    step4: {
      title: 'Support Registration of Client Devices in edna',
      automaticRegistrationDescription:
        "A client's device appears in edna after it is registered. Two methods – automatic or manual – are possible. With automatic registration, edna library registers the device in edna after calling the login method on the mobile app side. This method is simpler, does not require you to get the device address and manage registration from the backend, but is not recommended for sending sensitive data.",
      manualRegistrationDescription:
        'In manual registration, your backend must obtain the device address from the mobile app and register the device with the edna service via <0>the registration method of the public API</0>',
      number1: {
        label: 'Select a method of device registration in the platform:',
        autoRegistration:
          'Automatic registration: All devices are registered in the platform automatically. This method is suitable for sending advertising and marketing messages',
        manualRegistration:
          'Manual registration: your backend registers devices via <0>the registration method of the public API</0> – suitable for sending all types of messages',
      },
      message:
        'The following types of SubscriberIdType are allowed: phone number (PhoneNumber), email address (Email), IDs in Facebook, Telegram, Google, Apple, Yandex, or your internal client IDs (FaceBookId, TelegramId, GoogleId, AppleId, YandexId, ExtUserId, CookieId), as well as UTM tag (UTM).',
      autoDescription: {
        text: 'edna library automatically registers the client and their device in edna.',
        number2: {
          label:
            'To register a client in edna, call the library login method and specify the identifier and SubscriberIdType of the client',
          text: 'Call the method only after the client is successfully authorized in your app. Example:',
        },
        number3:
          'In the onCreate method of the child android.app.Application, initialize the library specifying the child class PushXEventHandler:',
      },
      manualDescription: {
        number2:
          "To register a client with the edna service, get the deviceAddress from the mobile library and call <0>the registration method of the public API</0>, specifying the client's subscriberId, his SubscriberIdType, and the deviceAddress. You should call the method only after the client has been successfully authorized in your application",
        number3:
          "At the time the client is deauthorized in the application, call <0>the logout method of the public API</0> to disconnect the client's device in the edna service.",
      },
    },
    step5: {
      title: 'Compile and Launch App',
      message: 'Connect the device that will be used to test the service to the Internet',
    },
    step6: {
      title: 'Check the device connection and test receiving a push notification',
    },
  },
  lastStep: {
    title: 'Check the device connection and test receiving a push notification',
    infoMessage:
      'After the device is compiled and launched, it will take some time to register the device in edna. Do not go yet',
    successMessage: 'Almost there! The {{deviceName}} device has been successfully connected',
    errorMessage: 'Something went wrong. Your device did not connect to the push service.',
    appRecommended: {
      title: 'At this step, verify that:',
      list: [
        {text: 'The app name is entered correctly in step 1'},
        {text: 'The library appId parameter is filled in correctly'},
        {text: 'The device is connected to the Internet'},
      ],
      paragraph:
        'If the device still does not connect, possible errors are describied in <0>online help</0>',
      errorMessage: 'Something went wrong. Your device did not connect to the Push service.',
      button: 'Try again',
    },
    pushRecommended: {
      title: 'At this step, verify that:',
      list: [
        {text: 'The device is connected to the Internet'},
        {text: 'Receiving push notifications is enabled for the app on the test device'},
      ],
      paragraph:
        'Still does not work? Take a look at possible errors described in <0>online help</0>',
      errorMessage:
        'If you did not receive a test push notification, there was an error in the configuration.',
      button: 'Send test push',
    },
    modal: {
      title: 'Congratulations! You have successfully configured push notifications!',
      text: 'Now you can send push notifications to the mobile app',
    },
    question: `We have sent a test push notification to your device, you should receive it within a minute.
        Have you received it?`,
  },
};
