import axios, {AxiosError, AxiosResponse} from 'axios';
import {Store} from 'redux';

import storage from '@edna/utils/storage';

import {EStorageKeys} from 'src/constants';
import {setServiceAvailability} from 'src/containers/App/slice';
import userModel, {TUser} from 'src/containers/Auth/userModel';
import {rootApi} from 'src/models/api';
import request from 'src/models/request';

import {setLastResponse} from './headers';
import {clearStorage} from './utils';

type TRefreshResponse = TUser & {
  accessExpiresIn: number;
  refreshExpiresIn: number;
};

const REFRESH_API_URL = '/rest/auth/refresh';
const SILENT_POLLING_API_URL = '/rest/web-notification/poll-all';

const unavailableStatusSet = new Set([502, 503, 504]);

let requestRefreshPromise: Promise<AxiosResponse<TRefreshResponse>> | null = null;

const networkError = {
  data: {code: 'networkError'},
};

let interceptors: number | undefined = undefined;

const requestInterceptors = (store: Store<TRootState, any>) => {
  if (interceptors !== undefined) {
    request.interceptors.response.eject(interceptors);
  }

  interceptors = request.interceptors.response.use(
    (response: AxiosResponse) => {
      setLastResponse(response);

      return response.data || {};
    },

    async (error: Error | AxiosError) => {
      if (!axios.isAxiosError(error)) {
        return Promise.reject(error);
      }

      const {response, config} = error;

      if (!response) {
        return Promise.reject(networkError);
      }

      // запрос был прерван https://jira.edna.ru/browse/EP-690
      if (!response.data) {
        return Promise.reject({data: {code: 0}});
      }

      if (config?.url === SILENT_POLLING_API_URL && response.status !== 401) {
        return Promise.reject(response);
      }

      if (response.status !== 401) {
        if (unavailableStatusSet.has(response.status)) {
          store.dispatch(setServiceAvailability(false));
        }

        return Promise.reject(response);
      }

      if (!storage.get(EStorageKeys.TENANT_ID) && !requestRefreshPromise) {
        return Promise.reject(response);
      }

      try {
        if (!requestRefreshPromise) {
          requestRefreshPromise = axios.post(REFRESH_API_URL);
        }

        try {
          await requestRefreshPromise;
        } finally {
          requestRefreshPromise = null;
        }
      } catch (refreshTokenError) {
        sessionStorage.clear();
        clearStorage();
        store.dispatch(userModel.actions.resetUser());
        store.dispatch(rootApi.util.resetApiState());
        store.dispatch(userModel.actions.removeTenant());

        return Promise.reject((refreshTokenError as TAnyObject).response ?? networkError);
      }

      return request.request(config ?? {});
    },
  );
};

export default requestInterceptors;
