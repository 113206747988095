export default {
  createInvoicePayment: {
    title: 'Сформировать счет на оплату',
    amount: 'Вставьте произвольную сумму, на которую вы бы хотели получить счет',
    warning:
      'Не забудьте при оплате учесть все сторонние комиссии за переводы и операции обмена валют.',
    generateButton: 'Сформировать',
    insertSum: 'Введите сумму',
  },
  stripeInvoice: {
    title: 'Оплатить картой через платежную систему <0></0>',
    topUp: 'Оплатить',
    amount: 'Сумма платежа',
    cancel: 'Отмена',
    warning:
      'Обращаем ваше внимание, что сумма платежа будет зачислена на ваш текущий баланс на основании оферты. При возникновении вопросов по зачислению денежных средств или ошибочному платежу просьба обращаться по адресу support@edna.ru',
    content:
      'Нажимая на кнопку “Оплатить”, вы будете перенаправлены на сайт платёжного сервиса Stripe',
  },
  invoiceGenerated: {
    title: 'Счет сформирован',
    content: `Ваш баланс будет пополнен после зачисления средств. Счет будет доступен в разделе <0>Документы</0>.`,
    download: 'Загрузить счет',
  },
};
