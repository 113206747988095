import {EWidgetType} from './definitions';

export default {
  statistics: {
    [EWidgetType.Licenses]: {
      title: 'Лицензии',
      empty: 'Здесь будут перечислены статусы лицензий',
      toPage: 'Все лицензии',
    },
    [EWidgetType.Payments]: {
      empty: 'Здесь будет указана предварительная сумма ближайшего списания',
      toPage: 'Подробнее',
    },
    [EWidgetType.Templates]: {
      title: 'Изменения в шаблонах',
      empty: 'Здесь будет информация о последних изменениях в шаблонах',
      toPage: 'Все шаблоны',
    },
    [EWidgetType.Broadcasts]: {
      title: 'Последние рассылки',
      empty: 'Здесь будет информация о последних рассылках',
      toPage: 'Все рассылки',
    },
    [EWidgetType.Channels]: {
      title: 'Изменения в каналах',
      empty: 'Здесь будет информация о последних изменениях в каналах',
      toPage: 'Все каналы',
    },
  },
};
