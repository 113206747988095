import {EJoiError} from './definitions';

const arrayMin = 'Must select at least {{count}}';
const arrayMax = 'Must select no more than {{count}}';
const stringMin = 'Must be at least {{count}}';
const stringMax = 'Must be no more than {{count}}';

export default {
  any: {
    only: 'Required',
    required: 'Required',
  },
  boolean: {
    base: 'Required',
  },
  object: {
    and: 'Fill in both fields',
    missing: 'Fill in one of the fields',
  },
  array: {
    min_one: `${arrayMin} item`,
    min_other: `${arrayMin} items`,
    max_one: `${arrayMax} item`,
    max_other: `${arrayMax} items`,
    unique: 'This name is already exists',
  },
  number: {
    base: 'The value must be a number',
    min: 'The value must not be less than {{limit}}',
    max: 'The value must not be greater than {{limit}}',
  },
  string: {
    email: 'Incorrect email',
    min_one: `${stringMin} character`,
    min_other: `${stringMin} characters`,
    max_one: `${stringMax} character`,
    max_other: `${stringMax} characters`,
    hex: 'The field must have a hexadecimal format',
    length_one: `Incorrect field length,  {{count}} character is required`,
    length_other: `Incorrect field length, {{count}} characters are required`,
  },
  date: {
    base: 'Required',
    min: 'Specify the date no earlier than {{limit}}',
    max: 'Specify the date no later than {{limit}}',
  },
  uniqueKey: 'The key with the same name already exists',
  invalidPhone: 'Incorrect phone number',
  invalidPassword: "Password doesn't meet the requirements",
  passwordsIsEqual: 'New password is the same as the old one',
  passwordsDoNotMatch: "Passwords don't match",
  invalidUrl: 'Wrong URL pattern',
  invalidNoEmoji: 'Field contains invalid emoji characters',
  invalidCurrency: 'Invalid amount format',
  minCurrency: 'Amount should be no less than {{minAmount}} {{currency}}',
  invalidMessageMatcher: 'No character % in the template. Incorrect template.',
  invalidGradient: 'Invalid gradient value',
  invalidBIC: 'Incorrect BIC',
  invalidTIN: 'Incorrect TIN',
  invalidKPP: 'Incorrect KPP',
  invalidIBAN: 'Incorrect IBAN',
  invalidTimeFormat: 'Invalid time format',
  chooseTime: 'Choose the time',
  invalidOperBankAccount: 'Incorrect account number',
  invalidCorrBankAccount: 'Incorrect bank account number',
  expiredTime: 'Expired time',
  emptyTime: "Time shouldn't be zero",
  ttlMinSeconds: 'For the {{channelType}} channel, the time value must be from {{limit}} seconds',
  invalidFile: 'Invalid value type, required file',
  invalidSpaceInBegin: 'You cannot use spaces and line breaks at the beginning of the phrase',
  invalidSpaceInEnd: 'Space or line break at the end of a phrase is not allowed',
  invalidTextFormat:
    'Incorrect format. The following characters are not permitted: three or more line breaks in a row, tabs, four or more spaces in a row.',
  invalidTextWithHashFormat:
    "Message text, including strings of characters, can't contain the number sign (#)",
  invalidVariableTextFormat: 'Incorrect format. The line breaks are not permitted',
  emptyVariable: 'Variables must be selected',
  onlyVariable: 'Incorrect format. Text cannot contain only variables',
  maxVariables: 'Incorrect format. Text cannot contain more than {{limit}} variables.',
  numberNotContainPlus: 'Error! Phone number should contain symbol plus',
  passwordHasNotASCII: 'The password contains non-ASCII characters and cannot be applied',
  invalidVibrate: 'The interval value must not be greater than {{limit}}',
  invalidTemplateName:
    'Only lowercase Latin characters, digits, and the underscore character are allowed. The space character is not allowed.',
  invalidTemporaryBlockDays: 'You can deactivate a client for a period of up to {{maxDays}} days',
  invalidMinDimensions: 'Invalid image size. Min size {{minWidth}}x{{minHeight}}px',
  invalidExactDimensions: 'Invalid image size. The image size must be {{minWidth}}x{{minHeight}}px',
  invalidTemplateTextLength_one: 'Message text should not exceed {{count}} character.',
  invalidTemplateTextLength_other: 'Message text should not exceed {{count}} characters.',
  atLeastOnePhone: 'Select the phone number column',
  atLeastOneDay: 'Set at least one day',
  minThreshold: 'The minimum balance is {{limit}} {{currency}}',
  minAmount: 'The minimum deposit is {{limit}} {{currency}}',
  [EJoiError.NOT_SPACED_REGEXPS]: 'Insert space between variables',
};
