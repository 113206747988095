import styled, {Interpolation, css} from 'styled-components';

import {Currency as BaseCurrency, Price as BasePrice} from '@edna/components/Price/style';
import {mixins} from '@edna/components/styles';
import {ESize} from '@edna/components/types';

export * from '@edna/components/Price/style';

export const Currency = styled(BaseCurrency)`
  font-weight: 500;
`;

const SIZE: Record<ESize, Interpolation<object>> = {
  [ESize.XL]: mixins.f12,
  [ESize.L]: mixins.f12,
  [ESize.M]: mixins.f8,
  [ESize.S]: mixins.f5,
  [ESize.XS]: mixins.f5,
};

const CURRENCY_SIZE: Record<ESize, Interpolation<object>> = {
  [ESize.XL]: mixins.f8,
  [ESize.L]: mixins.f8,
  [ESize.M]: mixins.f5,
  [ESize.S]: mixins.f3,
  [ESize.XS]: mixins.f3,
};

export const Price = styled(BasePrice)<{size: ESize}>`
  font-weight: 600;
  ${({size}) => css`
    ${SIZE[size]}
    ${Currency} {
      ${CURRENCY_SIZE[size]}
    }
  `}
`;
