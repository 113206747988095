import styled from 'styled-components';

import {mixins} from '@edna/components/styles';

import BaseStatusIcon from '../StatusIcon';
import {EFooterStatus} from './definitions';

export const StatusIcon = styled(BaseStatusIcon)`
  ${mixins.wh('16px')}
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s1};
`;

type TCheckResultProps = TTheme & {
  status: EFooterStatus;
};

export const CheckResult = styled.div<TCheckResultProps>`
  ${mixins.f2}
  color: ${({theme: {branding}, status}: TCheckResultProps) => {
    switch (status) {
      case EFooterStatus.SUCCESS:
        return branding.colors.success;
      case EFooterStatus.ERROR:
        return branding.colors.error;
      case EFooterStatus.WARNING:
      default:
        return branding.content.secondary;
    }
  }}
`;
