export const CATEGORIES = [
  'Automotive',
  'Beauty, Spa and Salon',
  'Clothing and Apparel',
  'Education',
  'Entertainment',
  'Event Planning and Service',
  'Finance and Banking',
  'Food and Grocery',
  'Public Service',
  'Hotel and Lodging',
  'Medical and Health',
  'Non-profit',
  'Professional Services',
  'Shopping and Retail',
  'Travel and Transportation',
  'Restaurant',
  'Other',
];

export type TLogoId =
  | 'logo50File'
  | 'logo65File'
  | 'logo100File'
  | 'logo130File'
  | 'logo256File'
  | 'logo360File';

export const LOGO_DIMENSIONS: {id: TLogoId; dimensions: [number, number]}[] = [
  {id: 'logo360File', dimensions: [360, 280]},
  {id: 'logo256File', dimensions: [256, 256]},
  {id: 'logo130File', dimensions: [130, 130]},
  {id: 'logo100File', dimensions: [100, 100]},
  {id: 'logo65File', dimensions: [65, 65]},
  {id: 'logo50File', dimensions: [50, 50]},
];
export const LOGO_SIZE = 200 * 1024;
export const LOGO_FORMAT = '.png';

export enum EStatus {
  DRAFT = 'DRAFT',
  CREATE_SUBJECT = 'CREATE_SUBJECT',
  CREATE_SUBJECT_ERROR = 'CREATE_SUBJECT_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  DISABLED = 'DISABLED',
  DONE = 'DONE',
}

export enum EAccountType {
  ONE_WAY = 'ONE_WAY',
  TWO_WAY = 'TWO_WAY',
}

export type TViberChannel = {
  id: number;
  status: EStatus;
  name: string;
  nameEn: string;
  nameRu: string;
  category: string;
  country: string;
  address: string;
  phone?: string;
  companyUrl?: string;
  companyDescription: string;
  messageExample?: string;
  launchDate: Date;
  broadcastCountries: string[];
  accountType: EAccountType;
  logo50File?: TFile;
  logo65File?: TFile;
  logo100File?: TFile;
  logo130File?: TFile;
  logo256File?: TFile;
  logo360File: TFile;
  guaranteeLetterEn: TFile;
  tariffTemplateId: number;
  legalName: string;
  beneficialOwner: string;
};

export type TFormData = Partial<TViberChannel>;

export const INITIAL_VALUES: TFormData = {
  accountType: EAccountType.ONE_WAY,
  broadcastCountries: [],
  name: '',
  nameRu: '',
  nameEn: '',
  category: '',
  country: '',
  companyUrl: '',
  messageExample: '',
  address: '',
  phone: '',
  companyDescription: '',
  legalName: '',
  beneficialOwner: '',
};

export enum EFormSteps {
  SETTINGS = 'SETTINGS',
  COMPANY = 'COMPANY',
  ATTACHMENTS = 'ATTACHMENTS',
}
