import {ELanguage} from 'src/constants';
import {
  CustomTemplateIcon,
  OperatorTemplateIcon,
  UserTemplateIconEn,
  UserTemplateIconRu,
} from 'src/icons';

export enum EApproveStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
  PAUSED = 'PAUSED',
  DISABLED = 'DISABLED',
}

export enum EType {
  OPERATOR = 'OPERATOR',
  USER = 'USER',
  CUSTOM = 'CUSTOM',
}

export const TYPES = Object.values(EType);

export const ICONS_MAP: Record<ELanguage, Record<EType, TAnyComponent>> = {
  [ELanguage.ru]: {
    [EType.OPERATOR]: OperatorTemplateIcon,
    [EType.USER]: UserTemplateIconRu,
    [EType.CUSTOM]: CustomTemplateIcon,
  },
  [ELanguage.en]: {
    [EType.OPERATOR]: OperatorTemplateIcon,
    [EType.USER]: UserTemplateIconEn,
    [EType.CUSTOM]: CustomTemplateIcon,
  },
};

export enum EListAction {
  CREATE_BASED_ON = 'CREATE_BASED_ON',
  DUPLICATE = 'DUPLICATE',
}

export enum EMessageMatcherCategory {
  AccountUpdate = 'ACCOUNT_UPDATE',
  AlertUpdate = 'ALERT_UPDATE',
  AppointmentUpdate = 'APPOINTMENT_UPDATE',
  IssueResolution = 'ISSUE_RESOLUTION',
  PaymentUpdate = 'PAYMENT_UPDATE',
  PersonalFinanceUpdate = 'PERSONAL_FINANCE_UPDATE',
  ReservationUpdate = 'RESERVATION_UPDATE',
  ShippingUpdate = 'SHIPPING_UPDATE',
  TicketUpdate = 'TICKET_UPDATE',
  AutoReply = 'AUTO_REPLY',
  TransportationUpdate = 'TRANSPORTATION_UPDATE',
}

export const MESSAGE_MATCHER_CATEGORIES = Object.values(EMessageMatcherCategory);

export const FieldForDuplicatesMap = [
  'name',
  'description',
  'adminComment',
  'exampleText',
  'category',
  'language',
  'content',
  'contentType',
  'subjectIds',
  'smsProviders',
  'type',
  'channelType',
];

export enum ESubmitType {
  SAVE = 'SAVE',
  REGISTER = 'REGISTER',
  APPROVE = 'APPROVE',
  DRAFT = 'DRAFT',
}

export type TSubmitType = keyof typeof ESubmitType;

export const COLUMNS_WIDTH = ['8%', '30%', '7%', '16%', '16%', '16%', '7%'];

export const TEMPLATE_TEXT_MAX_LENGTH = 1024;

export const VARIABLE_TEXT_MAX_LENGTH = 512;

export const BUTTON_NAME_MAX_LENGTH = 25;

export const MAX_URL_LENGTH = 2000;
export const MAX_CHAT_BUTTON_LENGTH = 250;

export const AUTH_BUTTON_NAME_MAX_LENGTH = 25;
