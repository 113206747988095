import React from 'react';
import {useTranslation} from 'react-i18next';

import {InfoDropdown, showNewNotification} from '@edna/components';
import {FormField} from '@edna/components/FormField';
import {Header as FormHeader} from '@edna/components/FormField/primitives';

import {ImagePickerOldField} from '../ImagePickerOld';
import {UploadTextField} from '../UploadTextField';
import {ACCEPTED_EXTENSIONS, MAX_SIZE_MB} from './definitions';
import * as S from './style';

type TProps = {
  disabled?: boolean;
  readOnly?: boolean;
  label?: React.ReactNode;
  field?: string;
  urlField?: string;
  isUploadField?: string;
  isUploadImage?: boolean;
  resetAttachment: TEmptyFunction;
  resultUploadByUrl: {loaded?: boolean; loading?: boolean} | null;
  onUpload: (value: File | string) => void;
  imageUrlLabel?: string;
  imageUrlHint?: string;
  cropAspect?: number;
  isValidateSize?: boolean;
};

export const ImageField = React.memo<TProps>(
  ({
    label,
    disabled,
    readOnly,
    field = 'content.attachment',
    urlField = 'content.imageUrl',
    isUploadField = 'content.isUploadImage',
    resetAttachment,
    isUploadImage,
    resultUploadByUrl,
    imageUrlLabel,
    imageUrlHint,
    cropAspect,
    isValidateSize,
    onUpload,
  }) => {
    const {t} = useTranslation();

    const handleChangeIsUploadImage = React.useCallback(
      (value) => {
        if (value !== isUploadImage) {
          resetAttachment();
        }
      },
      [isUploadImage, resetAttachment],
    );

    const isFileValid = React.useCallback(
      (payload: File | null) => {
        if (payload instanceof File && !['image/jpeg', 'image/png'].includes(payload.type)) {
          showNewNotification({
            type: 'error',
            message: t('ImageField:invalidImageFormat', {formats: 'JPEG, PNG'}),
          });

          return false;
        }

        if (isValidateSize && payload instanceof File && payload.size > MAX_SIZE_MB * 1024 * 1024) {
          showNewNotification({
            type: 'error',
            message: t('ImageField:invalidImageSize', {size: MAX_SIZE_MB}),
          });

          return false;
        }

        return true;
      },
      [t, isValidateSize],
    );

    return (
      <FormField noMargin>
        <FormHeader>
          <S.FieldLabel>{label ?? t('ImageField:imageLabel')}</S.FieldLabel>
        </FormHeader>
        <S.NewRadioField
          name={isUploadField}
          onChange={handleChangeIsUploadImage}
          type="radio"
          value
          disabled={disabled}
          readOnly={readOnly}
        >
          {t('ImageField:uploadImage')}
        </S.NewRadioField>
        <S.NewRadioField
          name={isUploadField}
          onChange={handleChangeIsUploadImage}
          type="radio"
          value={false}
          disabled={disabled}
          readOnly={readOnly}
        >
          {t('ImageField:addImageLink')}
        </S.NewRadioField>
        <S.NestedBlock>
          {isUploadImage && (
            <ImagePickerOldField
              hasCropper
              isFull
              accept={ACCEPTED_EXTENSIONS.join(', ')}
              name={field}
              onUpload={(files) => onUpload(files[0])}
              disabled={disabled}
              readOnly={readOnly}
              isFileValid={isFileValid}
              cropperOptions={{aspect: cropAspect}}
              isCastJPEG={isValidateSize}
            />
          )}
          {!isUploadImage && (
            <UploadTextField
              resultUploadByUrl={resultUploadByUrl}
              disabled={disabled}
              readOnly={readOnly}
              field={urlField}
              label={
                <>
                  <S.TextLabel>{imageUrlLabel ?? t('ImageField:imageUrlLabel')}</S.TextLabel>
                  <InfoDropdown
                    hasMarginLeft
                    content={imageUrlHint ?? t('ImageField:imageUrlHint')}
                  />
                </>
              }
              onChange={onUpload}
            />
          )}
        </S.NestedBlock>
      </FormField>
    );
  },
);

ImageField.displayName = 'ImageField';

export default ImageField;
