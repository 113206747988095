import {EAutoCreditType, EAutoDebitType, ETariffType} from './definitions';

export default {
  total: 'Total: ',
  allTransactions: 'Debited from the account <0></0>, credited to the account <1></1>',
  credit: 'Сredited to the account {{value}}',
  debit: 'Debited from the account {{value}}',
  button: {
    downloadList: 'Download list',
  },
  listFileName: 'Statement.xlsx',
  th: {
    date: 'Date',
    dailyStatement: 'Balancing statement for the day',
    createdAt: 'Transaction date',
    amount: 'Amount',
    description: 'Transaction description',
  },
  filters: {
    title: 'Filters',
    reset: 'Reset',
    debit: 'Debit',
    credit: 'Credit',
    allOperations: 'All transactions',
  },
  [ETariffType.SERVICE]: 'Service messages',
  [ETariffType.HSM]: 'Advertising messages',
  [ETariffType.MULTI]: 'Multinaming',
  item: {
    statement: `Statement (login {{login}})`,
    changed: 'Changed',
    cancelled: 'Cancelled:',
    debit: 'Debited – {{value}}',
    credit: 'Credited – {{value}}',
    debitTh: {
      type: 'Type',
      count: 'Count',
      amount: 'Amount',
    },
    creditTh: {
      paymentPurpose: 'Payment purpose description',
      amount: 'Amount',
      invoice: 'Payment for licenses and services',
    },
    number: ' (number {{channelAttribute}})',
    [EAutoDebitType.WHATSAPP_ABOVE_MAU_MAP]: `Message routing services via edna Pulse, {{subject}} account{{number}}. AMAU`,
    [EAutoDebitType.WHATSAPP_CONVERSATION]: `Opening a conversation via edna Pulse, {{subject}} account{{number}}. Conversations`,
    [EAutoDebitType.WHATSAPP_LICENSE_INIT]: `Activation of the license to use edna Pulse with WhatsApp channel connection (MAU {{mauCount}}), {{subject}} account{{number}}, {{month}} {{year}}.`,
    [EAutoDebitType.WHATSAPP_LICENSE_RENEWAL]: `Renewal of the license to use edna Pulse with WhatsApp channel connection (MAU {{mauCount}}), {{subject}} account{{number}}, {{month}} {{year}}.`,
    [EAutoDebitType.WHATSAPP_CONNECTION_FEE_OPERATIONS]: `Connection of a phone number {{number}} MAU {{mauCount}}, {{month}} {{year}}.`,
    [EAutoDebitType.SMS_LICENSE_INIT]: `Activation of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EAutoDebitType.SMS_LICENSE_RENEWAL]: `Renewal of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EAutoDebitType.VIBER_LICENSE_INIT]: `Activation of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EAutoDebitType.VIBER_LICENSE_RENEWAL]: `Renewal of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EAutoDebitType.VIBER_SUBJECTS]: `Message routing services via edna Pulse, {{subject}} account. {{tariff}}`,
    [EAutoDebitType.VIBER_RECURRENT_PAYMENT]: `Viber minimum fee over the period of {{month}} {{year}}, account {{subject}}.  `,
    [EAutoDebitType.SMS_SUBJECTS]: `Message routing services via edna Pulse, alphanumeric signature {{subject}}. {{tariff}}`,
    [EAutoDebitType.SMS_RECURRENT_PAYMENT]: `Subscription fee for using the alphanumeric name (signatures of {{subject}} SMS messages, {{mobileOperator}} operator), {{month}} {{year}}.`,
    [EAutoDebitType.VIBER_CORRECTION_TRANSACTION_REMOVE]: `Balance adjustment (foreign currency revaluation)`,
    [EAutoDebitType.WHATSAPP_CORRECTION_TRANSACTION_REMOVE]: `Balance adjustment (foreign currency revaluation)`,
    [EAutoCreditType.VIBER_CORRECTION_TRANSACTION_ADD]: `Balance adjustment (foreign currency revaluation)`,
    [EAutoCreditType.WHATSAPP_CORRECTION_TRANSACTION_ADD]: `Balance adjustment (foreign currency revaluation)`,
  },
};
