import React from 'react';

import {InfoDropdown} from '@edna/components';
import {Label} from '@edna/components/FormField/primitives';
import {TPlacementX, TPlacementY} from '@edna/components/types';

type Props = {
  children: React.ReactNode;
  hint?: string | React.ReactNode;
  className?: string;
  placementX?: TPlacementX;
  placementY?: TPlacementY;
  htmlFor?: string;
  id?: string;
};

const LabelWithHint = React.memo<Props>(
  ({children, hint, className, placementX, placementY, htmlFor, id}) => (
    <Label className={className} htmlFor={htmlFor} id={id}>
      {children}
      <InfoDropdown hasMarginLeft content={hint} placementX={placementX} placementY={placementY} />
    </Label>
  ),
);

export default LabelWithHint;
