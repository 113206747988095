export enum EChannelStatus {
  NO_CONNECTED_APPS = 'NO_CONNECTED_APPS',
  ACTIVE = 'ACTIVE',
  NEED_ATTENTION = 'NEED_ATTENTION',
  DISABLED = 'DISABLED ',
}

export type TPushChannel = {
  id: number;
  name: string;
  status: EChannelStatus;
};

export type TFormData = {
  name: string;
};
