import {isProdDomain} from 'src/constants';

const YM_ID = 95865849;

enum EMetricsType {
  CLICK = 'CLICK',
}

enum EMetricsId {
  ADD_CLIENTS_LIST = 'AUDIENCE_ADD-CLIENTS-LIST',
  ADD_CLIENT = 'AUDIENCE_ADD-CLIENT',
  ADD_TEMPLATE = 'TEMPLATES_ADD-TEMPLATE',
  SAVE_TEMPLATE = 'TEMPLATES_SAVE-TEMPLATE',
  SAVE_DRAFT_TEMPLATE = 'TEMPLATES_SAVE-DRAFT-TEMPLATE',
  ADD_CASCADE = 'CASCADES_ADD-CASCADE',
  SAVE_CASCADE = 'CASCADES_SAVE-CASCADE',
  ADD_BROADCAST = 'BROADCASTS_ADD-BROADCAST',
  ASSIGN_BROADCAST_TAG = 'BROADCASTS_ASSIGN-TAG',
  ADD_BROADCAST_SUBSCRIBERS = 'BROADCASTS_ADD-SUBSCRIBERS',
  PROFILE_DOWNLOAD_BILLING_STATISTICS = 'PROFILE_DOWNLOAD-BILLING-STATISTICS',
}

type TMetricsId = keyof typeof EMetricsId;

const YMCategoryMap = {
  [EMetricsType.CLICK]: 'reachGoal',
};

const GACategoryMap = {
  [EMetricsType.CLICK]: 'click',
};

type TSendMetrics =
  | TMetricsId
  | {
      id: TMetricsId;
      category?: keyof typeof EMetricsType;
      params?: TAnyObject;
    };

const sendMetrics = (data: TSendMetrics) => {
  if (!isProdDomain()) {
    return;
  }

  if (typeof data === 'string') {
    window.ym?.(YM_ID, YMCategoryMap.CLICK, data);
    window.gtag?.('event', GACategoryMap.CLICK, {event_category: data});

    return;
  }

  const {id, category = EMetricsType.CLICK, params = {}} = data;

  window.ym?.(YM_ID, YMCategoryMap[category], id);
  window.gtag?.('event', GACategoryMap[category], params);
};

const sendPageMetrics = (pathname: string) => {
  if (!isProdDomain()) {
    return;
  }

  window.gtag?.('event', 'page_view', {
    page_path: pathname,
  });
};

export {sendMetrics, sendPageMetrics};
