import {TFunction} from 'react-i18next';

import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import ru from 'i18n-iso-countries/langs/ru.json';

import {ELanguage} from '../constants';
import {getCurrentLang} from './getCurrentLang';

type TPriorityHash = Record<string, number>;

const priorityHashISO2: TPriorityHash = {
  RU: 1,
  // KZ: 2,
  // UA: 3,
};
const priorityHashISO3: TPriorityHash = {
  RUS: 1,
  // KAZ: 2,
  // UKR: 3,
};
const sortedOptions = {
  ignorePunctuation: true,
};

const isRuLang = getCurrentLang() === ELanguage.ru;

const prepareCountryHash = (map: Record<string, string | string[]>, isISO3?: boolean) => {
  const result: Record<string, string> = {};

  Object.entries(map).forEach(([key, value]) => {
    const newKey = isISO3 ? countries.alpha2ToAlpha3(key) : key;

    result[newKey] = Array.isArray(value) ? value[0] : value;
  });

  return result;
};

const sortByHashPriority = (hash: TPriorityHash) => (a: string, b: string) => {
  if (!hash[a] && !hash[b]) {
    return 0;
  }

  if (!hash[a]) {
    return 1;
  }

  return hash[b] ? hash[a] - hash[b] : -1;
};

const sortLocalList = (hash: Record<string, string>, local: string) =>
  Object.keys(hash).sort((a, b) => hash[a].localeCompare(hash[b], local, sortedOptions));

const priorityByLocal = (arr: string[], priority: TPriorityHash) =>
  arr.sort(sortByHashPriority(priority));

const ISO2RuCountryHash = prepareCountryHash(ru.countries);
const ISO2EnCountryHash = prepareCountryHash(en.countries);

const ISO2RuCountryCodeList = sortLocalList(ISO2RuCountryHash, 'ru');
const ISO2EnCountryCodeList = sortLocalList(ISO2EnCountryHash, 'en');

const ISO2CountryCodeList = isRuLang
  ? priorityByLocal(ISO2RuCountryCodeList, priorityHashISO2)
  : ISO2EnCountryCodeList;

const ISO3RuCountryHash = prepareCountryHash(ru.countries, true);
const ISO3EnCountryHash = prepareCountryHash(en.countries, true);

const ISO3RuCountryCodeList = sortLocalList(ISO3RuCountryHash, 'ru');
const ISO3EnCountryCodeList = sortLocalList(ISO3EnCountryHash, 'en');

const ISO3CountryCodeList = isRuLang
  ? priorityByLocal(ISO3RuCountryCodeList, priorityHashISO3)
  : ISO3EnCountryCodeList;

const getCountryOptions = (t?: TFunction) =>
  ISO2CountryCodeList.map((code) => ({
    label: t ? t(`Country:iso2.${code}`) : code,
    value: code,
  }));

export {
  ISO2RuCountryHash,
  ISO2EnCountryHash,
  ISO3RuCountryHash,
  ISO3EnCountryHash,
  ISO2CountryCodeList,
  ISO3CountryCodeList,
  getCountryOptions,
};
