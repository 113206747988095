import React from 'react';

import * as SS from '../style';
import {FOOTER_TIME} from '../utils';
import * as S from './style';

type TProps = {
  text: React.ReactNode;
};

const OkNotifyContent = React.memo<TProps>(({text}) => {
  return (
    <S.Message>
      <SS.MessageContent display="inline-block">
        <S.TextContainer>
          {text}
          <SS.Time>
            <span>{FOOTER_TIME}</span>
          </SS.Time>
        </S.TextContainer>
      </SS.MessageContent>
    </S.Message>
  );
});

OkNotifyContent.displayName = 'OkNotifyContent';

export default OkNotifyContent;
