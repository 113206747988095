import React from 'react';
import {useTranslation} from 'react-i18next';

import debounce from 'lodash/debounce';

import {NewTextInputField} from '@edna/components';

import {DEFAULT_DEBOUNCE_DELAY} from 'src/constants';

import FooterStatus from '../FooterStatus';
import {EFooterStatus} from '../FooterStatus/definitions';

type TProps = {
  field: string;
  label: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  resultUploadByUrl: {loaded?: boolean; loading?: boolean} | null;
  onChange: (value: string) => void;
  hideSuccessStatus?: boolean;
};

export const UploadTextField = React.memo<TProps>(
  ({field, label, onChange, resultUploadByUrl, readOnly, disabled, hideSuccessStatus}) => {
    const {t} = useTranslation();

    const debouncedOnChange = React.useMemo(
      () =>
        debounce(
          (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value),
          DEFAULT_DEBOUNCE_DELAY,
        ),
      [onChange],
    );

    const getFooterStatus = () => {
      if (resultUploadByUrl?.loading) {
        return EFooterStatus.LOADING;
      }

      if (resultUploadByUrl?.loaded) {
        return EFooterStatus.SUCCESS;
      }

      return EFooterStatus.ERROR;
    };

    return (
      <NewTextInputField
        name={field}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        onChange={debouncedOnChange}
        footer={
          resultUploadByUrl &&
          (!hideSuccessStatus || !resultUploadByUrl.loaded) && (
            <FooterStatus
              status={getFooterStatus()}
              successLabel={t('Common:loadingByUrlSuccess')}
              errorLabel={t('Common:loadingByUrlError')}
            />
          )
        }
      />
    );
  },
);

UploadTextField.displayName = 'UploadTextField';

export default UploadTextField;
