import {Dispatch} from 'redux';

import isFunction from 'lodash/isFunction';

import baseApiModel, {TActionCreator, TOptions, TReducerMap, TThunks} from '@edna/models/apiModel';

export type TApiState = {
  error: TAnyObject | null;
};

type TApiActions<State extends TApiState = TApiState> = {
  setError: TActionCreator<State['error']>;
  resetError: TActionCreator;
  reset: TActionCreator<Partial<State> | void>;
};

type TApiReducers<State extends TApiState, Actions extends TApiActions> = TReducerMap<
  State,
  Actions
>;

export type TApiOptions<
  TId extends string,
  Static extends TAnyObject,
  State extends TApiState,
  Actions extends TApiActions,
  Selectors extends TAnyObject,
> = Omit<TOptions<TId, Static, State, Actions, Selectors>, 'defaultState'> & {
  defaultState?: Omit<State, keyof TApiState> & Partial<TApiState>;
};

const apiModel = <
  TId extends string,
  Static extends TAnyObject = TAnyObject,
  State = TApiState,
  Actions = TApiActions,
  Selectors extends TAnyObject = TEmptyObject,
>(
  options: TApiOptions<TId, Static, State & TApiState, Actions & TApiActions, Selectors>,
) => {
  type TState = State & TApiState;
  type TActions = Actions & TApiActions;

  return baseApiModel<TId, Static, TState, TActions, Selectors>({
    ...options,
    defaultState: {
      ...((options.defaultState ?? {}) as TState),
      error: options.defaultState?.error ?? null,
    },
    reducers: ({defaultState, ...ctx}) => ({
      setError: (state, {payload}) => ({
        ...state,
        error: payload,
      }),
      resetError: (state) => ({
        ...state,
        error: null,
      }),
      reset: (state, {payload}) => ({
        ...defaultState,
        ...payload,
      }),
      ...(isFunction(options.reducers)
        ? options.reducers({defaultState, ...ctx})
        : ((options.reducers ?? {}) as TApiReducers<TState, TActions>)),
    }),
    thunks: (ctx) => ({
      ...(isFunction(options.thunks) ? options.thunks(ctx) : ({} as TThunks<TActions>)),
    }),
  });
};

export default apiModel;

export const dispatchHelper = <
  A extends Record<string, TAnyFunction> = Record<string, TAnyFunction>,
>(
  dispatch: Dispatch,
  actions: A,
) =>
  Object.entries(actions).reduce<A>(
    (result, [key, handler]) => ({
      ...result,
      [key]: (...args) => dispatch(handler(...args)),
    }),
    {} as A,
  );
