import styled, {css} from 'styled-components';

import {TSize} from '@edna/components';
import {StatefulCollapse as BaseStatefulCollapse} from '@edna/components/Collapse';
import {
  Td as BaseTd,
  Th as BaseTh,
  Tr as BaseTr,
  TTdProps,
  getTableCellWidth,
} from '@edna/components/Table/style';
import {TTheme} from '@edna/components/styles';

type TStatefulCollapseProps = TTheme & {
  size: TSize;
};

export const StatefulCollapse = styled(BaseStatefulCollapse)<TStatefulCollapseProps>`
  ${({theme: {spacing, palette}, size}: TStatefulCollapseProps) => css`
    padding-bottom: ${size === 'L' ? spacing.s4 : spacing.s2};
    &:not(:last-child) {
      margin-bottom: ${spacing.s4};
      border-bottom: 1px solid ${palette.grey100};
    }
  `};
`;

type TSectionProps = TTheme & {
  isScroll?: boolean;
};

export const Section = styled.div<TSectionProps>`
  ${({theme: {spacing}, isScroll}: TSectionProps) => css`
    margin-top: ${spacing.s4};
    overflow-x: ${isScroll ? 'scroll' : 'visible'};
  `}
`;

type TDropDownTableProps = TTheme & {
  width?: string;
};

export const DropdownTable = styled.div<TDropDownTableProps>`
  ${({theme: {borderRadius, spacing}, width}: TDropDownTableProps) => css`
    min-width: ${width ?? '550px'};
    padding: ${spacing.s4} ${spacing.s8};
    border-radius: ${borderRadius.br4};
  `}
`;

type TColumnProps = TTdProps & {
  columnsWidth?: string[];
};

const getTableCellStyle = ({theme, columnsWidth}: TColumnProps) => css`
  ${getTableCellWidth(columnsWidth ?? [])}
  padding: ${theme.spacing.s4} ${theme.spacing.s2};
  &:first-child {
    padding-left: ${theme.spacing.s4};
  }
`;

export const Table = styled.div<TColumnProps>`
  flex: 1 1 auto;
  overflow: visible;
  ${BaseTd}, ${BaseTh} {
    ${getTableCellStyle}
  }
`;

export const Tr = styled(BaseTr)<TColumnProps>`
  &:last-child {
    border-bottom: none;
  }
`;

export const Th = styled(BaseTh)<TColumnProps>`
  ${getTableCellStyle}
`;

export const Td = styled(BaseTd)<TColumnProps>`
  ${getTableCellStyle}
`;

export const ReportTableContent = styled.div`
  ${({theme}: TTheme) => css`
    margin: ${theme.spacing.s4};
  `}
`;
