import formatDate from '@edna/utils/formatDate';

import {EDirection, TItem} from './types';

export const getDownloadFileName = () => {
  const date = formatDate(new Date(), {format: 'yyyy-MM-dd'});

  return `report-${date}.csv`;
};

const getValueBySubject = (subject: TItem['subject'] | TItem['subscriber']) =>
  subject?.name || subject?.address;

export const getRecipient = (item: TItem) => {
  const subject = item.direction === EDirection.IN ? item.subject : item.subscriber;

  return getValueBySubject(subject);
};

export const getSender = (item: TItem) => {
  const subject = item.direction === EDirection.IN ? item.subscriber : item.subject;

  return getValueBySubject(subject);
};
