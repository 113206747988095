import React from 'react';

const useAccumulatingArray = <T,>(array?: T[]): T[] => {
  const [accumulatedArray, setAccumulatedArray] = React.useState<T[]>([]);

  React.useEffect(() => {
    if (array?.length) {
      setAccumulatedArray((prevArray) => [...prevArray, ...array]);
    }
  }, [array]);

  return accumulatedArray;
};

export default useAccumulatingArray;
