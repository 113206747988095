const enumToBooleanHash = <V extends string = any>(object: Record<V, V>, defaultValue = false) => {
  const result = {} as Record<V, boolean>;

  Object.values<V>(object).forEach((item) => {
    result[item] = defaultValue;
  });

  return result;
};

const booleanHashToArray = <V extends string = any>(hash?: Record<V, boolean>): V[] => {
  const result: V[] = [];

  if (hash) {
    Object.entries(hash).forEach(([key, value]) => {
      if (value) {
        result.push(key as V);
      }
    });
  }

  return result;
};

const arrayToBooleanHash = <V extends string = any>(array: V[]): Record<V, boolean> => {
  const result = {} as Record<V, boolean>;

  array.forEach((item) => {
    result[item] = true;
  });

  return result;
};

export {enumToBooleanHash, booleanHashToArray, arrayToBooleanHash};
