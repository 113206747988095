import React from 'react';

import {Icon} from '@edna/components/primitives';

import {TSubject, channelIconHash} from 'src/constants';

import * as S from './style';

type TProps = {
  className?: string;
  name?: TSubject['name'];
  type: TSubject['type'];
};

const Subject = React.memo<TProps>(({className, type, name}) => (
  <S.Subject className={className}>
    <Icon as={channelIconHash[type]} size="20px" />
    <S.SubjectName title={name}>{name}</S.SubjectName>
  </S.Subject>
));

export default Subject;
