export default {
  backAuthorization: 'Back to authorization',
  requestNewCodeButton: 'Request new code',
  changePassword: {
    title: 'Change password',
    changePasswordButton: 'Change the password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    repeatPassword: 'Repeat new password',
    passwordSaved: 'Password saved!',
    passwordSavedHint: 'You are well protected',
    signIn: 'Sign in to your account',
  },
  signIn: {
    forgotPassword: 'Forgot password?',
    password: 'Password',
    email: 'E-mail',
    captchaLabel: 'Enter the characters you see',
    signInButton: 'Sign in',
    signUpButton: 'No account? <0>Sign up</0>',
    contactUs:
      "Don't have an account?<br/>Contact us by phone:<br/><0>+7 (495) 609-60-80</0> ext. <0>177</0>",
  },
  signUpBlocked: {
    confirmationSendBlocked: {
      message1: 'Please, contact the support:',
      message2: 'The maximum number of registration attempts has been exceeded.',
    },
    confirmationSendAny: {
      message1: 'Phone verification',
      message2:
        'The maximum number of user access<br/> attempts has been exceeded. Try again in {{countdown}}.',
    },
  },
  signUp: {
    country: 'Country',
    phone: 'Phone',
    phoneHint: '(including country code)',
    authorizedPersonName: 'Last name and first name',
    companyName: 'Company name',
    email: 'E-mail',
    password: 'Password',
    confirmTermsOfUse: 'By clicking the button you agree to the<br/> <0>Terms of service</0>',
    haveAccount: 'Already have an account? ',
    registerButton: 'Register',
    signInLink: 'Sign in',
  },
  signUpConfirm: {
    title: 'Phone verification',
    verificationCodeSubmitted: 'The verification code has been sent to your phone number',
    expiredConfirm:
      'Unfortunately, the validation code has expired.<br/> Request a new confirmation code',
    requestCodeTimeout: 'You can request the code again in',
    haveAccount: 'Already have an account? ',
    signInLink: 'Sign in',
  },
  emailConfirm: {
    title: 'E-mail verification',
    verificationCodeSubmitted:
      'The verification code has been sent to your corporate e-mail address',
    requestCodeTimeout: 'You can request the code again in',
    sendNewCode: 'Send a new code',
    requestAnotherCode: 'Request another code',
    expiredConfirm: 'The validation code has expired. Do you want a new code to be sent?',
  },
};
