import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Button} from '@edna/components';
import {useExactSelector} from '@edna/hooks';
import DeleteIcon from '@edna/icons/filter.svg';

import {Message} from 'src/components';
import {BigText, SecondaryText} from 'src/components/primitives';
import {EPermission} from 'src/containers/Auth/definitions';
import userModel from 'src/containers/Auth/userModel';

import {TCallbackUrl} from '../definitions';
import * as SS from '../style';
import {
  showAddCallbackUrlModal,
  showChangeCallbackUrlModal,
  showDeleteCallbackUrlModal,
} from './modals';
import * as S from './style';

type TProps = {
  callbackUrl: TCallbackUrl;
  disabled: boolean;
  hasJivo?: boolean;
};

const CallBackUrl = React.memo<TProps>(({callbackUrl, disabled, hasJivo}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const {type, url} = callbackUrl;
  const userPermissions = useExactSelector(userModel.selectors.userPermissions);
  const canChangeCallback =
    !supportTenantId || userPermissions.includes(EPermission.PERMISSION_TENANT_CALLBACK_WRITE);
  const callbackDisabled = disabled || !canChangeCallback;

  const showChangeModal = React.useCallback(
    () => showChangeCallbackUrlModal(callbackUrl),
    [callbackUrl],
  );
  const showDeleteModal = React.useCallback(
    () => showDeleteCallbackUrlModal(callbackUrl),
    [callbackUrl],
  );
  const showAddModal = React.useCallback(() => showAddCallbackUrlModal(type), [type]);

  return (
    <SS.Content key={type}>
      <BigText>{t(`CompanyCallback:callbackUrlType.${type}.title`)}</BigText>
      {hasJivo && <Message type="Info">{t('CompanyCallback:jivoConnectedMessage')}</Message>}
      <SecondaryText bottomIndent="4">
        {t(`CompanyCallback:callbackUrlType.${type}.description`)}
      </SecondaryText>
      {url && (
        <SecondaryText bottomIndent="4">
          <S.BlockLink href={url}>{url}</S.BlockLink>
        </SecondaryText>
      )}
      <SS.CenterRow>
        {url && (
          <>
            <S.DeleteButton
              square
              onClick={showDeleteModal}
              disabled={callbackDisabled}
              data-selector="CompanyCallback:delete"
            >
              <S.DeleteIcon as={DeleteIcon} />
            </S.DeleteButton>
            <Button
              appearance="secondary"
              onClick={showChangeModal}
              disabled={callbackDisabled}
              data-selector="CompanyCallback:changeCallback"
            >
              {t('CompanyCallback:changeCallback')}
            </Button>
          </>
        )}
        {!url && (
          <Button
            appearance="secondary"
            onClick={showAddModal}
            disabled={callbackDisabled}
            data-selector="CompanyCallback:addCallback"
          >
            {t('CompanyCallback:addCallback')}
          </Button>
        )}
      </SS.CenterRow>
    </SS.Content>
  );
});

CallBackUrl.displayName = 'CallBackUrl';

export default CallBackUrl;
