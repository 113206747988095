export default {
  successTitle: 'Добрый день, {{name}} из {{companyName}}!',
  successText: 'Ваш аккаунт в edna Pulse создан!',
  linkRequested: 'Запрос новой ссылки для регистрации отправлен успешно',
  setPassword:
    'Вам необходимо придумать пароль, который в дальнейшем будет использоваться для входа в Личный кабинет.',
  yourLogin: 'Ваш логин',
  createPassword: 'Задайте пароль',
  repeatPassword: 'Повторите пароль',
  signIn: 'Войти в систему',
  acceptTerms:
    'Нажимая кнопку, вы согласны с условиями <0>Общих положений о предоставлении прав на использование программного обеспечения «edna»</0>',
  linkExpiredTitle: 'Ссылка больше недействительна',
  linkExpiredText: 'К сожалению, 24 часа действия ссылки истекли',
  requestLinkTitle: 'Запросите новую ссылку для регистрации и наш менеджер пришлет вам ее на почту',
  requestLink: 'Запросить повторно',
  linkAlreadyRequested: 'Вы уже запросили новую ссылку',
  wait: 'Надо немного подождать',
  requestLinkText:
    'Наш менеджер создаст вам для входа в систему новую ссылку и обязательно об этом сообщит',
};
