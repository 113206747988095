import {saveAs} from 'file-saver';

import getPeriod from '@edna/utils/getPeriod';

import {TData, TPayload} from 'src/components/List';
import {EChannelType} from 'src/constants';
import {CACHE_LIFETIME, listenerMiddleware, rootApi} from 'src/models/api';
import {booleanHashToArray, enumToBooleanHash} from 'src/utils';

import {selectFilters, setFiltersAction} from './slice';
import {EDeliveryStatus, EDirection, ETrafficType, TItem} from './types';
import {getDownloadFileName} from './utils';

export const LIST_CONFIG = {
  filtersConfig: {
    defaultFilters: {
      address: '',
      period: getPeriod('hours24'),
      channelTypes: enumToBooleanHash(EChannelType),
      deliveryStatuses: enumToBooleanHash(EDeliveryStatus),
      trafficTypes: enumToBooleanHash(ETrafficType),
      directions: enumToBooleanHash(EDirection),
      broadcasts: [],
      cascades: [],
      subjects: [],
    },
    action: setFiltersAction,
    selector: selectFilters,
  },
};

const isValidPeriod = (period: any): period is ReturnType<typeof getPeriod> =>
  typeof period === 'object' && 'period' in period && 'startDate' in period && 'stopDate' in period;

const prepareFilters = ({period, ...payload}: TAnyObject): TAnyObject => {
  const newPeriod =
    isValidPeriod(period) && !!period.startDate && !!period.stopDate
      ? period
      : getPeriod('hours24');

  return {
    ...payload,
    timeFrom: newPeriod.startDate,
    timeTo: newPeriod.stopDate,
    trafficTypes: booleanHashToArray(payload.trafficTypes),
    directions: booleanHashToArray(payload.directions),
    deliveryStatuses: booleanHashToArray(payload.deliveryStatuses).flatMap((status) =>
      status === EDeliveryStatus.FAILED
        ? [EDeliveryStatus.FAILED, EDeliveryStatus.INVALID, EDeliveryStatus.ACCEPTED]
        : status,
    ),
    channelTypes: booleanHashToArray(payload.channelTypes),
  };
};

const api = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getReport: build.query<TData<TItem>, TPayload>({
      query: (data) => ({
        method: 'POST',
        url: '/report/message',
        data: prepareFilters(data),
        meta: {isShowError: true},
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
    }),
    downloadReport: build.mutation<
      Blob,
      {filters: TAnyObject; locale: string; totalElements: number}
    >({
      query: (data) => ({
        method: 'POST',
        url: '/report/message/download',
        data: {
          offset: 0,
          limit: data.totalElements,
          locale: data.locale,
          ...prepareFilters(data.filters),
        },
        responseType: 'blob',
        meta: {isShowError: true},
      }),
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: api.endpoints.downloadReport.matchFulfilled,
  effect: ({payload}) => {
    saveAs(payload, getDownloadFileName());
  },
});

export const {useGetReportQuery, useDownloadReportMutation} = api;
