import React from 'react';

import {SecondaryText} from '../primitives';
import {EPosition, ETour, ONBOARDING_IDS, TTour} from './definitions';

const TOURS: Record<ETour, TTour> = {
  [ETour.COMPANY_PROFILE]: {
    steps: [
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.BALANCE,
        content: (
          <SecondaryText size="S">
            Первое и самое главное – ваш баланс на сегодня и кнопка для быстрого пополнения
          </SecondaryText>
        ),
        position: EPosition.LEFT,
      },
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.LICENSES,
        content: (
          <SecondaryText size="S">
            Здесь количество и статус всех ваших лицензий в каждом из каналов
          </SecondaryText>
        ),
        position: EPosition.RIGHT,
      },
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.PAYMENT_TAB,
        content: (
          <SecondaryText size="S">
            Все, что касается пополнения баланса, выписок по операциям, атоплатежам
          </SecondaryText>
        ),
        position: EPosition.RIGHT,
      },
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.LICENSE_TAB,
        content: (
          <SecondaryText size="S">
            Здесь хранится информация по статусам и срокам действия каждой вашей лицензии. Здесь
            можно отключить лицензию
          </SecondaryText>
        ),
        position: EPosition.RIGHT,
      },
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.REQUISITES_TAB,
        content: <SecondaryText size="S">Ваши и наши реквизиты</SecondaryText>,
        position: EPosition.RIGHT,
      },
      {
        onboardingId: ONBOARDING_IDS.COMPANY_PROFILE.PRICING_TAB,
        content: (
          <SecondaryText size="S">
            Активные и доступные тарифы по каждой вашей лицензии
          </SecondaryText>
        ),
        position: EPosition.RIGHT,
      },
    ],
  },
};

export {TOURS};
