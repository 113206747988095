import {EChannelStatus} from 'src/containers/PushChannel/definitions';

import {EAppStatus, EPushAppType} from './definitions';

export default {
  appData: 'App data',
  appStatus: {
    [EAppStatus.DEVICES_CONNECTED]: {
      title: 'Connected',
      details: 'The app is configured with no errors ',
    },
    [EAppStatus.DEVICES_NOT_CONNECTED]: {
      title: 'Not connected',
      details: 'App configuration is not complete',
    },
    [EAppStatus.ERROR]: {
      title: 'Error',
      details: 'Error in app configuration',
    },
    [EAppStatus.BLOCKED]: {
      title: 'Blocked',
      details: 'The app is blocked',
    },
  },
  copy: 'Copy',
  change: 'Change',
  update: 'Update',
  application: 'App',
  appRegistrationDate: 'App registration date',
  connect: 'Connect',
  ednaKey: 'edna Pulse access key',
  appPackage: 'App package name',
  serviceJson: 'Google service account JSON file',
  attachJson: 'Upload JSON',
  changeJson: 'Update JSON',
  serviceJsonWarning:
    'To continue the channel operation, upload the Google service account JSON file. <0>How to get the file</0>',
  registrationType: 'Method of device registration in edna Pulse',
  automaticRegistrationText:
    'Automatic registration: all devices are automatically registered in edna Pulse and can receive advertising and marketing messages',
  automaticRegistrationInfo:
    'To send confidential messages, we recommend implementing a manual method of registering devices in edna Pulse',
  manualRegistrationText:
    'Manual registration: your backend registers devices via <0>the registration method of the public API</0> – suitable for sending all types of messages',
  setUpManualRegistration: 'Configure manual registration',
  bundleID: 'BundleID',
  productionCertificatePassword: '.p12 certificate password',
  productionCertificateFile: '.p12 certificate file',
  certificateExpiredAt: 'The certificate expires',
  huaweiApp: {
    title: 'Huawei App',
    huaweiAppId: 'Huawei Client ID',
    huaweiAppSecret: 'Huawei Client Secret',
    [EAppStatus.DEVICES_CONNECTED]: {
      title: 'The app is connected',
      badge: 'Connected',
    },
    [EAppStatus.DEVICES_NOT_CONNECTED]: {
      title: 'The app is not connected',
      badge: 'Not connected',
    },
    hints: {
      huaweiAppId: 'Huawei Client Id from Huawei Console',
      huaweiAppSecret: 'Huawei Client Secret Key from Huawei Console',
    },
  },
  hints: {
    appRegistrationDate: 'Date of app registration in edna Pulse',
    ednaKey: 'edna Pulse access key',
    appPackage: 'App package name, for example, io.edna.app',
    registrationType:
      'With automatic registration, edna library registers the device in edna after calling the login method on the mobile app side. This method is simpler, does not require you to get the device address and manage registration from the backend, but is not recommended for sending sensitive data. \n' +
      'In manual registration, your backend must obtain the device address from the mobile app and register the device with the edna service via <0>the registration method of the public API</0>',
    bundleID: 'BundleID',
    productionCertificateFile:
      'Certificate for sending push notifications from developer.apple.com exported in the .p12 format',
    productionCertificatePassword:
      'Password to the .p12 certificate entered when exporting from Keychain. This password is required when setting up an iOS app in edna Pulse.',
  },
  dialogs: {
    changeFirebaseAPIKey: 'Change Firebase Server Key',
    newFirebaseAPIKey: 'New Firebase Server Key',
    changeIOSCertificateData: 'Change iOS certificate data',
    changeHuaweiAppSecret: 'Change Huawei App Secret',
    newHuaweiAppSecret: 'New Huawei App Secret',
    connectHuaweiApp: {
      title: 'Connect the Huawei push cloud',
      subTitle:
        'Configure sending push notifications via Huawei push cloud. Enter the Client Id and Client Secret parameters from the Huawei console, connect the plugin, and add the edna huawei library to the list of dependencies of your application according to the <0>instructions</0>',
    },
    hints: {
      changeFirebaseAPIKey: 'Change the Server Key to get access to Firebase',
      newFirebaseAPIKey:
        'Enter a new Server Key located on the Cloud Messaging tab of the project settings in Firebase Console',
      newHuaweiAppSecret: 'Enter a new App Secret from Huawei Console',
    },
  },
  listApps: {
    message: `Combine apps for the iOS and Android platforms into one push channel. This way you can send push notifications to the clients that use these apps via the same broadcast. Integrate each app of the channel with edna Pulse.`,
    th: {
      name: 'Name',
      status: 'Status',
      registrationCount: 'Total number of registered',
      perWeekCount: 'New (registered this week)',
    },
    actions: {
      move: 'Move to another channel',
      block: 'Block',
      unblock: 'Unblock',
      moveButton: 'Move',
      addApp: 'Add app',
      setupApp: 'Configure app',
      android: 'Android app',
      ios: 'iOS app',
    },
    empty: {
      [EPushAppType.ANDROID]: {
        title: 'To connect an Android app, you need',
        requirements: [
          {
            text: 'An Android app with minSdk no earlier than 21 (Android 5) and targetSdk no later than 34 (Android 14)',
          },
          {
            text: 'Get the Google service account JSON file. <0>How to get the file</0>',
          },
          {text: 'The google-services.json file from Firebase. <0>How to get the file</0>'},
          {
            text: 'A device with Android 5.0 or higher or an Android emulator with the Google Play services',
          },
          {text: 'A current version of Android Studio'},
        ],
      },
      [EPushAppType.IOS]: {
        title: 'To connect an iOS app, you need',
        requirements: [
          {text: 'An iOS app'},
          {
            text: 'An iOS device (iPhone, iPad, iPod Touch) for testing. The Xcode simulator does not support push notifications, so you need to use a physical device for testing.',
          },
          {text: 'A Mac workstation with the latest version of Xcode'},
          {text: 'iOS Production Push Certificate'},
          {text: 'CocoaPods or Swift Package Manager'},
        ],
      },
    },
    status: {
      [EChannelStatus.NO_CONNECTED_APPS]: {
        title: 'Not connected',
        details: 'No configured apps',
      },
      [EChannelStatus.ACTIVE]: {
        title: 'Active',
        details: 'Apps are configured correctly with no errors and warnings',
      },
      [EChannelStatus.NEED_ATTENTION]: {
        title: 'Attention required',
        details: 'One of the apps has an error or a warning',
      },
      [EChannelStatus.DISABLED]: {
        title: 'Disabled',
        details: 'Applications cannot receive push notification',
      },
    },
    moveModal: {
      title: 'Move to another channel',
      placeholder: 'Select channel',
      label: 'Select a channel for the move',
    },
    blockModal: {
      title: 'Block the app?',
      message: 'App users will not be able to receive push notifications.',
    },
    unblockModal: {
      title: 'Unblock the app?',
      message: 'App users will be able to receive push notifications again.',
    },
    notification: {
      successBlock: 'The app has been blocked!',
      successUnblock: 'The app has been unblocked!',
      successMove: 'The app has been moved!',
    },
  },
};
