/* eslint-disable import/no-extraneous-dependencies */
import {booleanTestId, prefixedTestId} from '@edna/tests';

import {unique} from 'src/utils';

const TEST_IDS = {
  // Components
  PHONE_PREVIEW: {CONTAINER: unique('PHONE_PREVIEW_CONTAINER')},
  EDITOR_WITH_SELECTOR: {CONTAINER: unique('EDITOR_WITH_SELECTOR_CONTAINER')},
  CHIP: {
    CONTAINER: unique('CHIP_CONTAINER'),
    REMOVE_BUTTON: unique('CHIP_REMOVE_BUTTON'),
  },
  STEPPER: {CONTAINER: unique('STEPPER_CONTAINER')},
  SWITCHER: {SELECTED: unique('SWITCHER_SELECTED')},
  MENU_BUTTON: {
    CONTAINER: unique('MENU_BUTTON_CONTAINER'),
    OPTION: prefixedTestId('MENU_BUTTON_OPTION'),
  },
  FORM: {
    HEADER: unique('FORM_HEADER'),
    FOOTER: unique('FORM_FOOTER'),
  },
  IMAGE_PICKER: {FILE_INPUT: unique('FILE_INPUT')},
  // Containers
  MESSAGE_MATCHERS: {
    VK_NOTIFY: {
      IMAGE: unique('VK_NOTIFY_MESSAGE_MATCHER_IMAGE'),
      BUTTON: unique('VK_NOTIFY_MESSAGE_MATCHER_BUTTON'),
    },
    SMS: {
      PREVIEW: unique('MESSAGE_MATCHERS_SMS_PREVIEW'),
    },
  },
  CASCADE: {
    FORM_ADD_BUTTON: unique('CASCADE_FORM_ADD_BUTTON'),
    STEP_ID: prefixedTestId('CASCADE_STEP_ID'),
    STEP_REMOVE_BUTTON: prefixedTestId('CASCADE_STEP_REMOVE_BUTTON'),
  },
  TRIGGERS: {
    STEP_CONTAINER: booleanTestId('TRIGGERS_STEP_CONTAINER', 'SELECTED'),
    STEP_TITLE: unique('TRIGGERS_STEP_TITLE'),
    EDGE: prefixedTestId('TRIGGERS_EDGE'),
    LIST_ITEM: unique('TRIGGERS_LIST_ITEM'),
    BUTTON_PRESSED_FORM: {
      BUTTON_NAME: prefixedTestId('TRIGGERS_BUTTON_PRESSED_FORM_BUTTON_NAME'),
      BUTTON_ID: prefixedTestId('TRIGGERS_BUTTON_PRESSED_FORM_BUTTON_ID'),
      ADD_BUTTON: unique('TRIGGERS_BUTTON_PRESSED_FORM_ADD_BUTTON'),
      REMOVE_BUTTON: prefixedTestId('TRIGGERS_BUTTON_PRESSED_FORM_REMOVE_BUTTON'),
    },
  },
  TAG_QUERIES: {
    GROUP_REMOVE_BUTTON: unique('TAG_QUERIES_GROUP_REMOVE_BUTTON'),
    GROUP_CONTAINER: prefixedTestId('TAG_QUERIES_GROUP_CONTAINER'),
    GROUP_EMPTY: unique('TAG_QUERIES_GROUP_EMPTY'),
    GROUPS_CONTAINER: prefixedTestId('TAG_QUERIES_GROUPS_CONTAINER'),
  },
  TAGS: {
    SELECTOR_CATEGORIES: unique('TAGS_SELECTOR_CATEGORIES'),
    ASSIGN_MODAL: unique('TAGS_ASSIGN_MODAL'),
    SELECTED_TAGS: unique('TAGS_SELECTED'),
    SEARCHED_TAGS: unique('TAGS_SEARCHED'),
  },
  AUTH: {
    CAPTCHA_UPDATE: unique('AUTH_CAPTCHA_UPDATE'),
    CAPTCHA_IMAGE: unique('AUTH_CAPTCHA_IMAGE'),
  },
  PARAMETERS: {ASSIGN_FORM: unique('PARAMETERS_ASSIGN_FORM')},
  BROADCAST: {FILTERS: unique('BROADCAST_FILTERS')},
};

export {TEST_IDS};
/* eslint-enable import/no-extraneous-dependencies */
