export default {
  migration: {
    title: 'Getting started in edna Pulse',
    hint: 'Guide',
    link: 'A manual for the clients migrating from the edna Enterprise platform',
  },
  assistant: {
    title: 'Your best friend at edna',
    hint: 'Account and billing questions',
  },
  tech: {
    title: 'Your tech guru',
    hint: 'Any technical questions',
  },
  library: {
    title: 'Your library',
    hint: 'Knowledge base',
    link: 'edna Pulse Online Help',
  },
};
