export default {
  title: 'The heart of digital communications',
  navigation: {
    home: 'Home',
    settings: 'Settings',
    triggers: 'Scripts',
    broadcasts: 'Broadcasts',
    audience: 'Audience',
    cascades: 'Cascades',
    channels: 'Channels',
    templates: 'Templates',
    analytics: 'Analytics',
    analyticsMessages: 'Messages',
    analyticsReport: 'Summary report',
    profile: 'Profile',
    myProfile: 'My profile',
    leadClients: 'Leads',
    supportClients: 'Company clients',
    userProfile: 'Company profile',
    help: 'Help',
    notifications: 'Notifications',
    signout: 'Sign out',
    contentManager: 'Content manager',
    integration: 'Integration',
    corrections: 'Corrections',
  },
};
