import keyBy from 'lodash/keyBy';

import {ESystemParameter, TItem, TState} from './definitions';

export const isSystemParameter = (code: TItem['code']): code is ESystemParameter =>
  Object.values(ESystemParameter).includes(code as ESystemParameter);

export const getParametersOptions = (
  parametersContent: TItem[],
  {withBraces}: {withBraces?: boolean} = {withBraces: true},
) =>
  parametersContent.map((item) => ({
    id: item.id,
    value: withBraces ? `{{${item.name}}}` : item.name,
    label: item.name,
  }));

export const getAssignInitialValues = (item: TItem | null) => {
  if (item === null) {
    return {};
  }

  return {
    id: item.id,
    name: item.name,
    valueType: item.valueType,
    value: undefined,
  };
};

export const transformContentToItemsMap = (content: TItem[]): TState['itemsMap'] =>
  keyBy(content, 'id');

export const getItemById = (itemsMap: TState['itemsMap'], id: TItem['id']) => itemsMap[id] || null;
