import PATHS from 'src/paths';
import {getTitleForPath} from 'src/utils';

export default {
  loading: 'Loading...',
  [PATHS.HOME]: 'Home',
  [PATHS.UNAVAILABLE]: 'Service temporarily unavailable',
  [PATHS.SIGN_IN]: 'Sign in to account',
  [PATHS.PASSWORD_SAVED]: 'Password saved!',
  [getTitleForPath(PATHS.PUBLIC_WHATSAPP_REGISTRATION)]: 'WhatsApp channel registration',
  [getTitleForPath(PATHS.SIGN_IN_BY_INVITE)]: 'Sign in',
  [PATHS.EMAIL_CONFIRM]: 'Email confirm',
  [PATHS.CHANNELS_LIST]: 'Channels',
  [PATHS.MESSAGE_MATCHERS_LIST]: 'Templates',
  [PATHS.CHANNELS_WHATSAPP_NEW]: 'Create WhatsApp channel',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_EDIT)]: 'Edit WhatsApp channel',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_VIEW)]: 'View WhatsApp channel',
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_NEW]: 'Create WhatsApp Embedded Signup channel',
  [getTitleForPath(PATHS.CHANNELS_EMBEDDED_WHATSAPP_EDIT)]: 'Edit WhatsApp Embedded Signup channel',
  [getTitleForPath(PATHS.CHANNELS_EMBEDDED_WHATSAPP_VIEW)]: 'View WhatsApp Embedded Signup channel',
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_NEW]: 'Create WhatsApp Embedded Signup channel',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_EDIT)]:
    'Edit WhatsApp Embedded Signup channel',
  [getTitleForPath(PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_VIEW)]:
    'View WhatsApp Embedded Signup channel',
  [PATHS.CHANNELS_VIBER_NEW]: 'Create Viber channel',
  [getTitleForPath(PATHS.CHANNELS_VIBER_EDIT)]: 'Edit Viber channel',
  [getTitleForPath(PATHS.CHANNELS_VIBER_VIEW)]: 'View Viber channel',
  [PATHS.CHANNELS_SMS_NEW]: 'Create SMS channel',
  [getTitleForPath(PATHS.CHANNELS_SMS_EDIT)]: 'Edit SMS channel',
  [getTitleForPath(PATHS.CHANNELS_SMS_VIEW)]: 'View SMS channel',
  [PATHS.CHANNELS_INSTAGRAM_NEW]: 'Create Instagram channel',
  [getTitleForPath(PATHS.CHANNELS_INSTAGRAM_VIEW)]: 'View Instagram channel',
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_NEW]: 'New template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_WHATSAPP_EDIT)]: 'Edit WhatsApp template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_WHATSAPP_VIEW)]: 'View WhatsApp template',
  [PATHS.MESSAGE_MATCHERS_VIBER_NEW]: 'New Viber template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VIBER_EDIT)]: 'Edit Viber template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VIBER_VIEW)]: 'View Viber template',
  [PATHS.MESSAGE_MATCHERS_SMS_NEW]: 'New SMS template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_SMS_EDIT)]: 'Edit SMS template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_SMS_VIEW)]: 'View SMS template',
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_NEW]: 'New Instagram template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_INSTAGRAM_EDIT)]: 'Edit Instagram template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_INSTAGRAM_VIEW)]: 'View Instagram template',
  [PATHS.MESSAGE_MATCHERS_PUSH_NEW]: 'New Push template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_PUSH_EDIT)]: 'Edit Push template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_PUSH_VIEW)]: 'View Push template',
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_NEW]: 'New OK Notify template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_OK_NOTIFY_EDIT)]: 'Edit OK Notify template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_OK_NOTIFY_VIEW)]: 'View OK Notify template',
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_NEW]: 'New VK Notify template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VK_NOTIFY_EDIT)]: 'Edit Vk Notify template',
  [getTitleForPath(PATHS.MESSAGE_MATCHERS_VK_NOTIFY_VIEW)]: 'View Vk Notify template',
  [PATHS.BROADCASTS_LIST]: 'Broadcasts',
  [getTitleForPath(PATHS.BROADCASTS_DETAILS)]: 'Broadcast details',
  [PATHS.BROADCASTS_NEW]: 'Create broadcast',
  [getTitleForPath(PATHS.BROADCASTS_EDIT)]: 'Edit broadcast',
  [PATHS.CASCADES_LIST]: 'Cascades',
  [PATHS.CASCADES_NEW]: 'Create cascade',
  [getTitleForPath(PATHS.CASCADES_VIEW)]: 'View cascade',
  [PATHS.SUBSCRIBERS_LIST]: 'Clients',
  [PATHS.SUBSCRIBERS_NEW]: 'Create client',
  [getTitleForPath(PATHS.SUBSCRIBERS_EDIT)]: 'Edit client',
  [PATHS.SUBSCRIBERS_GROUP_EDIT]: 'Add list of clients',
  [PATHS.PROFILE]: 'My profile',
  [PATHS.COMPANY_PROFILE]: 'Profile {{companyName}}',
  [PATHS.COMPANY_CLIENTS]: 'Leads',
  [getTitleForPath(PATHS.COMPANY_CLIENT_CARD)]: 'Company client card',
  [getTitleForPath(PATHS.COMPANY_CLIENT_NEW)]: 'New client',
  [PATHS.SUPPORT_CLIENTS]: 'Company clients',
  [getTitleForPath(PATHS.SUPPORT_CLIENT_CARD)]: 'Company client card',
  [PATHS.ANALYTICS_MESSAGES]: 'Message report',
  [PATHS.ANALYTICS_SUMMARY_REPORT]: 'Summary report',
  [getTitleForPath(PATHS.ANALYTICS_CHANNEL)]: 'Summary report',
  [PATHS.NOTIFICATIONS]: 'Notifications',
  [PATHS.HELP]: 'Help',
  [PATHS.TRIGGERS_NEW]: 'New script',
  [PATHS.TRIGGERS_LIST]: 'Scripts',
  [getTitleForPath(PATHS.TRIGGERS_EDIT)]: 'Edit trigger chain',
  [getTitleForPath(PATHS.TRIGGERS_VIEW)]: 'View trigger chain',
  [PATHS.CHANNELS_PUSH_NEW]: 'New push channel',
  [getTitleForPath(PATHS.CHANNELS_PUSH_APP_DATA)]: 'Application data',
  [getTitleForPath(PATHS.CHANNELS_PUSH_LIST)]: 'Push channel data',
  [getTitleForPath(PATHS.CHANNELS_PUSH_APP_NEW)]: 'Setting up {{appType}} application',
  [getTitleForPath(PATHS.CHANNELS_PUSH_IOS_NEW_STEP)]: 'Setting up iOS application',
  [getTitleForPath(PATHS.CHANNELS_PUSH_IOS_EDIT)]: 'Setting up iOS application',
  [getTitleForPath(PATHS.CHANNELS_PUSH_ANDROID_NEW_STEP)]: 'Setting up Android application',
  [getTitleForPath(PATHS.CHANNELS_PUSH_ANDROID_EDIT)]: 'Setting up Android application',
  [getTitleForPath(PATHS.INTEGRATION)]: 'Integration',
  [PATHS.FILES]: 'Downloading',
  [PATHS.CORRECTIONS]: 'Corrections',
  [PATHS.RENEWAL]: 'Renewal',
  [PATHS.CHANNELS_VK_NOTIFY_NEW]: 'Create VK Notify channel',
  [getTitleForPath(PATHS.CHANNELS_VK_NOTIFY_EDIT)]: 'Edit VK Notify channel',
  [getTitleForPath(PATHS.CHANNELS_VK_NOTIFY_VIEW)]: 'View VK Notify channel',
  [PATHS.CHANNELS_OK_NOTIFY_NEW]: 'Create OK Notify channel',
  [getTitleForPath(PATHS.CHANNELS_OK_NOTIFY_EDIT)]: 'Edit OK Notify channel',
  [getTitleForPath(PATHS.CHANNELS_OK_NOTIFY_VIEW)]: 'View OK Notify channel',
};
