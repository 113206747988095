import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';
import BaseMoreHorizIcon from '@edna/icons/moreHoriz.svg';
import {hexToRgba} from '@edna/utils/hexToRgba';

import {LogoImage} from 'src/images';

const FONT_SIZE = '12px';
const LINE_HEIGHT = 1.6;

export const truncate = (line: number, fontSize?: string) => css`
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-height: calc(${fontSize ?? FONT_SIZE} * ${LINE_HEIGHT} * ${line});
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
  overflow: hidden;
  text-overflow: ellipsis;
`;

type TDeviceProps = TTheme & {
  isIOS?: boolean;
};

type TMessageProps = TDeviceProps & {
  collapsed: boolean;
};

export const Message = styled.div<TMessageProps>`
  font-size: ${FONT_SIZE};
  line-height: ${LINE_HEIGHT};
  ${({isIOS, theme: {spacing, palette, branding}}: TMessageProps) => css`
    padding: ${spacing.s4} 0;
    background-color: ${branding.backgrounds.primary};
    border-radius: 14px;
    color: ${isIOS ? hexToRgba(palette.black, '60%') : 'currentColor'};
  `}
`;

export const Header = styled.div`
  ${mixins.flexAlign('space-between', 'center')}
  ${({theme: {spacing}}) => css`
    margin: 0 ${spacing.s4} ${spacing.s2} ${spacing.s4};
  `}
`;

export const AppLogo = styled(LogoImage)`
  ${mixins.wh('20px')}
  flex-shrink: 0;
  margin-right: 6px;
`;

export const AppTitle = styled.div`
  ${mixins.flexAlign('normal', 'center')}
`;

export const AppTitleText = styled.div`
  ${truncate(1)}
`;

export const BigTitle = styled.div<TDeviceProps>`
  ${({isIOS, theme: {palette}}: TDeviceProps) => css`
    color: ${palette.black};
    font-weight: ${isIOS ? 700 : 500};
    ${!isIOS && truncate(1)}
  `}
`;

export const Title = styled(BigTitle)`
  ${truncate(1)}
`;

type TBigBodyProps = TDeviceProps & {
  hasImage?: boolean;
};

export const BigBody = styled.div<TBigBodyProps>`
  ${({isIOS, hasImage, theme}: TBigBodyProps) => css`
    margin-top: ${hasImage ? theme.spacing.s2 : 0};
    margin-bottom: ${isIOS || !hasImage ? 0 : theme.spacing.s2};
    ${truncate(!isIOS && hasImage ? 1 : 6)}
    word-break: break-word;
    white-space: pre-wrap;
  `}
`;

type TBodyProps = TDeviceProps & {
  hasTitle?: boolean;
};

export const Body = styled.div<TBodyProps>`
  ${({isIOS, hasTitle, theme}: TBodyProps) => css`
    margin-top: ${theme.spacing.s2};
    word-break: break-word;
    white-space: pre-wrap;
    ${isIOS
      ? truncate(hasTitle ? 3 : 2)
      : css`
          ${truncate(1)}
          max-width: 100%;
        `}
  `}
`;

export const MoreHorizIcon = styled(BaseMoreHorizIcon)`
  color: #909090;
  height: 20px;
`;

export const Time = styled.div`
  color: #838383;
`;

export const Container = styled.div`
  display: flow-root;
  ${({theme: {spacing}}: TTheme) => css`
    margin: 0 ${spacing.s4};
  `}
`;

export const BigLogoWrapper = styled.div<TDeviceProps>`
  ${({isIOS, theme: {spacing}}: TDeviceProps) =>
    !isIOS &&
    css`
      overflow: hidden;
      margin: ${spacing.s1} ${spacing.s4} 0 ${spacing.s4};
    `}
`;

// MFMSCOM-3920
// для Android обрезаем картинку. По горизонтали оставляем 90%, по вертикали 80%
export const BigLogo = styled.img<TDeviceProps>`
  position: relative;
  width: 100%;
  max-height: 500px;
  ${({isIOS}: TDeviceProps) =>
    !isIOS &&
    css`
      width: 110%;
      margin: -5%;
    `}
`;

export const Logo = styled.img<TDeviceProps>`
  ${mixins.wh('36px')}
  flex-shrink: 0;
  float: right;
  ${({isIOS}: TDeviceProps) =>
    isIOS &&
    css`
      border-radius: 4px;
    `}
`;

export const Action = styled.div<TDeviceProps>`
  ${mixins.truncate}
  ${({isIOS, theme: {spacing}}: TDeviceProps) =>
    isIOS
      ? css`
          min-height: 40px;
          ${mixins.flexAlign('center')}
          &:not(:last-child) {
            border-bottom: 1px solid ${hexToRgba('#999999', '50%')};
          }
        `
      : css`
          color: #757575;
          &:not(:last-child) {
            margin-right: ${spacing.s5};
          }
        `}
`;

export const Actions = styled.div<TDeviceProps>`
  ${({isIOS, theme: {spacing, branding}}: TDeviceProps) => css`
    margin-top: ${spacing.s2};
    ${isIOS
      ? css`
          width: 100%;
          background-color: ${branding.backgrounds.primary};
          border-radius: 13px;
        `
      : css`
          display: flex;
          color: #757575;
          margin-left: ${spacing.s4};
        `}
  `}
`;
