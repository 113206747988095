import {EContentType, EOperatorName, TChannelType} from 'src/constants';
import {TPushChannel} from 'src/containers/PushChannel/definitions';
import {EUrgencyType, TPushStyle} from 'src/containers/PushStyle';
import {TListItem, TListItemId} from 'src/models/listApiModel';

import {ESmsCategory} from './SMSForm/definitions';
import {EOldWhatsAppCategory, EWhatsAppCategory} from './WhatsAppForm/constants';
import {EApproveStatus, EMessageMatcherCategory, EType} from './constants';
import {TResultUpload} from './model';

export enum EMultiApproveStatus {
  FULLY_APPROVED = 'FULLY_APPROVED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
}

export type TType = keyof typeof EType;

export type TTHeaderType =
  | EContentType.TEXT
  | EContentType.IMAGE
  | EContentType.VIDEO
  | EContentType.DOCUMENT;

type THeader = {
  text?: string;
  headerType?: TTHeaderType;
  attachment?: TFile | null;
  headerExampleMediaUrl?: string;
};

type TPushBigContent = {
  text?: string;
  header?: THeader;
};

export type TAdditionalParameter = Record<string, string>;

export type TPushItemContent = TCommonItemContent & {
  attachment?: TFile | null;
  header?: THeader;
  bigContent?: TPushBigContent;
  style?: TPushStyle;
  deviceSettings?: {
    urgency?: EUrgencyType;
  };
  buttons?: TItemButton[];
  action?: string;
  serviceAttrs?: TAdditionalParameter;
};

type TCommonItemContent = {text?: string};

export type TVkNotifyItemContent = TCommonItemContent & {
  vkAttachments?: TFile[];
  vkTwoWayEnabled?: boolean;
  buttons?: TItemButton[];
};

export type TViberItemContent = TCommonItemContent & {
  attachment?: TFile | null;
  caption?: string;
  action?: string;
  buttons?: TItemButton[];
};

export type TWhatsAppItemContent = TCommonItemContent & {
  header?: THeader;
  footer?: {
    text?: string;
  };
  attachment?: TFile | null;
  buttons?: TItemButton[];
  textExampleParams?: string[];
  codeExpirationMinutes?: number;
  securityRecommendation?: boolean;
};

export type TInstagramItemContent = TCommonItemContent & {
  attachment?: TFile | null;
};

export type TItemContent = TCommonItemContent &
  TPushItemContent &
  TInstagramItemContent &
  TWhatsAppItemContent &
  TVkNotifyItemContent &
  TViberItemContent;

export type TSmsProvider = {
  code: EOperatorName;
  approveStatus?: EApproveStatus;
  approveStatusComment?: string | null;
  approveStatusAt?: string;
};
export type TItem<T = TItemContent> = TListItem & {
  parentId: TListItemId;
  name: string;
  type: TType;
  channelType: TChannelType;
  adminComment: string;
  description: string;
  createdAt: string;
  createdBy: string;
  approveStatus: EApproveStatus;
  statusAt: string;
  statusComment: string;
  subjectIds: number[];
  template: string;
  exampleText: string;
  updatedAt: string;
  updatedBy: string;
  language: string;
  category: EMessageMatcherCategory | EWhatsAppCategory | EOldWhatsAppCategory | ESmsCategory | '';
  hasChildren: boolean;
  children?: TItem[];
  lastUsedAt: string;
  smsProviders?: TSmsProvider[];
  contentType?: EContentType;
  multiApproveStatus?: EMultiApproveStatus;
  content: T;
};

export type TInitialItem<T = TItemContent> = Partial<TItem<T>>;

export enum ETemplateButtonTypes {
  PHONE = 'PHONE',
  OTP = 'OTP',
  URL = 'URL',
  CHAT = 'QUICK_REPLY',
  LOCATION = 'LOCATION',
  VK_MINI_APPS = 'VK_MINI_APPS',
}

export enum ETemplateButtonOtpType {
  AUTO = 'ONE_TAP',
  COPY = 'COPY_CODE',
}

export enum ETemplateButtonColor {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export type TItemButton = {
  buttonType?: ETemplateButtonTypes;
  required?: boolean;
  text?: string;
  payload?: string;
  phone?: string;
  url?: string;
  urlPostfix?: string;
  hasUrlPostfix?: boolean;
  color?: ETemplateButtonColor;
  hash?: string;
  appId?: number;
  ownerId?: number;
  packageName?: string;
  autofillText?: string;
  otpType?: ETemplateButtonOtpType | '';
};

export type TFormDataButtons = Partial<
  Record<ETemplateButtonTypes, Omit<TItemButton, 'buttonType'>[]>
> & {
  isChatType?: boolean;
};

export type TWhatsAppMessageMatchersContent = {
  text: TWhatsAppItemContent['text'];
  hasHeader?: boolean;
  header?: TWhatsAppItemContent['header'];
  addFooter?: boolean;
  footer?: TWhatsAppItemContent['footer'];
  addButtons?: boolean;
  buttons?: TItemButton[];
  documentUrl?: string;
  imageUrl?: string;
  isUploadFile?: boolean;
  textExampleParams?: string[];
  addPasswordExpiration?: boolean;
  codeExpirationMinutes?: number;
  securityRecommendation?: boolean;
};

export type TWhatsAppFormData = TFormData<TWhatsAppMessageMatchersContent>;

export type TViberMessageMatchersContent = {
  text?: TViberItemContent['text'];
  attachment?: TViberItemContent['attachment'];
  caption?: TViberItemContent['caption'];
  action?: TViberItemContent['action'];
  documentUrl?: string;
  imageUrl?: string;
  isUploadImage?: boolean;
  isUploadDocument?: boolean;
};

export type TInstagramMessageMatchersContent = {
  text: TInstagramItemContent['text'];
  isUploadImage?: boolean;
  attachment?: TInstagramItemContent['attachment'];
  imageUrl?: string;
};

type TAttachment = {
  attachment?: TFile | null;
  imageUrl?: string;
  isUploadImage: boolean;
  resultUploadByUrl?: TResultUpload | null;
};

export type TPushMessageMatchersContent = {
  text: TPushItemContent['text'];
  action?: TPushItemContent['action'];
  style?: TPushItemContent['style'];
  deviceSettings?: TPushItemContent['deviceSettings'];
  headerText?: THeader['text'];
  headerAttachment?: TAttachment;
  bigContentText?: TPushBigContent['text'];
  bigContentAttachment?: TAttachment;
  attachment?: TPushItemContent['attachment'];
  buttons?: TFormDataButtons;
  imageUrl?: string;
  logoUrl?: string;
  addButtons?: boolean;
  addLinks?: boolean;
  parameters?: TAdditionalParameter[];
};

export type TVkNotifyFormData = TFormData<TVkNotifyMessageMatchersContent>;

export type TVkNotifyMessageMatchersContent = {
  text?: TVkNotifyItemContent['text'];
  vkTwoWayEnabled: boolean;
  addAttachments?: boolean;
  vkAttachments?: Partial<TFile>[];
  addButtons?: boolean;
  buttons?: TItemButton[];
};

export type TOkNotifyMessageMatchersContent = {
  text?: TCommonItemContent['text'];
};

export type TSmsMessageMatchersContent = {
  text?: TCommonItemContent['text'];
};

export enum EVariableType {
  TEXT = 'TEXT',
  PARAMS = 'PARAMS',
}

export type TVariables = {
  type: EVariableType;
  value: string;
};

export type TMessageMatchersContent =
  | TWhatsAppMessageMatchersContent
  | TViberMessageMatchersContent
  | TInstagramMessageMatchersContent
  | TPushMessageMatchersContent
  | TVkNotifyMessageMatchersContent
  | TOkNotifyMessageMatchersContent
  | TSmsMessageMatchersContent;

export type TFormData<T = TMessageMatchersContent> = {
  id?: TItem['id'];
  name?: TItem['name'];
  type?: TItem['type'];
  approveStatus?: TItem['approveStatus'];
  multiApproveStatus?: TItem['multiApproveStatus'];
  description?: TItem['description'];
  exampleText?: TItem['exampleText'];
  category?: TItem['category'];
  language?: TItem['language'];
  createdBy?: TItem['createdBy'];
  channelType: TChannelType;
  subjectIds: TItem['subjectIds'];
  smsProviders?: Partial<Record<EOperatorName, boolean>>;
  parentMessageMatcherText?: TCommonItemContent['text'];
  content: T;
  variables?: TVariables[];
  parentId?: TItem['parentId'];
  // viber custom content
  contentType?: TItem['contentType'];
  // push
  pushChannelId?: TPushChannel['id'];
  isUploadExampleFile?: boolean;
  subjectId?: number;
};

export type TExamplesFormData = {
  id?: TItem['id'];
  textExampleParams?: string[];
  header?: {
    headerExampleMediaUrl?: string;
  };
  mediaUrl?: string;
  mediaFile?: TFile | null;
  isUploadExampleFile?: boolean;
  linkButtonExampleUrl?: string;
  buttons?: {
    urlTextExample?: string;
    empty?: boolean;
  }[];
};

export type TValidationResult = {
  success: boolean;
  textExampleParams: [boolean];
  header: {
    headerExampleTextParam: boolean;
    headerExampleMediaUrl: boolean;
  };
  buttons: [
    {
      urlTextExample: boolean;
    },
  ];
};

export type TLanguage = {
  code: string;
  ru: string;
  en: string;
};

export type TSupportedLanguages = {
  whatsapp?: TLanguage[];
};

export enum EOperatorFormStep {
  DETAILS = 'DETAILS',
  TEXT_CONTENT = 'TEXT_CONTENT',
  HEADER_AND_SIGNATURE = 'HEADER_AND_SIGNATURE',
  BUTTONS = 'BUTTONS',
}

export enum EAuthFormStep {
  DETAILS = 'DETAILS',
  AUTH_CONTENT = 'AUTH_CONTENT',
}

export enum EUserFormStep {
  DETAILS = 'DETAILS',
  USER_CONTENT = 'USER_CONTENT',
}

export type TFormStep = EOperatorFormStep | EUserFormStep | EAuthFormStep;

export type TSteps = Record<string, {disabled: boolean; completed: boolean}>;
