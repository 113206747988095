import {EIntegrationTabs} from './index';

export default {
  tabs: {
    [EIntegrationTabs.DOCUMENTATION]: 'Documentation',
    [EIntegrationTabs.TESTING]: 'Testing',
    [EIntegrationTabs.SETTINGS]: 'Settings',
    [EIntegrationTabs.MODULES]: 'Available modules',
  },
  documentation: {
    whatsapp: {
      title: 'WhatsApp',
      description: 'WhatsApp API description',
    },
    push: {
      title: 'Push',
      description: 'Configuring push platform apps',
    },
    go: 'Open',
  },
  testing: {
    row1: {
      apiKey: 'Your API key',
      noApiKey: 'Generate a new API key',
    },
    row2: {
      inbox: 'Incoming messages are sent to',
      noInbox: `Specify the link to your app's service where we will send incoming messages (optional)`,
    },
    row3: {
      statuses: 'Message statuses are sent to',
      noStatuses: `Specify the link to your app's service where we will send information about changing message statuses (optional)`,
    },
    row4: {
      text: 'Enter test phone numbers',
      hint: 'Enter a phone number for testing',
    },
    row5: {
      text: 'Follow the link ',
      subtitle: 'or scan the QR code',
      hint: `Send any message from a test number to the chat. This initiates messaging in the chat. Review your message in the <0>message report</0> and check whether you have received it in your app.`,
    },
    row6: {
      text: 'Send a response message to the phone number from which you sent an incoming message.',
      hint: 'Review the message status in the message report and check whether you have received it in your app.',
      resetValues: 'Reset Values',
      callResource: 'Call Resource',
      response: 'Response',
    },
    generate: 'Generate a new API key',
    addPhone: 'Add phone',
    addLink: 'Add link',
  },
  modules: {
    jivo: {
      description: 'Connect a WhatsApp channel to the Jivo chat platform',
      modal: {
        title: 'Enable Jivo?',
        subtitle: 'All your channels on edna Pulse will be connected to Jivo. Are you sure?',
        no: 'Oh, no',
      },
      noSubjects: 'To enable the module, you need to have an active WhatsApp signature',
    },
    connect: 'Enable',
    disconnect: 'Disable',
    connected: 'Connected',
  },
};
