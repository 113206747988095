import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

type TMessageProps = {
  noBackground?: boolean;
};

export const Message = styled.div<TMessageProps>`
  position: relative;
  width: 100%;
  ${({noBackground}: TMessageProps) => css`
    background-color: ${noBackground ? 'transparent' : '#efefef'};
    border-radius: 0 15px 15px;
  `}
`;

export const EmptyImage = styled.div`
  background-color: #e2e2e2;
  min-height: 200px;
  ${mixins.flexAlign('center')}
  border-radius: 15px;
`;

export const Button = styled.div`
  text-align: center;
  word-break: break-all;
  ${({theme: {palette, spacing, borderRadius}}) => css`
    margin: ${spacing.s3} 0 ${spacing.s5} 0;
    padding: ${spacing.s2};
    color: ${palette.white};
    background-color: #7c6bde;
    border-radius: ${borderRadius.br12};
  `}
`;

export const Document = styled.img`
  background-color: #e2e2e2;
  ${({theme: {spacing}}) => css`
    max-width: calc(100% + 2 * ${spacing.s2});
    margin: -${spacing.s2} 0 0 -${spacing.s2};
    border-top-right-radius: 15px;
  `}
`;

export const Avatar = styled.div`
  ${mixins.wh('30px')}
  ${mixins.flexAlign('center')}
  flex-shrink: 0;
  font-weight: 500;
  border-radius: 10px;
  ${({theme: {palette, spacing}}) => css`
    color: ${palette.white};
    background-color: ${palette.black};
    margin-right: ${spacing.s1};
  `}
`;
