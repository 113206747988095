import React from 'react';

import {TItem as TMessageMatchersItem} from 'src/containers/MessageMatchers/types';
import {useGetAllParametersQuery} from 'src/containers/Parameters/api';

import * as S from './style';
import {prepareText} from './utils';

type TTextProps = {
  type?: TMessageMatchersItem['type'] | null;
  channelType: TMessageMatchersItem['channelType'];
  text: TMessageMatchersItem['content']['text'];
};

const Text = React.memo<TTextProps>(({type, channelType, text}: TTextProps) => {
  const {data: parameters = []} = useGetAllParametersQuery();

  if (text) {
    return (
      <S.TemplateText
        dangerouslySetInnerHTML={{
          __html: prepareText({type, channelType, parameters, text}),
        }}
      />
    );
  }

  return null;
});

Text.displayName = 'Text';

export default Text;
