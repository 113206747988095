import React from 'react';

import styled, {css} from 'styled-components';

import {
  LoadingButton as BaseLoadingButton,
  TLoadingButtonProps as TBaseLoadingButtonProps,
} from '@edna/components/Button';
import {mixins} from '@edna/components/styles';

export const Label = styled.div`
  ${mixins.stack({size: '2', align: 'center', direction: 'ROW'})}
  ${mixins.wh('100%')}
  padding: ${({theme: {spacing}}) => `${spacing.s3} ${spacing.s2} ${spacing.s3} 0`};
  border-radius: 2px;
`;

export const Text = styled.span`
  ${mixins.f3}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type TLoadingButtonProps = TTheme &
  React.LabelHTMLAttributes<HTMLLabelElement> &
  TBaseLoadingButtonProps & {
    isInvalid?: boolean;
  };

export const LoadingButton = styled(BaseLoadingButton)<TLoadingButtonProps>`
  width: max-content;
  max-width: 100%;
  appearance: none;
  ${({isInvalid, theme}: TLoadingButtonProps) =>
    isInvalid &&
    css`
      border-color: ${theme.palette.red400};
    `}
`;

export const SuccessUpload = styled.div`
  ${mixins.stack({size: '2', direction: 'ROW', align: 'center'})};
  margin-bottom: ${({theme}) => theme.spacing.s2};
`;
