import {EAutopaymentError} from './definitions';

export default {
  settingsModal: {
    titleConnect: 'Activate auto payment',
    titleEdit: 'Configure auto payment',
    threshold: 'When balance is below',
    amount: 'Replenish balance with',
    minThreshold: 'The minimum balance is {{threshold}} {{currency}}',
    minAmount: 'The minimum deposit is {{amount}} {{currency}}',
    acceptanceInfo: 'Once applied, you will be redirected to the Stripe payment system website',
    licensesInfo:
      'We recommend that you set a minimum balance of <0>{{threshold}} {{currency}}</0>. This is the cost of all your licenses and the minimum payment for Viber, if any.',
    incorrectSettingsWarning:
      'The amount you set is not enough to renew the licenses on time. There may be interruptions in the operation of the service if the bank debits funds multiple times',
  },
  offModal: {
    title: 'Deactivate auto payment?',
    subtitle:
      'If you deactivate the auto payment, your licenses will no longer be automatically renewed. This means that you will not be able to use our services and send messages to your customers.',
    submitButton: 'Request deactivation',
  },
  offSupportModal: {
    title: 'Deactivate auto payment?',
    subtitle:
      'Auto payment allows you to automatically add a specified amount of money to your balance using a bank card. Are you sure?',
    submitButton: 'Deactivate',
  },
  changeCardCreditsModal: {
    title: 'Edit card details for auto payment',
    subtitle:
      'To edit the linked card details, you will be redirected to the Stripe payment system website',
  },
  actions: {
    connect: 'Activate auto payment',
    connectHint:
      'Auto payment allows you to automatically add a specified amount of money to your balance using a bank card',
    changeSettings: 'Edit auto payment',
    changeCardCredits: 'Edit card details',
    off: 'Deactivate auto payment',
    restore: 'Resume auto payment',
  },
  info: 'When the balance is <1>{{threshold}}</1> <0>{{currency}}</0> it will be automatically replenished with <2>{{amount}}</2> <0>{{currency}}</0>',
  status: {
    connected: 'Auto payment activated',
    paused: 'Auto payment suspended',
  },
  thresholdWarning:
    'The set minimum balance may not be enough to cover your regular payments. We recommend that you update your auto payment to ensure that your licenses are renewed on time. <0>Update the auto payment</0>',
  errors: {
    [EAutopaymentError.INSUFFICIENT_FUNDS]:
      'The charge failed on {{date}}. Insufficient funds. Recharge your card within {{leftDays}} {{leftDays1}} to resume the auto payment. If the balance is insufficient after {{leftDays}} {{leftDays2}}, the auto payment will be disabled and the services will be suspended. The next charge will be on {{nextDate}}',
    [EAutopaymentError.CARD_DECLINE]:
      'Failed to make an auto payment with the linked card. Check the card details',
    [EAutopaymentError.AUTHENTICATION_REQUIRED]:
      'The auto payment failed on {{date}}. Your bank has requested payment confirmation. To confirm the payment, go to the <0>Stripe payment system website</0>.',
  },
};
