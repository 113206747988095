import getBrowserLang from '@edna/utils/getBrowserLang';
import storage from '@edna/utils/storage';

import {EStorageKeys} from '../constants';

const getCurrentLang = () => {
  const storageLang = storage.get<string>(EStorageKeys.LANG);

  return storageLang ?? getBrowserLang();
};

export {getCurrentLang};
