type TRule = {
  id: 'uppercase' | 'lowercase' | 'latin' | 'length' | 'number' | 'specialCharacter';
  loc: string;
  regExp: RegExp;
  include: boolean;
};

const getPasswordCheckResult = (value: string, rules: TRule[]): Record<TRule['id'], boolean> =>
  rules.reduce(
    (result, {id, include, regExp}) => {
      // eslint-disable-next-line no-bitwise
      const isValid = !!~value.search(regExp) === include;

      if (id === 'latin' && isValid && value.length === 0) {
        return {
          ...result,
          [id]: false,
        };
      }

      return {
        ...result,
        [id]: isValid,
      };
    },
    {} as Record<TRule['id'], boolean>,
  );

const isValidPassword = (value: string, rules: TRule[]) =>
  !Object.values(getPasswordCheckResult(value, rules)).includes(false);

export {isValidPassword, TRule, getPasswordCheckResult};
