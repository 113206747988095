import {HexColorPicker as BaseHexColorPicker} from 'react-colorful';

import styled, {css} from 'styled-components';

import {TextInput as BaseTextInput} from '@edna/components';
import {mixins} from '@edna/components/styles';

export const ColorPicker = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
`;

type TOverlayProps = {
  disabled: boolean;
};

export const Overlay = styled.div<TOverlayProps>`
  ${({disabled, theme}) => css`
    ${disabled && mixins.disabled}
    padding: ${theme.spacing.s3};
  `}
`;

type THexColorPickerProps = {
  disabled: boolean;
};

export const HexColorPicker = styled(BaseHexColorPicker)<THexColorPickerProps>`
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  /* stylelint-disable selector-class-pattern */
  .react-colorful__saturation,
  .react-colorful__hue {
    border-radius: 0;
  }
  .react-colorful__saturation-pointer,
  .react-colorful__pointer {
    ${mixins.wh('8px')}
    border-width: 1px;
  }
  /* stylelint-enable selector-class-pattern */
`;

type THeaderProps = {
  disabled: boolean;
  readOnly: boolean;
};

export const Header = styled.div<THeaderProps>`
  ${({disabled, readOnly, theme: {dimension, boxShadow, borderRadius, spacing, branding}}) => css`
    ${mixins.wh('20px')}
    padding: ${spacing.s05};
    border: ${dimension.borderWidth}px solid
      ${disabled || readOnly ? branding.colors.disabled : branding.content.primary};
    border-radius: ${borderRadius.br1};
    ${!disabled &&
    css`
      &:hover {
        border-color: ${branding.content.tertiary};
        box-shadow: ${boxShadow.default};
      }
    `}
  `}
`;

type THeaderInnerProps = {
  color?: TThemeColor;
};

export const HeaderInner = styled.div<THeaderInnerProps>`
  ${mixins.wh('12px')}
  background-color: ${({color}) => color};
`;

type TPrefixProps = {
  disabled: boolean;
};

export const Prefix = styled.div<TPrefixProps>`
  color: ${({disabled, theme}) => (disabled ? theme.branding.colors.disabled : 'currentColor')};
`;

export const TextInput = styled(BaseTextInput)`
  flex-shrink: 0;
  width: 100px;
  margin: 0 ${({theme}) => theme.spacing.s2};
`;

export const Label = styled.label`
  ${mixins.f3}
`;
