export default {
  availableTariffs: 'Доступные тарифы',
  availableTariffsHint:
    'Доступные тарифы - это тарифы, которые вы предварительно согласовали с менеджером и должны принять до даты активации тарифов. После этого вы можете подключить эти тарифы для ваших каналов',
  activeTariffs: 'Активные тарифы',
  activeTariffsHint:
    'Активные тарифы - это тарифы, подключенные к вашим учетным записям в каналах.',
  acceptAllTariffs:
    'Ознакомьтесь с тарифами и нажмите кнопку "{{buttonName}}". После принятия тарифы активируются',
  button: {
    acceptTariff: 'Принять тариф',
  },
};
