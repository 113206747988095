import {TCurrency} from '@edna/utils/formatMoney';

import {EClientStatus} from 'src/constants';
import {TPaymentType} from 'src/containers/Licenses/PaymentTab/definitions';
import {TTariffChannelType, TTariffsTemplatesIds} from 'src/containers/Tariffs/definitions';

export enum ELeadClientCreatingError {
  TAX_NUMBER_ALREADY_IN_USE = 'TAX_NUMBER_ALREADY_IN_USE',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
}

export enum EDocumentType {
  CONTRACT = 'CONTRACT', // Бумажный договор
  AGREEMENT = 'AGREEMENT', // Оферта
  TECH_PARTNER = 'TECH_PARTNER', // Технический партнер
  PILOT = 'PILOT', // Пилот
}

export type TBankDetails = {
  id: number;
  resellerId: number;
  beneficiaryBank: string;
  ibanIdr: string;
  ibanUsd: string;
  ibanEur: string;
  bankCode: string;
  branchName: string;
  swift: string;
  iban: string;
  accountNumber: string;
  currency: TCurrency;
  paymentType: TPaymentType;
  resellerName: string;
  resellerAddress: string;
  resellerRegNum: string;
  resellerVat: string;
  comment: string;
};

export type TTariffItem = {
  id?: number;
  channelType?: TTariffChannelType;
};

export type TLeadClient = {
  id: number;
  companyName: string;
  taxNumber: string;
  ogrn?: string;
  countryCode: string;
  currencyCode?: TCurrency;
  resellerId: number;
  contactName: string;
  phone: string;
  email: string;
  responsibleManagerId: number;
  partnerId?: number | null;
  status: EClientStatus;
  inviteUrl: string;
  inviteUrlCreatedAt: string;
  createdTenantId: number;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  tariffTemplatesIds: TTariffsTemplatesIds;
  documentType: EDocumentType | null;
  paymentType: TPaymentType;
  managerResponsibleForCheckingId?: number | null;
  maxOverdraft: number;
  securityDeposit: number;
  licensePaymentTerm: number;
  termBeforeTerminationLicense: number;
  locale: string;
  newLinkRequested: true;
  activateLicenseDebt: boolean;
  documentNumber: string;
  documentDate: string;
  paymentBankId?: number | null;
  billingDisabled?: boolean;
};

export type TFormData = Partial<TLeadClient> & {
  hasPartner?: boolean;
  isChecked?: boolean;
  isAgreedOverdraft?: boolean;
  agreedOverdraft?: number;
  isDisableBillingAgreed?: boolean;
  isDocumentTypeApproved?: boolean;
  tariffs: TTariffItem[];
};

export const DEFAULT_LEAD_CLIENT = {
  companyName: '',
  contactName: '',
  countryCode: '',
  email: '',
  taxNumber: '',
  phone: '',
  isDocumentTypeApproved: false,
  documentNumber: '',
  documentDate: '',
  tariffs: [{}],
};

export enum EFormStep {
  DETAILS = 'DETAILS',
  CALCULATION_AND_LICENSES = 'CALCULATION_AND_LICENSES',
  TARIFFS = 'TARIFFS',
}

export type TFormStep = keyof typeof EFormStep;
