import React from 'react';

import {H1} from '@edna/components/primitives';

import {NoShrinkImage, SecondaryText} from 'src/components/primitives';

import * as S from './style';

type TCardProps = {
  logo: TSvgComponentType;
  title: string;
  description: string;
  children: React.ReactNode;
};

const Card = React.memo<TCardProps>(({logo, title, description, children}) => {
  return (
    <S.Card>
      <S.CardImage>
        <NoShrinkImage as={logo} />
      </S.CardImage>
      <S.CardInfo>
        <H1>{title}</H1>
        <SecondaryText bottomIndent="5" topIndent="2">
          {description}
        </SecondaryText>
        <S.Content>{children}</S.Content>
      </S.CardInfo>
    </S.Card>
  );
});

Card.displayName = 'Card';

export default Card;
