import {generatePath, useNavigate} from 'react-router-dom';

import {TListItemId} from 'src/models';

const useRedirectToItem = () => {
  const navigate = useNavigate();

  return (path: string, result?: TAnyObject | TListItemId | null, replace = true, params = {}) => {
    const id = typeof result === 'object' ? result?.id : result;

    return navigate(generatePath(path, {id: String(id), ...params}), {
      replace,
    });
  };
};

export default useRedirectToItem;
