import styled from 'styled-components';

import {mixins} from '@edna/components/styles';

import BaseTabs, {Tab as BaseTab} from '../Tabs';
import {RouterLink as BaseRouterLink} from '../primitives';

export const Tabs = styled(BaseTabs)`
  margin-bottom: ${({theme}) => theme.spacing.s6};
`;
export const Tab = styled(BaseTab)`
  ${mixins.f4}
  font-weight: 500;
`;

export const TabsIcon = styled.svg`
  margin-right: ${({theme}) => theme.spacing.s2};
`;

export const RouterLink = styled(BaseRouterLink)`
  &:hover {
    text-decoration: none;
  }
`;
