import React from 'react';

import {showModal} from '@edna/components';

import {FailDialog} from '../FailDialog';

const hasFacebookPermissions = (neededPermissions: string[], response?: facebook.AuthResponse) => {
  const permissions = response?.grantedScopes?.split(',') ?? [];

  const permissionsSet = new Set(permissions);

  return neededPermissions.every((permission) => permissionsSet.has(permission));
};

const showFacebookPermissionsError = (channel: string) =>
  showModal((closeModal) => (
    <FailDialog
      closeModal={closeModal}
      title="Facebook:permissionsError.title"
      message="Facebook:permissionsError.message"
      messageOptions={{channel}}
      buttonText="Facebook:permissionsError.button"
    />
  ));

const showFacebookConnectionError = () =>
  showModal((closeModal) => (
    <FailDialog
      closeModal={closeModal}
      title="Facebook:connectionError.title"
      buttonText="Facebook:connectionError.button"
    />
  ));

export {showFacebookPermissionsError, showFacebookConnectionError, hasFacebookPermissions};
