import {EPermission, ERoles} from 'src/containers/Auth/definitions';
import PATHS from 'src/paths';
import {includesArray} from 'src/utils';

import {bootstrapComponents} from './utils';

/* prettier-ignore */
const componentImports = {
  Home: () => import(/* webpackChunkName: "channels" */ '../Home'),
  NotFound: () => import(/* webpackChunkName: "not-found" */ '../NotFound'),
  ServiceUnavailable: () => import(/* webpackChunkName: "service-unavailable" */ '../ServiceUnavailable'),
  // Auth
  SignIn: () => import(/* webpackChunkName: "sign-in" */ '../Auth/SignIn'),
  PasswordSaved: () => import(/* webpackChunkName: "password-saved" */ '../Auth/PasswordSaved'),
  EmailConfirm: () => import(/* webpackChunkName: "email-confirm" */ '../Auth/EmailConfirm'),
  SignInByInviteLink: () => import(/* webpackChunkName: "sign-up-by-invite-link" */ '../SignInByInviteLink'),
  Corrections: () => import(/* webpackChunkName: "corrections" */ '../Corrections'),

  // Channels
  Channels: () => import(/* webpackChunkName: "channels" */ '../Channels'),
  WhatsAppChannelEdit: () => import(/* webpackChunkName: "whats-app-channel-edit" */ '../WhatsAppChannel/EditForm'),
  EmbeddedWhatsAppChannel: () => import(/* webpackChunkName: "embedded-whats-app-channel-form" */ '../EmbeddedWhatsAppChannel/ViewForm'),
  EmbeddedWhatsAppChannelForm: () => import(/* webpackChunkName: "embedded-whats-app-channel-form" */ '../EmbeddedWhatsAppChannel/EditForm'),
  WhatsAppTechPartnerChannelForm: () => import(/* webpackChunkName: "whats-app-tech-partner-channel-form" */ '../EmbeddedWhatsAppChannel/TechPartnerForm'),
  WhatsAppPublicChannelForm: () => import(/* webpackChunkName: "embedded-whats-app-public-channel-form" */ '../EmbeddedWhatsAppChannel/PublicForm'),
  ViberChannelEditForm: () => import(/* webpackChunkName: "viber-channel-edit-form" */ '../ViberChannel/EditForm'),
  ViberChannelViewForm: () => import(/* webpackChunkName: "viber-channel-view-form" */ '../ViberChannel/ViewForm'),
  SmsChannelEdit: () => import(/* webpackChunkName: "sms-channel-edit" */ '../SmsChannel/EditForm'),
  InstagramChannelEdit: () => import(/* webpackChunkName: "instagram-channel-edit" */ '../InstagramChannel/EditForm'),
  PushAppData: () => import(/* webpackChunkName: "push-app-data" */ '../PushApps/AppData'),
  PushChannelApps: () => import(/* webpackChunkName: "push-channel-apps" */ '../PushApps'),
  PushChannelEdit: () => import(/* webpackChunkName: "push-channel-edit" */ '../PushChannel/EditForm'),
  StartSetupApp: () => import(/* webpackChunkName: "start-setup-app" */ '../PushApps/EditForm/StartSetupApp'),
  PushAppsIOSEdit: () => import(/* webpackChunkName: "push-apps-ios-edit" */ '../PushApps/EditForm/IOS'),
  PushAppsAndroidEdit: () => import(/* webpackChunkName: "push-apps-android-edit" */ '../PushApps/EditForm/Android'),
  VkNotifyEdit: () => import(/* webpackChunkName: "vk-notify-channel-edit" */ '../VkNotifyChannel/EditForm'),
  OkNotifyEdit: () => import(/* webpackChunkName: "ok-notify-channel-edit" */ '../OkNotifyChannel/EditForm'),

  // Broadcasts
  Broadcasts: () => import(/* webpackChunkName: "broadcasts" */ '../Broadcasts'),
  BroadcastDetails: () => import(/* webpackChunkName: "broadcast-details" */ '../Broadcasts/Details'),
  BroadcastsEdit: () => import(/* webpackChunkName: "broadcasts-edit" */ '../Broadcasts/Edit'),
  // Subscribers
  Subscribers: () => import(/* webpackChunkName: "subscribers" */ '../Subscribers'),
  SubscribersEdit: () => import(/* webpackChunkName: "subscribers-edit" */ '../Subscribers/EditForm'),
  SubscribersGroupEdit: () => import(/* webpackChunkName: "subscribers-group-edit" */ '../Subscribers/GroupEditForm'),
  // MessageMatchers
  MessageMatchers: () => import(/* webpackChunkName: "message-matchers" */ '../MessageMatchers'),
  WhatsAppMessageMatcherEdit: () => import(/* webpackChunkName: "new-whats-app-message-matcher-edit" */ '../MessageMatchers/WhatsAppForm'),
  WhatsAppMessageMatcherView: () => import(/* webpackChunkName: "new-whats-app-message-matcher-view" */ '../MessageMatchers/WhatsAppForm/WhatsAppView'),
  ViberMessageMatcherEdit: () => import(/* webpackChunkName: "viber-message-matcher-edit" */ '../MessageMatchers/ViberForm'),
  SmsMessageMatcherEdit: () => import(/* webpackChunkName: "sms-message-matcher-edit" */ '../MessageMatchers/SMSForm'),
  SmsMessageMatcherView: () => import(/* webpackChunkName: "sms-message-matcher-view" */ '../MessageMatchers/SMSForm/View'),
  InstagramMessageMatcherEdit: () => import(/* webpackChunkName: "instagram-message-matcher-edit" */ '../MessageMatchers/InstagramForm'),
  OkNotifyMessageMatcherEdit: () => import(/* webpackChunkName: "ok-notify-message-matcher-edit" */ '../MessageMatchers/OkNotifyForm'),
  VkNotifyMessageMatcherEdit: () => import(/* webpackChunkName: "vk-notify-message-matcher-edit" */ '../MessageMatchers/VkNotifyForm'),
  // Cascades
  Cascades: () => import(/* webpackChunkName: "cascades" */ '../Cascades'),
  CascadeEdit: () => import(/* webpackChunkName: "cascade-edit" */ '../Cascades/EditForm'),
  Profile: () => import(/* webpackChunkName: "profile" */ '../Profile'),
  //Company
  UserCompanyProfile: () => import(/* webpackChunkName: "user-company-profile" */ '../UserCompanyProfile'),
  LeadCompanyClients: () => import(/* webpackChunkName: "lead-company-clients" */ '../LeadCompanyClients'),
  LeadCompanyClientCard: () => import(/* webpackChunkName: "lead-company-client-card" */ '../LeadCompanyClients/LeadCompanyClientCard'),
  LeadCompanyClientEdit: () => import(/* webpackChunkName: "lead-client-edit" */ '../LeadCompanyClients/EditForm'),
  // Support
  SupportClients: () => import(/* webpackChunkName: "support-clients" */ '../SupportClients'),
  SupportClientCard: () => import(/* webpackChunkName: "support-client-card" */ '../SupportClients/SupportClientCard'),
  // Integration
  Integration: () => import(/* webpackChunkName: "integration" */ '../Integration'),

  // Analytics
  Report: () => import(/* webpackChunkName: "report" */ '../Report'),
  SummaryReport: () => import(/* webpackChunkName: "summary-report" */ '../SummaryReport'),
  ChannelReport: () => import(/* webpackChunkName: "channel-report" */ '../SummaryReport/ChannelReport'),

  NotificationsHistory: () => import(/* webpackChunkName: "notifications-history" */ '../NotificationsHistory'),
  Help: () => import(/* webpackChunkName: "help" */ '../Help'),
  Triggers: () => import(/* webpackChunkName: "triggers" */ '../Triggers'),
  TriggersEdit: () => import(/* webpackChunkName: "triggers-edit" */ '../Triggers/EditForm'),
  Files: () => import(/* webpackChunkName: "files" */ '../ServerFile'),

  // Admin Pages
  LicenseRenewal: () => import(/* webpackChunkName: "license-renewal" */ '../LicenseRenewal'),

};

/* prettier-ignore-end */

const C = bootstrapComponents(componentImports);

export {C as Components};

type TPrivateRoutes = Record<
  ValueOf<typeof PATHS>,
  {
    component: ValueOf<typeof C>;
    permissions?: EPermission[];
    roles?: ERoles[];
  }
>;

export const PRIVATE_ROUTES: TPrivateRoutes = {
  [PATHS.HOME]: {
    component: C.Home,
  },
  [PATHS.BROADCASTS_LIST]: {
    component: C.Broadcasts,
    permissions: [EPermission.PERMISSION_BROADCAST_READ],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.BROADCASTS_NEW]: {
    component: C.BroadcastsEdit,
    permissions: [
      EPermission.PERMISSION_BROADCAST_READ,
      EPermission.PERMISSION_BROADCAST_WRITE,
      EPermission.PERMISSION_WRITE,
    ],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.BROADCASTS_EDIT]: {
    component: C.BroadcastsEdit,
    permissions: [EPermission.PERMISSION_BROADCAST_READ],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.BROADCASTS_DETAILS]: {
    component: C.BroadcastDetails,
    permissions: [EPermission.PERMISSION_BROADCAST_READ],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_LIST]: {
    component: C.Channels,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_NEW]: {
    component: C.WhatsAppChannelEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_EDIT]: {
    component: C.WhatsAppChannelEdit,
    permissions: [EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_VIEW]: {
    component: C.WhatsAppChannelEdit,
    permissions: [EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_NEW]: {
    component: C.EmbeddedWhatsAppChannelForm,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_EDIT]: {
    component: C.EmbeddedWhatsAppChannelForm,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_EMBEDDED_WHATSAPP_VIEW]: {
    component: C.EmbeddedWhatsAppChannel,
    permissions: [EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_NEW]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_EDIT]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_WHATSAPP_TECH_PARTNER_VIEW]: {
    component: C.WhatsAppTechPartnerChannelForm,
    permissions: [EPermission.PERMISSION_WHATSAPP_PROFILE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_VIBER_NEW]: {
    component: C.ViberChannelEditForm,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_VIBER_EDIT]: {
    component: C.ViberChannelEditForm,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_VIBER_VIEW]: {
    component: C.ViberChannelViewForm,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_SMS_NEW]: {
    component: C.SmsChannelEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_SMS_EDIT]: {
    component: C.SmsChannelEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_SMS_VIEW]: {
    component: C.SmsChannelEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_INSTAGRAM_NEW]: {
    component: C.InstagramChannelEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_INSTAGRAM],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_INSTAGRAM_VIEW]: {
    component: C.InstagramChannelEdit,
    permissions: [EPermission.PERMISSION_INSTAGRAM],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_APP_DATA]: {
    component: C.PushAppData,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_NEW]: {
    component: C.PushChannelEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_LIST]: {
    component: C.PushChannelApps,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_IOS_EDIT]: {
    component: C.PushAppsIOSEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_APP_NEW]: {
    component: C.StartSetupApp,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_IOS_NEW_STEP]: {
    component: C.PushAppsIOSEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_ANDROID_NEW_STEP]: {
    component: C.PushAppsAndroidEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_PUSH_ANDROID_EDIT]: {
    component: C.PushAppsAndroidEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CASCADES_LIST]: {
    component: C.Cascades,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CASCADES_NEW]: {
    component: C.CascadeEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CASCADES_VIEW]: {
    component: C.CascadeEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_LIST]: {
    component: C.MessageMatchers,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_NEW]: {
    component: C.WhatsAppMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_EDIT]: {
    component: C.WhatsAppMessageMatcherEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_WHATSAPP_VIEW]: {
    component: C.WhatsAppMessageMatcherView,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_NEW]: {
    component: C.ViberMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_EDIT]: {
    component: C.ViberMessageMatcherEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VIBER_VIEW]: {
    component: C.ViberMessageMatcherEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_SMS_NEW]: {
    component: C.SmsMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_SMS_EDIT]: {
    component: C.SmsMessageMatcherEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_SMS_VIEW]: {
    component: C.SmsMessageMatcherView,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_NEW]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_INSTAGRAM],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_EDIT]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_INSTAGRAM],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_INSTAGRAM_VIEW]: {
    component: C.InstagramMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_INSTAGRAM],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_NEW]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_EDIT]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_VK_NOTIFY_VIEW]: {
    component: C.VkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.TRIGGERS_LIST]: {
    component: C.Triggers,
    permissions: [EPermission.PERMISSION_FLOW],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.TRIGGERS_NEW]: {
    component: C.TriggersEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.TRIGGERS_EDIT]: {
    component: C.TriggersEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.TRIGGERS_VIEW]: {
    component: C.TriggersEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.SUBSCRIBERS_LIST]: {
    component: C.Subscribers,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.SUBSCRIBERS_NEW]: {
    component: C.SubscribersEdit,
    permissions: [EPermission.PERMISSION_WRITE],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.SUBSCRIBERS_EDIT]: {
    component: C.SubscribersEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.SUBSCRIBERS_GROUP_EDIT]: {
    component: C.SubscribersGroupEdit,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.PROFILE]: {
    component: C.Profile,
  },
  [PATHS.COMPANY_PROFILE]: {
    component: C.UserCompanyProfile,
  },
  [PATHS.COMPANY_CLIENTS]: {
    component: C.LeadCompanyClients,
    roles: [ERoles.ROLE_ACCOUNT_MANAGER],
  },
  [PATHS.COMPANY_CLIENT_CARD]: {
    component: C.LeadCompanyClientCard,
    roles: [ERoles.ROLE_ACCOUNT_MANAGER],
  },
  [PATHS.COMPANY_CLIENT_NEW]: {
    component: C.LeadCompanyClientEdit,
    roles: [ERoles.ROLE_ACCOUNT_MANAGER],
  },
  [PATHS.CORRECTIONS]: {
    component: C.Corrections,
    permissions: [EPermission.PERMISSION_TENANT_STORNO],
  },
  [PATHS.RENEWAL]: {
    component: C.LicenseRenewal,
    permissions: [EPermission.PERMISSION_TENANT_LICENSE_RENEW],
    roles: [ERoles.ROLE_TENANT_LICENSE_RENEW],
  },
  [PATHS.SUPPORT_CLIENTS]: {
    component: C.SupportClients,
    permissions: [EPermission.PERMISSION_TENANT_LIST_READ],
    roles: [ERoles.ROLE_SUPPORT],
  },
  [PATHS.SUPPORT_CLIENT_CARD]: {
    component: C.SupportClientCard,
    permissions: [EPermission.PERMISSION_TENANT_DATA_READ],
    roles: [ERoles.ROLE_SUPPORT],
  },
  [PATHS.INTEGRATION]: {
    component: C.Integration,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.ANALYTICS_MESSAGES]: {
    component: C.Report,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.ANALYTICS_SUMMARY_REPORT]: {
    component: C.SummaryReport,
    roles: [ERoles.ROLE_USER],
    permissions: [EPermission.PERMISSION_VIEW_BILLING],
  },
  [PATHS.ANALYTICS_CHANNEL]: {
    component: C.ChannelReport,
    roles: [ERoles.ROLE_USER],
  },
  // TODO https://jira.edna.ru/browse/EP-3937 remove after prod test
  [PATHS.ANALYTICS_CHANNEL_TEST]: {
    component: C.ChannelReport,
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.NOTIFICATIONS]: {
    component: C.NotificationsHistory,
  },
  [PATHS.HELP]: {
    component: C.Help,
  },
  [PATHS.FILES]: {
    component: C.Files,
  },
  [PATHS.UNAVAILABLE]: {
    component: C.ServiceUnavailable,
  },
  [PATHS.CHANNELS_VK_NOTIFY_NEW]: {
    component: C.VkNotifyEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_VK_NOTIFY_EDIT]: {
    component: C.VkNotifyEdit,
    permissions: [EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_VK_NOTIFY_VIEW]: {
    component: C.VkNotifyEdit,
    permissions: [EPermission.PERMISSION_VK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_OK_NOTIFY_NEW]: {
    component: C.OkNotifyEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_OK_NOTIFY_EDIT]: {
    component: C.OkNotifyEdit,
    permissions: [EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.CHANNELS_OK_NOTIFY_VIEW]: {
    component: C.OkNotifyEdit,
    permissions: [EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_NEW]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_WRITE, EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_EDIT]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
  [PATHS.MESSAGE_MATCHERS_OK_NOTIFY_VIEW]: {
    component: C.OkNotifyMessageMatcherEdit,
    permissions: [EPermission.PERMISSION_OK_NOTIFY],
    roles: [ERoles.ROLE_USER],
  },
};

export type TRoutesOptions = Record<ValueOf<typeof PATHS>, ValueOf<typeof C>>;

export const PUBLIC_ROUTES: TRoutesOptions = {
  [PATHS.SIGN_IN]: C.SignIn,
  [PATHS.PASSWORD_SAVED]: C.PasswordSaved,
  [PATHS.SIGN_IN_BY_INVITE]: C.SignInByInviteLink,
  [PATHS.EMAIL_CONFIRM]: C.EmailConfirm,
  [PATHS.UNAVAILABLE]: C.ServiceUnavailable,
  [PATHS.PUBLIC_WHATSAPP_REGISTRATION]: C.WhatsAppPublicChannelForm,
};

export const getPrivateRoutesOptions = (userPermissions: EPermission[], userRoles: ERoles[]) => {
  const result: TRoutesOptions = {};

  Object.entries(PRIVATE_ROUTES).forEach(([path, {component, permissions, roles}]) => {
    if (includesArray(permissions, userPermissions) && includesArray(roles, userRoles)) {
      result[path] = component;
    }
  });

  return result;
};
