import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

export const Message = styled.div<TTheme>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  ${({theme: {palette, branding}}) => css`
    background-color: ${branding.backgrounds.primary};
    &::before {
      content: '';
      ${mixins.position({
        position: 'absolute',
        top: '-5px',
        left: '-5px',
      })}
      transform: rotate(135deg);
      border: 5px solid ${palette.white} ${palette.white} transparent transparent;
      border-bottom-right-radius: 3px;
    }
  `}
`;
