import React from 'react';

import * as S from './style';

type TProps = {
  className?: string;
  children?: React.ReactNode;
  color?: TThemeColor;
};

const Tabs = React.memo<TProps>(({children, className, color}) => (
  <div className={className}>
    <S.Content color={color}>{children}</S.Content>
  </div>
));

export default Tabs;

type TTabProps = {
  className?: string;
  active?: boolean;
  onClick?: TEmptyFunction;
  children: React.ReactNode;
};

export const Tab = React.memo<TTabProps>(
  ({className, active = false, onClick, children, ...props}) => (
    <S.Tab className={className} role="tab" active={active} onClick={onClick} {...props}>
      {children}
    </S.Tab>
  ),
);
