import React from 'react';

import {TSize} from '@edna/components/types';

import * as S from './style';

type TProps = {
  size?: TSize;
  width?: string;
  currentPercent?: number;
};

const ProgressBar = React.memo<TProps>(({size, width, currentPercent = 0}) => (
  <S.ProgressBarLayout width={width} size={size}>
    <S.ProgressBar percent={currentPercent} />
  </S.ProgressBarLayout>
));

export default ProgressBar;
