import React from 'react';

import * as S from './style';

type TProps = {
  apiKey?: string;
};

const Headers = React.memo<TProps>(({apiKey = ''}) => {
  const headers = [
    {
      key: 'Content-Type:',
      value: 'application/json',
    },
    {
      key: 'x-api-key:',
      value: apiKey,
    },
  ];

  return (
    <>
      {headers.map(({key, value}) => (
        <S.Tr key={key}>
          <S.Td>{key}</S.Td>
          <S.Td>{value}</S.Td>
        </S.Tr>
      ))}
    </>
  );
});

Headers.displayName = 'Headers';
export default Headers;
