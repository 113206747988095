import {EVerificationMethods} from '../definitions';

export default {
  requestTitle: 'Select a phone verification method',
  confirmTitle: 'Enter the received code',
  warning: `Attention!
 The number of attempts to request the code is limited.
 After 3 attempts to request the code, you will be able to request
 a new code only after 3 hours.
 After 5 attempts, you will need to contact Facebook support.`,
  formField: {
    phone: 'Phone number to which {{method}} will be sent',
    senderName: 'WhatsApp display name',
    method: 'Verification method',
    methodOption: {
      [EVerificationMethods.SMS]: 'SMS',
      [EVerificationMethods.VOICE]: 'A voice message',
    },
    code: 'Confirmation code',
  },
  phoneNumberVerified: 'Phone number verified',
};
