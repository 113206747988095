import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

import {getTitle} from 'src/utils/pageTitle';

import {ESize} from './constants';
import * as S from './style';

type TProps = {
  backToUrl?: string;
  title?: string;
  size?: keyof typeof ESize;
  noPadding?: boolean;
  onClick?: TEmptyFunction;
  titleVariables?: Record<string, string>;
};

const Header = React.memo<TProps>(
  ({backToUrl, title, onClick, size = 'M', noPadding = false, titleVariables}) => {
    const {pathname} = useLocation();
    const {i18n} = useTranslation();

    return (
      <S.Header noPadding={noPadding}>
        <S.Title>
          {!!backToUrl && (
            <S.ArrowWrapper to={backToUrl} as={Link} size={size}>
              <S.ArrowBackIcon />
            </S.ArrowWrapper>
          )}
          {!!onClick && (
            <S.ArrowWrapper onClick={onClick} size={size}>
              <S.ArrowBackIcon />
            </S.ArrowWrapper>
          )}
          <S.H1 size={size}>{title || getTitle(i18n, pathname, titleVariables)}</S.H1>
        </S.Title>
      </S.Header>
    );
  },
);

Header.displayName = 'Header';

export default Header;
