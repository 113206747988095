import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {EStorageKeys} from 'src/constants';
import {listenerMiddleware} from 'src/models/api';

import {EFormStep} from './types';

export type TFormState = {
  activeStep: keyof typeof EFormStep;
  steps: Record<string, {isVisited: boolean}>;
};

const initialFromState: TFormState = {
  activeStep: EFormStep.DETAILS,
  steps: {
    [EFormStep.DETAILS]: {
      isVisited: true,
    },
    [EFormStep.CALCULATION_AND_LICENSES]: {
      isVisited: false,
    },
    [EFormStep.TARIFFS]: {
      isVisited: false,
    },
  },
};

const initialState = () => {
  const rememberedFilters = sessionStorage.getItem(EStorageKeys.LEAD_COMPANY_CLIENTS_FILTERS);

  return {
    filters: rememberedFilters ? JSON.parse(rememberedFilters) : {},
    formState: initialFromState,
  };
};

const slice = createSlice({
  name: 'leadClients',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
    setActiveFormStep(state, action: PayloadAction<keyof typeof EFormStep>) {
      state.formState.activeStep = action.payload;
      state.formState.steps[action.payload].isVisited = true;
    },
    resetFormState(state) {
      state.formState = initialFromState;
    },
  },
});

export const {setFilters: setFiltersAction, setActiveFormStep, resetFormState} = slice.actions;
export const selectFilters = (state: TRootState) => state.leadClients.filters;
export const selectFormSteps = (state: TRootState) => state.leadClients.formState.steps;
export const selectFormActiveStep = (state: TRootState) => state.leadClients.formState.activeStep;
export const selectIsFormFirstStep = (state: TRootState) =>
  state.leadClients.formState.activeStep === Object.keys(state.leadClients.formState.steps)[0];
export const selectIsFormLastStep = (state: TRootState) =>
  state.leadClients.formState.activeStep === Object.keys(state.leadClients.formState.steps).pop();

listenerMiddleware.startListening({
  actionCreator: setFiltersAction,
  effect: (action) => {
    sessionStorage.setItem(
      EStorageKeys.LEAD_COMPANY_CLIENTS_FILTERS,
      JSON.stringify(action.payload),
    );
  },
});

export default slice.reducer;
