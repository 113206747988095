import {EChannelType} from 'src/constants';
import {EType} from 'src/containers/MessageMatchers/constants';
import {TItem, TType} from 'src/containers/MessageMatchers/types';
import {TItem as TParameterItem} from 'src/containers/Parameters/definitions';
import {getParametersOptions} from 'src/containers/Parameters/utils';
import {EMarkup, parseMarkdown} from 'src/utils';

const insertVariableText = (contentText: string, parameters: TParameterItem[]) => {
  const variableOptions = getParametersOptions(parameters);

  return contentText.replace(/({{([^{}]+)}})/g, (_, text, id) => {
    const option = variableOptions.find((item) => String(item.id) === String(id));

    if (option) {
      return option.value;
    }

    return text;
  });
};

const HTML_CODE: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#039;',
};

const escapeHtml = (text: string) => text.replace(/[&<>"']/g, (value) => HTML_CODE[value]);

const prepareText = ({
  type,
  text,
  parameters,
  channelType,
}: {
  type?: TType | null;
  text?: string | null;
  parameters?: TParameterItem[];
  channelType: TItem['channelType'];
}) => {
  if (!text) {
    return '';
  }
  let newText = escapeHtml(text);

  if (type !== EType.OPERATOR && !!parameters?.length) {
    newText = insertVariableText(newText, parameters);
  }

  if ([EChannelType.VIBER, EChannelType.WHATSAPP].includes(channelType as EChannelType)) {
    return parseMarkdown(newText);
  }

  return parseMarkdown(newText, [EMarkup.LINK]);
};

const FOOTER_TIME = '12:00';

export {FOOTER_TIME, prepareText, insertVariableText};
