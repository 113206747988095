import {EUrlType} from './definitions';

export default {
  addCallback: 'Добавить Callback URL',
  changeCallback: 'Изменить URL',
  modalNote: 'Для корректной работы настроек требуется проверка URL',
  modalText: 'Вставьте Callback URL, только  HTTPS, порт 443',
  errorMessage: 'Запрашиваемый ресурс не существует',
  jivoConnectedMessage: 'Изменить Callback URL нельзя, так как у вас подключен готовый модуль Jivo',
  callbackUrlType: {
    [EUrlType.IN_MESSAGE]: {
      title: 'Callback URL для входящих сообщений',
      description:
        'На указанный URL вы можете получать входящие сообщения от клиентов. Ваш адрес должен существовать и быть доступным для запроса из публичного интернета. Для проверки доступности мы пошлем HEAD запрос, на который ожидаем увидеть ответ со статусом 200. В противном случае мы не сможем зарегистрировать ваш callback url.',
      add: {
        title: 'Добавить Callback URL входящих сообщений',
      },
      change: {
        title: 'Изменить Callback URL входящих сообщений?',
      },
      delete: {
        title: 'Удалить Callback URL входящих сообщений?',
        text: 'Тогда вы не сможете получать входящие сообщения от клиентов',
        notification: 'Callback URL входящих сообщений успешно удален',
      },
    },
    [EUrlType.STATUS]: {
      title: 'Callback URL для статусов',
      description:
        'На указанный URL вы можете получать статусы отправленных рассылок. Ваш адрес должен существовать и быть доступным для запроса из публичного интернета. Для проверки доступности мы пошлем HEAD запрос, на который ожидаем увидеть ответ со статусом 200. В противном случае мы не сможем зарегистрировать ваш callback url.',
      add: {
        title: 'Добавить Callback URL для статусов',
      },
      change: {
        title: 'Изменить Callback URL для статусов?',
      },
      delete: {
        title: 'Удалить Callback URL для статусов?',
        text: 'Тогда вы не сможете получать статусы отправленных рассылок',
        notification: 'Callback URL статусов успешно удален',
      },
    },
    [EUrlType.MESSAGE_MATCHER]: {
      title: 'Callback URL для API управления',
      description:
        'На указанный URL вы можете получать обновления по изменению настроек. Ваш адрес должен существовать и быть доступным для запроса из публичного интернета. Для проверки доступности мы пошлем HEAD запрос, на который ожидаем увидеть ответ со статусом 200. В противном случае мы не сможем зарегистрировать ваш callback url.',
      add: {
        title: 'Добавить Callback URL для API управления',
      },
      change: {
        title: 'Изменить Callback URL для API управления?',
      },
      delete: {
        title: 'Удалить Callback URL для API управления?',
        text: 'Тогда вы не сможете получать обновления по изменению настроек',
        notification: 'Callback URL для API управления успешно удален',
      },
    },
  },
  yourAPIKey: 'Ваш API-ключ',
  APIKeyDescription:
    'Вы можете проинтегрировать наш сервис с любым приложением, получив доступ к рассылкам сообщений через API.<br/>Со всеми методами API вы можете ознакомится<br/> в <0>инструкции разработчика</0>.',
  generateAPIKey: 'Сгенерировать API-ключ',
  generateNewAPIKey: 'Сгенерировать новый API-ключ',
  changeAPIKeyModal: {
    title: 'Изменить API-ключ?',
    message:
      'При смене API-ключа прекратится трафик, который поступал по старому ключу. Для корректной работы скопируйте новый ключ и вставьте его себе в систему.',
  },
  callbackAuth: 'Аутентификация callback-запросов',
  callbackAuthHint:
    'Вы можете включить аутентификацию callback-запросов edna Pulse к вашему серверу. Установите флажок, чтобы сгенерировать API-ключ. edna Pulse будет использовать его при отправке запросов на ваш API-endpoint.',
  callbackAuthSwitch: 'Включить аутентификацию запросов edna Pulse',
  generateNewCallbackKey: 'Сгенерировать новый ключ для callback-запросов',
  authErrorText: 'Не удалось выполнить запрос, попробуйте позже.',
  copyCallbackKey: 'Скопируйте ваш API-ключ',
  newCallbackKeyHint:
    'Ради безопасности ваших данных API-ключ отображается полностью и может быть скопирован только один раз, потом вы не сможете увидеть его целиком или скопировать. Скопируйте его сейчас и передайте разработчику или сохраните в надежном месте.',
};
