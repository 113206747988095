import React from 'react';

import * as S from './style';

type TProps = {
  className?: string;
  children?: React.ReactNode;
};

const ToggleButtons = React.memo<TProps>(({children, className}) => (
  <S.ToggleButtons className={className}>{children}</S.ToggleButtons>
));

export default ToggleButtons;

type TTabProps = {
  className?: string;
  active?: boolean;
  onClick?: TEmptyFunction;
  children: React.ReactNode;
};

export const ToggleButton = React.memo<TTabProps>(
  ({className, active = false, onClick, children}) => (
    <S.ToggleButton
      className={className}
      role="tab"
      active={active}
      onClick={onClick}
      appearance={active ? 'primary' : 'secondary'}
    >
      {children}
    </S.ToggleButton>
  ),
);
