import {EUrgencyType} from './definitions';

export default {
  title: 'Звук, вибрация и цвет LED',
  urgencyType: {
    [EUrgencyType.PASSIVE]: 'Пассивный',
    [EUrgencyType.ACTIVE]: 'Активный',
    [EUrgencyType.TIME_SENSITIVE]: 'Неотложный',
    [EUrgencyType.CRITICAL]: 'Критический',
  },
  urgency: 'Уровень прерывания (только iOS)',
  urgencyHint:
    'Уровень прерывания определяет вид уведомления на iOS 15 и выше. Подробнее <0>в edna docs</0>',
  addStyle: 'Добавить новый канал уведомлений',
  styleName: 'Название канала уведомлений для Андроид',
  styleNameHint:
    'Выберите канал уведомлений для Андроид. Пользователи увидят его название в настройках смартфона. Изменить параметры канала сможет только сам пользователь. Подробнее <0>{{docs}}</0>',
  addStyleModal: {
    title: 'Добавить канал уведомлений Андроид',
    name: 'Введите название канала уведомлений Андроид',
    sound: 'Звук на устройстве при получении пуша',
    soundHint:
      'Имя файла со звуком (без расширения). Файл с таким именем должен находится в каталоге res/raw приложения Андроид и в корневом каталоге Xcode приложения iOS',
    lights: 'Цвет LED при получении пуша (только Андроид)',
    lightsHint:
      'На некоторых смартфонах Андроид есть сигнальный светодиод. Можно задать цвет мигания этого светодиода при получении пуша',
    vibrate: 'Паттерн вибрации при получении пуша (только Андроид)',
    vibrateHint: `Последовательность промежутков бездействия и вибрации мотора при получении пуша в миллисекундах.
       Первое значение – бездействие.

      Например, при паттерне [300,500,300,500] на устройстве будет 300 мс бездействия, 500 мс вибрации, 300 мс бездействия, 500 мс вибрации.

      Только для Андроид.`,
    vibratePlaceholder: '[300,500,300,500]',
  },
};
