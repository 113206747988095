import React, {useMemo} from 'react';

import {newFormField} from '@edna/components/FormField/hoc';

import {convertTimezoneToGMTOffset} from 'src/utils';

import * as S from './style';

type TProps = {
  value?: string;
  onChange?: TAnyFunction;
};

const TimezoneSelect = React.memo<TProps>(({value = null, onChange}) => {
  const timezoneOptions = useMemo(() => {
    return (Intl as unknown as {supportedValuesOf(input: string): string[]})
      .supportedValuesOf('timeZone')
      .map((timeZone: string) => {
        return {label: `${timeZone} ${convertTimezoneToGMTOffset(timeZone)}`, value: timeZone};
      });
  }, []);

  return <S.Select value={value} options={timezoneOptions} onChange={onChange} />;
});

TimezoneSelect.displayName = 'TimezoneSelect';

export default TimezoneSelect;

export const TimezoneSelectField = newFormField(TimezoneSelect);
