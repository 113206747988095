import {AnyAction} from 'redux';

import storage from '@edna/utils/storage';

import {EStorageKeys} from 'src/constants';
import {clearStorage} from 'src/models';
import {ECacheTag, listenerMiddleware, rootApi} from 'src/models/api';

import {
  TCaptcha,
  TChangePasswordFormData,
  TSendOtpPayload,
  TSignInPayload,
  TSignInResponse,
  TState,
} from './definitions';
import userModel from './userModel';

const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaptcha: builder.query<TCaptcha, void>({
      query: () => ({
        method: 'GET',
        url: `/auth/captcha/gen`,
        meta: {isShowError: true},
      }),
      providesTags: [ECacheTag.AUTH_CAPTCHA],
    }),
    signIn: builder.mutation<TSignInResponse, TSignInPayload>({
      query: (data) => ({
        method: 'POST',
        url: `/auth/login`,
        data,
      }),
    }),
    signOut: builder.mutation<TEmptyObject, void>({
      query: () => ({
        method: 'POST',
        url: `/auth/logout`,
        meta: {
          isShowError: true,
        },
      }),
    }),
    changePassword: builder.mutation<void, TChangePasswordFormData>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/password/change',
        data,
        meta: {
          isShowSuccess: true,
          successMessageKey: 'Profile:notification.changePasswordSuccess',
        },
      }),
    }),
    sendOtp: builder.mutation<TSignInResponse, TSendOtpPayload>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/otp',
        data,
      }),
    }),
    resetOtp: builder.mutation<TSignInResponse, {otpId: TState['otpId']}>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/otp-reset',
        data,
      }),
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: authApi.endpoints.signIn.matchFulfilled,
  effect: (data) => {
    storage.set(EStorageKeys.USERNAME, data.payload.login);
  },
});

listenerMiddleware.startListening({
  matcher: authApi.endpoints.signOut.matchFulfilled,
  effect: (_, {dispatch}) => {
    sessionStorage.clear();
    clearStorage();

    dispatch(userModel.actions.resetUser() as unknown as AnyAction);
    dispatch(rootApi.util.resetApiState());
    dispatch(userModel.actions.removeTenant() as unknown as AnyAction);
  },
});

export const {
  useSignInMutation,
  useGetCaptchaQuery,
  useChangePasswordMutation,
  useSignOutMutation,
  useResetOtpMutation,
  useSendOtpMutation,
} = authApi;
