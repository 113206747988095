import {EPaymentTab} from './definitions';

export default {
  yourBalance: 'Ваш баланс',
  generateInvoice: 'Сформировать счет',
  topUpBalance: 'Пополнить баланс',
  stripe: 'Оплатить картой',
  tab: {
    [EPaymentTab.REPLENISHMENT]: 'Пополнение баланса',
    [EPaymentTab.ACCOUNT_STATEMENT]: 'Выписка по счету',
    [EPaymentTab.DOCUMENTATION]: 'Документы',
  },
};
