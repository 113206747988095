import React from 'react';

import noop from 'lodash/noop';

import {Dropdown} from '@edna/components';
import {newFormField} from '@edna/components/FormField/hoc';

import * as S from './style';

type TValue = string;

type THandler = (value: TValue) => void;

type TProps = React.InputHTMLAttributes<HTMLInputElement> & {
  isInvalid?: boolean;
  value?: TValue;
  prefix?: React.ReactNode;
  onChange?: THandler;
  onFocus?: THandler;
  onBlur?: THandler;
};

const ColorPicker = React.memo<TProps>(
  ({
    className,
    id: propId,
    disabled = false,
    readOnly = false,
    onChange = noop,
    name,
    value,
    isInvalid,
    onFocus,
    onBlur,
    prefix,
  }) => {
    const id = propId ?? name;

    const handleColorSelect = React.useCallback(
      (hex: string) => {
        if (!disabled && !readOnly) {
          onChange(hex.toUpperCase());
        }
      },
      [disabled, onChange, readOnly],
    );

    return (
      <S.ColorPicker className={className}>
        {prefix && <S.Prefix disabled={disabled || readOnly}>{prefix}</S.Prefix>}
        <S.TextInput
          id={id}
          name={name}
          value={value}
          isInvalid={isInvalid}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={7}
        />
        <Dropdown
          disabled={disabled}
          head={
            <S.Header disabled={disabled} readOnly={readOnly}>
              <S.HeaderInner color={value as TThemeColor} />
            </S.Header>
          }
          overlay={
            <S.Overlay disabled={disabled || readOnly}>
              <S.HexColorPicker
                color={value}
                onChange={handleColorSelect}
                disabled={disabled || readOnly}
              />
            </S.Overlay>
          }
          closeWhen="clickOutsideOverlay"
        />
      </S.ColorPicker>
    );
  },
);

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;

export const ColorPickerField = newFormField<TProps>(ColorPicker);
