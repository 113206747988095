export default {
  availableTariffs: 'Available pricing',
  availableTariffsHint:
    'Available pricing is the pricing that you have previously agreed on with the manager; you need to accept it before the pricing activation date',
  activeTariffs: 'Active pricing',
  activeTariffsHint: 'Active pricing is the pricing connected to your user account in channels.',
  acceptAllTariffs:
    'Familiarize yourself with the pricing and click "{{buttonName}}". The pricing becomes active once accepted.',
  button: {
    acceptTariff: 'Accept pricing',
  },
};
