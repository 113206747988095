import React from 'react';
import {useTranslation} from 'react-i18next';

import {TStep as TStepperStep} from '@edna/components';

type TBaseStep = Record<string, string>;
type TStep<T> = keyof T;
type TSteps<T extends TBaseStep = TBaseStep> = T;

type TStepState<T> = Required<TStepperStep<TStep<T>>>;

type TFormSteps<T extends TBaseStep> = {
  stepsState: TStepState<T>[];
  changeStep: (id: TStepperStep['id']) => void;
  goToNextStep: TEmptyFunction;
  goToPrevStep: TEmptyFunction;
};

type TPayload<T extends TBaseStep> = {
  steps: TSteps<T>;
  labelPrefix: string;
  activeStep: TStep<T>;
  setActiveStep: React.Dispatch<React.SetStateAction<TStep<T>>>;
  isFormValid?: boolean;
};

export const useFormSteps = <T extends TBaseStep>({
  steps,
  labelPrefix,
  isFormValid,
  setActiveStep,
  activeStep,
}: TPayload<T>): TFormSteps<T> => {
  const {t} = useTranslation();
  const stepsKeys = Object.keys(steps) as TStep<T>[];

  const stepsState: TStepState<T>[] = React.useMemo(() => {
    const activeStepIndex = stepsKeys.indexOf(activeStep);

    return stepsKeys.map((step, index) => ({
      id: step,
      disabled: index > activeStepIndex && !isFormValid,
      completed: index < activeStepIndex,
      label: t(`${labelPrefix}.${step.toString()}`),
    }));
  }, [t, activeStep, isFormValid]);

  const handleStepChange = React.useCallback(
    (step: string | number) => {
      if (step === activeStep) {
        return;
      }

      setActiveStep(step as TStep<T>);
    },
    [activeStep],
  );

  const goToNextStep = React.useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIndex = stepsKeys.indexOf(currentStep);

      return stepsKeys[Math.min(currentStepIndex + 1, stepsKeys.length - 1)];
    });
  }, [stepsKeys]);

  const goToPrevStep = React.useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIndex = stepsKeys.indexOf(currentStep);

      return stepsKeys[Math.max(currentStepIndex - 1, 0)];
    });
  }, [stepsKeys]);

  return {
    changeStep: handleStepChange,
    stepsState,
    goToNextStep,
    goToPrevStep,
  };
};
