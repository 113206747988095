import React from 'react';

import LeftArrowIcon from '@edna/icons/keyboardArrowLeft.svg';

import * as S from './style';

type TProps = {
  className?: string;
  content: React.ReactNode[];
};

const Gallery = React.memo<TProps>(({content, className}) => {
  const [index, setIndex] = React.useState(0);

  const hasPrev = index > 0;
  const hasNext = index < content.length - 1;

  return (
    <S.Gallery className={className}>
      <S.ArrowLeft type="button" disabled={!hasPrev} onClick={() => setIndex(index - 1)}>
        <LeftArrowIcon />
      </S.ArrowLeft>
      <div>{content[index]}</div>
      <S.ArrowRight type="button" disabled={!hasNext} onClick={() => setIndex(index + 1)}>
        <LeftArrowIcon />
      </S.ArrowRight>
    </S.Gallery>
  );
});

Gallery.displayName = 'Gallery';

export default Gallery;
