import React from 'react';

import {EChannelType, EContentType} from 'src/constants';
import {ImageIcon} from 'src/icons';
import {LogoImage, LogoTextImage} from 'src/images';

import * as SS from '../style';
import * as S from './style';

type TProps = {
  contentType: EContentType | null;
  attachmentUrl?: string;
  text: React.ReactNode;
};

const InstagramContent = React.memo<TProps>(({contentType, attachmentUrl, text}) => {
  const renderContent = () => {
    switch (contentType) {
      case EContentType.TEXT:
        return <S.Text>{text}</S.Text>;

      case EContentType.IMAGE: {
        if (attachmentUrl) {
          return <SS.Image type={EChannelType.INSTAGRAM} src={attachmentUrl} />;
        }

        return (
          <S.EmptyImage>
            <SS.Icon as={ImageIcon} />
          </S.EmptyImage>
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <S.Avatar>
        <LogoImage />
        <LogoTextImage />
      </S.Avatar>
      <S.Message>
        <SS.MessageContent noPaddings={contentType === EContentType.IMAGE} display="flex">
          {renderContent()}
        </SS.MessageContent>
      </S.Message>
    </>
  );
});

InstagramContent.displayName = 'InstagramContent';

export default InstagramContent;
