export enum EVariant {
  contained = 'contained',
  outlined = 'outlined',
}

export type TVariant = keyof typeof EVariant;

export enum EColor {
  grey300 = 'grey300',
  black = 'black',
  green400 = 'green400',
}

export type TColor = keyof typeof EColor;
