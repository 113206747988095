import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';

import {showNewNotification} from '@edna/components';
import apiModel, {
  TActionCreator,
  TAnyActionCreatorMap,
  TModel,
  TOptions,
  TReducerMap,
  TSelect,
  TThunk,
  TThunkCreator,
  TThunks,
} from '@edna/models/apiModel';

import {EListState, TListState as TItemsLoadingState} from 'src/components/List/definitions';
import errorNotification from 'src/models/errorNotification';
import request from 'src/models/request';

export enum ESortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TSortDirection = keyof typeof ESortDirection;

export type TListItemId = string | number;
export type TListItemIdObject = {id: TListItemId};

export type TListItemState = {
  loading?: boolean;
  saving?: boolean;
  deleting?: boolean;
  registering?: boolean;
};

export type TListItem = {id: TListItemId};

export type TSortItem = {
  property: string;
  direction: ESortDirection;
};

export type TSortField = TSortItem & {
  name: string;
};

export type TListFilters = TAnyObject;

type TListPayload = {
  limit?: number;
};

type TListStatic = {
  form: {
    edit: string;
    filter: string;
  };
  defaultSort: TSortItem;
  additionalSort: TSortItem;
  sortFields: TSortField[];
  filtersLocalStorageKey?: string;
};

export type TListState<
  Item extends TListItem = TListItem,
  InitialValues extends TAnyObject = TEmptyObject,
  ListItemState extends TListItemState = TListItemState,
> = {
  // pagination
  offset: number;
  limit: number;
  last: boolean;
  first: boolean;
  totalElements: number;
  // items
  content: Item[];
  activeItem: Optional<Item, 'id'> | null;
  // state
  itemsLoadingState: TItemsLoadingState;
  itemState: Record<TListItemId | 'new', ListItemState>;
  error: TAnyObject | null;
  // sort
  sort: TSortItem;
  // filters
  hasFilters: boolean;
  filters: TListFilters;
  // forms
  initialValues: InitialValues;
  //search
  searchTimestamp?: number;
  pageable?: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: TSortItem;
    unpaged: false;
  };
};

export type TMeta = {
  pristine?: boolean;
  setItemForEdit?: boolean;
  insertItem?: boolean;
  errorI18nOptions?: TAnyObject;
};

interface TListActions<Item extends TListItem> {
  // Reducers
  reset: TActionCreator<{
    save?: string | string[];
    only?: string | string[];
  } | void>;
  setItemsLoadingState: TActionCreator<TListState<Item>['itemsLoadingState']>;
  receiveAllItems: TActionCreator<TListState<Item>['content']>;
  receiveItems: TActionCreator<Pick<TListState<Item>, 'last' | 'content' | 'totalElements'>>;
  setItemForEdit: TActionCreator<TAnyObject | null | void>;
  setError: TActionCreator<TListState<Item>['error']>;
  resetError: TActionCreator;
  insertItem: TActionCreator<Item>;
  refreshItem: TActionCreator<Item>;
  removeItem: TActionCreator<TListItemIdObject>;
  updateItemState: TActionCreator<{
    item: Partial<TListItemIdObject> | null;
    state: TListItemState;
  }>;
  resetFiltersReducer: TActionCreator<Partial<TListState<Item>['filters']> | void>;
  toggleSortDirectionReducer: TActionCreator<TSortItem['property']>;
  updateFiltersReducer: TActionCreator<Partial<TListState<Item>['filters']>>;
  // Thunks
  requestAllItems: TThunkCreator<void, void, Promise<Item[] | undefined>>;
  requestItems: TThunkCreator<TListPayload | void, void, Promise<Item[] | void>>;
  debouncedRequestItems: TThunkCreator<TListPayload | void, void, Promise<Item[] | void>>;
  requestItem: TThunkCreator<TListItemIdObject, TMeta, Promise<Item | void>>;
  saveItem: TThunkCreator<Optional<Item, 'id'>, TMeta, Promise<Item | void>>;
  deleteItem: TThunkCreator<TListItemIdObject, void, Promise<void>>;
  registerItem: TThunkCreator<Item, TMeta | void, Promise<Item | void>>;
  register: TThunkCreator<Item | TListItemIdObject, TMeta | void, Promise<Item | void>>;
  toggleSortDirection: TThunkCreator<TSortItem['property']>;
  initializeFilters: TThunkCreator;
  updateFilters: TThunkCreator<
    Partial<TListState['filters']>,
    {isSaveSessionStorage: boolean} | void
  >;
  resetFilters: TThunkCreator<Partial<TListState<Item>['filters']> | void>;
}

type TRootStateMock = TAnyObject;

type TSelectItemState<ListItemState extends TListItemState = TListItemState> = (
  state: TRootStateMock,
  item?: Partial<TListItemIdObject> | null,
) => ListItemState;

type TSelectCanLoadMore = (state: TRootStateMock) => boolean;

type THasMore = (state: TRootStateMock) => boolean;

type TListUtils<Item extends TListItem> = {
  getInitialValues: (item?: Item | null) => TAnyObject;
};

export type TListOptions<
  Id extends string,
  Item extends TListItem,
  Static,
  State,
  Actions extends TAnyActionCreatorMap,
  Utils extends TListUtils<Item>,
  Selectors extends TAnyObject = TEmptyObject,
> = Omit<
  TOptions<
    Id,
    TListStatic,
    TListState<Item> & State,
    Overwrite<TListActions<Item>, Actions>,
    Selectors
  >,
  'staticData' | 'defaultState'
> & {
  apiEndpoint?: string;
  staticData?: Merge<Partial<TListStatic>, {form?: Partial<Pick<TListStatic, 'form'>>}> & Static;
  defaultState?: Partial<TListState<Item>> & Omit<State, keyof TListState<Item>>;
  utils?: Partial<Utils>;
};

export type TListDefaultOptions<
  Item extends TListItem,
  Static,
  State,
  Actions extends TAnyActionCreatorMap = TEmptyObject,
  Utils extends Partial<TListUtils<Item>> = TEmptyObject,
  Selectors extends TAnyObject = TEmptyObject,
> = Omit<
  TOptions<
    never,
    TEmptyObject,
    TListState<Item> & State,
    Overwrite<TListActions<Item>, Actions>,
    Selectors
  >,
  'id' | 'staticData' | 'defaultState'
> & {
  apiEndpoint?: string;
  staticData?: Merge<Partial<TListStatic>, {form?: Partial<Pick<TListStatic, 'form'>>}> & Static;
  defaultState?: Partial<TListState<Item>> & Omit<State, keyof TListState<Item>>;
  utils?: TListUtils<Item> & Utils;
};

export type TListModel<
  Id extends string,
  Item extends TListItem,
  Static extends TAnyObject,
  State extends TAnyObject,
  Actions extends TAnyActionCreatorMap = TEmptyObject,
  ListItemState extends TListItemState = TListItemState,
  Selectors extends TAnyObject = TEmptyObject,
> = TModel<Id, Static, State, Overwrite<TListActions<Item>, Actions>, Selectors> & {
  selectItemState: TSelectItemState<ListItemState>;
  selectCanLoadMore: TSelectCanLoadMore;
  hasContent: THasMore;
};

export const getItemState = <ListState extends TAnyObject = TListState>(state: ListState) =>
  state.itemState[state.activeItem?.id ?? 'new'] ?? {};

const defaultSelectors = {
  hasContent: (state: TListState) => state.content.length > 0,
  hasMore: (state: TListState) =>
    !state.last && state.itemsLoadingState !== 'PENDING' && state.itemsLoadingState !== 'ERROR',
  loading: (state: TListState) => getItemState(state).loading,
  saving: (state: TListState) => getItemState(state).saving,
  deleting: (state: TListState) => getItemState(state).deleting,
  registering: (state: TListState) => getItemState(state).registering,
};

const listApiModel = <
  Id extends string,
  Item extends TListItem = TListItem,
  Static = TEmptyObject,
  State extends TAnyObject = TListState<Item>,
  Actions extends TAnyActionCreatorMap = TEmptyObject,
  ListItemState extends TListItemState = TListItemState,
  Selectors extends TAnyObject = TEmptyObject,
>(
  options: TListOptions<Id, Item, Static, State, Actions, TListUtils<Item>, Selectors>,
): TListModel<
  Id,
  Item,
  TListStatic & Static,
  TListState<Item, State['initialValues'], ListItemState> & State,
  Actions,
  ListItemState,
  Selectors & typeof defaultSelectors
> => {
  type TModelListState = TListState<Item, State['initialValues'], ListItemState>;

  type TStatic = TListStatic & Static;
  type TState = TModelListState & State;
  type TActions = Overwrite<TListActions<Item>, Actions>;
  type TUtils = TListUtils<Item>;
  type TSelectors = Selectors & typeof defaultSelectors;

  const {id} = options;
  const apiEndpoint = options.apiEndpoint ?? id;
  const select: TSelect<TState> = (state) => state[id];
  const defaultSort: TSortItem = options.staticData?.defaultSort ?? {
    property: 'updatedAt',
    direction: ESortDirection.DESC,
  };
  const additionalSort: TSortItem = options.staticData?.additionalSort ?? {
    property: 'id',
    direction: ESortDirection.DESC,
  };

  const utils: TUtils = {
    getInitialValues: (item) => item ?? {},
    ...options.utils,
  };

  const selectItemState: TSelectItemState<ListItemState> = (state, item) => {
    const listState = select(state);
    const itemId = item?.id ?? 'new';

    return listState.itemState[itemId] ?? {};
  };

  const selectCanLoadMore: TSelectCanLoadMore = (state) => {
    const {itemsLoadingState, last} = select(state);

    return !last && itemsLoadingState !== 'PENDING' && itemsLoadingState !== 'ERROR';
  };

  const hasContent: THasMore = (state) => {
    const {content} = select(state);

    return content.length > 0;
  };

  const model = apiModel<Id, TStatic, TState, TListActions<Item> & Actions, TSelectors>({
    id,
    staticData: {
      defaultSort,
      additionalSort,
      sortFields: [
        {
          name: 'List:sort.byChangeAt_asc',
          property: 'updatedAt',
          direction: ESortDirection.ASC,
        },
        {
          name: 'List:sort.byChangeAt_desc',
          property: 'updatedAt',
          direction: ESortDirection.DESC,
        },
        {
          name: 'List:sort.byCreatedAt_asc',
          property: 'createdAt',
          direction: ESortDirection.ASC,
        },
        {
          name: 'List:sort.byCreatedAt_desc',
          property: 'createdAt',
          direction: ESortDirection.DESC,
        },
      ],
      ...options.staticData,
      form: {
        edit: `${id}/form/edit`,
        filter: `${id}/form/filter`,
        ...options.staticData?.form,
      },
    } as TStatic,
    defaultState: {
      ...({
        offset: 0,
        limit: 15,
        last: false,
        first: false,
        totalElements: 0,
        content: [],
        activeItem: null,
        initialValues: utils.getInitialValues() as TModelListState['initialValues'],
        error: null,
        sort: defaultSort,
        itemsLoadingState: EListState.UNINITIALIZED,
        itemState: {} as TModelListState['itemState'],
        hasFilters: false,
        filters: {},
        searchTimestamp: undefined,
      } as TModelListState),
      ...(options.defaultState as State),
    },
    selectors: {
      ...(options.selectors ?? ({} as Selectors)),
      ...defaultSelectors,
    },
    reducers: ({staticData, defaultState}) => ({
      ...{
        reset: (state, {payload = {}}) => {
          if (payload.only) {
            return {
              ...defaultState,
              ...omit(state, payload.only),
            };
          }

          if (payload.save) {
            return {
              ...defaultState,
              ...pick(state, payload.save),
            };
          }

          return {...defaultState};
        },
        setItemsLoadingState: (state, {payload}) => ({
          ...state,
          itemsLoadingState: payload,
        }),
        receiveAllItems: (state, {payload}) => ({
          ...state,
          last: true,
          content: payload,
          totalElements: payload.length,
        }),
        receiveItems: (state, {payload}) => {
          if (
            !state.searchTimestamp ||
            !payload.searchTimestamp ||
            payload.searchTimestamp >= state.searchTimestamp
          ) {
            const common = {
              ...state,
              last: payload.last || payload.content.length < state.limit,
              totalElements: payload.totalElements,
            };

            if (payload.pageable?.offset === 0) {
              return {
                ...common,
                offset: payload.content.length,
                content: [...payload.content],
              };
            }

            return {
              ...common,
              offset: state.offset + payload.content.length,
              content: [...state.content, ...payload.content],
            };
          }

          return state;
        },
        setError: (state, {payload}) => ({
          ...state,
          error: payload,
        }),
        resetError: (state) => ({
          ...state,
          error: null,
        }),
        insertItem: (state, {payload}) => ({
          ...state,
          content: [payload, ...state.content],
          offset: state.offset + 1,
          totalElements: state.totalElements + 1,
        }),
        refreshItem: (state, {payload}) => ({
          ...state,
          content: state.content.map((item) => (item.id === payload.id ? payload : item)),
          activeItem: payload.id === state.activeItem?.id ? payload : state.activeItem,
        }),
        removeItem: (state, {payload}) => {
          const hasItem = state.content.find((item) => item.id === payload.id);

          if (hasItem) {
            return {
              ...state,
              content: state.content.filter((item) => item.id !== payload.id),
              offset: state.offset - 1,
              totalElements: state.totalElements - 1,
            };
          }

          return state;
        },
        toggleSortDirectionReducer: (state, {payload}) => {
          let direction = ESortDirection.ASC;

          if (state.sort.property === payload) {
            direction =
              state.sort.direction === ESortDirection.ASC
                ? ESortDirection.DESC
                : ESortDirection.ASC;
          }

          const sort: TSortItem = {
            property: payload,
            direction,
          };

          return {
            ...state,
            offset: 0,
            last: false,
            content: [],
            totalElements: 0,
            sort,
          };
        },
        updateFiltersReducer: (state, {payload}) => {
          const filters = {...state.filters, ...payload};
          const timestamp = Date.now();

          return {
            ...state,
            offset: 0,
            last: false,
            content: [],
            totalElements: 0,
            hasFilters: !isEqual(filters, defaultState.filters),
            filters,
            searchTimestamp: timestamp,
          };
        },
        resetFiltersReducer: (state, {payload}) => ({
          ...state,
          offset: 0,
          last: false,
          content: [],
          totalElements: 0,
          hasFilters: false,
          filters: {...defaultState.filters, ...payload},
          searchTimestamp: undefined,
        }),
        updateItemState: (state, {payload}) => {
          const itemId = payload.item?.id ?? 'new';

          return {
            ...state,
            itemState: {
              ...state.itemState,
              [itemId]: {
                ...state.itemState[itemId],
                ...payload.state,
              },
            },
          };
        },
        setItemForEdit: (state, {payload}) => {
          const activeItem = (payload ?? null) as Item | null;

          return {
            ...state,
            activeItem,
            initialValues: utils.getInitialValues(activeItem),
          };
        },
      },
      ...((isFunction(options.reducers)
        ? options.reducers({staticData, defaultState})
        : options.reducers) as TReducerMap<TState, TActions>),
    }),
    api: {
      requestItems: (payload) => request.post(`/rest/${apiEndpoint}/list`, payload),
      requestItem: (payload) => request.get(`/rest/${apiEndpoint}/${payload.id}`),
      requestAllItems: () => request.get(`/rest/${apiEndpoint}/`),
      saveItem: (payload) => {
        if (!!payload.id) {
          return request.put(`/rest/${apiEndpoint}/${payload.id}`, payload);
        }

        return request.post(`/rest/${apiEndpoint}/`, payload);
      },
      deleteItem: (payload) => request.delete(`/rest/${apiEndpoint}/${payload.id}`),
      register: (payload) => {
        return request.post(`/rest/${apiEndpoint}/${payload.id}/register`);
      },
      ...options.api,
    },
    thunks: ({actions, api, staticData, ...ctx}) => ({
      requestAllItems: throttle<TThunk<TActions['requestAllItems'], Promise<Item[] | void>>>(
        async ({dispatch}) => {
          dispatch(actions.setItemsLoadingState('PENDING'));

          const {result, error} = await api.requestAllItems();

          if (result) {
            dispatch(actions.receiveAllItems(result));
            dispatch(actions.setItemsLoadingState('SUCCESS'));
          }

          if (error) {
            errorNotification(error);
            dispatch(actions.setItemsLoadingState('ERROR'));
          }

          return result;
        },
        100,
        {trailing: false},
      ),
      requestItems: ({dispatch}, {payload}) => {
        dispatch(actions.setItemsLoadingState('PENDING'));
        dispatch(actions.debouncedRequestItems(payload));
      },
      debouncedRequestItems: debounce<TThunk>(async ({dispatch, getState}, {payload}) => {
        const {offset, limit, sort, filters, searchTimestamp} = select(getState());

        const config = {
          offset,
          limit: payload?.limit ?? limit,
          sort: [sort],
          ...filters,
        };

        if (staticData.additionalSort) {
          config.sort.push(staticData.additionalSort);
        }

        const {result, error} = await api.requestItems(config);

        if (result) {
          dispatch(actions.receiveItems({...result, searchTimestamp}));
          dispatch(actions.setItemsLoadingState('SUCCESS'));
        }

        if (error) {
          dispatch(actions.setItemsLoadingState('ERROR'));
        }
      }, 100),
      requestItem: async ({dispatch}, {payload, meta = {}}) => {
        dispatch(actions.updateItemState({item: payload, state: {loading: true}}));

        const {result, error} = await api.requestItem(payload);

        if (result) {
          dispatch(actions.refreshItem(result));

          if (meta.setItemForEdit) {
            dispatch(actions.setItemForEdit(result));
          }
        }

        if (error) {
          errorNotification(error, 'List:errors.failedToLoadItem');
        }

        dispatch(actions.updateItemState({item: payload, state: {loading: false}}));

        return result;
      },
      saveItem: async ({dispatch}, {payload, meta = {}}) => {
        const isCreate = !payload.id;

        if (meta.pristine && !isCreate) {
          showNewNotification({
            messageKey: 'List:notification.pristineEditForm',
          });

          return undefined;
        }

        dispatch(actions.updateItemState({item: payload, state: {saving: true}}));

        const {result, error} = await api.saveItem(payload);

        if (result) {
          showNewNotification({
            type: 'success',
            messageKey: 'List:notification.saveSuccess',
          });

          if (isCreate) {
            if (meta.insertItem) {
              dispatch(actions.insertItem(result));
            }
          } else {
            dispatch(actions.refreshItem(result));
          }

          if (meta.setItemForEdit) {
            dispatch(actions.setItemForEdit(result));
          }
        }

        if (error) {
          errorNotification(error, 'List:errors.failedToSaveItem', meta.errorI18nOptions);
        }

        dispatch(actions.updateItemState({item: payload, state: {saving: false}}));

        return result;
      },
      deleteItem: async ({dispatch}, {payload}) => {
        dispatch(actions.updateItemState({item: payload, state: {deleting: true}}));
        const {result, error} = await api.deleteItem(payload);

        if (result) {
          showNewNotification({
            type: 'success',
            messageKey: 'List:notification.deleteSuccess',
          });

          dispatch(actions.removeItem(payload));
        }

        if (error) {
          errorNotification(error, 'List:errors.failedToDeleteItem');
        }

        dispatch(actions.updateItemState({item: payload, state: {deleting: false}}));
      },
      initializeFilters: ({dispatch}) => {
        if (options.staticData?.filtersLocalStorageKey) {
          const rememberedFilters: string | null = sessionStorage.getItem(
            options.staticData.filtersLocalStorageKey,
          );

          if (rememberedFilters) {
            dispatch(actions.updateFilters(JSON.parse(rememberedFilters)));
          }
        }
      },
      updateFilters: ({dispatch}, {payload, meta = {isSaveSessionStorage: true}}) => {
        if (meta.isSaveSessionStorage && options.staticData?.filtersLocalStorageKey) {
          sessionStorage.setItem(
            options.staticData.filtersLocalStorageKey,
            JSON.stringify(payload),
          );
        }
        dispatch(actions.updateFiltersReducer(payload));
        dispatch(actions.requestItems());
      },
      resetFilters: ({dispatch}, {payload}) => {
        if (options.staticData?.filtersLocalStorageKey) {
          sessionStorage.setItem(options.staticData.filtersLocalStorageKey, '');
        }

        dispatch(actions.resetFiltersReducer(payload));
        dispatch(actions.requestItems());
      },
      toggleSortDirection: ({dispatch}, {payload}) => {
        dispatch(actions.toggleSortDirectionReducer(payload));
        dispatch(actions.requestItems());
      },
      register: async ({dispatch}, {payload, meta = {}}) => {
        dispatch(
          actions.updateItemState({
            item: payload,
            state: {registering: true},
          }),
        );
        const {result, error} = await api.register(payload);

        if (error) {
          errorNotification(error, 'List:errors.failedToRegisterItem');
        }
        dispatch(
          actions.updateItemState({
            item: payload,
            state: {registering: false},
          }),
        );

        if (result && meta.setItemForEdit) {
          dispatch(actions.setItemForEdit(result));
        }

        return result;
      },
      registerItem: async ({dispatch, getState}, {payload, meta = {}}) => {
        // item не был еще сохранен на бэке
        if (!payload.id) {
          await dispatch(actions.saveItem(payload, {setItemForEdit: true}));
          const {activeItem} = select(getState());

          if (activeItem?.id !== undefined) {
            return dispatch(actions.register({id: activeItem.id}));
          }

          return undefined;
        }

        // item был сохранен, но изменился
        if (!meta.pristine) {
          await dispatch(actions.saveItem(payload, {setItemForEdit: true}));
        }

        return dispatch(actions.register({id: payload.id}, {setItemForEdit: meta.setItemForEdit}));
      },
      ...((isFunction(options.thunks)
        ? options.thunks({actions, api, staticData, ...ctx})
        : {}) as TThunks<TActions>),
    }),
  });

  return {
    ...model,
    selectItemState,
    selectCanLoadMore,
    hasContent,
  };
};

export default listApiModel;
