import {ESubjectStatus} from 'src/constants';

export default {
  title: 'Summary report',
  searchPlaceholder: 'Quick search by channel name',
  th: {
    channel: 'Channel',
    status: 'Status',
  },
  status: {
    [ESubjectStatus.APPROVED]: 'Active',
    [ESubjectStatus.REJECTED]: 'Rejected',
    [ESubjectStatus.PENDING]: 'Pending registration',
    [ESubjectStatus.NOT_SENT]: 'Draft',
  },
};
