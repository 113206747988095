export default {
  title: 'Сумма баланса носит справочный характер.',
  more: 'Подробнее',
  modal: {
    title: 'Возможна корректировка баланса',
    text: 'По окончанию месяца итоговая сумма может быть скорректирована ввиду пересчета курса валют по каналам WhatsApp или Viber (в соответствии с договорными условиями)',
  },
  message:
    'Размер списания в выписке за диалоги WhatsApp и сообщения Viber носит справочный характер. В последний день месяца в выписке может быть добавлена корректирующая транзакция ввиду пересчета курса валют по диалогам WhatsApp и сообщениям Viber в соответствии с договорными условиями.',
};
