export default {
  ttl: `через {{hours}}{{minutes}}{{seconds}}
      тем, кто не получил сообщение`,
  whatsAppWarning:
    'На канал <0></0><b>{{name}}</b> поступило много жалоб, есть риск понижения лимитов.',
  whatsAppRestricted: `Рассылка может не уйти! Для канала <0></0><b>{{name}}</b> был исчерпан суточный лимит исходящих сообщений.
    <br/>Некоторые сообщения могут быть не доставлены.`,
  messagingLimit: 'Суточный лимит: <b>{{limit}}</b>{{messages}}',
  whatsAppInfo: `Суточный лимит канала <0></0><b>{{name}}</b> - <b>{{limit}}</b>{{messages}}.
    <br/>Учитывайте это при планировании рассылки`,
  whatsAppInfoMessages: ' сообщений',
  time: {
    seconds_one: 'секунду',
    seconds_few: 'секунды',
    seconds_many: 'секунд',
    minutes_one: 'минуту',
    minutes_few: 'минуты',
    minutes_many: 'минут',
    hours_one: 'час',
    hours_few: 'часа',
    hours_many: 'часов',
  },
};
