import React from 'react';
import {Spring, animated} from 'react-spring';

import isFunction from 'lodash/isFunction';
import {useTheme} from 'styled-components';

import {Icon} from '@edna/components/primitives';
import ArrowBackIcon from '@edna/icons/arrowBack.svg';
import events from '@edna/utils/events';

import {EEvent} from 'src/constants';
import {LogoImage, LogoTextImage} from 'src/images';

import {NoShrinkImage} from '../primitives';
import {CLOSED_MENU_WIDTH} from './constants';
import * as S from './style';

export {CLOSED_MENU_WIDTH};

type TProps = {
  className?: string;
  pinned: boolean;
  hasWhiteBackground?: boolean;
  toggleMenuPinned: TEmptyFunction;
  renderLogo?: (menuOpen: boolean) => React.ReactNode;
  renderTopContent: (menuOpen: boolean) => React.ReactNode;
  renderBottomContent: (menuOpen: boolean) => React.ReactNode;
  hovered?: boolean;
  onHoverChange: (state: boolean) => void;
  asideContent?: React.ReactNode;
  hoveredZIndex?: number;
};

const AnimatedMenu = animated(S.Menu);
const AnimatedAside = animated(S.Aside);

export const useMenuWidth = (pinned: boolean): number => {
  const {dimension} = useTheme();

  return pinned ? dimension.asideNavWidth : CLOSED_MENU_WIDTH;
};

const Menu = React.memo<TProps>(
  ({
    pinned,
    toggleMenuPinned,
    renderTopContent,
    renderBottomContent,
    className,
    hasWhiteBackground,
    renderLogo,
    hovered = false,
    hoveredZIndex,
    onHoverChange,
    asideContent,
  }) => {
    const {zIndex, dimension, animation, spacing} = useTheme();

    const handlePanelToggle = React.useCallback(() => {
      onHoverChange(!hovered);
      toggleMenuPinned();
    }, [onHoverChange, hovered, toggleMenuPinned]);

    const handleMouseEnter = React.useCallback(() => {
      onHoverChange(true);
    }, [onHoverChange]);
    const handleMouseLeave = React.useCallback(() => onHoverChange(false), [onHoverChange]);

    const menuOpen = hovered || pinned;

    React.useEffect(() => {
      events.trigger(EEvent.MENU_INTERACTION);
    }, [menuOpen]);

    const menuWidth = menuOpen ? dimension.asideNavWidth : CLOSED_MENU_WIDTH;
    const paddingRight = menuOpen ? spacing.s4 : spacing.s1;

    const getLogo = () => {
      if (isFunction(renderLogo)) {
        return renderLogo(menuOpen);
      }

      return (
        <S.LogoWrapper isMenuOpen={menuOpen}>
          <Icon as={LogoImage} size="36px" />
          {menuOpen && <NoShrinkImage as={LogoTextImage} />}
        </S.LogoWrapper>
      );
    };

    return (
      <Spring
        from={{
          width: menuWidth,
          zIndex: zIndex.drawer,
          paddingRight,
        }}
        to={
          hovered
            ? [
                {zIndex: hoveredZIndex ?? zIndex.notifications + 1, config: {duration: 0}},
                {width: menuWidth, paddingRight, config: {...animation}},
              ]
            : [
                {width: menuWidth, paddingRight, config: {...animation}},
                {zIndex: zIndex.drawer, config: {duration: 0}},
              ]
        }
      >
        {({paddingRight: paddingRightStyle, ...styles}) => (
          <>
            {asideContent && (
              <AnimatedAside
                style={{
                  left: styles.width.to((value) => `${value}px`),
                  zIndex: styles.zIndex,
                }}
              >
                {asideContent}
              </AnimatedAside>
            )}
            <AnimatedMenu
              className={className}
              style={{...styles, width: styles.width.to((value) => `${value}px`)}}
              open={pinned}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hasWhiteBackground={hasWhiteBackground}
            >
              {getLogo()}
              <S.ScrollContent hasWhiteBackground={hasWhiteBackground}>
                {renderTopContent(menuOpen)}
              </S.ScrollContent>
              <S.Toggler
                as={ArrowBackIcon}
                open={pinned}
                style={{marginRight: paddingRight}}
                onClick={handlePanelToggle}
              />
              {renderBottomContent(menuOpen)}
            </AnimatedMenu>
          </>
        )}
      </Spring>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
