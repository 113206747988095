import {EParameterType} from './definitions';

export default {
  parameter: 'Variable',
  chosenParameters: 'Selected variables',
  chosenParametersHint: 'Enter the values of the variables to be applied in the filter',
  choseParameters: 'Select variables: ',
  parameterValues: 'Variable values',
  findParameter: 'Search variable',
  availableParameters: 'Available variables',
  empty: {
    title: 'This is empty for now',
    nothingFound: 'Nothing found',
  },
  assignDialog: {
    title: 'Assign variable',
  },
  assignForm: {
    name: 'Name of the variable',
    type: 'Type of the variable',
    value: 'Value',
    create: 'Create a variable',
  },
  editForm: {
    title: 'Add a variable',
    name: 'Enter the name for the variable',
    valueType: 'Type of the variable',
  },
  removeDialog: {
    title: 'Delete this variable?',
    text: 'By deleting a variable, we lose a bit of the client information',
  },
  successDialog: {
    title: 'Great, you have added a variable!',
    text: 'It is now even easier to personalize your message for this client',
    action: 'Cool',
  },
  valueType: {
    [EParameterType.STRING]: 'String',
    [EParameterType.BOOLEAN]: 'Boolean value',
    [EParameterType.DATE]: 'Date',
    [EParameterType.NUMBER]: 'Number',
  },
  boolean: {
    true: '{{name}} (True)',
    false: '{{name}} (False)',
  },
};
