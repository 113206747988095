export default {
  channelType: 'Укажите тип канала',
  licenseID: 'Укажите ID лицензии',
  month: 'Номер месяца',
  year: 'Год',
  eventTable: 'license_billed_event_tab (по указанному периоду)',
  notFound: 'Лицензия не найдена',
  search: 'Поиск',
  notification: 'Операция пролонгации лицензии запущена',
  tr: {
    id: 'ID',
    channel: 'Канал',
    price: 'Стоимость',
    currency: 'Валюта',
    paymentType: 'Тип списания',
    periodStartedAt: 'Начало',
    periodEndedAt: 'Окончание',
    transactionId: 'ID транзакции',
    createdAt: 'Создана',
    updatedAt: 'Обновлена',
    documentId: 'ID документа',
    tenantId: 'ID тенанты',
    subjectId: 'ID подписи',
    periodMonths: 'Период продления, мес',
    name: 'Название',
    description: 'Примечание',
    licenseStatus: 'Статус лицензии',
    activatedAt: 'Дата активации',
    expiredAt: 'Лицензия действительна до',
    cancelledAt: 'Отменена',
    activeUntil: 'Активирована с ',
    paidUntil: 'Оплачена до',
    temporaryblockedAt: 'Временно заблокировано c',
    paymentCode: 'Код списания',
  },
};
