import {createNextState} from '@reduxjs/toolkit';
import i18n from 'i18next';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import {showNewNotification} from '@edna/components';
import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';
import getPeriod from '@edna/utils/getPeriod';

import {EChannelType, EStorageKeys, TChannelType} from 'src/constants';
import {TMeta, errorNotification, listApiModel, request} from 'src/models';
import {TListItem, TListItemId} from 'src/models/listApiModel';
import {booleanHashToArray, enumToBooleanHash, isValidUrl} from 'src/utils';

import {showDeniedDeletionModal} from './DeniedDeletionModal';
import {EApproveStatus, EType, FieldForDuplicatesMap} from './constants';
import {
  EAuthFormStep,
  EOperatorFormStep,
  EUserFormStep,
  TExamplesFormData,
  TFormData,
  TFormStep,
  TItem,
  TSteps,
  TSupportedLanguages,
  TValidationResult,
} from './types';
import {getInitialValues} from './utils';

export type {TFormData, TItem};

export type TResultUpload = {
  loaded?: boolean;
  loading?: boolean;
};

export type TState = {
  initialValues: TFormData;
  patternMatchingResult: {match: boolean} | null;
  resultUploadByUrl: TResultUpload | null;
  resultUpload: TResultUpload | null;
  resultCheckForUniqueName: {existing?: boolean; loading?: boolean} | null;
  approveLoading: boolean;
  filters: TFilters;
  itemsMap: Record<TItem['id'], TItem>;
  supportedLanguages: TSupportedLanguages;
  steps: TSteps;
  activeStep: TFormStep | null;
};

export type TCheckPatternMatch = {
  channelType: TChannelType;
  message: string;
  pattern: string;
};

type TRequestItemMeta = TMeta & {
  saveInHash?: boolean;
};

type TActions = {
  // Actions
  receiveChildren: TActionCreator<{id: TItem['id']; children: TItem[]}>;
  setPatternMatchingResult: TActionCreator<TState['patternMatchingResult']>;
  setResultUploadByUrl: TActionCreator<TState['resultUploadByUrl']>;
  setResultUpload: TActionCreator<TState['resultUpload']>;
  setResultCheckForUniqueName: TActionCreator<TState['resultCheckForUniqueName']>;
  setApproveLoading: TActionCreator<TState['approveLoading']>;
  setSupportedLanguages: TActionCreator<TState['supportedLanguages']>;
  receiveItemsMap: TActionCreator<TItem[]>;
  setActiveStep: TActionCreator<TState['activeStep']>;
  setSteps: TActionCreator<TState['steps']>;
  resetSteps: TActionCreator;

  // Thunks
  loadForm: TThunkCreator<{templateId: TListItemId | undefined; channelType: string}>;
  requestItem: TThunkCreator<TListItem, TRequestItemMeta | void, Promise<TItem | void>>;
  moveToArchive: TThunkCreator<TItem>;
  createBasedOn: TThunkCreator<TItem>;
  duplicateItem: TThunkCreator<TItem>;
  registerItem: TThunkCreator<Partial<TItem>, TMeta | void, Promise<TItem | void>>;
  register: TThunkCreator<Partial<TItem>, TMeta | void, Promise<TItem | void>>;
  registerWidthExamples: TThunkCreator<
    TExamplesFormData,
    TMeta | void,
    Promise<TValidationResult | void>
  >;
  requestItemsChildren: TThunkCreator<TItem['id'], void, Promise<TItem[] | void>>;
  saveItem: TThunkCreator<Partial<TItem>, TMeta, Promise<TItem | void>>;
  continueApprovingItem: TThunkCreator<Partial<TItem>, TMeta, Promise<void>>;
  approveItem: TThunkCreator<Partial<TItem>, TMeta, Promise<Partial<TItem> | null | void>>;
  updateLastUsedAt: TThunkCreator<TItem['id']>;
  uploadAttachment: TThunkCreator<
    File,
    void,
    Promise<{result: TFile | null | void; error?: TAnyObject}>
  >;
  uploadAttachmentByUrl: TThunkCreator<
    string,
    void,
    Promise<{result: TFile | null | void; error?: TAnyObject}>
  >;
  checkForUniqueName: TThunkCreator<
    {channelType: TChannelType; name: string},
    void,
    Promise<{result: {existing: boolean} | void}>
  >;
  checkForUniqueWabaNameAndLanguage: TThunkCreator<
    {subjectId: number; language: string; name: string},
    void,
    Promise<{result: {existing: boolean} | void}>
  >;
  checkPatternMatch: TThunkCreator<
    TCheckPatternMatch,
    void,
    Promise<TState['patternMatchingResult'] | undefined>
  >;
  countMessageSegments: TThunkCreator<string, void, Promise<number>>;
  getSupportedLanguages: TThunkCreator<void, void, Promise<void>>;
};

const getItemParameters = (item: TItem) => pick(item, FieldForDuplicatesMap);

const id = 'message-matcher';

export const getOptionsWithItemsMap = (itemsMap: Record<TItem['id'], TItem>, content: TItem[]) => {
  const result = Object.values(itemsMap).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return content.reduce((options, item) => {
    if (itemsMap[item.id]) {
      return options;
    }

    return [...options, {label: item.name, value: item.id}];
  }, result);
};

export type TFilters = {
  period: TPickerValue;
  statuses: Record<EApproveStatus, boolean>;
  channelTypes: Record<TChannelType, boolean>;
  types: Record<EType, boolean>;
  subjectIds: number[];
  exceptCategories: string[];
  categories: string[];
};

type TStaticData = {
  form: {
    notificationChannel: string;
  };
};

export const prepareFilters = ({period, ...payload}: TFilters) => ({
  ...payload,
  timeFrom: period.startDate,
  timeTo: period.stopDate,
  statuses: booleanHashToArray(payload.statuses),
  types: booleanHashToArray(payload.types),
  channelTypes: booleanHashToArray(payload.channelTypes),
});

export const defaultOperatorSteps = {
  [EOperatorFormStep.DETAILS]: {disabled: false, completed: false},
  [EOperatorFormStep.TEXT_CONTENT]: {disabled: true, completed: false},
  [EOperatorFormStep.HEADER_AND_SIGNATURE]: {disabled: true, completed: false},
  [EOperatorFormStep.BUTTONS]: {disabled: true, completed: false},
};

export const defaultUserSteps = {
  [EUserFormStep.DETAILS]: {disabled: false, completed: false},
  [EUserFormStep.USER_CONTENT]: {disabled: true, completed: false},
};

export const defaultAuthSteps = {
  [EAuthFormStep.DETAILS]: {disabled: false, completed: false},
  [EAuthFormStep.AUTH_CONTENT]: {disabled: true, completed: false},
};

const prepareBackendData = (payload: TAnyObject) => {
  if (payload?.content?.header?.text === '') {
    return createNextState(payload, (draft) => {
      draft.content.header.text = null;
    });
  }

  return payload;
};

export default listApiModel<typeof id, TItem, TStaticData, TState, TActions>({
  id,
  staticData: {
    form: {
      notificationChannel: `${id}/form/notificationChannel`,
    },
    filtersLocalStorageKey: EStorageKeys.MESSAGE_MATCHERS_FILTERS,
  },
  defaultState: {
    filters: {
      filter: '',
      period: getPeriod('anytime'),
      statuses: enumToBooleanHash(EApproveStatus),
      channelTypes: enumToBooleanHash(EChannelType),
      types: enumToBooleanHash(EType),
      subjectIds: [],
    },
    resultUploadByUrl: null,
    resultUpload: null,
    resultCheckForUniqueName: null,
    approveLoading: false,
    itemsMap: {},
    patternMatchingResult: null,
    supportedLanguages: {},
    activeStep: EOperatorFormStep.DETAILS,
    steps: defaultOperatorSteps,
  },
  reducers: () => ({
    receiveChildren: (state, {payload}) => ({
      ...state,
      content: state.content.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            children: payload.children,
          };
        }

        return item;
      }),
    }),
    setPatternMatchingResult: (state, {payload}) => ({
      ...state,
      patternMatchingResult: payload,
    }),
    setResultUploadByUrl: (state, {payload}) => ({
      ...state,
      resultUploadByUrl: payload,
    }),
    setResultUpload: (state, {payload}) => ({
      ...state,
      resultUpload: payload,
    }),
    setResultCheckForUniqueName: (state, {payload}) => ({
      ...state,
      resultCheckForUniqueName: payload,
    }),
    setApproveLoading: (state, {payload}) => ({
      ...state,
      approveLoading: payload,
    }),
    receiveItemsMap: (state, {payload}) => ({
      ...state,
      itemsMap: {
        ...state.itemsMap,
        ...keyBy(payload, 'id'),
      },
    }),
    setSupportedLanguages: (state, {payload}) => ({
      ...state,
      supportedLanguages: payload,
    }),
    setActiveStep: (state, {payload}) => ({
      ...state,
      activeStep: payload,
    }),
    setSteps: (state, {payload}) => ({
      ...state,
      steps: payload,
    }),
    resetSteps: (state) => ({
      ...state,
      steps: defaultOperatorSteps,
    }),
  }),
  api: {
    saveItem: (payload) => {
      if (!!payload.id) {
        return request.put(`/rest/message-matcher/${payload.id}`, prepareBackendData(payload));
      }

      return request.post(`/rest/message-matcher/`, prepareBackendData(payload));
    },
    moveToArchive: (payload) =>
      request.delete(`/rest/message-matcher/archive/with-id/${payload.id}`),
    deleteItem: (payload) => request.delete(`/rest/message-matcher/with-id/${payload.id}`),
    registerWidthExamples: (payload) =>
      request.post(
        `/rest/message-matcher/${payload.id}/validate-and-register`,
        omit(payload, 'id'),
      ),
    approveItem: (payload) => request.post(`/rest/${id}/${payload.id}/approve`, payload),
    uploadAttachment: (payload) => request.post(`/rest/${id}/attachment`, payload),
    uploadAttachmentByUrl: (payload) =>
      request.post(`/rest/${id}/attachment/upload`, {externalUrl: payload}),
    requestChildren: (payload: TItem['id']) => request.get(`/rest/${id}/${payload}/children`),
    updateLastUsedAt: (payload: TItem['id']) =>
      request.patch(`/rest/${id}/${payload}/last-used-at`),
    checkPatternMatch: (payload) => request.post(`/rest/${id}/pattern-match`, payload),
    countMessageSegments: (payload) =>
      request.put(`/rest/broadcast/utils/count-message-segments`, {
        text: payload,
      }),
    requestItems: (payload) => request.post(`/rest/${id}/list/`, prepareFilters(payload)),
    checkForUniqueName: (payload) =>
      request.get(`/rest/${id}/exists-by/channel-type/${payload.channelType}/name/${payload.name}`),
    checkForUniqueWabaNameAndLanguage: (payload) =>
      request.post('/rest/message-matcher/exists-by-waba-id-name-language', payload),
    getSupportedLanguages: () => request.get('/rest/message-matcher/supported-languages'),
  },
  thunks: ({actions, api, select}) => ({
    loadForm: ({dispatch, getState}, {payload: {templateId, channelType}}) => {
      const {activeItem} = select(getState());

      if (templateId) {
        dispatch(actions.requestItem({id: templateId}, {setItemForEdit: true}));
      } else if (!activeItem?.name) {
        // если имя есть, тогда это дублирование шаблона, если нет, тогда создание нового
        dispatch(actions.setItemForEdit({channelType}));
      }
    },
    requestItem: async ({dispatch}, {payload, meta = {}}) => {
      dispatch(actions.updateItemState({item: payload, state: {loading: true}}));

      const {result, error} = await api.requestItem(payload);

      if (result) {
        if (meta.saveInHash) {
          dispatch(actions.receiveItemsMap([result]));
        } else {
          dispatch(actions.refreshItem(result));

          if (meta.setItemForEdit) {
            dispatch(actions.setItemForEdit(result));
          }

          if (result.parentId) {
            dispatch(actions.requestItem({id: result.parentId}, {saveInHash: true}));
          }
        }
      }

      if (error) {
        errorNotification(error, 'List:errors.failedToLoadItem');
      }

      dispatch(actions.updateItemState({item: payload, state: {loading: false}}));

      return result;
    },
    approveItem: async ({dispatch}, {payload, meta}) => {
      const isCreate = !payload.id;

      if (meta.pristine && !isCreate && payload.approveStatus === EApproveStatus.APPROVED) {
        showNewNotification({
          messageKey: 'List:notification.pristineEditForm',
        });

        return;
      }

      if (isCreate) {
        const result = await dispatch(
          actions.saveItem(payload, {
            setItemForEdit: meta.setItemForEdit,
            errorI18nOptions: payload.type
              ? {type: i18n.t(`MessageMatchers:type.${payload.type}.title`)}
              : undefined,
          }),
        );

        if (result) {
          return result;
        } else {
          return null;
        }
      }

      return {...payload};
    },
    continueApprovingItem: async ({dispatch}, {payload, meta}) => {
      dispatch(actions.setApproveLoading(true));
      const {result, error} = await api.approveItem(payload);

      if (result) {
        showNewNotification({
          type: 'success',
          messageKey: 'List:notification.saveSuccess',
        });

        dispatch(actions.refreshItem(result));

        if (meta.setItemForEdit) {
          dispatch(actions.setItemForEdit(result));
        }
      }

      if (error) {
        errorNotification(error, 'List:errors.failedToSaveItem');
      }

      dispatch(actions.setApproveLoading(false));
    },
    moveToArchive: async ({dispatch}, {payload}) => {
      const {result, error} = await api.moveToArchive(payload);

      if (result) {
        showNewNotification({
          type: 'success',
          messageKey: 'List:notification.archiveSuccess',
        });
        dispatch(actions.removeItem(payload));
      }

      if (error) {
        if (error.data.title === 'message-matcher-used-in-broadcast') {
          showDeniedDeletionModal(JSON.parse(error.data.detail));

          return;
        }

        errorNotification(error);
      }
    },
    createBasedOn: ({dispatch}, {payload}) => {
      dispatch(
        actions.setItemForEdit({
          ...getItemParameters(payload),
          parentId: payload.id,
          type: EType.USER,
        }),
      );
    },
    duplicateItem: ({dispatch}, {payload}) => {
      dispatch(
        actions.setItemForEdit({
          ...getItemParameters(payload),
          parentId: payload.parentId,
        }),
      );
    },
    requestItemsChildren: async ({dispatch}, {payload}) => {
      dispatch(
        actions.updateItemState({
          item: {id: payload},
          state: {loading: true},
        }),
      );

      const {result, error} = await api.requestChildren(payload);

      if (result) {
        dispatch(actions.receiveChildren({id: payload, children: result}));
      }

      if (error) {
        errorNotification(error, 'List:errors.failedToLoadItem');
      }

      dispatch(
        actions.updateItemState({
          item: {id: payload},
          state: {loading: false},
        }),
      );

      return result;
    },
    updateLastUsedAt: (_, {payload}) => {
      api.updateLastUsedAt(payload);
    },
    uploadAttachment: async ({dispatch}, {payload}) => {
      const formData = new FormData();

      if (payload instanceof File) {
        formData.append('attachment', payload);
      }
      dispatch(actions.setResultUpload({loading: true}));

      const {result, error} = await api.uploadAttachment(formData);

      if (error) {
        errorNotification(error, 'Common:loadingError');
      }
      dispatch(actions.setResultUpload({loaded: false, loading: false}));

      return {result, error};
    },
    checkPatternMatch: async ({dispatch}, {payload}) => {
      const {result, error} = await api.checkPatternMatch(payload);

      if (result) {
        dispatch(actions.setPatternMatchingResult(result));
      }

      if (error) {
        errorNotification(error);
      }

      return result;
    },
    countMessageSegments: async (_, {payload}) => {
      if (!payload) {
        return 0;
      }
      const {result, error} = await api.countMessageSegments(payload);

      if (error) {
        errorNotification(error, 'Errors:segmentCountError');
      }

      return result?.value ?? 0;
    },
    uploadAttachmentByUrl: async ({dispatch}, {payload}) => {
      dispatch(actions.setResultUploadByUrl(null));

      if (!isValidUrl(payload ?? '')) {
        return {result: null};
      }

      dispatch(actions.setResultUploadByUrl({loading: true}));
      const {result, error} = await api.uploadAttachmentByUrl(payload);

      if (result) {
        dispatch(actions.setResultUploadByUrl({loaded: true, loading: false}));
      }

      if (error) {
        errorNotification(error, 'Common:loadingByUrlError');
        dispatch(actions.setResultUploadByUrl({loaded: false, loading: false}));
      }

      return {result, error};
    },
    checkForUniqueName: async ({dispatch}, {payload}) => {
      dispatch(actions.setResultCheckForUniqueName({loading: true}));

      const {result, error} = await api.checkForUniqueName(payload);

      if (result) {
        dispatch(actions.setResultCheckForUniqueName({...result, loading: false}));
      }

      if (error) {
        errorNotification(error);
        dispatch(actions.setResultCheckForUniqueName({loading: false}));
      }

      return {result};
    },
    checkForUniqueWabaNameAndLanguage: async ({dispatch}, {payload}) => {
      dispatch(actions.setResultCheckForUniqueName({loading: true}));

      const {result, error} = await api.checkForUniqueWabaNameAndLanguage(payload);

      if (result) {
        dispatch(actions.setResultCheckForUniqueName({...result, loading: false}));
      }

      if (error) {
        errorNotification(error);
        dispatch(actions.setResultCheckForUniqueName({loading: false}));
      }

      return {result};
    },
    registerWidthExamples: async ({}, {payload}) => {
      const {result, error} = await api.registerWidthExamples(payload);

      if (error) {
        errorNotification(error, 'List:errors.failedToRegisterItem');
      }

      return result;
    },
    getSupportedLanguages: async ({dispatch}) => {
      const {result, error} = await api.getSupportedLanguages();

      if (result) {
        dispatch(actions.setSupportedLanguages(result));
      }

      if (error) {
        errorNotification(error);
      }
    },
  }),
  utils: {
    getInitialValues,
  },
});
