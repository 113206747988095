export default {
  balance: 'Баланс',
  topUp: 'Пополнить',
  bill: 'Сформировать счет',
  stripe: 'Оплатить картой',
  overdraft: 'Овердрафт',
  payTheLicense: 'Для отправки и получения сообщений вам необходимо оплатить лицензию(и)',
  topUpBalance: 'Чтобы начать отправлять сообщения, пополните баланс',
  insufficientAmount:
    'Внимание! На балансе осталась небольшая сумма. Отправка и получение сообщений может быть приостановлена',
};
