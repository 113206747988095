import last from 'lodash/last';

const linkPattern = '(https?://)([\\w-]{1,32}\\.[\\w-]{1,32})[^\\s@]*';
const monospacePattern = '(`)|(`{1,3}.*?)|([^`\\s].*?)|([\\s].*?\\S.*?)';
const simpleMarkupPattern = (value: string) =>
  `([^${value}\\s]([\\S ]*?[^${value}\\s])?)|(${value})`;

enum EMarkup {
  ITALIC = 'italic',
  BOLD = 'bold',
  STRIKE = 'strike',
  MONOSPACE = 'monospace',
  LINK = 'link',
}

const PATTERNS: Record<EMarkup, string> = {
  [EMarkup.ITALIC]: `(?<!(${linkPattern})|([^\\s]))_(?<${EMarkup.ITALIC}>${simpleMarkupPattern(
    '_',
  )})_(?![^\\s])`,
  [EMarkup.BOLD]: `(?<![^\\s])\\*(?<${EMarkup.BOLD}>${simpleMarkupPattern('\\*')})\\*(?![^\\s])`,
  [EMarkup.STRIKE]: `(?<![^\\s])~(?<${EMarkup.STRIKE}>${simpleMarkupPattern('~')})~(?![^\\s])`,
  [EMarkup.MONOSPACE]: `\`\`\`(?<${EMarkup.MONOSPACE}>${monospacePattern})\`\`\``,
  [EMarkup.LINK]: `(?<${EMarkup.LINK}>${linkPattern})`,
};

const MARKDOWN_BOLD_CLASSNAME = 'markdown-bold';

const TAGS: Record<EMarkup, (value: string) => string> = {
  [EMarkup.BOLD]: (t) => `<b class='${MARKDOWN_BOLD_CLASSNAME}'>${t}</b>`,
  [EMarkup.ITALIC]: (t) => `<i>${t}</i>`,
  [EMarkup.STRIKE]: (t) => `<s>${t}</s>`,
  [EMarkup.MONOSPACE]: (t) => `<span style="font-family: monospace">${t}</span>`,
  [EMarkup.LINK]: (t) => `<a href="${t}" target="_blank">${t}</a>`,
};

const simpleMarkup = [EMarkup.ITALIC, EMarkup.BOLD, EMarkup.STRIKE];

const parseMarkdown = (text: string, allowedMarkup?: EMarkup[]): string => {
  const targetPatterns =
    allowedMarkup?.map((markup) => PATTERNS[markup]) ?? Object.values(PATTERNS);

  const regex = new RegExp(targetPatterns.map((p) => `(${p})`).join('|'), 'mgs');

  return text.replace(regex, (...args) =>
    Object.entries(last(args))
      .filter(([, value]) => value)
      .map(([key, value]) => {
        const content = simpleMarkup.includes(key as EMarkup)
          ? parseMarkdown(value as string, simpleMarkup)
          : (value as string);

        return TAGS[key as EMarkup](content);
      })
      .join(''),
  );
};

export {parseMarkdown, EMarkup, TAGS, MARKDOWN_BOLD_CLASSNAME};
