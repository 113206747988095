import {EVerificationMethods} from '../definitions';

export default {
  requestTitle: 'Выберите метод проверки телефона',
  confirmTitle: 'Введите полученный код',
  warning: `Внимание!
Количество попыток запроса кода ограничено.
После 3 попыток запроса кода вы сможете запросить
новый код только спустя 3 часа.
После 5 попыток потребуется обращения в службу поддержки Facebook.`,
  formField: {
    phone: 'Номер телефона, на который будет отправлено {{method}}',
    senderName: 'Отображаемое имя в WhatsApp',
    method: 'Способ верификации',
    methodOption: {
      [EVerificationMethods.SMS]: 'SMS',
      [EVerificationMethods.VOICE]: 'Голосовое сообщение',
    },
    code: 'Код подтверждения',
  },
  phoneNumberVerified: 'Номер телефона подтвержден',
};
