import React from 'react';
import {useTranslation} from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import {ROOT_NODE_ID, getAllChildNodesIds, getOwnChildIds} from 'src/containers/Tags/utils';

import * as S from './style';
import {TNode, TOptionsProps, TState} from './types';

const Options = React.memo<TOptionsProps>(({treeMap, onChange, search, setSearch, toggleOpen}) => {
  const {t} = useTranslation();
  const [selectedNodeId, setSelectedNodeId] =
    React.useState<TState['selectedNodeId']>(ROOT_NODE_ID);
  const hasSearch = !!search;
  const childIds = React.useMemo(
    () => getOwnChildIds(selectedNodeId, treeMap),
    [selectedNodeId, treeMap],
  );
  const allChildNodesIds = React.useMemo(
    () =>
      getAllChildNodesIds(selectedNodeId, treeMap).filter((id) =>
        treeMap[id].name.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, selectedNodeId, treeMap],
  );
  const handleTopOptionClick = React.useCallback(
    (id: string) => {
      if (treeMap[+id].parentId !== undefined) {
        setSelectedNodeId(treeMap[+id].parentId);
        setSearch('');
      }
    },
    [setSearch, treeMap],
  );

  const handleOptionClick = React.useCallback(
    (id: string) => {
      if (treeMap[+id].childIds.length > 0) {
        setSelectedNodeId(+id);
      } else {
        onChange(+id);
        toggleOpen();
        setSelectedNodeId(ROOT_NODE_ID);
      }
      setSearch('');
    },
    [onChange, setSearch, toggleOpen, treeMap],
  );

  const renderOptionLabel = (id: TNode['id']) => {
    if (treeMap[id].childIds.length > 0) {
      return (
        <>
          {treeMap[id].name} <S.TreeArrowIcon />
        </>
      );
    }

    return treeMap[id].name;
  };

  if (isEmpty(treeMap)) {
    return <S.Option disabled label={t('TreeSelect:noData')} />;
  }

  const renderSearchOptions = () => {
    if (isEmpty(allChildNodesIds)) {
      return <S.Option disabled label={t('TreeSelect:nothingFound')} />;
    }

    return allChildNodesIds.map((id) => (
      <S.Option
        key={id}
        onClick={handleOptionClick}
        value={String(id)}
        label={renderOptionLabel(id)}
      />
    ));
  };

  return (
    <>
      {hasSearch && renderSearchOptions()}
      {!hasSearch && (
        <>
          {selectedNodeId !== ROOT_NODE_ID && (
            <S.TopOption
              onClick={handleTopOptionClick}
              value={String(selectedNodeId)}
              label={
                <>
                  <S.TreeArrowBackIcon /> {treeMap[selectedNodeId].name}
                </>
              }
            />
          )}
          {childIds.map((id) => (
            <S.Option
              key={id}
              onClick={handleOptionClick}
              value={String(id)}
              label={renderOptionLabel(id)}
            />
          ))}
        </>
      )}
    </>
  );
});

Options.displayName = 'Options';

export default Options;
