export default {
  filter: 'Фильтр',
  sort: {
    header: 'Сортировать по',
    byChangeAt_asc: 'Дате изменения, сначала давние',
    byChangeAt_desc: 'Дате изменения, сначала недавние',
    byCreatedAt_asc: 'Дате создания, сначала старые',
    byCreatedAt_desc: 'Дате создания, сначала новые',
  },
  errors: {
    failedToLoadList: 'Извините, не удалось загрузить список.',
    failedToLoadItem: 'Извините, не удалось загрузить данные.',
    failedToSaveItem: 'Извините, при сохранении произошла ошибка.',
    failedToRegisterItem: 'Извините, при отправке на регистрацию произошла ошибка.',
    failedToDeleteItem: 'Извините, не удалось удалить элемент.',
  },
  notification: {
    deleteSuccess: 'Удалено успешно',
    archiveSuccess: 'Перенесено в архив',
    saveSuccess: 'Сохранено успешно',
    pristineEditForm: 'Сохранение не требуется, так как вы ничего не изменили',
  },
  totalElements: '{{title}} по критериям - {{count}}',
  noData: 'Здесь пока пусто',
  nothingFound: 'Ничего не найдено',
  errorLoadingList: {
    title: 'Упс :(',
    subtitle: 'Мы не смогли найти то, что вы ищете',
    hint: 'Попробуйте поискать по-другому',
  },
};
