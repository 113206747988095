import styled, {css} from 'styled-components';

import {Button} from '@edna/components';
import {TTheme, mixins} from '@edna/components/styles';

export const ToggleButtons = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
  width: max-content;
  border-radius: ${({theme}) => theme.borderRadius.br2};
`;

type TToggleButtonProps = TTheme & {
  active: boolean;
};

export const ToggleButton = styled(Button)<TToggleButtonProps>`
  ${mixins.flexAlign('center')}
  position: relative;
  border-radius: 0;
  ${({theme, active}: TToggleButtonProps) => css`
    cursor: ${active ? 'default' : 'pointer'};
    &:not(:last-child) {
      border-right: none;
    }
    &:first-child {
      border-top-left-radius: ${theme.borderRadius.br2};
      border-bottom-left-radius: ${theme.borderRadius.br2};
    }
    &:last-child {
      border-top-right-radius: ${theme.borderRadius.br2};
      border-bottom-right-radius: ${theme.borderRadius.br2};
    }
  `}
`;
