import {TSubject} from '../constants';

const prepareOptionsForSelect = (content: any[]) =>
  content.map(({name, id}) => ({
    label: name,
    value: id,
  }));

const prepareSubjectOptionsForSelect = (subjects: TSubject[]) =>
  subjects.map(({subject, id}) => ({
    label: subject,
    value: id,
  }));

export {prepareOptionsForSelect, prepareSubjectOptionsForSelect};
