import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

type TMessageProps = TTheme & {
  noBackground?: boolean;
  isRtlDirection?: boolean;
};

export const Message = styled.div<TMessageProps>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  ${({theme: {palette, branding}, noBackground, isRtlDirection}: TMessageProps) => css`
    background-color: ${noBackground ? 'transparent' : branding.backgrounds.primary};
    direction: ${isRtlDirection ? 'rtl' : 'ltr'};
    &::before {
      content: '';
      ${mixins.position({
        position: 'absolute',
        top: '-5px',
        left: '-5px',
      })}
      transform: rotate(135deg);
      border: 5px solid ${palette.white} ${palette.white} transparent transparent;
      border-bottom-right-radius: 3px;
    }
  `}
`;

export const ContactButton = styled.div`
  ${mixins.flexAlign('center')}
  width: 100%;
  text-align: center;
  ${({theme: {spacing, palette}}) => css`
    padding: ${spacing.s2};
    color: #1aabfc;
    &:first-child {
      border-top: 1px solid ${palette.grey100};
    }
  `}
`;

export const ContactIcon = styled.div`
  ${mixins.wh('16px')}
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s1};
`;

type THeaderBackgroundProps = TTheme & {
  isFullHeight?: boolean;
};

export const HeaderBackground = styled.div<THeaderBackgroundProps>`
  ${mixins.flexAlign('center')}
  background-color: #f3f9fd;
  ${({theme: {spacing, palette, borderRadius}, isFullHeight}: THeaderBackgroundProps) => css`
    height: ${isFullHeight ? 'auto' : '160px'};
    border: ${spacing.s2} solid ${palette.white};
    border-radius: ${borderRadius.br3};
  `}
`;

export const TextBodyHeader = styled.div`
  ${mixins.f3}
  font-weight: 600;
  white-space: pre-wrap;
  ${({theme: {spacing}}) => css`
    padding: ${spacing.s2} 0 0 ${spacing.s2};
  `}
`;

export const ChatButton = styled.div`
  ${mixins.f2}
  width: 100%;
  text-align: center;
  word-break: break-word;
  font-weight: 500;
  ${({theme: {spacing, borderRadius, branding}}) => css`
    margin-top: ${spacing.s1};
    padding: ${spacing.s2};
    color: #1aabfc;
    background-color: ${branding.backgrounds.primary};
    border-radius: ${borderRadius.br2};
  `}
`;

export const Footer = styled.div`
  ${mixins.f2}
  ${({theme}) => css`
    margin-top: ${theme.spacing.s1};
    color: ${theme.branding.content.tertiary};
  `}
`;
