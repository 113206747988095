import styled from 'styled-components';

type TPre = TTheme & {
  isTab?: boolean;
};

export const Pre = styled.pre<TPre>`
  width: ${({isTab}: TPre) => (isTab ? 'fit-content' : '100%')};
`;

export const Code = styled.div`
  ${Pre} {
    margin: 0;
  }
`;
