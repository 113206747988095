import {TValue as TPickerValue} from '@edna/components/PeriodPicker';
import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';

import {TPayload} from 'src/components/List';
import {EChannelType, EOperatorName, TBilledEventState} from 'src/constants';
import {TListItemIdObject} from 'src/models';

export type TCommonDebit = {
  subjectId: number;
  subject: string;
};

export type TCommonAmount = {
  amount: number;
  state: TBilledEventState | null;
};

type TWhatsAppStatement = TCommonDebit & {
  channelAttribute: string;
  exceededMauCount: number;
  amount: number;
  state?: TBilledEventState | null;
  count: number;
};

type TWhatsAppLicense = TCommonDebit & {
  channelAttribute: string;
  mauPackageCount: number;
  periodStarted: string;
  periodEnded: string;
  state: TBilledEventState | null;
};

type TViberLicense = TCommonDebit &
  TCommonAmount & {
    periodEnded: string;
    periodStarted: string;
    period: string;
  };

type TSMSLicense = TCommonDebit & {
  amount: number;
  periodEnded: string;
  periodStarted: string;
  period: string;
};

type TViberSubject = TCommonDebit & {
  channelAttribute: string;
  serviceCount: number;
  serviceAmount: number;
  marketingCount: number;
  marketingAmount: number;
};

type TViberRecurrentPayment = TCommonDebit & {
  channelAttribute: string;
  periodStarted: string;
  periodEnded: string;
  state: TBilledEventState | null;
};

type TSmsSubjects = TCommonDebit & {
  channelAttribute: string;
  serviceCount: number;
  serviceAmount: number;
  marketingCount: number;
  marketingAmount: number;
  multiCount: number;
  multiAmount: number;
};

type TSmsRecurrentPayment = TCommonDebit & {
  channelAttribute: string;
  mobileOperator: EOperatorName;
  periodStarted: string;
  periodEnded: string;
  state: TBilledEventState | null;
};

export type TAutoDebit = Partial<
  TWhatsAppStatement &
    TWhatsAppLicense &
    TViberSubject &
    TViberRecurrentPayment &
    TSmsSubjects &
    TSmsRecurrentPayment &
    TWAConnectionFee
>;

export type TAutoCredit = TCorrectionTransaction;
export type TManualBalance = TCommonAmount & {
  operationDescription: string;
};

export type TCorrectionTransaction = {
  amount: number;
};

export type TWAConnectionFee = TCommonAmount & {
  channelAttribute: string;
  mauCount: number;
  period: string;
};

export type TAlfaPayment = TCommonAmount & {
  paymentPurpose: string;
};

export enum EAutoDebitType {
  WHATSAPP_ABOVE_MAU_MAP = 'whatsappAboveMAUMap',
  WHATSAPP_CONVERSATION = 'whatsappConversation',
  WHATSAPP_LICENSE_INIT = 'whatsappLicenseInit',
  WHATSAPP_LICENSE_RENEWAL = 'whatsappLicenseRenewal',
  WHATSAPP_CONNECTION_FEE_OPERATIONS = 'whatsappConnectionfeeOperations',
  VIBER_SUBJECTS = 'viberSubjects',
  VIBER_RECURRENT_PAYMENT = 'viberRecurrentPayment',
  SMS_SUBJECTS = 'smsSubjects',
  SMS_RECURRENT_PAYMENT = 'smsRecurrentPayment',
  SMS_LICENSE_INIT = 'smsLicenseInit',
  SMS_LICENSE_RENEWAL = 'smsLicenseRenewal',
  VIBER_LICENSE_INIT = 'viberLicenseInit',
  VIBER_LICENSE_RENEWAL = 'viberLicenseRenewal',
  VIBER_CORRECTION_TRANSACTION_REMOVE = 'viberCorrectionTransactionRemove',
  WHATSAPP_CORRECTION_TRANSACTION_REMOVE = 'whatsappCorrectionTransactionRemove',
}

export enum EAutoCreditType {
  VIBER_CORRECTION_TRANSACTION_ADD = 'viberCorrectionTransactionAdd',
  WHATSAPP_CORRECTION_TRANSACTION_ADD = 'whatsappCorrectionTransactionAdd',
}

export const channelByDebitHash = {
  [EAutoDebitType.WHATSAPP_ABOVE_MAU_MAP]: EChannelType.WHATSAPP,
  [EAutoDebitType.WHATSAPP_CONVERSATION]: EChannelType.WHATSAPP,
  [EAutoDebitType.WHATSAPP_LICENSE_INIT]: EChannelType.WHATSAPP,
  [EAutoDebitType.WHATSAPP_LICENSE_RENEWAL]: EChannelType.WHATSAPP,
  [EAutoDebitType.WHATSAPP_CONNECTION_FEE_OPERATIONS]: EChannelType.WHATSAPP,
  [EAutoDebitType.SMS_LICENSE_INIT]: EChannelType.SMS,
  [EAutoDebitType.SMS_LICENSE_RENEWAL]: EChannelType.SMS,
  [EAutoDebitType.VIBER_LICENSE_INIT]: EChannelType.VIBER,
  [EAutoDebitType.VIBER_LICENSE_RENEWAL]: EChannelType.VIBER,
  [EAutoDebitType.VIBER_SUBJECTS]: EChannelType.VIBER,
  [EAutoDebitType.VIBER_RECURRENT_PAYMENT]: EChannelType.VIBER,
  [EAutoDebitType.SMS_SUBJECTS]: EChannelType.SMS,
  [EAutoDebitType.SMS_RECURRENT_PAYMENT]: EChannelType.SMS,
  [EAutoDebitType.VIBER_CORRECTION_TRANSACTION_REMOVE]: EChannelType.VIBER,
  [EAutoDebitType.WHATSAPP_CORRECTION_TRANSACTION_REMOVE]: EChannelType.WHATSAPP,
};

export const channelByCreditHash = {
  [EAutoCreditType.VIBER_CORRECTION_TRANSACTION_ADD]: EChannelType.VIBER,
  [EAutoCreditType.WHATSAPP_CORRECTION_TRANSACTION_ADD]: EChannelType.WHATSAPP,
};

export enum EManualType {
  MANUAL_BALANCE_ADD = 'manualBalanceAdd',
  MANUAL_BALANCE_REMOVE = 'manualBalanceRemove',
  ALFA_PAYMENT = 'alfaPayment',
  INVOICE_OPERATIONS = 'invoiceOperations',
  STRIPE_CHECKOUT_OPERATIONS = 'stripeCheckoutOperations',
}

export enum ETariffType {
  SERVICE = 'SERVICE',
  HSM = 'HSM',
  MULTI = 'MULTI',
}

export enum ETransactionAmountType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  ALL = 'ALL',
}

export const AUTO_DEBIT_TYPES = Object.values(EAutoDebitType);
export const AUTO_CREDIT_TYPES = Object.values(EAutoCreditType);

export type TDebitCredit = {
  credit: number | undefined;
  debit: number | undefined;
};

export type TItem = TListItemIdObject &
  TDebitCredit & {
    date: string;
    login: string;
    [EAutoDebitType.WHATSAPP_ABOVE_MAU_MAP]: TWhatsAppStatement[];
    [EAutoDebitType.WHATSAPP_CONVERSATION]: TWhatsAppStatement[];
    [EAutoDebitType.WHATSAPP_LICENSE_INIT]: TWhatsAppLicense[];
    [EAutoDebitType.WHATSAPP_LICENSE_RENEWAL]: TWhatsAppLicense[];
    [EAutoDebitType.WHATSAPP_CONNECTION_FEE_OPERATIONS]: TWAConnectionFee[];
    [EAutoDebitType.SMS_LICENSE_INIT]: TSMSLicense[];
    [EAutoDebitType.SMS_LICENSE_RENEWAL]: TSMSLicense[];
    [EAutoDebitType.VIBER_LICENSE_INIT]: TViberLicense[];
    [EAutoDebitType.VIBER_LICENSE_RENEWAL]: TViberLicense[];
    [EAutoDebitType.VIBER_SUBJECTS]: TViberSubject[];
    [EAutoDebitType.VIBER_RECURRENT_PAYMENT]: TViberRecurrentPayment[];
    [EAutoDebitType.SMS_SUBJECTS]: TSmsSubjects[];
    [EAutoDebitType.SMS_RECURRENT_PAYMENT]: TSmsRecurrentPayment[];
    [EAutoDebitType.VIBER_CORRECTION_TRANSACTION_REMOVE]: TCorrectionTransaction[];
    [EAutoDebitType.WHATSAPP_CORRECTION_TRANSACTION_REMOVE]: TCorrectionTransaction[];
    [EAutoCreditType.VIBER_CORRECTION_TRANSACTION_ADD]: TCorrectionTransaction[];
    [EAutoCreditType.WHATSAPP_CORRECTION_TRANSACTION_ADD]: TCorrectionTransaction[];
    [EManualType.MANUAL_BALANCE_ADD]: TManualBalance[];
    [EManualType.MANUAL_BALANCE_REMOVE]: TManualBalance[];
    [EManualType.ALFA_PAYMENT]: TAlfaPayment[];
    [EManualType.INVOICE_OPERATIONS]: TCommonAmount[];
    [EManualType.STRIPE_CHECKOUT_OPERATIONS]: TCommonAmount[];
  };

export type TFilters = TPayload & {
  period: TPickerValue;
  tenantId: number;
};

export type TState = TDebitCredit & {
  filters: TFilters;
  transactions: TItem[];
};

export const prepareFilters = (payload: TPayload) => {
  const {period, tenantId, ...other} = payload;

  let transactionAmountType = other.transactionAmountType;

  if (transactionAmountType === ETransactionAmountType.DEBIT) {
    transactionAmountType = ETransactionAmountType.CREDIT;
  } else if (transactionAmountType === ETransactionAmountType.CREDIT) {
    transactionAmountType = ETransactionAmountType.DEBIT;
  }

  const nextPayload: TAnyObject = {
    ...other,
    createdAtFrom: period.startDate,
    createdAtTo: period.stopDate,
    transactionAmountType,
  };

  return nextPayload;
};

export type TActions = {
  // Reducers
  setDebitCredit: TActionCreator<TDebitCredit>;
  downloadList: TThunkCreator<{locale: string}, void, Promise<void>>;
};
