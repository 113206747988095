import styled, {css} from 'styled-components';

import {Switch as BaseSwitch} from '@edna/components/Switch/style';
import {mixins} from '@edna/components/styles';
import {hexToRgba} from '@edna/utils/hexToRgba';

export * from '@edna/components/Switch/style';

type TSwitchProps = TTheme & {
  disabled?: boolean;
  checked?: boolean;
};

export const Switch = styled(BaseSwitch)<TSwitchProps>`
  display: flex;
  align-items: center;
  ${({disabled, checked, theme}: TSwitchProps) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    color: ${checked && !disabled
      ? theme.branding.content.primary
      : theme.branding.colors.disabled};
    transition: color ${theme.animation.duration}ms;
  `}
`;

export const Label = styled.div`
  ${mixins.f1}
  margin-right: ${({theme}: TTheme) => theme.spacing.s1};
`;

const getHandleStyle = ({checked, theme}: TSwitchProps) => {
  const common = css`
    transition: ${theme.animation.duration}ms;
  `;

  if (checked) {
    return css`
      ${common};
      left: 100%;
      transform: translate(-100%, -50%);
      box-shadow:
        0 3px 8px ${hexToRgba(theme.palette.black, '15%')},
        0 1px 1px ${hexToRgba(theme.palette.black, '16%')};
    `;
  }

  return css`
    ${common};
    left: 0;
    transform: translate(0, -50%);
    box-shadow: 0 3px 8px ${hexToRgba(theme.palette.black, '15%')};
  `;
};

export const Control = styled.div<TSwitchProps>`
  position: relative;
  ${mixins.wh('37px', '14px')}
  ${({theme}: TSwitchProps) => css`
    background-color: ${theme.palette.grey100};
    border-radius: ${theme.borderRadius.br2};
    &::after {
      content: '';
      ${mixins.wh('20px')}
      ${mixins.position({position: 'absolute', top: '50%'})}
      background-color: currentColor;
      border-radius: 50%;
      ${getHandleStyle}
    }
  `}
`;
