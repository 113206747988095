import {HIGHLIGHT_ATTRIBUTE} from './definitions';

const renderOnboardingId = (id?: string) =>
  id
    ? {
        [HIGHLIGHT_ATTRIBUTE]: id,
      }
    : {};

export {renderOnboardingId};
