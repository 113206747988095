import styled, {css} from 'styled-components';

import {Button as BaseButton} from '@edna/components';
import {Label as BaseLabel} from '@edna/components/FormField/primitives';
import {mixins} from '@edna/components/styles';

import {Link} from 'src/components/primitives';

export const DeleteIcon = styled.svg`
  ${mixins.wh('20px')}
`;

export const DeleteButton = styled(BaseButton)`
  margin-right: ${({theme}) => theme.spacing.s2};
`;

export const Error = styled.div`
  ${mixins.f2}
  ${({theme}) => css`
    color: ${theme.palette.red400};
    margin-top: ${theme.spacing.s1};
  `};
`;

export const Label = styled(BaseLabel)`
  color: ${({theme}) => theme.branding.content.primary};
`;

export const Note = styled(BaseLabel)`
  margin-bottom: ${({theme}) => theme.spacing.s6};
`;

export const BlockLink = styled(Link)`
  display: block;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
