import {ECorrectionType} from './definitions';

export default {
  storno: 'Сторнировать операцию',
  selectType: 'Выберите тип операции',
  notFound: 'Операция не найдена',
  type: {
    [ECorrectionType.STORNO]: 'Сторнировать операцию',
    [ECorrectionType.CANCEL_STORNO]: 'Отменить сторно',
  },
  enterEventId: 'Укажите ID операции',
  searchResults: 'Результаты поиска',
  companyName: 'Наименование компании',
  paymentType: 'Тип списания',
  licensePayment: 'Списание за лицензию',
  amount: 'Сумма списания',
  currency: 'Валюта списания',
  isSearchCorrectMessage: 'Обязательно убедитесь в корректности запроса по таблицам',
  billedEvent: 'Billed event',
  debtTransaction: 'Debt transaction',
  transaction: 'Transaction',
  id: 'ID',
  originalEventId: 'Original event ID',
  originalEventType: 'Original event Type',
  confirmation: 'Confirmation',
  createdAt: 'Дата',
  eventId: 'Event ID',
  tenantId: 'ID компании',
  reason: 'Reason',
  reasonCode: 'Reason code',
  balanceId: 'Balance ID',
  openingBalance: 'Opening balance',
  state: 'Статус',
  updated: 'Обновлено',
  search: 'Найти',
  comment: 'Комментарий',
  button: {
    [ECorrectionType.STORNO]: 'Сторнировать',
    [ECorrectionType.CANCEL_STORNO]: 'Отменить сторно',
  },
  confirmModal: {
    title: {
      [ECorrectionType.STORNO]: 'Уверены? Мы отменяем списание',
      [ECorrectionType.CANCEL_STORNO]: 'Уверены? Мы отменяем сторно',
    },
    message: 'Если это ошибочная операция, то исправить это будет невозможно',
    subtitle: 'Проверьте данные:',
    button: 'Да, продолжить',
  },
  successModal: {
    title: 'Все хорошо!',
    message: 'Запрос принят к исполнению',
  },
};
