import React from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {EButtonAppearance, LoadingButton, TSize, showNewNotification} from '@edna/components';
import {Icon, Stack} from '@edna/components/primitives';

import {Error, SecondaryText} from 'src/components/primitives';
import {OkIcon, UploadIcon} from 'src/icons';

import * as S from './style';

type TProps = {
  name: string;
  label?: React.ReactNode | string;
  onChange: (value: File) => void;
  accept?: string;
  fileName?: string;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  value?: TFile | null;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  error?: string;
  size?: TSize;
  appearance?: keyof typeof EButtonAppearance;
};

const FilePickerField = React.memo<TProps & {name: string}>(
  ({
    name,
    readOnly,
    disabled,
    value: propValue,
    error,
    onChange,
    className,
    isLoading,
    onClick,
    accept,
    size,
    appearance,
    label,
  }) => {
    const {t} = useTranslation();
    const acceptExtensions = accept?.replace(/ |((?<!\w)\.)/g, '').split(',') ?? [];

    const {
      field,
      fieldState: {error: fieldError},
    } = useController({name});

    const value = propValue ?? field.value;

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      field.onBlur();
      onClick?.(event);
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const {files} = event.target;

      if (!files?.length) {
        return;
      }

      const file = files[0];
      const extension = file.name.split('.').pop() ?? '';

      event.target.value = '';

      if (accept && !acceptExtensions.includes(extension)) {
        showNewNotification({
          type: 'error',
          message: t('FilePicker:invalidFileFormat', {
            formats: acceptExtensions.join(', ').toUpperCase(),
          }),
        });

        return;
      }

      await onChange?.(file);
      field.onBlur();
      event.target.value = '';
    };

    const isFieldInvalid = !disabled && !readOnly && (!!fieldError || !!error);
    const fieldErrorMessage = t(fieldError?.message ?? '', fieldError?.ref?.value);

    const fileLabel = React.useMemo(() => {
      if (label) {
        return label;
      }

      if (value) {
        return t('FilePicker:attachAnotherFile');
      }

      return acceptExtensions.length === 1
        ? t('FilePicker:attachFileWithExtension', {extension: acceptExtensions[0].toUpperCase()})
        : t('FilePicker:attachFile');
    }, [value, label, acceptExtensions]);

    return (
      <Stack size="2" className={className}>
        {value && (
          <S.SuccessUpload>
            <Icon as={OkIcon} size="20px" color="green500" />
            <SecondaryText size="S">{value.originalFileName}</SecondaryText>
          </S.SuccessUpload>
        )}
        <LoadingButton
          as="label"
          // @ts-expect-error prop from as
          htmlFor={name}
          loading={isLoading}
          size={size}
          appearance={appearance}
          disabled={disabled || readOnly}
        >
          <input
            hidden
            id={name}
            type="file"
            accept={accept}
            onChange={handleChange}
            disabled={disabled || isLoading || readOnly}
            onClick={handleClick}
          />
          <Stack size="2" direction="ROW" align="center">
            <Icon as={UploadIcon} size="20px" />
            {fileLabel}
          </Stack>
        </LoadingButton>
        {isFieldInvalid && <Error>{error ?? fieldErrorMessage}</Error>}
      </Stack>
    );
  },
);

export {FilePickerField};
