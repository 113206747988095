import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {
  LoadingButton,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  NewTextInputField,
  showModal,
} from '@edna/components';

import {LeftButton, ModalCenterImage} from 'src/components/primitives';
import {FallImage} from 'src/images';
import {joiResolver} from 'src/utils/validator';

import {
  useAddCallbackUrlMutation,
  useChangeCallbackUrlMutation,
  useDeleteCallbackUrlMutation,
} from '../api';
import {TCallbackUrl, TUrlType} from '../definitions';
import * as S from './style';

type TProps = {
  closeModal: TEmptyFunction;
};

type TAddProps = TProps & {
  type: TUrlType;
};

type TChangeProps = TProps & {
  item: TCallbackUrl;
};

type TFormData = {
  url: TCallbackUrl['url'];
  type: TUrlType;
  id: TCallbackUrl['id'];
};

const resolver = joiResolver((joi, mixed) =>
  joi.object({
    url: mixed.httpsUrl.required(),
  }),
);

const AddCallbackUrlModal = React.memo<TAddProps>(({closeModal, type}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const [addCallbackUrl, {isError, isLoading}] = useAddCallbackUrlMutation();

  const methods = useForm<TFormData>({
    mode: 'all',
    defaultValues: {url: ''},
    resolver,
  });

  const handleConfirm = React.useCallback(
    async (values) => {
      const data = {...values, type};

      try {
        await addCallbackUrl({supportTenantId, data}).unwrap();
        closeModal();
      } catch {}
    },
    [type, closeModal, addCallbackUrl, supportTenantId],
  );

  return (
    <Modal width="545px">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleConfirm)}>
          <ModalHeader>{t(`CompanyCallback:callbackUrlType.${type}.add.title`)}</ModalHeader>
          <ModalContent>
            <S.Note>{t('CompanyCallback:modalNote')}</S.Note>
            <NewTextInputField
              name="url"
              isInvalid={isError}
              label={<S.Label>{t('CompanyCallback:modalText')}</S.Label>}
            />
          </ModalContent>
          {isError && <S.Error>{t('CompanyCallback:errorMessage')}</S.Error>}
          <ModalActions>
            <LeftButton onClick={closeModal}>{t('Common:button.cancel')}</LeftButton>
            <LoadingButton
              appearance="primary"
              type="submit"
              disabled={isLoading}
              data-selector="Common:button.add"
            >
              {t('Common:button.add')}
            </LoadingButton>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
});

AddCallbackUrlModal.displayName = 'AddCallbackUrlModal';

const ChangeCallbackUrlModal = React.memo<TChangeProps>(({closeModal, item: {id, type}}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const [changeCallbackUrl, {isError, isLoading}] = useChangeCallbackUrlMutation();

  const methods = useForm<TFormData>({
    mode: 'all',
    defaultValues: {url: ''},
    resolver,
  });

  const handleConfirm = React.useCallback(
    async (values) => {
      const data = {...values, id, type};

      try {
        await changeCallbackUrl({supportTenantId, data}).unwrap();
        closeModal();
      } catch {}
    },
    [id, type, closeModal, changeCallbackUrl, supportTenantId],
  );

  return (
    <Modal width="550px">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleConfirm)}>
          <ModalHeader>{t(`CompanyCallback:callbackUrlType.${type}.change.title`)}</ModalHeader>
          <ModalContent>
            <S.Note>{t(`CompanyCallback:modalNote`)}</S.Note>
            <NewTextInputField
              name="url"
              isInvalid={isError}
              label={<S.Label>{t(`CompanyCallback:modalText`)}</S.Label>}
            />
          </ModalContent>
          {isError && <S.Error>{t(`CompanyCallback:errorMessage`)}</S.Error>}
          <ModalActions>
            <LeftButton onClick={closeModal} data-selector="Common:button.cancel">
              {t('Common:button.cancel')}
            </LeftButton>
            <LoadingButton
              appearance="primary"
              type="submit"
              disabled={isLoading}
              data-selector="Common:button.add"
            >
              {t('Common:button.add')}
            </LoadingButton>
          </ModalActions>
        </form>
      </FormProvider>
    </Modal>
  );
});

ChangeCallbackUrlModal.displayName = 'ChangeCallbackUrlModal';

const DeleteCallbackUrlModal = React.memo<TChangeProps>(({closeModal, item}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const [deleteCallbackUrl] = useDeleteCallbackUrlMutation();

  const handleConfirm = React.useCallback(async () => {
    try {
      await deleteCallbackUrl({supportTenantId, data: item}).unwrap();
      closeModal();
    } catch {}
  }, [closeModal, item, deleteCallbackUrl, supportTenantId]);

  return (
    <Modal width="430px">
      <ModalHeader>{t(`CompanyCallback:callbackUrlType.${item.type}.delete.title`)}</ModalHeader>
      <ModalCenterImage as={FallImage} />
      <ModalActions>
        <LeftButton onClick={handleConfirm} data-selector="Common:button.yes">
          {t('Common:button.yes')}
        </LeftButton>
        <LoadingButton appearance="primary" onClick={closeModal} data-selector="Common:button.no">
          {t('Common:button.no')}
        </LoadingButton>
      </ModalActions>
    </Modal>
  );
});

DeleteCallbackUrlModal.displayName = 'DeleteCallbackUrlModal';

export const showChangeCallbackUrlModal = (callbackUrlData: TCallbackUrl) => {
  if (callbackUrlData) {
    showModal((closeModal) => (
      <ChangeCallbackUrlModal closeModal={closeModal} item={callbackUrlData} />
    ));
  }
};

export const showDeleteCallbackUrlModal = (callbackUrlData: TCallbackUrl) => {
  if (callbackUrlData) {
    showModal((closeModal) => (
      <DeleteCallbackUrlModal closeModal={closeModal} item={callbackUrlData} />
    ));
  }
};

export const showAddCallbackUrlModal = (type: TUrlType) => {
  showModal((closeModal) => <AddCallbackUrlModal closeModal={closeModal} type={type} />);
};
