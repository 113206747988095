import React from 'react';

import CollapsibleSection from 'src/components/CollapsibleSection';
import {BigText} from 'src/components/primitives';

import * as S from './style';

type TProps = {
  isCollapsible?: boolean;
  isDropdown?: boolean;
  label?: React.ReactNode;
  rightLabel?: React.ReactNode;
  children?: React.ReactNode;
  columnsWidth?: string[];
  dropDownWidth?: string;
  isScroll?: boolean;
};

const ReportTable = React.memo<TProps>(
  ({
    isCollapsible = false,
    isDropdown = false,
    label,
    rightLabel,
    children,
    columnsWidth,
    dropDownWidth,
    isScroll,
  }) => {
    const renderTableContent = () => <S.Table columnsWidth={columnsWidth}>{children}</S.Table>;

    const renderLabel = React.useCallback(() => {
      if (!label) {
        return null;
      }

      return (
        <BigText bottomIndent="0" leftIndent="4" isInline>
          {label}
        </BigText>
      );
    }, [label]);

    if (isDropdown) {
      return <S.DropdownTable width={dropDownWidth}>{renderTableContent()}</S.DropdownTable>;
    }

    return (
      <S.Section isScroll={isScroll}>
        {isCollapsible && (
          <CollapsibleSection
            size="M"
            isOpenDefault
            isBlockLabel
            label={renderLabel()}
            rightLabel={rightLabel}
          >
            {renderTableContent()}
          </CollapsibleSection>
        )}
        {!isCollapsible && (
          <>
            {renderLabel()}
            {renderTableContent()}
          </>
        )}
      </S.Section>
    );
  },
);

ReportTable.displayName = 'ReportTable';

export {ReportTableContent} from './style';
export default ReportTable;
