import styled, {css} from 'styled-components';

import {
  CollapseLabel as BaseCollapseLabel,
  NewTextInputField as BaseNewTextInputField,
  Th as BaseTh,
  NewCheckboxField,
  getTableCellWidth,
} from '@edna/components';
import {Label as BaseLabel} from '@edna/components/FormField/primitives';
import {TTheme, mixins} from '@edna/components/styles';
import BaseArrowBackIcon from '@edna/icons/arrowBack.svg';

import {SecondaryText as BaseSecondaryText} from 'src/components/primitives';
import BasePhonePreview from 'src/containers/PhonePreview';
import {link} from 'src/styles/mixins';

import {COLUMNS_WIDTH} from './constants';

export const Th = styled(BaseTh)`
  ${getTableCellWidth(COLUMNS_WIDTH)}
  font-weight: 500;
`;

export const RightContentPhonePreview = styled(BasePhonePreview).attrs({
  isStickyPosition: true,
})``;

export const Link = styled.span`
  display: inline;
  ${link}
`;

export const IdField = styled(BaseNewTextInputField)`
  max-width: 100px;
  margin-left: ${({theme}) => theme.spacing.s4};
`;

export const Description = styled.div`
  ${mixins.f2}
  line-height: 16px;
  ${({theme}) => css`
    color: ${theme.branding.content.secondary};
    margin-top: ${theme.spacing.s05};
    margin-bottom: ${theme.spacing.s1};
  `};
`;

export const Label = styled(BaseLabel)`
  margin-bottom: ${({theme}) => theme.spacing.s2};
`;

export const Hint = styled.div`
  ${mixins.f2}
  line-height: 16px;
  margin-top: ${({theme}) => theme.spacing.s2};
`;

export const CollapseLabel = styled(BaseCollapseLabel)`
  justify-content: flex-start;
  max-width: max-content;
  ${mixins.f3}
`;

export const NestedBlock = styled.div`
  margin: 0 0 ${({theme: {spacing}}) => `${spacing.s3} ${spacing.s7}`};
`;

export const FieldDescription = styled(BaseSecondaryText)`
  white-space: pre-wrap;
  margin-top: -${({theme}) => theme.spacing.s2};
  ${({size}) =>
    size === 'S'
      ? css`
          ${mixins.f2}
          line-height: 16px;
        `
      : mixins.f3}
`;

FieldDescription.defaultProps = {
  size: 'S',
  leftIndent: '6',
  bottomIndent: '4',
};

export const TypeIcon = styled.span`
  ${mixins.f4}
  font-weight: 600;
  text-align: center;
  ${({theme}) => css`
    margin-right: ${theme.spacing.s2};
    padding: 0 ${theme.spacing.s1};
    border: 2px solid ${theme.branding.content.primary};
  `};
`;

export const ArrowBackIcon = styled(BaseArrowBackIcon)`
  ${mixins.wh('16px')}
`;

export const ArrowNextIcon = styled(BaseArrowBackIcon)`
  ${mixins.wh('16px')}
  transform: rotate(180deg);
  ${({theme}) => css`
    color: ${theme.palette.white};
  `}
`;

export const SubjectBadge = styled.div<
  TTheme & {
    isActive?: boolean;
    noMargin?: boolean;
  }
>`
  ${mixins.f2}
  display: inline-block;
  ${({theme, isActive}) => css`
    margin-right: ${theme.spacing.s1};
    padding: ${theme.spacing.s1};
    border: 1px solid ${isActive ? theme.branding.colors.accentA : theme.palette.grey100};
    border-radius: ${theme.borderRadius.br2};
  `}
`;

export const CheckboxField = styled(NewCheckboxField)`
  margin-top: ${({theme}) => theme.spacing.s4};
`;
