import {EContentType} from 'src/constants';

const getFileExtensionByContentType = (headerType: EContentType) => {
  switch (headerType) {
    case EContentType.DOCUMENT:
      return '.pdf';
    case EContentType.VIDEO:
      return '.mp4, .3gpp';
    case EContentType.IMAGE:
    default:
      return '.png, .jpg';
  }
};

export {getFileExtensionByContentType};
