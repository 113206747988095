import React from 'react';

import styled from 'styled-components';

import {Paper, SkeletonCircle, SkeletonItem} from '@edna/components/primitives';

const ListLoaderContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  padding: 10px 48px;
`;

const ChartLoader = React.memo(() => (
  <>
    <SkeletonItem width="400px" height="16px" style={{marginBottom: '23px'}} />
    <Paper>
      <div
        style={{
          display: 'flex',
          padding: '20px 30px 35px',
          width: '100%',
          height: '330px',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        {[200, 210, 120, 150, 130, 210, 50, 200, 20, 160, 150].map((itemHeight, index) => (
          <SkeletonItem key={index} width="40px" height={`${itemHeight}px`} /> // eslint-disable-line react/no-array-index-key
        ))}
      </div>
    </Paper>
    <SkeletonItem
      width="400px"
      height="18px"
      style={{
        display: 'flex',
        margin: '16px auto 0',
        justifyContent: 'center',
      }}
    />
  </>
));

const WidgetLoader = React.memo<{className?: string; height?: string}>(
  ({className, height = '62px'}) => (
    <>
      <SkeletonItem width="400px" height="16px" style={{marginBottom: '23px'}} />
      <Paper style={{display: 'flex', height, padding: '8px 0'}} className={className}>
        {[1, 2, 3, 4].map((item) => (
          <div
            key={item}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '25%',
              padding: '8px 16px',
            }}
          >
            <SkeletonItem width="80px" height="16px" style={{marginBottom: '16px'}} />
            <SkeletonItem width="150px" height="18px" />
          </div>
        ))}
      </Paper>
    </>
  ),
);

const ListLoader = React.memo(() => (
  <ListLoaderContent>
    {[1, 2, 3].map((item) => (
      <SkeletonCircle key={item} size="32px" style={{margin: '0 40px'}} />
    ))}
  </ListLoaderContent>
));

const TableLoader = React.memo(() => (
  <>
    {[1, 2, 3, 4].map((item) => (
      <div
        key={item}
        style={{
          display: 'flex',
          marginBottom: item === 4 ? 0 : '24px',
        }}
      >
        {[1, 2, 3, 4, 5].map((index) => (
          <SkeletonItem
            key={index}
            width="20%"
            height="24px"
            style={{marginRight: index === 5 ? 0 : '24px'}}
          />
        ))}
      </div>
    ))}
  </>
));

export {ChartLoader, WidgetLoader, ListLoader, TableLoader};
