import {DEFAULT_THEME} from '@edna/components/styles';

export default {
  ...DEFAULT_THEME,
  color: {
    ...DEFAULT_THEME.color,
    brightPurple: '#bc1bc6',
    brightRed: '#e11900',
    lightBlack: '#0d0d0d',
    beige: '#e6dcd4',
  },
  palette: {
    ...DEFAULT_THEME.palette,
    whatsApp: '#128c7e',
    facebook: '#1877f2',
    facebookDark: '#4267b2',
  },
  zIndex: {
    background: -1,
    dropdown: 3,
    tooltip: 2,
    infoDropdown: 6,
    footer: 5,
    subHeader: 1,
    header: 6,
    drawer: 7,
    modal: 8,
    notifications: 9,
  },
};
