import React from 'react';

import {TSize} from '@edna/components';
import {Number} from '@edna/components/primitives';

import * as S from './style';

type TProps = {
  className?: string;
  number: number;
  childrenComponent?: keyof JSX.IntrinsicElements | TAnyComponent;
  children: React.ReactNode;
  numberSize?: TSize;
};

const NumberRow = React.memo<TProps>(
  ({
    className,
    number,
    children,
    childrenComponent: ChildrenComponent = React.Fragment,
    numberSize = 'M',
  }) => (
    <S.Row className={className}>
      <Number size={numberSize}>{number}</Number>
      <ChildrenComponent>{children}</ChildrenComponent>
    </S.Row>
  ),
);

export default NumberRow;
