export default {
  httpStatus: {
    401: 'You are not authorized',
    403: 'Access is denied',
    404: 'The requested resource is not found',
  },
  tag: {
    'already-exists': 'Tag with this name already exists',
    query: {
      validation: {failed: 'Incorrect parameters of groups'},
    },
  },
  'tag-category': {
    'cannot-add-tags': 'Cannot add tags for tag category, category is not leaf',
  },
  'subscribers-upload': {
    'unsupported-format': 'Only Excel format is supported',
    'not-found': 'Subscribers upload task not found by id',
    'parameter-not-found': 'Not found one or more of parameters',
    'no-phone-column-selected': 'There is no any column selected as phone number',
    'multiple-phone-column-selected': 'There are more than one column selected as phone number',
    'column-duplicate': 'There are more than one column with same parameter type',
  },
  'viber-profile': {
    save: {
      'wrong-files': "Logo doesn't fit",
    },
    'file-upload': {
      'logo-is-wrong-size': 'The logo does not fit any of the listed sizes',
    },
    'not-found': 'Viber Channel not found',
    'logo-upload': {
      dimensions: 'Logo dimension not valid',
    },
  },
  'error-unauthorized': 'You are not authorized',
  'error-already-exist': 'An account with this email already exists',
  'sms-profile': {
    'already-exists': 'SMS Channel with this name already exists',
    update: {
      'illegal-status': 'Updating is possible only in "Draft" status',
    },
    'validate-error': {
      'name-not-valid': 'The signature name does not meet the requirements',
    },
  },
  unknownErrorCode: 'Error occurred {{code}}',
  networkError: 'Network error occurred, check your connection',
  unexpectedError: 'Unexpected error occurred',
  segmentCountError: 'Sorry, failed to calculate the number of segments.',
  'error-captcha-challenge': 'Incorrect code from the picture. Please try again',
  'error-bad-credentials': 'Invalid e-mail or password. Please try again',
  'user-not-found': 'Invalid e-mail or password. Please try again',
  'confirmation-send-retry':
    'The maximum number of code submissions is reached. Request confirmation code again',
  'confirmation-send-failed-internal':
    'Failed sending of the confirmation code, please contact technical support',
  'registration-title': 'Sorry, unexpected error occurred',
  'registration-message': 'Please, try again later',
  registration: 'Sorry, unexpected error occurred. Please, try again later',
  'account-unknown': 'No account with such email',
  'confirmation-send-blocked':
    'The maximum number of password recover attempts is reached. Please contact the support: +7 495 287 0112',
  'confirmation-check-failed': 'Invalid confirmation code. Please try again',
  'check-failed-phone': 'The phone number is incorrect',
  'confirmation-send-wait':
    'All attempts to enter the code are exhausted. You can request the code again later',
  'confirmation-not-found': 'No password recovery found',
  'error-system': 'Unknown server error occurred',
  'error-temporary-locked':
    'The maximum number of login attempt is reached. Please try again in {{count}} minute.',
  'error-temporary-locked_plural':
    'The maximum number of login attempt is reached. Please try again in {{count}} minutes.',
  'error-insufficient-data': 'You have requested nonexistent data',
  'error-bad-request': 'The data you have sent is incorrect',
  'account-exist': 'An account with this email already exists',
  opt: {
    incorrect: 'Invalid confirmation code. Please try again',
    'not-found': 'One-time password identifier not found',
    'too-much-attempts': 'The maximum number of code requests exceeded',
  },
  'error-taxNumber-non-updatable': 'Editing TIN is not allowed',
  'error-calendar-group-code-not-valid':
    'Invalid code format. Length from 3 to 100 characters and only latin letters, dashes and numbers',
  sort: {
    property: {
      incorrect: 'Invalid field for sorting',
    },
  },
  json: {
    format: {
      error: 'Sorry, invalid json format',
    },
  },
  'saving-file-error': 'File save error',
  file: {
    read: {
      error: 'Sorry, it is not possible to read the file',
    },
    'unsupported-format': 'This file format is not supported',
    upload: {
      'document-exceeds-size': "Uploaded document's size exceeds maximum allowed size",
    },
    'not-found': 'The requested resource is not found',
  },
  'unknown-broadcast-type-internal': 'Unknown broadcast type',
  'unknown-subject-type-internal': 'Unknown sender type',
  'tariffication-configuration-error': 'Sorry, could not find the destination',
  'invoice-number-generation-failed-internal': 'Failed to get account number',
  'notification-eval-failed-internal': 'Failed to generate text',
  'registration-failed-internal': {
    'reseller-not-found': 'Reseller not found by login',
    'connector-not-found': 'Code connector not found',
  },
  'subject-tariff': {
    'subject-not-found': 'Sorry, could not find the sender',
    'not-found-by-subject-id': 'Sorry, could not find the sender rate',
  },
  'dynamic-tariff': {
    'common-tariff-not-found': 'Sorry, could not find a common fare for the destination',
    'not-found-for-account-id': 'Sorry, could not find a dynamic rate for the selected account',
  },
  'service-package': {
    'not-found-by-account-id': 'Sorry, could not find the service package for the selected account',
    'not-found': 'Sorry, could not find the selected service package',
  },
  broadcast: {
    'not-exists': 'Sorry, could not find the selected newsletter',
    deleted: 'Sorry, the selected newsletter has been deleted',
    'no-valid-sms-messages': 'There are no valid subscriber numbers',
    'unrecognized-error':
      'Unknown error. For an explanation of the cause of the error, contact technical support',
    'incorrect-phone-number': 'Invalid number',
    'subject-not-found': 'Sender name not found',
    'insufficient-funds': 'Not enough money to send a message',
    'text-max-length-exceeded': 'Invalid mailing text length',
    'start-time-expired': 'The mailing start time has expired',
    'confirmation-period-expired': 'Verification expired. You must resend the newsletter',
    'cannot-rate-phone-number': 'Unable to charge phone number',
    'internal-server-error':
      'An internal server error has occurred, please contact technical support',
  },
  user: {
    'not-found': 'Sorry, could not find the selected user',
  },
  'calendars-theme-image': {
    'not-found': 'Sorry, you cannot find the calendar theme image by name',
  },
  'reseller-info': {
    'not-found': 'Sorry, could not find the selected reseller',
  },
  'account-api-key': {
    'not-found': 'The account does not have a single active API key',
  },
  subscriber: {
    'not-found': 'Sorry, could not find the selected subscriber',
    'constraint-violation': {
      address: 'The number has already been added before',
    },
    address: {
      already: {
        exist: 'Sorry, the client with this number already exists',
      },
    },
  },
  'error-password-validation-failed': 'Password did not pass validation',
  'error-incorrect-new-password-confirmation': 'Invalid confirmation of the new password',
  'error-old-new-password-same': 'The new password is the same as the old one',
  message: {
    'unknown-direction': 'Unknown direction',
    direction: {
      'unknown-direction': 'Unknown traffic direction',
    },
    status: {
      details: {
        default: {
          'unrecognized-code':
            'Unknown error. For an explanation of the cause of the error, contact technical support',
        },
        'insufficient-funds': 'There were not enough funds on the account to send a message',
        'absent-subscriber': 'Subscriber unavailable',
        'call-barred':
          "The subscriber is blocked or the subscriber is roaming, and the subscriber's roaming service is not turned on, or the operator who delivers the message does not have a roaming agreement with the roaming operator",
        failure:
          'An error on the network equipment, on the network, or on the subscriber’s phone — a system error during delivery',
        'memory-capacity-exceeded': "The subscriber's phone memory is full",
        'teleservice-not-provisioned':
          "The subscriber is blocked or the subscriber is roaming, and the subscriber's roaming service is not turned on, or the subscriber is not provided with the SMS message delivery service",
        timeout:
          'For the set time, the message was not delivered to the subscriber. Perhaps the subscriber is in roaming, and the equipment of the roaming operator does not respond to the operator through which delivery is made, or there are errors in the network',
        'unknown-subscriber':
          'Unknown caller. This number is not registered in the operator’s network',
        duplicate:
          'Duplicate check worked. In a short time, identical messages are sent to the same number',
        filtered: 'Filtering error',
        unrouted: 'Error connecting to the operator',
        unknown:
          'The operator did not provide a delivery report within the established waiting time for the report (72 hours in advance)',
        rejected: 'Refused by the operator',
        'cancelled-by-user': 'Newsletter canceled by user',
        'declined-by-moderator': 'Rejected by the moderator',
        'broadcast-deadline-expired': 'Reception of newsletter information completed',
        'invalid-subscriber':
          'The number is incorrect. For example, not mobile or not related to any known numbering',
        'equipment-protocol-error':
          'The subscriber’s device does not receive SMS due to a failure in the SIM card or the software of the device. Either a hardware error has occurred in the phone itself',
        'subject-not-allowed':
          'The operator rejected the message due to the fact that the signature of the message is not allowed on its side',
        'template-not-allowed':
          'The operator rejected the message due to the fact that the message text contains forbidden words or a combination thereof',
        'subscriber-barred-during-mnp':
          'After changing the SIM card for 24 hours, the operator blocked SMS messages',
        'timeout-stek':
          'For the set time, the message was not delivered to the subscriber. Perhaps the subscriber is in roaming, and the equipment of the roaming operator does not respond to the operator through which delivery is made, or there are errors in the network',
      },
    },
    subject: {
      'max-pending-exceeded':
        'The maximum number of sender names that we can accept for consideration has been exceeded. Please wait for consideration of previously sent ones .',
      blacklisted: 'The name of the sender is not available for registration',
    },
  },
  validation: {
    password: {
      length: 'Invalid password length',
      'uppercase-chars-required': 'Password must contain uppercase letters',
      'lowercase-chars-required': 'Password must contain lowercase letters',
      'digits-required': 'Password must contain numbers',
      'special-chars-optional': 'Password may contain special characters',
      'repeated-chars-forbidden':
        'The password should not contain sequences of repeated characters, for example: "aaa"',
      'numeric-sequences-forbidden':
        'The password should not contain numerical sequences, for example: "123"',
      'whitespace-forbidden': 'Password must not contain whitespace',
      passay: {
        TOO_SHORT: 'Invalid password length',
        TOO_LONG: 'Invalid password length',
        INSUFFICIENT_UPPERCASE: 'Password must contain uppercase letters',
        INSUFFICIENT_LOWERCASE: 'Password must contain lowercase letters',
        INSUFFICIENT_DIGIT: 'Password must contain numbers',
        ILLEGAL_MATCH:
          'The password should not contain sequences of repeated characters, for example: "aaa"',
        ILLEGAL_NUMBER_RANGE:
          'The password should not contain numerical sequences, for example: "123"',
        ILLEGAL_WHITESPACE: 'Password must not contain whitespace',
      },
    },
    subject: {
      length: 'Invalid signature length',
      'forbidden-characters': 'The sender name contains invalid characters',
      'phone-number-restrict': 'Phone numbers cannot be used for mass mailings',
      duplicate: 'This sender is already on your sender list',
    },
  },
  errors: {
    Size: {
      password: 'Invalid password length',
    },
  },
  account: {
    'not-found': 'Sorry, account not found',
  },
  subject: {
    add: {
      'operator-not-found': 'Sorry, such an operator does not exist',
    },
    'not-found': 'Sorry, sender not found',
    'not-found-by-id': 'Sorry, sender not found',
    register: {
      'illegal-status': 'You can send for registration only the sender in the status of "Draft"',
    },
    update: {
      'illegal-status': 'You can edit only the sender in the status of "Draft"',
    },
  },
  invoice: {
    limitError: 'Sorry, the amount indicated is less than the set limit {{minSum}}',
    'not-found': 'Sorry, account with Id {{invoiceId}} found',
  },
  'subscriber-group': {
    'name-not-unique': 'Sorry, a group with the same name already exists',
    'brute-force':
      'Most of the list contains numbers in order, which indicates an attempt to send mailings by "sorting" the numbers. Mailing on such a list will be rejected',
    'not-found': 'Sorry, the group was not found',
  },
  SubscriberParameter: {
    'already-exists': 'Variable already exists',
  },
  template: {
    'constraint-violation': {
      text: 'This newsletter text template has already been added',
    },
    'text-is-empty': 'Template text cannot be empty',
    'entity-not-found': 'Sorry, the template was not found',
    'subject-do-not-match': 'Sender does not match pattern',
  },
  calendarGroup: {
    'entity-not-found': 'Sorry, the group was not found',
    'constraint-violation': {
      code: 'This group code has already been added before',
    },
    'code-is-empty': 'Group code cannot be empty',
    already: {
      block: 'The group is already locked',
      unblock: 'The group is already unlocked',
    },
  },
  calendar: {
    'constraint-violation': {
      code: 'This calendar code has already been added before',
    },
    'calendarGroup-not-found': 'Sorry, group code not found',
    'entity-not-found': 'Sorry, no calendar found',
    'link-not-found': 'Sorry, no shared link was found for the selected calendar',
    already: {
      block: 'The calendar is already locked',
      unblock: 'The calendar is already unlocked',
    },
  },
  eventCategory: {
    'entity-not-found': 'Sorry, category not found',
    'constraint-violation': {
      code: 'This category code has already been added before',
    },
    'code-is-empty': 'Category code cannot be empty',
    already: {
      block: 'The category is already locked',
      unblock: 'The category is already unlocked',
    },
    has: {
      events: 'Sorry, the category has upcoming events',
    },
  },
  eventCategoryGroup: {
    'entity-not-found': 'Sorry, no category group was found',
    error: {
      'constraint-violation': {
        code: 'This category group code has already been added before',
      },
      'code-is-empty': 'Category group code cannot be empty',
      'name-is-empty': 'The category group name cannot be empty',
      'already-blocked': 'The category group is already locked',
    },
  },
  event: {
    'already-exists': 'Event already exists',
    'calendarId-is-empty': 'Calendar ID cannot be empty',
    'name-is-empty': 'The event name cannot be empty',
    'startDate-is-empty': 'Start date cannot be empty',
    'endDate-is-empty': 'The end date cannot be empty',
    'endDate-before-startDate': 'The end date of the event cannot be earlier than the start date',
    'eventCategory-not-found': 'Sorry, category code not found',
    'calendar-not-found': 'Sorry, calendar id not found',
    'entity-not-found': 'Sorry, no event found',
  },
  calendarClient: {
    'entity-not-found': 'Sorry, no unique link found',
  },
  calendarStatistic: {
    'calendarIds-is-empty': 'Filter by calendar id cannot be empty',
  },
  client: {
    'entity-not-found': 'Sorry, no subscriber found',
  },
  serviceMessageTemplate: {
    'constraint-violation': {
      text: 'This newsletter service message template has already been added before',
    },
    'text-is-empty': 'Service message template text cannot be empty',
    'entity-not-found': 'Sorry, no service message template was found',
    'subject-list-is-empty': 'The list of signatures cannot be empty',
    'operator-list-is-empty': 'The list of operators cannot be empty',
    'subject-not-found': 'Sorry, sender name not found',
    'editing-not-allowed': 'Editing is available only in the "Draft" status',
    registration: {
      'illegal-status':
        'The operation of sending for registration is possible only for templates in the "Draft" status',
    },
    delete: {
      'illegal-status': 'Deletion is possible only for templates in the "Draft" status',
    },
  },
  standaloneEvent: {
    'entity-not-found': 'Sorry, no event found',
    clientLink: {
      'entity-not-found': 'Sorry, a short link for the selected event was not found',
    },
  },
  eventClient: {
    'entity-not-found': 'Unique link to the event was not found',
    'eventId-is-empty': 'The identifier of a one-time event cannot be empty',
  },
  'facebook-manager-id-service': {
    'creation-failed': 'Creation of WhatsApp Channell failed due to Facebook Manager Id',
  },
  'whatsapp-profile': {
    'delete-file': {
      error: 'Error during deleting file',
    },
    delete: {
      'illegal-status': 'Deleting is possible only in "Draft" status',
    },
    'update-failed-due-status': 'Updating is possible only in "Draft" status',
    'upload-logo': {
      error: 'Error during uploading WhatsApp profile logo',
    },
    'sender-name': {
      empty: 'WhatsApp display name cannot be empty',
    },
    description: {
      'max-size': 'Description exceeds maximum length',
    },
    'phone-code': {
      'max-size': 'Code of the country exceeds maximum length',
      empty: 'Code of the country cannot be empty',
    },
    phone: {
      'max-size': 'Phone exceeds maximum length',
      empty: 'Phone cannot be empty',
    },
    address: {
      'max-size': 'Address exceeds maximum length',
      empty: 'Адрес cannot be empty',
    },
    urls: {
      empty: 'Url cannot be empty',
    },
    email: {
      'max-size': 'E-mail exceeds maximum length',
      empty: 'E-mail cannot be empty',
    },
    'logo-url': {
      'max-size': 'Logo url exceeds maximum length',
      empty: 'Logo url cannot be empty',
    },
    'business-kind': {
      'max-size': 'Business kind exceeds maximum length',
      empty: 'Business kind cannot be empty',
    },
    'facebook-page': {
      url: {
        'max-size': 'Facebook page exceeds maximum length',
      },
    },
    'contact-status': {
      'max-size': 'Contact status exceeds maximum length',
    },
    'not-found': 'WhatsApp profile not found',
    'already-exists': 'WhatsApp profile already exists',
    'update-failed-due-amount-of-profiles':
      'You can not update profile having more than 5 profiles in progress',
    'exceeded-max-in-progress':
      'The maximum number of profile {{maxProfilesWithInProgressStatusCount}} that we can accept for consideration has been exceeded. Please wait for consideration of previously sent ones',
    'registrar-error': 'Business Manager ID is wrong',
    'phone-verification': {
      'too-many-requests': 'Too many requests. Please try again later.',
      'unknown-error': 'Phone verification has failed',
      'cert-not-found':
        "This phone number doesn't have a certificate and can't be registered. Get the certificate first.",
      expired: 'This code is expired',
      'verification-code-expired': 'This code is expired',
      used: 'This phone number is already registered in WhatsApp. Remove the number from WhatsApp.',
      'unapproved-account': 'The requested account is unapproved',
      'wrong-country-code':
        "Couldn't validate the country code. Enter country code preceded by the plus sign (+).",
      'wrong-verify-code': 'Incorrect code',
      'validation-error': 'Fill in all required fields',
    },
  },
  'whatsapp-business': {
    'manager-id': {
      invalid: 'Business Manager ID is wrong',
    },
  },
  'whatsapp-registrar-rest-service': {
    EXTERNAL_ERROR: 'Something went wrong',
    WRONG_VERIFY_CODE: "The code didn't pass the WhatsApp check.",
    REQUESTS_MAX_NUMBER_EXCEEDED: 'Maximum number of request to external service was exceeded',
    EXTERNAL_SERVICE_UNAVAILABLE: 'External service unavailable',
    ENTITY_NOT_FOUND: 'Entity not found (for example, an application with such id does not exist)',
    WRONG_APPLICATION_STATUS: 'Wrong application status',
    VALIDATION_ERROR: 'Wrong Facebook Business Manager ID',
    AUTH_ERROR: 'Auth error',
    JSON_ERROR: 'Json structure error',
    INTERNAL_ERROR: 'Internal error',
    'check-facebook-manager-id-failed': 'Business Manager ID is wrong',
    'request-phone-verification-failed': 'Request phone verification failed',
    'confirm-phone-verification-failed': 'Confirm phone verification failed',
  },
  'instagram-profile': {
    'not-found': 'Instagram profile not found',
    'already-exists': 'Instagram profile with specified instagramAccountId already exists',
    'registrar-error': 'Registrar error Instagram',
    'send-profile.registrar-error': 'Sending profile to Instagram registrar has failed',
  },
  'account-test-address': {
    'not-found': 'Sorry, this phone was not found',
    'max-count-reached': 'You have created the maximum number of test phone numbers.',
    address: {
      'not-unique': 'Such a test phone already exists.',
      empty: 'Sorry, you did not provide a test phone',
    },
  },
  hsmTemplate: {
    'constraint-violation': {
      template: 'Sorry, you are trying to save a non-unique template.',
    },
    'entity-not-found': 'Sorry, the template was not found.',
    'editing-not-allowed': 'Sorry, only templates in the "Draft" status can be editing',
    'subject-not-found': 'Sorry, the subject was not found.',
    registration: {
      'illegal-status': 'Sorry, only templates in the "Draft" status can be registered',
    },
    delete: {
      'illegal-status': 'Sorry, only templates in the "Draft" status can be deleting',
    },
  },
  'change-password': {
    'incorrect-old-password': 'Old password is incorrect',
    'old-new-password-same': 'Old and new passwords are same',
    'new-password-validation-failed': "New password doesn't meet required constraints",
  },
  dlvStatus: {
    'session-not-started': 'The message does not match the registered template',
    'message-undeliverable': 'Unable deliver this message to the recipient',
    'spam-rate-limit-hit':
      'Unable to deliver this message because many previous messages were blocked or flagged as spam',
    ok: 'The outgoing message has been successfully accepted for sending',
    'error-system': 'System error',
    'error-instant-message-client-idnotunique': 'The client ID is not unique',
    'error-subject-format': 'Invalid message signature format',
    'error-subject-unknown': 'The message signature is not allowed for this client',
    'error-subject-not-specified': "A message signature hasn't been specified",
    'error-address-format': 'Invalid client phone or ID format',
    'error-address-unknown': 'Unknown client phone or ID',
    'error-address-not-specified': "A client phone or ID hasn't been specifiel",
    'error-priority-format': 'Invalid priority format',
    'error-comment-format': 'Invalid comment format',
    'error-instant-message-type-format': 'Invalid message type format',
    'error-instant-message-type-notspecified': 'A message type hasn’t been specified',
    'error-caption-too-long': 'The button name is too long',
    'error-content-type-not-specified': 'The content type hasn’t been specified',
    'error-content-type-format': 'Invalid content type format',
    'error-content-format': 'Invalid content format',
    'error-content-not-specified': 'Content hasn’t been specified',
    'error-content-image-id-or-imageurlnotspecified': 'The image ID or URL hasn’t been specified',
    'error-validity-period-seconds-format': 'Invalisubscriberd TTL format',
    'error-validity-period-seconds-too-large': 'The TTL value is too large',
    'error-sms-subject-format': 'Invalid SMS signature format',
    'error-sms-subject-unknown': 'Unknown SMS signature',
    'error-sms-priority-format': 'Invalid SMS priority format',
    'error-sms-content-not-specified': 'SMS content hasn’t been specified',
    'error-sms-comment-format': 'Invalid SMS comment format',
    'error-sms-not-permitted': 'SMS sending isn’t allowed',
    'error-content-document-url-not-specified': "The attachment URL hasn't been specified",
    'error-content-image-name-not-specified': "The attachment name hasn't been specified",
    'error-content-document-name-not-specified': "The attachment name hasn't been specified",
    'error-content-document-size-not-specified': "The attachment size hasn't been specified",
    'error-content-text-format': 'Invalid content text format',
    'error-content-latitude-not-specified': "The latitude hasn't been specified",
    'error-content-longitude-not-specified': "The longitude hasn't been specified",
    'error-content-location-address-not-specified': "The location address hasn't been specified",
    'message-limit-exceeded':
      "This message can't be sent because you've reached your daily limit. Please try again later",
    'not-template-match': 'The message does not match the registered template',
    'not-whatsapp-user': 'The user is not using Whatsapp',
    'error-destination-not-allowed':
      'The destination of the message has not been enabled. Please contact the support team',
    'error-phone-not-for-testing':
      'The message was sent from a test subject to a phone number that is not in the list of the test phone numbers',
    'not-viber-user': 'The user does not have the Viber app installed',
    'absent-subscriber': 'The subscriber is unavailable.',
    'call-bared':
      'The subscriber is blocked, or the subscriber is in roaming, and the roaming service is not enabled. Alternatively, the subscriber is in roaming, but the operator that delivers the message does not have a roaming agreement with the roaming operator.',
    'call-barred':
      'The subscriber is blocked, or the subscriber is in roaming, and the roaming service is not enabled. Alternatively, the subscriber is in roaming, but the operator that delivers the message does not have a roaming agreement with the roaming operator.',
    'error-out-message-client-id-not-unique':
      'A sending error occurred, please contact the support team.',
    failure:
      'There was an error in the network equipment, or within the network, or in the phone - i.e., a system error occurred during delivery. This general “failure” code includes a set of errors related to failures of the phone set, subscribers` SIM cards, operator network equipment, and so on. ',
    'memory-capacity-exceeded': 'The phone`s memory is full.',
    'subject-not-allowed':
      'The operator rejected the message as the message signature is not allowed on their side.',
    'subscriber-barred-during-mnp':
      'The operator has blocked the SMS traffic for the subscriber for 24 hours after changing the SIM card.',
    'teleservice-not-provisioned':
      'The subscriber is blocked, or the subscriber is in roaming, and the roaming service is not enabled. Alternatively, the subscriber is not provided with the SMS message delivery service.',
    'unknown-subscriber':
      'Unknown caller. This number is not registered within the operator`s network.',
    unrouted: 'A sending error occurred, please contact the support team.',
  },
  'push-style': {
    saving: {
      'already-exists':
        'A channel with the same name already exists. Please enter a different channel name',
    },
    'not-exists': 'Sorry, could not find the channel',
  },
  'push-app': {
    ios: {
      'certificate-subject-validation-error':
        'The Bundle ID from the certificate does not match the one specified in step 1.3.',
      'certificate-processing-error': 'The certificate is incorrect.',
      'certificate-expired': 'The certificate has expired.',
      'has-not-certificate': 'There is no certificate in the uploaded p12 file.',
      'password-incorrect': 'The password for file p12 is incorrect.',
      'password-contain-not-ascii':
        'The password contains non-ASCII characters and cannot be applied.',
      'app-already-exist':
        'You are trying to add an app that already exists in one of your Push Channels.',
    },
    android: {
      'invalid-service-json':
        "The JSON file doesn't contain all the required attributes. Upload another file",
    },
  },
  balance: {
    'not-found': 'Sorry, could not find the balance',
  },
  tenant: {
    'test-phone': {
      create: {
        failed: {
          error: 'This phone number cannot be used for testing purposes.',
          'limit-reached':
            'There cannot be more than {{maxCount}} phone numbers for testing purposes.',
        },
      },
    },
  },
  'error-phone-not-for-testing':
    'Not delivered as the phone number is not in the list of the test numbers',
  connection: {
    error:
      'Unexpected error during trying to connecting the URL. The URL is incorrect or if you are sure that is not true, please try later.',
  },
  'message-matcher-name-already-exists': 'The template with this name already exists.',
  'message-matcher': {
    saving: {
      'bad-request': 'The template with this name already exists.',
      'already-exists': '{{type}} template with the same content already exists.',
    },
    'name-exists': 'The template with this name already exists. Cannot be saved.',
  },
  callback: {
    url: {
      'not-available-error': 'Callback URL is not available',
    },
    jivo: 'No active whatsapp subjects',
  },
  'request-id-not-unique': 'RequestId is not unique',
  'upload-document': {
    'already-exists': 'A document with this name and checksum already exists',
  },
  'tenant-lead.in-accepted-status': 'Link has already been activated',
  'tenant-offer': {
    'not-found': 'The offer was not found',
  },
  violations: {
    tariffTemplateId:
      'No pricing is specified for the user account. Please contact the support team',
  },
};
