export default {
  password: 'Change password',
  contacts: 'Contact details',
  language: 'Language',
  chooseLanguage: 'Choose language',
  editForm: {
    name: 'Contact person name',
    email: 'E-mail',
    phone: 'Phone',
  },
  notification: {
    changePasswordSuccess: 'Your password has been successfully changed',
  },
  timezone: 'Timezone',
  timezoneLabel: 'Timezone for sending messages',
};
