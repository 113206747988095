import styled, {css} from 'styled-components';

import {
  Button as BaseButton,
  Td as BaseTd,
  NewTextareaField as BaseTextareaField,
  Tr as BaseTr,
} from '@edna/components';
import {Row as BaseRow} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';

import {Chip as BaseChip, Code as BaseCode, Tab as BaseTab} from 'src/components';

export const Container = styled.div`
  ${mixins.column}
  ${({theme}) => css`
    margin: ${theme.spacing.s2} 0 0 ${theme.spacing.s11};
    padding: ${theme.spacing.s6};
    background-color: ${theme.branding.backgrounds.secondary};
  `}
`;

export const ChipContainer = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
`;

export const Chip = styled(BaseChip)`
  ${({theme}) => css`
    margin-right: ${theme.spacing.s2};
    border: 2px solid ${theme.branding.colors.accentA};
    border-radius: ${theme.borderRadius.br1};
    color: ${theme.branding.colors.accentA};
    font-weight: bold;
  `}
`;

export const Tabs = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
  position: relative;
  ${({theme}) => css`
    margin: ${theme.spacing.s4} 0;
  `}
`;

export const Tab = styled(BaseTab)`
  ${({active, theme}) => css`
    font-weight: ${active ? 'bold' : 'normal'};
    color: ${active ? theme.branding.content.primary : theme.branding.content.tertiary};
    padding: ${theme.spacing.s2};
    &::after {
      border-bottom: 2px solid ${active ? theme.branding.content.primary : 'transparent'};
    }
  `};
`;

export const ConsoleHeader = styled.div`
  ${mixins.flexAlign('space-between', 'flex-end')}
`;

export const Button = styled(BaseButton)`
  box-shadow: none;
  border: none;
  ${({theme}) => css`
    color: ${theme.branding.content.tertiary};
    background-color: ${theme.branding.backgrounds.secondary};
  `}
`;

export const CallButton = styled(BaseButton)`
  ${({theme: {spacing, palette, branding}}) => css`
    align-self: end;
    margin-top: ${spacing.s3};
    background-color: ${branding.colors.accentA};
    color: ${palette.white};
    border: none;
    box-shadow: none;
    border-radius: 0;

    &:hover {
      color: ${palette.white};
    }
  `}
`;

export const TextareaField = styled(BaseTextareaField)`
  ${({theme}) => css`
    & > textarea {
      background-color: ${theme.branding.backgrounds.primary};
      height: 300px;
      border: none;
    }
  `}
`;
export const Tr = styled(BaseTr)`
  ${({theme}) => css`
    background-color: ${theme.branding.backgrounds.primary};
    border-radius: ${theme.borderRadius.br1};
  `}
`;

export const Td = styled(BaseTd)`
  ${({theme}) => css`
    width: 100%;
    color: ${theme.branding.content.tertiary};
    font-weight: 500;
    padding-left: ${theme.spacing.s10};
  `}
`;

export const Text = styled.div`
  ${mixins.f4}
`;

type TCodeChip = TTheme & {
  isError?: boolean;
};

export const CodeChip = styled(Chip)<TCodeChip>`
  ${({theme, isError}: TCodeChip) => css`
    border: 2px solid ${isError ? theme.branding.colors.error : theme.palette.green400};
    color: ${isError ? theme.branding.colors.error : theme.palette.green400};
  `}
`;

export const ResponseLabel = styled(Text)`
  ${({theme}) => css`
    color: ${theme.branding.content.tertiary};
    margin: ${theme.spacing.s4};
  `}
`;

export const Row = styled(BaseRow)`
  ${mixins.flexAlign('flex-start', 'center')}
`;

export const Code = styled(BaseCode)`
  max-height: 400px;
  overflow: auto;
`;

export const Form = styled.form`
  ${mixins.column}
`;
