import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'src/components/primitives';
import {TIME_UNITS, TTimeUnit} from 'src/constants';

import * as S from './style';

type TTimes = Partial<Record<TTimeUnit, string>>;

type TProps = {
  className?: string;
  fieldPrefix: string;
  disabled?: boolean;
  readOnly?: boolean;
  times?: TTimes;
};

const TimeUnitFields = React.memo<TProps>(
  ({className, fieldPrefix, disabled, readOnly, times = {} as TTimes}) => {
    const {t} = useTranslation();

    return (
      <S.TimeUnitFields className={className}>
        {TIME_UNITS.map((time) => (
          <S.Container key={time}>
            <S.NumberInputField
              disabled={disabled}
              readOnly={readOnly}
              noMargin
              key={time}
              showError={false}
              name={`${fieldPrefix}.${time}`}
              maxLength={2}
            />
            <Text>
              {t(`Common:time.${time}`, {
                count: Number(times[time] ?? 0),
              })}
            </Text>
          </S.Container>
        ))}
      </S.TimeUnitFields>
    );
  },
);

export default TimeUnitFields;

const getValidTime = (time: string | number = '') => {
  const stringTime = String(time);

  return stringTime?.length > 1 ? time : `0${time}`;
};

export const prepareTimeUnit = (times?: TTimes) => {
  if (times) {
    return `${getValidTime(times.hours)}:${getValidTime(times.minutes)}:${getValidTime(
      times.seconds,
    )}`;
  }

  return '';
};
