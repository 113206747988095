export default {
  title: 'Клиенты компании',
  searchPlaceholder: 'Быстрый поиск по названию, ИНН и логину ',
  th: {
    createdAt: 'Дата создания',
    company: 'Компания',
    taxNumber: 'ИНН',
    login: 'Логин',
    status: 'Статус',
  },
  queryError: {
    title: 'Упс :(',
    subtitle: 'Из-за спецсимволов в поиске мы не смогли найти вашего клиента',
    hint: 'Попробуйте поискать без них',
  },
  filters: {
    title: 'Фильтры',
    createdAtLabel: 'Дата регистрации',
    managerLabel: 'Ответственный менеджер',
    managerPlaceholder: 'Выберите менеджера',
    resellerLabel: 'Юрисдикция',
    resellerPlaceholder: 'Выберите юрлицо',
    supportClients: 'Клиентов',
  },
};
