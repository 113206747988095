import {ERegExpType, TRegexp} from 'src/components/EditorWithSelector/definitions';
import {EOperatorName} from 'src/constants';

const allowedRegexps: TRegexp[] = [
  {value: '%w', type: ERegExpType.STATIC},
  {value: '%d', type: ERegExpType.STATIC},
  {
    type: ERegExpType.LIMITED,
    value: '%d{1,n}',
    create: (n) => `%d{1,${n}}`,
    match: (value) => value.match(/^%d{1,\d+}/)?.[0] ?? '',
    matchLimit: (value) => {
      const limit = value.match(/%d{1,(?<limit>\d+)}/)?.groups?.limit;

      return limit ? Number(limit) : -1;
    },
  },
  {
    type: ERegExpType.LIMITED,
    value: '%w{1,n}',
    create: (n) => `%w{1,${n}}`,
    match: (value) => value.match(/^%w{1,\d+}/)?.[0] ?? '',
    matchLimit: (value) => {
      const limit = value.match(/%w{1,(?<limit>\d+)}/)?.groups?.limit;

      return limit ? Number(limit) : -1;
    },
  },
];

enum EExampleType {
  REGEX = 'REGEX',
  VARIABLE = 'VARIABLE',
}

type TExampleType = keyof typeof EExampleType;

enum ESmsStep {
  DATA = 'DATA',
  CONTENT = 'CONTENT',
}

enum ESmsCategory {
  SERVICE = 'SERVICE',
  TEMPLATE_ADV = 'TEMPLATE_ADV',
  AUTHORISATION = 'AUTHORIZATION',
  ADVERTISEMENT = 'ADVERTISEMENT',
}

type TCategoryOperator = {
  providerCode: EOperatorName;
};

export {ESmsStep, allowedRegexps, TExampleType, EExampleType, ESmsCategory, TCategoryOperator};
