import React from 'react';

import {Spinner} from '@edna/components';

import {FieldFooter} from '../primitives';
import {EFooterStatus} from './definitions';
import * as S from './style';

type TProps = {
  isSuccess?: boolean;
  loading?: boolean;
  successLabel?: string;
  warningLabel?: string;
  errorLabel?: string;
  noWrapper?: boolean;
  status: EFooterStatus;
};

const FooterStatus = React.memo<TProps>(
  ({status, warningLabel = '', successLabel = '', errorLabel, noWrapper}) => {
    const content = React.useMemo(() => {
      switch (status) {
        case EFooterStatus.LOADING:
          return <Spinner size="16px" />;
        case EFooterStatus.ERROR:
          return (
            <>
              <S.StatusIcon name="error" />
              <S.CheckResult status={status}>{errorLabel}</S.CheckResult>
            </>
          );
        case EFooterStatus.SUCCESS:
          return (
            <>
              <S.StatusIcon name="ok" />
              <S.CheckResult status={status}>{successLabel}</S.CheckResult>
            </>
          );
        case EFooterStatus.WARNING:
          return (
            <>
              <S.StatusIcon name="warning" />
              <S.CheckResult status={status}>{warningLabel}</S.CheckResult>
            </>
          );
      }
    }, [status]);

    if (noWrapper) {
      return content;
    }

    return <FieldFooter justifyContent="flex-end">{content}</FieldFooter>;
  },
);

FooterStatus.displayName = 'FooterStatus';

export default FooterStatus;
