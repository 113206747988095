import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export type TState = {
  facebookManagerData: {
    valid: boolean;
    id?: string | number;
  };
};

const initialState: TState = {
  facebookManagerData: {
    valid: false,
    id: '',
  },
};

const slice = createSlice({
  name: 'whatsAppChannel',
  initialState,
  reducers: {
    setManagerData(state, action: PayloadAction<TState['facebookManagerData']>) {
      state.facebookManagerData = action.payload;
    },
    resetFacebookManagerId(state) {
      state.facebookManagerData = initialState.facebookManagerData;
    },
  },
});

export const {setManagerData} = slice.actions;

export const selectFacebookManagerDataId = (state: TRootState) =>
  state.whatsAppChannel.facebookManagerData.id;
export const selectIsFacebookManagerDataValid = (state: TRootState) =>
  state.whatsAppChannel.facebookManagerData.valid;

export default slice.reducer;
