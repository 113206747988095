import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

import {ETemplateButtonColor} from 'src/containers/MessageMatchers/types';
import {
  ExternalLinkIcon as BaseExternalLinkIcon,
  LocationIcon as BaseLocationIcon,
} from 'src/icons';

export const Message = styled.div`
  width: 100%;
  border-radius: 5px;
  ${({theme: {palette, branding}}) => css`
    background-color: ${branding.backgrounds.primary};
    &::before {
      content: '';
      ${mixins.position({
        position: 'absolute',
        top: '-5px',
        left: '-5px',
      })}
      transform: rotate(135deg);
      border: 5px solid ${palette.white} ${palette.white} transparent transparent;
      border-bottom-right-radius: 3px;
    }
  `}
`;

export const Gallery = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  ${({theme: {spacing, borderRadius}}) => css`
    gap: ${spacing.s05};
    margin-top: ${spacing.s2};
    border-radius: ${borderRadius.br1};
  `};

  &::after {
    ${mixins.f2}
    position: absolute;
    content: '12:00';
    opacity: 0.8;
    ${({theme: {spacing, branding}}: TTheme) => css`
      right: ${spacing.s1};
      bottom: ${spacing.s1};
      color: ${branding.content.tertiary};
      background: ${branding.content.primary};
      padding: ${spacing.s1} ${spacing.s2};
      border-radius: ${spacing.s2};
    `}
  }
`;

type TImageProps = TTheme & {
  bigFirst?: boolean;
};

export const Image = styled.img<TImageProps>`
  ${mixins.wh('100%')};
  display: grid;
  object-fit: cover;

  ${({theme, bigFirst}: TImageProps) => css`
    grid-column-end: ${bigFirst ? 'span 1' : 'span 2'};
    height: ${bigFirst ? '58px' : '117px'};
    border-radius: ${theme.borderRadius.br2};
  `};

  &:first-child {
    grid-column-start: 1;
    ${({bigFirst}: TImageProps) =>
      bigFirst &&
      css`
        grid-column-end: 5;
        height: 238px;
      `}
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  min-height: 20px;
`;

const buttonColor: Record<ETemplateButtonColor, string> = {
  [ETemplateButtonColor.POSITIVE]: '#4BB34B',
  [ETemplateButtonColor.NEGATIVE]: '#E64646',
  [ETemplateButtonColor.PRIMARY]: '#5181B8',
  [ETemplateButtonColor.SECONDARY]: '#E5EBF1',
};

type TButtonProps = TTheme & {
  color: ETemplateButtonColor;
};

export const Button = styled.button<TButtonProps>`
  ${mixins.flexAlign('center')}
  ${mixins.f2};
  font-weight: 500;
  display: flex;
  width: 100%;
  height: 30px;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;

  ${({color, theme}: TButtonProps) => css`
    gap: ${theme.spacing.s1};
    background-color: ${buttonColor[color]};
    border: ${theme.dimension.borderWidth}px solid ${buttonColor[color]};
    border-radius: ${theme.spacing.s1};
    color: ${color === ETemplateButtonColor.SECONDARY
      ? theme.branding.content.primary
      : theme.palette.white};
    transition: all ${theme.animation.duration}ms;
  `};
`;

export const LocationIcon = styled(BaseLocationIcon)`
  ${mixins.wh('16px')}
`;

export const ExternalLinkIcon = styled(BaseExternalLinkIcon)`
  ${mixins.wh('16px')}
`;
