import styled, {css} from 'styled-components';

import {TSize} from '@edna/components';
import {
  CollapseLabel as BaseCollapseLabel,
  StatefulCollapse as BaseStatefulCollapse,
} from '@edna/components/Collapse';
import {Column} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';
import BaseKeyboardArrowDownIcon from '@edna/icons/keyboardArrowDown.svg';

type TStatefulCollapseProps = {
  size: TSize;
};

export const StatefulCollapse = styled(BaseStatefulCollapse)<TStatefulCollapseProps>`
  ${({theme: {spacing, palette}, size}) => css`
    padding-bottom: ${size === 'L' ? spacing.s4 : spacing.s2};
    &:not(:last-child) {
      margin-bottom: ${spacing.s4};
      border-bottom: 1px solid ${palette.grey100};
    }
  `};
`;

export const CenterCollapseLabel = styled(BaseCollapseLabel)`
  ${mixins.flexAlign('normal', 'center')}
`;

export const BlockCollapseLabel = styled(BaseCollapseLabel)`
  display: block;
`;

type TKeyboardArrowDownIconProps = {
  open: boolean;
};

export const ArrowIcon = styled(BaseKeyboardArrowDownIcon)<TKeyboardArrowDownIconProps>`
  ${mixins.wh('16px')}
  ${({theme, open}) => css`
    margin-left: ${theme.spacing.s2};
    transform: rotate(${open ? '0' : '-90deg'});
  `};
`;

export const LargeArrowIcon = styled(ArrowIcon)`
  ${mixins.wh('20px')}
`;

export const Children = styled(Column)`
  margin-top: ${({theme: {spacing}}) => spacing.s2};
`;
