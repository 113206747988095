import styled, {css} from 'styled-components';

import {Button as BaseButton, ESize} from '@edna/components';
import {mixins} from '@edna/components/styles';

type TSwitcherTransientProps = {
  selected?: boolean;
};

type TSwitcherProps = TTheme & TSwitcherTransientProps & {switchSize: ESize};

export const Switcher = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
`;

export const Switch = styled(BaseButton)<TSwitcherProps>(
  ({theme, selected, switchSize}: TSwitcherProps) => css`
    height: auto;
    margin-right: ${switchSize === ESize.M ? theme.spacing.s3 : theme.spacing.s2};
    padding: ${theme.spacing.s1} ${theme.spacing.s3};

    ${switchSize === ESize.M ? mixins.f2 : mixins.f1}

    font-weight: 500;
    ${selected &&
    css`
      background-color: ${theme.branding.backgrounds.secondary};
      border-color: ${theme.palette.grey50};
      cursor: default;

      &:hover {
        background-color: ${theme.branding.backgrounds.secondary};
        border-color: ${theme.palette.grey50};
      }
    `}
    &:hover {
      background-color: ${theme.palette.grey200};
      border-color: ${theme.palette.grey200};
    }
    &:last-child {
      margin-right: 0;
    }
  `,
);
