import {EOperatorName} from 'src/constants';

import {EStatus, ESubscriptionType} from './definitions';

export default {
  title: 'Анкета регистрации канала',
  subject: 'Имя отправителя',
  website: 'Сайт вашей компании',
  broadcastCountries: 'Страны назначения',
  exampleMessage: 'Пример текста сообщения',
  name: 'Внутреннее название канала',
  subscriptionByOperatorCode:
    'Условия отправки для этого отправителя (необходимо заполнить всех операторов)',
  subscriptionByOperatorCodeLabel: 'Для абонентов {{name}}',
  subscriptionByOperatorCodeLabelRu: 'Для абонентов прочих операторов России',
  subscriptionByOperatorCodeLabelIo: 'Для абонентов международных операторов',
  subscriptionByOperatorCodeRadio1: 'По фиксированной стоимости',
  subscriptionByOperatorCodeRadio2:
    'По динамическому тарифу с ежемесячной выплатой<br/> абонентской платы оператору',
  clientComment: 'Комментарий',
  status: {
    [EStatus.DRAFT]: 'Черновик',
    [EStatus.CREATE_SUBJECT]: 'Создание отправителя',
    [EStatus.CREATE_SUBJECT_ERROR]: 'Ошибка создания отправителя',
    [EStatus.IN_PROGRESS]: 'На регистрации',
    [EStatus.REJECTED]: 'Отключен',
    [EStatus.DONE]: 'Работает исправно',
  },
  options: {
    [ESubscriptionType.FEE]: 'Степ-тарификация сообщений с абонентской платой',
    [ESubscriptionType.MULTI]: 'Фиксированная стоимость за сообщение без абонентской платы',
    [ESubscriptionType.STEP]: 'Тариф с абонентской платой и степ-тарификацией',
    [ESubscriptionType.FEE_FIX]: 'Фиксированная стоимость за сообщение с абонентской платой',
    [ESubscriptionType.NONE]: 'Не подключать',
  },
  operators: {
    [EOperatorName.RuMts]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]:
        'Сообщения отправляются от общего имени. Общее имя не совпадает с именем, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuBeeline]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuTele2]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuMegafon]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]:
        'Сообщения отправляются от общего имени. Общее имя не совпадает с именем, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuYota]: {
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuVainahtelecom]: {
      [ESubscriptionType.MULTI]:
        'Сообщения отправляются от общего имени. Общее имя не совпадает с именем, которое вы ввели в этой анкете.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuMotiv]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuKtelecom]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]:
        'Сообщения отправляются от общего имени. Общее имя не совпадает с именем, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuKtktelecom]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]:
        'Сообщения отправляются от общего имени. Общее имя не совпадает с именем, которое вы ввели в этой анкете.',
      [ESubscriptionType.STEP]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuSbertelecom]: {
      [ESubscriptionType.FEE]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.FEE_FIX]:
        'Сообщения отправляются от имени, которое вы ввели в этой анкете. Абонентская плата списывается ежемесячно, независимо от трафика.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.RuDefault]: {
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера этого оператора будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
    },
    [EOperatorName.IoDefault]: {
      [ESubscriptionType.MULTI]: 'Сообщения отправляются от имени, которое вы ввели в этой анкете.',
      [ESubscriptionType.NONE]:
        'Отправка сообщений на номера международных операторов будет невозможна. При попытке рассылки на такие номера плата взиматься не будет.',
      message: 'Это направление доступно по согласованию с вашим менеджером',
    },
  },
  subscriptionsMessage: `Для регистрации канала заполните эту форму.<br/>Набор операторов и детали подключения вы можете уточнить у вашего менеджера`,
  registrationMessage: `Анкета этого СМС-канала отправлена на регистрацию. При изменении статуса вы получите уведомление по электронной почте`,
  channelDisabled: `Канал был отключен на стороне оператора. Детали вы можете узнать у вашего менеджера`,
};
