export default {
  allUser: 'Все клиенты',
  blackList: 'Черный список',
  editForm: {
    title: 'Добавить тег',
    name: 'Введите название для вашего нового тега',
    categoryId: 'Категория',
    categoryIdPlaceHolder: 'Выберите категорию',
  },
  tree: {
    categories: 'Категории',
    chosenCategoryTags: 'Теги выбранной категории',
    chosenTags: 'Выбранные теги',
    switcherLabel: 'Показать:',
    switcherCloud: 'Облако тегов',
    switcherCategories: 'Категории тегов',
    addTag: 'тег',
    findTag: 'Найти тег',
    availableTags: 'Доступные теги',
    selectTagsFromTree: 'Выбрать теги из дерева',
    chosenTagsHint: 'Укажите теги, которые будут применены в фильтре',
    info: 'Выберите теги для аудитории получателей',
  },
  empty: {
    searchTitle: 'Как жаль, мы не нашли такого тега',
    filterTitle: 'Увы, в этой категории пока нет тегов',
    searchSubtitle: 'Может что-то похожее есть в дереве тегов? Или попробуйте создать новый',
    filterSubtitle: 'Но вы можете их создать!',
    createAction: 'новый тег',
    backAction: 'К дереву',
  },
  dialog: {
    remove: {
      title: 'Удалить тег пользователя?',
      body: 'Помните, что удаляя тег пользователя, вы навсегда удаляете информацию о нем из нашей базы',
    },
  },
};
