import React from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {EChannelType} from 'src/constants';
import {EVariableType, TWhatsAppFormData} from 'src/containers/MessageMatchers/types';
import {
  getPlaceholdersFromVariableTemplate,
  getWordsFromVariableTemplate,
} from 'src/containers/MessageMatchers/utils';
import {useGetAllParametersQuery} from 'src/containers/Parameters/api';
import theme from 'src/styles/theme';

import * as S from '../style';
import {prepareText} from '../utils';

const CustomText = React.memo(({}) => {
  const {t} = useTranslation();

  const {data: parameters = []} = useGetAllParametersQuery();

  const {watch} = useFormContext<TWhatsAppFormData>();
  const text = watch('content.text');
  const variables = watch('variables') ?? [];

  if (!text) {
    return null;
  }

  const formattedText = prepareText({channelType: EChannelType.WHATSAPP, text, parameters});
  const words = getWordsFromVariableTemplate(formattedText ?? '');

  if (!words?.length) {
    return null;
  }

  const placeholders = getPlaceholdersFromVariableTemplate(formattedText ?? '');

  return (
    <S.TemplateText
      dangerouslySetInnerHTML={{
        __html: words
          .flatMap((word, index) => {
            const result = word.split(' ');

            if (placeholders[index]) {
              const variable = variables[index];
              const value = variable?.value ?? '';
              const option = parameters.find(
                (item) => String(item.id) === String(value).replace(/[{}]/g, ''),
              );

              if (option || (variable?.type === EVariableType.TEXT && value)) {
                result.push(
                  `<span style="color: ${theme.color.purple}; font-weight: 500">${
                    option?.name ?? value
                  }</span>`,
                );
              } else {
                result.push(
                  `<span style="color: ${theme.palette.green500}">${t(
                    'MessageMatchers:editForm.whatsApp.phonePreview',
                    {variableName: String(index + 1)},
                  )}</span>`,
                );
              }
            }

            return result;
          })
          .join(' '),
      }}
    />
  );
});

CustomText.displayName = 'CustomText';

export default CustomText;
