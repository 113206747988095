import React from 'react';
import {useTranslation} from 'react-i18next';

import {TNavigation} from 'src/containers/Layout/constants';

import MenuItem from '../MenuItem';
import * as S from '../style';

type TMenuListItem = {
  openItemId: string | null;
  menuOpen: boolean;
  item: TNavigation;
  toggleMenuItem: (id: string) => void;
};

const MenuListItem = React.memo<TMenuListItem>(({openItemId, menuOpen, item, toggleMenuItem}) => {
  const {t} = useTranslation();

  const handleToggleMenuItem = React.useCallback(
    () => toggleMenuItem(item.id),
    [toggleMenuItem, item.id],
  );

  if (!item.list) {
    return null;
  }

  return (
    <S.Collapse
      key={item.id}
      open={menuOpen && openItemId === item.id}
      labelComponent={S.CollapseLabel}
      arrowIcon={S.ArrowIcon}
      label={
        <>
          <S.LinkIcon as={item.icon} />
          {menuOpen && <S.LinkText>{t(`Layout:navigation.${item.id}`)}</S.LinkText>}
        </>
      }
      toggleOpen={handleToggleMenuItem}
    >
      {item.list.map((listItem) => (
        <MenuItem key={listItem.id} data={listItem} isNested />
      ))}
    </S.Collapse>
  );
});

MenuListItem.displayName = 'MenuListItem';

export default MenuListItem;
