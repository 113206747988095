import styled from 'styled-components';

import {mixins} from '@edna/components/styles';

import {ModalCenterImage as BaseModalCenterImage} from 'src/components/primitives';

export const ModalSubHeader = styled.div`
  ${mixins.f4}
`;

export const ModalCenterImage = styled(BaseModalCenterImage)`
  margin: ${({theme}: TTheme) => theme.spacing.s10} auto;
`;
