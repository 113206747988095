import {ETemporaryBlockedTypes, EUserCompanyProfileTabs} from './definitions';

export default {
  blockedByLicense:
    'Данное действие недоступно, по всем вопросам обратитесь к <0>вашему менеджеру</0>',
  serviceUnavailable: 'Сервис временно недоступен. Повторите попытку позже',
  tabs: {
    [EUserCompanyProfileTabs.PAYMENT]: 'Оплата',
    [EUserCompanyProfileTabs.LICENSE]: 'Лицензии',
    [EUserCompanyProfileTabs.REQUISITES]: 'Реквизиты',
    [EUserCompanyProfileTabs.PRICING]: 'Тарифы',
  },
  messages: {
    haveExpiredLicense_one:
      'У вас есть неоплаченный счет за лицензию. Лицензия на канал {{channels}} заблокирована. Для возобновления работы необходимо оплатить выставленные счета.',
    haveExpiredLicense_few:
      'У вас есть неоплаченный счет за лицензии. Лицензии на каналы {{channels}} заблокированы. Для возобновления работы необходимо оплатить выставленные счета.',
    haveExpiredLicense_many:
      'У вас есть неоплаченный счет за лицензии. Лицензии на каналы {{channels}} заблокированы. Для возобновления работы необходимо оплатить выставленные счета.',
    haveExpiredBillForLicense_one:
      'У вас есть неоплаченный счет за лицензии. Работа по каналу {{channels}} заблокирована. Для возобновления работы необходимо оплатить выставленные счета.',
    haveExpiredBillForLicense_few:
      'У вас есть неоплаченный счет за лицензии. Работа по каналам {{channels}} заблокирована. Для возобновления работы необходимо оплатить выставленные счета.',
    haveExpiredBillForLicense_many:
      'У вас есть неоплаченный счет за лицензии. Работа по каналам {{channels}} заблокирована. Для возобновления работы необходимо оплатить выставленные счета.',
    haveNotMoney: 'У вас недостаточно средств для продолжения работы в сервисе. {{action}}',
    haveNotPayedBills:
      'У вас есть неоплаченный счет. В случае неоплаты работа в системе может быть ограничена',
    addBalance: 'Пополните баланс.',
    lowBalance:
      'Доступных средств может быть недостаточно для отправки рассылок. Свяжитесь с менеджером, чтобы решить вопрос с вашим балансом или овердрафтом.',
    broadcastBlocked: `Ваш доступ к услугам приостановлен до {{date}}.
       До этого момента отправка рассылок и получение сообщений будут недоступны. Все создаваемые рассылки будут сохраняться как черновики.
       Для активации услуг необходимо связаться с ответственным менеджером`,
    accessBlocked: `Ваш доступ к услугам приостановлен до {{date}}.
       До этого момента отправка и получение сообщений будут недоступны.
       Для активации услуг необходимо связаться с ответственным менеджером`,
  },
  buttons: {
    openDocumentList: 'Открыть список документов',
    contactWithManager: 'Написать менеджеру',
  },
  temporaryBlockModal: {
    toProfile: 'В профиль',
    [ETemporaryBlockedTypes.BROADCASTS]: {
      title: 'Вы не можете регистрировать рассылки',
      subtitle:
        'Ваш аккаунт временно заблокирован, а поэтому регистрация рассылок недоступна до {{date}}. Чтобы активировать аккаунт свяжитесь с ответственным менеджером.',
    },
    [ETemporaryBlockedTypes.TEMPLATES]: {
      title: 'Вы не можете регистрировать шаблон',
      subtitle:
        'Ваш аккаунт временно заблокирован, а поэтому регистрация шаблонов недоступна до {{date}}. Чтобы активировать аккаунт свяжитесь с ответственным менеджером.',
    },
    [ETemporaryBlockedTypes.CHANNELS]: {
      title: 'Вы не можете регистрировать каналы',
      subtitle:
        'Ваш аккаунт временно заблокирован, а поэтому регистрация каналов недоступна до {{date}}. Чтобы активировать аккаунт свяжитесь с ответственным менеджером.',
    },
    [ETemporaryBlockedTypes.CASCADES]: {
      title: 'Вы не можете регистрировать каскад',
      subtitle:
        'Ваш аккаунт временно заблокирован, а поэтому регистрация каскадов недоступна до {{date}}. Чтобы активировать аккаунт свяжитесь с ответственным менеджером.',
    },
    [ETemporaryBlockedTypes.OTHER]: {
      title: 'Ваш аккаунт временно заблокирован',
      subtitle: 'Чтобы активировать аккаунт свяжитесь с ответственным менеджером.',
    },
  },
};
