import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, generatePath} from 'react-router-dom';

import {NotificationCounter} from '@edna/components';
import {useExactSelector} from '@edna/hooks';

import {
  selectIsNewNotificationsReceived,
  useGetNotificationsCountQuery,
} from 'src/containers/Notifications';
import PATHS from 'src/paths';

import {TNavigation} from '../constants';
import * as S from '../style';

type TMenuLink = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  data: TNavigation;
  menuOpen?: boolean;
  hasNotifications?: boolean;
  isNested?: boolean;
};

const MenuItem = React.memo<TMenuLink>(({onClick, isNested, data, menuOpen, hasNotifications}) => {
  const {t} = useTranslation();
  const isNewPollReceived = useExactSelector(selectIsNewNotificationsReceived);

  const {data: notificationsCount = 0} = useGetNotificationsCountQuery(undefined, {
    skip: !data.href || data.href === PATHS.SIGN_OUT,
  });

  if (!data.href) {
    return null;
  }

  const toPath = data.params ? generatePath(data.href, data.params) : data.href;

  if (isNested) {
    return (
      <S.NestedMenuItem key={data.id} to={toPath} as={NavLink}>
        {t(`Layout:navigation.${data.id}`)}
      </S.NestedMenuItem>
    );
  }

  const isShowNotificationsCount = hasNotifications && notificationsCount > 0;

  if (menuOpen) {
    return (
      <S.MenuItem key={data.id} to={toPath} onClick={onClick} as={NavLink}>
        <S.LinkIcon as={data.icon} />
        <S.LinkText>{t(`Layout:navigation.${data.id}`)}</S.LinkText>
        {isShowNotificationsCount && (
          <NotificationCounter count={notificationsCount} showAnimation={isNewPollReceived} />
        )}
      </S.MenuItem>
    );
  }

  return (
    <S.MenuItem key={data.id} to={toPath} onClick={onClick} as={NavLink}>
      {isShowNotificationsCount ? (
        <NotificationCounter count={notificationsCount} showAnimation={isNewPollReceived} />
      ) : (
        <S.LinkIcon as={data.icon} />
      )}
    </S.MenuItem>
  );
});

MenuItem.displayName = 'MenuItem';

export default MenuItem;
