/* eslint-disable global-require, @typescript-eslint/no-var-requires */
import {i18nEdnaResourcesEn, i18nEdnaResourcesRu} from '@edna/components';

import {ELanguage} from 'src/constants';
import {
  ISO2EnCountryHash,
  ISO2RuCountryHash,
  ISO3EnCountryHash,
  ISO3RuCountryHash,
} from 'src/utils/country';

// prettier-ignore
const resources = {
  [ELanguage.ru]: {
    ...i18nEdnaResourcesRu,
    // common
    Country: {
      iso2: ISO2RuCountryHash,
      iso3: ISO3RuCountryHash,
      other: 'Прочие'
    },
    Common: require('src/i18n/common.ru').default,
    PageTitle: require('src/i18n/pageTitle.ru').default,
    // components
    TreeSelect: require('src/components/TreeSelect/i18n.ru').default,
    PasswordRules: require('src/components/PasswordRules/i18n.ru').default,
    CascadeStages: require('src/containers/Broadcasts/ContentForm/CascadeStages/i18n.ru').default,
    NumberedSubject: require('src/components/NumberedSubject/i18n.ru').default,
    EditorWithSelector: require('src/components/EditorWithSelector/i18n.ru').default,
    SearchInput: require('src/components/SearchInput/i18n.ru').default,
    ScrollUpButton: require('src/components/ScrollUpButton/i18n.ru').default,
    ImageField: require('src/components/ImageField/i18n.ru').default,
    FilePicker: require('src/components/FilePicker/i18n.ru').default,
    Filters: require('src/components/Filters/i18n.ru').default,
    Facebook: require('src/components/Facebook/i18n.ru').default,

    // containers
    Home: require('src/containers/Home/i18n.ru').default,
    Tariff: require('src/containers/Tariff/i18n.ru').default,
    NotFound: require('src/containers/NotFound/i18n.ru').default,
    ServiceUnavailable: require('src/containers/ServiceUnavailable/i18n.ru').default,
    Auth: require('src/containers/Auth/i18n.ru').default,
    SignInByInviteLink: require('src/containers/SignInByInviteLink/i18n.ru').default,
    Layout: require('src/containers/Layout/i18n.ru').default,
    Channels: require('src/containers/Channels/i18n.ru').default,
    PushApps: require('src/containers/PushApps/i18n.ru').default,
    PushAppsEdit: require('src/containers/PushApps/EditForm/i18n.ru').default,
    WhatsAppChannel: require('src/containers/WhatsAppChannel/i18n.ru').default,
    WhatsAppStatusNotification: require('src/containers/WhatsAppChannel/StatusNotification/i18n.ru').default,
    WhatsAppVerificationForm: require('src/containers/WhatsAppChannel/VerificationForm/i18n.ru').default,
    ViberChannel: require('src/containers/ViberChannel/i18n.ru').default,
    SmsChannel: require('src/containers/SmsChannel/i18n.ru').default,
    InstagramChannel: require('src/containers/InstagramChannel/i18n.ru').default,
    EmbeddedWhatsAppChannel: require('src/containers/EmbeddedWhatsAppChannel/i18n.ru').default,
    Cascades: require('src/containers/Cascades/i18n.ru').default,
    MessageMatchers: require('src/containers/MessageMatchers/i18n.ru').default,
    WhatsAppAuthTemplate: require('src/containers/MessageMatchers/WhatsAppForm/AuthTemplateLocalization').default,
    MessageTextField: require('src/containers/MessageMatchers/MessageTextField/i18n.ru').default,
    Broadcasts: require('src/containers/Broadcasts/i18n.ru').default,
    Subscribers: require('src/containers/Subscribers/i18n.ru').default,
    Tags: require('src/containers/Tags/i18n.ru').default,
    Parameters: require('src/containers/Parameters/i18n.ru').default,
    Profile: require('src/containers/Profile/i18n.ru').default,
    SupportClients: require('src/containers/SupportClients/i18n.ru').default,
    SupportClientCard: require('src/containers/SupportClients/SupportClientCard/i18n.ru').default,
    BalanceWidget: require('src/containers/SupportClients/SupportClientCard/BalanceWidget/i18n.ru').default,
    LeadCompanyClients: require('src/containers/LeadCompanyClients/i18n.ru').default,
    LeadCompanyClientEdit: require('src/containers/LeadCompanyClients/EditForm/i18n.ru').default,
    UserCompanyProfile: require('src/containers/UserCompanyProfile/i18n.ru').default,
    CompanyDetails: require('src/containers/CompanyDetails/i18n.ru').default,
    AccountStatement: require('src/containers/AccountStatement/i18n.ru').default,
    CompanyCallback: require('src/containers/CompanyCallback/i18n.ru').default,
    Licenses: require('src/containers/Licenses/i18n.ru').default,
    UserCompanyProfileBalance: require('src/containers/UserCompanyProfile/BalanceWidget/i18n.ru').default,
    UserCompanyProfilePaymentModal: require('src/containers/UserCompanyProfile/PaymentModals/i18n.ru').default,
    CompanyDocument: require('src/containers/Licenses/PaymentTab/CompanyDocument/i18n.ru').default,
    Report: require('src/containers/Report/i18n.ru').default,
    Notifications: require('src/containers/Notifications/i18n.ru').default,
    NotificationsHistory: require('src/containers/NotificationsHistory/i18n.ru').default,
    Help: require('src/containers/Help/i18n.ru').default,
    Triggers: require('src/containers/Triggers/i18n.ru').default,
    TriggerSettings: require('src/containers/Triggers/Modals/AddTrigger/i18n.ru').default,
    PushChannel: require('src/containers/PushChannel/i18n.ru').default,
    PhonePreview: require('src/containers/PhonePreview/i18n.ru').default,
    Tariffs: require('src/containers/Tariffs/i18n.ru').default,
    TariffsTab: require('src/containers/Tariffs/TariffsTab/i18n.ru').default,
    Integration: require('src/containers/Integration/i18n.ru').default,
    SummaryReport: require('src/containers/SummaryReport/i18n.ru').default,
    ChannelReport: require('src/containers/SummaryReport/ChannelReport/i18n.ru').default,
    ClientStatus: require('src/containers/ClientStatus/i18n.ru').default,
    News: require('src/containers/News/i18n.ru').default,
    PaymentTables: require('src/containers/Licenses/PaymentTab/PaymentReplenishment/PaymentTables/i18n.ru').default,
    PaymentTab: require('src/containers/Licenses/PaymentTab/i18n.ru').default,
    Corrections: require('src/containers/Corrections/i18n.ru').default,
    LicenseRenewal: require('src/containers/LicenseRenewal/i18n.ru').default,
    CurrencyCorrections: require('src/containers/CurrencyCorrections/i18n.ru').default,
    VkNotifyChannel: require('src/containers/VkNotifyChannel/i18n.ru').default,
    OkNotifyChannel: require('src/containers/OkNotifyChannel/i18n.ru').default,
    PushStyle: require('src/containers/PushStyle/i18n.ru').default,
    Autopayment: require('src/containers/Autopayment/i18n.ru').default,
    // models
    Errors: require('src/models/errors.i18n.ru').default,
    List: require('src/models/list.i18n.ru').default,
    // utils
    validator: require('src/utils/validator/i18n.ru').default,
  },
  [ELanguage.en]: {
    ...i18nEdnaResourcesEn,
    // common
    Country: {
      iso2: ISO2EnCountryHash,
      iso3: ISO3EnCountryHash,
      other: 'Other'
    },
    Common: require('src/i18n/common.en').default,
    PageTitle: require('src/i18n/pageTitle.en').default,
    // components
    Home: require('src/containers/Home/i18n.en').default,
    TreeSelect: require('src/components/TreeSelect/i18n.en').default,
    PasswordRules: require('src/components/PasswordRules/i18n.en').default,
    CascadeStages: require('src/containers/Broadcasts/ContentForm/CascadeStages/i18n.en').default,
    NumberedSubject: require('src/components/NumberedSubject/i18n.en').default,
    EditorWithSelector: require('src/components/EditorWithSelector/i18n.en').default,
    SearchInput: require('src/components/SearchInput/i18n.en').default,
    ScrollUpButton: require('src/components/ScrollUpButton/i18n.en').default,
    Filters: require('src/components/Filters/i18n.en').default,
    ImageField: require('src/components/ImageField/i18n.en').default,
    FilePicker: require('src/components/FilePicker/i18n.en').default,
    Facebook: require('src/components/Facebook/i18n.en').default,

    // containers
    Tariff: require('src/containers/Tariff/i18n.en').default,
    NotFound: require('src/containers/NotFound/i18n.en').default,
    ServiceUnavailable: require('src/containers/ServiceUnavailable/i18n.en').default,
    Auth: require('src/containers/Auth/i18n.en').default,
    SignInByInviteLink: require('src/containers/SignInByInviteLink/i18n.en').default,
    Layout: require('src/containers/Layout/i18n.en').default,
    Channels: require('src/containers/Channels/i18n.en').default,
    PushApps: require('src/containers/PushApps/i18n.en').default,
    PushAppsEdit: require('src/containers/PushApps/EditForm/i18n.en').default,
    WhatsAppChannel: require('src/containers/WhatsAppChannel/i18n.en').default,
    WhatsAppStatusNotification: require('src/containers/WhatsAppChannel/StatusNotification/i18n.en').default,
    WhatsAppVerificationForm: require('src/containers/WhatsAppChannel/VerificationForm/i18n.en').default,
    ViberChannel: require('src/containers/ViberChannel/i18n.en').default,
    SmsChannel: require('src/containers/SmsChannel/i18n.en').default,
    InstagramChannel: require('src/containers/InstagramChannel/i18n.en').default,
    EmbeddedWhatsAppChannel: require('src/containers/EmbeddedWhatsAppChannel/i18n.en').default,
    Cascades: require('src/containers/Cascades/i18n.en').default,
    MessageMatchers: require('src/containers/MessageMatchers/i18n.en').default,
    WhatsAppAuthTemplate: require('src/containers/MessageMatchers/WhatsAppForm/AuthTemplateLocalization').default,
    MessageTextField: require('src/containers/MessageMatchers/MessageTextField/i18n.en').default,
    Broadcasts: require('src/containers/Broadcasts/i18n.en').default,
    Subscribers: require('src/containers/Subscribers/i18n.en').default,
    Tags: require('src/containers/Tags/i18n.en').default,
    Parameters: require('src/containers/Parameters/i18n.en').default,
    Profile: require('src/containers/Profile/i18n.en').default,
    SupportClients: require('src/containers/SupportClients/i18n.en').default,
    SupportClientCard: require('src/containers/SupportClients/SupportClientCard/i18n.en').default,
    BalanceWidget: require('src/containers/SupportClients/SupportClientCard/BalanceWidget/i18n.en').default,
    LeadCompanyClients: require('src/containers/LeadCompanyClients/i18n.en').default,
    LeadCompanyClientEdit: require('src/containers/LeadCompanyClients/EditForm/i18n.en').default,
    UserCompanyProfile: require('src/containers/UserCompanyProfile/i18n.en').default,
    CompanyDetails: require('src/containers/CompanyDetails/i18n.en').default,
    AccountStatement: require('src/containers/AccountStatement/i18n.en').default,
    CompanyCallback: require('src/containers/CompanyCallback/i18n.en').default,
    Licenses: require('src/containers/Licenses/i18n.en').default,
    UserCompanyProfileBalance: require('src/containers/UserCompanyProfile/BalanceWidget/i18n.en').default,
    UserCompanyProfilePaymentModal: require('src/containers/UserCompanyProfile/PaymentModals/i18n.en').default,
    CompanyDocument: require('src/containers/Licenses/PaymentTab/CompanyDocument/i18n.en').default,
    Report: require('src/containers/Report/i18n.en').default,
    Notifications: require('src/containers/Notifications/i18n.en').default,
    NotificationsHistory: require('src/containers/NotificationsHistory/i18n.en').default,
    Help: require('src/containers/Help/i18n.en').default,
    Triggers: require('src/containers/Triggers/i18n.en').default,
    TriggerSettings: require('src/containers/Triggers/Modals/AddTrigger/i18n.en').default,
    PushChannel: require('src/containers/PushChannel/i18n.en').default,
    PhonePreview: require('src/containers/PhonePreview/i18n.en').default,
    Tariffs: require('src/containers/Tariffs/i18n.en').default,
    TariffsTab: require('src/containers/Tariffs/TariffsTab/i18n.en').default,
    Integration: require('src/containers/Integration/i18n.en').default,
    SummaryReport: require('src/containers/SummaryReport/i18n.en').default,
    ChannelReport: require('src/containers/SummaryReport/ChannelReport/i18n.en').default,
    ClientStatus: require('src/containers/ClientStatus/i18n.en').default,
    News: require('src/containers/News/i18n.en').default,
    PaymentTables: require('src/containers/Licenses/PaymentTab/PaymentReplenishment/PaymentTables/i18n.en').default,
    PaymentTab: require('src/containers/Licenses/PaymentTab/i18n.en').default,
    Corrections: require('src/containers/Corrections/i18n.en').default,
    LicenseRenewal: require('src/containers/LicenseRenewal/i18n.en').default,
    CurrencyCorrections: require('src/containers/CurrencyCorrections/i18n.en').default,
    VkNotifyChannel: require('src/containers/VkNotifyChannel/i18n.en').default,
    OkNotifyChannel: require('src/containers/OkNotifyChannel/i18n.en').default,
    PushStyle: require('src/containers/PushStyle/i18n.en').default,
    Autopayment: require('src/containers/Autopayment/i18n.en').default,
    // models
    Errors: require('src/models/errors.i18n.en').default,
    List: require('src/models/list.i18n.en').default,
    // utils
    validator: require('src/utils/validator/i18n.en').default,
  },
};
// prettier-ignore-end

export default resources;
/* eslint-enable global-require, @typescript-eslint/no-var-requires */
