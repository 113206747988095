import React from 'react';

import * as S from './style';
import {useSystemNotifications} from './useNotifications';
import {useNotificationsSize} from './useNotificationsSize';

const DEFAULT_VISIBLE_COUNT = 5;

const Notifications = React.memo<{contentRef: React.RefObject<HTMLDivElement>}>(({contentRef}) => {
  const {width, left} = useNotificationsSize(contentRef);
  const systemNotifications = useSystemNotifications();

  return (
    <S.Notifications
      visibleCount={DEFAULT_VISIBLE_COUNT}
      isCheckDuplicates
      width={width}
      left={left}
      hasSystemNotifications={!!systemNotifications.length}
    />
  );
});

export {Notifications};
