import React from 'react';

import {Tour} from './Tour';
import {ETour} from './definitions';

const OnboardingActionsContext = React.createContext<{
  startOnboarding: (tour: keyof typeof ETour) => void;
  stopOnboarding: TEmptyFunction;
}>({
  startOnboarding: () => {},
  stopOnboarding: () => {},
});

const useOnboarding = () => {
  const actions = React.useContext(OnboardingActionsContext);

  return actions;
};

const Onboarding = React.memo<{children: React.ReactNode}>(({children}) => {
  const [currentTourId, setCurrentTourId] = React.useState<keyof typeof ETour | undefined>(
    undefined,
  );

  const stopOnboarding = React.useCallback(() => {
    setCurrentTourId(undefined);
  }, []);

  const actions = React.useMemo(
    () => ({
      startOnboarding: (tourId: keyof typeof ETour) => {
        setCurrentTourId(tourId);
      },
      stopOnboarding,
    }),
    [stopOnboarding],
  );

  return (
    <OnboardingActionsContext.Provider value={actions}>
      {children}
      {currentTourId && <Tour id={currentTourId} onClose={stopOnboarding} />}
    </OnboardingActionsContext.Provider>
  );
});

export {Onboarding, useOnboarding};
