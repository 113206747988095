import React from 'react';
import {useTranslation} from 'react-i18next';

import {Stack} from '@edna/components/primitives';

import {SecondaryText} from 'src/components/primitives';
import {EQualityScore, qualityScorePriorityMap} from 'src/containers/Channels/definitions';
import {TEmbeddedWhatsAppChannel} from 'src/containers/EmbeddedWhatsAppChannel/definitions';
import {TWhatsAppChannel} from 'src/containers/WhatsAppChannel/definitions';

import * as S from './style';

type TProps = {
  item: TWhatsAppChannel | TEmbeddedWhatsAppChannel;
};

const ChannelQualityScore = React.memo<TProps>(({item}) => {
  const {t} = useTranslation();

  return (
    <S.InfoRow>
      <Stack direction="ROW" size="2" align="center">
        <S.InfoLabel noMargin>{t('Channels:qualityScoreLabel')}</S.InfoLabel>
        <S.QualityScore qualityColor={item.qualityScore}>
          {item.previousQualityScore && item.qualityScore && (
            <S.ArrowIcon
              isDown={
                qualityScorePriorityMap[item.previousQualityScore] >
                qualityScorePriorityMap[item.qualityScore]
              }
            />
          )}
          {t(`Channels:qualityScore.${item.qualityScore}`)}
        </S.QualityScore>
        {item.qualityScore === EQualityScore.RED && (
          <SecondaryText size="XS">
            <Stack direction="ROW" size="05" align="center">
              <S.QualityWarningIcon name="error" />
              {t('Channels:qualityWarning')}
            </Stack>
          </SecondaryText>
        )}
      </Stack>
    </S.InfoRow>
  );
});

ChannelQualityScore.displayName = 'ChannelQualityScore';

export {ChannelQualityScore};
