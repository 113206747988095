import {ELicenseStatus} from './definitions';

export default {
  licenses: 'Лицензии',
  termsValidUntil: 'Пользовательское соглашение действительно до:',
  termsAccepted: 'Пользовательское соглашение принято:',
  availableChannels: 'Доступные каналы',
  activeSignatures: 'Активные подписи',
  noActiveSignatures: 'У вас нет активных подписей',
  licenseStatus: {
    [ELicenseStatus.NEW]: 'Требует внимания',
    [ELicenseStatus.CANCELLED]: 'Отключена',
    [ELicenseStatus.ACTIVE]: 'Активна',
    [ELicenseStatus.ACTIVE_DEBT]: 'Требует внимания',
    [ELicenseStatus.EXPIRED]: 'Просрочена',
    [ELicenseStatus.TEMPORARY_BLOCKED]: 'Отключена',
  },
  createdAt: 'Создана ',
  cancelledAt: 'Отменена ',
  temporaryblockedAt: 'Временно заблокировано c ',
  validUntil: 'Действительна до ',
  expiredAt: 'Закончилась ',
  validUntilHint: 'Активирована с {{activatedAt}}; оплачена до {{paidUntil}}',
  showAll: 'Показать все',
  hide: 'Скрыть',
  button: {
    block: 'Отключить',
    refuse: 'Отказаться',
    cancel: 'Отмена',
  },
  licensesInformation: {
    licenses: 'Лицензии:',
    status: {
      [`${ELicenseStatus.ACTIVE}_one`]: '{{count}} активная',
      [`${ELicenseStatus.ACTIVE}_few`]: '{{count}} активные',
      [`${ELicenseStatus.ACTIVE}_many`]: '{{count}} активных',
      [`${ELicenseStatus.EXPIRED}_one`]: '{{count}} просроченная',
      [`${ELicenseStatus.EXPIRED}_few`]: '{{count}} просроченные',
      [`${ELicenseStatus.EXPIRED}_many`]: '{{count}} просроченных',
      [`${ELicenseStatus.CANCELLED}_one`]: '{{count}} отключенная',
      [`${ELicenseStatus.CANCELLED}_few`]: '{{count}} отключенные',
      [`${ELicenseStatus.CANCELLED}_many`]: '{{count}} отключенных',
      [`${ELicenseStatus.TEMPORARY_BLOCKED}_one`]: '{{count}} отключенная',
      [`${ELicenseStatus.TEMPORARY_BLOCKED}_few`]: '{{count}} отключенные',
      [`${ELicenseStatus.TEMPORARY_BLOCKED}_many`]: '{{count}} отключенных',
    },
    needAttention: '{{count}} требует внимания',
  },
  cancelModal: {
    clientTitle: 'Хотите отказаться от лицензии?',
    clientText:
      'Лицензия будет заблокирована без возможности восстановления, а значит отправка и получение сообщений по этой подписи будут недоступны. Возврат денежных средств за оплаченную лицензию невозможен согласно договору.',
    accountTitle: 'Отключить лицензию?',
    accountText:
      'При отказе от лицензии подпись будет заблокирована без возможности восстановления. Отправка и получение по этой подписи будет недоступна.',
    successBlock: 'Лицензия успешно заблокирована',
  },
};
