import React from 'react';

import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import noop from 'lodash/noop';

import * as S from './style';
import {EColor, EVariant, TColor, TVariant} from './types';

export {EVariant, EColor};

export type TProps = {
  className?: string;
  variant?: TVariant;
  color?: TColor;
  children?: React.ReactNode;
  onClick?: TEmptyFunction;
  onDelete?: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
};

const Chip = React.memo<TProps>(
  ({
    className,
    variant = EVariant.outlined,
    color = EColor.black,
    onClick = noop,
    onDelete,
    children,
    disabled = false,
  }) => (
    <S.Chip
      disabled={disabled}
      className={className}
      variant={variant}
      color={color}
      onClick={disabled ? undefined : onClick}
      {...edna.renderTestId(edna.TEST_IDS.CHIP.CONTAINER)}
    >
      {isString(children) ? <S.Text title={children}>{children}</S.Text> : children}
      {!disabled && isFunction(onDelete) && (
        <S.CloseIcon onClick={onDelete} {...edna.renderTestId(edna.TEST_IDS.CHIP.REMOVE_BUTTON)} />
      )}
    </S.Chip>
  ),
);

export default Chip;
