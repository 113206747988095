import {ETariffChangeRequestStatus} from './definitions';

export default {
  personal: 'Индивидуальный ',
  free: '(бесплатное подключение)',
  mauCount: 'MAU {{mauCount}}',
  bynTariff: '{{channel}}_BYN',
  acceptTariff: {
    title: 'Примите ваши тарифы',
    subtitle:
      'Ознакомьтесь с тарифами и нажмите кнопку "{{buttonName}}". После принятия тарифы станут доступными для регистрации каналов',
  },
  agreeTariffChange: {
    title_one: 'У вас поменялся тариф',
    title_few: 'У вас поменялись тарифы',
    title_many: 'У вас поменялись тарифы',
    subtitle:
      'По вашему запросу текущий тариф был изменен. Чтобы изменения вступили в силу, вы должны принять новый тариф',
    text: '<0>Новый тариф:</0> {{newTariffName}}, <br/> <0>для канала:</0> {{subjectName}}',
  },
  tariffHistory: {
    title: 'История изменения тарифа',
    active: 'Действующий тариф: ',
    changed: '<0>{{date}} Тариф изменен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    connected: '<0>{{date}} Тариф подключен</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    individual: 'Индивидуальный',
  },
  successCreatingTariff: {
    title: 'Поздравляем! Вы успешно создали тариф!',
    text: 'Теперь вы можете продолжить с новым тарифом',
  },
  createPersonalTariff: {
    title: 'Создать индивидуальный тариф',
    message:
      'После создания индивидуальный тариф отредактировать нельзя. Если потребуются изменения, они будут вноситься только через саппорт',
    templateTitleError: 'Шаблон тарифа с таким названием уже существует',
    selectTemplateError: 'Выберите шаблон тарифа',
    attachFile: 'Прикрепить файл XLSX',
    attachAnotherFile: 'Загрузить другой файл',
    labels: {
      selectTemplate:
        'Выберите шаблон тарифа, на основании которого будет создан индивидуальный тариф',
      templateName:
        'Название индивидуального шаблона тарифа (должно быть уникальным для вашего личного кабинета). Имя будет отображаться клиенту',
      mauCount: 'Количество MAU',
      licensePrice: 'Стоимость лицензии, {{currency}}',
      additionalMauCost: 'Стоимость дополнительных MAU, {{currency}}',
      dialogsCost: 'Стоимость диалогов, {{currency}}',
      uploadFile: 'Загрузите согласованный файл со стоимостью сообщений',
      uploadFileWA:
        'Загрузите согласованный файл с индивидуальной стоимостью диалогов в валюте, которую вы указали на 1ом шаге регистрации клиента. Для клиентов ОСК стоимость сообщений указывается в USD.',
      country: 'Страна',
      businessDialog: 'Бизнес-диалог',
      userDialog: 'Клиентский диалог',
      initPayment: 'Стоимость подключения, {{currency}}',
    },
  },
  tariffCostModal: {
    title: 'Стоимость за диалоги',
  },
  button: {
    refuse: 'Отказаться',
    agreeTariff: 'Согласовать тариф',
    acceptTariff_one: 'Принять тариф',
    acceptTariff_few: 'Принять тарифы',
    acceptTariff_many: 'Принять тарифы',
    fine: 'Хорошо!',
  },
  status: {
    [ETariffChangeRequestStatus.NEED_TO_AGREE]: 'На согласовании',
    [ETariffChangeRequestStatus.AGREED]: 'Согласован',
    [ETariffChangeRequestStatus.PROCESSED]: 'На рассмотрении',
    [ETariffChangeRequestStatus.CANCELED]: 'Отменен',
    [ETariffChangeRequestStatus.REJECTED]: 'Отклонен',
    [ETariffChangeRequestStatus.NOT_AGREED]: 'Не согласован',
  },
};
