import styled, {CSSProperties, css} from 'styled-components';

import {Switch as BaseSwitch} from '@edna/components';
import {TTheme, mixins} from '@edna/components/styles';

import {Tabs as BaseTabs} from 'src/components';
import {Text as BaseText} from 'src/components/primitives';
import {EChannelType, TChannelType} from 'src/constants';
import {rightPreview} from 'src/styles/mixins';

type TPreviewWrapperProps = TTheme & {
  isSticky?: boolean;
};

export const PreviewWrapper = styled.div<TPreviewWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 320px;
  min-width: 320px;
  ${({isSticky}: TPreviewWrapperProps) => isSticky && rightPreview}
`;

export const Preview = styled.div`
  position: relative;
  ${mixins.flexAlign('center')}
  flex-direction: column;
  width: 100%;
  min-height: 670px;
  border-radius: ${({theme}) => theme.borderRadius.br1};
`;

export const Tabs = styled(BaseTabs)`
  max-width: max-content;
  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s4};
  `}
`;

export const Device = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  min-height: 30px;

  ${({theme}) => css`
    padding: 0 ${theme.spacing.s2};
    border: ${theme.dimension.borderWidth}px solid ${theme.branding.content.tertiary};
    border-radius: ${theme.borderRadius.br8};
  `}
`;

export const DeviceIcon = styled.div`
  ${mixins.wh('20px')}
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s1};
`;

type TBackgroundProps = TTheme & {
  type: EChannelType;
};

const getBackground = ({type, theme}: TBackgroundProps) => {
  if ([EChannelType.VK_NOTIFY, EChannelType.OK_NOTIFY, EChannelType.WHATSAPP].includes(type)) {
    return theme.color.beige;
  }

  if (type === EChannelType.PUSH) {
    return theme.branding.content.secondary;
  }

  return theme.palette.white;
};

export const PreviewBackground = styled.svg<TBackgroundProps>`
  ${mixins.wh('100%')}
  ${mixins.position({position: 'absolute', top: '0', left: '0'})}
  border-radius: 39px;
  color: ${getBackground};
`;

export const Title = styled.div`
  text-align: center;
  ${({theme: {spacing, branding}}) => css`
    margin-bottom: ${spacing.s4};
    color: ${branding.content.tertiary};
  `}
`;

export const Icon = styled.div`
  ${mixins.wh('40px')}
  color: ${({theme}) => theme.branding.content.tertiary};
`;

type TContentProps = {
  isIOS: boolean;
};

export const Content = styled.div<TContentProps>`
  width: calc(100% - 23px);
  ${mixins.position({position: 'absolute', top: '0', left: '0'})}
  margin: 39px 0 0 11px;
  border-radius: 0 0 35px 35px;
  overflow: hidden;
  ${({isIOS}: TContentProps) => css`
    height: calc(100% - ${isIOS ? 51 : 59}px);
    border-radius: ${isIOS ? '0 0 40px 37px' : '30px 33px 34px 32px'};
  `}
`;

export const TemplateText = styled.span`
  ${mixins.f3}
  line-height: 18px;
`;

type TBodyProps = TTheme & {
  type: TChannelType;
};

const getBodyStyle = ({theme: {spacing}, type}: TBodyProps) => {
  switch (type) {
    case EChannelType.WHATSAPP:
    case EChannelType.VK_NOTIFY:
    case EChannelType.OK_NOTIFY:
      return css`
        padding: ${spacing.s12} ${spacing.s8} ${spacing.s5} ${spacing.s4};
      `;
    case EChannelType.VIBER:
      return css`
        ${mixins.flexAlign('normal', 'flex-start')}
        padding: ${spacing.s12} ${spacing.s8} ${spacing.s5} ${spacing.s2};
      `;
    case EChannelType.SMS:
      return css`
        padding: ${spacing.s12} ${spacing.s8} ${spacing.s8} ${spacing.s4};
      `;
    case EChannelType.INSTAGRAM:
      return css`
        ${mixins.flexAlign('normal', 'flex-start')}
        height: max-content;
        padding: ${spacing.s12} ${spacing.s8} ${spacing.s8} ${spacing.s4};
      `;
    case EChannelType.PUSH:
      return css`
        padding: ${spacing.s12} ${spacing.s3} ${spacing.s12} ${spacing.s2};
      `;
    default:
      return '';
  }
};

export const Body = styled.div<TBodyProps>`
  height: 100%;
  overflow: hidden auto;
  ${getBodyStyle}
  &::-webkit-scrollbar {
    ${mixins.wh('4px')}
  }
`;

export const Switch = styled(BaseSwitch)`
  max-width: max-content;
  margin-bottom: ${({theme}) => theme.spacing.s4};
`;

export const SwitchContent = styled.div`
  ${mixins.f1}
`;

type TMessageContentProps = TTheme & {
  noPaddings?: boolean;
  display?: CSSProperties['display'];
};

export const MessageContent = styled.div<TMessageContentProps>`
  position: relative;
  width: 100%;
  min-height: 31px;
  word-break: break-word;
  white-space: pre-wrap;
  ${({theme: {spacing}, noPaddings, display}: TMessageContentProps) => css`
    display: ${display ?? 'block'};
    padding: ${noPaddings ? 0 : spacing.s2};
  `}
`;

type TTimeProps = TTheme & {
  isRtlDirection?: boolean;
};

export const Time = styled.span<TTimeProps>`
  ${mixins.f2}
  ${mixins.position({position: 'relative', bottom: '-4px'})}
  ${({theme: {branding, spacing}, isRtlDirection}: TTimeProps) => css`
    float: ${isRtlDirection ? 'left' : 'right'};
    padding-left: ${spacing.s2};
    color: ${branding.content.tertiary};
  `}
`;

type TImageProps = TTheme & {
  isFull?: boolean;
  type: TChannelType;
};

export const Image = styled.img<TImageProps>`
  display: block;
  max-width: 100%;
  max-height: 100%;
  ${({theme: {spacing}, isFull, type}: TImageProps) =>
    type === EChannelType.VIBER
      ? css`
          max-width: ${isFull ? '100%' : `calc(100% + 2 * ${spacing.s2})`};
          margin: ${isFull ? 0 : spacing.s2} 0 0 -${isFull ? 0 : spacing.s2};
          border-radius: ${isFull ? '15px' : 0};
        `
      : css`
          max-width: 100%;
        `}
`;

export const Text = styled(BaseText)`
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const GreenText = styled(Text)`
  color: ${({theme}) => theme.palette.green500};
`;

export const PurpleText = styled(Text)`
  color: ${({theme}) => theme.color.purple};
  font-weight: 500;
`;

type TBreakProps = TTheme & {
  hasHeight: boolean;
};
export const Break = styled.div<TBreakProps>`
  flex-basis: 100%;
  height: ${({hasHeight}: TBreakProps) => (hasHeight ? 'auto' : 0)};
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: preview;
  align-self: flex-start;
  overflow: hidden;
  width: 100%;
  height: auto;
  ${mixins.flexAlign('center')}
  border-radius: ${({theme}) => theme.borderRadius.br2};
  ${({theme}) => css`
    padding: ${theme.spacing.s10};
    background-color: ${theme.color.beige};
  `};
`;
