const ACCOUNT_DIGITS_WEIGHTS = '71371371371371371371371'.split('').map(Number);

const isValidAccountDigits = (accountDigits: string) => {
  let sum = 0;

  for (let i = 0; i < accountDigits.length; i += 1) {
    sum += +accountDigits.charAt(i) * ACCOUNT_DIGITS_WEIGHTS[i];
  }

  return sum % 10 === 0;
};

const isValidBankAccount = (account: string | null, bic: string, isCorrAccount?: boolean) => {
  if (!account) {
    return false;
  }

  return (
    /^\d{20}$/.test(account) &&
    isValidAccountDigits(
      isCorrAccount || bic.substring(6, 8) === '00'
        ? `0${bic.substring(4, 6)}${account}`
        : bic.substring(6, 9) + account,
    )
  );
};

export {isValidBankAccount};
