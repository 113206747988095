import {matchPath} from 'react-router';

import PATHS from 'src/paths';

import {TNavigation} from './constants';

const isAllowedPath = (path: string, allowedPaths: string[]) =>
  allowedPaths.find((allowedPath) => matchPath(allowedPath, path)) || path === PATHS.SIGN_OUT;

export const filterAllowedPaths = (arr: TNavigation[], allowedPaths: string[]) => {
  const result: TNavigation[] = [];

  arr.forEach((item) => {
    if (item.href && isAllowedPath(item.href, allowedPaths)) {
      return result.push(item);
    }

    if (item.list) {
      const filteredList = item.list.filter(
        (listItem) => listItem.href && isAllowedPath(listItem.href, allowedPaths),
      );

      if (filteredList.length > 1) {
        return result.push({...item, list: filteredList});
      }

      if (filteredList[0]) {
        return result.push(filteredList[0]);
      }
    }

    return null;
  });

  return result;
};
