import {addMonths} from 'date-fns/addMonths';
import {startOfMonth} from 'date-fns/startOfMonth';

import formatDate from '@edna/utils/formatDate';

import {EChannelType, channelTypeHash} from 'src/constants';
import {TListItemId} from 'src/models';
import {rootApi} from 'src/models/api';

import {EPermission} from '../Auth/definitions';
import {TNextPaymentItems, TPaymentDebtItems} from './PaymentTab/definitions';
import {TLicense, TLicenseStatus} from './definitions';

type TNextPaymentPayload = {
  tenantId?: TListItemId;
  additionalMonths?: number;
  statuses?: TLicenseStatus[];
};

export const licenseApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const makePaymentDebtsQuery = (channelType?: EChannelType) =>
      builder.query<TPaymentDebtItems, TListItemId | void>({
        query: (tenantId) => ({
          method: 'GET',
          url: tenantId
            ? '/admin/billing/license/payment/debt/groups'
            : '/billing/license/payment/debt/groups',
          params: {
            channelType: channelType ? channelTypeHash[channelType].toUpperCase() : undefined,
            tenantId,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? [EPermission.PERMISSION_TENANT_TRANSACTION_READ]
              : [EPermission.PERMISSION_TRANSACTION_READ],
          },
        }),
      });

    const makeNextPaymentQuery = (channelType?: EChannelType) =>
      builder.query<TNextPaymentItems, TNextPaymentPayload | void>({
        query: ({tenantId, additionalMonths, statuses} = {}) => ({
          method: 'POST',
          url: tenantId
            ? '/admin/billing/license/payment/next/groups'
            : '/billing/license/payment/next/groups',
          params: {
            channelType: channelType ? channelTypeHash[channelType].toUpperCase() : undefined,
            tenantId,
          },
          data: {
            period: formatDate(startOfMonth(addMonths(new Date(), additionalMonths ?? 1)), {
              format: 'yyyy-MM',
            }),
            statuses,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? [EPermission.PERMISSION_TENANT_TRANSACTION_READ]
              : [EPermission.PERMISSION_TRANSACTION_READ],
          },
        }),
      });

    return {
      getAllPaymentDebts: makePaymentDebtsQuery(),
      getSmsPaymentDebts: makePaymentDebtsQuery(EChannelType.SMS),
      getWhatsappPaymentDebts: makePaymentDebtsQuery(EChannelType.WHATSAPP),
      getViberPaymentDebts: makePaymentDebtsQuery(EChannelType.VIBER),

      getAllNextPayment: makeNextPaymentQuery(),
      getSmsNextPayment: makeNextPaymentQuery(EChannelType.SMS),
      getWhatsappNextPayment: makeNextPaymentQuery(EChannelType.WHATSAPP),
      getViberNextPayment: makeNextPaymentQuery(EChannelType.VIBER),

      getAllLicenses: builder.query<TLicense[], TListItemId | void>({
        query: (tenantId) => ({
          method: 'GET',
          url: tenantId ? '/admin/billing/license' : '/billing/license',
          params: {
            tenantId,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? [EPermission.PERMISSION_TENANT_LICENSE_READ]
              : [EPermission.PERMISSION_READ],
          },
        }),
      }),
      cancelLicense: builder.mutation<void, {licenseId: TLicense['id']; tenantId?: TListItemId}>({
        query: ({licenseId, tenantId}) => ({
          method: 'PUT',
          url: tenantId
            ? `/admin/billing/license/${licenseId}/cancel`
            : `/billing/license/${licenseId}/cancel`,
          params: {
            tenantId,
          },
          meta: {
            isShowError: true,
            isShowSuccess: true,
            successMessageKey: 'Licenses:cancelModal.successBlock',
            permissions: tenantId ? [] : [EPermission.PERMISSION_LICENSE_BLOCK],
          },
        }),
      }),
    };
  },
});

export const {
  useGetAllPaymentDebtsQuery,
  useGetSmsPaymentDebtsQuery,
  useGetWhatsappPaymentDebtsQuery,
  useGetViberPaymentDebtsQuery,

  useGetAllNextPaymentQuery,
  useGetSmsNextPaymentQuery,
  useGetWhatsappNextPaymentQuery,
  useGetViberNextPaymentQuery,

  useGetAllLicensesQuery,

  useCancelLicenseMutation,
} = licenseApi;
