import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Button, Modal, ModalActions, ModalHeader} from '@edna/components';

import {SecondaryText} from 'src/components/primitives';

import * as S from './style';

type TProps = {
  title: string;
  titleOptions?: TAnyObject;
  message?: string;
  messageOptions?: TAnyObject;
  buttonText: string;
  closeModal: TEmptyFunction;
};

const FailDialog = React.memo<TProps>(
  ({closeModal, title, titleOptions, message, messageOptions, buttonText}) => {
    const {t} = useTranslation();

    return (
      <Modal width="465px">
        <ModalHeader>{t(title, titleOptions)}</ModalHeader>
        {message && (
          <SecondaryText>
            <Trans i18nKey={message} values={messageOptions} />
          </SecondaryText>
        )}
        <S.Image />
        <ModalActions>
          <Button appearance="primary" onClick={closeModal}>
            {t(buttonText)}
          </Button>
        </ModalActions>
      </Modal>
    );
  },
);

FailDialog.displayName = 'FailDialog';

export {FailDialog};
