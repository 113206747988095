import styled from 'styled-components';

import {NewNumberInputField as BaseNumberInputField} from '@edna/components';
import {mixins} from '@edna/components/styles';

export const TimeUnitFields = styled.div`
  ${mixins.stack({size: '5', direction: 'ROW', align: 'center'})}
  flex-wrap: wrap;
`;

export const Container = styled.div`
  ${mixins.stack({size: '2', direction: 'ROW', align: 'center'})}
`;

export const NumberInputField = styled(BaseNumberInputField)`
  width: 55px;
`;
