import {isAllOf, isAnyOf} from '@reduxjs/toolkit';

import {ECompanyDataErrors, TItemId, TTenantDependentPayload} from 'src/constants';
import {EPermission} from 'src/containers/Auth/definitions';
import {isMatchQueryMeta, listenerMiddleware, rootApi} from 'src/models/api';
import errorNotification from 'src/models/errorNotification';

import {TApiKeyData, TCallbackAuth, TCallbackUrl} from './definitions';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getApiKey: builder.query<TApiKeyData | undefined, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/apikey`
            : '/tenantmanagement/apikey/user',
        meta: {
          permissions:
            supportTenantId !== undefined ? [EPermission.PERMISSION_TENANT_APIKEY_READ] : [],
        },
      }),
    }),
    generateApiKey: builder.mutation<TApiKeyData, void>({
      query: () => ({
        method: 'POST',
        url: '/tenantmanagement/apikey/user/generate',
        meta: {isShowError: true},
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data: newApiKeyData} = await queryFulfilled;

          dispatch(api.util.updateQueryData('getApiKey', undefined, () => newApiKeyData));
        } catch {}
      },
    }),
    regenerateApiKey: builder.mutation<TApiKeyData, TTenantDependentPayload>({
      query: ({supportTenantId, data}) => ({
        method: 'PUT',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/apikey/${data.id}/regenerate`
            : `/tenantmanagement/apikey/user/regenerate/${data.id}`,
        meta: {isShowError: true},
      }),
      async onQueryStarted({supportTenantId}, {dispatch, queryFulfilled}) {
        try {
          const {data: newApiKeyData} = await queryFulfilled;

          dispatch(api.util.updateQueryData('getApiKey', supportTenantId, () => newApiKeyData));
        } catch {}
      },
    }),
    getAllCallbackUrls: builder.query<TCallbackUrl[] | undefined, TItemId | void>({
      query: (supportTenantId) => ({
        method: 'GET',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/callback/global/all`
            : '/tenantmanagement/callback/global/all',
        meta: {
          isShowError: true,
          permissions:
            supportTenantId !== undefined ? [EPermission.PERMISSION_TENANT_CALLBACK_READ] : [],
        },
      }),
    }),
    addCallbackUrl: builder.mutation<TCallbackUrl, TTenantDependentPayload>({
      query: ({supportTenantId, data}) => ({
        method: 'POST',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/callback`
            : '/tenantmanagement/callback',
        data,
      }),
      async onQueryStarted({supportTenantId}, {dispatch, queryFulfilled}) {
        try {
          const {data: newCallback} = await queryFulfilled;

          dispatch(
            api.util.updateQueryData('getAllCallbackUrls', supportTenantId, (draft) =>
              draft ? [...draft, newCallback] : [newCallback],
            ),
          );
        } catch {}
      },
    }),
    changeCallbackUrl: builder.mutation<TCallbackUrl, TTenantDependentPayload>({
      query: ({supportTenantId, data}) => ({
        method: 'PUT',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/callback/${data.id}`
            : `/tenantmanagement/callback/${data.id}`,
        data,
      }),
      async onQueryStarted({supportTenantId, data}, {dispatch, queryFulfilled}) {
        try {
          const {data: newCallback} = await queryFulfilled;

          dispatch(
            api.util.updateQueryData('getAllCallbackUrls', supportTenantId, (draft) => {
              if (draft) {
                const index = draft.findIndex((item) => item.id === data.id);

                draft[index] = {...newCallback};
              }
            }),
          );
        } catch {}
      },
    }),
    deleteCallbackUrl: builder.mutation<void, TTenantDependentPayload>({
      query: ({supportTenantId, data}) => ({
        method: 'DELETE',
        url:
          supportTenantId !== undefined
            ? `/tenantmanagement/tenant/${supportTenantId}/callback/${data.id}`
            : `/tenantmanagement/callback/${data.id}`,
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: `CompanyCallback:callbackUrlType.${data.type}.delete.notification`,
        },
      }),
      onQueryStarted({supportTenantId, data}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          api.util.updateQueryData('getAllCallbackUrls', supportTenantId, (draft) =>
            draft?.filter((item) => item.id !== data.id),
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getAuthCallback: builder.query<TCallbackAuth, void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/callback/auth',
      }),
    }),
    generateAuthCallback: builder.mutation<TCallbackAuth, void>({
      query: () => ({
        method: 'POST',
        url: '/tenantmanagement/callback/auth',
        data: {activated: true},
        meta: {isShowError: true},
      }),
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const {data: newCallback} = await queryFulfilled;

          dispatch(api.util.updateQueryData('getAuthCallback', undefined, () => newCallback));
        } catch {}
      },
    }),
    switchAuthCallback: builder.mutation<TCallbackAuth, TCallbackAuth>({
      query: ({id, activated}) => ({
        method: 'PATCH',
        url: `/tenantmanagement/callback/auth/${id}`,
        data: {activated},
        meta: {isShowError: true},
      }),
      onQueryStarted(patch, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          api.util.updateQueryData('getAuthCallback', undefined, (draft) => ({
            ...draft,
            activated: patch.activated,
          })),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getAuthCallbackToken: builder.query<string, TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/tenantmanagement/callback/auth/${id}/token`,
      }),
      transformResponse: (data: {token: string}) => data.token,
    }),
    generateAuthCallbackToken: builder.mutation<string, TItemId>({
      query: (id) => ({
        method: 'POST',
        url: `/tenantmanagement/callback/auth/${id}/token`,
        meta: {isShowError: true},
      }),
      transformResponse: (data: {token: string}) => data.token,
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          const {data: newToken} = await queryFulfilled;

          dispatch(api.util.updateQueryData('getAuthCallbackToken', id, () => newToken));
        } catch {}
      },
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: isAllOf(
    isMatchQueryMeta({isBackendError: true}),
    api.endpoints.getAuthCallback.matchRejected,
  ),
  effect: ({payload}) => {
    if (payload.status !== 404) {
      errorNotification(payload);
    }
  },
});

listenerMiddleware.startListening({
  matcher: isAllOf(isMatchQueryMeta({isBackendError: true}), api.endpoints.getApiKey.matchRejected),
  effect: ({payload}) => {
    if (payload.data.code !== ECompanyDataErrors.APIKEY_NOT_FOUND) {
      errorNotification(payload);
    }
  },
});

listenerMiddleware.startListening({
  matcher: isAllOf(
    isMatchQueryMeta({isBackendError: true}),
    isAnyOf(
      api.endpoints.changeCallbackUrl.matchRejected,
      api.endpoints.addCallbackUrl.matchRejected,
    ),
  ),
  effect: ({payload}) => {
    if (payload.data.code !== ECompanyDataErrors.CALLBACK_URL_INCORRECT) {
      errorNotification(payload);
    }
  },
});

export const {
  useGetApiKeyQuery,
  useGenerateApiKeyMutation,
  useRegenerateApiKeyMutation,
  useGetAllCallbackUrlsQuery,
  useAddCallbackUrlMutation,
  useChangeCallbackUrlMutation,
  useDeleteCallbackUrlMutation,
  useGetAuthCallbackQuery,
  useGenerateAuthCallbackMutation,
  useSwitchAuthCallbackMutation,
  useGetAuthCallbackTokenQuery,
  useGenerateAuthCallbackTokenMutation,
} = api;
