import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {endOfMonth} from 'date-fns/endOfMonth';
import {startOfDay} from 'date-fns/startOfDay';
import {startOfMonth} from 'date-fns/startOfMonth';

import {TValue as TPickerValue} from '@edna/components/PeriodPicker';

type TFilters = {
  period: {
    period: TPickerValue['period'];
    startDate: Date;
    stopDate: Date;
  };
};

const initialState = (): {filters: TFilters} => {
  const now = startOfDay(new Date());
  const startDate = startOfMonth(now);
  const stopDate = endOfMonth(now);

  return {
    filters: {
      period: {
        stopDate,
        startDate,
        period: 'range',
      },
    },
  };
};

const slice = createSlice({
  name: 'summaryReport',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TFilters>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = slice.actions;
export const selectFilters = (state: TRootState) => state.summaryReport.filters;

export default slice.reducer;
