export default {
  createInvoicePayment: {
    title: 'Generate payment invoice',
    amount: 'Enter the amount for which you would like to get an invoice',
    warning:
      'When paying, please take into account all third-party fees for transfers and currency exchange operations.',
    generateButton: 'Generate',
    insertSum: 'Insert sum',
  },
  stripeInvoice: {
    title: 'Pay by card with <0></0> payment system',
    topUp: 'Checkout',
    amount: 'Enter the amount',
    cancel: 'Cancel',
    warning:
      'Your payment will be credited to your current balance based on the offer. For any inquiries or issues, contact us at support@edna.io',
    content:
      'After pressing the “Checkout” button, you will be redirected to Stripe Checkout page to make your payment.',
  },
  invoiceGenerated: {
    title: 'Your invoice is ready',
    content: `We'll update your account balance when your payment is received. To download your invoice later, go to <0>Documents.</0>`,
    download: 'Download invoice',
  },
};
