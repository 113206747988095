import {EChannelType, TSubject, TTimeUnit} from 'src/constants';

export enum EStepDecisionType {
  TTL = 'TTL',
}

export type TEditFormTTL = Record<TTimeUnit, string>;

export type TDecision<TTL> = {
  type: EStepDecisionType.TTL;
  ttl: TTL;
};

export type TCyclicStage = {
  order: number;
  uuid: string;
  decision: TDecision<string> | null;
  subject: TSubject | null;
  stages: TCyclicStage[];
};

export type TCyclicStageEditForm = {
  order: number;
  uuid: string;
  decision: TDecision<Partial<TEditFormTTL>> | null;
  subject: TSubject | null;
  stages: TCyclicStageEditForm[];
};

export type TStageEditForm = Omit<TCyclicStageEditForm, 'stages'> & {
  stages: [];
};

export const TTL_CHANNELS = [
  EChannelType.WHATSAPP,
  EChannelType.VIBER,
  EChannelType.VK_NOTIFY,
  EChannelType.OK_NOTIFY,
  EChannelType.PUSH,
  EChannelType.SMS,
];
