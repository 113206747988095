import React from 'react';
import {useTranslation} from 'react-i18next';

import {EChannelType, EContentType} from 'src/constants';
import {TViberItemContent} from 'src/containers/MessageMatchers/types';
import {ImageIcon} from 'src/icons';
import {DocumentImage} from 'src/images';

import * as SS from '../style';
import {FOOTER_TIME} from '../utils';
import * as S from './style';

type TProps = {
  subject: string;
  contentType: EContentType | null;
  caption?: TViberItemContent['caption'];
  attachmentUrl?: string;
  title?: string | null;
  text: React.ReactNode;
};

const ViberContent = React.memo<TProps>(
  ({subject, contentType, caption, attachmentUrl, text, title}) => {
    const {t} = useTranslation();

    const renderContent = () => {
      switch (contentType) {
        case EContentType.TEXT:
          return text;

        case EContentType.IMAGE: {
          if (attachmentUrl) {
            return <SS.Image type={EChannelType.VIBER} src={attachmentUrl} isFull />;
          }

          return (
            <S.EmptyImage>
              <SS.Icon as={ImageIcon} />
            </S.EmptyImage>
          );
        }
        case EContentType.BUTTON:
          return (
            <>
              {text}
              {attachmentUrl && <SS.Image type={EChannelType.VIBER} src={attachmentUrl} />}
              {caption && <S.Button title={title ?? ''}>{caption}</S.Button>}
            </>
          );
        case EContentType.DOCUMENT:
          return (
            <>
              <S.Document src={DocumentImage} />
              {attachmentUrl}
              <S.Button>{t('PhonePreview:open')}</S.Button>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <>
        <S.Avatar>{subject[0].toUpperCase()}</S.Avatar>
        <S.Message noBackground={contentType === EContentType.IMAGE}>
          <SS.MessageContent noPaddings={contentType === EContentType.IMAGE} display="inline-block">
            {renderContent()}
            <SS.Time>
              <span>{FOOTER_TIME}</span>
            </SS.Time>
          </SS.MessageContent>
        </S.Message>
      </>
    );
  },
);

ViberContent.displayName = 'ViberContent';

export default ViberContent;
