import omit from 'lodash/omit';
import range from 'lodash/range';
import without from 'lodash/without';

import {TValue as TPickerValue} from '@edna/components/PeriodPicker';

import {TBadgeColor} from 'src/components';
import {EChannelType, EContentType, TChannelType} from 'src/constants';
import {TItem as TCascadesItem} from 'src/containers/Cascades/api';
import {TStageEditForm} from 'src/containers/Cascades/definitions';
import {TMenuButtonAction} from 'src/containers/MenuButton';
import {
  TItem as TMessageMatcherItem,
  TOkNotifyMessageMatchersContent,
  TPushMessageMatchersContent,
  TViberMessageMatchersContent,
  TVkNotifyMessageMatchersContent,
  TWhatsAppMessageMatchersContent,
} from 'src/containers/MessageMatchers/types';
import {ETrafficType} from 'src/containers/Report/types';
import {TListItemId} from 'src/models';

import {TItem as TTagsItem} from '../Tags/definitions';

export type {TStageEditForm};

export enum EStatus {
  DRAFT = 'DRAFT',
  CANCELING = 'CANCELING',
  EXPIRED = 'EXPIRED',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  SUSPENDED = 'SUSPENDED',
  TRIGGERED = 'TRIGGERED',
  RUNNING = 'RUNNING',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  SUSPEND_REQUESTED = 'SUSPEND_REQUESTED',
  SUSPENDING = 'SUSPENDING',
}

export enum ESummary {
  totalCount = 'totalCount',
  deliveredCount = 'deliveredCount',
  readCount = 'readCount',
  undeliveredCount = 'undeliveredCount',
}

export type TStatus = keyof typeof EStatus;

export type TItem = {
  id: TListItemId;
  name: string;
  startNow: boolean;
  cascadeId: TCascadesItem['id'] | null;
  startPlannedAt: string;
  dayTimePeriod: {
    start: string;
    end: string;
    timezoneDependent: boolean;
  };
  messageSpeed: number;
  createdAt: string;
  updatedAt: string;
  statusAt?: string;
  deadlineAt?: string;
  status: TStatus;
  channelAttribute: string;
  contents?: TItemContent[];
  tagQueryId: number | null;
  summary: TSummary | null;
  stageSummaries: TStageSummary[] | null;
  flowIntegration?: boolean;
  flowId?: string;
  comment?: string;
  timeZone?: string;
  additionalProperties?: {
    uploadId?: number;
  };
};

export type TStageSummary = {
  currentStageUUID: TStageEditForm['uuid'];
  sentCount: number | null;
  undeliveredCount: number | null;
  deliveredCount: number | null;
  readCount: number | null;
};

export type TSummary = Record<keyof typeof ESummary, number | null>;

export type TFormData = {
  id?: TItem['id'];
  name?: string;
  statusAt?: string;
  deadlineAt?: string;
  messageSpeed?: number;
  dayTimePeriod?: {
    start: string;
    end: string;
    timezoneDependent: boolean;
  } | null;
  startNow: boolean;
  startPlannedAtDate?: Date;
  startPlannedAtTime?: string;
  cascadeId: TCascadesItem['id'] | null;
  contents: TBroadcastContentForm[];
  tagQueryId: number | null;
  flowIntegration?: boolean;
  flowId?: string;
  comment?: string;
  timeZone?: string;
  additionalProperties?: {
    uploadId?: number;
  };
};

export enum EFormStep {
  SETTINGS = 'SETTINGS',
  AUDIENCE = 'AUDIENCE',
  CONTENT = 'CONTENT',
  CHECK = 'CHECK',
}

export type TStep = {
  id: EFormStep;
  disabled: boolean;
  submitType?: ESubmitType;
};

export type TTime = {
  timestamp: number;
  synchronized: number;
};

export type TBroadcastContentHolder = TMessageMatcherItem['content'] & {
  attachment?: TFile | null;
  caption?: string | null;
  action?: string | null;
};

export type TItemContent = {
  cascadeStageUUID: string;
  channelType: TChannelType;
  contentType: EContentType;
  contentHolder: TBroadcastContentHolder;
  messageMatcherId?: TItem['id'] | null;
};

export type TCommonForm = {
  cascadeStageUUID: string | null;
  recipient?: string | null;
};

export type TSMSForm = TCommonForm & {
  channelType: EChannelType.SMS;
  messageMatcherId: TItem['id'] | null;
  contentHolder: {
    text: string;
  };
};

export type TViberForm = TCommonForm & {
  channelType: EChannelType.VIBER;
  contentType: EContentType | null;
  messageMatcherId?: TItem['id'] | null;
  contentHolder: TViberMessageMatchersContent;
};

export type TWhatsAppForm = TCommonForm & {
  channelType: EChannelType.WHATSAPP;
  messageMatcherId?: TItem['id'] | null;
  language?: string;
  contentHolder: TWhatsAppMessageMatchersContent;
};

export type TPushForm = TCommonForm & {
  channelType: EChannelType.PUSH;
  messageMatcherId?: TItem['id'] | null;
  contentHolder: TPushMessageMatchersContent;
};

export type TVKNotifyForm = TCommonForm & {
  channelType: EChannelType.VK_NOTIFY;
  messageMatcherId?: TItem['id'] | null;
  contentHolder: TVkNotifyMessageMatchersContent;
};

export type TOkNotifyForm = TCommonForm & {
  channelType: EChannelType.OK_NOTIFY;
  messageMatcherId?: TItem['id'] | null;
  contentHolder: TOkNotifyMessageMatchersContent;
};

export type TBroadcastContentForm =
  | TSMSForm
  | TViberForm
  | TWhatsAppForm
  | TPushForm
  | TVKNotifyForm
  | TOkNotifyForm;

export type TFilters = {
  period: TPickerValue;
  channelTypes: Record<TChannelType, boolean>;
  statuses: Record<EStatus, boolean>;
  trafficTypes: Record<ETrafficType, boolean>;
  cascadeIds: number[];
  subjects: number[];
  isCommentFilled: boolean;
  tagIds?: TTagsItem[];
};

export const START_PLANNED_AT_CORRECTION = 180_000;

export const colorHash: Record<TStatus, TBadgeColor> = {
  DRAFT: 'BLUE',
  SCHEDULED: 'YELLOW',
  TRIGGERED: 'GREEN',
  RUNNING: 'GREEN',
  CANCEL_REQUESTED: 'RED',
  CANCELING: 'RED',
  CANCELLED: 'RED',
  EXPIRED: 'RED',
  COMPLETED: 'GREEN',
  INCOMPLETE: 'GRAY',
  SUSPEND_REQUESTED: 'YELLOW',
  SUSPENDING: 'YELLOW',
  SUSPENDED: 'YELLOW',
};

export const MESSAGE_SPEED_DEFAULT = 5;

export const MESSAGE_SPEEDS = range(1, MESSAGE_SPEED_DEFAULT + 1);

export const defaultInitialValues: TFormData = {
  name: '',
  startNow: true,
  messageSpeed: MESSAGE_SPEED_DEFAULT,
  cascadeId: null,
  tagQueryId: null,
  contents: [],
};

export const ONE_MINUTE_IN_SECONDS = 60;
export const FIFTEEN_MINUTES_IN_SECONDS = 15 * ONE_MINUTE_IN_SECONDS;

export const actionsHash: Record<TStatus, TMenuButtonAction[]> = {
  DRAFT: ['EDIT', 'DELETE', 'COPY'],
  SCHEDULED: ['EDIT', 'VIEW', 'COPY', 'CANCEL'],
  RUNNING: ['VIEW', 'CANCEL', 'COPY'],
  COMPLETED: ['VIEW', 'COPY'],
  CANCELLED: ['VIEW', 'COPY'],
  // are not described
  TRIGGERED: ['COPY'],
  CANCEL_REQUESTED: ['COPY'],
  CANCELING: ['COPY'],
  INCOMPLETE: ['COPY'],
  // are not done
  SUSPENDED: ['VIEW', 'COPY'],
  EXPIRED: ['COPY'],
  SUSPEND_REQUESTED: ['COPY'],
  SUSPENDING: ['COPY'],
};

const EDIT_STATUSES: TStatus[] = ['SCHEDULED', 'DRAFT'];
const VIEW_STATUSES = without(Object.values(EStatus), ...EDIT_STATUSES);

export const cancelStatusSet = new Set<TStatus>([EStatus.RUNNING]);
export const editStatusSet = new Set<TStatus>(EDIT_STATUSES);
export const viewStatusSet = new Set<TStatus>(VIEW_STATUSES);

export const filterStatuses = omit(
  EStatus,
  EStatus.TRIGGERED,
  EStatus.SUSPEND_REQUESTED,
  EStatus.INCOMPLETE,
  EStatus.SUSPENDING,
  EStatus.CANCEL_REQUESTED,
);

export enum ESubmitType {
  SAVE = 'SAVE',
  CONTINUE = 'CONTINUE',
  DEFAULT = 'DEFAULT',
  TEST = 'TEST',
}
