import {AxiosResponse} from 'axios';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {useExactSelector, useOnChange} from '@edna/hooks';

import {getDomainByLanguage} from 'src/constants';
import userModel from 'src/containers/Auth/userModel';
import {TApiKeyData} from 'src/containers/CompanyCallback/definitions';
import {useCallConsoleResourceMutation} from 'src/containers/Integration/api';
import {uuidv4} from 'src/utils';

import Headers from './Headers';
import * as S from './style';

enum ETabs {
  HEADERS = 'Headers',
  BODY = 'Body',
}

type TProps = {
  apiKeyData?: TApiKeyData;
  cascadeId?: string;
  testPhone?: string;
};

const Console = React.memo<TProps>(({apiKeyData, cascadeId = '', testPhone = ''}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState(ETabs.BODY);
  const [uuid, setUuid] = React.useState(uuidv4());
  const isOSKReseller = useExactSelector(userModel.selectors.isOSKReseller);

  const [callConsoleResource, {data: consoleResponse, error}] = useCallConsoleResourceMutation();
  const consoleError = error as AxiosResponse;

  const url = React.useMemo(
    () => `https://${getDomainByLanguage(isOSKReseller)}/api/cascade/schedule`,
    [isOSKReseller],
  );

  const initialBody = React.useMemo(
    () => `{
    "requestId": "${uuid}",
    "cascadeId": "${cascadeId}",
    "subscriberFilter": {
      "address": "${testPhone}",
      "type": "PHONE"
    },
    "content": {
      "whatsappContent": {
        "contentType": "TEXT",
        "text": "WhatsApp TEXT"
        }
    }
    }`,
    [cascadeId, testPhone, uuid],
  );

  const methods = useForm({
    mode: 'all',
    defaultValues: {body: initialBody},
  });

  useOnChange(() => methods.reset({body: initialBody}), [initialBody]);

  const handleReset = React.useCallback(() => {
    methods.reset();
    setUuid(uuidv4());
  }, [methods]);

  const handleClickTab = React.useCallback((value) => setActiveTab(value), []);
  const handleCallResource = React.useCallback(
    (values) => {
      callConsoleResource({
        body: values.body,
        apiKey: apiKeyData?.apiKey,
      });
    },
    [dispatch, apiKeyData],
  );

  return (
    <S.Container>
      <S.ChipContainer>
        <S.Chip>POST</S.Chip>
        <S.Text>{url}</S.Text>
      </S.ChipContainer>
      <FormProvider {...methods}>
        <S.Form onSubmit={methods.handleSubmit(handleCallResource)}>
          <S.ConsoleHeader>
            <S.Tabs>
              {Object.values(ETabs).map((tab) => (
                <S.Tab key={tab} active={tab === activeTab} onClick={() => handleClickTab(tab)}>
                  {tab}
                </S.Tab>
              ))}
            </S.Tabs>
            <S.Button onClick={handleReset}>{t('Integration:testing.row6.resetValues')}</S.Button>
          </S.ConsoleHeader>
          {activeTab === ETabs.HEADERS && <Headers apiKey={apiKeyData?.apiKey} />}
          {activeTab === ETabs.BODY && <S.TextareaField name="body" />}
          <S.CallButton type="submit">{t('Integration:testing.row6.callResource')}</S.CallButton>
        </S.Form>
      </FormProvider>
      {!!consoleError && (
        <>
          <S.Row>
            <S.ResponseLabel>Response</S.ResponseLabel>
            <S.CodeChip isError>{consoleError.status}</S.CodeChip>
          </S.Row>
          <S.Code code={JSON.stringify(consoleError.data)} language="javascript" />
        </>
      )}
      {!!consoleResponse && (
        <>
          <S.Row>
            <S.ResponseLabel>Response</S.ResponseLabel>
            <S.CodeChip>200</S.CodeChip>
          </S.Row>
          <S.Code code={JSON.stringify(consoleResponse)} language="javascript" />
        </>
      )}
    </S.Container>
  );
});

Console.displayName = 'Console';

export default Console;
