import {CustomHelpers} from 'joi';
import isEmpty from 'lodash/isEmpty';

import {prepareTimeUnit} from 'src/components/TimeUnitFields';
import {CHANNEL_TYPE, EChannelType, TSubject} from 'src/constants';
import {joiResolver} from 'src/utils/validator';

import {EStepDecisionType, TCyclicStage, TDecision, TStageEditForm} from '../definitions';

const prepareStage = (stage: TStageEditForm): Omit<TCyclicStage, 'stages'> => {
  const {decision} = stage;
  const newDecision: TDecision<string> | null = decision
    ? {
        ttl: prepareTimeUnit(decision.ttl),
        type: EStepDecisionType.TTL,
      }
    : null;

  return {
    ...stage,
    decision: newDecision,
  };
};

export const convertStagesArrayToTree = (stages: TStageEditForm[]) => {
  const {length} = stages;

  return [...stages].reverse().reduce(
    (result, stage, index) => ({
      ...prepareStage(stage),
      order: length - 1 - index,
      stages: isEmpty(result) ? [] : [result],
    }),
    {} as TCyclicStage,
  );
};

export const convertStagesTreeToArray = (stages: TCyclicStage[]): TStageEditForm[] => {
  if (stages.length === 0) {
    return [];
  }

  const result: TStageEditForm[] = [];
  const queue: TCyclicStage[] = [...stages];

  // breadth first search for level by level traversal in the future
  while (queue.length > 0) {
    const stage = queue.pop() as TCyclicStage;
    const parseTTL = stage.decision?.ttl.split(':');

    result.push({
      ...stage,
      decision:
        parseTTL?.length === 3
          ? {
              type: EStepDecisionType.TTL,
              ttl: {
                hours: parseTTL[0],
                minutes: parseTTL[1],
                seconds: parseTTL[2],
              },
            }
          : null,
    } as TStageEditForm);

    if (stage.stages) {
      queue.push(...stage.stages);
    }
  }

  return result;
};

const MIN_TTL_SECONDS: Record<string, number> = {
  [EChannelType.VIBER]: 30,
  [EChannelType.VK_NOTIFY]: 15,
  [EChannelType.OK_NOTIFY]: 15,
  [EChannelType.WHATSAPP]: 0,
  [EChannelType.SMS]: 0,
  [EChannelType.PUSH]: 0,
};

const ttlTimeRule = (value: number, helpers: CustomHelpers) => {
  const ttl = helpers.state.ancestors[0];

  if (parseInt(ttl?.hours) === 0 && parseInt(ttl?.minutes) === 0 && parseInt(ttl?.seconds) === 0) {
    return helpers.error('emptyTime');
  }

  return value;
};

export const makeValidator = (subjects: TSubject[]) =>
  joiResolver((joi) =>
    joi.object({
      name: joi.string().max(100).required(),
      stages: joi
        .array()
        .items(
          joi
            .object({
              order: joi.number().required(),
              subject: joi.object({id: joi.number().required()}).required(),
              decision: joi.object({
                ttl: joi.object({
                  hours: joi.number().max(23).required().custom(ttlTimeRule),
                  minutes: joi.number().max(59).required().custom(ttlTimeRule),
                  seconds: joi
                    .number()
                    .max(59)
                    .required()
                    .custom(ttlTimeRule)
                    .custom((value, helpers) => {
                      const stepIndex = Number(helpers.state.path?.[1]);
                      const prevStepChannelTypeId = stepIndex
                        ? helpers.state.ancestors[3][stepIndex - 1]?.subject?.id
                        : null;
                      const prevStepChannelType = subjects.find(
                        (subject) => subject.id === prevStepChannelTypeId,
                      )?.type;

                      if (!prevStepChannelType) {
                        return value;
                      }

                      const ttl = helpers.state.ancestors[0];
                      const secondsLimit = MIN_TTL_SECONDS[prevStepChannelType];

                      if (
                        parseInt(ttl?.hours) === 0 &&
                        parseInt(ttl?.minutes) === 0 &&
                        parseInt(ttl?.seconds) < secondsLimit
                      ) {
                        return helpers.error('ttlMinSeconds', {
                          limit: secondsLimit,
                          channelType: CHANNEL_TYPE[prevStepChannelType as EChannelType],
                        });
                      }

                      return value;
                    }),
                }),
              }),
            })
            .required(),
        )
        .min(1)
        .required(),
    }),
  );
