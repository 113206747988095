import React from 'react';

import {Icon, Stack} from '@edna/components/primitives';

import {FacebookIcon} from 'src/icons';

import * as S from './style';

type TProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  label?: string;
};

const FacebookButton = React.memo<TProps>(({label, isLoading, onClick}) => (
  <S.FacebookButton loading={isLoading} onClick={onClick}>
    <Stack direction="ROW" size="2" align="center">
      <Icon as={FacebookIcon} size="20px" />
      {label}
    </Stack>
  </S.FacebookButton>
));

export {FacebookButton};
