import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const Container = styled.div`
  ${mixins.flexAlign('normal', 'center')}
  ${({theme}) => css`
    color: ${theme.branding.content.secondary};
    gap: ${theme.spacing.s1};
    margin-left: ${theme.spacing.s2};
  `};
`;

export const SmallLink = styled.div`
  ${mixins.f2}
  cursor: pointer;
  text-decoration: underline;
`;
