import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type TState = {
  filters: TAnyObject;
};

const initialState: TState = {
  filters: {},
};

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = parametersSlice.actions;
export const selectFilters = (state: TRootState) => state.parameters.filters;

export default parametersSlice.reducer;
