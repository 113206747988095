import React from 'react';

import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
// dependencies for java
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-swift';

import * as S from './style';

type TProps = {
  code: string;
  language: string;
  isTab?: boolean;
  className?: string;
};

const Code = React.memo<TProps>(({className, code, language, isTab}) => {
  React.useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);

  return (
    <S.Code className={className}>
      <S.Pre isTab={isTab}>
        <code className={`language-${language}`}>{code}</code>
      </S.Pre>
    </S.Code>
  );
});

Code.displayName = 'Code';

export default Code;
