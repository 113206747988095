import {MAX_SIZE_MB} from './definitions';

export default {
  invalidImageFormat: 'Image upload error: allowed file format is {{formats}}',
  invalidImageSize: 'Image upload error: minimum image size is {{size}} MB',
  imageLabel: 'Add a picture',
  imageLabelHint: `(.jpeg, .jpg, .png the size of 400x400 pixels, ${MAX_SIZE_MB} MB max)`,
  uploadImage: 'Upload from computer',
  addImageLink: 'Insert link',
  imageUrlLabel: 'Insert link to image',
  imageUrlHint: `Supported image formats: JPEG, PNG. The optimal image size is 400x400 pixels, ${MAX_SIZE_MB} MB max`,
};
