import styled, {css} from 'styled-components';

import {Button as BaseButton} from '@edna/components';
import {mixins} from '@edna/components/styles';

import {Tabs as BaseTabs} from 'src/components';

export const Tabs = styled(BaseTabs)`
  margin-bottom: ${({theme}) => theme.spacing.s6};
`;

export const Section = styled.section`
  margin-bottom: ${({theme}) => theme.spacing.s5};
`;

export const Document = styled.div`
  ${mixins.row}
  min-width: 100%;
  ${({theme, theme: {spacing}}) => css`
    margin-top: ${spacing.s6};
    padding: ${spacing.s5};
    border-radius: ${theme.borderRadius.modal};
    box-shadow: ${theme.boxShadow.widget};
  `}
`;

export const DocumentImage = styled.div`
  min-width: 240px;
  ${mixins.flexAlign('center')}
`;

export const ChannelIcon = styled.div`
  ${mixins.wh('68px')};
  margin-right: ${({theme}) => theme.spacing.s5};
  &:last-child {
    margin-right: 0;
  }
`;

export const DocumentInfo = styled.div`
  ${mixins.column}
  ${mixins.flexAlign('start')}
`;

export const Button = styled(BaseButton)`
  margin-top: ${({theme}) => theme.spacing.s5};
`;
