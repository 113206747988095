import React from 'react';
import {useTranslation} from 'react-i18next';

import {Tab} from 'src/components';
import {EChannelType, EContentType} from 'src/constants';
import {TItem as TMessageMatchersItem} from 'src/containers/MessageMatchers/types';
import {EPushAppType} from 'src/containers/PushApps/definitions';
import {useToggle} from 'src/hooks';
import {AndroidCircleIcon, IOSCircleIcon} from 'src/icons';
import {AndroidImage, IOSImage} from 'src/images';

import InstagramContent from './InstagramContent';
import OkNotifyContent from './OkNotifyContent';
import PushContent from './PushContent';
import SmsContent from './SmsContent';
import Text from './Text';
import ViberContent from './ViberContent';
import VkNotifyContent from './VkNotifyContent';
import WhatsAppContent from './WhatsAppContent';
import * as S from './style';

type TProps = {
  isStickyPosition?: boolean;
  className?: string;
  channelType: TMessageMatchersItem['channelType'];
  content?: TMessageMatchersItem['content'];
  subject?: string;
  attachmentUrl?: string;
  headerAttachmentUrl?: string;
  contentType?: TMessageMatchersItem['contentType'] | null;
  type?: TMessageMatchersItem['type'] | null;
  language?: TMessageMatchersItem['language'];
};

const devices = [
  {type: EPushAppType.ANDROID, label: 'Android', icon: AndroidCircleIcon},
  {type: EPushAppType.IOS, label: 'iOS', icon: IOSCircleIcon},
];

const PhonePreview = React.memo<TProps>(
  ({
    className,
    type,
    channelType,
    attachmentUrl,
    headerAttachmentUrl,
    content = {},
    language,
    subject = 'Edna',
    contentType = EContentType.TEXT,
    isStickyPosition = false,
  }) => {
    const {t} = useTranslation();
    const [device, setDevice] = React.useState(EPushAppType.ANDROID);
    const isIOS = device === EPushAppType.IOS;
    const [isCollapsed, toggleCollapse] = useToggle(true);

    const text = React.useMemo(
      () => <Text text={content?.text} type={type} channelType={channelType} />,
      [content?.text, type, channelType],
    );

    return (
      <S.PreviewWrapper
        className={className}
        isSticky={isStickyPosition}
        {...edna.renderTestId(edna.TEST_IDS.PHONE_PREVIEW.CONTAINER)}
      >
        <S.Tabs>
          {devices.map(({type: deviceType, label, icon}) => {
            const active = deviceType === device;

            return (
              <Tab key={deviceType} active={active} onClick={() => setDevice(deviceType)}>
                <S.Device>
                  <S.DeviceIcon as={icon} />
                  {label}
                </S.Device>
              </Tab>
            );
          })}
        </S.Tabs>
        {channelType === EChannelType.PUSH && (
          <S.Switch
            checked={isCollapsed}
            label={<S.SwitchContent>{t('PhonePreview:expandedPush')}</S.SwitchContent>}
            onChange={toggleCollapse}
          />
        )}
        <S.Preview>
          <S.PreviewBackground as={isIOS ? IOSImage : AndroidImage} type={channelType} />
          <S.Content isIOS={isIOS}>
            <S.Body type={channelType}>
              {channelType === EChannelType.SMS && <SmsContent text={content?.text ?? ''} />}
              {channelType === EChannelType.OK_NOTIFY && <OkNotifyContent text={text} />}
              {channelType === EChannelType.VK_NOTIFY && (
                <VkNotifyContent text={text} content={content} />
              )}
              {channelType === EChannelType.VIBER && (
                <ViberContent
                  subject={subject}
                  contentType={contentType}
                  text={text}
                  attachmentUrl={attachmentUrl}
                  caption={content.caption}
                  title={content.action}
                />
              )}
              {channelType === EChannelType.WHATSAPP && (
                <WhatsAppContent
                  language={language}
                  attachmentUrl={attachmentUrl}
                  content={content}
                  type={type}
                />
              )}
              {channelType === EChannelType.INSTAGRAM && (
                <InstagramContent
                  contentType={contentType}
                  text={text}
                  attachmentUrl={attachmentUrl}
                />
              )}
              {channelType === EChannelType.PUSH && (
                <PushContent
                  content={content}
                  text={text}
                  collapsed={isCollapsed}
                  isIOS={isIOS}
                  attachmentUrl={attachmentUrl}
                  headerAttachmentUrl={headerAttachmentUrl}
                />
              )}
            </S.Body>
          </S.Content>
        </S.Preview>
      </S.PreviewWrapper>
    );
  },
);

PhonePreview.displayName = 'PhonePreview';

export default PhonePreview;
