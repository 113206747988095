import {TImageDimensions} from '@edna/components';

import {joi, joiResolver, mixed} from 'src/utils/validator';

import {EType, MAX_URL_LENGTH} from '../constants';
import {ETemplateButtonTypes, TVkNotifyMessageMatchersContent} from '../types';
import {commonSchemaStrict, operatorTextMaxRule, templateHashRule, userTextMaxRule} from '../utils';

const TEMPLATE_TEXT_MAX_LENGTH = 2048;

const vkNotifySchema = {
  ...commonSchemaStrict,
  content: {
    text: joi
      .string()
      .min(1)
      .custom(templateHashRule)
      .custom((value, helpers) => operatorTextMaxRule(value, helpers, TEMPLATE_TEXT_MAX_LENGTH))
      .required()
      .when('...type', {
        is: EType.USER,
        then: mixed.textWithVariables.custom((value, helpers) =>
          userTextMaxRule(value, helpers, TEMPLATE_TEXT_MAX_LENGTH),
        ),
      }),
    vkTwoWayEnabled: joi.boolean().required(),
    vkAttachments: joi.when('addAttachments', {
      is: true,
      then: joi.array().items(mixed.fileDTO(true)).required(),
    }),
    buttons: joi.when('addButtons', {
      is: true,
      then: joi
        .array()
        .items(
          joi
            .object({
              buttonType: joi.string().required(),
              payload: joi.string().max(255).required(),
            })
            .when('.buttonType', {
              not: ETemplateButtonTypes.LOCATION,
              then: {
                text: joi.string().max(40).required(),
              },
            })
            .when('.buttonType', {
              switch: [
                {
                  is: ETemplateButtonTypes.URL,
                  then: {
                    url: mixed.url.max(MAX_URL_LENGTH).required(),
                  },
                },
                {
                  is: ETemplateButtonTypes.VK_MINI_APPS,
                  then: {
                    appId: joi.any().required(),
                    ownerId: joi.any().required(),
                  },
                },
              ],
            }),
        )
        .required(),
    }),
  },
};

const vkNotifyValidate = joiResolver((payloadJoi) => payloadJoi.object(vkNotifySchema));

const FILE_ACCEPT = '.jpg, .png, .gif';
const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_DIMENSIONS_SUM = 14_000;
const MAX_ATTACHMENTS_LENGTH = 5;
const MAX_BUTTONS_LENGTH = 6;
const MAX_FILE_RATIO = 1 / 20;

const isFileValid = (
  payload: File,
  attachments?: TVkNotifyMessageMatchersContent['vkAttachments'],
  dimensions?: TImageDimensions,
) => {
  if (!attachments?.length) {
    return true;
  }

  const commonSize = attachments.reduce((size, file) => size + (file?.size ?? 0), 0);

  if (commonSize + payload.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    return false;
  }

  if (!dimensions) {
    return true;
  }

  return (
    dimensions.height + dimensions.width <= MAX_FILE_DIMENSIONS_SUM &&
    Math.min(dimensions.width, dimensions.height) / Math.max(dimensions.width, dimensions.height) >=
      MAX_FILE_RATIO
  );
};

const getEmptyAttachmentsIndexList = (
  targetIndex: number,
  attachments: TVkNotifyMessageMatchersContent['vkAttachments'],
) => {
  return (
    attachments
      ?.reduce(
        (array: number[], current: Partial<TFile>, currentIndex: number) =>
          current || currentIndex === targetIndex ? array : [...array, currentIndex],
        [targetIndex],
      )
      .concat(new Array(MAX_ATTACHMENTS_LENGTH - attachments.length).fill(-1)) ?? []
  );
};

export {
  TEMPLATE_TEXT_MAX_LENGTH,
  MAX_FILE_SIZE_MB,
  FILE_ACCEPT,
  MAX_FILE_DIMENSIONS_SUM,
  MAX_ATTACHMENTS_LENGTH,
  MAX_BUTTONS_LENGTH,
  getEmptyAttachmentsIndexList,
  isFileValid,
  vkNotifyValidate,
};
