import {EParameterType} from './definitions';

export default {
  parameter: 'Переменная',
  chosenParameters: 'Значения выбранных переменных',
  chosenParametersHint: 'Укажите значения переменных, которые будут применены в фильтре',
  choseParameters: 'Выбрать переменные: ',
  parameterValues: 'Значения переменной',
  findParameter: 'Найти значение переменной',
  availableParameters: 'Значения доступных переменных',
  empty: {
    title: 'Здесь пока пусто',
    nothingFound: 'Ничего не найдено',
  },
  assignDialog: {
    title: 'Присвоить переменную',
  },
  assignForm: {
    name: 'Название переменной',
    type: 'Тип переменной',
    value: 'Значение',
    create: 'Создать переменную',
  },
  editForm: {
    title: 'Добавить переменную',
    name: 'Введите название для переменной',
    valueType: 'Тип переменной',
  },
  removeDialog: {
    title: 'Удалить эту переменную?',
    text: 'Удаляя переменную, мы теряем крупицу информации о клиенте',
  },
  successDialog: {
    title: 'Ура, вы добавили переменную!',
    text: 'Теперь еще проще персонализировать ваше сообщение для этого пользователя',
    action: 'Класс',
  },
  valueType: {
    [EParameterType.STRING]: 'Строка',
    [EParameterType.BOOLEAN]: 'Булево значение',
    [EParameterType.DATE]: 'Дата',
    [EParameterType.NUMBER]: 'Число',
  },
  boolean: {
    true: '{{name}} (Есть)',
    false: '{{name}} (Нет)',
  },
};
