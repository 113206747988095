import keyBy from 'lodash/keyBy';

import {TPayload} from 'src/components/List/useList';
import {ESystemParameter, TItem as TParametersItem} from 'src/containers/Parameters/definitions';
import {isSystemParameter} from 'src/containers/Parameters/utils';

import {TItem as TTagsItem} from '../Tags/definitions';
import {mapBackendItemToItem} from '../Tags/utils';
import {
  EAddress,
  TAddressItem,
  TAttributesForSave,
  TEditFormData,
  TSubscriber,
  TSubscriberAttributes,
} from './definitions';

export const DEFAULT_INITIAL_VALUES: TEditFormData = {
  blacklisted: false,
  phone: null,
  systemParameters: {
    [ESystemParameter.FIRST_NAME]: '',
    [ESystemParameter.LAST_NAME]: '',
    [ESystemParameter.MIDDLE_NAME]: '',
    [ESystemParameter.GENDER]: null,
    [ESystemParameter.BIRTHDAY]: null,
    [ESystemParameter.PHONE]: '',
  },
  parameters: [],
  tags: [],
  addresses: [],
};

const findPhoneAddress = (addresses: TAddressItem[]) =>
  addresses.find((address) => address.type === EAddress.PHONE);

export const getInitialValues = (
  item: Optional<TSubscriber, 'id'> | null = null,
  attributes: TSubscriberAttributes = {
    parameters: [],
    tags: [],
    addresses: [],
  },
) => {
  if (item === null) {
    return {...DEFAULT_INITIAL_VALUES};
  }

  const result = {
    ...DEFAULT_INITIAL_VALUES,
    blacklisted: item.blacklisted,
    phone: findPhoneAddress(attributes.addresses)?.address ?? null,
    addresses: attributes.addresses,
    tags: attributes.tags,
  };

  attributes.parameters.forEach((parameter) => {
    if (isSystemParameter(parameter.code)) {
      // Необходимо для обновления initialValues, без этого форма не обновляется
      result.systemParameters = {
        ...result.systemParameters,
        [parameter.code]: parameter.value,
      };
    } else {
      result.parameters = [...result.parameters, parameter];
    }
  });

  return result;
};

const prepareSystemParams = (
  values: TEditFormData,
  initialValues: TParametersItem[],
  allParams: TParametersItem[],
) => {
  const result: TAttributesForSave['params'] = {
    create: [],
    update: [],
    delete: [],
  };
  const systemParamMap = keyBy(allParams, 'code');

  Object.values(ESystemParameter).forEach((field) => {
    const value = values.systemParameters[field];
    const initialValue = initialValues.find((parameter) => parameter.code === field)?.value;
    const systemParameter = systemParamMap[field];

    const isEmptyValue = value === null || value === undefined;
    const isEmptyInitialValue = initialValue === null || initialValue === undefined;

    if (!systemParameter || (isEmptyValue && isEmptyInitialValue)) {
      return;
    }

    if (!isEmptyValue && isEmptyInitialValue) {
      result.create.push({...systemParameter, value} as TParametersItem);
    } else if (isEmptyValue && !isEmptyInitialValue) {
      result.delete.push(systemParameter);
    } else if (value !== initialValue) {
      result.update.push({...systemParameter, value} as TParametersItem);
    }
  });

  return result;
};

const prepareParams = (
  values: TEditFormData,
  initialValues: TParametersItem[],
  allParams: TParametersItem[],
) => {
  const parameterMap = keyBy(
    values.parameters.filter((param) => !param.code),
    'id',
  );

  const initialParameterMap = keyBy(
    initialValues.filter((param) => !param.code),
    'id',
  );

  const result = prepareSystemParams(values, initialValues, allParams);

  const processParameter = (currentParameter: string[]) => {
    currentParameter.forEach((id) => {
      const parameter = parameterMap[id];
      const initialParameter = initialParameterMap[id];

      if (!parameter && initialParameter) {
        result.delete.push(initialParameter);
      }

      const isEmptyValue = parameter?.value === null || parameter?.value === undefined;
      const isEmptyInitialValue =
        initialParameter?.value === null || initialParameter?.value === undefined;

      if (isEmptyValue && isEmptyInitialValue) {
        return;
      } else if (!isEmptyValue && isEmptyInitialValue) {
        result.create.push(parameter);
      } else if (isEmptyValue && !isEmptyInitialValue) {
        result.delete.push(initialParameter);
      } else if (parameter && parameter?.value !== initialParameter?.value) {
        result.update.push(parameter);
      }
    });
  };

  processParameter(Object.keys(initialParameterMap));
  processParameter(Object.keys(parameterMap));

  return result;
};

const prepareTags = (values: TEditFormData, initialValues: TTagsItem[]) => {
  const result: TAttributesForSave['tags'] = {
    create: [],
    delete: [],
  };

  const tagsMap = keyBy(values.tags, 'id');
  const initialTagsMap = keyBy(initialValues, 'id');

  Object.keys(initialTagsMap).forEach((id) => {
    if (tagsMap[id]) {
      delete tagsMap[id];
    } else {
      result.delete.push(initialTagsMap[id]);
    }
  });
  result.create = [...result.create, ...Object.values(tagsMap)];

  return result;
};

const prepareAddresses = (values: TEditFormData, initialValues: TAddressItem[]) => {
  const result: TAttributesForSave['addresses'] = {
    create: [],
    update: [],
  };
  const {addresses, phone} = values;
  const phoneAddressId = findPhoneAddress(addresses)?.id;
  const initialPhone = findPhoneAddress(initialValues)?.address;

  if (phone !== initialPhone) {
    const newPhone = {address: phone, type: EAddress.PHONE, info: null};

    if (phoneAddressId) {
      result.update.push({id: phoneAddressId, ...newPhone});
    } else {
      result.create.push(newPhone);
    }
  }

  // TODO добавить обновление полей для типов: EAddress.INSTAGRAM_ID и EAddress.DEVICE_APP_ID

  return result;
};

type TData = {
  values: TEditFormData;
  initialTags: TTagsItem[];
  initialAddresses: TAddressItem[];
  initialParams: TParametersItem[];
  allParams: TParametersItem[];
};

export const prepareAttributesForSave = ({
  values,
  initialAddresses,
  initialParams,
  initialTags,
  allParams,
}: TData) => ({
  params: prepareParams(values, initialParams, allParams),
  tags: prepareTags(values, initialTags),
  addresses: prepareAddresses(values, initialAddresses),
});

export const prepareFilters = (payload: TPayload) => ({
  ...payload,
  tags: payload.tags?.map((tag: any) => tag.id),
  variables: payload.parameters,
});

export const prepareActiveItemAttributes = ({
  parametersResult,
  tagsResult,
  addressesResult,
}: TAnyObject) =>
  ({
    parameters: parametersResult ?? [],
    tags: tagsResult ? tagsResult.map(mapBackendItemToItem) : [],
    addresses: addressesResult ?? [],
  }) as TSubscriberAttributes;
