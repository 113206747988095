import axios from 'axios';

type TRequestFnArgs = any[];
type TRequestFnResult = {
  result?: any;
  error?: any;
};
type TRequestFn = (...args: TRequestFnArgs) => Promise<TRequestFnResult>;

const safeCall =
  (fn: TAnyFunction): TRequestFn =>
  async (...args) => {
    try {
      const result = await fn(...args);

      return {result};
    } catch (error) {
      return {error};
    }
  };

const axiosInstance = axios.create();

const request = {
  defaults: axiosInstance.defaults,
  interceptors: axiosInstance.interceptors,
  request: axiosInstance.request,

  get: safeCall(axiosInstance.get),
  head: safeCall(axiosInstance.head),
  delete: safeCall(axiosInstance.delete),
  post: safeCall(axiosInstance.post),
  put: safeCall(axiosInstance.put),
  patch: safeCall(axiosInstance.patch),
};

export {axiosInstance};
export default request;
