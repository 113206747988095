import {createSlice} from '@reduxjs/toolkit';

import storage from '@edna/utils/storage';

import {EStorageKeys} from 'src/constants';
import {listenerMiddleware} from 'src/models/api';

export type TState = {
  isServiceAvailable: boolean;
  isMenuPinned: boolean;
};

const initialState = () => {
  const isMenuOpen = storage.get(EStorageKeys.MENU_OPEN);

  return {
    isServiceAvailable: true,
    isMenuPinned: isMenuOpen ? isMenuOpen === 'true' : true,
  };
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleMenuPinned(state) {
      state.isMenuPinned = !state.isMenuPinned;
    },
    setServiceAvailability(state, {payload}) {
      state.isServiceAvailable = payload;
    },
  },
});

export const {toggleMenuPinned, setServiceAvailability} = slice.actions;
export const selectIsServiceAvailable = (state: TRootState) => state.app.isServiceAvailable;
export const selectIsMenuPinned = (state: TRootState) => state.app.isMenuPinned;

listenerMiddleware.startListening({
  actionCreator: toggleMenuPinned,
  effect: (_, listenerApi) => {
    const isMenuPinned = selectIsMenuPinned(listenerApi.getState());

    storage.set(EStorageKeys.MENU_OPEN, String(isMenuPinned));
  },
});

export default slice.reducer;
