import {TData, TPayload} from 'src/components/List';
import {TItemId} from 'src/constants';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models/api';

import {TSupportClient} from './definitions';

const prepareFilters = (payload: TAnyObject) => {
  const {period, ...other} = payload;

  return {
    ...other,
    createdAtTo: period.stopDate,
    createdAtFrom: period.startDate,
  };
};

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupportClients: builder.query<TData<TSupportClient>, TPayload>({
      query: (data) => ({
        method: 'POST',
        url: `/tenantmanagement/tenant/list`,
        data: prepareFilters(data),
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: (result) => {
        if (!result) {
          return [ECacheTag.SUPPORT_CLIENTS];
        }

        return [
          ECacheTag.SUPPORT_CLIENTS,
          ...result.content.map(({id}) => ({id, type: ECacheTag.SUPPORT_CLIENTS})),
        ];
      },
    }),
    getSupportClient: builder.query<TSupportClient, TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/tenantmanagement/tenant/${id}/data`,
        meta: {isShowError: true, errorMessageKey: 'List:errors.failedToLoadItem'},
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.SUPPORT_CLIENTS}],
    }),
    blockSupportClient: builder.mutation<void, {id: TItemId; startAt: Date; endAt: Date}>({
      query: (data) => ({
        method: 'POST',
        url: `/tenantmanagement/tenant/${data.id}/temporary-block`,
        data,
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: 'ClientStatus:notifications.successBlock',
        },
      }),
      invalidatesTags: (_, __, data) => [{type: ECacheTag.SUPPORT_CLIENTS, id: data.id}],
    }),
    unblockSupportClient: builder.mutation<void, TItemId>({
      query: (id) => ({
        method: 'POST',
        url: `/tenantmanagement/tenant/${id}/temporary-block/running/unblock`,
        meta: {
          isShowError: true,
          isShowSuccess: true,
          successMessageKey: 'ClientStatus:notifications.successUnblock',
        },
      }),
      invalidatesTags: (_, __, id) => [{type: ECacheTag.SUPPORT_CLIENTS, id}],
    }),
  }),
});

export const {
  useGetSupportClientsQuery,
  useGetSupportClientQuery,
  useBlockSupportClientMutation,
  useUnblockSupportClientMutation,
} = api;
