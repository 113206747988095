import React from 'react';

const useInterval = (callback: TEmptyFunction, interval: number) => {
  const intervalRef = React.useRef<number | undefined>();

  const start = React.useCallback(() => {
    window.clearInterval(intervalRef.current);
    intervalRef.current = window.setInterval(callback, interval);
  }, [callback, interval]);

  const stop = React.useCallback(() => {
    window.clearInterval(intervalRef.current);
  }, []);

  return [start, stop];
};

export default useInterval;
