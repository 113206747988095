import React from 'react';

import {useTheme} from 'styled-components';

import WarningIcon from '@edna/icons/warning.svg';

import {
  CancelIcon,
  CancelledIcon,
  DoubleCheckCircleIcon,
  ErrorIcon,
  InfoIcon,
  OkContourIcon,
  OkIcon,
  PendingIcon,
  UploadIcon,
} from 'src/icons';

enum EStatusIcon {
  cancel = 'cancel',
  cancelled = 'cancelled',
  error = 'error',
  ok = 'ok',
  okContour = 'okContour',
  read = 'read',
  pending = 'pending',
  info = 'info',
  warning = 'warning',
  loading = 'loading',
}

export type TStatusIcon = keyof typeof EStatusIcon;

type TProps = {
  name: TStatusIcon;
  className?: string;
  size?: string;
};

const iconComponentHash: Record<TStatusIcon, TSvgComponentType> = {
  ok: OkIcon,
  okContour: OkContourIcon,
  info: InfoIcon,
  cancel: CancelIcon,
  cancelled: CancelledIcon,
  pending: PendingIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  read: DoubleCheckCircleIcon,
  loading: UploadIcon,
};

const StatusIcon = React.memo<TProps>(({className, name, size = '20px'}) => {
  const {palette, branding} = useTheme();
  const iconColorHash: Record<TStatusIcon, string> = {
    info: branding.colors.info,
    cancelled: branding.content.secondary,
    cancel: palette.red400,
    error: palette.red400,
    ok: branding.colors.success,
    okContour: branding.colors.success,
    read: branding.content.primary,
    pending: branding.colors.warning,
    warning: branding.colors.warning,
    loading: branding.content.primary,
  };

  const Component = iconComponentHash[name];

  if (!Component) {
    return null;
  }

  return (
    <Component
      className={className}
      style={{color: iconColorHash[name], width: size, height: size, flexShrink: 0}}
    />
  );
});

StatusIcon.displayName = 'StatusIcon';

export default StatusIcon;
