import React from 'react';
import {useTranslation} from 'react-i18next';

import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import last from 'lodash/last';
import noop from 'lodash/noop';

import {type EButtonAppearance, type TSize, showNewNotification} from '@edna/components';
import {newFormField} from '@edna/components/FormField/hoc';
import {Icon} from '@edna/components/primitives';

import {UploadIcon as BaseUploadIcon} from 'src/icons';

import * as S from './style';

type TValue = File | null;

type THandler = (value: TValue) => void;

type TProps = {
  name?: string;
  isInvalid?: boolean;
  accept?: string;
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  label: React.ReactNode;
  value?: TFile | File | null;
  onUpload?: (value: File) => void;
  onFocus?: THandler;
  onBlur?: THandler;
  onChange?: THandler;
  onClick?: THandler;
  size?: TSize;
  appearance?: keyof typeof EButtonAppearance;
  icon?: TAnyComponent;
  uploadedLabel?: React.ReactNode;
  isShowOnlyLabel?: boolean;
  acceptExtensions?: string[];
};

const getFileName = (file: TProps['value']): string | null => {
  if (!file) {
    return null;
  }

  return file instanceof File ? file.name : file.originalFileName;
};

// TODO: Нужно переписать FilePicker и убрать логику подставления "имени файла" - т.к цитата Виты: "оно может быть большим, а кнопка от этого уродливо разрастается"
// TODO: и сразу проверить все места, где FilePicker используется
// TODO: uploadedLabel и isShowOnlyLabel это временный костыль для учета всех ситуаций
const FilePicker = React.memo<TProps>(
  ({
    className,
    name,
    label,
    accept = '*',
    loading = false,
    disabled = false,
    readOnly = false,
    onUpload,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onClick = noop,
    value,
    isInvalid,
    size = 'M',
    appearance,
    icon: UploadIcon = BaseUploadIcon,
    uploadedLabel,
    isShowOnlyLabel,
    acceptExtensions,
  }) => {
    const {t} = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [text, setText] = React.useState(getFileName(value) ?? label);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {files} = event.target;

      if (files?.length) {
        const file = files[0];
        const extension = `.${last(file.name.split('.'))}`;

        if (acceptExtensions && !acceptExtensions.includes(extension)) {
          showNewNotification({
            type: 'error',
            message: t('FilePicker:invalidFileFormat', {formats: acceptExtensions?.join(', ')}),
          });

          return;
        }

        if (isFunction(onUpload)) {
          onUpload(file);
        } else {
          onChange(file);
          setText(uploadedLabel ?? file.name);
        }
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    React.useEffect(() => {
      if (!uploadedLabel && value && getFileName(value) !== text) {
        setText(getFileName(value));
      }
    }, [text, uploadedLabel, value, isShowOnlyLabel]);

    return (
      <S.LoadingButton
        forwardedAs="label"
        className={className}
        htmlFor={name}
        loading={loading}
        isInvalid={isInvalid}
        size={size}
        appearance={appearance}
        disabled={disabled}
      >
        <input
          hidden
          id={name}
          type="file"
          accept={accept}
          onChange={handleChange}
          disabled={disabled || loading || readOnly}
          onClick={() => onClick(value)}
          onBlur={() => onBlur(value)}
          onFocus={() => onFocus(value)}
          ref={inputRef}
        />
        <S.Label>
          <Icon as={UploadIcon} size="20px" />
          {isShowOnlyLabel ? label : isString(text) ? <S.Text title={text}>{text}</S.Text> : text}
        </S.Label>
      </S.LoadingButton>
    );
  },
);

FilePicker.displayName = 'FilePicker';

export {FilePicker};

export const FilePickerOldField = newFormField<TProps>(FilePicker, {
  showHeader: false,
});
