import React from 'react';
import {useTranslation} from 'react-i18next';

import noop from 'lodash/noop';

import {TOptionProps} from '@edna/components';
import {Head as BaseHead} from '@edna/components/Select/style';
import {TSelectOption} from '@edna/components/Select/types';
import {EPlacementY, TPlacementY} from '@edna/components/types';
import ENFlag from '@edna/country-flags/country-flags/GB.svg';
import RUFlag from '@edna/country-flags/country-flags/RU.svg';

import {ELanguage} from 'src/constants';

import * as S from './style';

const FLAG: Record<any, TSvgComponentType> = {
  ru: RUFlag,
  en: ENFlag,
};

const LABEL: Record<any, string> = {
  ru: 'Русский',
  en: 'English',
};

const Option = React.memo<TOptionProps>(({value, label = value, onClick = noop}) => {
  const handleClick = React.useCallback(() => onClick(value), [onClick, value]);

  return <div onClick={handleClick}>{label}</div>;
});

Option.displayName = 'Option';

const options = Object.values(ELanguage).map((lang) => ({
  value: lang,
  label: LABEL[lang],
}));

type TProps = {
  className?: string;
  placementY?: TPlacementY;
  readOnly?: boolean;
};

const Language = React.memo<TProps>(
  ({className, placementY = EPlacementY.bottom, readOnly = false}) => {
    const {i18n} = useTranslation();

    const renderLabel = React.useCallback(
      ({option}: {option?: TSelectOption<ELanguage>}) =>
        option?.value && (
          <S.Label
            key={option?.value}
            data-selector={`Language:select.${option.value}`}
            placementY={placementY}
          >
            <S.Flag as={FLAG[option.value]} />
            {LABEL[option.value]}
          </S.Label>
        ),
      [placementY],
    );

    return (
      <S.Select
        readOnly={readOnly}
        className={className}
        placementY={placementY}
        renderLabel={renderLabel}
        optionComponent={Option}
        options={options}
        value={i18n.language}
        onChange={i18n.changeLanguage}
        selector="Language:select"
        headComponent={placementY === EPlacementY.bottom ? S.Head : BaseHead}
      />
    );
  },
);

Language.displayName = 'Language';

export default Language;
