import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {skipToken} from '@reduxjs/toolkit/query/react';

import {
  Button,
  Checkbox,
  LoadingButton,
  Modal,
  ModalActions,
  ModalHeader,
  showModal,
} from '@edna/components';
import {Stack} from '@edna/components/primitives';
import copyToClipBoard from '@edna/utils/copyToClipBoard';

import {Message} from 'src/components';
import {BigText, LeftButton, SecondaryText} from 'src/components/primitives';

import {
  useGenerateAuthCallbackMutation,
  useGenerateAuthCallbackTokenMutation,
  useGetAuthCallbackQuery,
  useGetAuthCallbackTokenQuery,
  useSwitchAuthCallbackMutation,
} from '../api';
import * as SS from '../style';

type TModalProps = {
  closeModal: TEmptyFunction;
  token: string;
};

const CallbackKeyModal = React.memo<TModalProps>(({closeModal, token}) => {
  const {t} = useTranslation();

  return (
    <Modal width="530px">
      <ModalHeader>{t('CompanyCallback:copyCallbackKey')}</ModalHeader>
      <Message type="Warning" fontSize="S">
        {t('CompanyCallback:newCallbackKeyHint')}
      </Message>
      <BigText>{token}</BigText>
      <ModalActions>
        <LeftButton onClick={closeModal} data-selector="Common:button.cancel">
          {t('Common:button.cancel')}
        </LeftButton>
        <LoadingButton
          appearance="primary"
          onClick={() => copyToClipBoard(token)}
          data-selector="Common:button.copy"
        >
          <Stack size="2" direction="ROW">
            <SS.CopyIcon />
            {t('Common:button.copy')}
          </Stack>
        </LoadingButton>
      </ModalActions>
    </Modal>
  );
});

CallbackKeyModal.displayName = 'CallbackKeyModal';

const showCallbackKeyModal = (token: string) =>
  showModal((closeModal) => <CallbackKeyModal closeModal={closeModal} token={token} />);

const AuthCallback = React.memo(() => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id: supportTenantId} = useParams<'id'>();

  const {data: authCallback} = useGetAuthCallbackQuery();
  const {data: authCallbackToken} = useGetAuthCallbackTokenQuery(authCallback?.id ?? skipToken, {
    skip: !authCallback?.activated,
  });
  const [generateAuthCallback] = useGenerateAuthCallbackMutation();
  const [switchAuthCallback] = useSwitchAuthCallbackMutation();
  const [generateAuthCallbackToken] = useGenerateAuthCallbackTokenMutation();

  const handleGenerateKey = React.useCallback(async () => {
    if (!authCallback?.id) {
      return;
    }

    try {
      const token = await generateAuthCallbackToken(authCallback.id).unwrap();

      showCallbackKeyModal(token);
    } catch {}
  }, [dispatch, authCallback]);

  const handleChangeAuthority = React.useCallback(
    (event) => {
      if (authCallback?.id) {
        const activated = event.target.checked;

        switchAuthCallback({id: authCallback.id, activated});

        return;
      }

      generateAuthCallback();
    },
    [dispatch, authCallback],
  );

  return (
    <SS.Content>
      <BigText>{t('CompanyCallback:callbackAuth')}</BigText>
      {!!authCallbackToken && authCallback?.activated && <BigText>{authCallbackToken}</BigText>}
      <Stack size="4">
        <SecondaryText>{t('CompanyCallback:callbackAuthHint')}</SecondaryText>
        <Checkbox checked={!!authCallback?.activated} onChange={handleChangeAuthority}>
          {t('CompanyCallback:callbackAuthSwitch')}
        </Checkbox>
        {authCallback?.activated && !supportTenantId && (
          <Button
            appearance="secondary"
            onClick={handleGenerateKey}
            data-selector="CompanyCallback:generateNewCallbackKey"
          >
            {t('CompanyCallback:generateNewCallbackKey')}
          </Button>
        )}
      </Stack>
    </SS.Content>
  );
});

AuthCallback.displayName = 'authCallback';

export default AuthCallback;
