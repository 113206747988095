import {unique} from 'src/utils';

const HIGHLIGHTER_PADDING = 10;
const HIGHLIGHT_CLASSNAME = 'onboarding-highlight';
const HIGHLIGHT_ATTRIBUTE = 'data-onboarding-id';
const ANIMATION_DURATION = 300;

enum EPosition {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
}

enum ETour {
  COMPANY_PROFILE = 'COMPANY_PROFILE',
}

const ONBOARDING_IDS = {
  [ETour.COMPANY_PROFILE]: {
    BALANCE: unique('BALANCE'),
    LICENSES: unique('LICENSES'),
    PAYMENT_TAB: unique('PAYMENT_TAB'),
    LICENSE_TAB: unique('LICENSE_TAB'),
    REQUISITES_TAB: unique('REQUISITES_TAB'),
    PRICING_TAB: unique('PRICING_TAB'),
  },
};

type TStep = {
  content: React.ReactNode;
  onboardingId: string;
  position: EPosition;
};

type TTour = {
  steps: TStep[];
};

export {
  TTour,
  TStep,
  ONBOARDING_IDS,
  HIGHLIGHT_CLASSNAME,
  HIGHLIGHT_ATTRIBUTE,
  HIGHLIGHTER_PADDING,
  ANIMATION_DURATION,
  EPosition,
  ETour,
};
