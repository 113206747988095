import {TFacebookSDKConfig} from 'src/components';

export const INSTAGRAM_EXISTS_ERROR = 'instagram-profile.already-exists';

export const PERMISSIONS = [
  'pages_show_list', // предоставляет приложению доступ к списку Страниц, которыми управляет пользователь
  'instagram_basic', // позволяет просматривать информацию профиля и медиафайлы в аккаунте Instagram
  'instagram_manage_messages', // позволяет пользователям бизнес-аккаунтов просматривать сообщения в Instagram Direct и отвечать на них
  'pages_manage_metadata', // позволяет приложению подписываться на события Webhooks, связанные с действиями на Странице
];

export const facebookSDKConfig: TFacebookSDKConfig = {
  DEV_APP_ID: '551622619491225',
  PROD_APP_ID: '335859811455391',
};
