export enum EMessageAlign {
  RTL = 'RTL',
  LTR = 'LTR',
}

type TLocalization = {
  language?: string;
  code: string;
  baseText: string;
  securityRecommendationText: string;
  passwordExpirationText: string;
  copyButtonText: string;
  autoButtonText: string;
  align: keyof typeof EMessageAlign;
};

const authTemplateLocalization: TLocalization[] = [
  {
    language: 'Afrikaans',
    code: 'af',
    baseText: '{{1}} is u verifikasiekode.',
    securityRecommendationText: 'Moenie hierdie kode vir u sekuriteit deel nie.',
    passwordExpirationText: 'Hierdie kode verval binne {{count}} minute.',
    copyButtonText: 'Kopie -kode',
    autoButtonText: 'Outofill',
    align: 'LTR',
  },
  {
    language: 'Albanian',
    code: 'sq',
    baseText: '{{1}} është kodi juaj i verifikimit.',
    securityRecommendationText: 'Për sigurinë tuaj, mos e ndani këtë kod.',
    passwordExpirationText: 'Ky kod skadon në {{count}} minuta.',
    copyButtonText: 'Kopjoj',
    autoButtonText: 'Autopil',
    align: 'LTR',
  },
  {
    language: 'Arabic',
    code: 'ar',
    baseText: '{{1}} هو كود التحقق الخاص بك.',
    securityRecommendationText: 'للحفاظ على أمانك، لا تشارك هذا الكود مع أي شخص.',
    passwordExpirationText: 'تنتهي صلاحية هذا الكود خلال ‏١٠‏ من الدقائق.',
    copyButtonText: 'نسخ الكود',
    autoButtonText: 'ملء تلقائي',
    align: 'RTL',
  },
  {
    language: 'Azerbaijani',
    code: 'az',
    baseText: '{{1}} Sizin Doğrulama Kodunuzdur.',
    securityRecommendationText: 'Təhlükəsizliyiniz üçün bu kodu paylaşmayın.',
    passwordExpirationText: 'Bu kod {{count}} dəqiqədən sonra başa çatır.',
    copyButtonText: 'Kopyalamaq',
    autoButtonText: 'Avtofl',
    align: 'LTR',
  },
  {
    language: 'Bengali',
    code: 'bn',
    baseText: 'আপনার যাচাইকরণের কোড {{1}}',
    securityRecommendationText: '। আপনার নিরাপত্তার জন্য এই কোড শেয়ার করবেন না।',
    passwordExpirationText: 'এই কোডের মেয়াদ {{count}} মিনিটের মধ্যে শেষ হবে।',
    copyButtonText: 'কোড কপি করুন',
    autoButtonText: 'অটোফিল করুন',
    align: 'RTL',
  },
  {
    language: 'Bulgarian',
    code: 'bg',
    baseText: '{{1}} е вашият код за проверка.',
    securityRecommendationText: 'За вашата сигурност не споделяйте този код.',
    passwordExpirationText: 'Този код изтича за {{count}} минути.',
    copyButtonText: 'Копирайте код',
    autoButtonText: 'Автоматично заливане',
    align: 'LTR',
  },
  {
    language: 'Catalan',
    code: 'ca',
    baseText: '{{1}} és el vostre codi de verificació.',
    securityRecommendationText: 'Per a la vostra seguretat, no compartiu aquest codi.',
    passwordExpirationText: 'Aquest codi caduca en {{count}} minuts.',
    copyButtonText: 'Codi de còpia',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Chinese (CHN)',
    code: 'zh_CN',
    baseText: '{{1}}  是你的验证码。',
    securityRecommendationText: '为安全起见，请不要分享这组验证码。',
    passwordExpirationText: '这组验证码将在 {{count}} 分钟后过期',
    copyButtonText: '复制验证码',
    autoButtonText: '自动填写',
    align: 'LTR',
  },
  {
    language: 'Chinese (HKG)',
    code: 'zh_HK',
    baseText: '{{1}}  是你的验证码。',
    securityRecommendationText: '为安全起见，请不要分享这组验证码。',
    passwordExpirationText: '这组验证码将在 {{count}} 分钟后过期',
    copyButtonText: '复制验证码',
    autoButtonText: '自动填写',
    align: 'LTR',
  },
  {
    language: 'Chinese (TAI)',
    code: 'zh_TW',
    baseText: '{{1}}  是你的验证码。',
    securityRecommendationText: '为安全起见，请不要分享这组验证码。',
    passwordExpirationText: '这组验证码将在 {{count}} 分钟后过期',
    copyButtonText: '复制验证码',
    autoButtonText: '自动填写',
    align: 'LTR',
  },
  {
    language: 'Croatian',
    code: 'hr',
    baseText: '{{1}} je vaš kôd za provjeru.',
    securityRecommendationText: 'Za svoju sigurnost ne dijelite ovaj kôd.',
    passwordExpirationText: 'Ovaj kôd ističe za {{count}} minuta.',
    copyButtonText: 'Kopiranje',
    autoButtonText: 'Automatsko preusmjeravanj',
    align: 'LTR',
  },
  {
    language: 'Czech',
    code: 'cs',
    baseText: '{{1}} je váš ověřovací kód.',
    securityRecommendationText: 'Pro vaše zabezpečení tento kód nesdílejte.',
    passwordExpirationText: 'Tento kód vyprší za {{count}} minut.',
    copyButtonText: 'Kopírovat kód',
    autoButtonText: 'Automatické vyplnění',
    align: 'LTR',
  },
  {
    language: 'Danish',
    code: 'da',
    baseText: '{{1}} er din verifikationskode.',
    securityRecommendationText: 'For din sikkerhed skal du ikke dele denne kode.',
    passwordExpirationText: 'Denne kode udløber på {{count}} minutter.',
    copyButtonText: 'Kopier kode',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Dutch',
    code: 'nl',
    baseText: '{{1}} is uw verificatiecode.',
    securityRecommendationText: 'Deel deze code voor uw beveiliging niet.',
    passwordExpirationText: 'Deze code verloopt in {{count}} minuten.',
    copyButtonText: 'Kopieercode',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'English',
    code: 'en',
    baseText: '{{1}} is your verification code.',
    securityRecommendationText: 'For your security, do not share this code.',
    passwordExpirationText: 'This code expires in {{count}} minutes.',
    copyButtonText: 'Copy code',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'English (UK)',
    code: 'en_GB',
    baseText: '{{1}} is your verification code.',
    securityRecommendationText: 'For your security, do not share this code.',
    passwordExpirationText: 'This code expires in {{count}} minutes.',
    copyButtonText: 'Copy code',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'English (US)',
    code: 'en_US',
    baseText: '{{1}} is your verification code.',
    securityRecommendationText: 'For your security, do not share this code.',
    passwordExpirationText: 'This code expires in {{count}} minutes.',
    copyButtonText: 'Copy code',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Estonian',
    code: 'et',
    baseText: '{{1}} on teie kinnituskood.',
    securityRecommendationText: 'Teie turvalisuse huvides ärge jagage seda koodi.',
    passwordExpirationText: 'See kood aegub {{count}} minutiga.',
    copyButtonText: 'Koopiakood',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Filipino',
    code: 'fil',
    baseText: '{{1}} ang iyong verification code.',
    securityRecommendationText: 'Para sa iyong seguridad, huwag ibahagi ang code na ito.',
    passwordExpirationText: 'Ang code na ito ay nag -expire sa loob ng {{count}} minuto.',
    copyButtonText: 'Kopyahin ang code',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Finnish',
    code: 'fi',
    baseText: '{{1}} on vahvistuskoodisi.',
    securityRecommendationText: 'Älä jaa tätä koodia turvallisuutta varten.',
    passwordExpirationText: 'Tämä koodi vanhenee {{count}} minuutissa.',
    copyButtonText: 'Kopio',
    autoButtonText: 'Automaatti',
    align: 'LTR',
  },
  {
    language: 'French',
    code: 'fr',
    baseText: 'Votre code de vérification est {{1}}.',
    securityRecommendationText: 'Pour votre sécurité, ne le partagez pas.',
    passwordExpirationText: 'Ce code expire dans {{count}} minutes.',
    copyButtonText: 'Copier le code',
    autoButtonText: 'Saisie automatique',
    align: 'LTR',
  },
  {
    language: 'Georgian',
    code: 'ka',
    baseText: '{{1}} არის თქვენი გადამოწმების კოდი.',
    securityRecommendationText: 'თქვენი უსაფრთხოებისთვის, არ გაუზიაროთ ეს კოდი.',
    passwordExpirationText: 'ეს კოდი იწურება {{count}} წუთში.',
    copyButtonText: 'დააკოპირეთ კოდი',
    autoButtonText: 'ავტოფილი',
    align: 'LTR',
  },
  {
    language: 'German',
    code: 'de',
    baseText: '{{1}} ist Ihr Verifizierungscode.',
    securityRecommendationText: 'Teilen Sie diesen Code für Ihre Sicherheit nicht.',
    passwordExpirationText: 'Dieser Code läuft in {{count}} Minuten ab.',
    copyButtonText: 'Code kopieren',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Greek',
    code: 'el',
    baseText: '{{1}} είναι ο κωδικός επαλήθευσης.',
    securityRecommendationText: 'Για την ασφάλειά σας, μην μοιράζεστε αυτόν τον κωδικό.',
    passwordExpirationText: 'Αυτός ο κωδικός λήγει σε {{count}} λεπτά.',
    copyButtonText: 'Κώδικας αντιγραφής',
    autoButtonText: 'Αυτόματο',
    align: 'LTR',
  },
  {
    language: 'Gujarati',
    code: 'gu',
    baseText: '{{1}} એ તમારો ચકાસણી કોડ છે.',
    securityRecommendationText: 'તમારી સુરક્ષા માટે, આ કોડ શેર કરશો નહીં.',
    passwordExpirationText: 'આ કોડ {{count}} મિનિટમાં સમાપ્ત થાય છે.',
    copyButtonText: 'નકલ -સંહિતા',
    autoButtonText: 'સ્વચાલિત',
    align: 'LTR',
  },
  {
    language: 'Hausa',
    code: 'ha',
    baseText: '{{1}} Shin lambar tabbaci ce.',
    securityRecommendationText: 'Don amincin ku, kar a raba wannan lambar.',
    passwordExpirationText: 'Wannan lambar ta ƙare cikin minti {{count}}.',
    copyButtonText: 'Lambar code',
    autoButtonText: 'M',
    align: 'LTR',
  },
  {
    language: 'Hebrew',
    code: 'he',
    baseText: '{{1}} הוא קוד האימות שלך.',
    securityRecommendationText: 'לצורך האבטחה שלך, אל תשתף את הקוד הזה.',
    passwordExpirationText: 'קוד זה יפוג תוך {{count}} דקות.',
    copyButtonText: 'העתק קוד',
    autoButtonText: 'מילוי אוטומטי',
    align: 'RTL',
  },
  {
    language: 'Hindi',
    code: 'hi',
    baseText: '{{1}} आपका वेरिफ़िकेशन कोड है.',
    securityRecommendationText: 'अपनी सुरक्षा के लिए, इस कोड को किसी के साथ शेयर न करें.',
    passwordExpirationText: 'ऑटोमैटिकली भरें',
    copyButtonText: 'कोड कॉपी करें',
    autoButtonText: 'ऑटोमैटिकली भरें',
    align: 'LTR',
  },
  {
    language: 'Hungarian',
    code: 'hu',
    baseText: 'Az {{1}} az Ön ellenőrzési kódja.',
    securityRecommendationText: 'Biztonsága érdekében ne ossza meg ezt a kódot.',
    passwordExpirationText: 'Ez a kód {{count}} perc alatt lejár.',
    copyButtonText: 'Másoló kód',
    autoButtonText: 'Automatikus feltöltés',
    align: 'LTR',
  },
  {
    language: 'Indonesian',
    code: 'id',
    baseText: '{{1}} adalah kode verifikasi Anda.',
    securityRecommendationText: 'Demi keamanan, jangan bagikan kode ini.',
    passwordExpirationText: 'Kode ini kedaluwarsa dalam {{count}} menit.',
    copyButtonText: 'Salin kode',
    autoButtonText: 'Isi otomatis',
    align: 'LTR',
  },
  {
    language: 'Irish',
    code: 'ga',
    baseText: 'Is é {{1}} do chód fíoraithe.',
    securityRecommendationText: 'Maidir le do shlándáil, ná roinn an cód seo.',
    passwordExpirationText: 'Rachaidh an cód seo in éag i {{count}} nóiméad.',
    copyButtonText: 'Cóipchód',
    autoButtonText: 'Uathfhill',
    align: 'LTR',
  },
  {
    language: 'Italian',
    code: 'it',
    baseText: '{{1}} è il codice di verifica.',
    securityRecommendationText: 'Per la tua sicurezza, non condividere questo codice.',
    passwordExpirationText: 'Questo codice scade in {{count}} minuti.',
    copyButtonText: 'Codice di copia',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Japanese',
    code: 'ja',
    baseText: '{{1}}は検証コードです。',
    securityRecommendationText: 'あなたのセキュリティのために、このコードを共有しないでください。',
    passwordExpirationText: 'このコードは{{count}}分で期限切れになります。',
    copyButtonText: 'コードをコピーします',
    autoButtonText: 'オートフィル',
    align: 'LTR',
  },
  {
    language: 'Kannada',
    code: 'kn',
    baseText: '{{1}} ನಿಮ್ಮ ಪರಿಶೀಲನಾ ಸಂಕೇತವಾಗಿದೆ.',
    securityRecommendationText: 'ನಿಮ್ಮ ಸುರಕ್ಷತೆಗಾಗಿ, ಈ ಕೋಡ್ ಅನ್ನು ಹಂಚಿಕೊಳ್ಳಬೇಡಿ.',
    passwordExpirationText: 'ಈ ಕೋಡ್ {{count}} ನಿಮಿಷಗಳಲ್ಲಿ ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ.',
    copyButtonText: 'ನಕಲು ಕೋಡ್',
    autoButtonText: 'ಆಟೋಫಿಲ್',
    align: 'LTR',
  },
  {
    language: 'Kazakh',
    code: 'kk',
    baseText: '{{1}} - бұл сіздің растау кодыңыз.',
    securityRecommendationText: 'Сіздің қауіпсіздігіңіз үшін осы кодты бөліспеңіз.',
    passwordExpirationText: 'Бұл код {{count}} минутта аяқталады.',
    copyButtonText: 'Код көшірмесі',
    autoButtonText: 'Жүктеп алу',
    align: 'LTR',
  },
  {
    language: 'Kinyarwanda',
    code: 'rw_RW',
    baseText: '{{1}} ni code yawe yo kugenzura.',
    securityRecommendationText: 'Kubwumutekano wawe, ntugasangire iyi code.',
    passwordExpirationText: 'Iyi kode irangira muminota {{count}}.',
    copyButtonText: 'Kopi',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Korean',
    code: 'ko',
    baseText: '{{1}}은 귀하의 확인 코드입니다.',
    securityRecommendationText: '보안을 위해이 코드를 공유하지 마십시오.',
    passwordExpirationText: '이 코드는 {{count}} 분 안에 만료됩니다.',
    copyButtonText: '코드 복사',
    autoButtonText: '자동 제',
    align: 'LTR',
  },
  {
    language: 'Kyrgyz (Kyrgyzstan)',
    code: 'ky_KG',
    baseText: '{{1}} Сиздин текшерүү кодуңуз.',
    securityRecommendationText: 'Сиздин коопсуздугуңуз үчүн, бул кодду бөлүшпөңүз.',
    passwordExpirationText: 'Бул код {{count}} мүнөттө аяктайт.',
    copyButtonText: 'Кодду көчүрүү',
    autoButtonText: 'AutoFill',
    align: 'LTR',
  },
  {
    language: 'Lao',
    code: 'lo',
    baseText: '{{1}} ແມ່ນລະຫັດຢືນຢັນຂອງທ່ານ.',
    securityRecommendationText: 'ເພື່ອຄວາມປອດໄພຂອງທ່ານ, ຢ່າແບ່ງປັນລະຫັດນີ້.',
    passwordExpirationText: 'ລະຫັດນີ້ຫມົດອາຍຸໃນ {{count}} ນາທີ.',
    copyButtonText: 'ສໍາເນົາ',
    autoButtonText: 'ອັດຕະໂນມັດ',
    align: 'LTR',
  },
  {
    language: 'Latvian',
    code: 'lv',
    baseText: '{{1}} ir jūsu verifikācijas kods.',
    securityRecommendationText: 'Jūsu drošībai nesadalieties ar šo kodu.',
    passwordExpirationText: 'Šis kods beidzas {{count}} minūtēs.',
    copyButtonText: 'Kopēt kodu',
    autoButtonText: 'Autofilona',
    align: 'LTR',
  },
  {
    language: 'Lithuanian',
    code: 'lt',
    baseText: '{{1}} yra jūsų patvirtinimo kodas.',
    securityRecommendationText: 'Savo saugumui nedalykite šio kodo.',
    passwordExpirationText: 'Šis kodas pasibaigs per {{count}} minučių.',
    copyButtonText: 'Kopijuoti kodą',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Macedonian',
    code: 'mk',
    baseText: '{{1}} е вашиот код за верификација.',
    securityRecommendationText: 'За ваша безбедност, не го споделувајте овој код.',
    passwordExpirationText: 'Овој код истекува за {{count}} минути.',
    copyButtonText: 'Копија код',
    autoButtonText: 'Автоматско пополнување',
    align: 'LTR',
  },
  {
    language: 'Malay',
    code: 'ms',
    baseText: '{{1}} adalah kod pengesahan anda.',
    securityRecommendationText: 'Untuk keselamatan anda, jangan kongsi kod ini.',
    passwordExpirationText: 'Kod ini tamat dalam {{count}} minit.',
    copyButtonText: 'Kod salin',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Malayalam',
    code: 'ml',
    baseText: '{{1}} നിങ്ങളുടെ സ്ഥിരീകരണ കോഡാണ്.',
    securityRecommendationText: 'നിങ്ങളുടെ സുരക്ഷയ്ക്കായി, ഈ കോഡ് പങ്കിടരുത്.',
    passwordExpirationText: 'ഈ കോഡ് {{count}} മിനിറ്റിനുള്ളിൽ കാലഹരണപ്പെടും.',
    copyButtonText: 'കോപ്പി കോഡ്',
    autoButtonText: 'ഓട്ടോഫിൽ',
    align: 'LTR',
  },
  {
    language: 'Marathi',
    code: 'mr',
    baseText: '{{1}} आपला सत्यापन कोड आहे.',
    securityRecommendationText: 'आपल्या सुरक्षिततेसाठी, हा कोड सामायिक करू नका.',
    passwordExpirationText: 'हा कोड {{count}} मिनिटांत कालबाह्य होतो.',
    copyButtonText: 'कॉपी कोड',
    autoButtonText: 'ऑटोफिल',
    align: 'LTR',
  },
  {
    language: 'Norwegian',
    code: 'nb',
    baseText: '{{1}} er bekreftelseskoden din.',
    securityRecommendationText: 'For din sikkerhet, ikke del denne koden.',
    passwordExpirationText: 'Denne koden går ut på {{count}} minutter.',
    copyButtonText: 'Kopier kode',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Persian',
    code: 'fa',
    baseText: '{{1}} کد تأیید شماست.',
    securityRecommendationText: 'برای امنیت خود ، این کد را به اشتراک نگذارید.',
    passwordExpirationText: 'این کد در {{count}} دقیقه منقضی می شود.',
    copyButtonText: 'رمز',
    autoButtonText: 'خودرو',
    align: 'RTL',
  },
  {
    language: 'Polish',
    code: 'pl',
    baseText: '{{1}} to kod weryfikacyjny.',
    securityRecommendationText: 'Dla swojego bezpieczeństwa nie udostępniaj tego kodu.',
    passwordExpirationText: 'Ten kod wygasa w ciągu {{count}} minut.',
    copyButtonText: 'KOD KOPIOWY',
    autoButtonText: 'AUTOFILL',
    align: 'LTR',
  },
  {
    language: 'Portuguese (BR)',
    code: 'pt_BR',
    baseText: 'Seu código de verificação é {{1}}.',
    securityRecommendationText: 'Para sua segurança, não o compartilhe.',
    passwordExpirationText: 'Este código expira em {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Preenchimento automático',
    align: 'LTR',
  },
  {
    language: 'Portuguese (POR)',
    code: 'pt_PT',
    baseText: 'Seu código de verificação é {{1}}.',
    securityRecommendationText: 'Para sua segurança, não o compartilhe.',
    passwordExpirationText: 'Este código expira em {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Preenchimento automático',
    align: 'LTR',
  },
  {
    language: 'Punjabi',
    code: 'pa',
    baseText: '{{1}} ਤੁਹਾਡਾ ਤਸਦੀਕ ਕੋਡ ਹੈ.',
    securityRecommendationText: 'ਤੁਹਾਡੀ ਸੁਰੱਖਿਆ ਲਈ, ਇਸ ਕੋਡ ਨੂੰ ਸਾਂਝਾ ਨਾ ਕਰੋ.',
    passwordExpirationText: 'ਇਹ ਕੋਡ {{count}} ਮਿੰਟਾਂ ਵਿੱਚ ਖਤਮ ਹੋ ਰਿਹਾ ਹੈ.',
    copyButtonText: 'ਕਾਪੀ ਕੋਡ',
    autoButtonText: 'ਆਟੋਫਿਲ',
    align: 'LTR',
  },
  {
    language: 'Romanian',
    code: 'ro',
    baseText: '{{1}} este codul dvs. de verificare.',
    securityRecommendationText: 'Pentru securitatea dvs., nu partajați acest cod.',
    passwordExpirationText: 'Acest cod expiră în {{count}} minute.',
    copyButtonText: 'Cod cod',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Russian',
    code: 'ru',
    baseText: 'Ваш код подтверждения - {{1}}.',
    securityRecommendationText: 'Из соображений безопасности не сообщайте никому этот код.',
    passwordExpirationText: 'Срок действия этого кода истекает через {{count}} минут.',
    copyButtonText: 'Копировать код',
    autoButtonText: 'Заполнить автоматически',
    align: 'LTR',
  },
  {
    language: 'Serbian',
    code: 'sr',
    baseText: '{{1}} је ваш верификациони код.',
    securityRecommendationText: 'За вашу сигурност не делите овај код.',
    passwordExpirationText: 'Овај код истиче за {{count}} минута.',
    copyButtonText: 'Копирање копија',
    autoButtonText: 'Аутоматско копирање',
    align: 'LTR',
  },
  {
    language: 'Slovak',
    code: 'sk',
    baseText: '{{1}} je váš overovací kód.',
    securityRecommendationText: 'Pokiaľ ide o svoju bezpečnosť, nezdieľajte tento kód.',
    passwordExpirationText: 'Platnosť tohto kódu vyprší o {{count}} minút.',
    copyButtonText: 'Kópia',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Slovenian',
    code: 'sl',
    baseText: '{{1}} je vaša koda za preverjanje.',
    securityRecommendationText: 'Zaradi vaše varnosti ne delite te kode.',
    passwordExpirationText: 'Ta koda poteče v {{count}} minutah.',
    copyButtonText: 'Kodiraj kopiranja',
    autoButtonText: 'Samodejno',
    align: 'LTR',
  },
  {
    language: 'Spanish',
    code: 'es',
    baseText: 'Tu código de verificación es {{1}}.',
    securityRecommendationText: 'Por tu seguridad, no lo compartas.',
    passwordExpirationText: 'Este código caduca en {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Autocompletar',
    align: 'LTR',
  },
  {
    language: 'Spanish (ARG)',
    code: 'es_AR',
    baseText: 'Tu código de verificación es {{1}}.',
    securityRecommendationText: 'Por tu seguridad, no lo compartas.',
    passwordExpirationText: 'Este código caduca en {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Autocompletar',
    align: 'LTR',
  },
  {
    language: 'Spanish (SPA)',
    code: 'es_ES',
    baseText: 'Tu código de verificación es {{1}}.',
    securityRecommendationText: 'Por tu seguridad, no lo compartas.',
    passwordExpirationText: 'Este código caduca en {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Autocompletar',
    align: 'LTR',
  },
  {
    language: 'Spanish (MEX)',
    code: 'es_MX',
    baseText: 'Tu código de verificación es {{1}}.',
    securityRecommendationText: 'Por tu seguridad, no lo compartas.',
    passwordExpirationText: 'Este código caduca en {{count}} minutos.',
    copyButtonText: 'Copiar código',
    autoButtonText: 'Autocompletar',
    align: 'LTR',
  },
  {
    language: 'Swahili',
    code: 'sw',
    baseText: '{{1}} ni nambari yako ya uthibitisho.',
    securityRecommendationText: 'Kwa usalama wako, usishiriki nambari hii.',
    passwordExpirationText: 'Nambari hii inaisha katika dakika {{count}}.',
    copyButtonText: 'Nakala ya Nakala',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Swedish',
    code: 'sv',
    baseText: '{{1}} är din verifieringskod.',
    securityRecommendationText: 'För din säkerhet, dela inte den här koden.',
    passwordExpirationText: 'Denna kod löper ut på {{count}} minuter.',
    copyButtonText: 'Kopieringskod',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Tamil',
    code: 'ta',
    baseText: '{{1}} என்பது உங்கள் சரிபார்ப்புக் குறியீடு.',
    securityRecommendationText: 'உங்கள் பாதுகாப்பிற்காக, இந்த குறியீட்டைப் பகிர வேண்டாம்.',
    passwordExpirationText: 'இந்த குறியீடு {{count}} நிமிடங்களில் காலாவதியாகிறது.',
    copyButtonText: 'குறியீட்டை நகலெடுக்கவும்',
    autoButtonText: 'ஆட்டோஃபில்',
    align: 'LTR',
  },
  {
    language: 'Telugu',
    code: 'te',
    baseText: '{{1}} మీ ధృవీకరణ కోడ్.',
    securityRecommendationText: 'మీ భద్రత కోసం, ఈ కోడ్‌ను భాగస్వామ్యం చేయవద్దు.',
    passwordExpirationText: 'ఈ కోడ్ {{count}} నిమిషాల్లో ముగుస్తుంది.',
    copyButtonText: 'కాపీ కోడ్',
    autoButtonText: 'ఆటోఫిల్',
    align: 'LTR',
  },
  {
    language: 'Thai',
    code: 'th',
    baseText: '{{1}} เป็นรหัสการยืนยันของคุณ',
    securityRecommendationText: 'เพื่อความปลอดภัยของคุณอย่าแชร์รหัสนี้',
    passwordExpirationText: 'รหัสนี้จะหมดอายุใน {{count}} นาที',
    copyButtonText: 'รหัสคัดลอก',
    autoButtonText: 'การทำอัตโนมัติ',
    align: 'LTR',
  },
  {
    language: 'Turkish',
    code: 'tr',
    baseText: '{{1}} doğrulama kodunuzdur.',
    securityRecommendationText: 'Güvenliğiniz için bu kodu paylaşmayın.',
    passwordExpirationText: 'Bu kod {{count}} dakika içinde sona erer.',
    copyButtonText: 'Kopya Kodu',
    autoButtonText: 'Otomatik doldurma',
    align: 'LTR',
  },
  {
    language: 'Ukrainian',
    code: 'uk',
    baseText: '{{1}} - ваш код підтвердження.',
    securityRecommendationText: 'Для вашої безпеки не діліться цим кодом.',
    passwordExpirationText: 'Цей код закінчується через {{count}} хвилин.',
    copyButtonText: 'Копіювати код',
    autoButtonText: 'Автофільт',
    align: 'LTR',
  },
  {
    language: 'Urdu',
    code: 'ur',
    baseText: '{{1}}‏ آپ کا توثیقی کوڈ ہے۔',
    securityRecommendationText: 'اپنی حفاظت کی خاطر یہ کوڈ شیئر نہ کریں۔',
    passwordExpirationText: 'یہ کوڈ ‏{{count}}‏ منٹ میں زائد المیعاد ہو جائے گا۔',
    copyButtonText: 'کوڈ کاپی کریں',
    autoButtonText: 'آٹو میٹک طریقے سے بھریں',
    align: 'RTL',
  },
  {
    language: 'Uzbek',
    code: 'uz',
    baseText: '{{1}} - bu sizning tasdiqlash kodingiz.',
    securityRecommendationText: 'Xavfsizligingiz uchun ushbu kodni ulashmang.',
    passwordExpirationText: 'Ushbu kod muddati {{count}} daqiqada tugaydi.',
    copyButtonText: 'Kodni nusxalash',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
  {
    language: 'Vietnamese',
    code: 'vi',
    baseText: '{{1}} là mã xác minh của bạn.',
    securityRecommendationText: 'Đối với bảo mật của bạn, không chia sẻ mã này.',
    passwordExpirationText: 'Mã này hết hạn trong {{count}} phút.',
    copyButtonText: 'Sao chép mã',
    autoButtonText: 'Tự động điền',
    align: 'LTR',
  },
  {
    language: 'Zulu',
    code: 'zu',
    baseText: 'I-{{1}} yikhodi yakho yokuqinisekisa.',
    securityRecommendationText: 'Ngokuphepha kwakho, ungahlanganyeli le khodi.',
    passwordExpirationText: 'Le khodi iphelelwa yisikhathi ngemizuzu eyi-{{count}}.',
    copyButtonText: 'Kopisha ikhodi',
    autoButtonText: 'Autofill',
    align: 'LTR',
  },
];

export {authTemplateLocalization, TLocalization};
export default authTemplateLocalization;
