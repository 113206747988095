export default {
  allUser: 'All clients',
  blackList: 'Black list',
  editForm: {
    title: 'Add tag',
    name: 'Enter the name for your new tag',
    categoryId: 'Category ID',
    categoryIdPlaceHolder: 'Select a category',
  },
  tree: {
    categories: 'Categories',
    chosenCategoryTags: 'Tags of the selected category',
    chosenTags: 'Selected tags',
    switcherLabel: 'Show:',
    switcherCloud: 'Tag cloud',
    switcherCategories: 'Tag categories',
    addTag: 'tag',
    findTag: 'Find a tag',
    availableTags: 'Available tags',
    selectTagsFromTree: 'Select tags from the tree',
    chosenTagsHint: 'Enter the tags to be applied in the filter',
    info: 'Select tags for audience',
  },
  empty: {
    searchTitle: "Sorry, we didn't find this tag",
    filterTitle: 'There are no tags in this category yet',
    searchSubtitle: 'Check if there is something similar in the tag tree or create a new tag',
    filterSubtitle: 'But you can create them!',
    createAction: 'new tag',
    backAction: 'To the tree',
  },
  dialog: {
    remove: {
      title: 'Remove the client tag?',
      body: 'Remember that by deleting a client tag you permanently delete information about the client from our database.',
    },
  },
};
