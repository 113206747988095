import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {Button} from '@edna/components';
import {H1} from '@edna/components/primitives';

import * as S from './style';

type TOwnProps = {
  pathname: string;
  isFullWidth?: boolean;
};

type TProps = TOwnProps & WithTranslation;

type TState = {
  hasError: boolean;
  prevPathname: string | null;
};

class RouteErrorBoundary extends React.PureComponent<TProps, TState> {
  state = {
    hasError: false,
    prevPathname: null,
  };

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  static getDerivedStateFromProps(props: TProps, state: TState) {
    if (props.pathname !== state.prevPathname) {
      return {
        hasError: false,
        prevPathname: props.pathname,
      };
    }

    return null;
  }

  private handleRefreshPage = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    const {t, isFullWidth, children, pathname} = this.props;

    if (this.state.hasError) {
      return (
        <S.ServiceUnavailable isFullWidth={isFullWidth}>
          <div>
            <H1>{t('ServiceUnavailable:title')}</H1>
            <S.Text>{t('ServiceUnavailable:checkNetworkConnect')}</S.Text>
            <S.Hint>{t('ServiceUnavailable:hint')}</S.Hint>
            <Button
              to={pathname}
              as={Link}
              onClick={this.handleRefreshPage}
              appearance="primary"
              data-selector="ServiceUnavailable:refreshPage"
            >
              {t('ServiceUnavailable:refreshPage')}
            </Button>
          </div>
          <S.UnavailableImage />
        </S.ServiceUnavailable>
      );
    }

    return children;
  }
}

export default withTranslation()(RouteErrorBoundary);
