import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {
  Modal,
  ModalActions,
  ModalCancelButton,
  ModalContent,
  ModalHeader,
  ModalSubmitButton,
  showModal,
} from '@edna/components';

import {ModalCenterImage, SecondaryText} from 'src/components/primitives';
import {BlockImage} from 'src/images';

import {useLazyStripeRedirectQuery, useOffAutopaymentMutation} from './api';

type TModalProps = {
  closeModal: TEmptyFunction;
};

const OffAutopaymentModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();

  const [offAutopayment] = useOffAutopaymentMutation();

  const handleOff = React.useCallback(() => {
    offAutopayment();
    closeModal();
  }, []);

  return (
    <Modal width="430px">
      <ModalHeader>{t('Autopayment:offModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:offModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalCenterImage as={BlockImage} />
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleOff}>
          {t('Autopayment:offModal.submitButton')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const OffAutopaymentSupportModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const [offAutopayment] = useOffAutopaymentMutation();

  const handleOff = React.useCallback(() => {
    offAutopayment(supportTenantId);
    closeModal();
  }, [closeModal, supportTenantId]);

  return (
    <Modal width="530px">
      <ModalHeader>{t('Autopayment:offSupportModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:offSupportModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleOff}>
          {t('Autopayment:offSupportModal.submitButton')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const ChangeAutopaymentCreditsModal = React.memo<TModalProps>(({closeModal}) => {
  const {t} = useTranslation();

  const [redirectToStripe] = useLazyStripeRedirectQuery();

  const handleChangeCredits = React.useCallback(async () => {
    try {
      const result = await redirectToStripe().unwrap();

      if (result?.stripeUrl) {
        window.open(result.stripeUrl, '_blank', 'noreferrer');
      }

      closeModal();
    } catch (e) {}
  }, [closeModal]);

  return (
    <Modal width="530px">
      <ModalHeader>{t('Autopayment:changeCardCreditsModal.title')}</ModalHeader>
      <ModalContent>
        <SecondaryText>{t('Autopayment:changeCardCreditsModal.subtitle')}</SecondaryText>
      </ModalContent>
      <ModalActions>
        <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
        <ModalSubmitButton onClick={handleChangeCredits}>
          {t('Common:button.change')}
        </ModalSubmitButton>
      </ModalActions>
    </Modal>
  );
});

const showOffAutopaymentSupportModal = () =>
  showModal((closeModal) => <OffAutopaymentSupportModal closeModal={closeModal} />);

const showOffAutopaymentModal = () =>
  showModal((closeModal) => <OffAutopaymentModal closeModal={closeModal} />);

const showChangeAutopaymentCreditsModal = () =>
  showModal((closeModal) => <ChangeAutopaymentCreditsModal closeModal={closeModal} />);

export {showChangeAutopaymentCreditsModal, showOffAutopaymentModal, showOffAutopaymentSupportModal};
