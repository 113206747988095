import {ECacheTag, rootApi} from 'src/models/api';

export enum ECallbackSystem {
  EDNA = 'EDNA',
  CC = 'CC',
  JIVO = 'JIVO',
}

type TCallbackSystem = keyof typeof ECallbackSystem;

export type TPhoneData = {
  phone: string;
};

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTestPhones: builder.query<string[], void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/tenant/test-phone',
        meta: {isShowError: true},
      }),
    }),
    getTestPhoneForTesting: builder.query<string, void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/tenant/test-phone/channel-attribute',
        meta: {isShowError: true},
      }),
    }),
    saveTestPhone: builder.mutation<void, TPhoneData>({
      query: (data) => ({
        method: 'POST',
        url: '/tenantmanagement/tenant/test-phone',
        data,
        meta: {isShowError: true},
      }),
      onQueryStarted(patch, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          api.util.updateQueryData('getTestPhones', undefined, (draft) => [...draft, patch.phone]),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    callConsoleResource: builder.mutation<{requestId: string}, {body: string; apiKey?: string}>({
      query: (data) => ({
        baseUrl: '/api',
        method: 'POST',
        url: '/cascade/schedule',
        data: data.body,
        headers: {
          'x-api-key': data.apiKey ?? '',
          'Content-Type': 'application/json',
        },
        meta: {isShowError: true},
      }),
    }),
    getCascadeTemplateId: builder.query<string, void>({
      query: () => ({
        method: 'GET',
        url: '/broadcast/cascade-template/testing',
        meta: {isShowError: true},
      }),
    }),
    getAllActiveCallbackSystem: builder.query<TCallbackSystem[], void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/callback-system/active/all',
        meta: {isShowError: true},
      }),
      providesTags: [ECacheTag.SYSTEM_CALLBACK],
    }),
    activateJivo: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: '/tenantmanagement/callback-system/jivo/activate',
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.SYSTEM_CALLBACK],
    }),
    deactivateJivo: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: '/tenantmanagement/callback-system/jivo/deactivate',
        meta: {isShowError: true},
      }),
      invalidatesTags: [ECacheTag.SYSTEM_CALLBACK],
    }),
  }),
});

export const {
  useGetTestPhonesQuery,
  useGetTestPhoneForTestingQuery,
  useSaveTestPhoneMutation,
  useCallConsoleResourceMutation,
  useGetCascadeTemplateIdQuery,
  useGetAllActiveCallbackSystemQuery,
  useActivateJivoMutation,
  useDeactivateJivoMutation,
} = api;
