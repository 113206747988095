export default {
  balance: 'Current balance',
  overdraft: 'Overdraft',
  availableBalance: 'Available balance',
  topUp: 'Top up',
  withdraw: 'Debit',
  description: 'Transaction description',
  warning:
    'Please, fill in this form carefully. <br/>There must be no mistakes in the account statements of our clients.',
  topUpBalanceModal: {
    title: "Pay company's invoice",
    amount: 'Payment amount (the exact amount as in the invoice)',
    description: 'Copy and paste the payment purpose from the selected invoice',
    amountNotMatch:
      'The amount of the deposit does not match the amount of the invoice. Check the payment details. ',
  },
  withdrawBalanceModal: {
    title: 'Debit from the balance',
    amount: 'Debit amount',
  },
  changeOverdraftModal: {
    title: `Change the client's overdraft?`,
    amount: 'Overdraft amount, {{currency}}',
    notification: 'Overdraft has been change',
  },
  overdraftHistoryModal: {
    title: 'Overdraft changelog',
    overdraftChanged: '{{date}} The overdraft amount has been changed',
    responsible: 'Assigned manager: {{responsibleManager}}',
    newOverdraftLimit: 'New overdraft limit: ',
    overdraftLimit: 'Overdraft limit: ',
    overdraftInstalled: '{{date}} The overdraft amount has been set',
  },
};
