import styled, {css} from 'styled-components';

import {LoadingButton} from '@edna/components';
import {mixins} from '@edna/components/styles';

const FacebookButton = styled(LoadingButton)`
  display: block;
  width: max-content;
  text-decoration: none;
  font-weight: 700;
  border: none;
  ${({theme}) => css`
    background-color: ${theme.palette.facebook};
    color: ${theme.palette.white};
    margin-top: ${theme.spacing.s2};
    padding: ${theme.spacing.s2} ${theme.spacing.s3};
    border-radius: ${theme.borderRadius.br1};

    &:active,
    &:hover {
      background-color: ${theme.palette.facebookDark};
      color: ${theme.palette.white};
    }

    svg {
      ${mixins.wh('20px')};
      color: ${theme.palette.white};
    }
  `}
`;

export {FacebookButton};
