export default {
  balance: 'Account balance:',
  topUp: 'Top up',
  bill: 'Invoice',
  stripe: 'Pay by card',
  overdraft: 'Overdraft',
  payTheLicense: 'You need to pay the license fee(s) to be able to send and receive messages',
  topUpBalance: 'Top up your balance to start sending messages',
  insufficientAmount:
    'Attention! The amount left on your balance might be insufficient. Sending and receiving messages might be suspended.',
};
