import React from 'react';

import isEmpty from 'lodash/isEmpty';

import ExpandLessIcon from '@edna/icons/expandLess.svg';
import ExpandMoreIcon from '@edna/icons/expandMore.svg';

import {NoShrinkImage} from 'src/components/primitives';
import {TItem as TMessageMatchersItem} from 'src/containers/MessageMatchers/types';
import {lengthUnicode, sliceUnicode} from 'src/utils/unicode';

import * as S from './style';

const BUTTON_TRUNCATE_LENGTH = 15;

type TProps = {
  isIOS: boolean;
  collapsed: boolean;
  content: TMessageMatchersItem['content'];
  text: React.ReactNode;
  attachmentUrl?: string;
  headerAttachmentUrl?: string;
};

const APP_TITLE = 'App Name';

const PushContent = React.memo<TProps>(
  ({isIOS, collapsed, headerAttachmentUrl, attachmentUrl, content: {header, buttons}, text}) => {
    const headerText = header?.text;

    const renderActions = () => {
      if (buttons?.length && collapsed) {
        return (
          <S.Actions isIOS={isIOS}>
            {buttons
              .filter((button) => button.text)
              .map((button) => (
                <S.Action key={button.text} isIOS={isIOS}>
                  {sliceUnicode(button.text!, 0, BUTTON_TRUNCATE_LENGTH)}
                  {lengthUnicode(button.text!) > BUTTON_TRUNCATE_LENGTH && '...'}
                </S.Action>
              ))}
          </S.Actions>
        );
      }

      return null;
    };

    const renderCollapsedContent = () => (
      <S.Container>
        {!attachmentUrl && headerAttachmentUrl && (
          <S.Logo isIOS={isIOS} src={headerAttachmentUrl} />
        )}
        <div>
          {headerText && <S.BigTitle isIOS={isIOS}>{headerText}</S.BigTitle>}
          {text && (
            <S.BigBody isIOS={isIOS} hasImage={attachmentUrl !== undefined}>
              {text}
            </S.BigBody>
          )}
        </div>
      </S.Container>
    );

    const renderContent = () => {
      if (collapsed) {
        return (
          <div>
            {!isIOS && renderCollapsedContent()}
            {attachmentUrl && (
              <S.BigLogoWrapper isIOS={isIOS}>
                <S.BigLogo isIOS={isIOS} src={attachmentUrl} />
              </S.BigLogoWrapper>
            )}
            {isIOS && renderCollapsedContent()}
          </div>
        );
      }

      return (
        <S.Container>
          {headerAttachmentUrl && <S.Logo src={headerAttachmentUrl} />}
          <div>
            {headerText && <S.Title>{headerText}</S.Title>}
            {text && (
              <S.Body isIOS={isIOS} hasTitle={!isEmpty(headerText)}>
                {text}
              </S.Body>
            )}
          </div>
        </S.Container>
      );
    };

    const getHeaderIcon = () => {
      if (isIOS) {
        if (collapsed) {
          return <S.MoreHorizIcon />;
        }

        return <S.Time>3m ago</S.Time>;
      }

      if (collapsed) {
        return <NoShrinkImage as={ExpandLessIcon} />;
      }

      return <NoShrinkImage as={ExpandMoreIcon} />;
    };

    return (
      <>
        <S.Message collapsed={collapsed} isIOS={isIOS}>
          <S.Header>
            <S.AppTitle>
              <S.AppLogo />
              <S.AppTitleText>{APP_TITLE}</S.AppTitleText>
            </S.AppTitle>
            {getHeaderIcon()}
          </S.Header>
          {renderContent()}
          {!isIOS && renderActions()}
        </S.Message>
        {isIOS && renderActions()}
      </>
    );
  },
);

PushContent.displayName = 'PushContent';

export default PushContent;
