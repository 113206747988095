const getUploadedFileDimensions = (file: File): Promise<{width: number; height: number}> =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image();

      img.onload = () => {
        const width = img.naturalWidth,
          height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);

        return resolve({width, height});
      };

      img.onerror = () => resolve({width: 0, height: 0});

      img.src = window.URL.createObjectURL(file);
    } catch (exception) {
      return reject(exception);
    }
  });

export {getUploadedFileDimensions};
