import {EStatus} from '../definitions';

export default {
  confirmPhoneModal: {
    title: 'Как подтвердить номер телефона',
    contentHead: `Чтобы подключить аккаунт WhatsApp Business API, подтвердите номер телефона, который вы использовали для регистрации аккаунта.
После подтверждения ваш аккаунт появится в сети WhatsApp.
`,
    contentListHeader1: 'Подтверждение номера телефона',
    contentList1: `
      <0>Выберите способ подтверждения:
        <0>
          <1>SMS-сообщение: вы получите код в сообщении. Мы рекомендуем использовать SMS-сообщения для подтверждения мобильных номеров.</1>
          <1>Голосовое сообщение: вы получите входящий звонок с кодом страны «+1» (США). Робот продиктует вам на английском языке 6 цифр кода. Запишите их. Мы рекомендуем использовать голосовые сообщения для подтверждения городских номеров.</1>
        </0>
      </0>
      <0>Нажмите на кнопку <b>Отправить</b>.</0>
      <0>Введите полученный код в соответствующее поле формы подтверждения.</0>
      <0>Нажмите на кнопку <b>Отправить</b>.</0>
    `,
    contentListHeader2: 'Особенности и ограничения при подтверждении номера телефона:',
    contentList2: `
      <0>Указывайте номер телефона с кодом страны. Например, для РФ номер телефона начинается с «+7».</0>
      <0>Уточните у вашего провайдера, что звонки из США доступны для вашего номера телефона. Код страны для США «+1».</0>
      <0>Если для вашего номера телефона настроено интерактивное голосовое меню (IVR), настройте маршрутизацию для входящих звонков с кодом страны «+1» напрямую на оператора. Или найдите запись звонка в логах IVR и прослушайте ее.</0>
      <0>Количество запросов на подтверждение номера телефона ограничено. Сразу доступны 3 попытки. Если получить код не удалось, то через 4 часа вы можете отправить еще 1 запрос. Затем еще 1 запрос через сутки. Если после пятой попытки получить код подтверждения не удалось, обратитесь в службу поддержки <0>{{supportEmail}}</0></0>
      <0>Если вам необходимо знать, с каких конкретно номеров будет происходить входящий звонок, обратитесь в службу поддержки <0>{{supportEmail}}</0></0>
    `,
    contentFooter:
      'Если у вас возникнут проблемы с подтверждением номера телефона, <b>обратитесь к вашему менеджеру</b> <0>{{personalManagerEmail}}</0>.',
  },
  [EStatus.CANCELED]: {
    content: 'Регистрация аккаунта отменена',
  },
  [EStatus.SENT]: {
    title: 'Шаг 1 из 7: Аккаунт WhatsApp отправлен на регистрацию',
    content: `Мы пришлем уведомление на ваш email по дальнейшим шагам.`,
  },
  [EStatus.NEED_CONFIRM_ACCOUNT]: {
    title: 'Шаг 2 из 7: Требуется одобрить запрос на управление аккаунтом',
    content: `Вам необходимо одобрить запрос от edna Pulse на переписку от имени вашего аккаунта WhatsApp.
    Для этого <0>перейдите по ссылке</0> и нажмите на кнопку <b>Одобрить</b>.
После этого мы пришлем уведомления по дальнейшим действиям`,
  },
  [EStatus.NEED_VERIFY_FACEBOOK_BUSINESS]: {
    title: 'Шаг 3 из 7: Требуется верификация компании в Facebook Business',
    content: `Вам необходимо верифицировать компанию в Facebook Business Manager.
    Следуйте <0>инструкции</0> для этого. Там вы найдете точное описание шагов и ответы на частые вопросы.
    После выполнения данной процедуры мы пришлем уведомления по дальнейшим действиям.`,
  },
  [EStatus.CREATE_WHATSAPP_ACCOUNT]: {
    title: 'Шаг 4 из 7: Ваша заявка принята и в настоящий момент рассматривается нами',
    content: `Процедура займет некоторое время, обычно от 10 до 15 дней.
Менеджер свяжется с вами в случае появления вопросов.
По завершению процедуры регистрации на ваш адрес {{email}}
придет автоматическое уведомление.`,
  },
  [EStatus.NEED_CONFIRM_CODE]: {
    title: 'Шаг 5 из 7: Требуется подтвердить номер телефона',
    content: `Вам необходимо подтвердить номер телефона, на который регистрируется аккаунт WhatsApp.
Для этого нажмите на кнопку <b>Подтвердить телефон</b> и выберите способ получения кода.
После этого нажмите <b>Отправить</b>. Введите полученный код в соответствующее поле и нажмите на кнопку <b>Отправить</b>.
Перед началом ознакомьтесь, пожалуйста, с <0>особенностями</0>.`,
    button: 'Подтвердить телефон',
  },
  [EStatus.CONFIRM_CODE_REQUEST]: {
    title: 'Шаг 6 из 7: Требуется подтвердить номер телефона',
    content: `Подтверждение номера телефона, который используется для регистрации WhatsApp Business API,
необходимо для подключения аккаунта. После подтверждения ваш аккаунт появляется в сети WhatsApp.
Нажмите на кнопку «Запросить код» и выберите вариант: SMS или Голосовое сообщение.
Если вы уже запрашивали код, и срок его действия не истек, нажмите на кнопку «Отправить код».
Перед началом ознакомьтесь, пожалуйста, с <0>особенностями</0>.`,
    requestCodeButton: 'Запросить код',
    sendCodeButton: 'Отправить код',
  },
  [EStatus.SEND_CONFIRM_CODE]: {
    title: 'Шаг 6 из 7: Почти готово',
    content: `Мы выполняем финальные технические операции для подключения`,
  },
  [EStatus.DONE_NOT_VERIFIED]: {
    title: 'Шаг 7 из 7: Настройка сервиса завершена!',
    content: `Ваш аккаунт зарегистрирован! (<0>подробнее о статусе</0>)`,
    modalTitle: 'Поздравляем, вы подключили аккаунт WhatsApp Business API!',
    modalContent: `Теперь вы можете отправлять клиентам сообщения в WhatsApp и получать статусы по исходящим сообщениям.
Если вам нужно зарегистрировать шаблон для отправки рассылки, <b>обратитесь к вашему менеджеру</b> <0>{{personalManagerEmail}}</0>.<br/>
Вы можете ознакомиться с правилами отправки рассылок на сайте <1>Facebook for Developers</1>.<br/><br/>

<b>Получение официального статуса</b><br/>
Мы отправили запрос на получение официального статуса в WhatsApp,<br/>
но очень немногим компаниям присваивают официальный статус.<br/>
В списке чатов у официального бизнес-аккаунта вместо номера телефона будет отображаемое имя,
а в профиле и в чате будет зеленая галочка.<br/>
Подробнее об официальном статусе компании<br/>
вы можете узнать на сайте <1>Facebook for Developers</1>.
`,
  },
  [EStatus.DONE_VERIFIED]: {
    title: 'Шаг 7 из 7: Настройка сервиса завершена!',
    content: `Ваш аккаунт зарегистрирован с официальным статусом.
Теперь можно отправлять сообщения!`,
  },
};
