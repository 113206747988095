import React from 'react';

import {TStatusIcon} from '../StatusIcon';
import * as S from './style';

type TProps = {
  name: TStatusIcon;
  title: string | null;
  className?: string;
  description?: string | null;
};

const Status = React.memo<TProps>(({description, name, title, className}) => (
  <S.Status name={name} className={className}>
    <S.StatusIcon name={name} />
    <S.Content>
      {title && <div>{title}</div>}
      {description && <S.Description>{description}</S.Description>}
    </S.Content>
  </S.Status>
));

export default Status;
