import defaultsDeep from 'lodash/defaultsDeep';

import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';

import {TBankDetails} from 'src/containers/LeadCompanyClients/types';
import {apiModel, errorNotification, request} from 'src/models';

import {EPaymentType} from '../Licenses/PaymentTab/definitions';
import {TState, TTenantData, defaultTenantData} from './definitions';

type TActions = {
  // Reducers
  setTenantDataLoading: TActionCreator<boolean>;
  setTenantData: TActionCreator<TState['tenantData']>;
  setGeneratePaymentLinkLoading: TActionCreator<TState['generatePaymentLinkLoading']>;
  setLowBalanceLimits: TActionCreator<TState['lowBalanceLimits']>;

  // Thunks
  requestTenantData: TThunkCreator<void, void, Promise<TTenantData>>;
  generatePaymentLink: TThunkCreator<
    {bankDetails?: TBankDetails; amount: number},
    void,
    Promise<TAnyObject>
  >;
  requestLowBalanceLimits: TThunkCreator<void, void, Promise<void>>;
};

const id = 'profile';

const selectors = {
  offerContentUrl: (state: TState) => state.tenantData.offerContentUrl,
  needAgreementConfirmation: (state: TState) => state.tenantData.needAgreementConfirmation,
  personalManagerEmail: (state: TState) => state.tenantData.personalManagerEmail,
  personalManagerName: (state: TState) => state.tenantData.personalManagerName,
  personalManagerPhone: (state: TState) => state.tenantData.personalManagerPhone,
  supportEmail: (state: TState) => state.tenantData.supportEmail,
  contacts: (state: TState) => state.tenantData.contacts,
  contactEmail: (state: TState) => selectors.contacts(state)?.email,
  paymentType: (state: TState) => state.tenantData.paymentType,
  isPrePaymentType: (state: TState) => state.tenantData.paymentType === EPaymentType.PREPAYMENT,
  licensePaymentTerm: (state: TState) => state.tenantData.licensePaymentTerm,
  temporaryBlocked: (state: TState) => state.tenantData.temporaryBlocked,
  unblockAt: (state: TState) => state.tenantData.unblockAt,
  locked: (state: TState) => state.tenantData.locked,
  resellerId: (state: TState) => state.tenantData.resellerId,
  defaultCurrency: (state: TState) => state.tenantData.defaultCurrency,
};

export default apiModel<typeof id, TEmptyObject, TState, TActions, typeof selectors>({
  id,
  defaultState: {
    tenantDataLoading: false,
    tenantData: {...defaultTenantData},
    generatePaymentLinkLoading: false,
    lowBalanceLimits: {},
  },
  selectors,
  reducers: {
    setTenantDataLoading: (state, {payload}) => ({
      ...state,
      tenantDataLoading: payload,
    }),
    setTenantData: (state, {payload}) => ({
      ...state,
      tenantData: defaultsDeep(
        {
          ...payload,
          paymentData: payload.paymentData ?? {},
          contacts: payload.contacts ?? {},
        },
        defaultTenantData,
      ),
    }),
    setGeneratePaymentLinkLoading: (state, {payload}) => ({
      ...state,
      generatePaymentLinkLoading: payload,
    }),
    setLowBalanceLimits: (state, {payload}) => ({
      ...state,
      lowBalanceLimits: payload,
    }),
  },
  api: {
    // TODO: при переносе на RTK разнести запросы по соответствующим контейнерам
    generatePaymentLink: (payload) => request.post('/rest/billing/document/bill/generate', payload),
    requestTenantData: () => request.get('/rest/tenantmanagement/tenant/data'),
    requestLowBalanceLimits: () =>
      request.get('/rest/tenantmanagement/user/config/low-balance-notification-limits'),
  },
  thunks: ({actions, api}) => ({
    requestTenantData: async ({dispatch}) => {
      dispatch(actions.setTenantDataLoading(true));
      const {result, error} = await api.requestTenantData();

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.setTenantData(result));
      }
      dispatch(actions.setTenantDataLoading(false));

      return result;
    },
    generatePaymentLink: async ({dispatch}, {payload}) => {
      dispatch(actions.setGeneratePaymentLinkLoading(true));
      const {result, error} = await api.generatePaymentLink(payload);

      if (error) {
        errorNotification(error);
      }
      dispatch(actions.setGeneratePaymentLinkLoading(false));

      return result;
    },
    requestLowBalanceLimits: async ({dispatch}) => {
      const {result, error} = await api.requestLowBalanceLimits();

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.setLowBalanceLimits(result));
      }
    },
  }),
});
