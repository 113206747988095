import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Modal, ModalHeader, ModalSubmitButton} from '@edna/components';

import {
  ModalLayout,
  ModalLeftActions,
  ModalLeftContent,
  NoShrinkImage,
} from 'src/components/primitives';
import {TrainerImage} from 'src/images';

type TProps = {
  closeModal: TEmptyFunction;
  onMainClick: TEmptyFunction;
  head: string;
  text: string;
};

const SuccessDialog = React.memo<TProps>(({onMainClick, closeModal, head, text}) => {
  const {t} = useTranslation();

  const handleReturnToMain = React.useCallback(() => {
    onMainClick();
    closeModal();
  }, [closeModal, onMainClick]);

  return (
    <Modal width="700px">
      <ModalHeader>{t(head)}</ModalHeader>
      <ModalLayout>
        <ModalLeftContent>
          <Trans i18nKey={text} />
          <ModalLeftActions>
            <ModalSubmitButton onClick={handleReturnToMain}>
              {t('Common:button.toList')}
            </ModalSubmitButton>
          </ModalLeftActions>
        </ModalLeftContent>
        <NoShrinkImage as={TrainerImage} />
      </ModalLayout>
    </Modal>
  );
});

SuccessDialog.displayName = 'SuccessDialog';

export default SuccessDialog;
