import React from 'react';

import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';
import BaseCloseIcon from '@edna/icons/close.svg';

import {TColor, TVariant} from './types';

type TChipProps = {
  variant: TVariant;
  color: TColor;
  disabled: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
};

const getDisabledStyle = ({onClick, disabled}: TChipProps) => {
  if (disabled) {
    return css`
      opacity: 0.5;
      cursor: not-allowed;
    `;
  }

  if (onClick) {
    return css`
      cursor: pointer;
    `;
  }

  return css`
    cursor: default;
  `;
};

const getAppearanceStyle = ({theme, variant, color}: TTheme & TChipProps) => {
  switch (variant) {
    case 'contained':
      return css`
        padding: ${theme.spacing.s1} 6px;
        background-color: ${theme.palette[color]};
        border-radius: 10px;
      `;
    case 'outlined':
    default:
      return css`
        padding: 5.5px ${theme.spacing.s2};
        color: ${theme.palette[color]};
        border: 1px solid ${theme.palette[color]};
        border-radius: ${theme.borderRadius.br4};
      `;
  }
};

const getMainStyle = ({theme}: TTheme & TChipProps) => css`
  transition: opacity ${theme.animation.duration}ms;
`;

export const Chip = styled.div<TChipProps>`
  max-width: 100%;
  ${mixins.f2}
  ${mixins.flexAlign('center')}
  ${getMainStyle}
  ${getAppearanceStyle}
  ${getDisabledStyle}
`;

export const Text = styled.div`
  ${mixins.truncate}
`;

export const CloseIcon = styled(BaseCloseIcon)`
  ${mixins.wh('14px')};
  cursor: pointer;

  ${({theme}) => css`
    margin-left: ${theme.spacing.s1};

    &:hover {
      color: ${theme.branding.content.primary};
    }
  `};
`;
