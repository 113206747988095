import React from 'react';
import {useTranslation} from 'react-i18next';

import {H2, Stack} from '@edna/components/primitives';

import {TEmbeddedWhatsAppChannel} from 'src/containers/EmbeddedWhatsAppChannel/definitions';
import {TWhatsAppChannel} from 'src/containers/WhatsAppChannel/definitions';

import {ChannelMessagingLimit} from './MessagingLimit';
import {ChannelQualityScore} from './QualityScore';
import * as S from './style';

type TProps = {
  item: TWhatsAppChannel | TEmbeddedWhatsAppChannel;
};

const ChannelQualityLimitsMessage = React.memo<TProps>(({item}) => {
  const {t} = useTranslation();

  return (
    <S.Message data-selector="Components:ChannelLimitsMessage">
      <S.StatusIcon name="ok" />
      <S.Content>
        <Stack size="4">
          <H2>{t('Channels:activeChannel')}</H2>
          <Stack size="2">
            <ChannelQualityScore item={item} />
            <ChannelMessagingLimit item={item} />
          </Stack>
        </Stack>
      </S.Content>
    </S.Message>
  );
});

ChannelQualityLimitsMessage.displayName = 'ChannelQualityLimitsMessage';

export {ChannelQualityLimitsMessage};
