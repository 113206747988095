import styled, {css} from 'styled-components';

import {Select as BaseSelect} from '@edna/components';
import {
  ArrowIcon,
  ArrowIconWrapper,
  Head as BaseHead,
  Overlay,
} from '@edna/components/Select/style';
import {TTheme, mixins} from '@edna/components/styles';
import {EPlacementY, TPlacementY} from '@edna/components/types';

type TSelect = TTheme & {
  placementY?: TPlacementY;
};

export const Select = styled(BaseSelect)<TSelect>`
  ${({theme, placementY}: TSelect) => css`
    width: 150px;
    ${ArrowIconWrapper} {
      top: 10px;
      transform: ${placementY === EPlacementY.top ? 'rotate(180 deg)' : 'none'};
    }
    ${ArrowIcon} {
      color: ${theme.branding.content.tertiary};
    }

    ${Overlay}, ${BaseHead} {
      padding: 0;
      box-shadow: none;
      border: none;
      background-color: ${theme.palette.black};
    }
  `}
`;

type TLabelProps = TTheme & {
  selected?: boolean;
  placementY?: TPlacementY;
};

export const Label = styled.div<TLabelProps>`
  position: relative;
  ${mixins.flexAlign('flex-start', 'center')}

  ${({theme: {palette, animation, branding, dimension}, selected, placementY}: TLabelProps) => css`
    min-height: ${dimension.controlHeightM}px;
    background: ${palette.black};
    transition: color ${animation.duration}ms;
    color: ${selected ? palette.white : branding.content.tertiary};
    cursor: ${selected ? 'default' : 'pointer'};
    border-radius: ${placementY === EPlacementY.bottom ? '6px' : 0};
    &:hover {
      color: ${palette.white};
      background: ${palette.black};
    }
  `}
`;

export const Flag = styled.svg`
  ${mixins.wh('24px', '16px')}
  display: inline-block;
  margin: 0 ${({theme}) => theme.spacing.s3};
`;

type THeadProps = TTheme & {
  isOpen: boolean;
};

const getHeadBorder = ({
  theme: {
    borderRadius: {br1},
  },
  isOpen,
}: THeadProps) => {
  if (isOpen) {
    return css`
      border-radius: ${br1} ${br1} 0 0;
    `;
  }

  return css`
    border-radius: ${br1};
  `;
};

export const Head = styled(BaseHead)<THeadProps>`
  ${getHeadBorder}
`;
