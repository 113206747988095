import {showNewNotification} from '@edna/components';

import {TData, TPayload} from 'src/components/List/useList';
import {TItemId} from 'src/constants';
import {TItem as TParametersItem} from 'src/containers/Parameters/definitions';
import {TItem as TTagsItem} from 'src/containers/Tags/definitions';
import {ECacheTag, listenerMiddleware, rootApi} from 'src/models/api';

import {
  TAddressItem,
  TAutoGeneratedTag,
  TSubscriber,
  TSubscriberSaveRequest,
  TSubscribersConfirmPayload,
  TSubscribersUploadPreview,
  TSubscribersUploadStatus,
} from './definitions';
import {prepareFilters} from './utils';

export const SUBSCRIBERS_UPLOAD_STATUS_POLLING_INTERVAL = 3_000;

export type TConfirmSubscribersPayload = {
  data: TSubscribersConfirmPayload;
  setTag: boolean;
};

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadSubscribers: builder.mutation<TSubscribersUploadPreview, File>({
      query: (file) => {
        const formData = new FormData();

        formData.append('file', file);

        return {
          method: 'POST',
          url: `/subscriber/upload`,
          data: formData,
          meta: {isShowError: true},
        };
      },
      invalidatesTags: [ECacheTag.SUBSCRIBERS],
    }),
    confirmSubscribers: builder.mutation<TAutoGeneratedTag | undefined, TConfirmSubscribersPayload>(
      {
        query: ({data, setTag}) => ({
          method: 'POST',
          url: `/subscriber/upload/${setTag ? 'confirm-and-tag' : 'confirm'}`,
          data,
          meta: {isShowError: true},
        }),
      },
    ),
    getSubscribersUploadStatus: builder.query<TSubscribersUploadStatus, TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/subscriber/upload/status/${id}`,
      }),
    }),
    getSubscribers: builder.query<TData<TSubscriber>, TPayload>({
      query: (payload) => ({
        url: `/subscriber/list`,
        method: 'POST',
        data: prepareFilters(payload),
      }),
      providesTags: (result) => {
        if (!result) {
          return [ECacheTag.SUBSCRIBERS];
        }

        return [
          ECacheTag.SUBSCRIBERS,
          ...result.content.map(({id}) => ({id, type: ECacheTag.SUBSCRIBERS})),
        ];
      },
    }),
    saveSubscriber: builder.mutation<TSubscriber, TSubscriberSaveRequest>({
      query: (item) => {
        return {
          url: !!item.id ? `/subscriber/${item.id}` : `/subscriber/`,
          method: !!item.id ? 'PUT' : 'POST',
          data: item,
        };
      },
    }),
    getSubscriber: builder.query<TSubscriber, TItemId>({
      query: (id) => ({
        url: `/subscriber/${id}`,
        method: 'GET',
        meta: {isShowError: true, errorMessageKey: 'List:errors.failedToLoadItem'},
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.SUBSCRIBERS}],
    }),
    deleteSubscriber: builder.mutation<void, TItemId>({
      query: (id) => ({
        url: `/subscriber/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ECacheTag.SUBSCRIBERS],
    }),
    saveParameters: builder.mutation<
      TParametersItem[],
      {id: TSubscriber['id']; parameters: TParametersItem[]; isUpdate?: boolean}
    >({
      query: (payload) => ({
        url: `/subscriber/${payload.id}/parameters`,
        method: payload.isUpdate ? 'PUT' : 'POST',
        data: payload.parameters,
      }),
    }),
    getSubscriberParameters: builder.query<TParametersItem[], TItemId>({
      query: (id) => ({
        url: `/subscriber/${id}/parameter`,
        method: 'GET',
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.SUBSCRIBERS}],
    }),
    deleteParameters: builder.mutation<
      unknown,
      {id: TSubscriber['id']; parameters: TParametersItem[]}
    >({
      query: (payload) => ({
        url: `/subscriber/${payload.id}/parameters`,
        method: 'DELETE',
        data: payload.parameters,
      }),
    }),
    createTags: builder.mutation<TTagsItem[], {id: TSubscriber['id']; tags: TTagsItem[]}>({
      query: (payload) => ({
        url: `/subscriber/${payload.id}/tags`,
        method: 'POST',
        data: payload.tags,
      }),
    }),
    getSubscriberTags: builder.query<TTagsItem[], TItemId>({
      query: (id) => ({
        url: `/subscriber/${id}/tag`,
        method: 'GET',
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.SUBSCRIBERS}],
    }),
    deleteTags: builder.mutation<void, {id: TSubscriber['id']; tags: Omit<TTagsItem, 'id'>[]}>({
      query: (payload) => ({
        url: `/subscriber/${payload.id}/tags`,
        method: 'DELETE',
        data: payload.tags,
      }),
    }),
    getAddress: builder.query<TAddressItem[], TItemId>({
      query: (id) => ({
        url: `/subscriber/${id}/address`,
        method: 'GET',
      }),
      providesTags: (_, __, id) => [{id, type: ECacheTag.SUBSCRIBERS}],
    }),
    saveAddress: builder.mutation<
      TAddressItem | TAddressItem[],
      {id: TSubscriber['id']; address: Partial<TAddressItem>; isUpdate?: boolean}
    >({
      query: (payload) => ({
        url: payload.isUpdate
          ? `/subscriber/address/${payload.address.id}`
          : `/subscriber/${payload.id}/address`,
        method: payload.isUpdate ? 'PATCH' : 'POST',
        data: payload.address,
      }),
    }),
    deleteAddress: builder.mutation<void, TAddressItem['id']>({
      query: (id) => ({
        url: `/subscriber/address/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: api.endpoints.uploadSubscribers.matchFulfilled,
  effect: ({payload}) => {
    if (!payload.subscribers) {
      showNewNotification({
        type: 'warning',
        messageKey: 'Subscribers:notification.emptyFile',
      });
    }
  },
});

export const {
  useUploadSubscribersMutation,
  useConfirmSubscribersMutation,
  useGetSubscribersUploadStatusQuery,
  useDeleteSubscriberMutation,
  useGetAddressQuery,
  useGetSubscriberParametersQuery,
  useGetSubscriberQuery,
  useGetSubscriberTagsQuery,
  useGetSubscribersQuery,
  useDeleteParametersMutation,
  useCreateTagsMutation,
  useDeleteTagsMutation,
  useDeleteAddressMutation,
  useSaveSubscriberMutation,
  useSaveParametersMutation,
  useSaveAddressMutation,
  endpoints: subscribersEndpoints,
} = api;
