import React from 'react';
import {useTranslation} from 'react-i18next';

import * as S from './style';
import {getOrdinalDay} from './utils';

const useNewsDate = (date?: string | Date, isOrdinal?: boolean) => {
  const {t, i18n} = useTranslation();

  if (!date) {
    return '';
  }

  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  const day = isOrdinal ? getOrdinalDay(parsedDate, i18n.language) : parsedDate.getDate();
  const month = parsedDate.getMonth();

  return t(`News:date.${month}`, {day});
};

const NewsDate = React.memo<{date?: Date | string; isOrdinal?: boolean}>(({date, isOrdinal}) => {
  const dateText = useNewsDate(date, isOrdinal);

  return <S.NewsDate>{dateText}</S.NewsDate>;
});

export {NewsDate, useNewsDate};
