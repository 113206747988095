import {generatePath, useNavigate} from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  return (path: string, params = {}, replace = false) =>
    navigate(generatePath(path, {...params}), {replace});
};

export default useRedirect;
