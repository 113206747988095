import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Modal, showModal} from '@edna/components';

import {Badge, PageDialog} from 'src/components';
import {LeftButton} from 'src/components/primitives';
import {EChannelType, ESubjectStatus} from 'src/constants';
import {useGetAllSubjectsQuery} from 'src/containers/Subjects/api';
import {JivoImage, RocketButtonImage} from 'src/images';

import Card from '../Card';
import {
  ECallbackSystem,
  useActivateJivoMutation,
  useDeactivateJivoMutation,
  useGetAllActiveCallbackSystemQuery,
} from '../api';
import * as S from './style';

type TProps = {
  closeModal: TEmptyFunction;
  onConfirm: TEmptyFunction;
};

const JivoConnectModal = React.memo<TProps>(({closeModal, onConfirm}) => {
  const {t} = useTranslation();

  const handleConfirm = React.useCallback(() => {
    onConfirm();
    closeModal();
  }, [closeModal, onConfirm]);

  return (
    <Modal width="430px">
      <PageDialog
        layoutOrientation="VERTICAL"
        layoutSize="SMALL"
        title={t('Integration:modules.jivo.modal.title')}
        subtitle={t('Integration:modules.jivo.modal.subtitle')}
        imageComponent={RocketButtonImage}
        actions={
          <>
            <LeftButton
              onClick={handleConfirm}
              appearance="secondary"
              data-selector="Common:button.yes"
            >
              {t('Common:button.yes')}
            </LeftButton>
            <Button onClick={closeModal} appearance="primary" data-selector="Common:button.no">
              {t('Integration:modules.jivo.modal.no')}
            </Button>
          </>
        }
      />
    </Modal>
  );
});

JivoConnectModal.displayName = 'JivoConnectModal';

const Modules = React.memo(() => {
  const {t} = useTranslation();

  const {hasActiveSubjects} = useGetAllSubjectsQuery(
    {
      testing: false,
      locked: false,
      channelType: EChannelType.WHATSAPP,
    },
    {
      selectFromResult: ({data}) => ({
        hasActiveSubjects: data?.some((subject) => subject.status === ESubjectStatus.APPROVED),
      }),
    },
  );

  const {data: activeCallbacks = []} = useGetAllActiveCallbackSystemQuery();
  const [activateJivo] = useActivateJivoMutation();
  const [deactivateJivo] = useDeactivateJivoMutation();

  const hasJivo = React.useMemo(
    () => activeCallbacks.some((item) => item === ECallbackSystem.JIVO),
    [activeCallbacks],
  );

  const handleDeactivateJivo = React.useCallback(() => deactivateJivo(), [deactivateJivo]);

  const openJivoConnectModal = React.useCallback(() => {
    showModal((closeModal) => (
      <JivoConnectModal closeModal={closeModal} onConfirm={activateJivo} />
    ));
  }, [activateJivo]);

  if (hasJivo) {
    return (
      <Card logo={JivoImage} title="Jivo" description={t('Integration:modules.jivo.description')}>
        <Badge color="GREEN">{t('Integration:modules.connected')}</Badge>
        <Button onClick={handleDeactivateJivo}>{t('Integration:modules.disconnect')}</Button>
      </Card>
    );
  }

  if (hasActiveSubjects) {
    return (
      <Card logo={JivoImage} title="Jivo" description={t('Integration:modules.jivo.description')}>
        <Button appearance="primary" onClick={openJivoConnectModal}>
          {t('Integration:modules.connect')}
        </Button>
      </Card>
    );
  }

  return (
    <Card logo={JivoImage} title="Jivo" description={t('Integration:modules.jivo.description')}>
      <S.Message type="Info">{t('Integration:modules.jivo.noSubjects')}</S.Message>
    </Card>
  );
});

Modules.displayName = 'Modules';

export default Modules;
