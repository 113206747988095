import React from 'react';
import {useTranslation} from 'react-i18next';

import debounce from 'lodash/debounce';
import noop from 'lodash/noop';

import {NumberInput, TextInput} from '@edna/components';

import {DEFAULT_DEBOUNCE_DELAY} from 'src/constants';

import * as S from './style';

type TProps = {
  className?: string;
  query?: string;
  minQueryLength?: number;
  placeholder?: string;
  disabled?: boolean;
  delay?: number;
  type?: 'text' | 'tel';
  onChange?: (value: string, isValidQuery: boolean) => void;
  maxLength?: number;
};

const DEFAULT_MIN_QUERY_LENGTH = 1;

const SearchInput = React.memo<TProps>(
  ({
    className,
    query = '',
    minQueryLength = DEFAULT_MIN_QUERY_LENGTH,
    delay = DEFAULT_DEBOUNCE_DELAY,
    disabled,
    placeholder,
    type = 'text',
    onChange = noop,
    maxLength,
  }) => {
    const {t} = useTranslation();

    const [value, setValue] = React.useState(query);

    const debouncedOnChange = React.useMemo(() => debounce(onChange, delay), [delay, onChange]);

    React.useEffect(() => setValue(query), [query]);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const isValid = newValue.length >= minQueryLength || newValue === '';

        setValue(newValue);

        if (isValid) {
          debouncedOnChange(newValue);
        }
      },
      [minQueryLength, debouncedOnChange],
    );

    const handleKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }, []);

    return (
      <S.Input
        className={className}
        as={type === 'tel' ? NumberInput : TextInput}
        type={type}
        value={value}
        placeholder={placeholder ?? t('SearchInput:placeholder')}
        disabled={disabled}
        leftChildren={<S.SearchIcon />}
        isEnableReset
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        maxLength={maxLength}
      />
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
