export default {
  channelType: 'Channel type',
  licenseID: 'License ID',
  month: 'Month',
  year: 'Year',
  eventTable: 'license_billed_event_tab (for the specified period)',
  notFound: 'License not found',
  search: 'Search',
  notification: 'License renewal operation started',
  tr: {
    id: 'ID',
    channel: 'Channel',
    price: 'Price',
    currency: 'Currency',
    paymentType: 'Payment type',
    periodStartedAt: 'Period started at',
    periodEndedAt: 'Period ended at',
    transactionId: 'Transaction ID',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    documentId: 'Document ID',
    tenantId: 'Tenant ID',
    subjectId: 'SubjectID',
    periodMonths: 'Period months ',
    name: 'Name',
    description: 'Description',
    licenseStatus: 'License status',
    activatedAt: 'Activated at',
    expiredAt: 'Expired at',
    cancelledAt: 'Cancelled at',
    activeUntil: 'Active until',
    paidUntil: 'Paid until',
    temporaryblockedAt: 'Temporary blocked at',
    paymentCode: 'Payment code',
  },
};
