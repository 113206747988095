/* eslint-disable global-require */
import {createGlobalStyle, css} from 'styled-components';

import {mixins} from '@edna/components/styles';

import {HIGHLIGHT_CLASSNAME, HIGHLIGHT_ZINDEX} from './components';
import {MARKDOWN_BOLD_CLASSNAME} from './utils';

export default createGlobalStyle`
  ${require('sanitize.css')}
  ${require('draft-js/dist/Draft.css')}
  ${require('prism-themes/themes/prism-one-light.css')}
  ${require('reactflow/dist/style.css')}

  /* для правильного отображения обратной кавычки */
  @font-face {
    font-family: GraveAccent;
    src: local('Roboto'), url('/public/fonts/Roboto.ttf') format('truetype');
    unicode-range: U+0060;
  }

  @font-face {
    font-family: 'Noto Sans';
    src:
      local('NotoSans'),
      local('NotoSans-Regular'),
      url('/public/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src:
      local('NotoSans Medium'),
      local('NotoSans-Medium'),
      url('/public/fonts/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src:
      local('NotoSans Bold'),
      local('NotoSans-Bold'),
      url('/public/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  body {
    ${mixins.f3}
    font-family: GraveAccent, 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: none;
    overflow-x: hidden;

    ${({theme: {branding}}) => css`
      color: ${branding.content.primary};
      background-color: ${branding.backgrounds.primary};
    `}
  }
  input, textarea, button {font-family: inherit}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-shadow: 0 0 0 1000px ${({theme}) => theme.palette.grey50} inset;
    border: none;
  }

  input:-webkit-autofill::first-line {
    ${mixins.f3}
    font-family: GraveAccent, 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  ::-webkit-scrollbar {
    ${mixins.wh('8px')}
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    ${({theme: {branding, borderRadius}}) => css`
      background-color: ${branding.content.tertiary};
      border-radius: ${borderRadius.br5};
    `}
  }

  /* Перезаписываем св-ва из sanitize для accessibility тк в шапке селектора cursor 'not allowed' */
  :where([aria-disabled='true' i], [disabled]) {
    cursor: inherit;
  }

  .${HIGHLIGHT_CLASSNAME} {
    position: relative;
    z-index: ${HIGHLIGHT_ZINDEX} !important;
  }

  .${MARKDOWN_BOLD_CLASSNAME} * {
   font-weight: 700 !important;
  }
`;
/* eslint-enable global-require */
