import type {AxiosRequestConfig, AxiosResponse} from 'axios';

import {
  AnyAction,
  createListenerMiddleware,
  isAllOf,
  isFulfilled,
  isRejected,
} from '@reduxjs/toolkit';
import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';
import intersection from 'lodash/intersection';

import {showNewNotification} from '@edna/components';

import {EPermission} from 'src/containers/Auth/definitions';
import userModel from 'src/containers/Auth/userModel';

import errorNotification from './errorNotification';
import {getLastResponse} from './headers';
import {axiosInstance} from './request';

const CACHE_LIFETIME = 5;

type THeaders = Record<string, string>;
type TMeta = Partial<{
  isShowError: boolean;
  errorMessageKey: string;
  i18nOptions: TAnyObject;
  isShowSuccess: boolean;
  successMessageKey: string;
  isBackendError: boolean;
  permissions: EPermission[];
  headers: THeaders;
}> &
  TAnyObject;

const RESERVED_META_KEYS = ['isBackendError', 'headers'];

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#examples---basequery
const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      baseUrl?: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      responseType?: AxiosRequestConfig['responseType'];
      meta?: TMeta;
    },
    unknown,
    AxiosResponse,
    unknown,
    TMeta
  > =>
  async (
    {baseUrl: customBaseUrl, url, method, data, params, responseType, headers, meta = {}},
    api,
  ) => {
    const intersectedMetaKeys = intersection(RESERVED_META_KEYS, Object.keys(meta));

    if (intersectedMetaKeys.length) {
      throw new Error(
        `Reserved meta keys: ${intersectedMetaKeys.join(', ')} were used. Choose a different key`,
      );
    }

    const userPermissions = userModel.selectors.userPermissions(api.getState() as TRootState);

    if (
      meta.permissions &&
      !meta.permissions.every((permission) => userPermissions.includes(permission))
    ) {
      return {
        data: null,
        meta: {},
      };
    }

    try {
      const result = await axiosInstance({
        url: `${customBaseUrl ?? baseUrl}${url}`,
        method,
        data,
        params,
        responseType,
        headers,
      });

      const responseHeaders = {...(getLastResponse()?.headers ?? {})} as THeaders;

      return {data: result, meta: {...meta, headers: responseHeaders}};
    } catch (error) {
      return {
        error: error as AxiosResponse,
        meta: {...meta, isBackendError: true},
      };
    }
  };

enum ECacheTag {
  FLOW_STEPS = 'FLOW_STEPS',
  FLOW = 'FLOW',
  AUTH_CAPTCHA = 'AUTH_CAPTCHA',
  CHANNELS = 'CHANNELS',
  SYSTEM_CALLBACK = 'SYSTEM_CALLBACK',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT',
  CASCADES = 'CASCADES',
  BALANCE = 'BALANCE',
  PARAMETERS = 'PARAMETERS',
  TRANSACTIONS = 'TRANSACTIONS',
  SUPPORT_CLIENTS = 'SUPPORT_CLIENTS',
  LEAD_CLIENTS = 'LEAD_CLIENTS',
  OVERDRAFT = 'OVERDRAFT',
  COMPANY_DOCUMENT = 'COMPANY_DOCUMENT',
  TAGS = 'TAGS',
  SMS_CHANNEL = 'SMS_CHANNEL',
  EMBEDDED_WA_CHANNEL = 'EMBEDDED_WA_CHANNEL',
  PUSH_APPS = 'PUSH_APPS',
  BROADCASTS = 'BROADCASTS',
  AUTOPAYMENT = 'AUTOPAYMENT',
  MESSAGE_MATCHERS = 'MESSAGE_MATCHERS',
  SUBSCRIBERS = 'SUBSCRIBERS',
}

enum EFixedCacheKey {
  SIGN_IN = 'SIGN_IN',
  CREATE_PARAMETER = 'CREATE_PARAMETER',
  SUBSCRIBER_TAGS = 'SUBSCRIBER_TAGS',
}

const rootApi = createApi({
  reducerPath: 'rootApi',
  tagTypes: Object.values(ECacheTag),
  baseQuery: axiosBaseQuery({baseUrl: '/rest'}),
  endpoints: () => ({}),
});

const listenerMiddleware = createListenerMiddleware<TRootState>();

const isMatchQueryMeta =
  (meta: TMeta) =>
  (action: any): action is AnyAction =>
    Object.entries(meta).every(([key, value]) => action.meta?.baseQueryMeta?.[key] === value);

listenerMiddleware.startListening({
  matcher: isAllOf(isRejected, isMatchQueryMeta({isShowError: true, isBackendError: true})),
  effect: (action) => {
    errorNotification(
      action.payload,
      action.meta.baseQueryMeta.errorMessageKey,
      action.meta.baseQueryMeta.i18nOptions,
    );
  },
});

listenerMiddleware.startListening({
  matcher: isAllOf(isFulfilled, isMatchQueryMeta({isShowSuccess: true})),
  effect: (action) => {
    showNewNotification({
      type: 'success',
      messageKey: action.meta.baseQueryMeta.successMessageKey,
    });
  },
});

export {
  rootApi,
  ECacheTag,
  listenerMiddleware,
  axiosBaseQuery,
  EFixedCacheKey,
  isMatchQueryMeta,
  CACHE_LIFETIME,
  TMeta,
};
