import {EUrlType} from './definitions';

export default {
  addCallback: 'Add a callback URL',
  changeCallback: 'Change URL',
  modalNote: 'URL verification is required for the settings to work correctly',
  modalText: 'Insert the callback URL (HTTPS only, port 443)',
  errorMessage: 'The requested resource does not exist',
  jivoConnectedMessage:
    'You cannot change the callback URL, since you have an available Jivo module connected.',
  callbackUrlType: {
    [EUrlType.IN_MESSAGE]: {
      title: 'Callback URL for incoming messages',
      description:
        'You receive incoming messages from your clients via the specified URL.The URL address you specify must exist and be available for a request from the public Internet. To verify its availability, we will send a HEAD request, to which a response with the status 200 is expected. Otherwise, we won’t be able to register this callback URL.',
      add: {
        title: 'Add a callback URL for incoming messages',
      },
      change: {
        title: 'Change the callback URL for incoming messages?',
      },
      delete: {
        title: 'Do you want to delete the callback for incoming messages?',
        text: 'Then you will not be able to receive incoming messages from clients.',
        notification: 'Callback URL for incoming messages has been removed.',
      },
    },
    [EUrlType.STATUS]: {
      title: 'Callback URL for broadcast statuses',
      description:
        'You receive statuses of the broadcasts via the specified URL. The URL address you specify must exist and be available for a request from the public Internet. To verify its availability, we will send a HEAD request, to which a response with the status 200 is expected. Otherwise, we won’t be able to register this callback URL.',
      add: {
        title: 'Add a callback URL for broadcast statuses',
      },
      change: {
        title: 'Change the callback URL for broadcast statuses?',
      },
      delete: {
        title: 'Do you want to delete the callback for statuses?',
        text: "You won't be able to receive the statuses of the broadcasts.",
        notification: 'Сallback URL for statuses has been removed.',
      },
    },
    [EUrlType.MESSAGE_MATCHER]: {
      title: 'Callback URL for managing API',
      description:
        'You receive updates on changes in settings via the specified URL. The URL address you specify must exist and be available for a request from the public Internet. To verify its availability, we will send a HEAD request, to which a response with the status 200 is expected. Otherwise, we won’t be able to register this callback URL.',
      add: {
        title: 'Add a callback URL for managing API',
      },
      change: {
        title: 'Change the callback URL for managing API?',
      },
      delete: {
        title: 'Do you want to delete the callback URL for managing API?',
        text: 'You will not be able to receive updates on changes in settings.',
        notification: 'The callback URL for managing API has been successfully deleted',
      },
    },
  },
  yourAPIKey: 'Your API key',
  APIKeyDescription:
    "You can integrate edna Pulse with any app by accessing broadcasts via APIs. For more information about API methods,<br/> refer to <0>the developer's manual</0>.",
  generateAPIKey: 'Generate an API key',
  generateNewAPIKey: 'Generate a new API key',
  changeAPIKeyModal: {
    title: 'Change the API key?',
    message:
      'Once you change the API key, the traffic you receive through it will stop. Copy the new key and paste it into your system for it to work correctly.',
  },
  callbackAuth: 'Callback authentication',
  callbackAuthHint:
    'You can enable the authentication of callbacks sent from edna Pulse to your server. Enable authentication to generate an API key for callbacks that edna Pulse sends to your API endpoint.',
  callbackAuthSwitch: 'Authenticate callbacks from edna Pulse',
  generateNewCallbackKey: 'Generate a new callback key',
  authErrorText: "Couldn't complete the request. Try again later.",
  copyCallbackKey: 'Copy your API key',
  newCallbackKeyHint:
    'Copy your API key now and share it with a developer or store it safely. You can see and copy the whole key only once. To secure your data, the key will be hidden after that.',
};
