import React from 'react';

const usePromise = (onResolve: TAnyFunction, onReject: TAnyFunction) => {
  const resolveRef = React.useRef<TAnyFunction>(() => {});
  const rejectRef = React.useRef<TAnyFunction>(() => {});

  const onResolveRef = React.useRef(onResolve);
  const onRejectRef = React.useRef(onReject);

  React.useEffect(() => {
    onResolveRef.current = onResolve;
  }, [onResolve]);

  React.useEffect(() => {
    onRejectRef.current = onReject;
  }, [onReject]);

  React.useEffect(() => {
    new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    })
      .then(() => onResolveRef.current())
      .catch(() => onRejectRef.current());
  }, []);

  const handleResolve = React.useCallback(() => {
    resolveRef.current();
  }, []);

  const handleReject = React.useCallback(() => {
    rejectRef.current();
  }, []);

  return {resolve: handleResolve, reject: handleReject};
};

export {usePromise};
