import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {routeChanged} from 'src/models/actions';

const slice = createSlice({
  name: 'accountStatement',
  initialState: {
    filters: {},
  },
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeChanged, (state) => {
      state.filters = {};
    });
  },
});

export const {setFilters: setFiltersAction} = slice.actions;
export const selectFilters = (state: TRootState) => state.accountStatement.filters;

export default slice.reducer;
