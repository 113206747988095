import {EPushAppType} from '../definitions';

export default {
  setup: 'Начать настройку',
  saveContinue: 'Сохранить и продолжить',
  testPush: 'Протестировать пуши',
  [EPushAppType.IOS]: {
    new: {
      title: 'Проверьте, что располагаете необходимым для настройки:',
      list: [
        {
          text: 'Приложение iOS',
        },
        {
          text: `Устройство iOS (iPhone, iPad, iPod Touch) для тестирования. Симулятор Xcode не поддерживает
          пуш-уведомления, поэтому тестируйте на физическом устройстве`,
        },
        {
          text: 'Компьютер Мас с последней версией Xcode',
        },
        {
          text: 'Сертификат Apple Production Push Certificate',
          hint: 'Сертификат для отправки пуш-уведомлений из developer.apple.com, экспортированный в формате .p12 с паролем через Связку ключей. Мы не сможем принять p12 без пароля',
        },
        {
          text: 'Утилита CocoaPods или Swift Package Manager',
        },
      ],
      document: {
        text: 'Полная инструкция по настройке iOS-приложения',
      },
    },
    step1: {
      attach: 'Прикрепить',
      title: 'Создайте AppGroup и добавьте production сертификат Apple Push Services',
      number1: {
        label: 'Авторизуйтесь в своем аккаунте ',
        text: `Выберите Certificates, Identifiers & Profiles. Откройте вкладку <strong>Identifiers</strong> в левом меню и проверьте, зарегистрирована ли AppGroup для вашего приложения.

        Идентификатор группы должен соответствовать идентификатору приложения. Например для приложения com.edna.push.demoapp группа должна называться group.com.edna.push.demoapp.

        Если группы еще нет, нажмите «+» справа от заголовка Identifiers и зарегистрируйте новую AppGroup. Запомните идентификатор, он потребуется в дальнейшем.`,
        imageLabel: 'Создание AppGroup в личном кабинете Apple Developer',
        url: 'https://developer.apple.com/',
        urlText: 'Apple developer',
      },
      number2:
        'Получите в Apple сертификат Production Push Certificate. <0>Как получить сертификат</0>',
      number3: {
        label: 'Введите Bundle ID приложения',
        inputLabel: 'Bundle ID приложения',
        inputHint: 'Bundle ID это уникальный идентификатор приложения, например io.edna.app',
      },
      number4: {
        label: 'Прикрепите файл production сертификата в формате .p12 и введите пароль от него',
        password: 'Пароль файла сертификата .p12',
        passwordHint:
          'Пароль от сертификата .p12, который ввели при экспорте из Связки ключей. Мы не сможем принять p12 без пароля',
      },
    },
    step2: {
      title: 'Создайте расширение Notification Service Extension',
      number1: {
        label: 'В проекте приложения в Xcode добавьте новый target (File > New > Target):',
        imageLabel: 'Добавление нового target к проекту приложения',
      },
      number2: {
        label:
          'В окне «Choose a template for your new target» выберите тип расширения Notification Service Extension.',
        imageLabel: 'Выбор шаблона расширения для нового target проекта',
      },
      number3: {
        label:
          'Для основного приложения на вкладке Signing & Capabilities подключите созданную на предыдущем шаге AppGroup: добавьте Capability «Push Notifications» и «Background Modes» — «Remote notification»',
        imageLabel: 'Добавление настроек и разрешений для основного приложения',
      },
      number4: {
        label:
          'Для расширения Notification Service Extension на вкладке Signing & Capabilities подключите AppGroup и добавьте Capability «Push Notifications»',
        imageLabel:
          'Добавление настроек и разрешений для расширения Notification Service Extension',
      },
      number5: {
        label:
          'В файлах Info.plist в параметре edna_app_group для приложения и расширения укажите имя AppGroup',
        imageLabel: 'Добавление информации о AppGroup в Info.plist',
      },
    },
    step3: {
      title: 'Подключите библиотеки edna к проекту',
      number1: 'Подключите библиотеки edna с помощью CocoaPods или SwiftPackageManager.',
      mainApp: '<strong>Подключение библиотеки для основного приложения:</strong>',
      swift: 'Подключение через Swift Package Manager:',
      cocoapods: 'Подключение через CocoaPods:',
      notificationService:
        '<strong>Подключение библиотеки для расширения Notification Service Extension:</strong>',
    },
    step4: {
      title: 'Интегрируйте библиотеку в коде приложения',
      number1: 'В файле AppDelegate проекта добавьте следующую строку для подключения библиотеки:',
      number2:
        'Вставьте уникальный ключ приложения <strong>appId</strong> и инициализируйте библиотеку в методе AppDelegate <strong>applicationDidFinishLaunchingWithOptions</strong>:',
      number3:
        'Передайте в библиотеку системные вызовы в методах AppDelegate <strong>didRegisterForRemoteNotificationsWithDeviceToken</strong> и <strong>didReceiveRemoteNotification</strong>:',
      number4: 'Замените код основного класса Notification Service Extension на следующий:',
      number5:
        'Если пользователь нажмет на область или кнопки уведомления, будет запущено приложение. Чтобы получить от библиотеки информацию о том, что приложение было запущено по нажатию на уведомление, в метод <strong>EDNAPushX.setOnPushAction</strong> передайте замыкание:',
      message:
        'Метод EDNAPushX.setOnPushAction нужно вызвать до инициализации библиотеки — до вызова метода EDNAPushX.initWithLaunchOptions.',
      codeComments: {
        uniqueAppKey: 'Уникальный ключ приложения',
        autoRegister: 'Автоматически показывать диалог с запросом на показ пуш-уведомлений',
      },
    },
    step5: {
      title: 'Поддержите регистрацию устройств пользователей в edna Pulse',
      automaticRegistrationDescription:
        'Устройство пользователя появится в сервисе edna после регистрации. Доступны автоматический и ручной способы регистрации устройств. При автоматической регистрации мобильная пуш-библиотека edna регистрирует устройство в edna Pulse после вызова метода login со стороны мобильного приложения. Этот способ проще, не требует от вас получать адрес устройства и управлять регистрацией с бэка, но не рекомендуется для отправки конфиденциальных данных.',
      manualRegistrationDescription:
        'В ручной регистрации ваш бэкенд должен получить с мобильного приложения адрес устройства и зарегистрировать устройство в сервисе edna через <0>метод registration публичного API</0>',
      autoDescription: {
        text: 'Библиотека edna самостоятельно зарегистрирует пользователя и устройство на платформе edna.',
        number2: {
          label:
            'Для регистрации пользователя в сервисе edna вызовите метод login библиотеки, указав идентификатор пользователя subscriberId и его тип SubscriberIdType.',
          text: 'Следует вызывать метод только после успешной авторизации пользователя в вашем приложении.',
        },
        number3:
          'В момент деавторизации пользователя в приложении, вызовите метод logout библиотеки, чтобы отключить устройство пользователя в сервисе edna:',
      },
      manualDescription: {
        number2:
          'Для регистрации пользователя в сервисе edna получите от мобильной библиотеки адрес устройства deviceAddress и вызовите <0>метод registration публичного API</0>, указав идентификатор пользователя subscriberId, его тип SubscriberIdType и адрес устройства deviceAddress.\n' +
          'Следует вызывать метод только после успешной авторизации пользователя в вашем приложении',
        number3:
          'В момент деавторизации пользователя в приложении, вызовите <0>метод logout публичного API</0>, чтобы отключить устройство пользователя в сервисе edna.',
      },
      message:
        'Допускаются следующие виды SubscriberIdType: номер телефона (PhoneNumber), адрес электронной почты (Email), идентификаторы Фейсбук, Телеграм, Гугл, Эпл, Яндекс или ваш внутренний идентификатор пользователя (FaceBookId, TelegramId, GoogleId, AppleId, YandexId, ExtUserId, CookieId), а также UTM.',
      number1: {
        label: 'Выберите тип регистрации устройства на платформе:',
        autoRegistration:
          'Автоматическая регистрация: все устройства сами регистрируются на платформе – подходит для отправки рекламных и маркетинговых сообщений',
        manualRegistration:
          'Ручная регистрация: ваш бэкенд регистрирует устройства через <0>метод registration публичного API</0> – подходит для отправки всех типов сообщений',
      },
    },
    step6: {
      title: 'Подключите Notification Content Extension',
      text: `Если сильно или продолжительно нажать на уведомление, откроется его расширенный вид. По умолчанию, для расширенного уведомления используются данные основного уведомления – заголовок, текст и логотип. Вид данного уведомления можно настроить с помощью расширения Notification Content Extension.

      Для работы с Notification Content Extension edna предоставляет дополнительную библиотеку, которая позволяет отображать расширенную версию уведомления. В расширенное уведомление передаются заголовок, расширенный текст и дополнительное изображение. Также библиотека позволяет добавить кнопки действия к расширенному уведомлению.`,
      number1: {
        label:
          'В Xcode-проекте добавьте новый target, как и для Notification Service Extension. Далее в окне «Choose a template for your new target» выберите тип расширения Notification Content Extension.',
        imageLabel:
          'Добавление необходимых настроек и разрешений для расширения Notification Content Extension',
      },
      number2: {
        label:
          'Подключите библиотеку для расширения Notification Content Extension с помощью Swift Package Manager или CocoaPods.',
        swift: 'Подключение через Swift Package Manager: ',
        cocoapods: 'Подключение через CocoaPods:',
      },
      number3: 'Замените код основного класса Notification Content Extension на следующий:',
      number4:
        'Укажите название extension-категории ednaPushCategory в файле Info.plist расширения, в параметре NSExtension/NSExtensionAttributes/UNNotificationExtensionCategory. Уведомления, требующие расширенной верстки, будут отправлены с extension-категорией <strong>ednaPushCategory</strong>.',
      number5:
        'Убедитесь, что для параметра NSExtension / NSExtensionAttributes / UNNotificationExtensionDefaultContentHidden указано значение YES (1). Данный параметр позволяет скрыть информацию из оригинального уведомления при отображении расширенного вида.',
    },
    step7: {
      title: 'Скомпилируйте и запустите приложение',
      message: 'Учтите при компиляции:',
      messageList: [
        'Подключите к интернету устройство, на котором будет тестироваться сервис',
        'Тестируйте получение пушей на физическом устройстве (эмулятор XCode не поддерживает пуш-уведомления)',
        'Одобрите показ пуш-уведомлений в диалоговом окне после запуска приложения',
      ],
    },
    step8: {
      title: 'Проверьте подключение устройства и протестируйте получение пуша',
    },
  },
  [EPushAppType.ANDROID]: {
    new: {
      title: 'Проверьте, что располагаете необходимым для настройки:',
      list: [
        {
          text: 'Проект приложения Андроид с minSdk не ранее 21 (Андроид 5) и targetSdk не позже 34 (Андроид 14)',
        },
        {text: 'Файл JSON сервисного аккаунта Google. <0>Как получить файл</0>'},
        {text: 'Файл google-services.json из Firebase. <0>Как получить файл</0>'},
        {
          text: 'Устройство Андроид 5.0 и новее или эмулятор с сервисами Google Play',
          hint: 'Для проверки облака Huawei потребуется устройство с сервисами Huawei',
        },
        {text: 'Актуальная версия Android Studio'},
      ],
      document: {
        text: 'Полная инструкция по настройке приложения на Андроид',
      },
    },
    step1: {
      title: 'Введите данные из Firebase',
      huaweiClientId: 'Huawei Client Id',
      huaweiClientSecret: 'Huawei Client Secret',
      appSetup: {
        number1: 'Зарегистрируйте приложение в Firebase. <0>Как зарегистрировать приложение</0>',
        number2: 'Введите параметры',
      },
      addHuawei: 'Добавить приложение Huawei',
      huaweiSetup: {
        number1:
          'Зарегистрируйте приложение в Huawei AppGallery. <0>Как зарегистрировать приложение</0>',
        number2:
          'Получите параметры Huawei Client Id и Client Secret. <0>Как получить параметры</0>',
        number3: 'Введите параметры',
      },
      checkbox: 'Подключить пуш-облако Huawei',
      nameApp: {
        label: 'Имя приложения',
        hint: 'Имя пакета приложения (app package), например io.edna.app',
      },
      huaweiAppIDHint: 'Параметр Huawei Client Id из консоли Huawei',
      huaweiAppSecretHint: 'Ключ Huawei Client Secret из консоли Huawei',
    },
    step2: {
      title: 'Добавьте зависимости в файл build.gradle',
      number1:
        'Добавьте зависимости в файл <i>build.gradle</i> проекта — укажите адрес репозитория edna и добавьте в список зависимостей сервисы Google:',
      number2:
        'В файл <i>build.gradle</i> приложения подключите плагин и добавьте библиотеку edna в список зависимостей:',
      number3:
        'Укажите в <i>манифесте приложения</i> следующую строку с ключом доступа к пуш-сервису edna:',
      number4:
        'В корневую директорию <i>app</i> приложения добавьте файл google-services.json, полученный в консоли Firebase для приложения.',
      huaweiNumber4:
        'В корневую директорию <i>app</i> приложения добавьте файл google-services.json, полученный в консоли Firebase для вашего приложения, а также файл agconnect-services.json, полученный в Huawei AppGallery.',
      number5: `Библиотека edna по умолчанию инициализируется автоматически. Но если в приложении реализован <0>Configuration.Provider</0>, используйте <1>ручную инициализацию библиотеки</1>.`,
    },
    step3: {
      title: 'Добавьте код обработки нажатий на уведомление',
      text: `Если пользователь нажмет на уведомление или кнопки действий, то попадет в приложение.

      Библиотека edna обработает информацию о переходе пользователя и направит Intent в стартовое Activity приложения. Обработайте полученную от библиотеки edna информацию в методах onCreate и onNewIntent приложения.

      Библиотека edna передаст в Intent строку действия, указанную вами в рассылке. Пример кода для обработки перехода клиента по уведомлению:`,
      codeComments: {
        action: 'Hавигация клиента с учётом переданной строки, где PUSH_ACTION_KEY = "action"',
      },
    },
    step4: {
      title: 'Поддержите регистрацию устройств пользователей в сервисе edna',
      automaticRegistrationDescription:
        'Устройство пользователя появится в сервисе edna после регистрации. Доступны автоматический и ручной способы регистрации устройств. При автоматической регистрации мобильная пуш-библиотека edna регистрирует устройство в edna Pulse после вызова метода login со стороны мобильного приложения. Этот способ проще, не требует от вас получать адрес устройства и управлять регистрацией с бэка, но не рекомендуется для отправки конфиденциальных данных.',
      manualRegistrationDescription:
        'В ручной регистрации ваш бэкенд должен получить с мобильного приложения адрес устройства и зарегистрировать устройство в сервисе edna через <0>метод registration публичного API</0>',
      number1: {
        label: 'Выберите тип регистрации устройства на платформе:',
        autoRegistration:
          'Автоматическая регистрация: все устройства сами регистрируются на платформе – подходит для отправки рекламных и маркетинговых сообщений',
        manualRegistration:
          'Ручная регистрация: ваш бэкенд регистрирует устройства через <0>метод registration публичного API</0> – подходит для отправки всех типов сообщений',
      },
      autoDescription: {
        text: 'Библиотека edna самостоятельно зарегистрирует пользователя и устройство на платформе edna.',
        number2: {
          warning:
            'Допускаются следующие виды SubscriberIdType: номер телефона (PhoneNumber), адрес электронной почты (Email), идентификаторы Фейсбук, Телеграм, Гугл, Эпл, Яндекс или ваши внутренние идентификаторы пользователя (FaceBookId, TelegramId, GoogleId, AppleId, YandexId, ExtUserId, CookieId), UTM-метка (UTM)',
          label:
            'Для регистрации пользователя в сервисе вызовите метод login библиотеки, укажите идентификатор пользователя и тип идентификатора SubscriberIdType',
          text: 'Следует вызывать метод только после успешной авторизации пользователя в вашем приложении. Пример:',
        },
        number3:
          'В момент деавторизации пользователя в приложении, вызовите метод logout библиотеки, чтобы отключить устройство пользователя в сервисе edna:',
      },
      manualDescription: {
        number2:
          'Для регистрации пользователя в сервисе edna получите от мобильной библиотеки адрес устройства deviceAddress и вызовите <0>метод registration публичного API</0>, указав идентификатор пользователя subscriberId, его тип SubscriberIdType и адрес устройства deviceAddress.\n' +
          'Следует вызывать метод только после успешной авторизации пользователя в вашем приложении',
        number3:
          'В момент деавторизации пользователя в приложении, вызовите <0>метод logout публичного API</0>, чтобы отключить устройство пользователя в сервисе edna.',
      },
    },
    step5: {
      title: 'Скомпилируйте и запустите приложение',
      message:
        'Подключите к интернету устройство, на котором будете запускать скомпилированное приложение',
    },
    step6: {
      title: 'Проверьте подключение устройства и протестируйте получение пуша',
    },
  },
  lastStep: {
    title: 'Проверьте подключение устройства и протестируйте получение пуша',
    infoMessage:
      'После компиляции и запуска приложения требуется время для регистрации устройства в edna. Не уходите далеко',
    successMessage: 'Почти у цели! Устройство {{deviceName}} успешно подключено',
    errorMessage: 'Что-то пошло не так. Ваше устройство не подключилось к пуш сервису',
    appRecommended: {
      title: 'Что нужно проверить на этом шаге:',
      list: [
        {text: 'На шаге 1 верно указали Имя приложения'},
        {text: 'Указали правильный appId в коде приложения'},
        {text: 'На устройстве подключен интернет'},
      ],
      paragraph:
        'Если после этого устройство не подключается, посмотрите возможные ошибки в <0>нашей инструкции</0>',
      errorMessage: 'Что-то пошло не так. Ваше устройство не подключилось к пуш сервису.',
      button: 'Попробовать еще раз',
    },
    pushRecommended: {
      title: 'Что нужно проверить на этом шаге:',
      list: [
        {text: 'На устройстве подключен интернет'},
        {text: 'Приложению на тестовом устройстве разрешено получать пуши'},
      ],
      paragraph: 'Не сработало? Посмотрите возможные ошибки в <0>нашей инструкции</0>',
      errorMessage: 'Если вы не получили тестовый пуш, то ранее где-то произошла ошибка',
      button: 'Отправить тестовый пуш',
    },
    modal: {
      title: 'Поздравляем! Вы успешно настроили пуш-уведомления!',
      text: 'Теперь вы можете отправлять пуш-уведомления в мобильное приложение',
    },
    question: `Мы отправили тестовое пуш-уведомление на ваше устройство, в течение минуты вы должны получить его.
        Получили?`,
  },
};
