import {ESortDirection} from 'src/models';

const PAGE_SIZE = 5;

const LIST_CONFIG = {
  sortConfig: {
    defaultPrimarySort: {property: 'updatedAt', direction: ESortDirection.DESC},
  },
  paginationConfig: {
    pageSize: PAGE_SIZE,
  },
};

enum EWidgetType {
  Licenses = 'Licenses',
  Payments = 'Payments',
  Broadcasts = 'Broadcasts',
  Channels = 'Channels',
  Templates = 'Templates',
}

export {LIST_CONFIG, PAGE_SIZE, EWidgetType};
