import {TChannelType} from 'src/constants';
import {TBroadcastContentHolder} from 'src/containers/Broadcasts/definitions';
import {TItem as TCascadeItem} from 'src/containers/Cascades/api';
import {
  ETemplateButtonTypes,
  TItem as TMessageMatcherItem,
} from 'src/containers/MessageMatchers/types';
import {TItem as TTagsItem} from 'src/containers/Tags/definitions';
import {uuidv4} from 'src/utils';

import {STEP_HEIGHT, STEP_WIDTH} from './Canvas/components/Step/constants';

// Flow - модель-заголовок, описываюещая цепочку.

// Flow может иметь версии, так при изменении уже запущенной цепочки,
// пользователя уже ведущие диалог с ботом, продолжат продвигаться по старой версии сценария,
// а новые пользователи будут начинать диалог уже по новой версии цепочки.

// Для этого в модели присутствуют два вида идентификатора:
// 1) uuid - уникальный для каждой версии
// 2) flowId - общий для всех версий

export enum EFlowStatus {
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  DELETED = 'DELETED',
  FINISHED = 'FINISHED',
}

export type TDayInterval = {isSet: boolean; start: string; end: string};

export enum EScheduleType {
  DAILY_SCHEDULE = 'DAILY_SCHEDULE',
  SINGLE = 'SINGLE',
}
export type TDailySchedule = {
  type: EScheduleType.DAILY_SCHEDULE;
  timeZone: string;
  dayIntervals: Record<string, TDayInterval>;
};
export type TSingleSchedule = {type: EScheduleType.SINGLE};
export type TSchedule = TDailySchedule | TSingleSchedule;

export type TFlowStatus = keyof typeof EFlowStatus;

export type TFlow = {
  id: string;
  flowId: string;
  subjectId: number;
  version: number;
  tenantId: number;
  name: string;
  description: string;
  status: TFlowStatus;
  startAt?: string;
  endAt?: string;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  activatedAt?: string;
  sentToSubscribers?: number;
  receiveNow?: number;
  completed?: number;
  onlyOnes: boolean;
  schedule?: TSchedule;
  totalSubscribers: number;
  activeSubscribers: number;
  endedSubscribers: number;
};

export enum ETriggerType {
  CONDITION = 'CONDITION',
  ACTION = 'ACTION',
  EVENT = 'EVENT',
}

// FlowStep - шаг цепочки
export enum EFlowStepType {
  TRIGGER = 'TRIGGER', // триггер для запуска цепочки
  CONDITION = 'CONDITION', // условный оператор внутри цепочки
  ACTION = 'ACTION', // действие внутри цепочки
}

export enum ETrigger {
  CONDITION_TRIGGER_STEP = 'CONDITION_TRIGGER_STEP', // шаг-условие запуска цепочки
  GENERATOR_TRIGGER_STEP = 'GENERATOR_TRIGGER_STEP',
}

export enum EUITrigger {
  CONDITION_TRIGGER_STEP = 'CONDITION_TRIGGER_STEP', // шаг-условие запуска цепочки
  GENERATOR_TRIGGER_STEP = 'GENERATOR_TRIGGER_STEP',
  EMPTY_TRIGGER_STEP = 'EMPTY_TRIGGER_STEP',
}

export enum ECondition {
  TWO_BRANCH_CONDITION_STEP = 'TWO_BRANCH_CONDITION_STEP', // шаг-условие да/нет
  MULTI_BRANCH_CONDITION_STEP = 'MULTI_BRANCH_CONDITION_STEP', // шаг-условие в стиле switch-case-default
}

export enum EAction { // Действия:
  SINGLE_ACTION_STEP = 'SINGLE_ACTION_STEP', // одинарное действие
}

export enum EConditionType { // типы условий
  //OUT_MESSAGE_CONDITION = 'OUT_MESSAGE_CONDITION', // условие: отправка исходящего сообщения
  EVENT_OCCURRED_CONDITION = 'EVENT_OCCURRED_CONDITION', // случилось событие определенного типа
  IN_MESSAGE_PAYLOAD_IN_CONDITION = 'IN_MESSAGE_PAYLOAD_IN_CONDITION', // payload входящего сообщения содержит что-то из списка (нажата кнопка из списка)
  IN_MESSAGE_BUTTON_PAYLOAD_CONDITION = 'IN_MESSAGE_BUTTON_PAYLOAD_CONDITION',
  //IN_MESSAGE_SELECTED_ITEMS_CONDITION = 'IN_MESSAGE_SELECTED_ITEMS_CONDITION', // то же что и PAYLOAD_IN, но для ListPicker
  TAG_CONDITION = 'TAG_CONDITION', // добавлен тэг
  TAG_QUERY_CONDITION = 'TAG_QUERY_CONDITION',
  TAG_QUERY_WITH_DELAY_CONDITION = 'TAG_QUERY_WITH_DELAY_CONDITION',
}

export enum EUIConditionType { // типы условий
  EMPTY = 'EMPTY',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  BUTTON_PRESSED = 'BUTTON_PRESSED',
  TAG_ASSIGN_TIMEOUT_CONDITION = 'TAG_ASSIGN_TIMEOUT_CONDITION',
  TAG_PRESENCE_CONDITION = 'TAG_PRESENCE_CONDITION',
}

export enum EEventType { // тип события, на которое среагирует условие.
  OUT_MESSAGE_FLOW_EVENT = 'OUT_MESSAGE_FLOW_EVENT', // исходящее сообщение (рассылка)
  IN_MESSAGE_FLOW_EVENT = 'IN_MESSAGE_FLOW_EVENT', // входящее сообщение
  ADD_TAG_FLOW_EVENT = 'ADD_TAG_FLOW_EVENT', // подписчику добавлен тэг
  DIALOG_FLOW_EVENT = 'DIALOG_FLOW_EVENT', // переключение диалога в роутере, например, активация диалога с ботом, окончание треда в СС
  // Внутренние типы событий:
  EXECUTE_NEXT_STEP_COMMAND = 'EXECUTE_NEXT_STEP_COMMAND', // переход на следующий шаг цепочки
  EXPIRE_ASYNC_CONDITION_COMMAND = 'EXPIRE_ASYNC_CONDITION_COMMAND', // просрочено ожидание события для условия
  START_GENERATOR_TRIGGER_FLOW_EVENT = 'START_GENERATOR_TRIGGER_FLOW_EVENT',
}

export enum EActionType { // Типы действий
  SEND_MESSAGE_ACTION = 'SEND_MESSAGE_ACTION', // отправка сообщения абоненту
  CC_DIALOG_SWITCH_ACTION = 'CC_DIALOG_SWITCH_ACTION', // переключение диалога на СС
  ADD_SUBSCRIBER_TAG_ACTION = 'ADD_SUBSCRIBER_TAG_ACTION', // добавление тэга/тёгов абоненту
  DELETE_SUBSCRIBER_TAG_ACTION = 'DELETE_SUBSCRIBER_TAG_ACTION', // удаление тэга/тэгов у абонента
  PAUSE_ACTION = 'FLOW_PAUSE_ACTION', // пауза цепочки
}

export enum ETransition {
  EDGE = 'EDGE', // тип перехода EDGE (других пока нет)
}

export type TTransition = {
  id: string;
  type: ETransition;
  toStepId: string;
};

type TGenericCondition = {
  id: string;

  //subject?: string;
  //payloadIn?: string[];
  //eventType?: EEventType;
  //eventCondition?: TEventCondition;
  //expirationDuration?: string;
};

type TGenericUICondition = {
  id: string;
  disabled: boolean;

  //subject?: string;
  //payloadIn?: string[];
  //eventType?: EEventType;
  //eventCondition?: TEventCondition;
  //expirationDuration?: string;
};

export type TUIButtonPressedCondition = TGenericUICondition & {
  type: EUIConditionType.BUTTON_PRESSED;
  text: string;
  payload: string;
  buttonType: ETemplateButtonTypes;
};

export type TUIMessageReceivedCondition = TGenericUICondition & {
  type: EUIConditionType.MESSAGE_RECEIVED;
};

export type TUITagAssignTimeoutCondition = TGenericUICondition & {
  type: EUIConditionType.TAG_ASSIGN_TIMEOUT_CONDITION;
  tagQueryId: number;
};

export type TUITagPresenceCondition = TGenericUICondition & {
  type: EUIConditionType.TAG_PRESENCE_CONDITION;
  tagQueryId: number;
};

export type TUIEmptyCondition = TGenericUICondition & {
  type: EUIConditionType.EMPTY;
};

export type TUICondition =
  | TUITagAssignTimeoutCondition
  | TUIMessageReceivedCondition
  | TUIButtonPressedCondition
  | TUITagPresenceCondition;

export type TInMessagePayloadCondition = TGenericCondition & {
  type: EConditionType.IN_MESSAGE_PAYLOAD_IN_CONDITION;
  payloadIn?: string[];
};

export type TEventOccurredCondition = TGenericCondition & {
  type: EConditionType.EVENT_OCCURRED_CONDITION;
};

export type TInMessageButtonPayloadCondition = TGenericCondition & {
  type: EConditionType.IN_MESSAGE_BUTTON_PAYLOAD_CONDITION;
  button: {
    payload: string;
    buttonType: ETemplateButtonTypes;
    text: string;
  };
};

export type TTagCondition = TGenericCondition & {
  type: EConditionType.TAG_CONDITION;
  tagValues: TTagsItem[];
};

export type TTagQueryCondition = TGenericCondition & {
  type: EConditionType.TAG_QUERY_CONDITION;
  tagQueryId: number;
};

export type TTagQueryWithDelayCondition = TGenericCondition & {
  type: EConditionType.TAG_QUERY_WITH_DELAY_CONDITION;
  tagQueryId: number;
};

export type TCondition =
  | TInMessagePayloadCondition
  | TInMessageButtonPayloadCondition
  | TEventOccurredCondition
  | TTagQueryCondition
  | TTagQueryWithDelayCondition;

type TGenericAction = {
  id: string;
};

export type TAddSubscribersTagAction = TGenericAction & {
  type: EActionType.ADD_SUBSCRIBER_TAG_ACTION;
  tagValues: TTagsItem[];
};

export type TDeleteSubscribersTagAction = TGenericAction & {
  type: EActionType.DELETE_SUBSCRIBER_TAG_ACTION;
  tagValues: TTagsItem[];
};

export type TSendMessageAction = TGenericAction & {
  type: EActionType.SEND_MESSAGE_ACTION;
  messageContent: {
    channelType: TChannelType;
    contentType: TMessageMatcherItem['contentType'];
    contentHolder?: TBroadcastContentHolder;
    messageMatcherId: TMessageMatcherItem['id'] | null;
  } | null;
  messageMatcherId: TMessageMatcherItem['id'];
  cascade?: TCascadeItem;
};

export type TCCDialogSwitchAction = TGenericAction & {
  type: EActionType.CC_DIALOG_SWITCH_ACTION;
};

export type TPauseAction = TGenericAction & {
  type: EActionType.PAUSE_ACTION;
  delay: string; // ISO Duration
  allowedPeriod?: string; // CRON Format
  timezoneDependent?: boolean;
};

export type TAction =
  | TAddSubscribersTagAction
  | TDeleteSubscribersTagAction
  | TSendMessageAction
  | TCCDialogSwitchAction
  | TPauseAction;

export type TActionPayload = {
  type: EAction;
  action?: TAction;
  transition?: TTransition;
};

export type TBranch<T = TCondition> = {
  condition: T;
  transition?: TTransition;
};

export type TEventCondition = {
  id: string;
  type: EConditionType;
  subject: string;
};

// will be implemented later
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TTwoBranchConditionStep = {
  type: ECondition.TWO_BRANCH_CONDITION_STEP;
  onTrue: TTransition;
  onFalse: TTransition;
  condition: TCondition;
};

export type TMultiBranchConditionStep<T = TCondition> = {
  type: ECondition.MULTI_BRANCH_CONDITION_STEP;
  branches: TBranch<T>[];
  defaultBranch?: TTransition;
  eventCondition?: TEventCondition;
};

export type TUIConditionPayload<
  T =
    | TUIButtonPressedCondition
    | TUIMessageReceivedCondition
    | TUITagPresenceCondition
    | TUITagAssignTimeoutCondition,
> = TMultiBranchConditionStep<T>;
export type TConditionPayload<T = TCondition> = TMultiBranchConditionStep<T>;

export enum EGeneratorType {
  BROADCAST_GENERATOR = 'BROADCAST_GENERATOR',
  BROADCAST_GENERATOR_WITH_DELAY = 'BROADCAST_GENERATOR_WITH_DELAY',
}
export type TBroadcastGenerator = {
  id: string;
  type: EGeneratorType;
  tagQueryId: number;
};

export type TGenerator = TBroadcastGenerator;

export type TGeneratorTriggerStep<T = TGenerator> = {
  type: ETrigger.GENERATOR_TRIGGER_STEP;
  eventType: EEventType.START_GENERATOR_TRIGGER_FLOW_EVENT;
  transition?: TTransition;
  switchDialogToBot: boolean;
  generator: T;
};

export type TConditionTriggerStep<T = TInMessagePayloadCondition | TTagCondition> = {
  type: ETrigger.CONDITION_TRIGGER_STEP;
  condition: T;
  eventType: EEventType;
  subjectId?: number;
  transition?: TTransition;
  switchDialogToBot: boolean;
};

export type TTriggerEmptyStep<
  T =
    | TUITagPresenceCondition
    | TUITagAssignTimeoutCondition
    | TUIMessageReceivedCondition
    | TUIEmptyCondition,
> = {
  type: EUITrigger.EMPTY_TRIGGER_STEP;
  condition: T;
};

export type TUITriggerPayload<
  T =
    | TUITagPresenceCondition
    | TUITagAssignTimeoutCondition
    | TUIMessageReceivedCondition
    | TUIEmptyCondition,
> = TTriggerEmptyStep<T>;

export type TTriggerPayload<
  T =
    | TGeneratorTriggerStep<TGenerator>
    | TConditionTriggerStep<TInMessagePayloadCondition | TTagCondition>,
> = T;

//type TPayload = TTriggerPayload | TConditionPayload | TActionPayload;
export type TUIPayload = TUITriggerPayload | TUIConditionPayload | TActionPayload;

type TMeta = {
  position: {
    x: number;
    y: number;
  };
};

type TGenericFlowStep<T> = {
  id: string; // уникальный идентификатор
  flowId: string; // ссылается на Flow.uuid
  flowStepId?: string; // идентификатор, общий для всех версий flow
  description: string;
  type: EFlowStepType;
  enabled: boolean;
  depth: number;
  payload: T;
  meta: TMeta;
  visitCount: number;
  updatedBy?: number;
  createdAt?: string;
  updatedAt?: string;
  error?: string;
};

export type TTriggerStep<T = TTriggerPayload> = TGenericFlowStep<T>;
export type TConditionStep<T = TConditionPayload> = TGenericFlowStep<T>;
export type TActionStep = TGenericFlowStep<TActionPayload>;

export type TFlowStep = TTriggerStep | TConditionStep | TActionStep;

export const makeEmptyCondition = () =>
  ({
    id: uuidv4(),
    type: EUIConditionType.EMPTY,
    disabled: false,
  }) as TUIEmptyCondition;

const PAYLOAD_FACTORY: {
  [EFlowStepType.TRIGGER]: () => TUITriggerPayload<TUIEmptyCondition>;
  [EFlowStepType.CONDITION]: () => TConditionPayload;
  [EFlowStepType.ACTION]: () => TActionPayload;
} = {
  [EFlowStepType.TRIGGER]: () => ({
    type: EUITrigger.EMPTY_TRIGGER_STEP,
    switchDialogToBot: true,
    condition: makeEmptyCondition(),
  }),
  [EFlowStepType.CONDITION]: () => ({
    type: ECondition.MULTI_BRANCH_CONDITION_STEP,
    branches: [],
    defaultBranch: undefined,
  }),
  [EFlowStepType.ACTION]: () => ({
    type: EAction.SINGLE_ACTION_STEP,
    action: undefined,
  }),
};

export const makeFlowStep = ({
  type,
  payload,
}: {
  type: EFlowStepType;
  payload?: TUIPayload;
}): TGenericFlowStep<typeof payload> => ({
  id: uuidv4(),
  visitCount: 0,
  flowId: '',
  meta: {
    position: {x: 0, y: 0},
  },
  description: 'Description',
  type,
  enabled: true,
  depth: 0,
  payload: payload ?? (PAYLOAD_FACTORY[type]() as TUIPayload),
});

export const makeTransition = (toStepId: string) => ({
  type: ETransition.EDGE,
  id: uuidv4(),
  toStepId,
});

const VERTICAL_GAP = 30;

export const migrateMeta = (steps: Array<TFlowStep & {meta: Partial<TMeta>}>): TFlowStep[] =>
  steps.map((step, index) => ({
    ...step,
    meta:
      step.meta.position === undefined
        ? {
            position: {
              x: STEP_WIDTH * (index + 1),
              y: (STEP_HEIGHT + VERTICAL_GAP) * (index + 1),
            },
          }
        : step.meta,
  }));

export type TErrors = Record<TFlowStep['id'], string[]>;
