import React from 'react';
import {Trans} from 'react-i18next';

import formatDate from '@edna/utils/formatDate';

import {useEdnaLinks} from 'src/hooks';

import {ESystemNotificationType, TReleaseNotification, TSystemNotification} from '../model';
import * as S from '../style';

type TProps = {
  item: TSystemNotification;
};

const MaintenanceRunning = React.memo<TProps>(({item}) => (
  <Trans i18nKey={`Notifications:system.${item.type}`} />
));

const UpdateRunning = React.memo<{item: TReleaseNotification}>(({item}) => {
  const link = useEdnaLinks();

  return (
    <Trans
      i18nKey={`Notifications:system.${item.type}`}
      values={{
        date: formatDate(new Date(item.meta.startAt), {format: 'dd.MM.yyyy'}),
        startTime: formatDate(new Date(item.meta.startAt), {format: 'HH:mm'}),
        endTime: formatDate(new Date(item.meta.endAt), {format: 'HH:mm'}),
      }}
    >
      <S.Link href={link.RELEASE} target="_blank" />
    </Trans>
  );
});

const SystemNotification = React.memo<TProps>(({item}) => {
  switch (item.type) {
    case ESystemNotificationType.MaintenanceRunning:
      return <MaintenanceRunning item={item} />;

    case ESystemNotificationType.UpdateRunning:
      return <UpdateRunning item={item} />;

    default:
      return null;
  }
});

SystemNotification.displayName = 'SystemNotification';

export {SystemNotification};
