import {EAutopaymentError} from './definitions';

export default {
  settingsModal: {
    titleConnect: 'Подключить автоплатеж',
    titleEdit: 'Настроить автоплатеж',
    threshold: 'При остатке на балансе менее',
    amount: 'Пополнить счет на',
    minThreshold: 'Минимальный баланс – {{threshold}} {{currency}}',
    minAmount: 'Минимальная сумма пополнения – {{amount}} {{currency}}',
    acceptanceInfo: 'После применения вы будете перенаправлены на сайт платежной системы Stripe',
    licensesInfo:
      'Мы рекомендуем установить баланс не менее <0>{{threshold}} {{currency}}</0>. Это стоимость всех ваших лицензий и минимальный платеж для Viber, если есть.',
    incorrectSettingsWarning:
      'Установленной вами суммы недостаточно для своевременного продления лицензий. Если банк будет списывать средства несколько раз, могут возникнуть перебои в работе сервиса',
  },
  offModal: {
    title: 'Хотите отключить автоплатеж?',
    subtitle:
      'Если отключить автоплатеж, ваши лицензии перестанут продлеваться автоматически. Это значит, что вы не сможете пользоваться нашими услугами и отправлять сообщения вашим клиентам.',
    submitButton: 'Запросить отключение',
  },
  offSupportModal: {
    title: 'Отключить автоплатеж?',
    subtitle:
      'Автоплатеж позволяет автоматически пополнять баланс с банковской карты на указанную сумму. Вы уверены?',
    submitButton: 'Отключить',
  },
  changeCardCreditsModal: {
    title: 'Изменить данные карты для автоплатежа',
    subtitle:
      'Для изменения данных привязанной карты вы будете перенаправлены на сайт платежной системы Stripe',
  },
  actions: {
    connect: 'Подключить автоплатеж',
    connectHint:
      'Автоплатеж позволяет автоматически пополнять баланс с банковской карты на указанную сумму',
    changeSettings: 'Изменить автоплатеж',
    changeCardCredits: 'Изменить данные карты',
    off: 'Отключить автоплатеж',
    restore: 'Возобновить автоплатеж',
  },
  info: 'Когда на балансе будет <1>{{threshold}}</1> <0>{{currency}}</0> он автоматически пополнится на <2>{{amount}}</2> <0>{{currency}}</0>',
  status: {
    connected: 'Автоплатеж подключен',
    paused: 'Автоплатеж приостановлен',
  },
  thresholdWarning:
    'Установленного минимального баланса может не хватить для оплаты ваших регулярных платежей. Мы рекомендуем обновить автоплатеж для своевременного продления лицензий. <0>Обновить автоплатеж</0>',
  errors: {
    [EAutopaymentError.INSUFFICIENT_FUNDS]:
      'Не удалось выполнить списание {{date}}. Недостаточно средств на карте. Пополните карту в течение {{leftDays}} {{leftDays1}}, чтобы повторно выполнить автоплатеж. Если через {{leftDays}} {{leftDays2}} на балансе будет недостаточно средств, автоплатеж будет отключен и услуги приостановлены. Следующая попытка списания будет {{nextDate}}',
    [EAutopaymentError.CARD_DECLINE]:
      'Не удалось выполнить автоплатеж с помощью привязанной карты. Проверьте данные карты',
    [EAutopaymentError.AUTHENTICATION_REQUIRED]:
      'Не удалось выполнить автоплатеж {{date}}. Ваш банк запросил подтверждение платежа. Чтобы подтвердить платеж, перейдите на <0>сайт платежной системы Stripe</0>.',
  },
};
