export default {
  ednaNews: 'Новости edna Pulse',
  date: {
    0: '{{day}} января',
    1: '{{day}} февраля',
    2: '{{day}} марта',
    3: '{{day}} апреля',
    4: '{{day}} мая',
    5: '{{day}} июня',
    6: '{{day}} июля',
    7: '{{day}} августа',
    8: '{{day}} сентября',
    9: '{{day}} октября',
    10: '{{day}} ноября',
    11: '{{day}} декабря',
  },
};
