import React from 'react';
import {useTranslation} from 'react-i18next';

import saveAs from 'file-saver';

import {showNewNotification} from '@edna/components';

import {useLazyGetFileQuery} from './api';

const useDownloadFile = () => {
  const [getFile] = useLazyGetFileQuery();
  const {t} = useTranslation();

  const downloadFile = React.useCallback(
    async (id: string, fileName?: string) => {
      try {
        const file = await getFile({id, fileName}).unwrap();

        saveAs(file);

        showNewNotification({
          type: 'success',
          message: t('Common:successDownload'),
        });
      } catch {}
    },
    [getFile, t],
  );

  return downloadFile;
};

export {useDownloadFile};
