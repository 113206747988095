/* eslint-disable func-style */
import {usePollNotificationsQuery} from './api';
import {
  ESystemNewsType,
  ESystemNotificationEntityType,
  ESystemNotificationType,
  EWebNotificationType,
  TSystemNews,
  TSystemNotification,
  TWebNotification,
} from './model';

type TConfig<T> = {
  pollingInterval?: number;
  skip?: boolean;
  type: T | T[];
};

type TWebConfig = TConfig<keyof typeof EWebNotificationType>;

function useWebNotifications<T extends TWebNotification>(config: TWebConfig): T[];

function useWebNotifications(config?: Omit<TWebConfig, 'type'>): TWebNotification[];

function useWebNotifications<T extends TWebNotification>(
  config: Partial<TWebConfig> = {},
): TWebNotification[] | T[] {
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });

  if (!data) {
    return [];
  }

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return data.webNotifications.filter((notification): notification is T =>
      types.includes(notification.type),
    );
  }

  return data.webNotifications;
}

type TSystemConfig = TConfig<keyof typeof ESystemNotificationType>;

function useSystemNotifications<T extends TSystemNotification>(config: TSystemConfig): T[];

function useSystemNotifications(config?: Omit<TSystemConfig, 'type'>): TSystemNotification[];

function useSystemNotifications<T extends TSystemNotification>(
  config: Partial<TSystemConfig> = {},
): TSystemNotification[] | T[] {
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });

  if (!data) {
    return [];
  }

  const systemNotifications =
    data.systemNotifications?.filter(
      (notification): notification is TSystemNotification =>
        notification.entityType === ESystemNotificationEntityType.NOTIFICATION,
    ) ?? [];

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return systemNotifications.filter((notification): notification is T =>
      types.includes(notification.type),
    );
  }

  return systemNotifications;
}

type TSystemNewsConfig = TConfig<keyof typeof ESystemNewsType>;

function useSystemNews<T extends TSystemNews>(config: TSystemNewsConfig): T[];

function useSystemNews(config?: Omit<TSystemNewsConfig, 'type'>): TSystemNews[];

function useSystemNews<T extends TSystemNews>(
  config: Partial<TSystemNewsConfig> = {},
): TSystemNews[] | T[] {
  const {data} = usePollNotificationsQuery(undefined, {
    pollingInterval: config.pollingInterval,
    skip: config.skip,
  });

  if (!data) {
    return [];
  }

  const news =
    data.systemNotifications?.filter(
      (notification): notification is TSystemNews =>
        notification.entityType === ESystemNotificationEntityType.NEWS,
    ) ?? [];

  if (config.type) {
    const types = Array.isArray(config.type) ? config.type : [config.type];

    return news.filter((newsItem) => types.includes(newsItem.type));
  }

  return news;
}

export {useSystemNews, useWebNotifications, useSystemNotifications};
/* eslint-enable func-style */
