import React from 'react';

import {TSize} from '@edna/components';

import * as S from './style';

type TProps = {
  className?: string;
  size?: TSize;
  isBlockLabel?: boolean;
  isOpenDefault?: boolean;
  disabled?: boolean;
  label: React.ReactNode;
  rightLabel?: React.ReactNode;
  children: React.ReactNode;
};

const CollapsibleSection = React.memo<TProps>(
  ({className, label, rightLabel, disabled, isOpenDefault, size = 'M', isBlockLabel, children}) => (
    <S.StatefulCollapse
      className={className}
      isOpenDefault={isOpenDefault}
      labelComponent={isBlockLabel ? S.BlockCollapseLabel : S.CenterCollapseLabel}
      arrowIcon={size === 'L' ? S.LargeArrowIcon : S.ArrowIcon}
      label={label}
      rightLabel={rightLabel}
      size={size}
      disabled={disabled}
    >
      <S.Children>{children}</S.Children>
    </S.StatefulCollapse>
  ),
);

export default CollapsibleSection;
