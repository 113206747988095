import {PayloadAction, createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'report',
  initialState: {
    filters: {},
  },
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = slice.actions;
export const selectFilters = (state: TRootState) => state.report.filters;

export default slice.reducer;
