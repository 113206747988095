import storage from '@edna/utils/storage';

import {EStorageKeys} from 'src/constants';

const clearStorage = () => {
  storage.remove(EStorageKeys.TENANT_ID);
  storage.remove(EStorageKeys.NEWS);
};

export {clearStorage};
