import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Button, InfoDropdown, TActionButtonOption} from '@edna/components';
import {Icon, Stack} from '@edna/components/primitives';

import {ButtonContent} from 'src/components/primitives';
import {CoinsIcon} from 'src/icons';

import {showAutopaymentSettingsModal} from './AutopaymentSettingsModal';
import {useGetAutopaymentInfoQuery, useRestoreAutopaymentMutation} from './api';
import {EAutopaymentStatus} from './definitions';
import * as S from './style';
import {useAutopaymentEnabled} from './useAutopaymentEnabled';

const Content = React.memo(() => {
  const {t} = useTranslation();

  const handleChangeSettings = () => showAutopaymentSettingsModal(true);

  return (
    <InfoDropdown
      overlayComponent={S.Overlay}
      head={
        <Button onClick={handleChangeSettings} size="S">
          <ButtonContent>
            <Icon as={CoinsIcon} size="16px" />
            {t('Autopayment:actions.connect')}
          </ButtonContent>
        </Button>
      }
      content={t('Autopayment:actions.connectHint')}
    />
  );
});

const AutopaymentConnectButton = React.memo(() => {
  const {id: supportTenantId} = useParams<'id'>();

  const {data: info} = useGetAutopaymentInfoQuery(supportTenantId);

  if (supportTenantId || info) {
    return null;
  }

  return <Content />;
});

const useAutopaymentButtonOption = (): TActionButtonOption | null => {
  const {t} = useTranslation();

  const isAutopaymentEnabled = useAutopaymentEnabled();

  const {data: info} = useGetAutopaymentInfoQuery(undefined, {skip: !isAutopaymentEnabled});
  const [restoreAutopayment] = useRestoreAutopaymentMutation();

  if (!isAutopaymentEnabled) {
    return null;
  }

  if (info?.customerStatus === EAutopaymentStatus.DISABLED) {
    return {
      id: 'autopayment',
      content: (
        <Stack size="3" direction="ROW" align="center">
          <Icon as={CoinsIcon} size="20px" />
          {t('Autopayment:actions.restore')}
        </Stack>
      ),
      handler: () => restoreAutopayment(),
    };
  }

  return {
    id: 'autopayment',
    content: (
      <Stack size="3" direction="ROW" align="center">
        <Icon as={CoinsIcon} size="20px" />
        {t(info ? 'Autopayment:actions.changeSettings' : 'Autopayment:actions.connect')}
      </Stack>
    ),
    handler: () => showAutopaymentSettingsModal(!info),
  };
};

export {AutopaymentConnectButton, useAutopaymentButtonOption};
