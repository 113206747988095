import {EContentType} from 'src/constants';
import {DocumentIcon, ImageIcon, TextIcon, VideoIcon} from 'src/icons';

import {TTHeaderType} from '../types';

export const CONTENT_TYPE_ICON: Record<TTHeaderType, TSvgComponentType> = {
  [EContentType.TEXT]: TextIcon,
  [EContentType.VIDEO]: VideoIcon,
  [EContentType.IMAGE]: ImageIcon,
  [EContentType.DOCUMENT]: DocumentIcon,
};
export enum EWhatsAppCategory {
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
}

// для корректного показа старых категорий шаблонов
export enum EOldWhatsAppCategory {
  TRANSACTIONAL = 'TRANSACTIONAL',
  OTP = 'OTP',
}

export const WHATSAPP_CATEGORIES = Object.values(EWhatsAppCategory);
