import React from 'react';

import {SecondaryText} from 'src/components/primitives';

import * as S from './style';
import {
  EActionsPosition,
  EOrientation,
  ESize,
  TActionsPosition,
  TOrientation,
  TSize,
} from './types';

type TProps = {
  className?: string;
  title: string;
  subtitle?: string;
  text?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  titleComponent?: TAnyComponent | string;
  imageComponent?: TAnyComponent;
  layoutOrientation?: TOrientation;
  layoutSize?: TSize;
  actionsPosition?: TActionsPosition;
  hasTopIndent?: boolean;
};

const PageDialog = React.memo<TProps>(
  ({
    className,
    title,
    content,
    subtitle,
    text,
    actions,
    titleComponent: TitleComponent,
    imageComponent: ImageComponent,
    layoutOrientation = EOrientation.HORIZONTAL,
    layoutSize = ESize.MEDIUM,
    actionsPosition = layoutOrientation === EOrientation.HORIZONTAL
      ? EActionsPosition.LEFT
      : EActionsPosition.RIGHT,
    hasTopIndent = false,
  }) => {
    const renderedActions = actions && (
      <S.Actions
        justifyContent={actionsPosition === EActionsPosition.LEFT ? 'flex-start' : 'flex-end'}
      >
        {actions}
      </S.Actions>
    );

    return (
      <S.PageDialog className={className} orientation={layoutOrientation}>
        <S.Content>
          <S.Title as={TitleComponent} size={layoutSize} hasTopIndent={hasTopIndent}>
            {title}
          </S.Title>
          {subtitle && <S.SubTitle size={layoutSize}>{subtitle}</S.SubTitle>}
          {text && <SecondaryText bottomIndent="3">{text}</SecondaryText>}
          {content}
          {layoutOrientation === EOrientation.HORIZONTAL && renderedActions}
        </S.Content>
        {ImageComponent && <S.Image as={ImageComponent} />}
        {layoutOrientation === EOrientation.VERTICAL && renderedActions}
      </S.PageDialog>
    );
  },
);

PageDialog.displayName = 'PageDialog';

export default PageDialog;
