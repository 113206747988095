import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@edna/components';
import {Icon, Stack} from '@edna/components/primitives';

import {NoShrinkImage, Text} from 'src/components/primitives';
import {LogoImage} from 'src/images';

import * as S from './style';

type TProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  image: TSvgComponentType;
  onClose?: TEmptyFunction;
};

const NewsContent = React.memo<TProps>(({image, title, children, onClose}) => {
  const {t} = useTranslation();

  return (
    <Stack size="4">
      <Stack direction="ROW" justify="space-between" isFullWidth>
        <S.Badge>
          <Icon as={LogoImage} size="12px" />
          <span>{t('News:ednaNews')}</span>
        </S.Badge>
        {onClose && <S.CloseIcon onClick={onClose} />}
      </Stack>
      <Stack direction="ROW" size="10" justify="space-between">
        <Stack size="5">
          <Text size="XL" weight={500} color="black">
            {title}
          </Text>
          <Text>{children}</Text>
          {onClose && (
            <Button size="S" onClick={onClose}>
              {t('Common:button.understand')}
            </Button>
          )}
        </Stack>
        <NoShrinkImage as={image} maxWidth="241px" />
      </Stack>
    </Stack>
  );
});

export {NewsContent};
export type {TProps};
