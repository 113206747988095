import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

import {ArrowBackIcon as BaseArrowBackIcon} from 'src/icons';
import {link} from 'src/styles/mixins';

import {ESize} from './constants';

type TSize = keyof typeof ESize;

type THeaderProps = {
  noPadding: boolean;
};

export const Header = styled.header<THeaderProps>`
  ${mixins.flexAlign('space-between', 'center')}
  ${({theme: {spacing}, noPadding}) => css`
    padding-bottom: ${noPadding ? '0' : spacing.s5};
  `}
`;

export const Title = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
`;

type TH1Props = {
  size: TSize;
};

const getH1Style = ({size}: TH1Props) => {
  switch (size) {
    case ESize.S:
      return mixins.f8;
    case ESize.M:
      return mixins.f12;
    default:
      return mixins.f12;
  }
};

export const H1 = styled.h1<TH1Props>`
  font-weight: 700;
  margin: 0;
  ${getH1Style}
`;

type TArrowWrapperProps = {
  size: TSize;
};

const getArrowWrapperStyle = ({size}: TArrowWrapperProps) => {
  switch (size) {
    case ESize.S:
      return mixins.wh('24px');
    default:
      return mixins.wh('32px');
  }
};

export const ArrowWrapper = styled.div<TArrowWrapperProps>`
  ${link}
  text-decoration: none;
  flex-shrink: 0;
  ${getArrowWrapperStyle}

  ${({theme: {branding, spacing}}) => css`
    color: ${branding.content.primary};
    margin-right: ${spacing.s4};
  `}
`;

export const ArrowBackIcon = styled(BaseArrowBackIcon)`
  ${mixins.wh('100%')}
`;
