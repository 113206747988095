import {EPaymentTab, ESupportProfileTabs} from '../definitions';

//TODO: удалить дублирование локализации для PaymentTab
export default {
  availableTariffs: 'Available pricing',
  title: 'Client card',
  licenseStatus: 'Current license status',
  expiredAt: 'until ',
  responsibleManager: 'Responsible manager edna',
  partner: 'Agent',
  taxNumber: 'Taxpayer Identification Number (TIN)',
  country: 'Country',
  currency: 'Currency',
  reseller: 'Jurisdiction',
  contact: 'Contact details',
  contactName: 'Contact details',
  phone: 'Phone number',
  companyName: 'Company name',
  companyData: 'Company details',
  empty: 'Not specified',
  tariffs: 'Pricing',
  responsibleManagerModal: {
    title: 'Change responsible manager',
    selectManager: 'Select a responsible manager',
  },
  tabs: {
    [ESupportProfileTabs.PAYMENT]: 'Payment',
    [ESupportProfileTabs.REQUISITES]: 'Company details',
    [ESupportProfileTabs.PRICING]: 'Pricing',
    [ESupportProfileTabs.CALLBACK]: 'API key and callbacks',
    [ESupportProfileTabs.LICENSE]: 'License',
  },
  paymentTab: {
    [EPaymentTab.REPLENISHMENT]: 'Balance replenishment',
    [EPaymentTab.ACCOUNT_STATEMENT]: 'Account statement',
    [EPaymentTab.DOCUMENTATION]: 'Documents',
  },
};
