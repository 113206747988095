import styled from 'styled-components';

import {
  Placeholder as BasePlaceholder,
  ValueLabel as BaseValueLabel,
  TPlaceholderProps,
} from '@edna/components/Select/style';
import {TTheme, mixins} from '@edna/components/styles';

export * from '@edna/components/Select/style';

type TValueProps = TTheme & {
  multiple?: boolean;
  disabled?: boolean;
};

export const ValueLabel = styled(BaseValueLabel)<TValueProps>`
  ${({multiple}: TValueProps) => (multiple ? mixins.f2 : mixins.f3)}
`;

export const Placeholder = styled(BasePlaceholder)<TPlaceholderProps>`
  color: ${({theme, disabled}) =>
    disabled ? theme.branding.colors.disabled : theme.palette.grey500};
`;
