export enum EOrientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type TOrientation = keyof typeof EOrientation;

export enum ESize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export type TSize = keyof typeof ESize;

export enum EActionsPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type TActionsPosition = keyof typeof EActionsPosition;
