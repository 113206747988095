import styled from 'styled-components';

import {NewRadioField as BaseNewRadioField, RadioField as BaseRadioField} from '@edna/components';
import {Label as BaseFieldLabel} from '@edna/components/FormField';
import {mixins} from '@edna/components/styles';

export const RadioField = styled(BaseRadioField)`
  width: max-content;
`;

export const NewRadioField = styled(BaseNewRadioField)`
  width: max-content;
`;

export const FieldLabel = styled(BaseFieldLabel)`
  ${mixins.f3}
`;

export const TextLabel = styled.span`
  ${mixins.f2}
`;

export const NestedBlock = styled.div`
  margin-left: ${({theme: {spacing}}) => spacing.s8};
`;
