import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useSpring} from 'react-spring';

import {Modal, showModal} from '@edna/components';
import {useTheme} from '@edna/components/styles';
import {useMeasure} from '@edna/components/utils';

import {NewsContent, TProps} from './NewsContent';
import * as S from './style';
import {useNewsState} from './useNewsState';

const News = React.memo<TProps & {id: number}>(({image, children, title, id}) => {
  const theme = useTheme();
  const [contentRef, {height: contentHeight}] = useMeasure<HTMLDivElement>();

  const {isNewsOpen, closeNews} = useNewsState(id);

  const styles = useSpring({
    from: {
      maxHeight: isNewsOpen ? contentHeight / 2 : 0,
      overflow: 'hidden',
      opacity: 0,
    },
    to: [
      {
        maxHeight: isNewsOpen ? contentHeight : 0,
        overflow: isNewsOpen ? 'visible' : 'hidden',
      },
      {
        opacity: isNewsOpen ? 1 : 0,
        config: {duration: theme.animation.duration / 2},
      },
    ],
    config: {duration: theme.animation.duration},
  });

  return (
    <animated.div style={styles}>
      <div ref={contentRef}>
        <S.News>
          <NewsContent image={image} title={title} onClose={closeNews}>
            {children}
          </NewsContent>
        </S.News>
      </div>
    </animated.div>
  );
});

const NewsModal = React.memo<TProps & {onClose: TEmptyFunction}>(
  ({onClose, children, title, image}) => {
    const {t} = useTranslation();

    return (
      <Modal width="936px">
        <NewsContent title={title} image={image}>
          {children}
        </NewsContent>
        <S.Button appearance="primary" data-selector="Common:button.ok" onClick={onClose}>
          {t('Common:button.ok')}
        </S.Button>
      </Modal>
    );
  },
);

const showNewsModal = ({children, image, title}: TProps) =>
  showModal((closeModal) => (
    <NewsModal onClose={closeModal} image={image} title={title}>
      {children}
    </NewsModal>
  ));

export {News, NewsModal, showNewsModal};
