import {EAutopaymentError} from '../Autopayment';
import {EApproveStatus} from '../MessageMatchers/constants';
import {
  EAutopaymentNotificationStatus,
  ESystemNewsType,
  ESystemNotificationType,
  EWebNotificationType,
} from './model';

export default {
  button: {
    downloadErrorFile: 'Download error file',
    toAudienceSection: 'To the audience section',
    contactTechnicalSupport: 'Contact technical support',
    toReportBroadcast: 'To the broadcast report',
  },
  news: {
    [ESystemNewsType.UpdatePlanned]: {
      title: '{{date}} Update',
      content:
        "edna Pulse will be updated on <0></0>. We recommend that you don't schedule or send broadcasts between {{startTime}} and {{endTime}} due to short software interruptions.\n" +
        'We try to be the best for you! We hope you enjoy this update.',
    },
  },
  system: {
    [ESystemNotificationType.UpdateRunning]:
      '{{date}} from {{startTime}} to {{endTime}} <0>edna Pulse update</0>. During this time, short software interruptions may occur.',
    [ESystemNotificationType.MaintenanceRunning]:
      'There are short software interruptions in edna Pulse. We are already working on fixing them!',
  },
  web: {
    [EWebNotificationType.SubscribersUploadFailed]: `The audience list has not been loaded. <0>To notifications</0>`,
    [EWebNotificationType.SubscribersUploadPartial]: `The audience list has not been fully loaded. <0>To notifications</0>`,
    [EWebNotificationType.SubscribersUploadSuccess]: `The audience list has been loaded. <0>To notifications</0>`,
    [EWebNotificationType.BroadcastCompleted]: `The broadcast "{{name}}" is completed. <0>To notifications</0>`,
    [EWebNotificationType.BroadcastCancelled]: `The broadcast "{{name}}" has been stopped. <0>To notifications</0>`,
    [EWebNotificationType.LicenseExpiring]:
      'Your license for edna Pulse expires {{date}}. To renew the license, please contact your personal manager: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL for broadcast statuses has been successfully changed. <0>To notifications</0>`,
    [EWebNotificationType.ApikeyUserUpdated]: `The new API key has been successfully generated. <0>To notifications</0>`,
    [EWebNotificationType.CompanyDetailsUpdated]: `User "{{login}}" has changed the company details. <0>To notifications</0>`,
    [EWebNotificationType.PaymentDetailsUpdated]: `User "{{login}}" has changed the company payment details. <0>To notifications</0>`,
    [EWebNotificationType.TenantTariffAccepted]: `User "{{login}}" has agreed to the available pricing options. <0>To notifications</0>`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `User "{{login}}" has accepted the pricing changes for <b>{{tariffName}}</b>. <0>To notifications</0>`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `User "{{login}}" has rejected the pricing changes for <b>{{tariffName}}</b>. <0>To notifications</0>`,
    [EWebNotificationType.JivoEnabled]:
      'User {{login}} has connected all the WhatsApp channel traffic to the Jivo chat platform. <0>To notifications</0>',
    [EWebNotificationType.JivoDisabled]:
      'User {{login}} has disconnected all the WhatsApp channel traffic to the Jivo chat platform. <0>To notifications</0>',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Template "{{name}}" was disabled. All scheduled broadcasts using this template are stopped <0>To notifications</0>',
      [EApproveStatus.PAUSED]:
        'Template "{{name}}" was paused. All scheduled broadcasts using this template are stopped. <0>To notifications</0>',
      [EApproveStatus.APPROVED]:
        'Template "{{name}}" status changed. New status: Active. <0>To notifications</0>',
      [EApproveStatus.REJECTED]:
        'Template "{{name}}" status changed. New status: Rejected. <0>To notifications</0>',
      [EApproveStatus.PENDING]:
        'Template "{{name}}" status changed. New status: Pending registration. <0>To notifications</0>',
      [EApproveStatus.NOT_SENT]:
        'Template "{{name}}" status changed. New status: Draft. <0>To notifications</0>',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `The category of WhatsApp template "{{name}}" was changed by WhatsApp. New category: "{{newCategory}}". <0>To notifications</0>`,
    [EWebNotificationType.InvoicePaid]: 'Payment successful. <0>To notifications</0>',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: quality changed. New quality: {{qualityScore}}. <0>To notifications</0>',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: limit changed. New limit: {{messagingLimit}}. <0>To notifications</0>',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'WhatsApp account {{name}} status changed. New status: {{qualityStatus}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'The auto payment failed on {{date}}. For more information, contact technical support <1>{{email}}</1>. <0>To notifications</0>',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'The auto payment failed on {{date}}. Your bank has requested payment confirmation. <0>To notifications</0>',
      [EAutopaymentError.CARD_DECLINE]:
        'Failed to make an auto payment with the linked card. <0>To notifications</0>',
      [EAutopaymentError.INSUFFICIENT_FUNDS]:
        'The charge failed on {{date}}. Insufficient funds. <0>To notifications</0>',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]:
        'Auto payment activated. <0>To notifications</0>',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]:
        'Auto payment deactivated. <0>To notifications</0>',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Auto payment activated. To receive a payment receipt, follow the link to the <1>Stripe payment system website</1>. <0>To notifications</0>',
    },
  },
};
