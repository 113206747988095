import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const ScrollUpButton = styled.button`
  ${mixins.position({position: 'fixed', right: '32px', bottom: '32px'})}
  border: none;
  outline: none;
  cursor: pointer;

  ${({theme}) => css`
    ${mixins.wh('48px')};
    z-index: ${theme.zIndex.header};
    padding: ${theme.spacing.s3};
    color: ${theme.palette.white};
    background-color: ${theme.branding.content.tertiary};
    border-radius: ${theme.borderRadius.br2};
    box-shadow: ${theme.boxShadow.default};
  `};
`;
