import {ESubjectStatus} from 'src/constants';

export default {
  title: 'Итоговый отчет',
  searchPlaceholder: 'Быстрый поиск по названию канала',
  th: {
    channel: 'Канал',
    status: 'Статус',
  },
  status: {
    [ESubjectStatus.APPROVED]: 'Активный',
    [ESubjectStatus.REJECTED]: 'Отклонен',
    [ESubjectStatus.PENDING]: 'Регистрация',
    [ESubjectStatus.NOT_SENT]: 'Черновик',
  },
};
