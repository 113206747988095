import {ESystemNotificationEntityType, TGenericNotification} from './definitions';

type TGenericSystemNotification<T, M = TAnyObject> = TGenericNotification<T, M> & {
  entityType: ESystemNotificationEntityType.NOTIFICATION;
};

enum ESystemNotificationType {
  UpdateRunning = 'UpdateRunning',
  MaintenanceRunning = 'MaintenanceRunning',
}

type TReleaseNotification = TGenericSystemNotification<
  ESystemNotificationType.UpdateRunning,
  {
    startAt: string;
    endAt: string;
  }
>;

type TSystemNotification =
  | TReleaseNotification
  | TGenericSystemNotification<ESystemNotificationType.MaintenanceRunning>;

export type {TSystemNotification, TReleaseNotification};
export {ESystemNotificationType};
