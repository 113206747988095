import {ETariffChangeRequestStatus} from './definitions';

export default {
  personal: 'Personal',
  free: '(free)',
  mauCount: 'MAU {{mauCount}}',
  bynTariff: '{{channel}}_BYN',
  acceptTariff: {
    title: 'Accept your pricing',
    subtitle:
      'Familiarize yourself with the pricing and click "{{buttonName}}". Once the pricing is accepted, the channels are available for registration',
  },
  agreeTariffChange: {
    title: 'Your pricing has been changed',
    subtitle:
      'Your current pricing has been changed upon your request. Accept the new pricing for the changes to take effect. ',
    text: '<0>New pricing:</0> {{newTariffName}},<br/> <0>for the:</0> {{subjectName}}',
  },
  tariffHistory: {
    title: 'Pricing change history',
    active: 'Active pricing: ',
    changed: '<0>The pricing has been changed on {{date}}</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    connected:
      '<0>The pricing has been connected on {{date}}</0><br/><1>{{active}}</1><2>{{name}}<2/>',
    individual: 'Individual',
  },
  successCreatingTariff: {
    title: 'Congratulations! You have successfully created a rate!',
    text: 'You can continue with the new rate',
  },
  createPersonalTariff: {
    title: 'Create a personal rate',
    message:
      'Once created, you cannot edit a personal rate. If you need to change it, you will have to do it through the support team',
    templateTitleError: 'This rate template title is already exist',
    selectTemplateError: 'Select tariff template',
    attachFile: 'Attach XLSX file',
    attachAnotherFile: 'Upload another file',
    labels: {
      selectTemplate: 'Select a rate template based on which you want to create the personal rate',
      templateName:
        'A rate template title must be unique for you dashboard. The name will be shown to the client.',
      mauCount: 'Number of MAU',
      licensePrice: 'License cost, {{currency}}',
      additionalMauCost: 'Additional MAU cost, {{currency}}',
      dialogsCost: 'Cost of conversations, {{currency}}',
      uploadFile: 'Upload the agreed file with the cost of messages',
      uploadFileWA:
        'upload the agreed file with the individual cost of dialogs in currency, which you have selected on the first step of the client registration. For ОСК clients the cost of messages must be in USD.',
      country: 'Country',
      businessDialog: 'Business-initiated conversation',
      userDialog: 'User-initiated conversation',
      initPayment: 'Service connection cost, {{currency}}',
    },
  },
  tariffCostModal: {
    title: 'The cost of conversations',
  },
  button: {
    refuse: 'Refuse',
    acceptTariff: 'Accept pricing',
    agreeTariff: 'Agree pricing',
    fine: 'Fine!',
  },
  status: {
    [ETariffChangeRequestStatus.NEED_TO_AGREE]: 'Need to agree',
    [ETariffChangeRequestStatus.AGREED]: 'Agreed',
    [ETariffChangeRequestStatus.PROCESSED]: 'Processed',
    [ETariffChangeRequestStatus.CANCELED]: 'Canceled',
    [ETariffChangeRequestStatus.REJECTED]: 'Rejected',
    [ETariffChangeRequestStatus.NOT_AGREED]: 'Not agreed',
  },
};
