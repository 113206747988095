import React from 'react';
import {useTranslation} from 'react-i18next';

import {useTheme} from 'styled-components';

import {useGetAllParametersQuery} from 'src/containers/Parameters/api';

import * as SS from '../style';
import {FOOTER_TIME} from '../utils';
import * as S from './style';

const REGEXES_REGEX = /%(d|w)\S*/g;
const VARIABLES_REGEX = /{{(\d*)}}/g;
const WORDS_REGEX = /(\w*\S)+/g;

const makeRegexSpan = (theme: TTheme['theme'], text: string) =>
  `<span style="color: ${theme.palette.green500}">${text}</span>`;
const makeEmptyVariableSpan = (theme: TTheme['theme'], text: string) =>
  `<span style="color: ${theme.branding.content.tertiary}" font-weight: 600>${text}</span>`;
const makeVariableSpan = (theme: TTheme['theme'], text: string) =>
  `<span style="color: ${theme.color.purple}" font-weight: 500>${text}</span>`;

const SmsContent = React.memo<{text: string; parentText?: string}>(({text, parentText = ''}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const {data: parameters = []} = useGetAllParametersQuery();

  const formattedText = React.useMemo(() => {
    const parentWords = (parentText.match(WORDS_REGEX) ?? []).filter(
      (word) => !REGEXES_REGEX.test(word) && !VARIABLES_REGEX.test(word),
    );

    let formatted = text;

    if (parentText) {
      formatted = text.replace(WORDS_REGEX, (word) => {
        if (REGEXES_REGEX.test(word) || VARIABLES_REGEX.test(word)) {
          return word;
        }

        if (word === parentWords[0]) {
          parentWords.shift();

          return word;
        }

        return makeVariableSpan(theme, word);
      });
    }

    formatted = formatted.replace(REGEXES_REGEX, (regex) => makeRegexSpan(theme, regex));

    return formatted.replace(VARIABLES_REGEX, (_, parameterId) => {
      const name = parameters.find(({id}) => Number(parameterId) === id)?.name;

      if (!name) {
        return makeEmptyVariableSpan(theme, t('MessageMatchers:template.options.variable'));
      }

      return makeVariableSpan(theme, name);
    });
  }, [text, parameters]);

  return (
    <S.Message {...edna.renderTestId(edna.TEST_IDS.MESSAGE_MATCHERS.SMS.PREVIEW)}>
      <SS.MessageContent>
        <span
          dangerouslySetInnerHTML={{
            __html: formattedText,
          }}
        />

        <SS.Time>
          <span>{FOOTER_TIME}</span>
        </SS.Time>
      </SS.MessageContent>
    </S.Message>
  );
});

SmsContent.displayName = 'SmsContent';

export default SmsContent;
