import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';
import {TCurrency} from '@edna/utils/formatMoney';
import {buildQueryString} from '@edna/utils/uri';

import {EChannelType, TChannelType, TSubject} from 'src/constants';
import errorNotification from 'src/models/errorNotification';
import listApiModel, {TListItemId} from 'src/models/listApiModel';
import request from 'src/models/request';

import {showSuccessCreatingTariffModal} from './Modals/SuccessCreatingTariffModal';
import {
  CHANNEL_ACTIVE_TARIFF_KEY,
  CHANNEL_TEMPLATE_KEY,
  TActiveTariff,
  TConversationCountry,
  TOwnerType,
  TTariffChangeRequest,
  TTariffHistory,
  TState as TTariffState,
  TTariffTemplate,
} from './definitions';
import {getTariffId, splitTariffs} from './utils';

type TItem = {
  id: TListItemId;
  resellerId?: number;
  initPayment?: number;
  mauCount?: number;
  licensePrice?: number;
  defaultMauPrice?: number;
  defaultServicePrice?: number;
  defaultMarketingPrice?: number;
  defaultMultiPrice?: number;
  packagePrice?: number;
  currency?: TCurrency;
  licenseCurrency?: TCurrency;
  dialogCurrency?: TCurrency;
  ownerType?: TOwnerType;
  conversationCountryScaleList?: TConversationCountry[];
  name?: string;
};

type TActions = {
  // Reducers
  setTariffsLoading: TActionCreator<TState['tariffsLoading']>;
  setAcceptTariffsLoading: TActionCreator<TState['acceptTariffsLoading']>;
  setTariffChangeRequests: TActionCreator<TTariffChangeRequest[]>;
  updateTariffChangeRequest: TActionCreator<TTariffChangeRequest>;
  setWhatsAppTariffTemplate: TActionCreator<TState['whatsAppTariffTemplate']>;
  setChannelTariffTemplates: TActionCreator<{data: TTariffTemplate[]; channelType?: TChannelType}>;
  setChannelActiveTariffs: TActionCreator<{data: TActiveTariff[]; channelType?: TChannelType}>;
  addChannelTariffTemplates: TActionCreator<{data: TTariffTemplate; channelType?: TChannelType}>;

  // Thunks
  requestAllTariffTemplates: TThunkCreator;
  acceptTariffs: TThunkCreator<{data: TTariffTemplate[]}, void, Promise<void>>;
  requestAllDetails: TThunkCreator<void, void, Promise<TAnyObject>>;
  getNeedToAgreeTariffChangeRequests: TThunkCreator<
    void,
    void,
    Promise<{result: TTariffChangeRequest[] | void}>
  >;
  changeTariffRequest: TThunkCreator<void, void, Promise<void>>;
  agreeTariffChangeRequests: TThunkCreator<TTariffChangeRequest['id'], void, Promise<void>>;
  disagreeTariffChangeRequests: TThunkCreator<TTariffChangeRequest['id'], void, Promise<void>>;
  getChannelTariffTemplatesForTenant: TThunkCreator<
    {channelType: TChannelType} | void,
    void,
    Promise<{result?: TTariffTemplate[] | void}>
  >;
  createChannelPersonalTariff: TThunkCreator<
    {data: TFormData; channelType: TChannelType},
    void,
    Promise<{
      result?: TTariffTemplate | void;
    }>
  >;
  createChannelPersonalTariffWithFile: TThunkCreator<
    {data: TFormData & {file: File}; channelType: TChannelType},
    void,
    Promise<{
      result?: TTariffTemplate | void;
    }>
  >;
  getAllTariffsTemplatesByFilter: TThunkCreator<
    {
      channelType?: TChannelType;
      leadId?: TListItemId;
      currency?: TCurrency | null;
      partnerUuid?: string;
      tenantId?: TListItemId;
    },
    void,
    Promise<void>
  >;
  getAllActiveTariffsByTenantId: TThunkCreator<
    {tenantId: TListItemId; channelType?: TChannelType},
    void,
    Promise<void>
  >;
  getAllTariffTemplatesByTenantId: TThunkCreator<
    {tenantId: TListItemId; channelType?: TChannelType},
    void,
    Promise<void>
  >;
  getAllTariffTemplatesByLeadId: TThunkCreator<
    {leadId: TListItemId; channelType?: TChannelType},
    void,
    Promise<void>
  >;
  getUnacceptedTariffTemplatesForTenant: TThunkCreator<
    {channelType: TChannelType} | void,
    void,
    Promise<TTariffTemplate[]>
  >;
  getAllActiveTariffsForTenant: TThunkCreator<
    {channelType: TChannelType} | void,
    void,
    Promise<void>
  >;

  getTariffHistory: TThunkCreator<
    {subjectId: TTariffTemplate['subjectId']; channelType: TSubject['type']},
    void,
    Promise<TTariffHistory[]>
  >;
  checkIsTariffExists: TThunkCreator<{name: string}, void, Promise<{exists: boolean}>>;
};

const id = 'tariffs';

const defaultInitialValues: TFormData = {
  id: '',
  conversationCountryScaleList: [],
};

export type TFormData = {
  id: TListItemId;
  conversationCountryScaleList?: TConversationCountry[];
  mauCount?: number;
  licensePrice?: number;
  defaultMauPrice?: number;
  // бэк не ожидает это поле, оно необходимо лишь для контента тарифа
  basedOnTariffId?: TListItemId;
  whatsappTariffTemplateId?: TListItemId;
  initPayment?: number;
  // custom tariff
  name?: string;
  currency?: TCurrency;
  licenseCurrency?: TCurrency;
  dialogCurrency?: TCurrency;
};

const getInitialValues = (item: TItem | null = null): TFormData => {
  if (item === null) {
    return defaultInitialValues;
  }

  return {
    id: item.id,
    conversationCountryScaleList:
      item.conversationCountryScaleList || defaultInitialValues.conversationCountryScaleList,
    mauCount: item.mauCount || 0,
    licensePrice: item.licensePrice || 0,
    defaultMauPrice: item.defaultMauPrice || 0,
    basedOnTariffId: item.id,
    initPayment: item.initPayment || 0,
    name: item.name ?? '',
    currency: item.currency,
    licenseCurrency: item.licenseCurrency ?? item.currency,
    dialogCurrency: item.dialogCurrency ?? item.currency,
  };
};

type TState = TTariffState & {
  initialValues: TFormData;
};

export default listApiModel<typeof id, TItem, TEmptyObject, TState, TActions>({
  id,
  defaultState: {
    tariffsLoading: false,
    acceptTariffsLoading: false,
    tariffChangeRequests: [],
    whatsAppTariffTemplate: null,
    whatsAppTariffTemplates: [],
    viberTariffTemplates: [],
    smsTariffTemplates: [],
    activeWhatsAppTariffs: [],
    activeViberTariffs: [],
    activeSmsTariffs: [],
  },
  utils: {
    getInitialValues,
  },
  reducers: {
    setAcceptTariffsLoading: (state, {payload}) => ({
      ...state,
      acceptTariffsLoading: payload,
    }),
    setTariffsLoading: (state, {payload}) => ({
      ...state,
      tariffsLoading: payload,
    }),
    setTariffChangeRequests: (state, {payload}) => ({
      ...state,
      tariffChangeRequests: payload,
    }),
    updateTariffChangeRequest: (state, {payload}) => ({
      ...state,
      tariffChangeRequests: state.tariffChangeRequests.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          };
        }

        return item;
      }),
    }),
    setWhatsAppTariffTemplate: (state, {payload}) => ({
      ...state,
      whatsAppTariffTemplate: payload,
    }),
    setChannelTariffTemplates: (state, {payload}) => {
      if (!payload.channelType) {
        return {
          ...state,
          ...splitTariffs(payload?.data ?? [], CHANNEL_TEMPLATE_KEY),
        };
      }

      switch (payload?.channelType) {
        case EChannelType.WHATSAPP: {
          return {
            ...state,
            whatsAppTariffTemplates: payload.data,
          };
        }

        case EChannelType.VIBER: {
          return {
            ...state,
            viberTariffTemplates: payload.data,
          };
        }

        case EChannelType.SMS: {
          return {
            ...state,
            smsTariffTemplates: payload.data,
          };
        }
        default:
          return state;
      }
    },
    setChannelActiveTariffs: (state, {payload}) => {
      if (!payload.channelType) {
        return {
          ...state,
          ...splitTariffs(payload?.data ?? [], CHANNEL_ACTIVE_TARIFF_KEY),
        };
      }

      switch (payload.channelType) {
        case 'WHATSAPP': {
          return {
            ...state,
            activeWhatsAppTariffs: payload.data,
          };
        }

        case 'VIBER': {
          return {
            ...state,
            activeViberTariffs: payload.data,
          };
        }

        case 'SMS': {
          return {
            ...state,
            activeSmsTariffs: payload.data,
          };
        }
        default:
          return state;
      }
    },
    addChannelTariffTemplates: (state, {payload}) => {
      switch (payload.channelType) {
        case 'WHATSAPP': {
          return {
            ...state,
            whatsAppTariffTemplates: [...state.whatsAppTariffTemplates, payload.data],
          };
        }

        case 'VIBER': {
          return {
            ...state,
            viberTariffTemplates: [...state.viberTariffTemplates, payload.data],
          };
        }

        case 'SMS': {
          return {
            ...state,
            smsTariffTemplates: [...state.smsTariffTemplates, payload.data],
          };
        }
        default:
          return state;
      }
    },
  },
  api: {
    acceptTariffs: (payload) =>
      request.post(`/rest/billing/tariff/template/accept${buildQueryString(payload)}`),
    getNeedToAgreeTariffChangeRequests: () =>
      request.get('/rest/tenantmanagement/change-tariff-request/status/need-to-agree'),
    disagreeTariffChangeRequests: (payload) =>
      request.post(`/rest/tenantmanagement/change-tariff-request/${payload.id}/not-agree`),
    agreeTariffChangeRequests: (payload) =>
      request.post(`/rest/tenantmanagement/change-tariff-request/${payload.id}/agree`),
    setStatusNotAgreeToChangeTariffRequest: (payload) =>
      request.post(`/rest/tenantmanagement/change-tariff-request/${payload.id}/not-agree`),
    getChannelTariffTemplatesForTenant: (payload) =>
      request.get(`/rest/billing/tariff/template${buildQueryString(payload)}`),
    createChannelPersonalTariff: ({channelType, ...data}) =>
      request.post(`rest/billing/${channelType.toLowerCase()}/tariff-template`, data),
    createChannelPersonalTariffWithFile: ({channelType, ...data}) => {
      const formData = new FormData();

      formData.append('file', data.file);
      formData.append(
        'request',
        new Blob([JSON.stringify(data.request)], {
          type: 'application/json',
        }),
      );

      return request.post(
        `rest/billing/${channelType.toLowerCase()}/tariff-template/with-file`,
        formData,
      );
    },
    getAllTariffsTemplatesByFilter: (payload) =>
      request.post(`rest/billing/tariff/template/manager`, payload),
    getAllActiveTariffsByTenantId: (payload) =>
      request.get(`/rest/billing/tariff/manager/${payload.tenantId}`),
    getAllActiveTariffsForTenant: (payload) =>
      request.get(`rest/billing/tariff${buildQueryString(payload)}`),
    getAllTariffTemplatesByTenantId: (payload) =>
      request.get(`/rest/billing/tariff/template/manager/${payload.tenantId}`),
    getUnacceptedTariffTemplatesForTenant: () =>
      request.get(`/rest/billing/tariff/template/unaccepted`),
    getTariffHistoryBySubject: (payload) =>
      request.get(`/rest/billing/tariff/${payload.channelType}/subject/${payload.subjectId}`),
    checkIsTariffExists: (payload) =>
      request.get(`/rest/billing/tariff/template/exists${buildQueryString(payload)}`),
  },
  thunks: ({actions, api}) => ({
    requestAllTariffTemplates: async ({dispatch}) => {
      dispatch(actions.setTariffsLoading(true));

      await dispatch(actions.getChannelTariffTemplatesForTenant());

      dispatch(actions.setTariffsLoading(false));
    },
    acceptTariffs: async ({dispatch}, {payload}) => {
      dispatch(actions.setAcceptTariffsLoading(true));

      const tariffIds = payload.data.map(getTariffId);

      const {result, error} = await api.acceptTariffs({tariffIds});

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.getChannelTariffTemplatesForTenant());
      }
      dispatch(actions.setAcceptTariffsLoading(false));
    },
    getNeedToAgreeTariffChangeRequests: async ({dispatch}) => {
      const {result, error} = await api.getNeedToAgreeTariffChangeRequests();

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.setTariffChangeRequests(result));
      }

      return {result};
    },
    agreeTariffChangeRequests: async ({dispatch}, {payload}) => {
      const {result, error} = await api.agreeTariffChangeRequests({
        id: payload,
      });

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.updateTariffChangeRequest(result));
      }
    },
    disagreeTariffChangeRequests: async ({dispatch}, {payload}) => {
      const {result, error} = await api.disagreeTariffChangeRequests({
        id: payload,
      });

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(actions.updateTariffChangeRequest(result));
      }
    },
    getChannelTariffTemplatesForTenant: async ({dispatch}, {payload}) => {
      const {result, error} = await api.getChannelTariffTemplatesForTenant(payload);

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.setChannelTariffTemplates({data: result, channelType: payload?.channelType}),
        );
      }

      return {result};
    },
    createChannelPersonalTariff: async ({dispatch}, {payload}) => {
      const {result, error} = await api.createChannelPersonalTariff({
        ...payload.data,
        tariffTemplateId: payload.data.id,
        channelType: payload.channelType,
      });

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.addChannelTariffTemplates({data: result, channelType: payload.channelType}),
        );
        showSuccessCreatingTariffModal();
      }

      return {result};
    },
    createChannelPersonalTariffWithFile: async ({dispatch}, {payload}) => {
      const {id: tariffTemplateId, file, ...data} = payload.data;

      const {result, error} = await api.createChannelPersonalTariffWithFile({
        file,
        request: {
          ...data,
          tariffTemplateId,
        },
        channelType: payload.channelType,
      });

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.addChannelTariffTemplates({data: result, channelType: payload.channelType}),
        );
        showSuccessCreatingTariffModal();
      }

      return {result};
    },
    getAllTariffsTemplatesByFilter: async ({dispatch}, {payload}) => {
      const {result, error} = await api.getAllTariffsTemplatesByFilter(payload);

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.setChannelTariffTemplates({data: result, channelType: payload?.channelType}),
        );
      }
    },
    getAllActiveTariffsByTenantId: async ({dispatch}, {payload}) => {
      dispatch(actions.setTariffsLoading(true));

      const {result, error} = await api.getAllActiveTariffsByTenantId(payload);

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.setChannelActiveTariffs({channelType: payload?.channelType, data: result}),
        );
      }

      dispatch(actions.setTariffsLoading(false));
    },
    getAllActiveTariffsForTenant: async ({dispatch}, {payload}) => {
      dispatch(actions.setTariffsLoading(true));

      const {result, error} = await api.getAllActiveTariffsForTenant(payload);

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.setChannelActiveTariffs({channelType: payload?.channelType, data: result}),
        );
      }

      dispatch(actions.setTariffsLoading(false));
    },
    getAllTariffTemplatesByTenantId: async ({dispatch}, {payload}) => {
      dispatch(actions.setTariffsLoading(true));

      const {result, error} = await api.getAllTariffTemplatesByTenantId(payload);

      if (error) {
        errorNotification(error);
      }

      if (result) {
        dispatch(
          actions.setChannelTariffTemplates({data: result, channelType: payload?.channelType}),
        );
      }
      dispatch(actions.setTariffsLoading(false));
    },
    getUnacceptedTariffTemplatesForTenant: async (_, {payload}) => {
      const {result, error} = await api.getUnacceptedTariffTemplatesForTenant(payload);

      if (error) {
        errorNotification(error);
      }

      if (result && payload?.channelType) {
        result.map((item: TTariffTemplate) => ({...item, channelType: payload.channelType}));
      }

      return result || [];
    },
    getTariffHistory: async ({}, {payload}) => {
      const {result, error} = await api.getTariffHistoryBySubject(payload);

      if (error) {
        errorNotification(error);

        return;
      }

      return result.map((tariff: any) => ({...tariff, ...tariff?.payload}));
    },
    checkIsTariffExists: async ({}, {payload}) => {
      const {result, error} = await api.checkIsTariffExists(payload);

      if (error) {
        errorNotification(error);
      }

      return result;
    },
  }),
});
