export default {
  password: 'Изменить пароль',
  contacts: 'Контактные данные',
  language: 'Язык интерфейса',
  chooseLanguage: 'Выберите язык',
  editForm: {
    name: 'ФИО',
    email: 'E-mail',
    phone: 'Телефон',
  },
  notification: {
    changePasswordSuccess: 'Пароль успешно изменен',
  },
  timezone: 'Часовой пояс',
  timezoneLabel: 'Часовой пояс при отправке сообщений',
};
