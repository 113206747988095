import {animated} from 'react-spring';

import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';
import BaseCloseIcon from '@edna/icons/close.svg';
import {hexToRgba} from '@edna/utils/hexToRgba';

import {SecondaryText} from 'src/components/primitives';

import BaseStatusIcon from '../StatusIcon';

export const MessageWrapper = styled.div`
  display: flex;
`;

type TMessageProps = TTheme & {
  noMargin?: boolean;
  hasFullWidth?: boolean;
};

export const Message = styled.div<TMessageProps>`
  display: flex;
  overflow: auto;
  ${({noMargin, hasFullWidth, theme}: TMessageProps) => css`
    width: ${hasFullWidth ? '100%' : 'auto'};
    margin-bottom: ${noMargin ? 0 : theme.spacing.s5};
    padding: ${theme.spacing.s5} ${theme.spacing.s6} ${theme.spacing.s5};
    border-radius: ${theme.borderRadius.br4};
    box-shadow:
      0 3px 12px -1px ${hexToRgba(theme.branding.content.primary, '26%')},
      0 2px 4px -1px ${hexToRgba(theme.branding.content.primary, '16%')};
  `}
`;

export const StatusIcon = styled(BaseStatusIcon)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${({theme}) => theme.spacing.s3};
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

type TTitleProps = TTheme & {
  hasMargin: boolean;
};

export const Title = styled.div<TTitleProps>`
  ${({theme, hasMargin}: TTitleProps) => css`
    margin-bottom: ${hasMargin ? theme.spacing.s2 : 0};
    color: ${theme.branding.content.secondary};
  `}
`;

export const Text = styled(SecondaryText)`
  white-space: pre-line;
`;

export const CloseIcon = styled(BaseCloseIcon)`
  ${mixins.wh('24px')}
  flex-shrink: 0;
  cursor: pointer;
  ${({theme: {branding, spacing}}) => css`
    color: ${branding.content.secondary};
    margin-left: ${spacing.s5};
  `}
`;

export const AnimatedDiv = styled(animated.div)`
  width: 100%;
`;
