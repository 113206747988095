import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import getPeriod from '@edna/utils/getPeriod';

import {TItemId} from 'src/constants';
import PATHS from 'src/paths';

import {LIST_CONFIG} from '../api';
import {setFiltersAction} from '../slice';

const useNavigateToReportByBroadcastId = (broadcastId?: TItemId) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return React.useCallback(async () => {
    if (!broadcastId) {
      return;
    }

    dispatch(
      setFiltersAction({
        ...LIST_CONFIG.filtersConfig.defaultFilters,
        broadcasts: [Number(broadcastId)],
        period: getPeriod('hours24'),
      }),
    );

    navigate(PATHS.ANALYTICS_MESSAGES);
  }, [broadcastId, dispatch, navigate]);
};

export default useNavigateToReportByBroadcastId;
