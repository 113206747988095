import {TFunction} from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import {ECurrency} from '@edna/utils/formatMoney';

import {EChannelType} from 'src/constants';

import {
  TActiveTariff,
  TActiveTariffs,
  TConversationCountry,
  TCountryDetail,
  TTariffChannelType,
  TTariffOption,
  TTariffTemplate,
  TTariffsTemplates,
  TWhatsappTariffPayload,
} from './definitions';

export const getTariffName = (t: TFunction, tariff: TTariffTemplate) => {
  const {id, isIndividual, individual, name, channelType} = tariff;

  if (name && (isIndividual || individual)) {
    return name;
  }

  if (tariff.currency === ECurrency.BYN) {
    return t('Tariffs:bynTariff', {channelType});
  }

  switch (channelType) {
    case EChannelType.WHATSAPP:
      const {
        payload: {mauCount, initPayment},
      } = tariff as TTariffTemplate<TWhatsappTariffPayload>;
      const whatsAppPrefix = isIndividual ? t('Tariffs:personal') : '';
      const whatsAppPostfix = mauCount ? t('Tariffs:mauCount', {mauCount}) : id;

      return whatsAppPrefix + whatsAppPostfix + (initPayment ? '' : t('Tariffs:free'));
    default:
      // TODO: добавить текст о бесплатности, когда начнет прилетать initPayment
      //  `+ (initPayment ? '' : t('Tariffs:free'))`
      return channelType ? channelType + id : String(id);
  }
};

export const getTariffsOptionsForChannel = (
  t: TFunction,
  selectedTariffs: TTariffTemplate[],
): TTariffOption[] =>
  selectedTariffs.map((tariff) => ({
    label: getTariffName(t, tariff),
    value: tariff.id,
  }));

export const getTariffId = (tariff: TTariffTemplate) => tariff.id;

export const isNotAcceptedTariff = (item: TTariffTemplate) => !item.acceptedAt;

export const getTariffByID = (tariffs: TTariffTemplate[], tariffId: TTariffTemplate['id']) =>
  tariffs?.find((tariff) => tariff?.id === tariffId) || null;

export const hasTariffs = (tariffs: TTariffsTemplates | TActiveTariffs) =>
  Object.values(tariffs).some((value) => !isEmpty(value));

export const hasTariffsToAccept = (tariffs: TTariffTemplate[]) => {
  const notAcceptedTariffs = tariffs.filter(isNotAcceptedTariff);

  return !isEmpty(notAcceptedTariffs);
};

type TTariff = TActiveTariff | TTariffTemplate;

export const splitTariffs = (data: TTariff[], tariffKeys: Record<TTariffChannelType, string>) => {
  return data
    ?.filter((item) => !!item.channelType)
    .reduce(
      (result, item) => {
        const itemKey = tariffKeys[item.channelType as TTariffChannelType];

        return {
          ...result,
          [itemKey]: [...(result[itemKey] ?? []), item],
        };
      },
      {} as Record<string, TTariff[]>,
    );
};

export const detailsToCountryPrices = (details: TCountryDetail[]): TConversationCountry[] => {
  return [...details]
    .filter((item) => !!item)
    .reduce((result, item) => {
      const existingCountry = result?.find((newItem) => newItem?.country === item.country);

      if (existingCountry) {
        existingCountry.prices.push({price: item.price, conversationType: item.conversationType});
      } else {
        result.push({
          country: item.country,
          prices: [{price: item.price, conversationType: item.conversationType}],
        });
      }

      return result;
    }, [] as TConversationCountry[]);
};
