import {MAX_SIZE_MB} from './definitions';

export default {
  invalidImageFormat: 'Ошибка загрузки изображения: допустимый формат файла - {{formats}}',
  invalidImageSize: 'Ошибка загрузки изображения: максимальный размер файла - {{size}} МБ',
  imageLabel: 'Добавьте картинку',
  imageLabelHint: `(.jpeg, .jpg, .png размером 400x400 пикселей, не более ${MAX_SIZE_MB} МБ)`,
  uploadImage: 'Загрузить с компьютера',
  addImageLink: 'Вставить ссылку',
  imageUrlLabel: 'Вставьте ссылку на изображение',
  imageUrlHint: `Поддерживаемые форматы изображений: JPEG, PNG. Оптимальный размер изображения 400x400 пикселей, не более ${MAX_SIZE_MB} МБ`,
};
