import React from 'react';

import noop from 'lodash/noop';

import {ESize} from '@edna/components';

import * as S from './style';

type TId = number | string;

type TSwitcherItem = {
  id: TId;
  label: string | React.ReactNode;
};

type TProps = {
  items: TSwitcherItem[];
  preselected?: TSwitcherItem['id'];
  onSelect?: (id: TSwitcherItem['id']) => void;
  size?: ESize;
};

const Switcher = React.memo<TProps>(({items, preselected, onSelect = noop, size = ESize.M}) => {
  const [selected, setSelected] = React.useState(preselected || items[0].id);

  React.useEffect(() => {
    if (preselected) {
      setSelected(preselected);
    }
  }, [preselected]);

  const handleSelectItem = (itemId: TSwitcherItem['id']) => {
    if (itemId === selected) {
      return;
    }

    setSelected(itemId);
    onSelect(itemId);
  };

  return (
    <S.Switcher>
      {items.map((item) => (
        <S.Switch
          key={item.id}
          selected={selected === item.id}
          onClick={() => handleSelectItem(item.id)}
          switchSize={size}
          {...(selected === item.id ? edna.renderTestId(edna.TEST_IDS.SWITCHER.SELECTED) : {})}
        >
          {item.label}
        </S.Switch>
      ))}
    </S.Switcher>
  );
});

Switcher.displayName = 'Switcher';

export default Switcher;
