import {ECurrency} from '@edna/utils/formatMoney';

import {EOperatorName, TChannelType, TContentType} from 'src/constants';
import {ETemplateButtonTypes} from 'src/containers/MessageMatchers/types';

export enum EDeliveryStatus {
  ENQUEUED = 'ENQUEUED',
  SENT = 'SENT',
  FAILED = 'FAILED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  UNDELIVERED = 'UNDELIVERED',
  READ = 'READ',
  EXPIRED = 'EXPIRED',
  SUSPENDED = 'SUSPENDED',
  BLOCKED = 'BLOCKED',
  ACCEPTED = 'ACCEPTED',
  INVALID = 'INVALID',
}

export enum IncomingMessageStatus {
  RECEIVED = 'RECEIVED',
}

export enum ETrafficType {
  AD = 'AD',
  SERVICE = 'SERVICE',
  HSM = 'HSM',
  CHAT = 'CHAT',
  MULTINAMING = 'MULTINAMING',
}

export enum EDirection {
  IN = 'IN',
  OUT = 'OUT',
}

export type TSubject = {
  id: number;
  name?: string;
  address: string;
};

export type TDeviceAppInfo = {
  deviceName: string;
  systemName: string;
  deviceUID: string;
  appPackageName: string;
};

export type TSubscriber = Omit<TSubject, 'id'> & {
  subscriberId?: number;
  mobileOperatorName?: EOperatorName;
  userInfo?: {
    userName: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
  deviceAppInfo?: TDeviceAppInfo;
  identifier?: string;
};

export type TInstagramStory = {
  storyMentionUrl?: string;
  replyToStoryUrl?: string;
};

export type TReferral = {
  body: string;
  headline: string;
  sourceId: string;
  sourceType: string;
  sourceUrl: string;
};

export type TListPickerItem = {
  identifier: string;
  title: string;
  subtitle: string;
};

export type TCatalog = {
  id: string;
  product: {id: string};
  sections: {
    title: string;
    products: {id: string}[];
  }[];
};

export type TOrderProduct = {
  catalogId: string;
  products: {
    id: string;
    price: number;
    quantity: number;
    currency: ECurrency;
  }[];
};

type TAttachment = {
  url?: string;
  name?: string;
  size?: number;
};

export type TMessageContent = {
  type: TContentType;
  attachment?: TAttachment;
  location: {
    longitude?: number;
    latitude?: number;
    address?: string;
  };
  contact?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  action?: string;
  payload?: string;
  caption?: string;
  header?: {
    text?: string;
    imageUrl?: string;
    documentUrl?: string;
    documentName?: string;
    videoUrl?: string;
    videoName?: string;
  };
  text?: string;
  footer?: {text: string};
  buttons?: {
    text?: string;
    url?: string;
    phone?: string;
    payload?: string;
    type?: ETemplateButtonTypes;
  }[];
  language?: string;
  securityRecommendation?: boolean;
  codeExpirationMinutes?: number;
  vkAttachments?: TAttachment[];
  story?: TInstagramStory;
  referral?: TReferral;
  items?: TListPickerItem[];
  order?: TOrderProduct;
  // InMessageContent
  product?: {
    id: string;
    catalogId: string;
  };
  // OutMessageContent
  catalog?: TCatalog;
  // TODO MFMSCOM-4093 убрать вложенность, сделать такую же как items?: TListPickerItem[];
  listPicker?: {
    sections?: {
      items?: TListPickerItem[];
    }[];
  };
};

export type TItem = {
  id: number;
  direction: EDirection;
  channelType: TChannelType;
  subject: TSubject;
  subscriber: TSubscriber;
  messageContent?: TMessageContent;
  broadcast?: {
    id: number;
    name: string;
  };
  cascade?: {
    id: number;
    name: string;
  };
  status?: {
    status: EDeliveryStatus;
    statusAt?: string;
    error?: string;
  };
  trafficType: ETrafficType;
  sentOrReceivedAt: string;
  segments?: number;
};
