import {EAudienceGroupState} from 'src/constants';

import {ETab} from './EditForm/TagsParametersAddresses/definitions';

export default {
  createClient: 'клиента',
  uploadList: 'список',
  editForm: {
    title: 'Данные пользователя',
    fields: {
      lastName: 'Фамилия',
      firstName: 'Имя',
      surName: 'Отчество',
      phone: 'Телефон',
      gender: 'Пол',
      birthDate: 'Дата рождения',
      blacklisted: 'Не отправлять клиенту никаких сообщений',
    },
    placeholders: {
      lastName: 'Не указана',
      firstName: 'Не указано',
      surName: 'Не указано',
      gender: 'Не указан',
      birthDate: 'Введите дату',
      phone: 'Введите номер',
    },
    tag: 'Тег',
    [ETab.TAGS]: {
      head: 'Теги пользователя',
      content: {
        title: 'Теги пользователя',
        action: 'Присвоить тег',
        notification: 'Теги успешно добавлены',
      },
      empty: {
        title: 'У этого пользователя пока нет тегов',
        text: 'Теги пользователя можно добавить сразу или позже, когда у вас о нем появится больше информации',
        action: 'Присвоить тег',
      },
    },
    [ETab.PARAMETERS]: {
      head: 'Переменные',
      content: {
        title: 'Переменные пользователя',
        action: 'Присвоить переменную',
        nameLabel: 'Переменная',
        valueLabel: 'Значение переменной',
      },
      empty: {
        title: 'У этого пользователя пока нет переменных',
        text: 'Увы, никаких отличительных признаков пользователя у нас пока нет. Давайте это исправим?',
        action: 'Добавить переменную',
      },
    },
    [ETab.ADDRESSES]: {
      head: 'Идентификаторы',
      content: {
        title: 'Идентификаторы в каналах',
        type: 'Тип',
        name: 'Имя',
        id: 'ID',
      },
      channelIdentifier: 'Идентификатор в канале',
      systemIdentifier: 'Идентификатор в системе',
      empty: {
        title: 'У этого пользователя пока нет идентификаторов',
        text: 'Вы можете добавить их сразу или позже, когда у вас о нем появится больше информации',
        action: 'Добавить идентификатор',
      },
    },
  },
  editGroupForm: {
    title: 'Добавьте список',
    subtitle: `Вы можете загрузить новый список и добавить ему тег.
    На загрузку списка понадобится какое-то время`,
    pendingHint: `<0>Пожалуйста, не покидайте страницу.</0>
    Если вы уйдете со страницы, прогресс не сохранится`,
    fileButton: '.xlsx',
    anotherFileButton: 'Загрузить другой список',
    tag: 'тег',
    chooseTag: 'Выбрать теги',
    chooseFromTagTree: 'Выбрать тег из дерева',
    tagIds: 'Присвойте теги для вашего списка',
    tagIdsHint: 'Укажите теги, которые будут применены для выбранного',
    variable: 'переменную',
    variableIds: 'Присвойте переменные клиентам списка',
    variableIdsHint: 'Укажите для каждого столбца название переменной',
    chooseVariable: 'Выберите переменную',
    editTitle: 'Отредактируйте список, добавьте теги и переменные клиентам списка',
    uploadTitle: 'Добавляем список в базу',
    uploadSubtitle: `Загружаем и обновляем вашу аудиторию, это займет какое-то время
    <0>Вы можете покинуть страницу</0> - процесс будет выполняться.
    Актуальный статус загрузки можно узнать на <1>этой странице</1>`,
    errorLoadingFile:
      'Загрузка списка аудитории была прервана. Список клиентов базы {{fileName}} не был загружен.',
  },
  editGroupFormModal: {
    title: 'Загрузить документ',
    subtitle:
      'К уже выбранной аудитории получатей вы можете добавить новый список и задать ему тег. На загрузку списка новых получателей потребуется какое-то время',
    fileButton: 'Прикрепить файл',
    anotherFileButton: 'Прикрепить другой файл',
  },
  pickAudienceForm: {
    title: 'Куда определить выбранную аудиторию?',
    subtitle: {
      tagsChosen: 'Аудиторию с этими тегами нужно:',
      tagsNotChosen:
        'Вы загрузили список пользователей и не назначили этому списку теги. Мы присвоили этому списку тег с названием вашего файла (вы найдете его в категории “Списки рассылки”). Что дальше сделать с этой аудиторией?',
    },
    whichGroup: 'В какую группу отнести аудиторию?',
    includeToBroadcast: 'Добавить в рассылку',
    excludeFromBroadcast: 'Исключить из рассылки',
    button: {
      continue: 'Продолжить',
      back: 'Назад',
    },
    group: 'Группа',
    tagWarning: {
      [EAudienceGroupState.excluded]:
        'Аудитория с таким тегом уже включена в рассылку, одновременно исключить ее из рассылки нельзя.',
      [EAudienceGroupState.included]:
        'Аудитория с таким тегом уже исключена из рассылки, одновременно включить ее в рассылку нельзя',
    },
  },
  modal: {
    uploadingTitle: 'Прогресс загрузки получателей',
    resultTitle: 'Результат загрузки получателей',
    successInfoWithFile_one:
      'Из файла {{fileName}} в базу <b>загружен {{successCount}} получатель</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    successInfoWithFile_few:
      'Из файла {{fileName}} в базу <b>загружено {{successCount}} получателя</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    successInfoWithFile_many:
      'Из файла {{fileName}} в базу <b>загружено {{successCount}} получателей</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    successInfo_one:
      'В базу <b>загружен {{successCount}} получатель</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    successInfo_few:
      'В базу <b>загружено {{successCount}} получателя</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    successInfo_many:
      'В базу <b>загружено {{successCount}} получателей</b> из {{totalCount}}, или <b>{{percent}}%</b>',
    errorInfo_one: '. Не загружен {{errorCount}} получатель. <0><b>Скачать список</b></0>',
    errorInfo_few: '. Не загружены {{errorCount}} получателя. <0><b>Скачать список</b></0>',
    errorInfo_many: '. Не загружены {{errorCount}} получателей. <0><b>Скачать список</b></0>',
  },
  error: {
    atLeastOnePhone: 'Выберите колонку с номером телефона',
    atLeastOnePhoneMessage:
      'Чтобы мы могли отправить вашу рассылку, один из столбцов списка должен иметь заголовок "Телефон" и содержать номера телефонов',
    failedToLoadParameters: 'Произошла ошибка при загрузке переменных пользователя',
    failedToLoadTags: 'Произошла ошибка при загрузке тегов пользователя',
    failedToLoadAddresses: 'Произошла ошибка при загрузке идентификаторов пользователя',
  },
  list: {
    searchPlaceholder: 'Быстрый поиск по ФИО, телефону или идентификатору',
    name: 'ФИО',
    emptyName: 'Имя не задано',
    phone: 'Идентификатор',
    addButton: 'в базу',
    addClient: 'Добавить клиента',
    addList: 'Добавить список',
  },
  notification: {
    emptyFile: 'Файл пуст, пожалуйста, загрузите другой',
  },
  dialog: {
    remove: {
      title: 'Правда хотите удалить пользователя?',
      body: 'Мы безвозвратно потеряем его и всю информацию о нем',
    },
    blacklist: {
      title: 'Внести в черный список?',
      body: 'Так вы внесете пользователя в “черный список”, и он больше не будет получать наши рассылки. Также ему автоматом добавится тег “Черный список”.',
    },
    unblacklist: {
      title: 'Убрать из “черного списка”?',
      body: 'Так вы уберете пользователя из категории “черный список”, и он будет получать наши рассылки. И еще у него автоматом будет убран тег “Черный список”. Убираем?',
    },
  },
  empty: {
    title: 'Здесь пока никого нет',
    subtitle:
      'К сожалению, у вас пока нет никого в базе клиентов. Вы можете создать клиентов вручную или сразу загрузить целый список ',
  },
  noDataWithFilters: {
    title: ':(',
    text: 'Мы не нашли клиента с таким номером телефона или идентификатором',
    hint: 'Проверьте, возможно данные введены неверно',
    button: 'Назад к списку',
  },
  filters: {
    selectTags: 'Выбрать теги',
    recipientTags: 'Теги получателя',
    parameters: 'Переменные',
    clients: 'Клиентов',
  },
};
