import React from 'react';

import {isProdDomain} from 'src/constants';

export const useMetrics = () => {
  React.useEffect(() => {
    if (!isProdDomain()) {
      return;
    }

    const pageTitle = document.head.querySelector('title');

    // Yandex.Metrika
    const scriptYM = document.createElement('script');

    scriptYM.type = 'text/javascript';
    scriptYM.innerHTML = `
      (function(m,e,t,r,i,k,a){
        m[i]=m[i]||function() {(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
      })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(95865849, "init", {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true});
    `;

    const noscriptYM = document.createElement('noscript');

    noscriptYM.innerHTML = `<div><img
        src="https://mc.yandex.ru/watch/95865849" style="position:absolute; left:-9999px;" alt="" /></div>`;

    document.head.insertBefore(scriptYM, pageTitle);
    document.body.insertBefore(noscriptYM, document.body.childNodes[0]);

    // Google Analytics
    const scriptGA = document.createElement('script');

    scriptGA.async = true;
    scriptGA.src = 'https://www.googletagmanager.com/gtag/js?id=G-EQ1PP7GXYK';

    const scriptGADataLayer = document.createElement('script');

    scriptGADataLayer.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)};
      gtag('js', new Date());
      gtag('config', 'G-EQ1PP7GXYK');
    `;

    document.head.insertBefore(scriptGA, pageTitle);
    document.head.insertBefore(scriptGADataLayer, pageTitle);

    // Google Tag Manager
    const scriptGTM = document.createElement('script');

    scriptGTM.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true; j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MG6MSPJQ');
    `;

    const noscriptGTM = document.createElement('noscript');

    noscriptGTM.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MG6MSPJQ"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    document.head.insertBefore(scriptGTM, pageTitle);
    document.body.insertBefore(noscriptGTM, document.body.childNodes[0]);
  }, []);
};
