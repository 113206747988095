import React from 'react';

import {Row} from '@edna/components/primitives';

import * as S from './style';

type TProps = {
  name: string;
};

const UploadedFileName = React.memo<TProps>(({name}) => {
  return (
    <Row>
      <S.OkGreenIcon />
      <S.Label>{name}</S.Label>
    </Row>
  );
});

UploadedFileName.displayName = 'UploadedFileName';

export default UploadedFileName;
