import {TCurrency} from '@edna/utils/formatMoney';

export type TCaptcha = {
  captcha?: string;
  id?: string;
};

type TTenantData = {
  id: number;
  locale: string;
  companyName: string;
  currencyCode: TCurrency;
};

export type TSignInPayload = {
  username: string | null;
  password: string | null;
  captchaId: TCaptcha['id'];
  captcha: string | null;
  rememberMe: true;
};

export type TSignInResponse = {
  name: string | null;
  login: string | null;
  userId: number | null;
  description: string | null;
  accessExpiresIn: number | null;
  refreshExpiresIn: number | null;
  tenants: TTenantData[];
  otpId: string | null;
};

export type TSignInFormData = {
  username: string;
  password: string;
  captcha: string;
};

export type TChangePasswordFormData = {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

export type TSignUpFormData = {
  email: string;
  phone: string;
  password: string;
  countyCode: string;
  companyName?: string;
  authorizedPersonName?: string;
  captcha: string;
};

export type TSignUpConfirmData = {
  confirmCode: string | null;
};

export enum EAuthErrors {
  SEND_WAIT = 'confirmation-send-wait',
  SEND_RETRY = 'confirmation-send-retry',
  SEND_BLOCKED = 'confirmation-send-blocked',
  COMPLETED = 'confirmation-completed',
  TEMPORARY_LOCKED = 'error-temporary-locked',
  INCORRECT_CAPTCHA = 'error-captcha-challenge',
  INCORRECT_CONFIRMATION_CODE = 'confirmation-check-failed',
  OTP_NOT_FOUND = 'otp.not-found',
  OTP_REQUESTS_EXCEEDED = 'otp.too-much-attempts',
}

export const SEND_ERROR_CODES = new Set<string>([
  EAuthErrors.SEND_WAIT,
  EAuthErrors.SEND_RETRY,
  EAuthErrors.SEND_BLOCKED,
]);

export type TState = {
  name?: string | null;
  login?: string | null;
  userId?: number | null;
  description?: string | null;
  accessExpiresIn?: number | null;
  refreshExpiresIn?: number | null;
  tenants?: TTenantData[];
  otpId?: string | null;
};

export type TSendOtpPayload = {
  otpId?: TState['otpId'];
  otpCode: string;
};

export const isSendErrorCode = (code: string) => SEND_ERROR_CODES.has(code);

export enum EPermission {
  // User permissions
  PERMISSION_READ = 'PERMISSION_READ',
  PERMISSION_WRITE = 'PERMISSION_WRITE',
  PERMISSION_INSTAGRAM = 'PERMISSION_INSTAGRAM',
  PERMISSION_BROADCAST_WRITE = 'PERMISSION_BROADCAST_WRITE',
  PERMISSION_BROADCAST_READ = 'PERMISSION_BROADCAST_READ',
  PERMISSION_WHATSAPP_PROFILE = 'PERMISSION_WHATSAPP_PROFILE',
  PERMISSION_BALANCE_READ = 'PERMISSION_BALANCE_READ',
  PERMISSION_TRANSACTION_READ = 'PERMISSION_TRANSACTION_READ',
  PERMISSION_VIEW_BILLING = 'PERMISSION_VIEW_BILLING',
  PERMISSION_BROADCAST_FLOW_INTEGRATION = 'PERMISSION_BROADCAST_FLOW_INTEGRATION',
  PERMISSION_LICENSE_BLOCK = 'PERMISSION_LICENSE_BLOCK',
  PERMISSION_VK_NOTIFY = 'PERMISSION_VK_NOTIFY',
  PERMISSION_OK_NOTIFY = 'PERMISSION_OK_NOTIFY',
  PERMISSION_FLOW = 'PERMISSION_FLOW',
  PERMISSION_STRIPE_ONETIME_INVOICE = 'PERMISSION_STRIPE_ONETIME_INVOICE',

  //  Support permissions
  PERMISSION_TENANT_LIST_READ = 'PERMISSION_TENANT_LIST_READ',
  PERMISSION_TENANT_DATA_READ = 'PERMISSION_TENANT_DATA_READ',
  PERMISSION_TENANT_LICENSE_READ = 'PERMISSION_TENANT_LICENSE_READ',
  PERMISSION_TENANT_CALLBACK_READ = 'PERMISSION_TENANT_CALLBACK_READ',
  PERMISSION_TENANT_CALLBACK_WRITE = 'PERMISSION_TENANT_CALLBACK_WRITE',
  PERMISSION_TENANT_APIKEY_READ = 'PERMISSION_TENANT_APIKEY_READ',
  PERMISSION_TENANT_APIKEY_WRITE = 'PERMISSION_TENANT_APIKEY_WRITE',
  PERMISSION_TENANT_DETAILS_READ = 'PERMISSION_TENANT_DETAILS_READ',
  PERMISSION_TENANT_DETAILS_WRITE = 'PERMISSION_TENANT_DETAILS_WRITE',
  PERMISSION_TENANT_TARIFF_READ = 'PERMISSION_TENANT_TARIFF_READ',
  PERMISSION_TENANT_TRANSACTION_READ = 'PERMISSION_TENANT_TRANSACTION_READ',
  PERMISSION_TENANT_BALANCE_READ = 'PERMISSION_TENANT_BALANCE_READ',
  PERMISSION_TENANT_BALANCE_ADD = 'PERMISSION_TENANT_BALANCE_ADD',
  PERMISSION_TENANT_BALANCE_SUBTRACT = 'PERMISSION_TENANT_BALANCE_SUBTRACT',
  PERMISSION_TARIFF_TEMPLATE_READ = 'PERMISSION_TARIFF_TEMPLATE_READ',
  PERMISSION_TENANT_STORNO = 'PERMISSION_TENANT_STORNO',

  //  Tenant license permissions
  PERMISSION_TENANT_LICENSE_RENEW = 'PERMISSION_TENANT_LICENSE_RENEW',
}

export enum ERoles {
  ROLE_USER = 'ROLE_USER',
  ROLE_ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_SUPER_USER = 'ROLE_SUPER_USER',
  ROLE_TENANT_LICENSE_RENEW = 'ROLE_TENANT_LICENSE_RENEW',
}
