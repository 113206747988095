import {EStatus} from './definitions';

export default {
  title: {
    list: 'Анкеты WhatsApp',
    edit: 'Анкета',
    new: 'Новая анкета',
  },
  message: {
    title: 'Важно до регистрации',
    text: 'До регистрации канала в системе edna вам необходимо иметь <0>ID компании в Facebook Business Manager</0>',
  },
  managerId: 'ID компании в Facebook Business Messenger',
  editForm: {
    title: 'Анкета регистрации канала',
    nameLabel: 'Внутреннее название канала',
    phoneCodeLabel: 'Код страны',
    phoneLabel: 'Номер телефона, на который будет зарегистрирован WhatsApp Business Account',
    phoneHint: `указывается без кода страны, данный телефон будет отображаться в профиле компании в WhatsApp;
      телефонный номер должен уметь принимать SMS-сообщения или звонки из США`,
    phoneWarning: 'Проверьте правильность заполнения номера',
    senderNameLabel: 'Отображаемое имя отправителя',
    senderNameHint: 'См. <0>политику отображаемого имени</0>',
    addressLabel: 'Адрес компании',
    addressHint: 'данный адрес будет отображаться в профиле компании в WhatsApp',
    descriptionLabel: 'Краткое описание деятельности компании',
    urlsLabel: 'Ваш сайт',
    site: 'сайт',
    urlsHint:
      'данный сайт будет использоваться при верификации анкеты, а также отображаться в профиле компании в WhatsApp',
    emailLabel: 'E-mail адрес компании',
    emailHint: 'данный e-mail будет отображаться в профиле компании в WhatsApp',
    businessKindLabel: 'Вид бизнеса',
    contactStatusLabel: 'Строка статуса профиля в WhatsApp',
    contactStatusHint: 'Данное значение можно позже поменять через вашего менеджера',
    fileLabel: 'Прикрепите лого компании',
    fileHint: 'Разрешен только формат jpeg. Минимальный размер изображения 400х400 пикселей.',
    facebookPageUrlLabel: 'URL на страницу Facebook',
    facebookPageUrlHint: 'если есть',
    alternativeName: 'альтернативное название',
    alternativeNamesLabel:
      'Альтернативное название компании, по которому вас может узнать ваш клиент',
    communicationSolution: {
      label: 'Используемое решение',
    },
  },
  instructionManual: 'Инструкция',
  requestCode: 'Запросить код',
  sendCode: 'Отправить код',
  accountVerificationLink: 'Ссылка для подтверждения аккаунта',
  invalidNaturalSize: 'Слишком маленькое изображение',
  invalidLogoFormat: 'Неверный формат логотипа',
  sendVerificationSMS: 'SMS с кодом отправлено',
  sendVerificationVOICE: 'Голосовое сообщение с кодом отправлено',
  phoneNumberVerified: 'Номер телефона подтвержден',
  businessKind: {
    Automotive: 'Автомобильный бизнес',
    'Beauty, Spa and Salon': 'Салон красоты, SPA, парикмахерская',
    'Clothing and Apparel': 'Одежда',
    Education: 'Образование',
    Entertainment: 'Развлекательная программа',
    'Event Planning and Service': 'Планирование и обслуживание мероприятий',
    'Finance and Banking': 'Финансы и банковское дело',
    'Food and Grocery': 'Еда и Бакалея',
    'Public Service': 'Государственная служба',
    'Hotel and Lodging': 'Отель и жилье',
    'Medical and Health': 'Медицина и здоровье',
    'Non-profit': 'Некоммерческая организация',
    'Professional Services': 'Профессиональные услуги',
    'Shopping and Retail': 'Покупки и розничная торговля',
    'Travel and Transportation': 'Путешествия и транспорт',
    Restaurant: 'Ресторанный бизнес',
    Other: 'Иное',
  },
  status: {
    [EStatus.SENT]: {
      title: 'Ожидает регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.DRAFT]: {
      title: 'Черновик',
      details: '',
    },
    [EStatus.NEW]: {
      title: 'Новый',
      details: '',
    },
    [EStatus.CREATE_ENVIRONMENT]: {
      title: 'Ожидает регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.CONFIRM_CODE_REQUEST]: {
      title: 'На регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.SEND_CONFIRM_CODE]: {
      title: 'На регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.FINISH_REGISTER]: {
      title: 'На регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.CREATE_WHATSAPP_ACCOUNT]: {
      title: 'На регистрации',
      details: 'Канал не готов к работе',
    },
    [EStatus.CONFIRM_ACCOUNT]: {
      title: 'Требует внимания',
      details: 'Канал не готов к работе',
    },
    [EStatus.NEED_CONFIRM_ACCOUNT]: {
      title: 'Требует внимания',
      details: 'Канал не готов к работе',
    },
    [EStatus.NEED_VERIFY_FACEBOOK_BUSINESS]: {
      title: 'Требует внимания',
      details: 'Канал не готов к работе',
    },
    [EStatus.NEED_CONFIRM_CODE]: {
      title: 'Требует внимания',
      details: 'Канал не готов к работе',
    },
    [EStatus.DONE_VERIFIED]: {
      title: 'Зарегистрирован',
      details: 'Работает исправно (получил официальный статус)',
    },
    [EStatus.DONE_NOT_VERIFIED]: {
      title: 'Зарегистрирован',
      details: 'Работает исправно (аккаунт без официального статуса)',
    },
    [EStatus.CANCELED]: {
      title: 'Отменен',
      details: '',
    },
    [EStatus.BLOCKED]: {
      title: 'Заблокирован',
      details: '',
    },
    [EStatus.UNKNOWN]: {
      title: 'Неизвестный',
      details: '',
    },
  },
  errors: {
    EXTERNAL: `Facebook Business Manager c данным ID не существует.
    Следуйте инструкции, чтобы найти ваш ID:`,
    UNEXPECTED: `Произошла непредвиденная ошибка.
    Свяжитесь со службой поддержки {{supportEmail}}`,
  },
};
