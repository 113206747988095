enum EListState {
  UNINITIALIZED = 'UNINITIALIZED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type TListState = keyof typeof EListState;

const PAGE_SIZE = 15;

export {EListState, PAGE_SIZE};
export type {TListState};
