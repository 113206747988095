import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';

import {TAudienceGroupState} from 'src/constants';
import {TItem as TTagsItem} from 'src/containers/Tags/definitions';
import {TListState, TMeta} from 'src/models';

export enum ESystemTagsCodes {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  GENDER_UNKNOWN = 'GENDER_UNKNOWN',
  AGE_LT_18 = 'AGE_LT_18',
  AGE_18_TO_25 = 'AGE_18_TO_25',
  AGE_26_TO_30 = 'AGE_26_TO_30',
  AGE_31_TO_35 = 'AGE_31_TO_35',
  AGE_36_TO_40 = 'AGE_36_TO_40',
  AGE_40_TO_45 = 'AGE_40_TO_45',
  AGE_45_TO_50 = 'AGE_45_TO_50',
  AGE_GT_50 = 'AGE_GT_50',
  AGE_UNKNOWN = 'AGE_UNKNOWN',
  BLACKLIST = 'BLACKLIST',
  CREATED_BY_SYSTEM = 'CREATED_BY_SYSTEM',
  CREATED_AUTOMATICALLY = 'CREATED_AUTOMATICALLY',
}

export const DEFAULT_TAGS_LIMIT = 50;
export const SUBSCRIBERS_TAGS_LIMIT = 20;
export const PROHIBIT_REPETITIONS_CATEGORIES_CODES = ['GENDER', 'AGE'];

export const ALL_RECEIVERS_TAG_ID = -1;
export const BROADCAST_LIST_CATEGORY_CODE = 'BROADCAST_LISTS';
export const SYSTEM_CATEGORY_CODE = 'SYSTEM_TAGS';
export const HIDDEN_CATEGORY_CODE = 'HIDDEN';
export const ALL_RECEIVERS_TAG = {
  id: ALL_RECEIVERS_TAG_ID,
  name: '',
  code: null,
  subscriberId: null,
  // categoryID добавляется в getTagsGroups
  categoryId: null,
};
export const BLACKLIST_CODE = 'BLACKLIST';
export const AUDIENCE_LISTS_CATEGORY_ID = 5787;

export type TCategoryMap = Record<TTagCategory['id'], TTagCategory>;

export type TTagCategory = {
  id: number;
  parentId: number;
  name: string;
  childIds: TTagCategory['id'][];
  code?: string;
};

export type TBackendItem = {
  tagId: number;
  name: string;
  code: string | null;
  subscriberId: number | string | null;
  categoryId: number;
};

export type TItem = {
  id: number;
  name: string;
  code: string | null;
  subscriberId: number | string | null;
  categoryId: number | null;
};

export type TState = {
  itemsMap: Record<TItem['id'], TItem>;
  newTagsIds: TItem['id'][];
  limit: number;
  blacklistTag: TItem;
};

export type TActions = {
  // Reducers
  receiveAllItems: TActionCreator<TBackendItem[]>;
  receiveItems: TActionCreator<
    Pick<TListState<TItem>, 'last' | 'totalElements'> & {content: TBackendItem[]}
  >;
  receiveItemsByIds: TActionCreator<TBackendItem[]>;
  insertItem: TActionCreator<TBackendItem>;
  refreshItem: TActionCreator<TBackendItem>;
  receiveCategories: TActionCreator<TItem[]>;
  resetNewTagsIds: TActionCreator;
  setNewTagsIds: TActionCreator<TState['newTagsIds']>;
  setLimit: TActionCreator<TState['limit']>;
  setBlacklistTag: TActionCreator<TBackendItem>;

  // Thunks
  requestCategories: TThunkCreator;
  saveItem: TThunkCreator<Partial<TItem>, TMeta | void, Promise<TBackendItem | void>>;
  requestItemsByIds: TThunkCreator<TItem['id'][], TMeta | void, Promise<TBackendItem[] | void>>;
  requestItemByCode: TThunkCreator<TItem['code'], void, Promise<TBackendItem | void>>;
};

export type TTagsGroup = {id: TItem['categoryId']; path: TItem['id'][]; tags: TItem[]};
export type TTagsGroups = Record<number, TTagsGroup>;

export type TOperator = 'MINUS' | 'AND' | 'OR' | 'ALL';

export type TTagsOperand = {
  operator: 'EQ';
  delay: string | null;
  tagId: number;
};

export type TOperand = {
  operator: TOperator;
  operands?: (TOperand | TTagsOperand)[];
};

export type TStatus = 'CREATED' | 'CALCULATION_IN_PROGRESS' | 'COMPLETED' | 'ERROR';

export type TTagQuery = {
  id: number;
  query: TOperand | TTagsOperand;
  status: TStatus;
  subscribersCount: number;
  error: string;
};

export type TAudience = Record<TAudienceGroupState, TAudienceParam[]>;

export type TAudienceOperator = 'OR' | 'AND';

export type TAudienceParam = {
  id: string | number;
  type: TAudienceOperator;
  items: TTagsItem['id'][];
  delay: string | null;
};

export type TFormData = {
  audience: TAudience;
};
