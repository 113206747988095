import {Link as BaseRouterLink} from 'react-router-dom';

import styled, {css} from 'styled-components';

import {ESize, TSize} from '@edna/components';
import {TTheme, mixins as uiMixins} from '@edna/components/styles';
import {TSpacingSize} from '@edna/components/types';

import {link} from 'src/styles/mixins';

type TIndent = {
  topIndent?: TSpacingSize;
  rightIndent?: TSpacingSize;
  bottomIndent?: TSpacingSize;
  leftIndent?: TSpacingSize;
};

type TTextProps = TIndent & {
  isInline?: boolean;
  isCenter?: boolean;
  color?: TThemeColor;
  size?: TSize;
  secondary?: boolean;
  weight?: number;
};

const getFontSize = (size?: TSize) => {
  switch (size) {
    case ESize.XS:
      return uiMixins.f2;
    case ESize.S:
      return uiMixins.f3;
    case ESize.L:
      return uiMixins.f5;
    case ESize.XL:
      return uiMixins.f6;
    case ESize.M:
    default:
      return uiMixins.f4;
  }
};

const getSpacing =
  (indent: TSpacingSize) =>
  ({theme}: TTheme) =>
    theme.spacing[`s${indent}`];

const Text = styled.div<TTextProps>`
  ${({
    theme,
    color = 'grey900',
    secondary,
    weight = 400,
    topIndent = '0',
    rightIndent = '0',
    bottomIndent = '0',
    leftIndent = '0',
    isInline,
    isCenter,
    size,
  }) => css`
    ${getFontSize(size)};
    text-align: ${isCenter ? 'center' : 'inherit'};
    margin-top: ${getSpacing(topIndent)};
    margin-right: ${getSpacing(rightIndent)};
    margin-bottom: ${getSpacing(bottomIndent)};
    margin-left: ${getSpacing(leftIndent)};
    color: ${secondary ? theme.branding.content.secondary : theme.palette[color]};
    font-weight: ${weight};
    display: ${isInline ? 'inline' : 'block'};
    overflow-wrap: anywhere;
  `}
`;

const SecondaryText = styled(Text)`
  color: ${({theme}: TTheme) => theme.branding.content.secondary};
`;

const Hint = styled(SecondaryText)`
  ${uiMixins.f2}
  ${({theme, topIndent}) => css`
    margin-top: ${topIndent === '0' ? 0 : theme.spacing[`s${topIndent ?? 2}`]};
  `}
`;

const BigText = styled(Text)`
  ${uiMixins.f5}
  font-weight: 500;
  margin-bottom: ${({bottomIndent = '4'}: TTextProps) => getSpacing(bottomIndent)};
`;

const RouterLink = styled(BaseRouterLink)`
  ${link}
`;

const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${link}
`;

const InlineLink = styled(Link)<{weight?: number}>`
  font-weight: ${({weight}) => weight ?? 400};
  text-decoration: underline;
  color: currentColor;
`;

const SecondaryLink = styled(Link)`
  text-decoration: underline;
  ${({theme: {branding}}: TTheme) => css`
    color: ${branding.content.secondary};
    &:active {
      color: ${branding.content.secondary};
    }
  `}
`;

const FormTitle = styled.div<TTheme & {noMargin?: boolean}>`
  ${uiMixins.f5}

  ${({theme, noMargin}) => css`
    color: ${theme.branding.content.secondary};
    ${!noMargin &&
    css`
      margin-bottom: ${theme.spacing.s4};
    `}
  `};
`;

const Error = styled(Text)`
  ${uiMixins.f2}
  color: ${({theme}: TTheme) => theme.palette.red400};
`;

export {
  Text,
  SecondaryText,
  Hint,
  BigText,
  InlineLink,
  SecondaryLink,
  Link,
  RouterLink,
  FormTitle,
  getFontSize,
  Error,
};
