import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';

import {endpoints} from './api';
import {TErrors} from './types';

type TState = {
  filters: TAnyObject;
  flowStepErrors: TErrors;
};

const initialState: TState = {
  filters: {},
  flowStepErrors: {},
};

const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
    setFlowStepErrors(state, action: PayloadAction<TErrors>) {
      state.flowStepErrors = action.payload;
    },
    removeFlowStepError(state, action: PayloadAction<string>) {
      delete state.flowStepErrors[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(endpoints.activateFlow.matchFulfilled, (state) => {
      state.filters = {};
    });
    builder.addMatcher(endpoints.deactivateFlow.matchFulfilled, (state) => {
      state.filters = {};
    });
    builder.addMatcher(endpoints.deleteFlow.matchFulfilled, (state) => {
      state.filters = {};
    });
  },
});

export const {
  setFilters: setFiltersAction,
  setFlowStepErrors: setFlowStepErrorsAction,
  removeFlowStepError: removeFlowStepErrorAction,
} = triggersSlice.actions;
export const selectFilters = (state: TRootState) => state.triggers.filters;
const triggersSelect = (state: TRootState) => state;

export const selectFlowStepErrorsById = (id: string) => {
  return createSelector(triggersSelect, (state) => state.triggers.flowStepErrors[id]);
};

export default triggersSlice.reducer;
