export default {
  validator: {
    uppercase: 'Есть прописная буква',
    lowercase: 'Есть строчная буква',
    latin: 'Только латиница',
    length: 'От 8 до 32 символов',
    number: 'Есть цифра',
    specialCharacter: 'Есть спецсимвол',
  },
};
