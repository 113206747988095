import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const Gallery = styled.div`
  ${mixins.flexAlign('center', 'flex-start')}
  position: relative;
  width: 100%;
`;

type IArrowIconProps = {
  disabled: boolean;
};

const iconWrapper = css<IArrowIconProps>`
  flex-shrink: 0;
  margin: auto 0;
  ${mixins.round('24px')}
  ${mixins.flexAlign('center')}
  padding: 0;
  border: none;
  outline: none;
  ${({theme: {palette, branding}, disabled}) => css`
    color: ${palette.white};
    background-color: ${disabled ? branding.colors.disabled : branding.content.primary};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const ArrowLeft = styled.button<IArrowIconProps>`
  ${iconWrapper};
  margin-right: ${({theme}) => theme.spacing.s4};
`;

export const ArrowRight = styled.button<IArrowIconProps>`
  ${iconWrapper};
  margin-left: ${({theme}) => theme.spacing.s4};
  transform: rotate(180deg);
`;
