import {TItem as TParameterValueItem} from 'src/containers/ParameterValues/api';
import {ESystemParameter, TItem as TParametersItem} from 'src/containers/Parameters/definitions';
import {TItem as TTagsItem} from 'src/containers/Tags/definitions';

export const SUBSCRIBERS_FORM_ID = 'subscribers/form';

type TColumn = {
  order: number;
  value: string;
};

export type TSubscribersUploadPreview = {
  subscribers: {columns: TColumn[]}[];
  subscribersUploadId: number;
};

export type TSubscribersConfirmPayload = {
  columns: {order: TColumn['order']; parameterId: TParametersItem['id']}[];
  tagIds: TTagsItem['id'][];
  subscribersUploadId: number;
};

export type TSubscribersUploadStatus = {
  errorCount: number;
  successCount: number;
  totalCount: number;
  loadedPercentage: number;
  uploadPercentage: number;
};

export type TAutoGeneratedTag = {
  name: string;
  tagId: number;
  categoryId: number;
};

export type TAttributesForSave = {
  params: {
    create: TParametersItem[];
    update: TParametersItem[];
    delete: TParametersItem[];
  };
  tags: {
    create: TTagsItem[];
    delete: TTagsItem[];
  };
  addresses: {
    create: Omit<TAddressItem, 'id'>[];
    update: TAddressItem[];
  };
};

export type TSubscriber = {
  id: number;
  blacklisted: boolean;
  address: string;

  // есть в списке, нет при редактирование
  firstName?: string;
  lastName?: string;
  middleName?: string;
};

export type TSubscriberSaveRequest = Omit<TSubscriber, 'id' | 'address'> & {
  id?: number;
};

export type TItemCreate = Optional<Pick<TSubscriber, 'id' | 'blacklisted'>, 'id'>;
export type TItemPayload = Pick<TSubscriber, 'id' | 'blacklisted'>;

export enum EAddress {
  EDNA_ID = 'EDNA_ID',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  UTM = 'UTM',
  COOKIE_ID = 'COOKIE_ID',
  INSTAGRAM_ID = 'INSTAGRAM_ID',
  FACEBOOK_ID = 'FACEBOOK_ID',
  TELEGRAM_ID = 'TELEGRAM_ID',
  GOOGLE_ID = 'GOOGLE_ID',
  APPLE_ID = 'APPLE_ID',
  YANDEX_ID = 'YANDEX_ID',
  DEVICE_APP_ID = 'DEVICE_APP_ID',
  EXT_USER_ID = 'EXT_USER_ID',
}

export type TAddressItemInfo = {
  deviceName?: string | null;
  systemName?: string | null;
  deviceUID: string | null;
  appPackageName: string | null;
};

export type TAddressItem = {
  id: string;
  address: string | null;
  type: EAddress;
  info: TAddressItemInfo | null;
};

export type TSubscriberAttributes = {
  parameters: TParametersItem[];
  tags: TTagsItem[];
  addresses: TAddressItem[];
};

export type TEditFormData = {
  blacklisted: TSubscriber['blacklisted'];
  phone: string | null;

  systemParameters: Record<keyof typeof ESystemParameter, TParametersItem['value'] | null>;
  parameters: TParametersItem[];
  tags: TTagsItem[];
  addresses: TAddressItem[];
};

export type TGroupEditFormColumn = {value: TParametersItem['id'] | null};

export type TGroupEditFormData = {
  columns: TGroupEditFormColumn[];
  tagIds: TTagsItem['id'][];
  subscribersUploadId: number;
};

export type TFilters = {
  tags?: TTagsItem[];
  parameters?: TParameterValueItem[];
};

export type TItemsFilters = TFilters & {
  id: number;
};
