import defaultsDeep from 'lodash/defaultsDeep';

import {TActionCreator, TThunkCreator} from '@edna/models/apiModel';
import {TCurrency} from '@edna/utils/formatMoney';
import storage from '@edna/utils/storage';

import {EResellerIds, EStorageKeys} from 'src/constants';
import userProfileModel from 'src/containers/UserCompanyProfile/model';
import {TListItemId, apiModel, clearStorage, errorNotification, request} from 'src/models';

import {EPermission, ERoles} from './definitions';

export enum EFeatureFlag {
  SMS_PROFILE_QUESTIONNAIRE = 'MFMSCOM-6093_sms_profile_questionnaire',
  BROADCAST_START_TIME_PERIOD_CHECK = 'EP-260_broadcast_start_time_period_check',
  STRIPE_AUTOPAYMENT = 'EP-3258_stripe_checkout_integration',
  WA_ES_ENABLED_FOR_ALL = 'EP-3260_wa_es_all',
  WA_ES_TENANT_EXCEPTION = 'EP-3260_wa_es_tenant_exception',
}

export type TTenant = {
  id: TListItemId;
  locale: string;
  companyName: string;
  currencyCode: TCurrency;
};

export type TUser = {
  id: number | null;
  login: string | null;
  description: string | null;
  name: string | null;
  roles: ERoles[];
  permissions: EPermission[];
  tenants: TTenant[];
  resellerId: number;
  timeZone?: string;
};

enum EMigrationStatus {
  NEED_MIGRATION = 'NEED_MIGRATION',
  INITIAL_MIGRATION_FINISHED = 'INITIAL_MIGRATION_FINISHED',
  FINAL_MIGRATION_FINISHED = 'FINAL_MIGRATION_FINISHED',
  MIGRATION_FAILED = 'MIGRATION_FAILED',
}
export type TMigration = {
  status?: EMigrationStatus;
  initialMigrationAt?: string;
  licenseStartAt?: string;
  firstPaymentDelayDays?: number;
  finalMigrationAt?: number;
  tenantId?: number;
};

export type TState = {
  loading: boolean;
  loaded: boolean;
  logged: boolean;
  user: TUser;
  tenantId: TTenant['id'] | undefined;
  migration: TMigration | undefined;
  // пока не используется, может быть понадобится
  isMigrationLoading: boolean;
  featureFlags: Partial<Record<EFeatureFlag, boolean>>;
};

type TActions = {
  // Reducers
  setLoading: TActionCreator<TState['loading']>;
  setLoaded: TActionCreator<TState['loaded']>;
  setLogged: TActionCreator<TState['logged']>;
  receiveUser: TActionCreator<Partial<TUser>>;
  resetUserReducer: TActionCreator;
  updateTenantId: TActionCreator<TTenant['id']>;
  setTenants: TActionCreator<TTenant[] | undefined>;
  setMigration: TActionCreator<TMigration | undefined>;
  setMigrationLoading: TActionCreator<boolean>;
  setFeatureFlags: TActionCreator<TState['featureFlags']>;
  // Thunks
  requestUser: TThunkCreator<void, void, Promise<{result: Partial<TUser> | void}>>;
  resetUser: TThunkCreator;
  setDefaultTenantId: TThunkCreator<{useLast: boolean} | void>;
  changeTenantId: TThunkCreator<TTenant['id']>;
  removeTenant: TThunkCreator;
  initializationAtLogin: TThunkCreator<TTenant[] | undefined, void, Promise<Partial<TUser> | void>>;
  requestMigration: TThunkCreator;
  getFeatureFlags: TThunkCreator;
  setTimeZone: TThunkCreator<string, void, Promise<void>>;
};

const defaultUser: TUser = {
  id: null,
  login: null,
  description: null,
  name: null,
  roles: [],
  permissions: [],
  tenants: [],
  resellerId: -1,
};

const id = 'user';

const selectors = {
  login: (state: TState) => state.user.login,
  tenants: (state: TState) => state.user.tenants,
  name: (state: TState) => state.user.name,
  tenant: (state: TState) => selectors.tenants(state).find((item) => item.id === state.tenantId),
  locale: (state: TState) => selectors.tenant(state)?.locale,
  currencyCode: (state: TState) => selectors.tenant(state)?.currencyCode,
  userPermissions: (state: TState) => state.user.permissions,
  userRoles: (state: TState) => state.user.roles,
  resellerId: (state: TState) => state.user.resellerId,
  isOSKReseller: (state: TState) => selectors.resellerId(state) === EResellerIds.OSK_ID,
  noWritePermission: (state: TState) =>
    !state.user.permissions.includes(EPermission.PERMISSION_WRITE),
  noWriteBroadcastPermission: (state: TState) =>
    selectors.noWritePermission(state) ||
    !state.user.permissions.includes(EPermission.PERMISSION_BROADCAST_WRITE),
  timeZone: (state: TState) => state.user.timeZone,
};

const userModel = apiModel<typeof id, TEmptyObject, TState, TActions, typeof selectors>({
  id,
  defaultState: {
    loading: false,
    loaded: false,
    logged: false,
    user: defaultUser,
    tenantId: undefined,
    migration: undefined,
    isMigrationLoading: false,
    featureFlags: {},
  },
  selectors,
  reducers: {
    setLoading: (state, {payload}) => ({
      ...state,
      loading: payload,
    }),
    setLoaded: (state, {payload}) => ({
      ...state,
      loaded: payload,
    }),
    setLogged: (state, {payload}) => ({
      ...state,
      logged: payload,
    }),
    receiveUser: (state, {payload}) => ({
      ...state,
      user: defaultsDeep({...payload}, defaultUser),
    }),
    resetUserReducer: (state) => ({
      ...state,
      logged: false,
      user: defaultUser,
    }),
    setTenants: (state, {payload}) => ({
      ...state,
      user: {
        ...state.user,
        tenants: payload ?? [],
      },
    }),
    updateTenantId: (state, {payload}) => ({
      ...state,
      tenantId: payload,
    }),
    setMigration: (state, {payload}) => ({
      ...state,
      migration: payload,
    }),
    setMigrationLoading: (state, {payload}) => ({
      ...state,
      isMigrationLoading: payload,
    }),
    setFeatureFlags: (state, {payload}) => ({
      ...state,
      featureFlags: payload ?? undefined,
    }),
  },
  api: {
    // TODO: при переводе на RTK перенести запрос getBankDetails из src/containers/LeadCompanyClients/api
    requestUser: () => request.get('/rest/tenantmanagement/user/current'),
    setTimeZone: (timeZone) => request.put('/rest/tenantmanagement/user/time-zone', {timeZone}),
    requestMigration: () => request.get('/rest/tenantmanagement/migration'),
    getFeatureFlagsByTenant: (payload) =>
      request.get(`/rest/tenantmanagement/unleash/feature-toggle-list/${payload.tenantId}`),
  },
  thunks: ({actions, api, select}) => ({
    initializationAtLogin: async ({dispatch}, {payload}) => {
      dispatch(actions.setTenants(payload));
      dispatch(actions.setDefaultTenantId());
      const {result: userData} = await dispatch(actions.requestUser());

      return userData;
    },
    resetUser: async ({dispatch}) => {
      dispatch(actions.setLoading(true));
      dispatch(actions.resetUserReducer());
      dispatch(actions.setLoading(false));
    },
    requestUser: async ({dispatch}) => {
      dispatch(actions.setLoading(true));
      const {result, error} = await api.requestUser();

      if (result) {
        await dispatch(actions.getFeatureFlags());

        dispatch(actions.receiveUser(result));
        dispatch(actions.setDefaultTenantId());
        dispatch(actions.setLogged(true));
        dispatch(userProfileModel.actions.requestTenantData());
      }

      if (error && error.status !== 401) {
        errorNotification(error);
      }

      dispatch(actions.setLoaded(true));
      dispatch(actions.setLoading(false));

      return {result};
    },
    setDefaultTenantId: ({dispatch, getState}, {payload = {}}) => {
      const lastTenantId = Number(storage.get(EStorageKeys.TENANT_ID));
      const {useLast} = payload;

      const getDefaultTenantId = () => {
        const {user} = select(getState());
        const {tenants} = user;
        const tenant = tenants.find(({id: tenantId}) => tenantId === lastTenantId);

        if (useLast && lastTenantId !== 0) {
          return lastTenantId;
        }

        if (tenant !== undefined) {
          return lastTenantId;
        }

        if (tenants.length > 0) {
          return tenants[0].id;
        }

        return undefined;
      };

      const tenantId = getDefaultTenantId();

      if (tenantId) {
        if (tenantId !== lastTenantId) {
          storage.set(EStorageKeys.TENANT_ID, tenantId);
        }
        dispatch(actions.updateTenantId(tenantId));
        request.defaults.headers.common['tenant-id'] = String(tenantId);
      } else {
        clearStorage();
        delete request.defaults.headers.common['tenant-id'];
      }
    },
    changeTenantId: ({dispatch}, {payload}) => {
      // TODO при написании ролевой модели перезапрашивать права и роли пользователя (экшн requestUser) при сменене тенанты
      dispatch(actions.updateTenantId(payload));
      storage.set(EStorageKeys.TENANT_ID, payload);
      request.defaults.headers.common['tenant-id'] = String(payload);
    },
    removeTenant: () => {
      delete request.defaults.headers.common['tenant-id'];
    },
    requestMigration: async ({dispatch}) => {
      dispatch(actions.setMigrationLoading(true));
      const {result, error} = await api.requestMigration();

      if (result) {
        dispatch(actions.setMigration(result));
      }

      if (error) {
        errorNotification(error);
      }

      dispatch(actions.setMigrationLoading(false));
    },
    getFeatureFlags: async ({dispatch}) => {
      const tenantId = Number(storage.get(EStorageKeys.TENANT_ID));

      const {error, result} = await api.getFeatureFlagsByTenant({tenantId});

      if (result) {
        dispatch(actions.setFeatureFlags(result));
      }

      if (error) {
        errorNotification(error);
      }
    },
    setTimeZone: async ({dispatch}, {payload}) => {
      const {error} = await api.setTimeZone(payload);

      if (error) {
        return;
      }

      const {result} = await api.requestUser();

      if (result) {
        dispatch(actions.receiveUser(result));
      }
    },
  }),
});

export default userModel;
