import styled, {css} from 'styled-components';

import {TTheme, mixins} from '@edna/components/styles';

export const Message = styled.div`
  position: relative;
  width: max-content;
  overflow: hidden;
  min-width: 100px;
  border-radius: 22px;
  line-height: 18px;
  background-color: #efefef;
`;

export const Text = styled.div`
  margin: 0 ${({theme}: TTheme) => theme.spacing.s2};
`;

export const Avatar = styled.div`
  ${mixins.round('24px')}
  ${mixins.flexAlign('center')}
  flex-shrink: 0;
  ${({theme: {palette, spacing}}: TTheme) => css`
    color: ${palette.white};
    background-color: ${palette.black};
    margin: auto ${spacing.s2} 0 0;
    padding: ${spacing.s05};
  `}
`;

export const EmptyImage = styled.div`
  ${mixins.wh('200px')}
  ${mixins.flexAlign('center')}
`;
