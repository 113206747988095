import {EIntegrationTabs} from './index';

export default {
  tabs: {
    [EIntegrationTabs.DOCUMENTATION]: 'Документация',
    [EIntegrationTabs.TESTING]: 'Тестирование',
    [EIntegrationTabs.SETTINGS]: 'Настройки',
    [EIntegrationTabs.MODULES]: 'Готовые модули',
  },
  documentation: {
    whatsapp: {
      title: 'WhatsApp',
      description: 'Описание WhatsApp API',
    },
    push: {
      title: 'Push',
      description: 'Настройка приложений пуш-платформы',
    },
    go: 'Перейти',
  },
  testing: {
    row1: {
      apiKey: 'Ваш API-ключ',
      noApiKey: 'Сгенерируйте API-ключ',
    },
    row2: {
      inbox: 'Входящие сообщения мы отправляем на',
      noInbox:
        'Укажите ссылку на сервис вашего приложения, куда мы будем отправлять входящие сообщения (необязательно)',
    },
    row3: {
      statuses: 'Статусы сообщений мы отправляем на',
      noStatuses:
        'Укажите ссылку на сервис вашего приложения, куда мы будем отправлять информацию о смене статусов сообщений (необязательно)',
    },
    row4: {
      text: 'Укажите тестовые номера телефонов',
      hint: 'Вставьте номер телефона для тестирования',
    },
    row5: {
      text: 'Перейдите по ссылке ',
      subtitle: 'Или отсканируйте код',
      hint: `Напишите в чате любое сообщение с тестового номера. Этим вы инициируете переписку в чате.
    Проверьте в <0>отчете</0> ваше сообщение и проверьте, получено ли оно в вашем приложении`,
    },
    row6: {
      text: 'Отправьте в ответ исходящее сообщение на номер, с которого вы отправили входящее сообщение',
      hint: 'Проверьте в отчете статус вашего сообщения и проверьте, получено ли он в вашем приложении',
      resetValues: 'Сбросить значения',
      callResource: 'Отправить запрос',
      response: 'Ответ',
    },
    generate: 'Сгенерировать API-ключ',
    addPhone: 'Добавить номер',
    addLink: 'Добавить ссылку',
  },
  modules: {
    jivo: {
      description: 'Подключение канала WhatsApp к чат-платформе Jivo',
      modal: {
        title: 'Подключить Jivo?',
        subtitle:
          'Все ваши каналы на платформе edna будут подключены к чат-платформе Jivo. Вы уверены?',
        no: 'Ой, нет',
      },
      noSubjects: 'Для подключения модуля у вас должна быть активирована подпись WhatsApp',
    },
    connect: 'Подключить',
    disconnect: 'Отключить',
    connected: 'Подключено',
  },
};
