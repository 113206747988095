import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSpring} from 'react-spring';

import {useTheme} from 'styled-components';

import {Button, TSize} from '@edna/components';
import {useMeasure} from '@edna/components/utils';

import {TStatusIcon} from '../StatusIcon';
import {TMessageType} from './constants';
import * as S from './style';

const iconNames: Record<TMessageType, TStatusIcon> = {
  Success: 'ok',
  SuccessLight: 'okContour',
  Info: 'info',
  Cancelled: 'cancelled',
  Pending: 'pending',
  Warning: 'warning',
  Error: 'error',
};

type TProps = {
  className?: string;
  noMargin?: boolean;
  type?: TMessageType;
  title?: React.ReactNode;
  children?: React.ReactNode;
  closible?: boolean;
  hasFullWidth?: boolean;
  fontSize?: TSize;
  closeButtonText?: string;
};

const Message = React.memo<TProps>(
  ({
    className,
    children,
    noMargin,
    title,
    type,
    closible,
    hasFullWidth,
    fontSize,
    closeButtonText,
  }) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [isVisible, setIsVisible] = React.useState(true);
    const [childrenRef, {height: childrenHeight}] = useMeasure<HTMLDivElement>();

    const styles = useSpring({
      from: {
        maxHeight: childrenHeight / 2,
        overflow: 'hidden',
        opacity: 0,
      },
      to: [
        {
          maxHeight: isVisible ? childrenHeight : 0,
          overflow: isVisible ? 'visible' : 'hidden',
        },
        {
          opacity: isVisible ? 1 : 0,
          config: {duration: theme.animation.duration / 2},
        },
      ],
      config: {duration: theme.animation.duration},
    });

    const handleClose = React.useCallback(() => setIsVisible(false), []);

    return (
      <S.AnimatedDiv style={styles}>
        <S.MessageWrapper ref={childrenRef}>
          <S.Message
            data-selector="Components:Message"
            className={className}
            noMargin={noMargin}
            hasFullWidth={hasFullWidth}
          >
            {type && <S.StatusIcon name={iconNames[type]} />}
            <S.Content>
              {title && <S.Title hasMargin={!!children}>{title}</S.Title>}
              {children && <S.Text size={fontSize}>{children}</S.Text>}
              {closible && closeButtonText && (
                <Button onClick={handleClose}>{t(closeButtonText)}</Button>
              )}
            </S.Content>
            {closible && <S.CloseIcon onClick={handleClose} />}
          </S.Message>
        </S.MessageWrapper>
      </S.AnimatedDiv>
    );
  },
);

Message.displayName = 'Message';

export {Message};

export type {TMessageType};
