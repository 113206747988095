import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Modal, showModal} from '@edna/components';

import {PageDialog} from 'src/components';
import {useRedirect} from 'src/hooks';
import {InaccessibleImage} from 'src/images';
import {TListItemId} from 'src/models';
import PATHS from 'src/paths';

import {EFormStep} from '../Broadcasts/definitions';
import * as S from './style';

type TBroadcastsIds = Record<string, number | string>[];

type TProps = {
  broadcastsIds: TBroadcastsIds;
  closeModal: TAnyFunction;
};

const DeniedDeletionModal = React.memo<TProps>(({broadcastsIds, closeModal}) => {
  const {t} = useTranslation();
  const redirect = useRedirect();

  const handleBroadcastClick = (id: TListItemId) => {
    redirect(PATHS.BROADCASTS_EDIT, {
      id: String(id),
      action: 'edit',
      step: EFormStep.SETTINGS.toLocaleLowerCase(),
    });
    closeModal();
  };

  return (
    <Modal width="430px">
      <PageDialog
        layoutOrientation="VERTICAL"
        layoutSize="SMALL"
        title={t('MessageMatchers:deleteDenied.title')}
        content={
          <>
            {t('MessageMatchers:deleteDenied.body1')}
            {broadcastsIds.map((broadcast) => (
              <S.Link key={broadcast.id} onClick={() => handleBroadcastClick(broadcast.id!)}>
                <li>{broadcast.name}</li>
              </S.Link>
            ))}
            {t('MessageMatchers:deleteDenied.body2')}
          </>
        }
        imageComponent={InaccessibleImage}
        actions={
          <Button onClick={closeModal} appearance="primary" data-selector="Common:button.ok">
            {t('Common:button.ok')}
          </Button>
        }
      />
    </Modal>
  );
});

DeniedDeletionModal.displayName = 'DeniedDeletionModal';

export const showDeniedDeletionModal = (broadcastsIdsUsedIn: TBroadcastsIds) =>
  showModal((closeModal) => (
    <DeniedDeletionModal broadcastsIds={broadcastsIdsUsedIn} closeModal={closeModal} />
  ));
