import React from 'react';

import {useExactSelector} from '@edna/hooks';

import userModel, {EFeatureFlag} from './userModel';

const useFeatureFlag = (flag: EFeatureFlag) => {
  const featureFlags = useExactSelector(userModel.selectors.featureFlags);

  return Boolean(featureFlags[flag]);
};

const FeatureGuard = React.memo(
  ({flag, children}: {flag: EFeatureFlag; children: React.ReactNode | (() => React.ReactNode)}) => {
    const hasFlag = useFeatureFlag(flag);

    if (!hasFlag) {
      return null;
    }

    return typeof children === 'function' ? children() : children;
  },
);

export {useFeatureFlag, FeatureGuard};
