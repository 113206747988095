import {TItemId} from 'src/constants';

type TPushStyle = {
  id: TItemId;
  sound: string;
  lights: string;
  vibrate: string;
  styleName: string;
};

type TFormData = {
  id?: TPushStyle['id'];
  sound: TPushStyle['sound'];
  lights: TPushStyle['lights'];
  vibrate?: TPushStyle['vibrate'];
  styleName: TPushStyle['styleName'];
};

enum EUrgencyType {
  PASSIVE = 'passive',
  ACTIVE = 'active',
  TIME_SENSITIVE = 'time-sensitive',
  CRITICAL = 'critical',
}

export type {TPushStyle, TFormData};
export {EUrgencyType};
