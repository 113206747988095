import {EDeliveryStatus, EDirection, ETrafficType, IncomingMessageStatus} from './types';

export default {
  filter: {
    period: 'Time interval',
    phone: "Search by client's phone number",
    channelType: 'Channel',
    deliveryStatus: 'Message status',
    broadcasts: 'Broadcast name',
    cascades: 'Cascade name',
    trafficType: 'Traffic type',
    direction: 'Traffic direction',
    subjects: 'Sender channel',
  },
  messagesByCriteria: 'Messages by criteria – {{count, number}}',
  button: {
    downloadList: 'Download list',
  },
  list: {
    head: {
      sendDateTime: 'Date and time when\nthe message was sent',
      deliveryStatus: 'Message\nstatus',
      recipient: 'Recipient',
      sender: 'Sender',
      traffic: 'Traffic',
      inout: 'IN/OUT',
      content: 'Content',
    },
    loading: 'loading the list',
  },
  status: {
    [EDeliveryStatus.ENQUEUED]: 'Queued',
    [EDeliveryStatus.SENT]: 'Sent',
    [EDeliveryStatus.FAILED]: 'Failed to send',
    [EDeliveryStatus.ACCEPTED]: 'Failed to send',
    [EDeliveryStatus.INVALID]: 'Failed to send',
    [EDeliveryStatus.DELIVERED]: 'Delivered',
    [EDeliveryStatus.CANCELLED]: 'Canceled',
    [EDeliveryStatus.UNDELIVERED]: 'Not delivered',
    [EDeliveryStatus.READ]: 'Read',
    [EDeliveryStatus.EXPIRED]: 'Expired',
    [EDeliveryStatus.BLOCKED]: 'Blocked',
    [EDeliveryStatus.SUSPENDED]: 'Suspended',
    [IncomingMessageStatus.RECEIVED]: 'Received',
  },
  trafficType: {
    [ETrafficType.AD]: 'Advertising',
    [ETrafficType.MULTINAMING]: 'Advertising',
    [ETrafficType.SERVICE]: 'Service',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Chat',
  },
  trafficTypeShort: {
    [ETrafficType.AD]: 'Adv',
    [ETrafficType.SERVICE]: 'Serv',
    [ETrafficType.HSM]: 'HSM',
    [ETrafficType.CHAT]: 'Chat',
  },
  direction: {
    [EDirection.IN]: 'Incoming',
    [EDirection.OUT]: 'Outgoing',
  },
  directionShort: {
    [EDirection.IN]: 'IN',
    [EDirection.OUT]: 'OUT',
  },
  content: {
    headerText: 'Header',
    operator: 'Receiver operator',
    text: 'Text',
    footerText: 'Footer',
    language: 'Language',
    securityAdvice: 'Security disclaimer',
    codeExpirationMinutes: '<0>Code expiration interval (minutes):</0> {{minutes}}',
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    document: 'File',
    location: 'Location',
    name: 'Name',
    link: 'Link',
    links: 'Links',
    buttonText: 'Button text:',
    value: 'Value',
    coordinates: 'Coordinates',
    error: 'Error',
    broadcast: 'Broadcast',
    cascade: 'Cascade',
    segments: 'Segments',
    story: 'Story',
    menu: 'Menu',
    buttons: 'Buttons',
    contact: 'Contact',
    firstName: 'Name',
    lastName: 'Last Name',
    phone: 'Phone',
    subtitle: 'Subtitle',
    sender: 'Sender',
    recipientUID: 'Recipient UID',
    deviceAddress: 'Recipient deviceAddress',
    deviceModel: 'Device model',
    referral: 'Referral',
    locationButtonText: 'Location',
  },
  noDataWithFilters: {
    title: "Sorry, we didn't find anything",
    text: 'There are no messages for the selected filters',
    hint: 'Check for a mistake somewhere in the settings and verify you set the correct time interval',
    button: 'Try again',
  },
  noData: {
    title: 'Nothing here yet',
    text: 'After you start your first broadcast, the data on the sent messages will appear here. Would you like to create a broadcast?',
    button: 'To broadcasts',
  },
  referral: {
    body: 'Body',
    headline: 'Headline',
    sourceId: 'SourceID',
    sourceType: 'SourceType',
    sourceUrl: 'SourceURL',
  },
  orderProduct: {
    product: 'Message Business',
    catalogId: 'catalog_id:',
    productId: 'product_retailer_id:',
    order: 'Placing an Order',
  },
};
