import {EFormSteps, EStatus} from './definitions';

export default {
  steps: {
    [EFormSteps.SETTINGS]: 'Channel settings',
    [EFormSteps.COMPANY]: 'Company details',
    [EFormSteps.ATTACHMENTS]: 'Brand logo and warranty Letter',
  },
  editForm: {
    name: 'Internal channel name',
    nameHint: 'As it will appear in the edna Pulse channel list',
    tariffTemplateId: 'Pricing for the channel',
    nameEn: 'Sender name',
    nameEnHint:
      'Business Name (up to 100 characters) - in English, as it will appear on the Viber app',
    nameRu: 'Name in native language',
    nameRuHint: 'Required for Viber business account registration',
    accountType: 'Account type',
    accountTypeHint:
      "Whether a subscriber is able to respond to your messages. One way – subscribers can't reply to\nyour messages; two way – subscribers can reply to your messages",
    accountTypeLabelOneWay: 'one way',
    accountTypeLabelTwoWay: 'two way',
    launchDate: 'Business launch date',
    messageExample: 'Utility message sample',

    legalName: 'Legal name',
    beneficialOwner: 'Beneficial owner',
    beneficialOwnerHint:
      'Any individual who, directly or indirectly, exercises substantial control over the company, or who owns or controls at least 25 percent of the ownership interests of such company',
    category: 'Business category',
    country: ' Country in which the business is registered',
    broadcastCountries: 'Destination countries',
    broadcastCountriesHint:
      'All destination countries where Viber Business Messages will be sent, no more than 20 countries',
    companyDescription: 'Business description',
    address: 'Business address',
    phone: 'Business Phone Number',
    companyUrl: 'Business Phone Number',
    viberHint: 'It will appear on the Viber app',

    logo: 'Brand logo',
    logoHint: 'Logo used to represent your brand on Viber, PNG filetype:',
    guaranteeLetter: 'Warranty Letter',
    guaranteeLetterHint:
      'Attach the Warranty Letter in English, PDF filetype. <0>Warranty Letter example</0>',
    optional: '(optional)',
  },
  status: {
    [EStatus.DRAFT]: 'Draft',
    [EStatus.CREATE_SUBJECT]: 'Under consideration',
    [EStatus.CREATE_SUBJECT_ERROR]: 'Problem',
    [EStatus.IN_PROGRESS]: 'Under consideration',
    [EStatus.DISABLED]: 'Disabled',
    [EStatus.DONE]: 'Correct',
  },
  category: {
    Automotive: 'Automotive',
    'Beauty, Spa and Salon': 'Beauty, Spa and Salon',
    'Clothing and Apparel': 'Clothing and Apparel',
    Education: 'Education',
    Entertainment: 'Entertainment',
    'Event Planning and Service': 'Event Planning and Service',
    'Finance and Banking': 'Finance and Banking',
    'Food and Grocery': 'Food and Grocery',
    'Public Service': 'Public Service',
    'Hotel and Lodging': 'Hotel and Lodging',
    'Medical and Health': 'Medical and Health',
    'Non-profit': 'Non-profit',
    'Professional Services': 'Professional Services',
    'Shopping and Retail': 'Shopping and Retail',
    'Travel and Transportation': 'Travel and Transportation',
    Restaurant: 'Restaurant',
    Other: 'Other',
  },
  errors: {
    legalName: 'Name can contain only alphanumeric Latin characters, spaces and quotation marks',
    onlyLatin: 'Name can contain only Latin letters and spaces',
  },
};
