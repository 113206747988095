import {EClientStatus} from 'src/containers/UserCompanyProfile/definitions';

export default {
  notifications: {
    successBlock: 'Запрос на блокировку тенанты отправлен!',
    successUnblock: 'Тенанта успешно разблокирована',
  },
  options: {
    moveToDeactivated: 'Перевести в неактивный',
    moveToActivated: 'Перевести в активный',
  },
  confirmActivatingModal: {
    title: 'Восстановить активный статус клиенту?',
    subtitle: 'В активном статусе клиент сможет восстановить доступ к услугам. Восстановить?',
    notNeeded: 'Нет, не надо',
  },
  confirmDeactivatingModal: {
    title: 'Перевести клиента в неактивный статус?',
    subtitle:
      'В неактивном статусе клиент не сможет пользоваться услугами edna Pulse. Доступ к ЛК останется, но для возобновления активности потребуется подписание нового договора. Уверены, что хотите перевести?',
    no: 'Ой, нет',
  },
  deactivateClientModal: {
    title: 'Перевести клиента в неактивный статус?',
    message:
      'Перевести в неактивный статус можно на период до 120 дней. Максимальное количество таких периодов в год – 3.',
    periodLabel: 'Задайте период перевода в неактивный статус',
    move: 'Перевести',
  },
  clientStatus: 'Статус клиента:',
  clientStatuses: {
    [EClientStatus.ACTIVE]: 'Активный',
    [EClientStatus.BLOCKED]: 'Заблокирован',
    [EClientStatus.DEACTIVATED]: 'Неактивный',
  },
  changeStatus: 'Изменить статус',
  until: 'до {{date}}',
};
