import {TCurrency} from '@edna/utils/formatMoney';

import {EChannelType, EOperatorName, TChannelType, TSubject} from 'src/constants';
import {EWAConversation} from 'src/containers/SummaryReport/definitions';
import {TListItemId} from 'src/models/listApiModel';

export enum ETariffChangeRequestStatus {
  NEED_TO_AGREE = 'NEED_TO_AGREE',
  AGREED = 'AGREED',
  NOT_AGREED = 'NOT_AGREED',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export type TTariffHistory = TTariffTemplate & {
  id: TListItemId;
  channelType: TChannelType;
  subjectId: TSubject['id'];
  activeFrom: string;
  activeTo: string;
  individual: boolean;
};

export type TTariffChangeRequest = {
  id: TListItemId;
  tenantId: TListItemId;
  subjectId: TSubject['id'];
  oldtariffTemplateId: TListItemId;
  oldTenantTariffName: string;
  oldTenantTariffDescription: string;
  newTariffId: TListItemId;
  newTenantTariffName: string;
  newTenantTariffDescription: string;
  newTenantTariffStartedAt: string;
  status: ETariffChangeRequestStatus;
  statusAt: string;
  createdAt: string;
};

export type TState = {
  tariffsLoading: boolean;
  acceptTariffsLoading: boolean;
  tariffChangeRequests: TTariffChangeRequest[];
  whatsAppTariffTemplate: TWhatsAppTariffTemplateInfo | null;
  activeWhatsAppTariffs: TActiveTariff[];
  activeViberTariffs: TActiveTariff[];
  activeSmsTariffs: TActiveTariff[];
  whatsAppTariffTemplates: TTariffTemplate[];
  viberTariffTemplates: TTariffTemplate[];
  smsTariffTemplates: TTariffTemplate[];
};

//new tariff templates
export enum EOwnerType {
  MANAGER = 'MANAGER',
  LEAD = 'LEAD',
  TENANT = 'TENANT',
}

export type TOwnerType = keyof typeof EOwnerType;

export type TCountryDetail = {
  country: string;
  price: number;
  conversationType: EWAConversation;

  // viber, sms
  servicePrice?: number;
  marketingPrice?: number;
  currency?: TCurrency;
  // sms
  mobileOperator: EOperatorName;
  lowerLimit?: number;
  upperLimit?: number;
  multiFlag?: boolean;
  multiPrice?: number;
};

export type TViberTariffPayload = {
  type: EChannelType.VIBER;
  defaultServicePrice?: number;
  defaultMarketingPrice?: number;
  packagePrice?: number;
  currency: TCurrency;
  details: TCountryDetail[];
  licensePrice?: number;
  licenseCurrency?: TCurrency;
};

export type TWhatsappTariffPayload = {
  licensePrice?: number;
  type: EChannelType.WHATSAPP;
  initPayment: number;
  defaultMauPrice: number;
  subjectId?: number;
  mauCount?: number;
  defaultConversationBusinessPrice?: number;
  defaultUserConversationPrice?: number;
  currency: TCurrency;
  dialogCurrency: TCurrency;
  licenseCurrency: TCurrency;
  details: TCountryDetail[];
};

export type TSmsTariffPayload = {
  type: EChannelType.SMS;
  defaultServicePrice?: number;
  defaultMarketingPrice?: number;
  defaultMultiPrice?: number;
  currency: TCurrency;
  details: TCountryDetail[];
  recurrentOperatorBills: {
    mobileOperator: string;
    amount: number;
    currency: TCurrency;
  };
  licensePrice?: number;
  licenseCurrency?: TCurrency;
};

export type TTariffPayload = TViberTariffPayload | TWhatsappTariffPayload | TSmsTariffPayload;

export type TTariffTemplate<T = TTariffPayload> = {
  id: number;
  channelType?: TChannelType;
  createdAt?: string | null;
  updatedAt?: string | null;
  // custom tariff
  name?: string;
  payload: T;
  isIndividual?: boolean;
  individual?: boolean;
  partnerIndividual?: boolean;
  acceptedAt?: string | null;
  ownerType?: TOwnerType;
  tenantId?: number;
  leadId?: number;
  resellerId?: number;
  subjectId?: number;
  currency: TCurrency;
};

export type TTariffsTemplates = {
  whatsAppTariffTemplates: TTariffTemplate<TWhatsappTariffPayload>[];
  viberTariffTemplates: TTariffTemplate<TViberTariffPayload>[];
  smsTariffTemplates: TTariffTemplate<TSmsTariffPayload>[];
};

export type TTariffChannelType = Exclude<
  TChannelType,
  'PUSH' | 'INSTAGRAM' | 'EMBEDDED_WHATSAPP' | 'VK_NOTIFY' | 'OK_NOTIFY'
>;

export const TARIFF_CHANNEL_TYPES: TTariffChannelType[] = [
  EChannelType.SMS,
  EChannelType.WHATSAPP,
  EChannelType.VIBER,
];

export const CHANNEL_TEMPLATE_KEY: Record<TTariffChannelType, keyof TTariffsTemplates> = {
  [EChannelType.WHATSAPP]: 'whatsAppTariffTemplates',
  [EChannelType.VIBER]: 'viberTariffTemplates',
  [EChannelType.SMS]: 'smsTariffTemplates',
};

export const CHANNEL_ACTIVE_TARIFF_KEY: Record<TTariffChannelType, string> = {
  [EChannelType.WHATSAPP]: 'activeWhatsAppTariffs',
  [EChannelType.VIBER]: 'activeViberTariffs',
  [EChannelType.SMS]: 'activeSmsTariffs',
};

type TConversationPrice = {
  conversationType: EWAConversation;
  price: number;
};

export type TConversationCountry = {
  country: string;
  prices: TConversationPrice[];
};

export type TWhatsAppTariffTemplateInfo = {
  whatsappTariffTemplate: TTariffTemplate;
  conversationCountryScaleList: TConversationCountry[];
};

//здесь именно whatsapp - app с маленькой буквы
export type TTariffsTemplatesIds = {
  whatsappTariffTemplatesIds?: number[];
  viberTariffTemplatesIds?: number[];
  smsTariffTemplatesIds?: number[];
};

export type TActiveTariff<T = TTariffPayload> = {
  id: number;
  tenantId: number;
  channelType?: TChannelType;
  subjectId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  activeFrom?: string;
  activeTo?: string;
  isIndividual: boolean;
  currency: TCurrency;
  payload: T;
  name: string;
};

export type TActiveTariffs = {
  whatsappTariffs: TActiveTariff<TWhatsappTariffPayload>[];
  viberTariffs: TActiveTariff<TViberTariffPayload>[];
  smsTariffs: TActiveTariff<TSmsTariffPayload>[];
};

export type TTariffOption = {
  label: string;
  value: number;
};
