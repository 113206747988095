import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@edna/components';
import {H1} from '@edna/components/primitives';

import {SecondaryText} from 'src/components/primitives';
import {useEdnaLinks} from 'src/hooks';
import {AndroidIcon, IosIcon, WhatsAppCircleIcon} from 'src/icons';

import * as S from './style';

const Documentation = React.memo(() => {
  const {t} = useTranslation();
  const links = useEdnaLinks();

  return (
    <>
      <S.Document>
        <S.DocumentImage>
          <S.ChannelIcon as={WhatsAppCircleIcon} />
        </S.DocumentImage>
        <S.DocumentInfo>
          <H1>{t('Integration:documentation.whatsapp.title')}</H1>
          <SecondaryText bottomIndent="5" topIndent="2">
            {t('Integration:documentation.whatsapp.description')}
          </SecondaryText>
          <Button
            as="a"
            href={links.INTEGRATION_WA}
            target="_blank"
            rel="noopener noreferrer"
            appearance="primary"
            data-selector={links.INTEGRATION_WA}
          >
            {t('Integration:documentation.go')}
          </Button>
        </S.DocumentInfo>
      </S.Document>
      <S.Document>
        <S.DocumentImage>
          <S.ChannelIcon as={IosIcon} />
          <S.ChannelIcon as={AndroidIcon} />
        </S.DocumentImage>
        <S.DocumentInfo>
          <H1>{t('Integration:documentation.push.title')}</H1>
          <SecondaryText bottomIndent="5" topIndent="2">
            {t('Integration:documentation.push.description')}
          </SecondaryText>
          <Button
            as="a"
            href={links.INTEGRATION_PUSH}
            target="_blank"
            rel="noopener noreferrer"
            appearance="primary"
            data-selector={links.INTEGRATION_PUSH}
          >
            {t('Integration:documentation.go')}
          </Button>
        </S.DocumentInfo>
      </S.Document>
    </>
  );
});

Documentation.displayName = 'Documentation';

export default Documentation;
