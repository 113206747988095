import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon} from '@edna/components/primitives';
import CheckIcon from '@edna/icons/checked.svg';

import {TRule, getPasswordCheckResult} from 'src/utils/password';

import * as S from './style';

type TProps = {
  value?: string | null;
};

/* eslint-disable no-useless-escape */
export const passwordRules: TRule[] = [
  {
    regExp: /^[\w!@#$%^&*)(+=._-]+$/,
    include: true,
    id: 'latin',
    loc: 'latin',
  },
  {
    regExp: /[a-z]/,
    include: true,
    id: 'lowercase',
    loc: 'lowercase',
  },
  {
    regExp: /[A-Z]/,
    include: true,
    id: 'uppercase',
    loc: 'uppercase',
  },
  {
    regExp: /[0-9]/,
    include: true,
    id: 'number',
    loc: 'number',
  },
  {
    regExp: /[!@#$%^&*)(+=._-]/,
    include: true,
    id: 'specialCharacter',
    loc: 'specialCharacter',
  },
  {
    regExp: /^.{8,32}$/,
    include: true,
    id: 'length',
    loc: 'length',
  },
];
/* eslint-enable no-useless-escape */

const PasswordRules = React.memo<TProps>(({value}) => {
  const {t} = useTranslation();

  const checkResult = getPasswordCheckResult(value || '', passwordRules);

  return (
    <S.Rules>
      {passwordRules.map((rule) => {
        const isValid = checkResult[rule.id];

        return (
          <S.RuleItem isValid={isValid} key={rule.id}>
            <S.RuleCheck isValid={isValid}>
              {isValid && <Icon as={CheckIcon} size="8px" />}
            </S.RuleCheck>
            <span>{t(`PasswordRules:validator:${rule.loc}`)}</span>
          </S.RuleItem>
        );
      })}
    </S.Rules>
  );
});

PasswordRules.displayName = 'PasswordRules';

export default PasswordRules;
