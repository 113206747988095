import React from 'react';
import {useTranslation} from 'react-i18next';

import {EChannelType, EContentType} from 'src/constants';
import authTemplateLocalization, {
  EMessageAlign,
} from 'src/containers/MessageMatchers/WhatsAppForm/AuthTemplateLocalization';
import {
  ETemplateButtonOtpType,
  ETemplateButtonTypes,
  TItemButton,
  TType,
  TWhatsAppItemContent,
} from 'src/containers/MessageMatchers/types';
import {deleteVariable} from 'src/containers/MessageMatchers/utils';
import {useGetAllParametersQuery} from 'src/containers/Parameters/api';
import {CopyIcon, DocumentIcon, ImageIcon, OpenIcon, PhoneIcon, VideoIcon} from 'src/icons';

import Text from '../Text';
import * as SS from '../style';
import {FOOTER_TIME, prepareText} from '../utils';
import CustomText from './CustomText';
import * as S from './style';

type TUrlButtonProps = {
  button: TItemButton;
  type?: TType | null;
};

const UrlButton = React.memo<TUrlButtonProps>(({button, type}) => {
  const {data: parameters = []} = useGetAllParametersQuery();

  const title =
    deleteVariable(button.url) +
    prepareText({
      type,
      channelType: EChannelType.WHATSAPP,
      parameters,
      text: button.urlPostfix ?? '',
    });

  return (
    <S.ContactButton title={title}>
      <S.ContactIcon as={OpenIcon} />
      {button.text}
    </S.ContactButton>
  );
});

UrlButton.displayName = 'UrlButton';

type TButtonProps = {
  buttons: TItemButton[];
  type?: TType | null;
};

const ContactsButtons = React.memo<TButtonProps>(({buttons, type}) => (
  <div>
    {buttons?.map((button, index) => {
      if (button.buttonType === ETemplateButtonTypes.PHONE) {
        return (
          <S.ContactButton key={index}>
            <S.ContactIcon as={PhoneIcon} />
            {button.text}
          </S.ContactButton>
        );
      }

      return <UrlButton key={index} button={button} type={type} />;
    })}
  </div>
));

ContactsButtons.displayName = 'ContactsButtons';

const ChatButtons = React.memo<TButtonProps>(({buttons}) => {
  if (buttons[0].buttonType === ETemplateButtonTypes.OTP) {
    return (
      <S.ChatButton>
        {buttons[0].otpType === ETemplateButtonOtpType.COPY && <S.ContactIcon as={CopyIcon} />}
        {buttons[0].text}
      </S.ChatButton>
    );
  }

  if (buttons.some(({text}) => !!text)) {
    return (
      <>
        {buttons.map(({text, payload}, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <S.ChatButton key={`${text}${payload}${index}`}>{text}</S.ChatButton>
        ))}
      </>
    );
  }

  return null;
});

ChatButtons.displayName = 'ChatButtons';

type THeaderProps = {
  content: TWhatsAppItemContent;
  attachmentUrl?: string;
};

const Header = React.memo<THeaderProps>(({content = {}, attachmentUrl}) => {
  if (content.header?.headerType === EContentType.TEXT && content.header?.text) {
    return <S.TextBodyHeader>{content.header.text}</S.TextBodyHeader>;
  }

  if (content.header?.headerType === EContentType.IMAGE) {
    return (
      <S.HeaderBackground isFullHeight={!!attachmentUrl}>
        {!!attachmentUrl ? (
          <SS.Image type={EChannelType.WHATSAPP} src={attachmentUrl} />
        ) : (
          <SS.Icon as={ImageIcon} />
        )}
      </S.HeaderBackground>
    );
  }

  if (content.header?.headerType === EContentType.VIDEO) {
    return (
      <S.HeaderBackground>
        <SS.Icon as={VideoIcon} />
      </S.HeaderBackground>
    );
  }

  if (content.header?.headerType === EContentType.DOCUMENT) {
    return (
      <S.HeaderBackground>
        <SS.Icon as={DocumentIcon} />
      </S.HeaderBackground>
    );
  }

  return null;
});

Header.displayName = 'Header';

type TProps = {
  content: TWhatsAppItemContent;
  language?: string;
  attachmentUrl?: string;
  type?: TType | null;
  isCustomText?: boolean;
};

const WhatsAppContent = React.memo<TProps>(
  ({content = {}, language, attachmentUrl, type, isCustomText = false}) => {
    const {t, i18n} = useTranslation();
    const localizationIndex = authTemplateLocalization.findIndex(
      (item) => item.code.toLowerCase() === (language?.toLowerCase() || i18n.language),
    );
    const align = authTemplateLocalization[localizationIndex]?.align;
    const buttonType = content?.buttons?.length && content?.buttons[0]?.buttonType;

    return (
      <>
        <S.Message isRtlDirection={align === EMessageAlign.RTL}>
          <Header content={content} attachmentUrl={attachmentUrl} />
          <SS.MessageContent display="inline-block">
            {isCustomText && <CustomText />}
            {!isCustomText && (
              <Text text={content.text} type={type} channelType={EChannelType.WHATSAPP} />
            )}
            {!!content.codeExpirationMinutes && localizationIndex >= 0 && (
              <S.Footer>
                {t(`WhatsAppAuthTemplate:${localizationIndex}.passwordExpirationText`, {
                  count: Number(content.codeExpirationMinutes ?? 0),
                })}
              </S.Footer>
            )}
            {content.footer?.text && <S.Footer>{content.footer.text}</S.Footer>}
            <SS.Time isRtlDirection={align === EMessageAlign.RTL}>
              <span>{FOOTER_TIME}</span>
            </SS.Time>
          </SS.MessageContent>
          {content.buttons &&
            (buttonType === ETemplateButtonTypes.PHONE ||
              buttonType === ETemplateButtonTypes.URL) && (
              <ContactsButtons buttons={content.buttons} type={type} />
            )}
        </S.Message>
        {content.buttons &&
          (buttonType === ETemplateButtonTypes.CHAT || buttonType === ETemplateButtonTypes.OTP) && (
            <ChatButtons buttons={content.buttons} />
          )}
      </>
    );
  },
);

WhatsAppContent.displayName = 'WhatsAppContent';

export default WhatsAppContent;
