export default {
  label: 'Продолжить с Facebook',
  permissionsError: {
    title: 'Ошибка при подключении аккаунта',
    message:
      'Чтобы подключить аккаунт к каналу {{channel}} в edna нужно везде отметить “Да” в форме Facebook на этапе “Что разрешено делать приложению”. <b>Необходимо изменить настройки</b>',
    button: 'Попробовать заново',
  },
  connectionError: {
    title: 'Ошибка при подключении к Facebook',
    button: 'Попробовать заново',
  },
};
