import {TActionCreator} from '@edna/models/apiModel';

import {TListState} from 'src/models';

export enum EParameterType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
}

export enum ESystemParameter {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  GENDER = 'GENDER',
  BIRTHDAY = 'BIRTHDAY',
  PHONE = 'PHONE',
}

export type TValueType = keyof typeof EParameterType;

type TNumberValue = {
  valueType: EParameterType.NUMBER;
  value?: number;
};

type TStringValue = {
  valueType: EParameterType.STRING | EParameterType.DATE;
  value?: string;
};

type TBooleanValue = {
  valueType: EParameterType.BOOLEAN;
  value?: boolean;
};

export type TValue = TNumberValue | TStringValue | TBooleanValue;

export type TItem = TValue & {
  id: number;
  subscriberId: number;
  name: string;
  code: string | null;
};

export type TStatic = {
  form: {
    assign: string;
  };
};

export type TState = {
  assignInitialValues: Partial<TAssignFormData>;
  itemsMap: Record<TItem['id'], TItem>;
  parameterValues: TItem[];
};

export type TActions = {
  // Reducers
  receiveAllItems: TActionCreator<TItem[]>;
  receiveItems: TActionCreator<
    Pick<TListState<TItem>, 'last' | 'totalElements' | 'first'> & {content: TItem[]}
  >;
  insertItem: TActionCreator<TItem>;
  refreshItem: TActionCreator<TItem>;
  setAssignItemForEdit: TActionCreator<TItem | null>;
};

export type TAssignFormData = TItem;
