import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {EStorageKeys} from 'src/constants';
import {listenerMiddleware} from 'src/models/api';

const initialState = () => {
  const rememberedFilters = sessionStorage.getItem(EStorageKeys.CHANNELS_FILTERS);

  return {
    filters: rememberedFilters ? JSON.parse(rememberedFilters) : {},
  };
};

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TAnyObject>) {
      state.filters = action.payload;
    },
  },
});

export const {setFilters: setFiltersAction} = channelsSlice.actions;
export const selectFilters = (state: TRootState) => state.channels.filters;

listenerMiddleware.startListening({
  actionCreator: setFiltersAction,
  effect: (action) => {
    sessionStorage.setItem(EStorageKeys.CHANNELS_FILTERS, JSON.stringify(action.payload));
  },
});

export default channelsSlice.reducer;
