import {EAccountType, EFormSteps, EStatus} from './definitions';

export default {
  steps: {
    [EFormSteps.SETTINGS]: 'Настройки канала',
    [EFormSteps.COMPANY]: 'Данные компании',
    [EFormSteps.ATTACHMENTS]: 'Логотипы и гарантийное письмо',
  },
  accountType: {
    [EAccountType.ONE_WAY]: 'one way',
    [EAccountType.TWO_WAY]: 'two way',
  },
  editForm: {
    name: 'Внутреннее название канала',
    nameHint: 'Это название будет отображаться в списке каналов в edna Pulse',
    tariffTemplateId: 'Тариф для канала',
    nameEn: 'Имя отправителя',
    nameEnHint:
      'Название канала латиницей, которое увидят в Viber ваши клиенты. Не более 100 символов',
    nameRu: 'Название на родном языке',
    nameRuHint: 'Требуется для регистрации бизнес-аккаунта в Viber',
    accountType: 'Тип аккаунта',
    accountTypeHint:
      'Здесь настраивается возможность ответа абонента на ваше сообщение. One way – абоненты не могут отвечать на\nваши сообщения; two way – абоненты могут отвечать на ваши сообщения',
    launchDate: 'Планируемая дата запуска',
    messageExample: 'Пример сервисного сообщения',

    legalName: 'Юридическое название компании',
    beneficialOwner: 'Владелец компании',
    beneficialOwnerHint: 'Перечислите владельцев компании с долей владения 25% или более',
    category: 'Вид деятельности компании',
    country: 'Страна регистрации компании',
    broadcastCountries: 'Страны получения рассылок',
    broadcastCountriesHint:
      'Страны, в которых ваши клиенты смогут получать сообщения. Можно выбрать до 20 стран',
    companyDescription: 'Описание компании',
    address: 'Адрес компании',
    phone: 'Телефон компании',
    companyUrl: 'Сайт компании',
    viberHint: 'Отображается в профиле компании в Viber',

    logo: 'Логотипы компании',
    logoHint: 'Для анкеты необходимо загрузить логотипы указанных размеров в формате PNG:',
    guaranteeLetter: 'Гарантийное письмо',
    guaranteeLetterHint:
      'Прикрепите скан гарантийного письма на английском языке в формате PDF. <0>Образец письма</0>',
    optional: '(необязательно)',
  },
  status: {
    [EStatus.DRAFT]: 'Черновик',
    [EStatus.CREATE_SUBJECT]: 'На регистрации',
    [EStatus.CREATE_SUBJECT_ERROR]: 'Проблема',
    [EStatus.IN_PROGRESS]: 'На регистрации',
    [EStatus.DISABLED]: 'Отключен',
    [EStatus.DONE]: 'Работает исправно',
  },
  category: {
    Automotive: 'Автомобильный бизнес',
    'Beauty, Spa and Salon': 'Салон красоты, SPA, парикмахерская',
    'Clothing and Apparel': 'Одежда',
    Education: 'Образование',
    Entertainment: 'Развлекательная программа',
    'Event Planning and Service': 'Планирование и обслуживание мероприятий',
    'Finance and Banking': 'Финансы и банковское дело',
    'Food and Grocery': 'Еда и Бакалея',
    'Public Service': 'Государственная служба',
    'Hotel and Lodging': 'Отель и жилье',
    'Medical and Health': 'Медицина и здоровье',
    'Non-profit': 'Некоммерческая организация',
    'Professional Services': 'Профессиональные услуги',
    'Shopping and Retail': 'Покупки и розничная торговля',
    'Travel and Transportation': 'Путешествия и транспорт',
    Restaurant: 'Ресторанный бизнес',
    Other: 'Иное',
  },
  errors: {
    legalName: 'Название может содержать только латинские буквы, цифры, пробелы и кавычки',
    onlyLatin: 'Имя может содержать только латинские буквы и пробелы',
  },
};
