import styled, {css} from 'styled-components';

import {HeadButton as BaseHeadButton} from '@edna/components/EmojiPicker/style';
import {mixins} from '@edna/components/styles';
import {hexToRgba} from '@edna/utils/hexToRgba';

import {SmileIcon as BaseSmileIcon} from 'src/icons';

export * from '@edna/components/EmojiPicker/style';

const EMOJI_WIDTH = '30px';

export const HeadButton = styled(BaseHeadButton)`
  ${mixins.wh(EMOJI_WIDTH)}
  ${mixins.flexAlign('center')}

  ${({theme}: TTheme) => css`
    padding: ${theme.spacing.s1};
    border-radius: ${theme.borderRadius.br2};
    box-shadow: 0 1px 4px -1px ${hexToRgba(theme.branding.content.primary, '20%')};
  `}
`;

export const SmileIcon = styled(BaseSmileIcon)`
  ${mixins.wh('20px')}
`;
