import {EWidgetType} from './definitions';

export default {
  statistics: {
    [EWidgetType.Licenses]: {
      title: 'Licenses',
      empty: 'This section lists license statuses',
      toPage: 'All licenses',
    },
    [EWidgetType.Payments]: {
      empty: 'This section specifies the estimated amount of the next debit',
      toPage: 'See more',
    },
    [EWidgetType.Templates]: {
      title: 'Template changes',
      empty: 'This section displays information about recent templates changes',
      toPage: 'All templates',
    },
    [EWidgetType.Broadcasts]: {
      title: 'Recent broadcasts',
      empty: 'This section displays information about recent broadcasts',
      toPage: 'All broadcasts',
    },
    [EWidgetType.Channels]: {
      title: 'Channel changes',
      empty: 'This section displays information about recent channel changes',
      toPage: 'All channels',
    },
  },
};
