export default {
  backAuthorization: 'Вернуться к авторизации',
  requestNewCodeButton: 'Запросить новый код',
  changePassword: {
    title: 'Смена пароля',
    changePasswordButton: 'Сменить пароль',
    currentPassword: 'Текущий пароль',
    newPassword: 'Новый пароль',
    repeatPassword: 'Повторите новый пароль',
    passwordSaved: 'Пароль сохранен!',
    passwordSavedHint: 'Вы под надежной защитой!',
    signIn: 'Войти в аккаунт',
  },
  resetPassword: {
    title: 'Сброс пароля',
    email: 'E-mail',
    getCodeButton: 'Получить код',
  },
  restorePassword: {
    title: 'SMS Подтверждение',
    verificationCodeSubmitted:
      'Код подтверждения отправлен на ваш номер, указанный при регистрации',
    expiredConfirm:
      'К сожалению, срок действия<br/> кода подтверждения истек. Запросите новый код подтверждения',
    requestCodeTimeout: 'Запросить код повторно можно через',
  },
  signIn: {
    forgotPassword: 'Забыли пароль?',
    password: 'Пароль',
    email: 'E-mail',
    captchaLabel: 'Введите символы с картинки',
    signInButton: 'Войти',
    signUpButton: 'Нет аккаунта? <0>Зарегистрироваться</0>',
    contactUs:
      'Нет учетной записи?<br/> Свяжитесь с нами по телефону:<br/><0>+7 (495) 609-60-80</0> доб. <0>177</0>',
  },
  signUpBlocked: {
    confirmationSendBlocked: {
      message1: 'Пожалуйста, свяжитесь с поддержкой:',
      message2: 'Все попытки регистрации исчерпаны',
    },
    confirmationSendAny: {
      message1: 'Подтверждение телефона',
      message2: 'Все попытки ввода кода исчерпаны.<br/>Через {{countdown}} можно попытаться снова.',
    },
  },
  signUp: {
    country: 'Страна',
    phone: 'Телефон',
    phoneHint: '(вместе с кодом страны)',
    authorizedPersonName: 'Фамилия и имя',
    companyName: 'Название компании',
    email: 'E-mail',
    password: 'Пароль',
    confirmTermsOfUse:
      'Нажимая кнопку, вы согласны с условиями<br/> <0>Общих положений о предоставлении прав на использование программного обеспечения edna Pulse</0>',
    haveAccount: 'Уже есть аккаунт? ',
    registerButton: 'Зарегистрироваться',
    signInLink: 'Войти',
  },
  signUpConfirm: {
    title: 'Подтверждение телефона',
    verificationCodeSubmitted: 'Код подтверждения отправлен на ваш номер',
    expiredConfirm:
      'К сожалению, срок действия<br/> кода подтверждения истек. Запросите новый код подтверждения',
    requestCodeTimeout: 'Запросить код повторно можно через',
    haveAccount: 'Уже есть аккаунт? ',
    signInLink: 'Войти',
  },
  emailConfirm: {
    title: 'Email-подтверждение',
    verificationCodeSubmitted: 'Код подтверждения отправлен на ваш корпоративный email',
    requestCodeTimeout: 'Запросить код повторно можно через',
    requestAnotherCode: 'Запросить другой код',
    sendNewCode: 'Прислать новый код',
    expiredConfirm: 'Срок действия кода подтверждения истек. Прислать вам новый код?',
  },
};
