import React from 'react';
import {useTranslation} from 'react-i18next';

import {useEvent} from '@edna/hooks';
import ArrowUpward from '@edna/icons/arrowUpward.svg';

import * as S from './style';

type TProps = {
  reserve?: number;
};

const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const ScrollUpButton = React.memo<TProps>(({reserve = 200}) => {
  const {t} = useTranslation();
  const [isVisible, setVisibility] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    const visible = document.documentElement.scrollTop > reserve;

    setVisibility(visible);
  }, [reserve]);

  useEvent('scroll', handleScroll);

  return (
    <S.ScrollUpButton title={t('ScrollUpButton:title')} hidden={!isVisible} onClick={scrollToTop}>
      <ArrowUpward />
    </S.ScrollUpButton>
  );
});

ScrollUpButton.displayName = 'ScrollUpButton';

export default ScrollUpButton;
