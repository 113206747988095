import {useGetAllNextPaymentQuery} from 'src/containers/Licenses/api';
import {ELicenseStatus} from 'src/containers/Licenses/definitions';

export const useAutopaymentRecommendedThreshold = () => {
  const {data} = useGetAllNextPaymentQuery({
    statuses: [ELicenseStatus.NEW, ELicenseStatus.ACTIVE, ELicenseStatus.EXPIRED],
    additionalMonths: 2,
  });

  return Math.ceil(data?.totalAmount ?? 0);
};
