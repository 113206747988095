import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';

export const Subject = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
  min-height: 30px;
  ${({theme}) => css`
    gap: ${theme.spacing.s1};
    padding: 0 ${theme.spacing.s2};
    border: ${theme.dimension.borderWidth}px solid ${theme.branding.content.tertiary};
    border-radius: ${theme.borderRadius.br8};
  `}
`;

export const SubjectName = styled.div`
  ${mixins.truncate}
  max-width: 200px;
`;
