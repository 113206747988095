import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';

import noop from 'lodash/noop';

import {EImagePickerError, showCropControlModal, showNewNotification} from '@edna/components';
import {newFormField} from '@edna/components/FormField/hoc';
import {Icon} from '@edna/components/primitives';
import EditIcon from '@edna/icons/edit.svg';
import NewImageIcon from '@edna/icons/newImage.svg';

import * as S from './style';
import {getUploadedFileDimensions} from './utils';

type TValue = File | null;

type THandler = (value: TValue) => void;

type TCropperOptions = {
  isExact?: boolean;
  minHeight?: number;
  minWidth?: number;
  aspect?: number;
};

export type TProps = {
  name?: string;
  isInvalid?: boolean;
  className?: string;
  placeHolder?: string;
  value?: TFile | null;
  onUpload: (value: File[]) => void;
  onErrorUpload?: (value: File) => void;
  hasCropper?: boolean;
  cropperOptions?: TCropperOptions;
  isFull?: boolean;
  width?: number;
  minHeight?: number;
  disabled?: boolean;
  readOnly?: boolean;
  onFocus?: THandler;
  onBlur?: THandler;
  accept?: string;
  isFileValid?: (value: File) => boolean | Promise<boolean>;
  multiple?: boolean;
  isCastJPEG?: boolean;
};

const isFileDimensionsValid = async (file: File, cropperOptions: TCropperOptions, t: TFunction) => {
  if (file instanceof File) {
    const dimensions = await getUploadedFileDimensions(file);

    if (!dimensions.height || !dimensions.width) {
      showNewNotification({
        type: 'error',
        message: t('ImagePicker:imageError', {
          error: t(
            `ImagePicker:errors.${
              cropperOptions.minWidth ? EImagePickerError.DIMENSIONS : EImagePickerError.FORMAT
            }`,
            {
              width: cropperOptions.minWidth,
              height: cropperOptions.minHeight,
            },
          ),
        }),
      });

      return false;
    }

    const minHeight = cropperOptions.minHeight || 0;
    const minWidth = cropperOptions.minWidth || 0;

    if (dimensions.height < minHeight || dimensions.width < minWidth) {
      showNewNotification({
        type: 'error',
        message: t('ImagePicker:imageError', {
          error: t(`ImagePicker:errors.${EImagePickerError.DIMENSIONS}`, {
            width: cropperOptions.minWidth,
            height: cropperOptions.minHeight,
          }),
        }),
      });

      return false;
    }
  }

  return true;
};

const DEFAULT_WIDTH = 152;

const ImagePicker = React.memo<TProps>(
  ({
    onUpload,
    value,
    placeHolder,
    isInvalid,
    name,
    className,
    isFileValid = () => true,
    disabled = false,
    readOnly = false,
    hasCropper = false,
    isFull = false,
    width = DEFAULT_WIDTH,
    minHeight = 60,
    cropperOptions = {},
    accept = '*',
    multiple,
    onFocus = noop,
    onBlur = noop,
    onErrorUpload = noop,
    isCastJPEG,
  }) => {
    // сохраняем состояние,
    // чтобы отображать EditButton для редактирования в кроппере
    const [file, setFile] = React.useState<TValue>(null);
    const {t} = useTranslation();

    const handleCropperSubmit = React.useCallback(
      async (payload?: File | null) => {
        if (!payload) {
          return;
        }

        if (isCastJPEG) {
          const outputMimeType = 'image/jpeg';
          const outputFileName = payload.name.replace(/\.\w+$/, '.jpeg');

          const fileBits = await payload.arrayBuffer();
          const targetFile = new File([fileBits], outputFileName, {type: outputMimeType});

          return onUpload([targetFile]);
        }

        onUpload([payload]);
      },
      [onUpload],
    );

    const showCropperModal = React.useCallback(
      (fileValue: File) =>
        showCropControlModal({
          file: fileValue,
          onSubmit: handleCropperSubmit,
          isExactParams: cropperOptions.isExact,
          minHeight: cropperOptions.minHeight,
          minWidth: cropperOptions.minWidth,
          aspectRatio: cropperOptions.aspect,
          outputMimeType: isCastJPEG ? 'image/jpeg' : undefined,
        }),
      [cropperOptions, handleCropperSubmit],
    );

    const showCropper = React.useCallback(() => {
      if (hasCropper && !!file) {
        showCropperModal(file);
      }
    }, [hasCropper, file, showCropperModal]);

    const handleChange = React.useCallback(
      async (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement;

        if (!target.files?.[0]) {
          return;
        }

        const files = [...target.files];

        const resolvedPromises = await Promise.allSettled(
          files.map(async (targetFile) => {
            if (!targetFile) {
              return Promise.reject();
            }

            if (!(await isFileValid(targetFile))) {
              onErrorUpload(targetFile);

              return Promise.reject();
            }

            if (!(await isFileDimensionsValid(targetFile, cropperOptions, t))) {
              return Promise.reject();
            }

            if (hasCropper && !multiple) {
              setFile(targetFile);
              showCropperModal(targetFile);

              return Promise.reject();
            }

            return targetFile;
          }),
        );

        const filesToUpload = resolvedPromises
          .filter((promise) => promise.status === 'fulfilled')
          .map((promise) => (promise as PromiseFulfilledResult<File>).value);

        if (filesToUpload.length) {
          onUpload(filesToUpload);
        }

        // сбрасываем цель, чтобы была возможность повторно загрузить изображение
        target.value = '';
      },
      [
        isFileValid,
        cropperOptions,
        hasCropper,
        showCropperModal,
        onUpload,
        onErrorUpload,
        multiple,
        t,
      ],
    );

    const handleBlur = React.useCallback(() => onBlur(file), [file, onBlur]);
    const handleFocus = React.useCallback(() => onFocus(file), [file, onFocus]);

    return (
      <S.ImagePicker className={className} htmlFor={name} disabled={disabled} readOnly={readOnly}>
        <S.Content
          isFull={isFull}
          disabled={disabled}
          isInvalid={isInvalid}
          readOnly={readOnly}
          width={width}
          minHeight={minHeight}
        >
          <input
            hidden
            id={name}
            type="file"
            accept={accept}
            multiple={multiple}
            disabled={disabled || readOnly}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          {!!value?.fileUrl && (
            <>
              {hasCropper && !!file && (
                <S.EditButton
                  onClick={showCropper}
                  data-selector="editButton"
                  disabled={disabled || readOnly}
                >
                  <Icon as={EditIcon} size="20px" />
                </S.EditButton>
              )}
              <S.Img src={value?.fileUrl} alt="Image" />
            </>
          )}
          {!value?.fileUrl && (
            <>
              <S.AddIcon as={NewImageIcon} disabled={disabled || readOnly} />
              <S.Accept size={width < DEFAULT_WIDTH ? 'S' : 'M'}>{accept}</S.Accept>
            </>
          )}
        </S.Content>
        {placeHolder && <S.PlaceHolder>{placeHolder}</S.PlaceHolder>}
      </S.ImagePicker>
    );
  },
);

ImagePicker.displayName = 'ImagePicker';

export default ImagePicker;

export const ImagePickerOldField = newFormField<TProps>(ImagePicker);
