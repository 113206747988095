import {i18n as TI18n} from 'i18next';

import {getMatchedPath} from 'src/paths';

import {getTitleForPath} from './getTitleForPath';

export const getTitle = (i18n: TI18n, pathname: string, variables?: Record<string, string>) => {
  const match = getMatchedPath(pathname);
  const key = match ? `PageTitle:${getTitleForPath(match)}` : null;

  if (key && i18n.exists(key)) {
    return i18n.t(key, variables);
  }

  return '404';
};

export const setTitle = (i18n: TI18n, pathname: string, loading?: boolean) => {
  if (loading) {
    window.document.title = i18n.t('PageTitle:loading');

    return;
  }

  if (pathname) {
    window.document.title = getTitle(i18n, pathname);
  }
};
