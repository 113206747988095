export default {
  title: 'Company clients',
  searchPlaceholder: 'Quick search by company, TIN or login',
  th: {
    createdAt: 'Created at',
    company: 'Company',
    taxNumber: 'TIN',
    login: 'Login',
    status: 'Status',
  },
  queryError: {
    title: 'Oops :(',
    subtitle: "We couldn't find your client because there are special characters in your search",
    hint: 'Try searching without them',
  },
  filters: {
    title: 'Filters',
    statusLabel: 'Registration status',
    createdAtLabel: 'Client registration date',
    managerLabel: 'Responsible manager',
    managerPlaceholder: 'Select a manager',
    resellerLabel: 'Jurisdiction',
    resellerPlaceholder: 'Select a jurisdiction',
    supportClients: 'Clients',
  },
};
