import {EAutopaymentError} from 'src/containers/Autopayment';
import {EApproveStatus} from 'src/containers/MessageMatchers/constants';
import {
  EAutopaymentNotificationStatus,
  ENotificationLevel,
  EWebNotificationType,
} from 'src/containers/Notifications';

import {ECategory} from './definitions';

export default {
  today: 'Today',
  yesterday: 'Yesterday',
  previously: 'Previously',
  client_one: '<b>{{count}}</b> client',
  client_other: '<b>{{count}}</b> clients',
  filters: {
    labels: 'Status',
    categories: 'Notification type',
    broadcasts: 'Broadcast name',
  },
  level: {
    [ENotificationLevel.SUCCESS]: 'Success',
    [ENotificationLevel.WARNING]: 'Attention required',
    [ENotificationLevel.ERROR]: 'Error',
    [ENotificationLevel.LOADING]: 'Loading',
  },
  category: {
    [ECategory.SUBSCRIBERS_UPLOAD]: 'Clients upload',
    [ECategory.BROADCAST_LIFECYCLE]: 'Broadcast status',
    [ECategory.LICENSE]: 'License',
    [ECategory.CALLBACK_LIFECYCLE]: 'Callback',
    [ECategory.APIKEY_USER_LIFECYCLE]: 'API key',
    [ECategory.TENANT_DETAILS_UPDATES]: 'Details',
    [ECategory.TARIFF_ACCEPTANCE]: 'Pricing accepted',
    [ECategory.TARIFF_CHANGE]: 'Pricing change',
    [ECategory.TARIFF_REJECTION]: 'Pricing rejected',
  },
  message: {
    [EWebNotificationType.SubscribersUploadLoading]:
      'Uploading client list {{fileName}}. <0>Check the progress</0>',
    [EWebNotificationType.SubscribersUploadFailed]: `Client list upload was interrupted. The list of the clients {{fileName}} has not been uploaded`,
    [EWebNotificationType.SubscribersUploadPartial]:
      'The client list {{fileName}} has been partially loaded. <0>Check the result</0>',
    [EWebNotificationType.SubscribersUploadSuccess]: `The client list {{fileName}} has been successfully loaded. <0>Check the result</0>`,
    [EWebNotificationType.BroadcastCompleted]: `The broadcast "{{name}}" is completed. <0>To the broadcast report</0>`,
    [EWebNotificationType.BroadcastCancelled]: `The broadcast "{{name}}" has been stopped. <0>To the broadcast report</0>`,
    [EWebNotificationType.LicenseExpiring]:
      'Your license for edna Pulse expires {{date}}. To renew the license, please contact your personal manager: {{name}} (<0>{{email}}</0>)',
    [EWebNotificationType.CallbackUpdated]: `Callback URL for broadcast statuses has been successfully changed. <0>To API key and callbacks</0>`,
    [EWebNotificationType.ApikeyUserUpdated]: `The new API key has been successfully generated. <0>To API key and callbacks</0>`,
    [EWebNotificationType.CompanyDetailsUpdated]: `User "{{login}}" has changed the company details. <0>To details</0>`,
    [EWebNotificationType.PaymentDetailsUpdated]: `User "{{login}}" has changed the company payment details. <0>To details</0>`,
    [EWebNotificationType.TenantTariffAccepted]: `User "{{login}}" has agreed to the available pricing options. <0>To tariffs</0>`,
    [EWebNotificationType.AgreedChangeTariffRequest]: `User "{{login}}" has accepted the pricing changes for <b>{{tariffName}}</b>. <0>To pricing</0>`,
    [EWebNotificationType.NotAgreedChangeTariffRequest]: `User "{{login}}" has rejected the pricing changes for <b>{{tariffName}}</b>. <0>To tariffs</0>`,
    [EWebNotificationType.JivoEnabled]:
      'User {{login}} has connected all the WhatsApp channel traffic to the Jivo chat platform',
    [EWebNotificationType.JivoDisabled]:
      'User {{login}} has disconnected all the WhatsApp channel traffic to the Jivo chat platform',
    [EWebNotificationType.MessageMatcherStatusChanged]: {
      [EApproveStatus.DISABLED]:
        'Template "{{name}}" was disabled. All scheduled broadcasts using this template are stopped.',
      [EApproveStatus.PAUSED]:
        'Template "{{name}}" was paused. All scheduled broadcasts using this template are stopped.',
      [EApproveStatus.APPROVED]: 'Template "{{name}}" status changed. New status: Active.',
      [EApproveStatus.REJECTED]: 'Template "{{name}}" status changed. New status: Rejected.',
      [EApproveStatus.PENDING]:
        'Template "{{name}}" status changed. New status: Pending registration.',
      [EApproveStatus.NOT_SENT]: 'Template "{{name}}" status changed. New status: Draft.',
    },
    [EWebNotificationType.MessageMatcherCategoryChanged]: `The category of WhatsApp template "{{name}}" was changed by WhatsApp. New category: "{{newCategory}}".`,
    [EWebNotificationType.InvoicePaid]: 'Payment successful',
    [EWebNotificationType.ChannelProfileQualityScoreChanged]:
      'WhatsApp Business API {{name}}: quality changed. New quality: {{qualityScore}}.',
    [EWebNotificationType.ChannelProfileMessagingLimitChanged]:
      'WhatsApp Business API {{name}}: limit changed. New limit: {{messagingLimit}}.',
    [EWebNotificationType.ChannelProfileQualityStatusChanged]:
      'WhatsApp account {{name}} status changed. New status: {{qualityStatus}}.',
    [EWebNotificationType.StripeCheckout]: {
      [EAutopaymentError.GENERIC_DECLINE]:
        'The auto payment failed on {{date}}. For more information, contact technical support <0>{{email}}</0>.',
      [EAutopaymentError.AUTHENTICATION_REQUIRED]:
        'The auto payment failed on {{date}}. Your bank has requested payment confirmation.',
      [EAutopaymentError.CARD_DECLINE]: 'Failed to make an auto payment with the linked card.',
      [EAutopaymentError.INSUFFICIENT_FUNDS]: 'The charge failed on {{date}}. Insufficient funds.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_ENABLED]: 'Auto payment activated.',
      [EAutopaymentNotificationStatus.AUTO_REPLENISHMENT_DISABLED]: 'Auto payment deactivated.',
      [EAutopaymentNotificationStatus.PAYMENT_SUCCEEDED]:
        'Auto payment activated. To receive a payment receipt, follow the link to the <0>Stripe payment system website</0>.',
    },
  },
  noDataWithFilters: {
    title: "Sorry, we didn't find anything",
    text: 'There are no notifications for the selected filters',
    button: 'Try again',
  },
  noData: {
    title: 'Nothing here for the selected period',
  },
};
