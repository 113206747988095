import {TFunction} from 'react-i18next';

import i18n from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {showNewNotification} from '@edna/components';

import {showTemporaryBlockModal} from 'src/containers/UserCompanyProfile/TemporaryBlockModal';

import {ETenantCode} from '../constants';

type TViolation = {
  field: string;
  message: string;
};

export type TErrorPayload = {
  messageKey?: string;
  code?: string;
  title?: string;
  status?: number;
  args?: TAnyObject;
};

export type TGetErrorMessage = (
  t: TFunction,
  payload: TErrorPayload,
  i18nOptions?: TAnyObject & {
    violations?: TViolation[];
  },
) => string;

export const getErrorMessage: TGetErrorMessage = (t, {messageKey, code, status}, i18nOptions) => {
  const strings = [];

  if (messageKey) {
    strings.push(t(messageKey, i18nOptions));
  }

  if (code) {
    if (i18nOptions?.violations && !isEmpty(i18nOptions?.violations)) {
      Object.values(i18nOptions.violations).forEach((value) => {
        strings.push(t(`Errors:violations.${value?.field}`, ''));
      });
    } else {
      strings.push(t([`Errors:${code}`, 'Errors:unknownErrorCode'], {...i18nOptions, code}));
    }
  }

  if (isEmpty(strings)) {
    strings.push(t([`Errors:httpStatus.${status}`, 'Errors:unexpectedError']));
  }

  return strings.join(' ');
};

export const isErrorWithCode = (error: unknown): error is {data: TErrorPayload} => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof (error as any).data === 'object' &&
    (typeof (error as any).data.code === 'string' || typeof (error as any).data.title === 'string')
  );
};
export const getErrorCode = (error?: TAnyObject) => error?.data?.code || error?.data?.title;

// TODO: подумать о переименовании
const errorNotification = (error: TAnyObject, messageKey?: string, i18nOptions?: TAnyObject) => {
  if (getErrorCode(error) === ETenantCode.TENANT_TEMPORARY_BLOCKED) {
    showTemporaryBlockModal(error.config.url, error.data.detail);

    return;
  }

  showNewNotification({
    type: 'error',
    message: getErrorMessage(
      i18n.t,
      {
        code: getErrorCode(error),
        status: error?.status,
        messageKey,
      },
      {
        violations: {...error?.data?.violations},
        ...error?.data?.args,
        ...i18nOptions,
      },
    ),
  });
};

export default errorNotification;
