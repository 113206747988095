enum EAutopaymentStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

enum EAutopaymentError {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  CARD_DECLINE = 'CARD_DECLINE',
  GENERIC_DECLINE = 'GENERIC_DECLINE',
  AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED',
}

type TAutopaymentError = {
  occurredAt: string;
  nextAttemptAt: string;
  attemptsLeft: number;
  declineCode: EAutopaymentError;
  hostedInvoiceUrl: string;
};

type TAutopayment = {
  threshold: number;
  amount: number;
  customerStatus: EAutopaymentStatus;
  paymentError?: TAutopaymentError;
  stripeUrl?: string;
};

type TAutopaymentRestrictions = {
  minimumThreshold: number;
  minimumAmount: number;
};

type TFormData = {
  threshold: number;
  amount: number;
};

const MAX_AMOUNT = 99_999;

export {EAutopaymentStatus, EAutopaymentError, MAX_AMOUNT};
export type {TAutopayment, TAutopaymentRestrictions, TFormData, TAutopaymentError};
