import {AxiosResponse} from 'axios';

// TODO: https://jira.edna.ru/browse/EP-3799
let lastResponse: AxiosResponse | undefined = undefined;

const setLastResponse = (response: AxiosResponse) => {
  lastResponse = response;
};

const getLastResponse = () => {
  return lastResponse;
};

export {setLastResponse, getLastResponse};
