export default {
  filter: 'Filter',
  sort: {
    header: 'Sort by',
    byCreatedAt_asc: 'Time of creation, old first',
    byCreatedAt_desc: 'Time of creation, new first',
    byChangeAt_asc: 'Time of modify, old first',
    byChangeAt_desc: 'Time of modify, first recent',
  },
  errors: {
    failedToLoadList: 'Sorry, failed to load list.',
    failedToLoadItem: 'Sorry, failed to load the data.',
    failedToSaveItem: 'Sorry, an error occurred while saving.',
    failedToRegisterItem: 'Sorry, an error occurred while submitting for registration.',
    failedToDeleteItem: 'Sorry, the item can not be removed.',
  },
  notification: {
    deleteSuccess: 'Removed successfully',
    archiveSuccess: 'Moved to archive',
    saveSuccess: 'Saved successfully',
    pristineEditForm: 'No save required because you didn’t change anything',
  },
  totalElements: '{{title}} by criteria - {{count}}',
  noData: 'This is empty for now',
  nothingFound: 'Nothing found',
  errorLoadList: {
    title: 'Oops...',
    subtitle: "We can't find any matches",
    hint: 'Try different search conditions',
  },
};
