import {ETemporaryBlockedTypes, EUserCompanyProfileTabs} from './definitions';

export default {
  blockedByLicense:
    'This action is unavailable. For the details, contact <0>your personal manager</0>',
  serviceUnavailable: 'Service is temporarily unavailable. Please try again later',
  tabs: {
    [EUserCompanyProfileTabs.PAYMENT]: 'Payment',
    [EUserCompanyProfileTabs.LICENSE]: 'License',
    [EUserCompanyProfileTabs.REQUISITES]: 'Company details',
    [EUserCompanyProfileTabs.PRICING]: 'Pricing',
  },
  messages: {
    haveExpiredLicense_one:
      'You have an unpaid license invoice. The license for the channel {{channels}} has been blocked. To resume work, you need to pay the invoice issued.',
    haveExpiredLicense_other:
      'You have an unpaid license invoice. The license for the channels {{channels}} has been blocked. To resume work, you need to pay the invoices issued.',
    haveExpiredBillForLicense_one:
      'You have an unpaid license invoice. The operation of the channel {{channels}} has been blocked. To resume work, you need to pay the invoice issued.',
    haveExpiredBillForLicense_other:
      'You have an unpaid license invoice. The operation of the channels {{channels}} has been blocked. To resume work, you need to pay the invoices issued.',
    haveNotMoney: "You don't have enough funds to continue using the service.",
    haveNotPayedBills:
      'You have unpaid invoices. In case of non-payment, your work in the system may be limited.',
    addBalance: 'Please top up your balance.',
    lowBalance:
      'The available funds might not be enough to send the broadcast. Contact your manager to resolve the issue with your balance or overdraft.',
    broadcastBlocked: `Your access to the services is suspended until {{date}}.
       Until then, sending broadcasts and receiving messages is unavailable. All broadcasts you create will be saved as drafts.
       To activate the services, please contact the assigned manager`,
    accessBlocked: `Your access to the services is suspended until {{date}}.
       Until then, sending and receiving messages is unavailable.
       To activate the services, please contact the assigned manager`,
  },
  buttons: {
    openDocumentList: 'Open document list',
    contactWithManager: 'Contact manager',
  },
  temporaryBlockModal: {
    toProfile: 'To profile',
    [ETemporaryBlockedTypes.BROADCASTS]: {
      title: 'You cannot register broadcasts',
      subtitle:
        'Your account is temporarily blocked, so you cannot register broadcasts until {{date}}. To activate your account, please contact the assigned manager.',
    },
    [ETemporaryBlockedTypes.TEMPLATES]: {
      title: 'You cannot register templates',
      subtitle:
        'Your account is temporarily blocked, so you cannot register templates until {{date}}. To activate your account, please contact the assigned manager.',
    },
    [ETemporaryBlockedTypes.CHANNELS]: {
      title: 'You cannot register channels',
      subtitle:
        'Your account is temporarily blocked, so you cannot register channels until {{date}}. To activate your account, please contact the assigned manager.',
    },
    [ETemporaryBlockedTypes.CASCADES]: {
      title: 'You cannot register cascades',
      subtitle:
        'Your account is temporarily blocked, so you cannot register cascades until {{date}}. To activate your account, please contact the assigned manager.',
    },
    [ETemporaryBlockedTypes.OTHER]: {
      title: 'Your account is temporarily blocked',
      subtitle: 'To activate your account, please contact the assigned manager.',
    },
  },
};
