import styled, {css} from 'styled-components';

import {TSize} from '@edna/components';
import {InfoDropdownOverlay as BaseInfoDropdownOverlay} from '@edna/components/InfoDropdown/style';
import {TTheme, mixins} from '@edna/components/styles';

import {InfoOutlineIcon as BaseInfoOutlineIcon} from 'src/icons';

export * from '@edna/components/InfoDropdown/style';

type TInfoOutlinedIconProps = {
  size: TSize;
};

export const InfoOutlinedIcon = styled(BaseInfoOutlineIcon)<TInfoOutlinedIconProps>`
  display: inline-block;
  ${({size}: TInfoOutlinedIconProps) => mixins.round(size === 'L' ? '24px' : '16px')}
  color: ${({theme}) => theme.branding.content.secondary};
`;

const getInfoDropdownOverlayStyle = ({theme: {palette, borderRadius}}: TTheme) => css`
  color: ${palette.grey100};
  background-color: ${palette.grey700};
  border-radius: ${borderRadius.br2};
  &::before {
    border-color: ${palette.grey700} ${palette.grey700} transparent transparent;
  }
`;

export const InfoDropdownOverlay = styled(BaseInfoDropdownOverlay)`
  ${mixins.f2}
  line-height: 16px;
  ${getInfoDropdownOverlayStyle}
`;

export const InfoDropdownContent = styled.div`
  padding: ${({theme}: TTheme) => theme.spacing.s4};
`;
