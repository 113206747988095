import {ParameterIcon, ProfileSquaredIcon, TagIcon} from 'src/icons';
import {DiverImage, EmptyAddressesImage, EmptyParametersImage} from 'src/images';

export enum ETab {
  TAGS = 'tags',
  PARAMETERS = 'parameters',
  ADDRESSES = 'addresses',
}

export const TABS = Object.values(ETab);

export const tabIconHash = {
  [ETab.TAGS]: TagIcon,
  [ETab.PARAMETERS]: ParameterIcon,
  [ETab.ADDRESSES]: ProfileSquaredIcon,
};

export const emptyTabIconHash = {
  [ETab.TAGS]: DiverImage,
  [ETab.PARAMETERS]: EmptyParametersImage,
  [ETab.ADDRESSES]: EmptyAddressesImage,
};
