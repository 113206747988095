import {EDebtPaymentType} from 'src/containers/Licenses/PaymentTab/definitions';

export default {
  paymentDebtsTitle: 'You have an overdue payment of',
  nextPaymentsTitle:
    'Upcoming fees for <strong>{{month}} {{year}}</strong> The preliminary amount is',
  th: {
    createdAt: 'Expired status effective date',
    blockedAt: 'Will be blocked on',
    paymentType: 'Payment type',
    total: 'Amount',
    paymentDate: 'Payment date',
    expireAt: 'Must pay before',
  },
  item: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `Activation of the license to use edna Pulse with WHATSAPP channel connection (MAU {{mauCount}}), {{subject}} account{{number}}, {{month}} {{year}}.`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with WHATSAPP channel connection (MAU {{mauCount}}), {{subject}} account{{number}}, {{month}} {{year}}.`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `Activation of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `Activation of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Viber minimum fee over the period of {{month}} {{year}}, account {{subject}}.`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `Message routing services via edna Pulse, {{subject}} account{{number}}. AMAU`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Subscription fee for using the alphanumeric name (signatures of {{subject}} SMS messages, {{mobileOperator}} operator), {{month}} {{year}}.`,
  },
  nextPaymentItem: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `License activation, {{number}} {{month}} {{year}}.`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with WHATSAPP channel connection (MAU {{mauCount}}), {{subject}} account{{number}}, {{month}} {{year}}.`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `Activation of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with the connection of the channel SMS, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `Activation of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `Renewal of the license to use edna Pulse with the connection of the channel VIBER, {{month}} {{year}}`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Viber minimum fee over the period of {{month}} {{year}}, account {{subject}}.`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `Message routing services via edna Pulse, {{subject}} account{{number}}. AMAU`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Subscription fee for using the alphanumeric name (signatures of {{subject}} SMS messages, {{mobileOperator}} operator), {{month}} {{year}}.`,
  },
  collapseTd: {
    [EDebtPaymentType.WHATSAPP_LICENSE_INIT_PAYMENT]: `License activation`,
    [EDebtPaymentType.VIBER_LICENSE_INIT_PAYMENT]: `License activation`,
    [EDebtPaymentType.SMS_LICENSE_INIT_PAYMENT]: `License activation`,
    [EDebtPaymentType.WHATSAPP_LICENSE_RENEWAL_PAYMENT]: `License renewal`,
    [EDebtPaymentType.SMS_LICENSE_RENEWAL_PAYMENT]: `License renewal`,
    [EDebtPaymentType.VIBER_LICENSE_RENEWAL_PAYMENT]: `License renewal`,
    [EDebtPaymentType.WHATSAPP_AMAU_PAYMENT]: `Message routing services`,
    [EDebtPaymentType.SMS_RECURRENT_BILLED]: `Subscription fee for the use of signatures in SMS`,
    [EDebtPaymentType.VIBER_RECURRENT_PAYMENT]: `Subscription fee for the use of signatures in VIBER`,
  },
  hint: {
    blockedAtHint: 'After that, your account and interface will be locked beyond recovery',
    createdAtHint:
      'Before that date, the balance sheet was insufficient to pay the debt. Sending messages in this channel is not possible',
    expireAtHint:
      'If by this date the available balance is insufficient to write off, the license will be blocked and sending messages in the channel will become impossible',
    nextPaymentTotalHint:
      'The amount is preliminary. It depends on the variable part of the license and other non-fixed fees. The exact amount will be known on {{date}}',
    initPaymentTotalHint: 'The amount will appear when activating the license',
    amauPaymentTotalHint:
      'The amount depends on the number of messages sent over the license (AMAU)',
  },
};
