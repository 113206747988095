import React from 'react';

import {EFacebookStatus, TFacebook, TFacebookPayload, TLoginOptions} from './definitions';
import {handleFBLogin, useFacebookLogin} from './useFacebookLogin';

const FacebookContext = React.createContext<TFacebook>({
  status: EFacebookStatus.LOADING,
  checkConnection: () => {},
  login: (options: TLoginOptions) => handleFBLogin(options),
});

type TFacebookContextProviderProps = TFacebookPayload & {
  children?: React.ReactNode;
};

const FacebookContextProvider = React.memo<TFacebookContextProviderProps>(
  ({config, onError, children}) => {
    const context = useFacebookLogin({config, onError});

    return <FacebookContext.Provider value={context}>{children}</FacebookContext.Provider>;
  },
);

export {FacebookContext, FacebookContextProvider};
