import {EStatus} from './definitions';

export default {
  title: {
    list: 'WhatsApp profiles',
    edit: 'Profile',
    new: 'New profile',
  },
  message: {
    title: 'Important before registration',
    text: 'Before registering a channel with edna Pulse, you need to have <0>Company ID in Facebook Business Manager</0>',
  },
  managerId: 'Enter Facebook Business Manager ID',
  editForm: {
    title: 'Channel registration form',
    nameLabel: 'Internal channel name',
    phoneCodeLabel: 'Country code',
    phoneLabel: 'Phone number the WhatsApp Business Account will be registered to',
    phoneHint:
      'entered without the country code; the phone number must be able to receive text messages or calls from the USA',
    phoneWarning: 'Make sure the number is filled in correctly',
    senderNameLabel: 'Display name',
    senderNameHint: 'See <0>display name policy guide</0>',
    addressLabel: 'Company address',
    addressHint: 'the address will be displayed in your WhatsApp profile',
    descriptionLabel: 'Brief description of the company',
    urlsLabel: 'Company website',
    site: 'website',
    urlsHint: 'the website will be displayed in your WhatsApp profile',
    emailLabel: 'Company e-mail address',
    emailHint: 'the e-mail will be displayed in your WhatsApp profile',
    businessKindLabel: 'Type of business',
    contactStatusLabel:
      'WhatsApp account status that clients can see under the name of the account',
    contactStatusHint:
      'The status will be displayed under the account name on your company in your account profile; you can change it later via your personal manager',
    fileLabel: 'Logo',
    fileHint: 'Only jpeg format is allowed. The minimum image size is 400x400 pixels.',
    facebookPageUrlLabel: 'URL to Facebook page',
    facebookPageUrlHint: 'if any',
    alternativeName: 'an alternative company name',
    alternativeNamesLabel: 'An alternative company name by which your clients can recognize you',
    communicationSolution: {
      label: 'Solution used',
    },
  },
  instructionManual: 'Instruction',
  requestCode: 'Request code',
  sendCode: 'Send code',
  accountVerificationLink: 'Account verification link',
  invalidNaturalSize: 'Image too small',
  invalidLogoFormat: 'Invalid logo format',
  sendVerificationSMS: 'SMS with code sent',
  sendVerificationVOICE: 'Voice message with code sent',
  phoneNumberVerified: 'Phone number verified',
  businessKind: {
    Automotive: 'Automotive',
    'Beauty, Spa and Salon': 'Beauty, Spa and Salon',
    'Clothing and Apparel': 'Clothing and Apparel',
    Education: 'Education',
    Entertainment: 'Entertainment',
    'Event Planning and Service': 'Event Planning and Service',
    'Finance and Banking': 'Finance and Banking',
    'Food and Grocery': 'Food and Grocery',
    'Public Service': 'Public Service',
    'Hotel and Lodging': 'Hotel and Lodging',
    'Medical and Health': 'Medical and Health',
    'Non-profit': 'Non-profit',
    'Professional Services': 'Professional Services',
    'Shopping and Retail': 'Shopping and Retail',
    'Travel and Transportation': 'Travel and Transportation',
    Restaurant: 'Restaurant',
    Other: 'Other',
  },
  status: {
    [EStatus.SENT]: {
      title: 'Pending registration',
      details: 'The WhatsApp account has been sent for registration',
    },
    [EStatus.DRAFT]: {title: 'Draft', details: ''},
    [EStatus.NEW]: {title: 'New', details: ''},
    [EStatus.CREATE_ENVIRONMENT]: {
      title: 'Pending registration',
      details: 'The channel is not ready to work',
    },
    [EStatus.CONFIRM_CODE_REQUEST]: {
      title: 'Pending registration',
      details: 'The channel is not ready to work',
    },
    [EStatus.SEND_CONFIRM_CODE]: {
      title: 'Pending registration',
      details: 'The channel is not ready to work',
    },
    [EStatus.FINISH_REGISTER]: {
      title: 'Pending registration',
      details: 'The channel is not ready to work',
    },
    [EStatus.CREATE_WHATSAPP_ACCOUNT]: {
      title: 'Pending registration',
      details: 'The channel is not ready to work',
    },
    [EStatus.CONFIRM_ACCOUNT]: {
      title: 'Attention required',
      details: 'The channel is not ready to work',
    },
    [EStatus.NEED_CONFIRM_ACCOUNT]: {
      title: 'Attention required',
      details: 'Request confirmation required',
    },
    [EStatus.NEED_VERIFY_FACEBOOK_BUSINESS]: {
      title: 'Attention required',
      details: 'Facebook Business verification required',
    },
    [EStatus.NEED_CONFIRM_CODE]: {
      title: 'Attention required',
      details: 'The channel is not ready to work',
    },
    [EStatus.DONE_VERIFIED]: {
      title: 'Active',
      details: 'Works properly (received official status)',
    },
    [EStatus.DONE_NOT_VERIFIED]: {
      title: 'Active',
      details: 'Works properly (account without official status)',
    },
    [EStatus.CANCELED]: {title: 'Canceled', details: ''},
    [EStatus.BLOCKED]: {title: 'Locked', details: ''},
    [EStatus.UNKNOWN]: {title: 'Unknown', details: ''},
  },
  errors: {
    EXTERNAL: `Facebook Business Manager with this ID does not exist.
    Follow the instructions to find your ID:`,
    UNEXPECTED: `Unexpected error occurred.
  Contact the support team {{supportEmail}}`,
  },
};
