import styled, {css} from 'styled-components';

import {
  Button as BaseButton,
  LoadingButton as BaseLoadingButton,
  NewNumberInputField as BaseNumberInputField,
} from '@edna/components';
import {Label as BaseFieldLabel} from '@edna/components/FormField';
import {SkeletonItem as BaseSkeletonItem} from '@edna/components/primitives';
import {mixins} from '@edna/components/styles';

import {Chip as BaseChip, NumberRow as BaseNumberRow} from 'src/components';
import {Link as BaseLink, RouterLink as BaseRouterLink} from 'src/components/primitives';

export const Row = styled.div`
  ${mixins.row}
  ${mixins.flexAlign('flex-start')}
`;

export const NumberInputField = styled(BaseNumberInputField)`
  max-width: 170px;
`;

export const LoadingButton = styled(BaseLoadingButton)`
  ${({theme}) => css`
    margin: 0 0 ${theme.spacing.s4} ${theme.spacing.s3};
  `}
`;

export const Label = styled(BaseFieldLabel)`
  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s2};
  `}
`;

export const PhonesContainer = styled.div`
  ${mixins.flexAlign('flex-start', 'center')}
  flex-wrap: wrap;
  ${({theme}) => css`
    max-width: ${theme.dimension.formWidth}px;
    padding-bottom: ${theme.spacing.s2};
  `}
`;

export const PhoneChip = styled(BaseChip)`
  ${({theme}) => css`
    margin: 0 ${theme.spacing.s2} ${theme.spacing.s2} 0;
  `}
`;

export const NumberRow = styled(BaseNumberRow).attrs({numberSize: 'L'})`
  ${mixins.flexAlign('flex-start', 'center')}
  ${mixins.f5}
  ${({theme}) => css`
    margin: ${theme.spacing.s2} 0;
  `}
`;

export const QRContainer = styled.div`
  ${mixins.column}
  ${mixins.flexAlign('center')}
  ${({theme}) => css`
    max-width: ${theme.dimension.formWidth}px;
  `}
`;

export const QRSubtitle = styled.div`
  ${mixins.f4}
  ${({theme}) => css`
    margin: ${theme.spacing.s4} 0;
  `}
`;

export const QRHint = styled.div`
  ${mixins.f3}
  ${({theme}) => css`
    max-width: 800px;
    margin: ${theme.spacing.s5} 0;
  `}
`;

export const RouterLink = styled(BaseRouterLink).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: underline;
`;

export const Link = styled(BaseLink)`
  text-decoration: underline;
`;

export const Button = styled(BaseButton)`
  ${({theme}) => css`
    margin: ${theme.spacing.s4} 0;
  `}
`;

export const SkeletonItem = styled(BaseSkeletonItem)`
  height: 70px;
  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s2};
  `}
`;

export const Nested = styled.div`
  ${({theme}) => css`
    margin-left: ${theme.spacing.s11};
  `}
`;
