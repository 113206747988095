import styled, {css} from 'styled-components';

import {TThProps} from '@edna/components/Table';
import {Th as BaseTh} from '@edna/components/Table/style';

export * from '@edna/components/Table/style';

export const Th = styled(BaseTh)`
  height: 100%;
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0;
  ${({sortDirection, theme}: TThProps) => css`
    color: ${theme.branding.content.secondary};
    ${sortDirection !== undefined &&
    css`
      background-color: ${theme.palette.grey10};
      border-radius: ${theme.borderRadius.br2};
    `}
  `}
`;
