import {TData, TPayload} from 'src/components/List';
import {TChannelType, TItemId} from 'src/constants';
import {CACHE_LIFETIME, ECacheTag, rootApi} from 'src/models/api';
import {booleanHashToArray} from 'src/utils';

import {convertStagesTreeToArray} from './EditForm/utils';
import {TCyclicStage, TStageEditForm} from './definitions';

export enum EStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const CASCADE_STATUSES = Object.values(EStatus);

export type TStatus = keyof typeof EStatus;

export type TItem<Stage = TCyclicStage> = {
  id: TItemId;
  name: string;
  status: TStatus;
  stages: Stage[];
  channelTypes: TChannelType[];
};

export const DEFAULT_STAGE: TStageEditForm = {
  order: 0,
  uuid: '',
  decision: null,
  stages: [],
  subject: null,
};

export const DEFAULT_INITIAL_VALUES: TItem<TStageEditForm> = {
  id: '',
  name: '',
  status: EStatus.ACTIVE,
  stages: [DEFAULT_STAGE],
  channelTypes: [],
};

const prepareFilters = (payload: TPayload) => ({
  ...payload,
  channelTypes: booleanHashToArray(payload.channelTypes),
  statuses: booleanHashToArray(payload.statuses),
});

export const cascadesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCascades: builder.query<TData<TItem>, TPayload>({
      query: (data) => ({
        method: 'POST',
        url: '/broadcast/cascade-template/list',
        data: prepareFilters(data),
      }),
      keepUnusedDataFor: CACHE_LIFETIME,
      providesTags: (result) => {
        if (!result) {
          return [ECacheTag.CASCADES];
        }

        return [
          ECacheTag.CASCADES,
          ...result.content.map(({id}) => ({id, type: ECacheTag.CASCADES})),
        ];
      },
    }),
    getCascade: builder.query<TItem<TStageEditForm>, TItemId>({
      query: (id) => ({
        method: 'GET',
        url: `/broadcast/cascade-template/${id}`,
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToLoadItem',
        },
      }),
      transformResponse: (response: TItem) => {
        return {
          ...response,
          stages: response.stages ? convertStagesTreeToArray(response.stages) : [],
        };
      },
    }),
    createCascade: builder.mutation<TItem, TItem>({
      query: (data) => ({
        method: 'POST',
        url: `/broadcast/cascade-template/`,
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToSaveItem',
          isShowSuccess: true,
          successMessageKey: 'List:notification.saveSuccess',
        },
        data,
      }),
      invalidatesTags: [ECacheTag.CASCADES],
    }),
    deleteCascade: builder.mutation<TItem, TItemId>({
      query: (id) => ({
        method: 'POST',
        url: `/broadcast/cascade-template/${id}/actions/disable`,
        meta: {
          isShowError: true,
          errorMessageKey: 'List:errors.failedToDeleteItem',
          isShowSuccess: true,
          successMessageKey: 'List:notification.deleteSuccess',
        },
      }),
      invalidatesTags: [ECacheTag.CASCADES],
    }),
  }),
});

export const {
  useGetCascadesQuery,
  useDeleteCascadeMutation,
  useGetCascadeQuery,
  useLazyGetCascadeQuery,
  useCreateCascadeMutation,
} = cascadesApi;
