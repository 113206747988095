export default {
  successTitle: 'Hello {{name}}!',
  successText: 'Your edna Pulse account has been created!',
  linkRequested: 'Your request for a new registration link has been successfully sent',
  setPassword: 'You need to create a password to log into your edna dashboard.',
  createPassword: 'Create password',
  repeatPassword: 'Repeat password',
  yourLogin: 'Your login',
  signIn: 'Sign in',
  acceptTerms: 'By clicking the button you agree to the<br/> <0>user agreement</0>',
  linkExpiredTitle: 'The link is no longer valid',
  linkExpiredText: 'Unfortunately, the link has expired (its validity period is 24 hours)',
  requestLinkTitle: 'Please request a new registration link and our manager will email it to you',
  requestLink: 'Repeat request',
  linkAlreadyRequested: 'You have already requested a new registration link',
  wait: 'Please be patient, it might take some time',
  requestLinkText: 'Our manager will let you know once the new registration link is generated',
};
