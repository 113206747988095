import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {LoadingButton, Modal, ModalActions, ModalContent, ModalHeader} from '@edna/components';

import {LeftButton, ModalCenterImage} from 'src/components/primitives';
import {QuestionImage} from 'src/images';

import {useRegenerateApiKeyMutation} from '../api';
import {TApiKeyData} from '../definitions';

type TProps = {
  closeModal: TEmptyFunction;
  apiKeyData?: TApiKeyData;
};

const GenerateNewApiKeyModal = React.memo<TProps>(({closeModal, apiKeyData}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id: supportTenantId} = useParams<'id'>();

  const [regenerateApiKey] = useRegenerateApiKeyMutation();

  const handleConfirm = React.useCallback(() => {
    if (!apiKeyData) {
      return;
    }

    regenerateApiKey({supportTenantId, data: apiKeyData});
    closeModal();
  }, [dispatch, apiKeyData, supportTenantId, closeModal]);

  return (
    <Modal width="430px">
      <ModalHeader>{t('CompanyCallback:changeAPIKeyModal.title')}</ModalHeader>
      <ModalContent>{t('CompanyCallback:changeAPIKeyModal.message')}</ModalContent>
      <ModalCenterImage as={QuestionImage} />
      <ModalActions>
        <LeftButton onClick={handleConfirm}>{t('Common:button.yes')}</LeftButton>
        <LoadingButton onClick={closeModal} appearance="primary" data-selector="Common:button.no">
          {t('Common:button.no')}
        </LoadingButton>
      </ModalActions>
    </Modal>
  );
});

GenerateNewApiKeyModal.displayName = 'GenerateNewApiKeyModal';

export default GenerateNewApiKeyModal;
